import { TextGrid, Toggle, Container as ToggleContainer } from '../styles/CustomSelector.styles';
import { TimeControlType, TimePeriodType } from '../enums';
import { Container } from '../styles/ReadOnlyPeriodInput.styles';
import { ControlTitle } from '../styles';
import CustomSkeletonGrid from './CustomSkeletonGrid';
import React from 'react';

interface ReadOnlyPeriodInputProps {
  controlType: TimeControlType;
  timeTextValue?: string;
  timePeriod?: TimePeriodType;
  loading: boolean;
}

const ReadOnlyPeriodInput: React.FC<ReadOnlyPeriodInputProps> = ({
  controlType,
  timeTextValue,
  timePeriod,
  loading,
}: ReadOnlyPeriodInputProps) => {
  return (
    <Container>
      <ControlTitle>
        {`${controlType === TimeControlType.CHECK_IN ? 'Check-in' : 'Check-out'} Time`}
      </ControlTitle>
      {loading ? (
        <>
          <CustomSkeletonGrid
            skeletonDimensionsList={[
              { height: 35, width: 120 },
              { height: 35, width: 100 },
            ]}
            rounded
          />
        </>
      ) : (
        <>
          <input value={timeTextValue} readOnly />
          <ToggleContainer compact disableCursor>
            <TextGrid>
              <span>{TimePeriodType.AM}</span>
              <span>{TimePeriodType.PM}</span>
            </TextGrid>
            <Toggle leftSelected={timePeriod === TimePeriodType.AM}>{timePeriod}</Toggle>
          </ToggleContainer>
        </>
      )}
    </Container>
  );
};

export default ReadOnlyPeriodInput;
