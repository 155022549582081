import { ServiceType } from '../__generated__/graphql';

type serviceType = {
  [key: string]: string;
};

export const serviceTypeLabels: serviceType = {
  [ServiceType.EarlyCheckIn]: 'Early Check-in',
  [ServiceType.LateCheckOut]: 'Late Check-out',
  [ServiceType.Housekeeping]: 'House Keeping',
  [ServiceType.Room]: 'Room',
  [ServiceType.Food]: 'Food',
  [ServiceType.Comparable]: 'Comparable',
  [ServiceType.Packages]: 'Packages',
};
