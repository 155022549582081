import {
  BaseModalHeaderContainer,
  CloseModalButton,
  HeaderIcon,
  HeaderText,
} from '../styles/BaseModalHeader.styles';
import { BaseModalHeaderProps } from '../@types/QuickActionBarTypes';
import { CTAIcon } from './CTAIcon';
import { MdClose } from 'react-icons/md';
import React from 'react';
import Theme from '../../../theme';

const BaseModalHeader: React.FC<BaseModalHeaderProps> = ({ headerTitle, onClose, ctaType }) => {
  return (
    <BaseModalHeaderContainer>
      <HeaderIcon>
        <CTAIcon type={ctaType} color={Theme.colors.quickActionButton.hexCode} />
      </HeaderIcon>
      <HeaderText>{headerTitle}</HeaderText>
      <CloseModalButton onClick={onClose}>
        <MdClose size="22px" />
      </CloseModalButton>
    </BaseModalHeaderContainer>
  );
};

export default BaseModalHeader;
