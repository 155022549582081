const WaitingCallListStyle = `
  grid-auto-flow: dense;
  grid-column-gap: 17px;
  grid-row-gap: 100px;
  grid-template-columns: repeat(auto-fill, 160px);
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
`;

export default WaitingCallListStyle;
