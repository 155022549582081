import { Kiosk } from '../../__generated__/graphql';

export default function getKioskLocation(
  kiosk: Pick<Kiosk, 'metadata'>
): { lat: number; lng: number } | undefined {
  if (kiosk.metadata?.lat && kiosk.metadata?.lng) {
    return { lat: kiosk.metadata.lat, lng: kiosk.metadata.lng };
  }

  return undefined;
}
