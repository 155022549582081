/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css } from 'styled-components';
import { ActionButtonProps } from '../@types/QuickActionBarTypes';
import Theme from '../../../theme';

export const ActionButtonContainer = styled.button<
  Pick<ActionButtonProps, 'displayTopSeparatorBar' | 'disabled'>
>`
  display: flex;
  flex-direction: column;
  height: 85px;
  padding: 10px 0;
  border-radius: 16px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  position: relative;
  border: none;
  background: none;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed !important;
    `}

  ${({ displayTopSeparatorBar }) =>
    displayTopSeparatorBar &&
    css`
      :before {
        content: '';
        position: absolute;
        top: -6px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: ${Theme.colors.lightBorder.hexCode};
      }
    `}

  :hover {
    background-color: rgba(${Theme.colors.lightBorder.rgb}, 0.5);
    .abTooltip {
      visibility: visible;
    }
  }

  span {
    color: rgba(
      ${({ disabled }) => {
        return Theme.colors.white.rgb + (disabled ? ',0.4' : '');
      }}
    );
  }

  .abTooltip {
    visibility: hidden;
    background-color: ${Theme.colors.white.hexCode};
    color: black;
    text-align: center;
    border-radius: 10px;
    padding: 5px;
    position: absolute;
    height: 30px;
    z-index: 1;
    top: 25px;
    left: -165px;
    text-overflow: ellipsis;
    max-width: 180px;
    width: 180px;
  }

  @media only screen and (max-height: 768px) {
    padding: 0;
  }
`;

export const IconContainer = styled.div<Pick<ActionButtonProps, 'disabled'>>`
  width: 45px;
  height: 45px;
  min-height: 45px;
  border-radius: 12px;
  background-color: rgba(
    ${({ disabled }) => {
      return Theme.colors.quickActionButton.rgb + (disabled ? ',0.2' : '');
    }}
  );
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-height: 768px) {
    width: 36px;
    height: 36px;
    min-height: 36px;
    border-radius: 8px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
`;
