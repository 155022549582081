import 'styled-components/macro';

import JsonEditor, { ReferenceJsonEditorInterface } from '../JsonEditor';
import React, { useRef } from 'react';

import Container from '../Container';
import CustomizeJsonData from './@types/CustomizeJsonData';
import { JSONSchema7 } from 'json-schema';
import JsonCustomizationHeader from '../JsonEditor/components/JsonCustomizationHeader';
import JsonCustomizationSaveButton from '../JsonEditor/components/JsonCustomizationButtons/SaveButton';
import { LoadingSpinner } from '../LoadingSpinner';
import { TextDirection } from './@types/TextDirectionEnum';
import _ from 'lodash';

interface CustomizeJsonInterface {
  data: CustomizeJsonData | undefined;
  schema: JSONSchema7;
  uiSchema: Record<string, unknown>;
  fileNameToDownloadDefaultJson: string;
  fileNameToDownloadCurrentJson: string;
  isLoading: boolean;
  onSubmit: (_data: Record<string, unknown>) => Promise<void>;
  title: string;
  textDirection?: TextDirection;
}

const CustomizeJson: React.FC<CustomizeJsonInterface> = ({
  data,
  schema,
  uiSchema,
  fileNameToDownloadDefaultJson,
  fileNameToDownloadCurrentJson,
  isLoading,
  onSubmit,
  title,
  textDirection = 'LTR',
}: CustomizeJsonInterface) => {
  const ref: React.MutableRefObject<unknown> = useRef();

  const onTapSubmitButton = (): void => {
    if (ref && ref.current) {
      const jsonEditorReference = ref.current as ReferenceJsonEditorInterface;
      jsonEditorReference.handleSubmit();
    }
  };

  const downloadJson = (downloadData: string | Record<string, unknown>, fileName: string): void => {
    try {
      const url = window.URL.createObjectURL(
        new Blob([JSON.stringify(downloadData)], { type: 'application/json' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);

      document.body.appendChild(link);

      link.click();

      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }

      window.flash({
        message: 'Exported successfully',
        subText: fileName,
        type: 'sucess',
      });
    } catch (error) {
      let errorMessage: string | undefined;
      if (typeof error === 'string') {
        errorMessage = error;
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }

      window.flash({
        message: `Error on downloading ${fileName}`,
        subText: errorMessage,
        type: 'error',
      });
    }
  };

  const onDownload = (): void => {
    if (data) {
      const jsonValue = _.merge(
        _.cloneDeep(data.mergedSettingsData),
        _.cloneDeep(data.settingsData)
      );

      downloadJson(jsonValue, fileNameToDownloadDefaultJson);
    }
  };

  const onTapDownloadCurrentButton = (): void => {
    if (ref && ref.current) {
      const jsonEditorReference = ref.current as ReferenceJsonEditorInterface;
      const downloadData = jsonEditorReference.getData();

      downloadJson(downloadData, fileNameToDownloadCurrentJson);
    }
  };

  const onUploadJSONFile = (newData: string): void => {
    if (ref && ref.current) {
      const jsonEditorReference = ref.current as ReferenceJsonEditorInterface;
      try {
        const newJsonData = JSON.parse(newData);
        jsonEditorReference.importJsonData(newJsonData);
      } catch (error) {
        let errorMessage;
        if (typeof error === 'object') {
          errorMessage = error?.toString();
        }

        window.flash({
          message: 'Error on importing JSON',
          subText: errorMessage ?? 'Something wrong.',
          type: 'error',
        });
      }
    }
  };

  const dataIsDefined = (): boolean => {
    if (!data?.mergedSettingsData) {
      return false;
    }

    return data.mergedSettingsData !== '';
  };

  return (
    <Container margin="0 0 0 30px">
      <JsonCustomizationHeader
        onTapSubmitButton={onTapSubmitButton}
        onTapDownloadButton={onDownload}
        onTapDownloadCurrentButton={onTapDownloadCurrentButton}
        onUploadJSONFile={onUploadJSONFile}
        title={title}
        enableButtons={!isLoading && dataIsDefined()}
      />
      {!isLoading && data ? (
        <div dir={textDirection}>
          <JsonEditor
            jsonEditorSchema={schema}
            jsonEditorUiSchema={uiSchema}
            data={data}
            onSubmit={onSubmit}
            saveButton={<JsonCustomizationSaveButton onTapSubmitButton={onTapSubmitButton} />}
            ref={ref}
          />
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </Container>
  );
};

export default CustomizeJson;
