import { MembershipCreateWithoutUserInput } from '../../../__generated__/graphql';
import { UserFormMembership } from '../../../components/UserForm/@types/UserFormMembershipMap';

export default function generateMembershipWithoutUserInput(
  membership: UserFormMembership,
  tenantId: string
): MembershipCreateWithoutUserInput {
  return {
    location: {
      connect: {
        id: membership.option.value,
      },
    },
    role: membership.withRole,
    tenant: {
      connect: {
        id: tenantId,
      },
    },
  };
}
