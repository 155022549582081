import Theme from '../../../theme';

const TableCRUDActionsContainerStyle = `
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-column-gap: 20px;
  justify-content: end;

  button:disabled {
    cursor: pointer;
    opacity: .5;
  }

  button:not(:disabled) {
    &:hover {
      background: ${Theme.colors.buttonDarkBackground.hexCode};
    }
  }
`;

export default TableCRUDActionsContainerStyle;
