import BooleanSwitch from './components/BooleanSwitch';
import React from 'react';

interface ToggleBooleanSwitchProps {
  isActive: boolean;
  toggleActive: () => void;
}

const ToggleBooleanSwitch: React.FC<ToggleBooleanSwitchProps> = ({
  isActive,
  toggleActive,
}: ToggleBooleanSwitchProps) => {
  return (
    <BooleanSwitch on={isActive} onClick={toggleActive}>
      <div></div>
    </BooleanSwitch>
  );
};

export default ToggleBooleanSwitch;
