import { Location, Mutation } from '../../__generated__/graphql';
import React, { useEffect } from 'react';

import FormTemplate from '../../templates/Form';
import { ImageTypeEnum } from '../UnitTypeForm/enums/ImageTypeEnum.enum';
import RESOURCE_FORM_STEPS from './constants/resourceFormSteps';
import ResourceFormInputs from './@types/ResourceFormInputs';
import pick from 'lodash/pick';
import useGetResourceById from '../../hooks/useGetResourceById';

type CreateResourceData = Pick<Mutation, 'createResourceAndUploadImageFile'>;

interface ResourceAditionalDataOnOpeningFormInterface {
  location: Pick<Location, 'id' | 'name'>;
}

interface ResourceProps {
  id?: string;
  aditionalDataOnOpeningForm?: unknown;
}

const ResourceForm: React.FC<ResourceProps> = ({
  id,
  aditionalDataOnOpeningForm,
}: ResourceProps) => {
  const { called, data: resource, query } = useGetResourceById();

  useEffect(() => {
    if (id && !called) {
      query(id);
    }
  });

  if (id && !resource) {
    return <React.Fragment />;
  }

  const initialValues = (): Partial<ResourceFormInputs> => {
    if (resource) {
      return {
        ...pick(resource, [
          'code',
          'controlType',
          'defaultEnd',
          'defaultStart',
          'description',
          'floor',
          'building',
          'name',
          'passLevel',
          'pmsId',
          'type',
          'vendor',
          'vendorId',
          'isComponentRoom',
        ]),
        imageType: resource.imageUrl ? ImageTypeEnum.URL : undefined,
        imageUrl: resource.imageUrl ?? '',
        location: {
          label: resource.location.name,
          value: resource.location.id,
        },
        unitType: resource.unitType
          ? {
              label: resource.unitType.type,
              value: resource.unitType.id,
            }
          : undefined,
      };
    }

    const defaultInitialValues = {
      imageType: ImageTypeEnum.URL,
    };

    try {
      if (aditionalDataOnOpeningForm) {
        const {
          location,
        } = aditionalDataOnOpeningForm as ResourceAditionalDataOnOpeningFormInterface;
        return {
          ...defaultInitialValues,
          location: {
            label: location.name,
            value: location.id,
          },
        };
      }
      // eslint-disable-next-line no-empty
    } catch {}

    return defaultInitialValues;
  };

  return (
    <FormTemplate<ResourceFormInputs, CreateResourceData>
      formHeader={id ? 'Edit Resource' : 'Create Resource'}
      identifier={id}
      steps={RESOURCE_FORM_STEPS}
      initialValues={initialValues()}
    />
  );
};

export default ResourceForm;
