import { RGB } from '../../../theme/colors';

const TooltipContainerStyle = `
  display: inline-block;
  position: relative;

  > .tooltipContainer {
    background: rgba(${RGB.slateBlue}, 1);
    border-radius: 4px;
    color: #fff;
    font-family: sans-serif;
    font-size: 14px;
    line-height: 1;
    max-width: 200px;
    padding: 6px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    white-space: wrap;
    z-index: 1000;
  }

  > .tooltipContainer::before {
    border: solid transparent;
    border-width: 6px;
    content: " ";
    height: 0;
    left: 50%;
    margin-left: -6px;
    pointer-events: none;
    position: absolute;
    width: 0;
  }

  > .tooltipContainer.top {
    top: -35px;
  }

  > .tooltipContainer.top::before {
    border-top-color: rgba(${RGB.slateBlue}, 1);
    top: calc(100% - 1px);
  }

  > .tooltipContainer.right {
    left: calc(100% + 30px);
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }

  > .tooltipContainer.right::before {
    left: calc(6px * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: rgba(${RGB.slateBlue}, 1);
  }

  > .tooltipContainer.bottom {
    bottom: calc(30px * -1);
  }

  > .tooltipContainer.bottom::before {
    bottom: 100%;
    border-bottom-color: rgba(${RGB.slateBlue}, 1);
  }

  > .tooltipContainer.left {
    left: auto;
    right: calc(100% + 30px);
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }

  > .tooltipContainer.left::before {
    left: auto;
    right: calc(6px * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: rgba(${RGB.slateBlue}, 1);
  }
`;

export default TooltipContainerStyle;
