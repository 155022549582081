import Theme from '../../../theme';
import styled from 'styled-components';

const FormGrid = styled.div`
  display: grid;
  gap: 20px;
  margin: 15px 0;
  align-items: center;
`;

export const EarlyCheckInInputGrid = styled(FormGrid)`
  grid-template-columns: 20px 250px 270px 450px;
`;

export const GracePeriodFormGrid = styled(FormGrid)`
  grid-template-columns: 20px 350px 270px 240px 90px;
`;

export const ResetButton = styled.button`
  height: 35px;
  background-color: ${Theme.colors.white.hexCode};
  border-radius: 10px;
  width: 90px;
  font-size: 16px;
  font-weight: 600;
  border: none;

  :hover {
    background-color: ${Theme.colors.linkWater.hexCode};
    transition: all 0.5s;
  }
`;
