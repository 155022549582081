/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationPaymentFormSchema: NodeSchemaProperties = {
  paymentFormScreen: {
    title: '#24 Payment Form Screen',
    type: 'object',
    properties: {
      'failedToAddPayment:enable': {
        description: '[paymentFormScreen.failedToAddPayment]',
        title: '#1 Failed To Add Payment',
        type: 'boolean',
      },
      failedToAddPayment: {
        type: 'string',
      },
      'succeedToAddPayment:enable': {
        description: '[paymentFormScreen.succeedToAddPayment]',
        title: '#2 Success To Add Payment',
        type: 'boolean',
      },
      succeedToAddPayment: {
        type: 'string',
      },
      successModal: {
        title: '#3 Success Modal',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[paymentFormScreen.successModal.title]',
            title: '#3.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'button:enable': {
            description: '[paymentFormScreen.successModal.button]',
            title: '#3.2 Buttton Title',
            type: 'boolean',
          },
          button: {
            type: 'string',
          },
        },
      },
      errorModal: {
        title: '#4 Error Modal',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[paymentFormScreen.errorModal.title]',
            title: '#4.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'button:enable': {
            description: '[paymentFormScreen.errorModal.button]',
            title: '#4.2 Buttton Title',
            type: 'boolean',
          },
          button: {
            type: 'string',
          },
        },
      },
    },
  },
};

export const mobileV3LocalizationPaymentFormUiSchema: NodeUiSchemaProperties = {
  paymentFormScreen: {
    failedToAddPayment: {
      'ui:label': false,
      'ui:readonly': true,
    },
    succeedToAddPayment: {
      'ui:label': false,
      'ui:readonly': true,
    },
    successModal: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      button: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    errorModal: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      button: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
  },
};
