import Form from '@rjsf/core';
import { JsonEditorTabType } from '../@types/JsonEditorTabType';
import JsonValidationError from '../@types/JsonValidationError';
import jsonGetObjectFromKey from './jsonGetObjectFromKey';

export default function validateFieldsOnSubmit(
  data: {
    jsonData: Record<string, unknown>;
    schema: Record<string, unknown>;
  },
  tabsInfo: {
    tabs: JsonEditorTabType;
    tabsReferences: React.MutableRefObject<never[]>;
  }
): void {
  const { jsonData, schema } = data;
  const { tabs, tabsReferences } = tabsInfo;

  // eslint-disable-next-line no-restricted-syntax
  for (const [key] of Object.entries(tabs)) {
    const jsonFieldsEditorTab: React.MutableRefObject<() => Form<Record<string, unknown>> | null> =
      tabsReferences.current[Object.keys(tabs).indexOf(key)];
    if (jsonFieldsEditorTab && jsonFieldsEditorTab.current) {
      const jsonEditorForm: Form<Record<string, unknown>> | null = jsonFieldsEditorTab.current();
      if (jsonEditorForm) {
        const result = jsonEditorForm.validate(
          jsonGetObjectFromKey(jsonData, key),
          jsonGetObjectFromKey(schema, key)
        );
        const hasErrors = result && result.errors && result.errors.length > 0;
        if (hasErrors) {
          const error: JsonValidationError = {
            errors: result.errors,
            message: 'Error on validating JSON fields',
            name: 'JsonValidationError',
            tabKey: key,
          };

          throw error;
        }
      }
    }
  }
}
