import { gql } from '../__generated__/gql';

const CREATE_ACCESS_GRANT = gql(`
  mutation CreateAccessGrant($data: AccessGrantCreateInput!, $originalAgreementFileId: String) {
    createAccessGrant(data: $data, originalAgreementFileId: $originalAgreementFileId) {
      confirmationCode
      id
      requirements {
        type
        id
      }
    }
  }
`);

export default CREATE_ACCESS_GRANT;
