/* eslint-disable */
import {
  DndProvider,
  MultiBackend,
  Tree,
  getBackendOptions,
  getDescendants,
  getParents,
} from '@minoru/react-dnd-treeview';
import React, { useEffect } from 'react';

import { InfoType } from '../../../__generated__/graphql';
import Node from './Node';
import Placeholder from './Placeholder';
import styles from './styles.module.css';
import useTreeOpenHandler from './useTreeOpenHandler';

export default function TreeView(props) {
  const {
    treeData = [],
    setTreeData = () => null,
    editConciergeItem,
    allCategories,
    reorderConcierge,
  } = props;
  const { ref, getPipeHeight, toggle } = useTreeOpenHandler();

  useEffect(() => {
    ref.current.openAll();
  });

  const handleDrop = (newTree, e) => {
    const { dragSourceId, dropTargetId, destinationIndex } = e;
    if (typeof dragSourceId === 'undefined' || typeof dropTargetId === 'undefined') {
      return;
    }
    const start = treeData.find((v) => v.id === dragSourceId);
    const end = treeData.find((v) => v.id === dropTargetId);

    const parentsForDropTarget = getParents(treeData, dropTargetId).filter(
      (item) => item?.data?.type === InfoType.Category
    );

    if (
      (end && end.data && end.data.type !== 'CATEGORY') ||
      (start &&
        start.data &&
        start.data.type === 'CATEGORY' &&
        end &&
        end.data &&
        end.data.type === 'CATEGORY' &&
        parentsForDropTarget.length)
    ) {
      return false;
    }

    if (start?.parent === dropTargetId && start && typeof destinationIndex === 'number') {
      setTreeData(newTree);
      reorderConcierge(newTree);
      ref.current.open(start.id);
      ref.current.open(end.id);
    }

    if (start?.parent !== dropTargetId && start && typeof destinationIndex === 'number') {
      if (
        getDescendants(treeData, dragSourceId).find((el) => el.id === dropTargetId) ||
        dropTargetId === dragSourceId ||
        (end && !end?.droppable)
      ) {
        return;
      }
      setTreeData(newTree);
      reorderConcierge(newTree);
      ref.current.open(start.id);
      ref.current.open(end.id);
    }
  };

  return (
    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
      <div className={styles.wrapper}>
        <Tree
          ref={ref}
          classes={{
            root: styles.treeRoot,
            placeholder: styles.placeholder,
            dropTarget: styles.dropTarget,
            listItem: styles.listItem,
          }}
          tree={treeData}
          sort={false}
          rootId={'0'}
          insertDroppableFirst={false}
          enableAnimateExpand={true}
          onDrop={handleDrop}
          canDrop={() => true}
          dropTargetOffset={5}
          initialOpen
          placeholderRender={(node, { depth }) => <Placeholder node={node} depth={depth} />}
          render={(node, { depth, isOpen, isDropTarget, handleRef }) => (
            <Node
              getPipeHeight={getPipeHeight}
              node={node}
              depth={depth}
              isOpen={isOpen}
              onClick={() => {}}
              isDropTarget={isDropTarget}
              treeData={treeData}
              handleRef={handleRef}
              editConciergeItem={editConciergeItem}
              allCategories={allCategories}
            />
          )}
        />
      </div>
    </DndProvider>
  );
}
