import { gql } from '../__generated__/gql';

const GET_ACCESS_GRANT = gql(`
  query AccessGrant($where: AccessGrantWhereUniqueInput!) {
    accessGrant(where: $where) {
      code
      confirmationCode
      credentials {
        data
        type
      }
      endsAt
      endDate
      endTime
      id
      locationId
      location {
        address
        id
        name
        phoneNumber
        timezone
      }
      metadata
      overrides {
        code
        endTime
        id
        resourceId
        startTime
      }
      requirements {
        type
        status
        metadata
        files {
          file {
            url
            fileType
          }
        }
      }
      resources {
        controlType
        defaultEnd
        defaultStart
        id
        locationId
        name
        passLevel
        pmsId
        type
        vendor
        vendorId
      }
      requirements {
        id
        type
        status
        metadata
      }
      retryCheckin
      secondaryUsers {
        user {
          id
          name
        }
      }
      startsAt
      startDate
      arrivalTime
      startTime
      status
      type
      user {
        email
        id
        lastSeenAt
        name
        phoneNumber
      }
    }
  }
`);

export default GET_ACCESS_GRANT;
