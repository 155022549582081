export const getTotalAdultsLabel = (adults?: number): string => {
  if (!adults || adults === 0 || adults > 1) {
    return 'Adults';
  }
  return 'Adult';
};

export const getTotalKidsLabel = (children?: number): string => {
  if (!children || children === 0 || children > 1) {
    return 'Kids';
  }
  return 'Kid';
};
