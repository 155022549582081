import 'styled-components/macro';
import * as React from 'react';
import { Colors } from '../../theme/colors';

interface SimpleTableProps {
  headers: string[];
  data: string[][];
}

const SimpleTable: React.FC<SimpleTableProps> = ({ data, headers }: SimpleTableProps) => {
  return (
    <table
      css={`
        border: 1px solid ${Colors.neroDark};
        border-collapse: collapse;

        th,
        td {
          border: 1px solid ${Colors.neroDark};
        }

        td {
          padding: 0 5px;
        }

        thead {
          background: ${Colors.whisper};
        }
      `}
    >
      <thead>
        {headers.map((header) => (
          <th key={`simple-table-head-${header}`}>{header}</th>
        ))}
      </thead>
      <tbody>
        {data.map((datum, index) => (
          <tr key={`simple-table-row-${index}`}>
            {datum.map((value) => (
              <td key={value}>{value}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SimpleTable;
