/* eslint-disable sort-keys */

import { JSONSchema7, JSONSchema7Type } from 'json-schema';

const androidCustomizableFields: JSONSchema7Type[] | undefined = [
  'CheckOutDate',
  'CheckOutTime',
  'CheckOutDateAndTime',
  'RoomAndFloor',
];

const iOSCustomizableFields: JSONSchema7Type[] | undefined = [
  ...androidCustomizableFields,
  'CheckInDate',
  'CheckInTime',
  'CheckInDateAndTime',
  'GuestName',
  'LocationName',
  'ConfirmationCode',
];

export const passInfoThemeCustomizationSchema: JSONSchema7 = {
  type: 'object',
  properties: {
    iosPass: {
      title: '#1 iOS pass',
      type: 'object',
      properties: {
        'logoUrl:enable': {
          title: '#1 Logo URL',
          type: 'boolean',
          description:
            'This image must have be a PNG file. This image must have width no large than 160px. [iosPass.logoUrl]',
        },
        logoUrl: {
          type: 'string',
        },
        'iconUrl:enable': {
          title: '#2 Icon URL',
          type: 'boolean',
          description:
            'This image must have be a PNG file. This image must have width and height large than 29px. [iosPass.iconUrl]',
        },
        iconUrl: {
          type: 'string',
        },
        'bannerUrl:enable': {
          title: '#3 Banner URL',
          type: 'boolean',
          description: 'This image must have be a PNG file. [iosPass.bannerUrl]',
        },
        bannerUrl: {
          type: 'string',
        },
        customizableFields: {
          type: 'object',
          title: '#3 iOS customizable fields',
          properties: {
            'topRight:enable': {
              title: '#3.1 Top right',
              description:
                'Field to be displayed on top right - Default: Check-in date [iosPass.customizableFields.topRight]',
              type: 'boolean',
            },
            topRight: {
              type: 'string',
              enum: iOSCustomizableFields,
            },
            'imageCenter:enable': {
              title: '#3.2 Image center',
              description:
                'Field to be displayed in the center of the image - Default: Empty [iosPass.customizableFields.imageCenter]',
              type: 'boolean',
            },
            imageCenter: {
              type: 'string',
              enum: iOSCustomizableFields,
            },
            'bottom:enable': {
              title: '#3.3 Bottom',
              description:
                'Fields to be displayed below the centered image - Maximum: 3 values - Default: Guest name / Location name / Confirmation code [iosPass.customizableFields.bottom]',
              type: 'boolean',
            },
            bottom: {
              type: 'array',
              items: {
                type: 'string',
                enum: iOSCustomizableFields,
              },
            },
            'details:enable': {
              title: '#3.4 Details',
              description: 'Fields to be displayed on details [iosPass.customizableFields.details]',
              type: 'boolean',
            },
            details: {
              type: 'array',
              items: {
                type: 'string',
                enum: iOSCustomizableFields,
              },
            },
          },
        },
      },
    },
    androidPass: {
      title: '#2 Android pass',
      type: 'object',
      properties: {
        'logoUrl:enable': {
          title: '#1 Logo URL',
          type: 'boolean',
          description: '[androidPass.logoUrl]',
        },
        logoUrl: {
          type: 'string',
        },
        'bannerUrl:enable': {
          title: '#2 Banner URL',
          type: 'boolean',
          description: '[androidPass.bannerUrl]',
        },
        bannerUrl: {
          type: 'string',
        },
        customizableFields: {
          type: 'object',
          title: '#3 Android customizable fields',
          properties: {
            'details:enable': {
              title: '#3.1 Details',
              description:
                'Fields to be displayed on details [androidPass.customizableFields.details]',
              type: 'boolean',
            },
            details: {
              type: 'array',
              items: {
                type: 'string',
                enum: androidCustomizableFields,
              },
            },
          },
        },
      },
    },
    general: {
      title: '#3 General',
      type: 'object',
      properties: {
        'displayRoomNumberBeforeCheckIn:enable': {
          title: '#1 Display room number before checkin',
          description:
            'Indicator whether the room number should be displayed even with a status different than IN_HOUSE [general.displayRoomNumberBeforeCheckIn]',
          type: 'boolean',
        },
        displayRoomNumberBeforeCheckIn: {
          type: undefined,
          enum: [false, true],
        },
      },
    },
  },
};

export const passInfoThemeCustomizationUiSchema = {
  iosPass: {
    'ui:title': '‎',
    logoUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    iconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    bannerUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    customizableFields: {
      topRight: {
        'ui:label': false,
        'ui:readonly': true,
      },
      imageCenter: {
        'ui:label': false,
        'ui:readonly': true,
      },
      bottom: {
        'ui:label': false,
        'ui:title': '‎',
        'ui:readonly': true,
        items: {
          'ui:title': '‎',
          'ui:label': false,
        },
      },
      details: {
        'ui:label': false,
        'ui:title': '‎',
        'ui:readonly': true,
        items: {
          'ui:title': '‎',
          'ui:label': false,
        },
      },
    },
  },
  androidPass: {
    'ui:title': '‎',
    logoUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    bannerUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    customizableFields: {
      details: {
        'ui:label': false,
        'ui:title': '‎',
        'ui:readonly': true,
        items: {
          'ui:title': '‎',
          'ui:label': false,
        },
      },
    },
  },
  general: {
    'ui:title': '‎',
    displayRoomNumberBeforeCheckIn: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
  },
};
