import { ApolloError, useMutation } from '@apollo/client';
import { Mutation, MutationExchangeRefreshTokenArgs } from '../__generated__/graphql';

import EXCHANGE_REFRESH_TOKEN from '../graphql/exchangeRefreshToken';
import { flashApolloError } from '../util/errorUtils';
import { receiveTokens } from '../redux/actions/auth.actions';
import { useDispatch } from 'react-redux';
import useLogoutOfApp from './useLogoutOfApp';

type ExchangeRefreshTokenData = Pick<Mutation, 'exchangeRefreshToken'>;

export default function useRefreshToken(): {
  data?: ExchangeRefreshTokenData | null;
  error?: ApolloError;
  loading: boolean;
  attemptTokenRefresh: () => void;
} {
  const dispatch = useDispatch();
  const logout = useLogoutOfApp();

  const [refresh, { data, error, loading }] = useMutation<
    ExchangeRefreshTokenData,
    MutationExchangeRefreshTokenArgs
  >(EXCHANGE_REFRESH_TOKEN, {
    context: {
      sendAccessToken: false,
    },
    onCompleted: ({ exchangeRefreshToken }: ExchangeRefreshTokenData): void => {
      const { accessToken, expiresIn, refreshToken } = exchangeRefreshToken;
      if (accessToken && refreshToken) {
        localStorage.setItem('ACCESS_TOKEN', accessToken);
        localStorage.setItem('REFRESH_TOKEN', refreshToken);

        if (expiresIn) {
          const expirationDate = new Date(Date.now() + Number(expiresIn) * 1000);
          localStorage.setItem('TOKEN_EXPIRY', expirationDate.toJSON());
        }

        dispatch(
          receiveTokens({
            accessToken,
            refreshToken,
          })
        );
      }
    },
    onError: (e: ApolloError) => {
      flashApolloError(e);
      logout();
    },
  });

  return {
    attemptTokenRefresh: (): void => {
      const refreshToken = localStorage.getItem('REFRESH_TOKEN');

      if (refreshToken && !loading) {
        refresh({ variables: { token: refreshToken } });
      } else if (!refreshToken) {
        logout();
      }
    },
    data,
    error,
    loading,
  };
}
