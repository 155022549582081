import { Colors } from '../../../../../theme/colors';
import Theme from '../../../../../theme';
import styled from 'styled-components';

const Input = styled.input`
  background: transparent;
  border: 0;
  box-shadow: unset;
  color: ${Theme.colors.white.hexCode};
  display: inline;
  font-family: 'San Francisco Pro';
  font-size: ${Theme.fontStyles.bodyLarge.size};
  max-width: 105px;
  padding: 0 10px;
  outline: 0;

  &:-internal-autofill-selected {
    background: transparent !important;
  }

  &::placeholder {
    color: ${Colors.shuttleGrey};
    font-weight: bold;
  }

  @media only screen and (max-width: 1450px) {
    font-size: ${Theme.fontStyles.bodySmall.size};

    &::placeholder {
      color: ${Colors.shuttleGrey};
      font-size: 10px;
      font-weight: bold;
    }
  }

  @media only screen and (max-width: 1450px) {
    font-size: ${Theme.fontStyles.bodySmall.size};

    &::placeholder {
      color: ${Colors.shuttleGrey};
      font-size: 10px;
      font-weight: bold;
    }
  }
`;

export default Input;
