import Theme from '../../../theme';

const DataTableStyle = `
  background: ${Theme.colors.darkAlternate.hexCode};
  border-radius: 24px;
  grid-auto-rows: auto;
  grid-template-rows: repeat(2, max-content);
  min-height: 800px;
  padding: 30px 30px 35px;
`;

export default DataTableStyle;
