/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3ThemeAccessManagementSchema: NodeSchemaProperties = {
  accessManagement: {
    title: '#13 Access Management',
    type: 'object',
    properties: {
      digitalKey: {
        title: '#1 Digital Key',
        type: 'object',
        properties: {
          'image:enable': {
            description: '[accessManagement.digitalKey.image]',
            title: '#1.1 Image',
            type: 'boolean',
          },
          image: {
            type: 'string',
          },
          'imageOnUnlocking:enable': {
            description: '[accessManagement.digitalKey.imageOnUnlocking]',
            title: '#1.2 Image On Unlocking',
            type: 'boolean',
          },
          imageOnUnlocking: {
            type: 'string',
          },
          'imageOnUnlocked:enable': {
            description: '[accessManagement.digitalKey.imageOnUnlocked]',
            title: '#1.3 Image On Unlocked',
            type: 'boolean',
          },
          imageOnUnlocked: {
            type: 'string',
          },
          pulseAnimation: {
            title: '#1.4 Pulse Animation',
            type: 'object',
            properties: {
              'pulseAnimationBackgroundColor:enable': {
                description:
                  '[accessManagement.digitalKey.pulseAnimation.pulseAnimationBackgroundColor]',
                title: '#1.4.1 Pulse Animation Background Color',
                type: 'boolean',
              },
              pulseAnimationBackgroundColor: {
                type: 'string',
              },
              'pulseAnimationSpeed:enable': {
                description: '[accessManagement.digitalKey.pulseAnimation.pulseAnimationSpeed]',
                title: '#1.4.2 Pulse Animation Speed',
                type: 'boolean',
              },
              pulseAnimationSpeed: {
                type: 'integer',
              },
              'pulseAnimationNumberOfPulses:enable': {
                description:
                  '[accessManagement.digitalKey.pulseAnimation.pulseAnimationNumberOfPulses]',
                title: '#1.4.3 Pulse Animation Number Of Pulses',
                type: 'boolean',
              },
              pulseAnimationNumberOfPulses: {
                type: 'integer',
              },
              'pulseAnimationDiameter:enable': {
                description: '[accessManagement.digitalKey.pulseAnimation.pulseAnimationDiameter]',
                title: '#1.4.4 Pulse Animation Diameter',
                type: 'boolean',
              },
              pulseAnimationDiameter: {
                type: 'integer',
              },
              'pulseAnimationDuration:enable': {
                description: '[accessManagement.digitalKey.pulseAnimation.pulseAnimationDuration]',
                title: '#1.4.5 Pulse Animation Duration',
                type: 'boolean',
              },
              pulseAnimationDuration: {
                type: 'integer',
              },
            },
          },
          'accessIconUrl:enable': {
            description: '[accessManagement.digitalKey.accessIconUrl]',
            title: '#1.5 Image for access icon in key card',
            type: 'boolean',
          },
          accessIconUrl: {
            type: 'string',
          },
          'errorImage:enable': {
            description: '[accessManagement.digitalKey.errorImage]',
            title: '#1.6 Image for error image in key card',
            type: 'boolean',
          },
          errorImage: {
            type: 'string',
          },
          'vibrateAfterUnlock:enable': {
            description: '[accessManagement.digitalKey.vibrateAfterUnlock]',
            title: '#1.7 Enable vibration after unlock',
            type: 'boolean',
          },
          vibrateAfterUnlock: {
            type: undefined,
            enum: [true, false],
          },
        },
      },
      entryScreen: {
        title: '#2 Entry Screen',
        type: 'object',
        properties: {
          'unlockingImage:enable': {
            description: '[accessManagement.entryScreen.unlockingImage]',
            title: '#2.1 Unlocking Image',
            type: 'boolean',
          },
          unlockingImage: {
            type: 'string',
          },
          'unlockedImage:enable': {
            description: '[accessManagement.entryScreen.unlockedImage]',
            title: '#2.2 Unlocked Image',
            type: 'boolean',
          },
          unlockedImage: {
            type: 'string',
          },
          'openLockerButtonColor:enable': {
            description: '[accessManagement.entryScreen.openLockerButtonColor]',
            title: '#2.3 Open Locker Button Color',
            type: 'boolean',
          },
          openLockerButtonColor: {
            type: 'string',
          },
          'openLockerButtonBackgroundColor:enable': {
            description: '[accessManagement.entryScreen.openLockerButtonBackgroundColor]',
            title: '#2.4 Open Locker Button Background Color',
            type: 'boolean',
          },
          openLockerButtonBackgroundColor: {
            type: 'string',
          },
          'openLockerButtonBorderRadius:enable': {
            description: '[accessManagement.entryScreen.openLockerButtonBorderRadius]',
            title: '#2.5 Open Locker Button Border Radius',
            type: 'boolean',
          },
          openLockerButtonBorderRadius: {
            type: 'integer',
          },
          'goBackTimeAfterSuccess:enable': {
            description:
              'The milliseconds amount to close the UI after the success. [accessManagement.entryScreen.goBackTimeAfterSuccess]',
            title: '#2.6 Go Back Time After Success',
            type: 'boolean',
          },
          goBackTimeAfterSuccess: {
            type: 'integer',
          },
          frontDesk: {
            title: '#2.7 Front Desk',
            type: 'object',
            properties: {
              'buttonBackgroundColor:enable': {
                description: '[accessManagement.entryScreen.frontDesk.buttonBackgroundColor]',
                title: '#2.7.1 Button Background Color',
                type: 'boolean',
              },
              buttonBackgroundColor: {
                type: 'string',
              },
              'buttonBorderColor:enable': {
                description: '[accessManagement.entryScreen.frontDesk.buttonBorderColor]',
                title: '#2.7.2 Button Border Color',
                type: 'boolean',
              },
              buttonBorderColor: {
                type: 'string',
              },
              'buttonTextColor:enable': {
                description: '[accessManagement.entryScreen.frontDesk.buttonTextColor]',
                title: '#2.7.3 Button Text Color',
                type: 'boolean',
              },
              buttonTextColor: {
                type: 'string',
              },
              'buttonBorderRadius:enable': {
                description: '[accessManagement.entryScreen.frontDesk.buttonBorderRadius]',
                title: '#2.7.4 Button Border Radius',
                type: 'boolean',
              },
              buttonBorderRadius: {
                type: 'integer',
              },
              'frontDeskNumber:enable': {
                description: '[accessManagement.entryScreen.frontDesk.frontDeskNumber]',
                title: '#2.7.5 Front Desk Number',
                type: 'boolean',
              },
              frontDeskNumber: {
                type: 'string',
              },
              'frontDeskCtaThreshold:enable': {
                description:
                  'Number of errors to show front desk cta [accessManagement.entryScreen.frontDesk.frontDeskCtaThreshold]',
                title: '#2.7.6 Front Desk Cta Threshold',
                type: 'boolean',
              },
              frontDeskCtaThreshold: {
                type: 'integer',
              },
            },
          },
          'openLockerButtonDisabledColor:enable': {
            description: '[accessManagement.entryScreen.openLockerButtonDisabledColor]',
            title: '#2.8 Open Locker Button Disabled Color',
            type: 'boolean',
          },
          openLockerButtonDisabledColor: {
            type: 'string',
          },
          'openLockerButtonDisabledTextColor:enable': {
            description: '[accessManagement.entryScreen.openLockerButtonDisabledTextColor]',
            title: '#2.9 Open Locker Button Disabled Text Color',
            type: 'boolean',
          },
          openLockerButtonDisabledTextColor: {
            type: 'string',
          },
        },
      },
      tutorial: {
        title: '#3 Tutorial',
        type: 'object',
        properties: {
          showMeHow: {
            title: '#3.1 Show Me How',
            type: 'object',
            properties: {
              'steps:enable': {
                title: '#3.1.1 Steps',
                type: 'boolean',
                description: '[accessManagement.tutorial.showMeHow.steps]',
              },
              steps: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    index: {
                      description:
                        'Index of the step, will be used to sort the steps [accessManagement.tutorial.showMeHow.steps.index]',
                      title: '#3.1.1.1 Index',
                      type: 'integer',
                    },
                    image: {
                      description:
                        'Presentation Image of the step [accessManagement.tutorial.showMeHow.steps.image]',
                      title: '#3.1.1.2 Image',
                      type: 'string',
                    },
                    title: {
                      title: '#3.1.1.3 Title',
                      description: '[accessManagement.tutorial.showMeHow.steps.title]',
                      type: 'object',
                      properties: {
                        text: {
                          description: '[accessManagement.tutorial.showMeHow.steps.title.text]',
                          title: '#3.1.1.3.1 Text',
                          type: 'string',
                        },
                        type: {
                          description: '[accessManagement.tutorial.showMeHow.steps.title.type]',
                          title: '#3.1.1.3.2 Type',
                          type: 'string',
                        },
                        useDefaultStyles: {
                          description:
                            '[accessManagement.tutorial.showMeHow.steps.title.useDefaultStyles]',
                          title: '#3.1.1.3.3 Use Default Styles',
                          type: undefined,
                          enum: [true, false],
                        },
                      },
                    },
                    description: {
                      title: '#3.1.1.4 Description',
                      type: 'object',
                      properties: {
                        text: {
                          description:
                            '[accessManagement.tutorial.showMeHow.steps.description.text]',
                          title: '#3.1.1.4.1 Text',
                          type: 'string',
                        },
                        type: {
                          description:
                            '[accessManagement.tutorial.showMeHow.steps.description.type]',
                          title: '#3.1.1.4.2 Type',
                          type: 'string',
                        },
                        useDefaultStyles: {
                          description:
                            '[accessManagement.tutorial.showMeHow.steps.description.useDefaultStyles]',
                          title: '#3.1.1.4.3 Use Default Styles',
                          type: undefined,
                          enum: [true, false],
                        },
                      },
                    },
                    imageSizes: {
                      title: '#3.1.1.5 Image Sizes',
                      type: 'object',
                      properties: {
                        width: {
                          description:
                            '[accessManagement.tutorial.showMeHow.steps.imageSizes.width]',
                          title: '#3.1.1.5.1 Width',
                          type: 'integer',
                        },
                        height: {
                          description:
                            '[accessManagement.tutorial.showMeHow.steps.imageSizes.height]',
                          title: '#3.1.1.5.2 Height',
                          type: 'integer',
                        },
                      },
                    },
                  },
                },
              },
              'buttonColor:enable': {
                description: '[accessManagement.tutorial.showMeHow.buttonColor]',
                title: '#3.1.2 Button Color',
                type: 'boolean',
              },
              buttonColor: {
                type: 'string',
              },
              'buttonBackgroundColor:enable': {
                description: '[accessManagement.tutorial.showMeHow.buttonBackgroundColor]',
                title: '#3.1.3 Button Background Color',
                type: 'boolean',
              },
              buttonBackgroundColor: {
                type: 'string',
              },
              'buttonBorderRadius:enable': {
                description: '[accessManagement.tutorial.showMeHow.buttonBorderRadius]',
                title: '#3.1.4 Button Border Radius',
                type: 'boolean',
              },
              buttonBorderRadius: {
                type: 'integer',
              },
            },
          },
          stepDots: {
            title: '#3.2 Step Dots',
            type: 'object',
            properties: {
              'color:enable': {
                description: '[accessManagement.tutorial.stepDots.color]',
                title: '#3.2.1 Color',
                type: 'boolean',
              },
              color: {
                type: 'string',
              },
              'selectedColor:enable': {
                description: '[accessManagement.tutorial.stepDots.selectedColor]',
                title: '#3.2.2 Selected Color',
                type: 'boolean',
              },
              selectedColor: {
                type: 'string',
              },
              'size:enable': {
                description: '[accessManagement.tutorial.stepDots.size]',
                title: '#3.2.3 Size',
                type: 'boolean',
              },
              size: {
                type: 'integer',
              },
              'gab:enable': {
                description: '[accessManagement.tutorial.stepDots.gab]',
                title: '#3.2.4 Gab',
                type: 'boolean',
              },
              gab: {
                type: 'integer',
              },
            },
          },
          'replayStepsButtonTextColor:enable': {
            description: '[accessManagement.tutorial.replayStepsButtonTextColor]',
            title: '#3.3 Replay Steps Button Text Color',
            type: 'boolean',
          },
          replayStepsButtonTextColor: {
            type: 'string',
          },
        },
      },
      errorMessage: {
        title: '#4 Error Message',
        type: 'object',
        properties: {
          'errorContainerBackgroundColor:enable': {
            description: '[accessManagement.errorMessage.errorContainerBackgroundColor]',
            title: '#4.1 Error Container Background Color',
            type: 'boolean',
          },
          errorContainerBackgroundColor: {
            type: 'string',
          },
          'errorContainerTextColor:enable': {
            description: '[accessManagement.errorMessage.errorContainerTextColor]',
            title: '#4.2 Error Container Text Color',
            type: 'boolean',
          },
          errorContainerTextColor: {
            type: 'string',
          },
          'errorContainerIcon:enable': {
            description: '[accessManagement.errorMessage.errorContainerIcon]',
            title: '#4.3 Error Container Icon',
            type: 'boolean',
          },
          errorContainerIcon: {
            type: 'string',
          },
          'errorContainerTitleColor:enable': {
            description: '[accessManagement.errorMessage.errorContainerTitleColor]',
            title: '#4.4 Error Container Title Color',
            type: 'boolean',
          },
          errorContainerTitleColor: {
            type: 'string',
          },
        },
      },
    },
  },
};

export const mobileV3ThemeAccessManagementUiSchema: NodeUiSchemaProperties = {
  accessManagement: {
    digitalKey: {
      image: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
      imageOnUnlocking: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
      imageOnUnlocked: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
      pulseAnimation: {
        pulseAnimationBackgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        pulseAnimationSpeed: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'updown',
        },
        pulseAnimationNumberOfPulses: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'updown',
        },
        pulseAnimationDiameter: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'updown',
        },
        pulseAnimationDuration: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'updown',
        },
      },
      accessIconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
      errorImage: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
      vibrateAfterUnlock: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
    },
    entryScreen: {
      unlockingImage: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
      unlockedImage: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
      openLockerButtonColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      openLockerButtonBackgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      openLockerButtonDisabledColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      openLockerButtonDisabledTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      openLockerButtonBorderRadius: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
      goBackTimeAfterSuccess: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
      frontDesk: {
        buttonBackgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        buttonBorderColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        buttonTextColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        buttonBorderRadius: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'updown',
        },
        frontDeskNumber: {
          'ui:label': false,
          'ui:readonly': true,
        },
        frontDeskCtaThreshold: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'updown',
        },
      },
    },
    tutorial: {
      showMeHow: {
        steps: {
          'ui:label': false,
          'ui:title': '‎',
          'ui:readonly': true,
          items: {
            'ui:label': false,
            index: {
              'ui:widget': 'updown',
            },
            image: {
              'ui:fieldtype': 'link',
              'ui:widget': 'uri',
            },
            title: {
              text: {},
              type: {},
              useDefaultStyles: {
                'ui:widget': 'select',
              },
            },
            description: {
              text: {},
              type: {},
              useDefaultStyles: {
                'ui:widget': 'select',
              },
            },
            imageSizes: {
              width: {
                'ui:widget': 'updown',
              },
              height: {
                'ui:widget': 'updown',
              },
            },
          },
        },
        buttonColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        buttonBackgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        buttonBorderRadius: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'updown',
        },
      },
      stepDots: {
        color: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        selectedColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        size: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'updown',
        },
        gab: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'updown',
        },
      },
      replayStepsButtonTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    errorMessage: {
      errorContainerBackgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      errorContainerTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      errorContainerIcon: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
      errorContainerTitleColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
  },
};
