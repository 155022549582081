import 'styled-components/macro';

import { Colors, RGB } from '../../../../theme/colors';
import Dropdown, { ReferenceDropdownInterface } from '../../../Dropdown';
import React, { createRef, useEffect, useRef } from 'react';
import {
  clearFilter,
  clearSortOptions,
  setFilter,
} from '../../../../redux/actions/dataTable.actions';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../Button';
import ButtonModel from '../../../Button/enums/ButtonModel.enum';
import Container from '../../../Container';
import Filter from '../../@types/Filter';
import FiltersForm from './components/FiltersForm';
import Label from '../../../Label';
import { RootState } from '../../../../redux/reducers';
import checkIfFilterIsDefault from './functions/checkIfFilterIsDefault';
import getDefaultFilters from './functions/getDefaultFilters';
import groupBy from 'lodash/groupBy';

interface FiltersProps {
  colorMode?: 'DARK' | 'LIGHT';
  dataTableKey?: string;
  filters?: Filter[];
}

const Filters: React.FC<FiltersProps> = ({
  colorMode = 'DARK',
  dataTableKey = '',
  filters = [],
}: FiltersProps) => {
  const groupedFilters = groupBy(filters, 'key');
  const groups = Object.keys(groupedFilters);

  const dispatch = useDispatch();

  const fieldsFiltered = useSelector((state: RootState) => state.dataTable.filters);
  const filterIsDefault = checkIfFilterIsDefault(filters, fieldsFiltered[dataTableKey]);

  const sortOptions = useSelector((state: RootState) => state.dataTable.sortOptions);
  const hasSortOptions = Boolean(sortOptions[dataTableKey] && sortOptions[dataTableKey].length > 0);

  const refs = useRef([]);
  refs.current = Object.keys(groups).map(
    (__, iCount) => refs.current[iCount] ?? createRef<React.ReactElement>()
  );
  useEffect(() => {
    refs.current = refs.current.slice(0, groups.length);
  }, [groups]);

  useEffect(() => {
    return (): void => {
      dispatch(clearFilter(dataTableKey));
    };
  }, [dispatch, dataTableKey]);

  const setDefaultFilter = (): void => {
    dispatch(clearFilter(dataTableKey));

    const defaultFilterValues = getDefaultFilters(filters);

    defaultFilterValues.forEach((defaultFilterValue) => {
      const { fieldKey, values } = defaultFilterValue;
      dispatch(
        setFilter(dataTableKey, {
          fieldKey,
          values,
        })
      );
    });
  };

  useEffect(() => {
    setDefaultFilter();
  }, []);

  if (!filters.length || !dataTableKey) {
    return <React.Fragment />;
  }

  const getRef = (key: string): React.MutableRefObject<unknown> => {
    return refs.current[groups.indexOf(key)];
  };

  return (
    <Container
      css={`
        align-content: center;
        border: 1px solid rgba(${RGB.shuttleGrey}, 0.4);
        border-radius: 12px;
        grid-auto-columns: max-content;
        grid-auto-flow: column;
        grid-column-gap: 5px;
        min-width: 200px;
        padding: 25px 15px 25px 15px;
        position: relative;
        width: max-content;
      `}
    >
      <Label
        css={`
          background: ${colorMode === 'DARK' ? Colors.neroDark : Colors.white};
          color: ${colorMode === 'DARK' ? Colors.whisper : `rgb(${RGB.shuttleGrey})`};
          font-size: 14px;
          font-weight: bold;
          position: absolute;
          left: 18px;
          padding: 10px;
          top: -20px;
          z-index: 5;
        `}
      >
        Filters
      </Label>
      {groups.map((group) => (
        <Dropdown
          label={groupedFilters[group][0].displayKey}
          key={`filter-${group}`}
          ref={getRef(group)}
        >
          <FiltersForm
            dataTableKey={dataTableKey}
            filterName={group}
            filters={groupedFilters[group]}
            closeDropdown={(): void => {
              const ref = getRef(group);
              if (ref) {
                const dropdownRef = ref.current as ReferenceDropdownInterface;
                dropdownRef.close();
              }
            }}
          />
        </Dropdown>
      ))}
      {groups.length && (filterIsDefault || hasSortOptions) && (
        <Button
          css={`
            background: ${Colors.whisper};
            border-radius: 6px;
            color: ${Colors.shuttleGrey};
            font-size: 12px;
            padding: 0 10px;

            &:disabled {
              background: rgba(${RGB.linkWater}, 0.7);
              border-radius: 6px;
              color: ${Colors.shuttleGrey};
              font-size: 12px;
              padding: 0 10px;
            }

            &:hover:not(:disabled) {
              background: rgba(${RGB.whisper}, 0.95);
              color: ${Colors.blackRussian};
            }
          `}
          disabled={!(filterIsDefault || hasSortOptions)}
          model={ButtonModel.DEFAULT}
          onClick={(): void => {
            setDefaultFilter();
            dispatch(clearSortOptions(dataTableKey));
          }}
        >
          Reset
        </Button>
      )}
    </Container>
  );
};

export default Filters;
