export const ringing = new Audio('./phone-ringing.mp3');

export async function ring(): Promise<void> {
  try {
    if (ringing.paused) {
      await ringing.play();
    }
  } catch (e) {
    ringing.pause();
  }
}

export function stopRinging(): void {
  if (!ringing.paused) {
    ringing.pause();
    ringing.currentTime = 0;
    ringing.volume = 1;
  }
}

export async function ringOnceAndLower(): Promise<void> {
  ringing.volume = 0.2;
  await ring();
  setTimeout(stopRinging, 2000);
}
