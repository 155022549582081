import DataTablePage from '../../templates/DataTablePage/DataTablePage';
import React from 'react';
import useAccessGrantsTableData from '../../hooks/useAccessGrantsTableData';

const AccessGrantsPage: React.FunctionComponent<{}> = () => {
  const tableData = useAccessGrantsTableData({ verifyIfLocationsSupportsPollReservation: true });
  return <DataTablePage bannerText="Access Grants" {...tableData} />;
};

export default AccessGrantsPage;
