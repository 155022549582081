import { gql } from '../../../__generated__/gql';

const GET_TENANT_PAGE_INFO = gql(`
  query GetTenantPageInfo($where: TenantWhereUniqueInput!) {
    tenant(where: $where) {
      id
      name
    }
  }
`);

export default GET_TENANT_PAGE_INFO;
