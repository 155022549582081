import { Mutation, MutationDeleteOneVendorServerArgs } from '../../../__generated__/graphql';

import DELETE_VENDOR_SERVER from '../../../graphql/deleteVendorServer';
import RemoveHookData from '../../@types/RemoveHookData';
import { closeRemoveModal } from '../../../redux/actions/modals.actions';
import { flashApolloError } from '../../../util/errorUtils';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

type DeleteVendorServerData = Pick<Mutation, 'deleteOneVendorServer'>;

export default function useRemoveVendorServer(): RemoveHookData {
  const dispatch = useDispatch();
  const history = useHistory();

  const [remove, { error, loading }] = useMutation<
    DeleteVendorServerData,
    MutationDeleteOneVendorServerArgs
  >(DELETE_VENDOR_SERVER, {
    onCompleted: ({ deleteOneVendorServer }) => {
      dispatch(closeRemoveModal());

      if (deleteOneVendorServer) {
        window.flash({
          message: `Vendor server: ${deleteOneVendorServer.vendor} removed successfully`,
        });
      } else {
        window.flash({ message: 'Fail to remove vendor server' });
      }

      history.go(0);
    },
    onError: flashApolloError,
  });

  return {
    error,
    loading,
    mutation: (id: string): void => {
      remove({
        variables: {
          where: { id },
        },
      });
    },
  };
}
