import { AmenityWhereInput } from '../../../__generated__/graphql';
import SearchField from '../../../@types/SearchField';
import createSearchField from '../../../util/tableData/createSearchField';
import transformStringFilter from '../../../util/tableData/transformStringFilter';
import transformWhereRelationFilterInput from '../../../util/tableData/transformWhereRelationFilterInput';

const AMENITIES_TABLE_SEARCH_FIELDS: SearchField<AmenityWhereInput>[] = [
  createSearchField<AmenityWhereInput, 'title'>({
    label: 'Title',
    queryField: 'title',
    transform: transformStringFilter,
  }),
  createSearchField<AmenityWhereInput, 'description'>({
    label: 'Description',
    queryField: 'description',
    transform: transformStringFilter,
  }),
  createSearchField<AmenityWhereInput, 'group'>({
    label: 'Group',
    queryField: 'group',
    transform: (key: string) => transformWhereRelationFilterInput(key, 'title'),
  }),
];

export default AMENITIES_TABLE_SEARCH_FIELDS;
