import GET_INFO_TYPES from '../graphql/getInfoItemTypes';
import { Query } from '../__generated__/graphql';
import SelectOption from '../@types/react/SelectOption';
import { useApolloClient } from '@apollo/client';

type GetInfoTypeData = Pick<Query, 'infoTypes'>;

export default function useLoadInfoTypeOptions(): (input: string) => Promise<SelectOption[]> {
  const client = useApolloClient();

  return async (): Promise<SelectOption[]> => {
    const { data } = await client.query<GetInfoTypeData>({
      query: GET_INFO_TYPES,
    });

    const filteredData = data?.infoTypes?.filter((type) => type.shouldAppearInDashboard);

    return (
      filteredData?.map((infoType) => ({
        label: infoType.title,
        value: infoType.id,
      })) ?? []
    );
  };
}
