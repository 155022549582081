import { Location, Mutation, ResourceVendor } from '../../__generated__/graphql';
import React, { useEffect } from 'react';

import FormTemplate from '../../templates/Form';
import VENDOR_SERVER_FORM_STEPS from './constants/vendorServerFormSteps';
import VendorServerFormInputs from './@types/VendorServerFormInputs';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import useGetVendorServerById from '../../hooks/useGetVendorServerById';

interface VendorServerAditionalDataOnOpeningFormInterface {
  location: Pick<Location, 'id' | 'name'>;
}

interface VendorServerFormProps {
  id?: string;
}

type CreateVendorServerData = Pick<Mutation, 'createOneVendorServer'>;

const VendorServerForm: React.FC<VendorServerFormProps> = ({ id }: VendorServerFormProps) => {
  const { called, data: vendorServer, query } = useGetVendorServerById();

  useEffect(() => {
    if (id && !called) {
      query(id);
    }
  });

  if (id && !vendorServer) {
    return <React.Fragment />;
  }

  const getInitialValues = (): Partial<VendorServerFormInputs> => {
    if (vendorServer) {
      let initialValues = {
        ...pick(vendorServer, [
          'vendor',
          'host',
          'port',
          'isActive',
          'password',
          'shouldAllJoin',
          'username',
          'lockServiceCode',
          'trailerBlock',
          'accessID',
          'uuid',
          'assaCardType',
          'additionalConfig',
        ]),
      };

      if (
        [ResourceVendor.Onity, ResourceVendor.Openpath].includes(initialValues.vendor) &&
        initialValues.additionalConfig
      ) {
        const vendorAdditionalConfig = initialValues.additionalConfig;

        initialValues = {
          ...initialValues,
          ...vendorAdditionalConfig,
        };
      }

      return omit(initialValues, ['additionalConfig']);
    }

    const defaultInitialValues: Partial<VendorServerFormInputs> = {
      isActive: true,
      shouldAllJoin: true,
    };

    return defaultInitialValues;
  };

  return (
    <FormTemplate<VendorServerFormInputs, CreateVendorServerData>
      formHeader={'Vendor Server'}
      identifier={id}
      steps={VENDOR_SERVER_FORM_STEPS}
      initialValues={getInitialValues()}
    />
  );
};

export default VendorServerForm;
