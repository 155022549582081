import { Datum } from '../../../components/DataTable';
import { Query } from '../../../__generated__/graphql';
import capitalize from 'lodash/capitalize';
import moment from 'moment';

type GetPaymentTransactionTableData = Pick<Query, 'paymentTransactions'>;

export default function generatePaymentTransactionsTableData(
  data?: GetPaymentTransactionTableData
): Datum[] {
  const tableData =
    data?.paymentTransactions.map((paymentTransaction) => ({
      dataType: 'PaymentTransaction',
      fields: [
        {
          key: 'invoiceNumber',
          value: paymentTransaction.invoiceNumber,
        },
        {
          key: 'amount',
          value: `$${paymentTransaction.amount}`,
        },
        {
          key: 'status',
          value: capitalize(paymentTransaction.status),
        },
        {
          key: 'createdAt',
          value: moment(paymentTransaction.createdAt).format('lll'),
        },
      ],
      key: paymentTransaction.id,
    })) || [];

  return tableData;
}
