import 'styled-components/macro';
import { Bars } from 'svg-loaders-react';
import { Colors } from '../../../../theme/colors';
import Container from '../../../Container';
import React from 'react';
import SearchResultsDropdownProps from './@types/SearchResultsDropdownProps';
import SearchResultsDropdownStyle from './styles/SearchResultsDropdown.style';
import SearchResultsItemStyle from './styles/SearchResultsItem.style';
import SearchResultsItemsListStyle from './styles/SearchResultsItemsList.style';
import SearchResultsSpinnerContainerStyle from './styles/SearchResultsSpinnerContainer.style';

const SearchResultsDropdown: React.FC<SearchResultsDropdownProps> = ({
  isLoading,
  items,
  onSelect,
}: SearchResultsDropdownProps) => {
  return (
    <Container className="search-results-dropdown" css={SearchResultsDropdownStyle}>
      {isLoading && (
        <Container css={SearchResultsSpinnerContainerStyle}>
          <Bars fill={Colors.blackRussian} height="16px" />
        </Container>
      )}
      {!isLoading && (
        <Container as="ol" css={SearchResultsItemsListStyle}>
          {items.map((item) => {
            return (
              <Container
                as="li"
                css={SearchResultsItemStyle}
                data-identifier={item.id}
                key={`item-results-dropdown-${item.id}`}
                onClick={onSelect}
                tabIndex={-1}
              >
                {item.name}
              </Container>
            );
          })}
        </Container>
      )}
    </Container>
  );
};

export default SearchResultsDropdown;
