import { ImageTypeEnum } from '../../UnitTypeForm/enums/ImageTypeEnum.enum';
import InputGroupProps from '../../InputGroup/@types/InputGroupProps';
import { MAX_IMAGE_SIZE } from '../../../util/fileUtil';
import { ResourceType } from '../../../__generated__/graphql';
import useLoadLocationOptions from '../../../hooks/useLoadLocationOptions';
import useLoadUnitTypeOptions from '../../../hooks/useLoadUnitTypeOptions';
import useSearchLocationsByName from '../../../hooks/useSearchLocations';
import useSearchUnitTypeByType from '../../../hooks/useSearchUnitType';
import values from 'lodash/values';

interface ResourceFormInputProps {
  onSelectLocation: () => void;
  imageType: string;
}

export default function generateResourceSectionInput({
  onSelectLocation,
  imageType,
}: ResourceFormInputProps): InputGroupProps[] {
  let resourceSectionInput: InputGroupProps[] = [
    {
      label: 'Name',
      name: 'name',
      type: 'text',
    },
    {
      label: 'Description',
      name: 'description',
      required: false,
      type: 'text',
    },
    {
      label: 'Image type',
      name: 'imageType',
      options: Object.values(ImageTypeEnum),
      type: 'select',
    },
  ];

  if (imageType === ImageTypeEnum.URL) {
    resourceSectionInput.push({
      label: 'Image URL',
      name: 'imageUrl',
      required: true,
      type: 'url',
    });
  } else if (imageType === ImageTypeEnum.UPLOAD) {
    resourceSectionInput.push({
      accept: 'image/*',
      label: 'Image file',
      maxFileSize: MAX_IMAGE_SIZE,
      name: `imageFile`,
      required: true,
      type: 'file',
    });
  }

  resourceSectionInput = [
    ...resourceSectionInput,
    {
      label: 'Search Location',
      loadOptions: useLoadLocationOptions,
      name: 'location',
      onSelect: onSelectLocation,
      query: useSearchLocationsByName,
      type: 'async-select',
    },
    {
      label: 'Type',
      name: 'type',
      options: values(ResourceType),
      type: 'select',
    },
    {
      label: 'Search Unit Type',
      loadOptions: useLoadUnitTypeOptions,
      name: 'unitType',
      query: useSearchUnitTypeByType,
      required: false,
      type: 'async-select',
    },
    {
      label: 'Building',
      name: 'building',
      required: false,
      type: 'text',
    },
    {
      label: 'Floor',
      name: 'floor',
      required: false,
      type: 'text',
    },
    {
      label: 'Component Room',
      name: 'isComponentRoom',
      required: false,
      type: 'checkbox',
    },
  ];

  return resourceSectionInput;
}
