import AmenityFormInputs from '../@types/AmenityFormInputs';
import AmenitySection from '../components/AmenitySection';
import FormTemplateStep from '../../../templates/Form/@types/FormTemplateStep';
import { Mutation } from '../../../__generated__/graphql';

type CreateAmenityData = Pick<Mutation, 'createAmenityAndUploadImageFile'>;

const AMENITY_FORM_STEPS: FormTemplateStep<AmenityFormInputs, CreateAmenityData>[] = [
  {
    canBeAccessed: (): boolean => true,
    component: AmenitySection,
    name: 'Amenity',
  },
];

export default AMENITY_FORM_STEPS;
