import { Colors, RGB } from '../../../theme/colors';

const PhoneInputContainerStyle = `
  .intl-tel-input {
    input {
      background: rgba(${RGB.linkWater}, .2);
      border-color: rgba(${RGB.linkWater}, .2);
      border-style: solid;
      border-width: 2px;
      box-shadow: unset;
      box-sizing: border-box;
      border-radius: 8px;
      font-size: 16px;
      font-weight: bold;
      height: 40px;
      outline: 0;
      padding: 5px 10px;
      width: 100%;
    
      &:focus:not(.error) {
        border-color: ${Colors.mountainMeadow};
        outline: 0;
      }
    }
  }

  &.error {
    .intl-tel-input {
      input {
        border-color: ${Colors.salmon} !important;
      }
    }
  }
`;

export default PhoneInputContainerStyle;
