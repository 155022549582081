import { KiosksLiveUsageAction } from '../../actions/kiosksLiveUsage.actions';
import KiosksLiveUsageActionEnum from '../../actions/@types/kiosksLiveUsage/KiosksLiveUsageAction.enum';
import KiosksLiveUsageReduxState from './@types/KiosksLiveUsageReduxState';
import loadKioskLiveUsageDataAction from './functions/loadKioskLiveUsageDataAction';
import loadKioskLiveUsageReservationUpdatedDataAction from './functions/loadKioskLiveUsageReservationUpdatedDataAction';
import setKioskLiveUsageAction from './functions/setKioskLiveUsageAction';

const initialState: KiosksLiveUsageReduxState = {};

export default function kiosksLiveUsageReducer(
  state: KiosksLiveUsageReduxState = initialState,
  action: KiosksLiveUsageAction
): KiosksLiveUsageReduxState {
  switch (action.type) {
    case KiosksLiveUsageActionEnum.SET_KIOSK_LIVE_USAGE: {
      return setKioskLiveUsageAction(state, action.payload);
    }

    case KiosksLiveUsageActionEnum.LOAD_KIOSK_LIVE_USAGE_DATA: {
      return loadKioskLiveUsageDataAction(state, action.payload);
    }

    case KiosksLiveUsageActionEnum.LOAD_KIOSK_LIVE_USAGE_RESERVATION_UPDATED_DATA: {
      return loadKioskLiveUsageReservationUpdatedDataAction(state, action.payload);
    }

    default: {
      return state;
    }
  }
}
