import { InfoItem, Location, Maybe, Query, QueryInfoArgs } from '../__generated__/graphql';

import GET_INFO_ITEM from '../graphql/getInfoItem';
import QueryOneHookData from './@types/QueryOneHookData';
import { flashApolloError } from '../util/errorUtils';
import { useLazyQuery } from '@apollo/client';

type GetInfoItemByIdType = Pick<
  InfoItem,
  'id' | 'title' | 'description' | 'imageUrl' | 'moreInfoLink' | 'type' | 'infoType'
> & {
  location?: Maybe<Pick<Location, 'id' | 'name'>>;
};

type GetInfoItemData = Pick<Query, 'info'>;

export default function useGetInfoItemById(): QueryOneHookData<GetInfoItemByIdType> {
  const [getInfoItem, { called, data, error, loading }] = useLazyQuery<
    GetInfoItemData,
    QueryInfoArgs
  >(GET_INFO_ITEM, {
    fetchPolicy: 'network-only',
    onCompleted: () => {},
    onError: flashApolloError,
  });

  return {
    called,
    data: data?.info ?? undefined,
    error,
    loading,
    query: (id: string): void => {
      getInfoItem({
        variables: {
          where: { id },
        },
      });
    },
  };
}
