const DisplayBoxCTAContainerStyle = `
  align-self: end;
  border-top-width: 1px;
  padding: 17px 30px 35px;

  @media only screen and (max-width: 1450px) {
    padding: 10px 20px 15px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 10px 20px 15px;
  }
`;

export default DisplayBoxCTAContainerStyle;
