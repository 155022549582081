import {
  SectionDescription,
  SectionDescriptionWrapper,
  SectionTitle,
  SectionTitleWrapper,
} from '../styles/CustomSectionTitle.styles';
import { MdInfo } from 'react-icons/md';
import React from 'react';
import Theme from '../../../theme';

interface CustomSectionTitleProps {
  title: string;
  description?: string[];
  margin?: string;
  smallerTitle?: boolean;
}

const CustomSectionTitle: React.FC<CustomSectionTitleProps> = ({
  title,
  description,
  margin,
  smallerTitle,
}: CustomSectionTitleProps) => {
  return (
    <SectionTitleWrapper margin={margin}>
      <SectionTitle smallerTitle={smallerTitle}>{title}</SectionTitle>
      {description && (
        <SectionDescriptionWrapper>
          <MdInfo color={Theme.colors.white.hexCode} size="24px" />
          <SectionDescription>
            {description.map((text, index) => (
              <p key={`text-item-${index}`}>{text}</p>
            ))}
          </SectionDescription>
        </SectionDescriptionWrapper>
      )}
    </SectionTitleWrapper>
  );
};

export default CustomSectionTitle;
