import * as yup from 'yup';

import { Role } from '../../../__generated__/graphql';

const passwordMinLenght = 8;

const passwordIsRequired = (userRole?: Role): boolean => {
  return Boolean(userRole && (userRole === Role.Admin || userRole === Role.Agent));
};

export const REQUIRED_PASSWORD_VALIDATION_SCHEMA = yup.object().shape({
  confirmPassword: yup
    .string()
    .when('role', {
      is: (userRole?: Role) => passwordIsRequired(userRole),
      then: yup.string().required('Confirm Password is required'),
    })
    .oneOf([yup.ref('password'), null], 'Passwords do not match'),
  password: yup.string().when('role', {
    is: (userRole?: Role) => passwordIsRequired(userRole),
    then: yup
      .string()
      .min(passwordMinLenght, `Password must be at least ${passwordMinLenght} characters`)
      .required('Password is required'),
  }),
});

export const OPTIONAL_PASSWORD_VALIDATION_SCHEMA = yup.object().shape({
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords do not match'),
  password: yup
    .string()
    .test(
      'checkPasswordLenghtAtNonEmptyPassword',
      `Password must be at least ${passwordMinLenght} characters`,
      (password) => (password?.length ?? 0) === 0 || (password?.length ?? 0) >= passwordMinLenght
    ),
});
