import { gql } from '../__generated__/gql';

export const GET_DASHBOARD_LOCATION_COUNT = gql(`
  query AggregateDashboardLocation($where: CustomLocationWhereInput) {
    aggregateDashboardLocation(where: $where) {
      _count {
        _all
      }
    }
  }
`);

const GET_LOCATION_COUNT = gql(`
  query GetLocationCount($where: LocationWhereInput) {
    aggregateLocation(where: $where) {
      _count {
        _all
      }
    }
  }
`);

export default GET_LOCATION_COUNT;
