import 'styled-components/macro';

import { MdCheck, MdClose } from 'react-icons/md';

import Button from '../../Button';
import ButtonModel from '../../Button/enums/ButtonModel.enum';
import ConfirmationModalContentContainerStyle from '../styles/ConfirmationModalContentContainer.style';
import ConfirmationModalStyle from '../styles/ConfirmationModal.style';
import Container from '../../Container';
import Modal from 'react-modal';
import React from 'react';
import TextContent from '../../TextContent';
import { closeConfirmationModal } from '../../../redux/actions/modals.actions';
import { useDispatch } from 'react-redux';

interface ConfirmationModalProps {
  messageText?: string;
  onClose?: () => void;
  onConfirm: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  messageText,
  onClose,
  onConfirm,
}: ConfirmationModalProps) => {
  const dispatch = useDispatch();

  function close(): void {
    dispatch(closeConfirmationModal());
    if (onClose) {
      onClose();
    }
  }

  function onSubmit(): void {
    close();
    onConfirm();
  }

  return (
    <Modal
      isOpen
      onRequestClose={close}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      style={ConfirmationModalStyle}
    >
      <Container>
        <Container css={ConfirmationModalContentContainerStyle}>
          <TextContent>
            {messageText ?? 'Please confirm that you would like to perform this action'}
          </TextContent>
          <Button model={ButtonModel.PASSIVE_ICON_BUTTON} onClick={onSubmit}>
            <MdCheck className="yes-icon" size="18px" />
          </Button>
          <Button model={ButtonModel.PASSIVE_ICON_BUTTON} onClick={close}>
            <MdClose className="no-icon" size="18px" />
          </Button>
        </Container>
      </Container>
    </Modal>
  );
};

export default ConfirmationModal;
