import InputGroupProps from '../../InputGroup/@types/InputGroupProps';
import useLoadDashboardLocationOptions from '../../../hooks/useLoadDashboardLocationOptions';
import useSearchDashboardLocationsByName from '../../../hooks/useSearchDashboardLocations';

const POLL_RESERVATION_SECTION_INPUTS: InputGroupProps[] = [
  {
    label: 'Search Location',
    loadOptions: useLoadDashboardLocationOptions,
    name: 'location',
    query: useSearchDashboardLocationsByName,
    type: 'async-select',
  },
  {
    label: 'Confirmation code',
    name: 'confirmationCode',
    required: true,
    type: 'text',
  },
];

export default POLL_RESERVATION_SECTION_INPUTS;
