import 'styled-components/macro';
import React, { SyntheticEvent } from 'react';
import Button from '../../../Button';
import ButtonModel from '../../../Button/enums/ButtonModel.enum';
import Container from '../../../Container';
import PageSelectorContainerStyle from './styles/PageSelectorContainer.style';
import generateNumbersFromCount from '../../../../util/generateNumbersFromCount';

interface PageSelectorProps {
  count: number;
  handlePageClick: (offset: number) => void;
  perPageCount?: number;
  offset: number;
}

const PageSelector: React.FC<PageSelectorProps> = ({
  count,
  handlePageClick,
  offset,
  perPageCount = 10,
}: PageSelectorProps) => {
  const currentPage = offset / perPageCount + 1;
  const pages = generateNumbersFromCount(count, perPageCount);

  function generatePagesSnapshot(): number[] {
    const pagesLeft = pages.length - currentPage;

    if (pagesLeft > 5 && currentPage >= 5) {
      return pages.slice(currentPage - 5, currentPage + 5);
    }

    if (currentPage >= 5 && pages.length > 10) {
      return pages.slice(-11, -1);
    }

    return pages.slice(0, 10);
  }

  function handleClick(e: SyntheticEvent<HTMLButtonElement>): void {
    const pageNumberData: string = e.currentTarget.getAttribute('data-page') || '1';
    const pageNumberInt = parseInt(pageNumberData, 10);
    handlePageClick((pageNumberInt - 1) * perPageCount);
  }

  return (
    <Container css={PageSelectorContainerStyle}>
      {generatePagesSnapshot().map((num) => (
        <Button
          active={currentPage === num}
          data-offset={(num - 1) * perPageCount}
          data-page={num}
          disabled={currentPage === num}
          key={`page-${num}`}
          model={ButtonModel.INLINE_BUTTON}
          onClick={handleClick}
        >
          {num}
        </Button>
      ))}
    </Container>
  );
};

export default PageSelector;
