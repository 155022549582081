import { Mutation, MutationEnrollKioskArgs } from '../__generated__/graphql';

import { ENROLL_KIOSK } from '../graphql/mutations';
import MutationHookData from './@types/MutationHookData';
import MutationHookParams from './@types/MutationHookParams';
import { flashApolloError } from '../util/errorUtils';
import { useMutation } from '@apollo/client';

type EnrollKioskData = Pick<Mutation, 'enrollKiosk'>;

export default function useEnrollKiosk({
  onCompleted = (): void => {},
  onError = flashApolloError,
}: MutationHookParams<EnrollKioskData> = {}): MutationHookData<
  MutationEnrollKioskArgs,
  EnrollKioskData
> {
  const [create, { data, error, loading }] = useMutation<EnrollKioskData, MutationEnrollKioskArgs>(
    ENROLL_KIOSK,
    {
      onCompleted: ({ enrollKiosk }) => {
        window.flash({
          message: `Kiosk: ${enrollKiosk.name} created successfully`,
        });

        onCompleted({ enrollKiosk });
      },
      onError,
    }
  );

  return {
    data,
    error,
    loading,
    mutation: (inputs: MutationEnrollKioskArgs): void => {
      create({
        variables: inputs,
      });
    },
  };
}
