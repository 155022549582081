import { gql } from '../__generated__/gql';

const CANCEL_ACCESS_GRANT = gql(`
  mutation CancelAccessGrant($where: AccessGrantWhereUniqueInput!) {
    cancelAccessGrant(where: $where) {
      confirmationCode
      id
    }
  }
`);

export default CANCEL_ACCESS_GRANT;
