import { gql } from '../__generated__/gql';

const DELETE_RESOURCE = gql(`
  mutation DeleteOneResource($where: ResourceWhereUniqueInput!) {
    deleteOneResource(where: $where) {
      id
      name
    }
  }
`);

export default DELETE_RESOURCE;
