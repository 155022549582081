import { gql } from '../__generated__/gql';

const GET_SERVICE_COUNT = gql(`
  query GetServiceCount($where: ServiceWhereInput!) {
    aggregateService(where: $where) {
      _count {
        _all
      }
    }
  }
`);

export default GET_SERVICE_COUNT;
