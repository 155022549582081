import {
  mobileV3LocalizationSchema,
  mobileV3LocalizationUiSchema,
} from '../../../../../components/JsonEditor/constants/LocalizationSchema/mobileV3LocalizationSchema';

import CustomizeLanguageLocalization from '../../../../../components/CustomizeLocalization/components/CustomizeLanguageLocalization';
import { CustomizeThemeLocalizationEntityLevel } from '../../../../../enums/CustomizeThemeLocalizationEntityLevel';
import { LocalizationComponentName } from '../../../../../enums/ComponentNameEnum';
import React from 'react';
import { useParams } from 'react-router-dom';

const CustomizeMobileV3Localization: React.FC<{}> = () => {
  const { locationId } = useParams<{ locationId: string }>();

  return (
    <CustomizeLanguageLocalization
      title={'Customize mobile v3 localization'}
      componentName={LocalizationComponentName.MOBILE_V3}
      schema={mobileV3LocalizationSchema}
      uiSchema={mobileV3LocalizationUiSchema}
      fileNameToDownloadDefaultJson={`Default mobile v3 localization - Location ${locationId} - Language \${LANGUAGE_NAME}.json`}
      fileNameToDownloadCurrentJson={`Current mobile v3 localization - Location ${locationId} - Language \${LANGUAGE_NAME}.json`}
      entityLevel={CustomizeThemeLocalizationEntityLevel.LOCATION}
      entityId={locationId}
    />
  );
};

export default CustomizeMobileV3Localization;
