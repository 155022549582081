const getTokenFromUrl = (url: string): string => {
  try {
    const tokenText = 'token';

    const findTokenIndex = url.indexOf(tokenText);
    if (findTokenIndex === -1) {
      throw new Error();
    }

    const tokenIndex = findTokenIndex + tokenText.length + '='.length;
    const token = url.substring(tokenIndex);
    return token;
  } catch (error) {
    throw new Error('Token not found in the URL parameters');
  }
};

export default getTokenFromUrl;
