import DataTable from '../../../components/DataTable';
import { LocationPageInfo } from '../@types/GetLocationPageInfo';
import React from 'react';
import useAmenitiesTableData from '../../../hooks/useAmenitiesTableData';
import { useParams } from 'react-router-dom';

interface AmenityTabProps {
  location: LocationPageInfo;
}

const AmenitiesTab: React.FC<AmenityTabProps> = ({ location }: AmenityTabProps) => {
  const { locationId } = useParams<{ locationId: string }>();
  const tableData = useAmenitiesTableData({
    queryOptions: {
      where: {
        locationId: {
          equals: locationId,
        },
      },
    },
    relatedLocation: location,
  });

  return <DataTable {...tableData} />;
};

export default AmenitiesTab;
