import {
  AccessGrant,
  MutationSendNotificationArgs,
  NotificationTransport,
  QueryGetDashboardSupportedNotificationsArgs,
} from '../../__generated__/graphql';
import { useMutation, useQuery } from '@apollo/client';

import { DetailPageItem } from '../../templates/DetailPage';
import { EmailButtonKeyAndText } from './constants';
import { GET_EMAILS } from '../../pages/AccessGrant/graphql/getNotifications';
import { GetNotificationsInfo } from '../../pages/AccessGrant/@types/GetNotificationsInfo';
import { MdAccessAlarm } from 'react-icons/md';
import { SEND_NOTIFICATION } from '../../graphql/sendNotification';
import { flashApolloError } from '../../util/errorUtils';
import { openConfirmationModal } from '../../redux/actions/modals.actions';
import { useDispatch } from 'react-redux';

export const useGetEmailButtons = ({
  id: accessGrantId,
  tenantId,
}: Pick<AccessGrant, 'id' | 'tenantId'>): DetailPageItem[] => {
  const dispatch = useDispatch();

  const { data } = useQuery<GetNotificationsInfo, QueryGetDashboardSupportedNotificationsArgs>(
    GET_EMAILS,
    {
      fetchPolicy: 'cache-and-network',
      onError: flashApolloError,
      variables: {
        where: {
          tenantId,
        },
      },
    }
  );

  const buttonArray: DetailPageItem[] = [];

  const [sendNotification, { loading }] = useMutation<void, MutationSendNotificationArgs>(
    SEND_NOTIFICATION,
    {
      onCompleted: () => {
        window.flash({
          message: `Email sent successfully `,
        });
      },
      onError: flashApolloError,
    }
  );

  const notifications = data?.getDashboardSupportedNotifications.notifications;

  if (notifications?.length) {
    const entries = Object.entries(EmailButtonKeyAndText);

    entries.forEach(([key, value]) => {
      if (notifications.includes(key)) {
        buttonArray.push({
          disabled: loading,
          icon: MdAccessAlarm,
          onClick: (): void => {
            dispatch(
              openConfirmationModal({
                action: (): void => {
                  sendNotification({
                    variables: {
                      accessGrantId,
                      notificationId: key,
                      transport: NotificationTransport.Email,
                    },
                  });
                },
              })
            );
          },
          text: value,
        });
      }
    });
  }

  return buttonArray;
};
