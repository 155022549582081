import { Mutation, Query, ResourceGroupWhereUniqueInput } from '../../__generated__/graphql';

import FormResourceMap from '../../@types/react/FormResourceMap';
import FormTemplate from '../../templates/Form';
import GET_RESOURCE_GROUP from '../../graphql/getResourceGroup';
import RESOURCE_GROUP_FORM_STEPS from './constants/formSteps';
import RESOURCE_GROUP_VALIDATION_SCHEMA from './constants/validationSchema';
import React from 'react';
import ResourceGroupFormInputs from './@types/ResourceGroupFormInputs';
import { RootState } from '../../redux/reducers';
import { flashApolloError } from '../../util/errorUtils';
import reduce from 'lodash/reduce';
import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

type GetResourceGroupData = Pick<Query, 'resourceGroup'>;
type CreateResourceData = Pick<Mutation, 'createResourceGroup'>;

const ResourceGroupForm: React.FC = () => {
  const id = useSelector((state: RootState) => state.modals.form.id ?? '');

  const [get, { called, data }] = useLazyQuery<GetResourceGroupData, ResourceGroupWhereUniqueInput>(
    GET_RESOURCE_GROUP,
    {
      fetchPolicy: 'no-cache',
      onError: flashApolloError,
      variables: { id },
    }
  );

  useEffect(() => {
    if (!called && id) {
      get();
    }
  });

  const resourceGroup = data?.resourceGroup;

  if (id && !resourceGroup) {
    return <></>;
  }

  const getInitialValues = (): Partial<ResourceGroupFormInputs> => {
    if (resourceGroup) {
      return {
        ...resourceGroup,
        resources: {
          map: reduce(
            resourceGroup.resources,
            (acc, resource) => ({
              ...acc,
              [resource.id]: {
                isConnected: true,
                option: {
                  label: resource.name,
                  value: resource.id,
                },
                shouldConnect: false,
                shouldDisconnect: false,
              },
            }),
            {} as FormResourceMap
          ),
          values: resourceGroup.resources.map((resource) => ({
            label: resource.name,
            value: resource.id,
          })),
        },
      };
    }

    return {
      isDefault: false,
      name: '',
      resources: {
        map: {},
        values: [],
      },
    };
  };

  return (
    <FormTemplate<ResourceGroupFormInputs, CreateResourceData>
      formHeader={id ? 'Edit Resource Group' : 'Create Resource Group'}
      identifier={id}
      steps={RESOURCE_GROUP_FORM_STEPS}
      validationSchema={RESOURCE_GROUP_VALIDATION_SCHEMA}
      initialValues={getInitialValues()}
    />
  );
};

export default ResourceGroupForm;
