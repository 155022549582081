import { useFormContext } from 'react-hook-form';

export default function useSetLocationIdFilterForUnitType(): () => void {
  const { setValue, trigger } = useFormContext();

  return (): void => {
    const UNIT_TYPE_PATH = 'unitType';

    setValue(UNIT_TYPE_PATH, undefined);
    trigger([UNIT_TYPE_PATH]);
  };
}
