import FormTemplateStep from '../../../templates/Form/@types/FormTemplateStep';
import { Mutation } from '../../../__generated__/graphql';
import UnitTypeFormInputs from '../@types/UnitTypeFormInputs';
import UnitTypeLocalizationSection from '../components/UnitTypeLocalizationSection';
import UnitTypeSection from '../components/UnitTypeSection';

type CreateUnitTypeData = Pick<Mutation, 'createUnitTypeAndUploadImageFile'>;

const UNIT_TYPE_FORM_STEPS: FormTemplateStep<UnitTypeFormInputs, CreateUnitTypeData>[] = [
  {
    canBeAccessed: (): boolean => true,
    component: UnitTypeSection,
    name: 'Unit Type',
  },
  {
    canBeAccessed: (): boolean => false,
    component: UnitTypeLocalizationSection,
    name: 'Localization',
  },
];

export default UNIT_TYPE_FORM_STEPS;
