import 'styled-components/macro';

import {
  FileStatus,
  FileToEntity,
  Query,
  QueryFileToEntitiesArgs,
} from '../../../../__generated__/graphql';
import { default as React, useContext } from 'react';
import { SubmitHandler, useFieldArray, useFormContext } from 'react-hook-form';

import AccessGrantFormInputs from '../../@types/AccessGrantFormInputs';
import { AgreementFile } from '../AgreementFile';
import Button from '../../../Button';
import ButtonModel from '../../../Button/enums/ButtonModel.enum';
import { Colors } from '../../../../theme/colors';
import Container from '../../../Container';
import Form from '../../../Form';
import FormContext from '../../../../contexts/form.context';
import GET_LEASE_FILE from '../../../../graphql/getLeaseDocument';
import { MdClose } from 'react-icons/md';
import SimpleFormSection from '../../../../templates/SimpleFormSection';
import SubmitRow from '../../../SubmitRow';
import compact from 'lodash/compact';
import { flashApolloError } from '../../../../util/errorUtils';
import generateRequirementFormInputs from './functions/generateRequirementFormInputs';
import { useQuery } from '@apollo/client';

type FileToEntityData = Pick<Query, 'fileToEntities'>;

const RequirementSection: React.FC<{}> = () => {
  const { control, getValues, handleSubmit } = useFormContext();

  const { setStep, step } = useContext(FormContext);

  const { append, fields, remove } = useFieldArray({
    control,
    name: 'requirements.values',
  });

  const requirementIds = fields.map((item) => item.requirementId);

  const { data } = useQuery<FileToEntityData, QueryFileToEntitiesArgs>(GET_LEASE_FILE, {
    fetchPolicy: 'network-only',
    onError: flashApolloError,
    variables: {
      where: {
        entityId: { in: compact(requirementIds) },
        file: {
          is: { status: { equals: FileStatus.Active } },
        },
      },
    },
  });

  const { append: appendDelete } = useFieldArray({
    control,
    name: 'requirements.delete',
  });

  const submitHandler: SubmitHandler<AccessGrantFormInputs> = (): void => {
    setStep((step ?? 0) + 1);
  };

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Container>
        <Container
          css={`
            margin: 0 0 10px;
          `}
        >
          <Button
            model={ButtonModel.ACTIVE_CTA_DARK}
            onClick={(e: React.SyntheticEvent): void => {
              e.preventDefault();

              if (fields.length < 15) {
                append({
                  status: undefined,
                  type: undefined,
                });
              }
            }}
          >
            Add Requirement
          </Button>
        </Container>
        <Container
          css={`
            grid-row-gap: 10px;
          `}
        >
          {fields.map((_, index) => {
            const requirementInfo = getValues(`requirements.values[${index}]`);
            const requirementFileToEntities = data?.fileToEntities.filter(
              (item: FileToEntity) => item.entityId === requirementInfo.requirementId
            );

            return (
              <div key={`vendor-form-${index}`}>
                <Container
                  css={`
                    align-items: flex-start;
                    grid-auto-flow: column;
                    grid-column-gap: 15px;
                    grid-template-columns: 1fr max-content;
                  `}
                >
                  <SimpleFormSection
                    columns="1fr 1fr"
                    inputs={generateRequirementFormInputs(index, getValues)}
                  />
                  <Button
                    background={Colors.freeSpeechRed}
                    css="margin-top: 28px"
                    model={ButtonModel.PASSIVE_ICON_BUTTON}
                    onClick={(e: React.SyntheticEvent): void => {
                      e.preventDefault();

                      const id = getValues(`requirements.values[${index}].requirementId`);

                      if (id) {
                        appendDelete(id);
                      }

                      remove(index);
                    }}
                  >
                    <MdClose color="#fff" size="16px" />
                  </Button>
                </Container>
                <Container
                  css={`
                    display: flex;
                    margin-bottom: 8px;
                    margin-top: 24px;
                    > *:not(:last-child) {
                      margin-right: 8px;
                    }
                  `}
                >
                  {requirementFileToEntities?.map((fileToEntity) => (
                    <AgreementFile fileToEntity={fileToEntity} />
                  ))}
                </Container>
              </div>
            );
          })}
        </Container>
      </Container>
      <SubmitRow submitText="Set Resources" />
    </Form>
  );
};

export default RequirementSection;
