import { AnyAction, Reducer, combineReducers } from 'redux';

import accessGrantReducer from './accessGrant.reducer';
import authReducer from './auth.reducer';
import client from '../../client';
import conciergeReducer from './concierge.reducer';
import dashboardPreferencesReducer from './dashboardPreferences.reducer';
import dataTableReducer from './dataTable.reducer';
import kiosksLiveUsageReducer from './kiosksLiveUsage.reducer';
import meReducer from './me.reducer';
import modalsReducer from './modals.reducer';
import pendingTasksReducer from './pendingTasks.reducer';
import remoteAssistanceReducer from './remoteAssistance.reducer';
import reportingReducer from './reporting';

const combinedReducer = combineReducers({
  accessGrant: accessGrantReducer,
  auth: authReducer,
  concierge: conciergeReducer,
  dashboardPreferences: dashboardPreferencesReducer,
  dataTable: dataTableReducer,
  kioskLiveUsage: kiosksLiveUsageReducer,
  me: meReducer,
  modals: modalsReducer,
  pendingTasks: pendingTasksReducer,
  remoteAssistance: remoteAssistanceReducer,
  reporting: reportingReducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'LOGOUT') {
    client.clearStore();
    localStorage.clear();
    return combinedReducer({} as RootState, action);
  }

  return combinedReducer(state, action);
};

export default rootReducer;
