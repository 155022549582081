import { ClearFilterPayload } from '../../../actions/@types/ClearFilterAction';
import DataTableReduxState from '../../@types/DataTableReduxState';

export default function clearFilterAction(
  currentState: DataTableReduxState,
  actionPayload: ClearFilterPayload
): DataTableReduxState {
  const newFilters = { ...currentState.filters };
  delete newFilters[actionPayload.dataTableKey];

  return {
    ...currentState,
    filters: newFilters,
  };
}
