import {
  CustomAmenityCreateInput,
  Mutation,
  MutationCreateAmenityAndUploadImageFileArgs,
} from '../__generated__/graphql';

import { CREATE_AMENITY } from '../graphql/createAmenity';
import MutationHookData from './@types/MutationHookData';
import MutationHookParams from './@types/MutationHookParams';
import { apolloRequirePreflightHeader } from '../client';
import { closeForm } from '../redux/actions/modals.actions';
import { flashApolloError } from '../util/errorUtils';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';

type CreateAmenityData = Pick<Mutation, 'createAmenityAndUploadImageFile'>;

export default function useCreateAmenityAndUploadImage({
  onCompleted = (): void => {},
}: MutationHookParams<CreateAmenityData> = {}): MutationHookData<
  CustomAmenityCreateInput,
  CreateAmenityData
> {
  const dispatch = useDispatch();

  const [create, { data, error, loading }] = useMutation<
    CreateAmenityData,
    MutationCreateAmenityAndUploadImageFileArgs
  >(CREATE_AMENITY, {
    context: {
      headers: apolloRequirePreflightHeader,
    },
    onCompleted: ({ createAmenityAndUploadImageFile }) => {
      window.flash({
        message: `Amenity: ${createAmenityAndUploadImageFile.title} created successfully`,
      });

      onCompleted({ createAmenityAndUploadImageFile });

      dispatch(closeForm());
    },
    onError: flashApolloError,
  });

  return {
    data,
    error,
    loading,
    mutation: (inputs: CustomAmenityCreateInput): void => {
      create({
        variables: {
          data: inputs,
        },
      });
    },
  };
}
