import { Location, Mutation } from '../../__generated__/graphql';
import React, { useEffect } from 'react';

import AMENITY_FORM_STEPS from './constants/amenityFormSteps';
import AMENITY_VALIDATION_SCHEMA from './constants/amenityValidationSchema';
import AmenityFormInputs from './@types/AmenityFormInputs';
import FormTemplate from '../../templates/Form';
import { ImageTypeEnum } from '../UnitTypeForm/enums/ImageTypeEnum.enum';
import pick from 'lodash/pick';
import useGetAmenityById from '../../hooks/useGetAmenityById';

interface AmenityAditionalDataOnOpeningFormInterface {
  location: Pick<Location, 'id' | 'name'>;
}

interface AmenityProps {
  id?: string;
  aditionalDataOnOpeningForm?: unknown;
}

type CreateAmenityData = Pick<Mutation, 'createAmenityAndUploadImageFile'>;

const AmenityForm: React.FC<AmenityProps> = ({ id, aditionalDataOnOpeningForm }: AmenityProps) => {
  const { called, data: amenity, query } = useGetAmenityById();

  useEffect(() => {
    if (id && !called) {
      query(id);
    }
  });

  if (id && !amenity) {
    return <React.Fragment />;
  }

  const getInitialValues = (): Partial<AmenityFormInputs> => {
    if (amenity) {
      const initialValues = {
        ...pick(amenity, ['title', 'description', 'imageUrl']),
        ...(amenity.group && {
          group: {
            label: amenity.group?.title,
            value: amenity.group?.id,
          },
        }),
        imageType: ImageTypeEnum.URL,
        ...(amenity.location
          ? {
              location: {
                label: amenity.location.name,
                value: amenity.location.id,
              },
            }
          : {}),
      };

      return initialValues;
    }

    const defaultInitialValues: Partial<AmenityFormInputs> = {
      imageType: ImageTypeEnum.URL,
    };

    try {
      if (aditionalDataOnOpeningForm) {
        const {
          location,
        } = aditionalDataOnOpeningForm as AmenityAditionalDataOnOpeningFormInterface;
        return {
          ...defaultInitialValues,
          location: {
            label: location.name,
            value: location.id,
          },
        };
      }
      // eslint-disable-next-line no-empty
    } catch {}

    return defaultInitialValues;
  };

  return (
    <FormTemplate<AmenityFormInputs, CreateAmenityData>
      formHeader={id ? 'Edit Amenity' : 'Create Amenity'}
      identifier={id}
      steps={AMENITY_FORM_STEPS}
      validationSchema={AMENITY_VALIDATION_SCHEMA}
      initialValues={getInitialValues()}
    />
  );
};

export default AmenityForm;
