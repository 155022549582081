import { gql } from '../__generated__/gql';

const GET_DATE_TIME_PREFERENCES = gql(`
  query GetDashboardPreferences($userId: String!, $tenantId: String!, $locationId: String) {
    getDashboardPreferences(userId: $userId, tenantId: $tenantId, locationId: $locationId) {
      preferredDateFormat
      preferredTimeFormat
      quickActionBar {
        enabled
        actions {
          checkIn
          checkOut
          blockCheckIn
          getAutenticationCredentials
          remotePrintKeyCard
          repollReservation
        }
      }
      reportingServices {
        reportTitle
        reportUrl
      }
    }
  }
`);

export default GET_DATE_TIME_PREFERENCES;
