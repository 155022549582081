import { Role, UserWhereInput } from '../../../__generated__/graphql';

import { DataTableFilterValues } from '../../../@types/client/DataTableFilterValues';
import { ReduxMembership } from '../../../redux/reducers/@types/MeReduxState';

export default function parseUserQueryParameters(
  fieldsFiltered: DataTableFilterValues[],
  membership: ReduxMembership
): Partial<UserWhereInput> {
  const whereInput: Partial<UserWhereInput> = {};

  if (fieldsFiltered && fieldsFiltered.length > 0) {
    // Role filter
    const roleFieldsIndex = fieldsFiltered.findIndex((field) => field.fieldKey === 'role');
    if (roleFieldsIndex > -1) {
      const inInput = fieldsFiltered[roleFieldsIndex].values as Role[];

      whereInput.memberships = {
        some: {
          ...(membership.location && { locationId: { equals: membership.location.id } }),
          role: {
            in: inInput,
          },
          tenantId: { equals: membership.tenant.id },
        },
      };
    }
  }

  return whereInput;
}
