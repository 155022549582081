import 'styled-components/macro';

import { SubmitHandler, useFormContext } from 'react-hook-form';

import Container from '../../Container';
import Form from '../../Form';
import POLL_RESERVATION_SECTION_INPUTS from '../constants/pollReservationSectionInputs';
import PollReservationFormInputs from '../@types/PollReservationFormInputs';
import React from 'react';
import SimpleFormSection from '../../../templates/SimpleFormSection';
import SubmitRow from '../../SubmitRow';
import { openConfirmationModal } from '../../../redux/actions/modals.actions';
import { useDispatch } from 'react-redux';
import usePollReservations from '../../../hooks/usePollReservations';

const PollReservationSection: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { handleSubmit } = useFormContext();

  const { mutation, loading } = usePollReservations();

  const submitHandler: SubmitHandler<PollReservationFormInputs> = async (params) => {
    dispatch(
      openConfirmationModal({
        action: (): void => {
          mutation({
            ...params,
          });
        },
      })
    );
  };

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Container>
        <Container>
          <SimpleFormSection inputs={POLL_RESERVATION_SECTION_INPUTS} />
          <SubmitRow loading={loading} submitText="Poll reservation" />
        </Container>
      </Container>
    </Form>
  );
};

export default PollReservationSection;
