import 'styled-components/macro';
import React, { PropsWithChildren } from 'react';
import AppHeader from '../../components/AppHeader';
import Container from '../../components/Container';
import { HEADER_HEIGHT } from '../../constants/dimensions';
import Sidebar from '../../components/Sidebar';

interface BaseLayoutProps {
  background?: string;
}

const BaseLayout: React.FC<BaseLayoutProps> = ({
  background = '#fff',
  children,
}: PropsWithChildren<BaseLayoutProps>) => {
  return (
    <Container
      css={`
        grid-auto-flow: column;
        grid-template-columns: max-content 1fr;
      `}
    >
      <Sidebar />
      <Container>
        <AppHeader />
        <Container
          css={`
            background: ${background};
            min-height: calc(100vh - ${HEADER_HEIGHT});
            padding: 30px 35px 75px 25px;
          `}
        >
          {children}
        </Container>
      </Container>
    </Container>
  );
};

export default BaseLayout;
