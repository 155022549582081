import Theme from '../../../theme';
import styled from 'styled-components';

interface ToggleProps {
  leftSelected: boolean;
}

interface ContainerProps {
  compact?: boolean;
  disableCursor?: boolean;
}

const determineContainerWidth = ({ compact }: ContainerProps): string => {
  return compact ? '100px' : '270px';
};

export const Container = styled.div<ContainerProps>`
  width: 100%;
  max-width: ${determineContainerWidth};
  width: ${determineContainerWidth};
  max-height: 35px;
  height: 35px;
  border-radius: 10px;
  background-color: ${Theme.colors.borderDark.hexCode};
  font-weight: 600;
  color: ${Theme.colors.white.hexCode};
  position: relative;
  cursor: ${({ disableCursor }): string => {
    return disableCursor ? 'not-allowed' : 'pointer';
  }};

  & > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
`;

export const Toggle = styled.div<ToggleProps>`
  position: absolute;
  top: 0;
  left: ${({ leftSelected }): string => {
    return leftSelected ? '0' : '50%';
  }};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50%;
  border-radius: 10px;
  background-color: ${Theme.colors.tableHeading.hexCode};
  color: ${Theme.colors.white.hexCode};
  transition: all 0.2s;
  font-weight: 600;
`;

export const TextGrid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;

  & span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
