import { gql } from '../__generated__/gql';

const SIGN_UP_USERS_FROM_THIRD_PARTY_AUTHENTICATION_SERVICE = gql(`
  mutation SignUpUsersFromThirdPartyAuthenticationService(
    $tenantId: String!
    $serviceType: String!
  ) {
    signUpUsersFromThirdPartyAuthenticationService(tenantId: $tenantId, serviceType: $serviceType) {
      email
      id
      name
    }
  }
`);

export default SIGN_UP_USERS_FROM_THIRD_PARTY_AUTHENTICATION_SERVICE;
