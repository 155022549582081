import { Mutation, MutationCreateResourceGroupArgs } from '../__generated__/graphql';
import { useDispatch, useSelector } from 'react-redux';

import CREATE_RESOURCE_GROUP from '../graphql/createResourceGroup';
import MutationHookData from './@types/MutationHookData';
import MutationHookParams from './@types/MutationHookParams';
import ResourceGroupFormInputs from '../components/ResourceGroupForm/@types/ResourceGroupFormInputs';
import { RootState } from '../redux/reducers';
import { closeForm } from '../redux/actions/modals.actions';
import createResourcesInput from '../util/mutations/createResourcesInput';
import { flashApolloError } from '../util/errorUtils';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

type CreateResourceGroupData = Pick<Mutation, 'createResourceGroup'>;

export default function useCreateResourceGroup({
  onCompleted = (): void => {},
  onError = flashApolloError,
}: MutationHookParams<CreateResourceGroupData> = {}): MutationHookData<
  ResourceGroupFormInputs,
  CreateResourceGroupData
> {
  const dispatch = useDispatch();
  const { locationId } = useParams<{ locationId: string }>();
  const tenantId = useSelector((state: RootState) => state.me.activeMembership.tenantId);

  const [create, { data, error, loading }] = useMutation<
    CreateResourceGroupData,
    MutationCreateResourceGroupArgs
  >(CREATE_RESOURCE_GROUP, {
    onCompleted: ({ createResourceGroup }) => {
      window.flash({
        message: `Resource Group: ${createResourceGroup.name} created successfully`,
      });

      onCompleted({ createResourceGroup });

      dispatch(closeForm());
    },
    onError,
  });

  return {
    data,
    error,
    loading,
    mutation: (inputs: ResourceGroupFormInputs): void => {
      create({
        variables: {
          data: {
            isDefault: inputs.isDefault,
            location: { connect: { id: locationId } },
            name: inputs.name,
            resources: {
              connect: createResourcesInput(inputs.resources.map).connect,
            },
            tenant: { connect: { id: tenantId } },
          },
        },
      });
    },
  };
}
