import { Colors } from '../../../../../theme/colors';
import Theme from '../../../../../theme';

const EmptyDisclaimerTextStyle = `
  color: ${Colors.white};
  font-size: ${Theme.fontSizes.secondHeading};
  font-weight: bold;
  text-transform: uppercase;

  @media only screen and (max-width: 1450px) {
    font-size: ${Theme.fontSizes.thirdHeading};
  }
`;

export default EmptyDisclaimerTextStyle;
