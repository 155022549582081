import IMPORT_FROM_CSV_SECTION_INPUTS from '../../ImportFromCSVForm/constants/importFromCSVSectionInputs';
import InputGroupProps from '../../InputGroup/@types/InputGroupProps';
import useLoadLanguageOptions from '../../../hooks/useLoadLanguageOptions';

const IMPORT_UNIT_TYPE_FROM_CSV_SECTION_INPUTS: InputGroupProps[] = [
  ...IMPORT_FROM_CSV_SECTION_INPUTS,
  {
    label: 'Search Language',
    loadOptions: useLoadLanguageOptions,
    name: 'language',
    required: true,
    type: 'async-select',
  },
];

export default IMPORT_UNIT_TYPE_FROM_CSV_SECTION_INPUTS;
