import { gql } from '../__generated__/gql';

const GET_RESOURCE_GROUP_COUNT = gql(`
  query GetResourceGroupCount($where: ResourceGroupWhereInput) {
    aggregateResourceGroup(where: $where) {
      _count {
        _all
      }
    }
  }
`);

export default GET_RESOURCE_GROUP_COUNT;
