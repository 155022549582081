import { Colors } from '../../../theme/colors';

export const AuthenticationFormCommonInputStyle = `
  background: ${Colors.midnightExpress};
  border-color: transparent;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  display: block;
  margin: 10px 0;
  outline: 0;
  text-align: center;
  width: 100%;

  &:focus {
    border-color: ${Colors.mountainMeadow};
    color: ${Colors.mountainMeadow};
  }

  &::placeholder {
    color: ${Colors.stormGrey};
  }

  &.error {
    border-color: ${Colors.salmon};

    &::placeholder {
      color: ${Colors.salmon};
    }
  }
`;

const AuthenticationFormInputStyle = `
  ${AuthenticationFormCommonInputStyle}
  height: 45px;
  line-height: 16px;
  font-size: 11px;
  color: #fff;

  &.error {
    color: ${Colors.salmon};
  }
`;

export default AuthenticationFormInputStyle;
