import GuestOnAGWhereInput from '../@types/GuestOnAGWhereInput';
import SearchField from '../../../@types/SearchField';
import createSearchField from '../../../util/tableData/createSearchField';
import transformWhereInput from '../../../util/tableData/transformWhereInput';

const GUESTS_DETAILS_ON_AG_TABLE_SEARCH_FIELDS: SearchField<GuestOnAGWhereInput>[] = [
  createSearchField<GuestOnAGWhereInput, 'user'>({
    label: 'Guest',
    queryField: 'user',
    transform: transformWhereInput,
  }),
];

export default GUESTS_DETAILS_ON_AG_TABLE_SEARCH_FIELDS;
