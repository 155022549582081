import { gql } from '../__generated__/gql';

const GET_KIOSK = gql(`
  query Kiosk($where: KioskWhereUniqueInput!) {
    kiosk(where: $where) {
      id
      lastSeenAt
      location {
        id
        name
        timezone
      }
      locationId
      name
      hasTerminal
      hasDispenser
      powerSwitchId
      dispenserType
      maintenanceStartsAt
      maintenanceEndsAt
      dispenserConfig {
        id
        dispenserType
        serial
        usage
        isActive
      }
      rfidTagType
    }
  }
`);

export default GET_KIOSK;
