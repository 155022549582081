import { CredentialType, CredentialWhereInput } from '../../../__generated__/graphql';

import { AccessGrantPageInfo } from '../../../pages/AccessGrant/@types/GetAccessGrantPageInfo';
import { CredentialBasicTypeEnum } from '../enums/CredentialBasicType.enum';
import { DataTableFilterValues } from '../../../@types/client/DataTableFilterValues';
import FILTERABLE_CREDENTIAL_STATUS from '../constants/FilterableCredentialStatus';
import { UserTypeEnum } from '../enums/UserType.enum';
import { credentialTypesByBasicType } from '../functions/credentialType';

export default function parseCredentialQueryParameters(
  fieldsFiltered: DataTableFilterValues[],
  relatedAccessGrant?: AccessGrantPageInfo
): Partial<CredentialWhereInput> {
  const whereInput: Partial<CredentialWhereInput> = {};

  if (fieldsFiltered?.length > 0) {
    // User type filter
    if (relatedAccessGrant) {
      const userTypeFilterIndex = fieldsFiltered.findIndex(
        (field) => field.fieldKey === 'userType'
      );
      if (userTypeFilterIndex > -1) {
        const inInput = fieldsFiltered[userTypeFilterIndex].values as UserTypeEnum[];
        if (inInput.length === 1) {
          const [value] = inInput;
          const isPrimaryUser = value === UserTypeEnum.PRIMARY_GUEST;

          whereInput.userId = {};
          if (isPrimaryUser) {
            whereInput.userId.equals = relatedAccessGrant.user.id;
          } else {
            whereInput.userId.not = {};
            whereInput.userId.not.equals = relatedAccessGrant.user.id;
          }
        }
      }
    }

    // Type Filter
    const typeFieldsIndex = fieldsFiltered.findIndex((field) => field.fieldKey === 'type');
    if (typeFieldsIndex > -1) {
      const inInput = fieldsFiltered[typeFieldsIndex].values as CredentialBasicTypeEnum[];
      let types: CredentialType[] = [];
      inInput.forEach((basicType) => {
        types = [...types, ...credentialTypesByBasicType[basicType]];
      });

      whereInput.type = {};
      whereInput.type.in = types;
    }

    // Status filters
    const statusFieldsIndex = fieldsFiltered.findIndex((field) => field.fieldKey === 'status');
    if (statusFieldsIndex > -1) {
      const inInput = fieldsFiltered[statusFieldsIndex].values;

      if (
        inInput.length > 0 &&
        inInput.length !== Object.keys(FILTERABLE_CREDENTIAL_STATUS).length
      ) {
        const [value] = inInput;

        whereInput.isActive = {};
        whereInput.isActive.equals = value === FILTERABLE_CREDENTIAL_STATUS.ACTIVE;
      }
    }
  }

  return whereInput;
}
