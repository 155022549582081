import { UserTypeEnum } from '../enums/UserType.enum';

export const getUserTypeName = (userType: UserTypeEnum): string => {
  switch (userType) {
    case UserTypeEnum.PRIMARY_GUEST:
      return 'Primary guest';

    case UserTypeEnum.SECONDARY_GUEST:
      return 'Secondary guest';

    default:
      return userType;
  }
};

interface GetUserTypeProps {
  credentialUserId: string;
  accessGrantUserId: string;
}
export const getUserType = ({
  credentialUserId,
  accessGrantUserId,
}: GetUserTypeProps): UserTypeEnum => {
  return credentialUserId === accessGrantUserId
    ? UserTypeEnum.PRIMARY_GUEST
    : UserTypeEnum.SECONDARY_GUEST;
};
