import { gql } from '../../../__generated__/gql';

const GET_LOCATION_PAGE_INFO = gql(`
  query GetDashboardLocation($where: LocationWhereInput!) {
    getDashboardLocation(where: $where) {
      id
      address
      email
      logoUrl
      imageUrl
      name
      phoneNumber
      pmsType
      tenantId
      supportsPolling
      defaultStartTime
      defaultEndTime
    }
  }
`);

export default GET_LOCATION_PAGE_INFO;
