import Modal from 'react-modal';
import Theme from '../../../theme';

const KioskActionsModalStyle: Modal.Styles = {
  content: {
    background: Theme.colors.darkAlternate.hexCode,
    border: '0',
    borderRadius: '12px',
    boxShadow: `0px 5px 32px 0px rgba(${Theme.colors.borderDark.rgb}, 0.91)`,
    height: 'min-content',
    inset: '25px 0px 0px calc(100% - 325px)',
    outline: '0',
    padding: '20px',
    position: 'fixed',
    width: '300px',
    zIndex: 1,
  },
  overlay: {
    border: '0',
    display: 'contents',
    outline: '0',
    zIndex: 10,
  },
};

export default KioskActionsModalStyle;
