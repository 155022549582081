import { CustomizeComponentEnum } from './types/CustomizeComponent.enum';
import { CustomizeSettingsEnum } from './types/CustomizeSettings.enum';
import { SettingsTabOption } from '../../components/SettingsTab';
import { TGetCustomizeableComponentsData } from '../../hooks/useGetDashboardCustomizeableComponents';

type TGetCustomizationSettingsProps = {
  getComponentBasedOnSettingAndComponent: (
    settings: CustomizeSettingsEnum,
    component: CustomizeComponentEnum
  ) => SettingsTabOption | undefined;
  componentsData: TGetCustomizeableComponentsData | undefined;
};

export const getCustomizationTabsPerSettingsAndComponent = ({
  getComponentBasedOnSettingAndComponent,
  componentsData,
}: TGetCustomizationSettingsProps): SettingsTabOption[] => {
  const settingsAndComponents: {
    setting: CustomizeSettingsEnum;
    component: CustomizeComponentEnum;
  }[] = [];

  const settings = Object.values(CustomizeSettingsEnum);
  settings.forEach((setting) => {
    componentsData?.forEach((component) => {
      settingsAndComponents.push({
        component: component.name as CustomizeComponentEnum,
        setting,
      });
    });
  });

  let customizationSettings = settingsAndComponents.map(({ setting, component }) =>
    getComponentBasedOnSettingAndComponent(setting, component)
  );
  customizationSettings = customizationSettings.filter((setting) => Boolean(setting));

  return customizationSettings as SettingsTabOption[];
};
