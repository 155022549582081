import 'styled-components/macro';

import DataTable from '../../../components/DataTable';
import React from 'react';
import { useParams } from 'react-router-dom';
import useResourceGroupsTableData from '../../../hooks/useResourceGroupsTableData';

const ResourceGroupsTab: React.FC<{}> = () => {
  const { locationId } = useParams<{ locationId: string }>();

  const tableData = useResourceGroupsTableData({
    queryOptions: {
      where: {
        locationId: {
          equals: locationId,
        },
      },
    },
  });

  return <DataTable {...tableData} />;
};

export default ResourceGroupsTab;
