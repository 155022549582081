import { gql } from '../__generated__/gql';

const BYPASS_VERIFICATION = gql(`
  mutation bypassVerification($kioskId: String!) {
    bypassVerification(kioskId: $kioskId) {
      success
    }
  }
`);

export default BYPASS_VERIFICATION;
