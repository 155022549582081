import { gql } from '../__generated__/gql';

export const CREATE_VENDOR_SERVER = gql(`
  mutation CreateOneVendorServer($data: VendorServerCreateInput!) {
    createOneVendorServer(data: $data) {
      id
      vendor
    }
  }
`);
