import { Mutation, MutationSetLimitKeysConfigArgs } from '../__generated__/graphql';

import SET_LIMIT_KEYS_CONFIG from '../graphql/upsertLimitKeysConfig';
import { flashApolloError } from '../util/errorUtils';
import { useMutation } from '@apollo/client';

interface UseSetLimitKeys {
  isSubmitting: boolean;
  setLimitKeysConfigCallback: (args: MutationSetLimitKeysConfigArgs) => void;
}

type SetLimitKeysConfigResponse = Pick<Mutation, 'setLimitKeysConfig'>;

export function useSetLimitKeys(): UseSetLimitKeys {
  const [setLimitKeysConfig, { loading: isSubmitting }] = useMutation<
    SetLimitKeysConfigResponse,
    MutationSetLimitKeysConfigArgs
  >(SET_LIMIT_KEYS_CONFIG, {
    onCompleted: () => {
      window.flash({
        message: 'Grace periods saved successfully',
      });
    },
    onError: flashApolloError,
  });

  const setLimitKeysConfigCallback = (data: MutationSetLimitKeysConfigArgs): void => {
    const variables = {
      variables: {
        ...data,
      },
    };

    setLimitKeysConfig(variables);
  };

  return {
    isSubmitting,
    setLimitKeysConfigCallback,
  };
}
