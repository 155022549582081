/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3ThemeAuthenticationSchema: NodeSchemaProperties = {
  authentication: {
    title: '#2 Authentication',
    type: 'object',
    properties: {
      'backgroundColor:enable': {
        description: '[authentication.backgroundColor]',
        title: '#1 Background Color',
        type: 'boolean',
      },
      backgroundColor: {
        type: 'string',
      },
      submitButton: {
        title: '#2 Submit Button',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[authentication.submitButton.backgroundColor]',
            title: '#2.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'textColor:enable': {
            description: '[authentication.submitButton.textColor]',
            title: '#2.2 Text Color',
            type: 'boolean',
          },
          textColor: {
            type: 'string',
          },
          'borderColor:enable': {
            description: '[authentication.submitButton.borderColor]',
            title: '#2.3 Border Color',
            type: 'boolean',
          },
          borderColor: {
            type: 'string',
          },
          'borderWidth:enable': {
            description: '[authentication.submitButton.borderWidth]',
            title: '#2.4 Border Width',
            type: 'boolean',
          },
          borderWidth: {
            type: 'integer',
          },
          'borderRadius:enable': {
            description: '[authentication.submitButton.borderRadius]',
            title: '#2.5 Border Radius',
            type: 'boolean',
          },
          borderRadius: {
            type: 'integer',
          },
        },
      },
      authOptionButton: {
        title: '#3 Auth Option Button',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[authentication.authOptionButton.backgroundColor]',
            title: '#3.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'textColor:enable': {
            description: '[authentication.authOptionButton.textColor]',
            title: '#3.2 Text Color',
            type: 'boolean',
          },
          textColor: {
            type: 'string',
          },
          'borderColor:enable': {
            description: '[authentication.authOptionButton.borderColor]',
            title: '#3.3 Border Color',
            type: 'boolean',
          },
          borderColor: {
            type: 'string',
          },
          'borderWidth:enable': {
            description: '[authentication.authOptionButton.borderWidth]',
            title: '#3.4 Border Width',
            type: 'boolean',
          },
          borderWidth: {
            type: 'integer',
          },
          'borderRadius:enable': {
            description: '[authentication.authOptionButton.borderRadius]',
            title: '#3.5 Border Radius',
            type: 'boolean',
          },
          borderRadius: {
            type: 'integer',
          },
          'iconBackgroundColor:enable': {
            description: '[authentication.authOptionButton.iconBackgroundColor]',
            title: '#3.6 Icon Background Color',
            type: 'boolean',
          },
          iconBackgroundColor: {
            type: 'string',
          },
          'iconColor:enable': {
            description: '[authentication.authOptionButton.iconColor]',
            title: '#3.7 Icon Color',
            type: 'boolean',
          },
          iconColor: {
            type: 'string',
          },
          'arrowColor:enable': {
            description: '[authentication.authOptionButton.arrowColor]',
            title: '#3.8 Arrow Color',
            type: 'boolean',
          },
          arrowColor: {
            type: 'string',
          },
        },
      },
      authMethods: {
        title: '#4 Auth Methods',
        type: 'object',
        properties: {
          'email:enable': {
            description: '[authentication.authMethods.email]',
            title: '#4.1 Email',
            type: 'boolean',
          },
          email: {
            type: undefined,
            enum: [true, false],
          },
          'phoneNumber:enable': {
            description: '[authentication.authMethods.phoneNumber]',
            title: '#4.2 Phone Number',
            type: 'boolean',
          },
          phoneNumber: {
            type: undefined,
            enum: [true, false],
          },
          'nameAndDepatureDate:enable': {
            description: '[authentication.authMethods.nameAndDepatureDate]',
            title: '#4.3 Name And Depature Date',
            type: 'boolean',
          },
          nameAndDepatureDate: {
            type: undefined,
            enum: [true, false],
          },
          'confirmationCode:enable': {
            description: '[authentication.authMethods.confirmationCode]',
            title: '#4.4 Confirmation Code',
            type: 'boolean',
          },
          confirmationCode: {
            type: undefined,
            enum: [true, false],
          },
          'confirmationCodeAndLastName:enable': {
            description: '[authentication.authMethods.confirmationCodeAndLastName]',
            title: '#4.5 Confirmation Code And Last Name',
            type: 'boolean',
          },
          confirmationCodeAndLastName: {
            type: undefined,
            enum: [true, false],
          },
        },
      },
      'titleTextColor:enable': {
        description: '[authentication.titleTextColor]',
        title: '#5 Title text color',
        type: 'boolean',
      },
      titleTextColor: {
        type: 'string',
      },
      'descriptionTextColor:enable': {
        description: '[authentication.descriptionTextColor]',
        title: '#6 Title text color',
        type: 'boolean',
      },
      descriptionTextColor: {
        type: 'string',
      },
      'headerIconBackgroundColor:enable': {
        description: '[authentication.headerIconBackgroundColor]',
        title: '#7 Header Icon Background Color',
        type: 'boolean',
      },
      headerIconBackgroundColor: {
        type: 'string',
      },
      'textFieldPlaceholderTextColor:enable': {
        description: '[authentication.textFieldPlaceholderTextColor]',
        title: '#8 Text Field Placeholder Text Color',
        type: 'boolean',
      },
      textFieldPlaceholderTextColor: {
        type: 'string',
      },
      'textFieldTextColor:enable': {
        description: '[authentication.textFieldTextColor]',
        title: '#9 Text Field Text Color',
        type: 'boolean',
      },
      textFieldTextColor: {
        type: 'string',
      },
      'headerIconColor:enable': {
        description: '[authentication.headerIconColor]',
        title: '#10 Header Icon Color',
        type: 'boolean',
      },
      headerIconColor: {
        type: 'string',
      },
      'tenantLogo:enable': {
        description: '[authentication.tenantLogo]',
        title: '#11 Tenant Logo',
        type: 'boolean',
      },
      tenantLogo: {
        type: 'string',
      },
      'tenantWelcomeImage:enable': {
        description: '[authentication.tenantWelcomeImage]',
        title: '#12 Tenant Welcome Image',
        type: 'boolean',
      },
      tenantWelcomeImage: {
        type: 'string',
      },
      'resendCodeInterval:enable': {
        description: '[authentication.resendCodeInterval]',
        title: '#13 Resend Code Interval',
        type: 'boolean',
      },
      resendCodeInterval: {
        type: 'number',
      },
      'remoteAssistanceTextColor:enable': {
        description: '[authentication.remoteAssistanceTextColor]',
        title: '#14 Remote Assistance Text Color',
        type: 'boolean',
      },
      remoteAssistanceTextColor: {
        type: 'string',
      },
      remoteAssistanceIcon: {
        title: '#15 Remote Assistance Icon',
        type: 'object',
        properties: {
          'url:enable': {
            description: '[authentication.remoteAssistanceIcon.email]',
            title: '#15.1 URL',
            type: 'boolean',
          },
          url: {
            type: 'string',
          },
          'width:enable': {
            description: '[authentication.remoteAssistanceIcon.width]',
            title: '#15.2 Width',
            type: 'boolean',
          },
          width: {
            type: 'number',
          },
          'height:enable': {
            description: '[authentication.remoteAssistanceIcon.height]',
            title: '#15.3 Height',
            type: 'boolean',
          },
          height: {
            type: 'number',
          },
        },
      },
      'tenantTitleWelcomeImage:enable': {
        description: '[authentication.tenantTitleWelcomeImage]',
        title: '#16 Tenant Title Welcome Image',
        type: 'boolean',
      },
      tenantTitleWelcomeImage: {
        type: 'string',
      },
      verifyButton: {
        title: '#17 Verify Button',
        type: 'object',
        properties: {
          enabled: {
            title: '#17.1 Enabled',
            type: 'object',
            properties: {
              'backgroundColor:enable': {
                description: '[authentication.verifyButton.enabled.backgroundColor]',
                title: '#17.1.1 Background Color',
                type: 'boolean',
              },
              backgroundColor: {
                type: 'string',
              },
              'textColor:enable': {
                description: '[authentication.verifyButton.enabled.textColor]',
                title: '#17.1.2 Text Color',
                type: 'boolean',
              },
              textColor: {
                type: 'string',
              },
              'borderColor:enable': {
                description: '[authentication.verifyButton.enabled.borderColor]',
                title: '#17.1.3 Border Color',
                type: 'boolean',
              },
              borderColor: {
                type: 'string',
              },
              'borderWidth:enable': {
                description: '[authentication.verifyButton.enabled.borderWidth]',
                title: '#17.1.4 Border Width',
                type: 'boolean',
              },
              borderWidth: {
                type: 'integer',
              },
            },
          },
          disabled: {
            title: '#17.2 Disabled',
            type: 'object',
            properties: {
              'backgroundColor:enable': {
                description: '[authentication.verifyButton.disabled.backgroundColor]',
                title: '#17.2.1 Background Color',
                type: 'boolean',
              },
              backgroundColor: {
                type: 'string',
              },
              'textColor:enable': {
                description: '[authentication.verifyButton.disabled.textColor]',
                title: '#17.2.2 Text Color',
                type: 'boolean',
              },
              textColor: {
                type: 'string',
              },
              'borderColor:enable': {
                description: '[authentication.verifyButton.disabled.borderColor]',
                title: '#17.2.3 Border Color',
                type: 'boolean',
              },
              borderColor: {
                type: 'string',
              },
              'borderWidth:enable': {
                description: '[authentication.verifyButton.disabled.borderWidth]',
                title: '#17.2.4 Border Width',
                type: 'boolean',
              },
              borderWidth: {
                type: 'integer',
              },
            },
          },
        },
      },
    },
  },
};

export const mobileV3ThemeAuthenticationUiSchema: NodeUiSchemaProperties = {
  authentication: {
    backgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    submitButton: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderWidth: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
      borderRadius: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
    },
    authOptionButton: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderWidth: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
      borderRadius: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
      iconBackgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      iconColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      arrowColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    authMethods: {
      email: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      phoneNumber: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      nameAndDepatureDate: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      confirmationCode: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      confirmationCodeAndLastName: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
    },
    titleTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    descriptionTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    headerIconBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    textFieldPlaceholderTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    textFieldTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    headerIconColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    tenantLogo: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
    },
    tenantWelcomeImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
    },
    remoteAssistanceTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    resendCodeInterval: {
      'ui:label': false,
      'ui:readonly': true,
    },
    remoteAssistanceIcon: {
      url: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
      width: {
        'ui:label': false,
        'ui:readonly': true,
      },
      height: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    tenantTitleWelcomeImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    verifyButton: {
      enabled: {
        backgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        borderColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        borderWidth: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'updown',
        },
      },
      disabled: {
        backgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        borderColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        borderWidth: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'updown',
        },
      },
    },
  },
};
