const paths = {
  ACCESS_GRANTS: '/access',
  FORGOT_PASSWORD: '/forgot-password',
  HOME: '/',
  KIOSKS: '/kiosks',
  LOCATIONS: '/locations',
  LOGIN: '/login',
  MARKETPLACE: '/marketplace',
  REMOTE_ASSISTANCE: '/remote-assistance',
  REPORTING: '/reporting',
  RESET_PASSWORD: '/reset-password',
  TENANT: '/tenant',
  USERS: '/users',
};

export type Paths = typeof paths;

export default {
  paths: paths as Paths,
};
