import * as yup from 'yup';

import { Query, QueryKioskArgs } from '../../__generated__/graphql';
import React, { useEffect } from 'react';

import FormTemplate from '../../templates/Form';
import GET_KIOSK from '../../graphql/getKiosk';
import KioskIssueKeycardSection from './components/KioskIssueKeycardSection';
import { flashApolloError } from '../../util/errorUtils';
import { useLazyQuery } from '@apollo/client';

type GetKioskData = Pick<Query, 'kiosk'>;

const KioskIssueKeycardForm: React.FC<{ id?: string }> = ({ id }: { id?: string }) => {
  const [getKiosk, { data }] = useLazyQuery<GetKioskData, QueryKioskArgs>(GET_KIOSK, {
    fetchPolicy: 'network-only',
    onError: flashApolloError,
  });

  useEffect(() => {
    if (id) {
      getKiosk({
        variables: {
          where: { id },
        },
      });
    }
  }, [getKiosk, id]);

  const kiosk = data?.kiosk ?? undefined;

  if (id && !kiosk) {
    return <div>Loading...</div>;
  }

  const KIOSK_ACTION_FORM_VALIDATION_SCHEMA = yup.object().shape({
    accessGrant: yup.object().shape({
      label: yup.string().required('AccessGrant is required'),
      value: yup.string().required('AccessGrant is required'),
    }),
  });

  return (
    <FormTemplate
      formHeader={`Issue Card From Kiosk ${kiosk?.name}`}
      initialValues={kiosk}
      steps={[
        {
          canBeAccessed: (): boolean => true,
          component: KioskIssueKeycardSection,
          name: 'Kiosk',
        },
      ]}
      validationSchema={KIOSK_ACTION_FORM_VALIDATION_SCHEMA}
    />
  );
};

export default KioskIssueKeycardForm;
