import Filter from '../../../../../components/DataTable/@types/Filter';
import ID_VERIFICATION_STATUS_TOOLTIPS from '../../../../../constants/tooltips/accessGrants/IdVerificationStatusTooltips';
import { RequirementStatus } from '../../../../../__generated__/graphql';

export default function addIdVerificationStatusFilters(filters: Filter[]): Filter[] {
  Object.values(RequirementStatus).forEach((status) => {
    filters.push({
      displayKey: 'ID Verification Status',
      displayValue: status,
      key: 'ivs',
      tooltip: ID_VERIFICATION_STATUS_TOOLTIPS[status],
      value: status,
    });
  });

  return filters;
}
