import { DAY, WEEK } from '../../../../../constants/time';
import Filter from '../../../../../components/DataTable/@types/Filter';

function generateFutureDates(): { key: string; value: number }[] {
  return [
    {
      key: '1d',
      value: DAY,
    },
    {
      key: '1w',
      value: WEEK,
    },
    {
      key: '30d',
      value: 30 * DAY,
    },
    {
      key: '90d',
      value: 90 * DAY,
    },
    {
      key: '180d',
      value: 180 * DAY,
    },
    {
      key: '1y',
      value: 365 * DAY,
    },
  ];
}

export default function addDatesFilters(filters: Filter[]): Filter[] {
  const dates = generateFutureDates();

  dates.forEach((date) => {
    filters.push({
      displayKey: 'Check-in',
      displayValue: `+${date.key}`,
      key: 'ci',
      value: (date.value / 1000).toString(),
    });

    filters.push({
      displayKey: 'Check-out',
      displayValue: `+${date.key}`,
      key: 'co',
      value: (date.value / 1000).toString(),
    });
  });

  dates.forEach((date) => {
    filters.push({
      displayKey: 'Check-in',
      displayValue: `-${date.key}`,
      key: 'ci',
      value: (-date.value / 1000).toString(),
    });

    filters.push({
      displayKey: 'Check-out',
      displayValue: `-${date.key}`,
      key: 'co',
      value: (-date.value / 1000).toString(),
    });
  });

  return filters;
}
