/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
export default function recursivelyRemoveTypenameProp(obj: any): any {
  Object.keys(obj).forEach((property) => {
    if (obj[property] && typeof obj[property] === 'object') {
      recursivelyRemoveTypenameProp(obj[property]);
    } else if (property === '__typename') {
      delete obj[property];
    }
  });
}
