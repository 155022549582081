import Form from '../../../Form';
import React from 'react';
import { RootState } from '../../../../redux/reducers';
import SelectOption from '../../../../@types/react/SelectOption';
import SimpleFormSection from '../../../../templates/SimpleFormSection';
import SubmitRow from '../../../SubmitRow';
import generateInfoItemSectionInputs from '../constants/SectionInputs';
import useConciergeCrud from '../hooks/useConciergeCrud';
import { useConciergeParentCategoriesWithState } from '../hooks/useConciergeCategories';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useSubmitInfoItem from '../hooks/useSubmitInfoItem';

const ConciergeItemSection: React.FC<{}> = () => {
  const { id } = useSelector((state: RootState) => state.modals.form);
  const { handleSubmit, watch } = useFormContext();
  const { deleteConciergeItem } = useConciergeCrud();
  const contentType: SelectOption = watch('contentType');
  const type: SelectOption = watch('type');

  const { submitHandler } = useSubmitInfoItem();
  const loadOptions = useConciergeParentCategoriesWithState({ contentType, type });

  const INFO_ITEM_SECTION_INPUTS = generateInfoItemSectionInputs({
    contentType,
    type,
    loadOptions,
  });

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <SimpleFormSection inputs={INFO_ITEM_SECTION_INPUTS} />
      <SubmitRow
        loading={false}
        submitText={id ? 'Update' : 'Save'}
        {...(id
          ? {
              cancelButtonText: 'Delete',
              showCancelButton: true,
              onCancelClick: deleteConciergeItem,
            }
          : {})}
      />
    </Form>
  );
};

export default ConciergeItemSection;
