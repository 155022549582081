import 'styled-components/macro';

import React, { useEffect } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';

import Colors from '../../../util/colors';
import Container from '../../Container';
import { FileDownloadIcon } from '../../FileDownloadIcon';
import Form from '../../Form';
import IMPORT_FROM_CSV_SECTION_INPUTS from '../../ImportFromCSVForm/constants/importFromCSVSectionInputs';
import { MdFileDownload } from 'react-icons/md';
import ResourceImportFormInputs from '../@types/ResourceImportFormInputs';
import SimpleFormSection from '../../../templates/SimpleFormSection';
import SubmitRow from '../../SubmitRow';
import { openConfirmationModal } from '../../../redux/actions/modals.actions';
import { useDispatch } from 'react-redux';
import useDownloadResourceCSVTemplate from '../../../hooks/useDownloadResourceCSVTemplate';
import { useParams } from 'react-router-dom';
import useUploadResources from '../../../hooks/useUploadResources';

const ImportResourcesSection: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { handleSubmit, setValue, watch } = useFormContext();

  const { locationId } = useParams<{ locationId: string }>();

  const { mutation, loading } = useUploadResources();

  const csvFile: File = watch('csvFile');

  const submitHandler: SubmitHandler<ResourceImportFormInputs> = async (params) => {
    dispatch(
      openConfirmationModal({
        action: (): void => {
          mutation({
            file: params.resourcesFile,
            locationId,
          });
        },
      })
    );
  };

  useEffect(() => {
    if (csvFile) {
      setValue('csvFile', csvFile);
    }
  }, [csvFile, setValue]);

  const { query } = useDownloadResourceCSVTemplate();

  const downloadCSVTemplate = (): void => {
    query();
  };

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Container>
        <Container>
          <SimpleFormSection inputs={IMPORT_FROM_CSV_SECTION_INPUTS} />
          <SubmitRow loading={loading} submitText="Upload resources" />
        </Container>
        <Container
          css={`
            margin-top: 10px;
            width: fit-content;
          `}
        >
          <FileDownloadIcon
            color={Colors.green}
            label="Download CSV template"
            icon={<MdFileDownload color="#fff" size="16px" />}
            onClick={(e: React.SyntheticEvent): void => {
              e.preventDefault();
              downloadCSVTemplate();
            }}
          />
        </Container>
      </Container>
    </Form>
  );
};

export default ImportResourcesSection;
