import * as React from 'react';
import TextInput from '../TextInput';
import TextInputProps from '../TextInput/@types/TextInputProps';
import { useFormContext } from 'react-hook-form';

type UrlInputProps = Omit<TextInputProps, 'type'> & { name: string; required?: boolean };

const UrlInput: React.FC<UrlInputProps> = (props: UrlInputProps) => {
  const { register } = useFormContext();

  return <TextInput ref={register({ required: props.required ?? false })} {...props} />;
};

export default UrlInput;
