import ToggleSelectorOption from '../@types/ToggleSelectorOption';

type UseOptionsHelperProps = {
  options: ToggleSelectorOption[] | string[];
};

type UseOptionsHelperResult = {
  optionsToSelectOption: () => ToggleSelectorOption[];
  optionValuesToString: () => string[];
};

const useOptionsHelper = ({ options }: UseOptionsHelperProps): UseOptionsHelperResult => {
  const optionsToSelectOption = (): ToggleSelectorOption[] => {
    if (!options || options.length === 0) {
      return [];
    }
    if (typeof options[0] === 'object') {
      return options as ToggleSelectorOption[];
    }
    return (options as string[]).map((option) => {
      return { displayText: option, value: option };
    });
  };

  const optionValuesToString = (): string[] => {
    if (!options || options.length === 0) {
      return [];
    }
    if (typeof options[0] === 'string') {
      return options as string[];
    }
    return (options as ToggleSelectorOption[]).map((option) => option.value);
  };

  return {
    optionsToSelectOption,
    optionValuesToString,
  };
};

export default useOptionsHelper;
