import Theme from '../../../theme';
import styled from 'styled-components';

export const EarlyCheckInInputInstructions = styled.div`
  color: ${Theme.colors.white.hexCode};
  font-size: 15px;
  width: 350px;
  display: flex;
  align-items: center;
`;
