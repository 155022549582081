export default function freezeObject(data: Record<string, unknown>): void {
  const forEachFunction = (
    _data: Record<string, unknown>,
    parentKey: string,
    key: string
  ): void => {
    if (typeof _data[key] === 'object') {
      if (_data[key]) {
        // eslint-disable-next-line no-restricted-syntax
        for (const childKey of Object.keys(_data[key] as Record<string, unknown>)) {
          forEachFunction(_data[key] as Record<string, unknown>, `${parentKey}.${key}`, childKey);
          Object.freeze(_data);
        }
      }
    }
  };

  if (data) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(data)) {
      forEachFunction(data, '', key);
      Object.freeze(data[key]);
    }
  }
}
