import styled from 'styled-components';

interface TableProps extends React.HTMLAttributes<HTMLTableElement> {
  columns: number;
}

const Table = styled.table<TableProps>`
  background: transparent;
  display: grid;
  border-collapse: collapse;
  min-width: 100%;
  grid-auto-flow: row;
  grid-template-columns: ${({ columns }): string => {
    return `min-content repeat(${columns - 1}, minmax(100px, 200px))  minmax(100px, 1fr)`;
  }};
  overflow: hidden;
`;

Table.displayName = 'StyledTable';

export default Table;
