import { Query, QueryAccessGrantsArgs, SortOrder } from '../__generated__/graphql';

import QueryHookData from './@types/QueryHookData';
import QueryHookParams from './@types/QueryHookParams';
import SEARCH_ACCESS_GRANTS from '../graphql/searchAccessGrants';
import { useLazyQuery } from '@apollo/client';

type SearchAccessGrantsData = Pick<Query, 'accessGrants'>;

export default function useSearchAccessGrantsByConfCode(params?: QueryHookParams): QueryHookData {
  const { onCompleted = (): void => {}, onError = (): void => {}, searchValue = '' } = params ?? {};

  const [getLocations, { data, error, loading }] = useLazyQuery<
    SearchAccessGrantsData,
    QueryAccessGrantsArgs
  >(SEARCH_ACCESS_GRANTS, {
    onCompleted,
    onError,
  });

  const accessGrants = data?.accessGrants ?? [];

  return {
    data: accessGrants.map((accessGrant) => ({
      id: accessGrant.id,
      name: accessGrant.confirmationCode ?? accessGrant.id,
    })),
    error,
    loading,
    query: (confirmationCode = ''): void => {
      getLocations({
        variables: {
          orderBy: [{ confirmationCode: { sort: SortOrder.Asc } }],
          take: 10,
          where: {
            confirmationCode: { contains: confirmationCode ?? searchValue },
          },
        },
      });
    },
  };
}
