import styled from 'styled-components';

interface BooleanSwitchProps {
  on: boolean;
}

const BooleanSwitch = styled.div<BooleanSwitchProps>`
  background: #ffffff;
  cursor: pointer;
  display: inline-block;
  height: 16px;
  position: relative;
  user-select: none;
  width: 26px;

  &::before,
  &::after {
    content: '';
    left: 0;
    position: absolute;
  }

  &::before {
    border-radius: 16px;
    height: inherit;
    transition: background 0.4s 0.3s ease-out;
    width: inherit;
    will-change: background;
  }

  &::after {
    background: #ffffff;
    border-radius: 6px;
    height: 12px;
    top: 2px;
    transition: transform 0.4s ease-out;
    width: 12px;
    will-change: transform;
  }

  ${({ on }): string => {
    if (on) {
      return `      
        &::after {
          transform: translateX(12px);
        }

        &::before {
          background: #62c28e;
        }
      `;
    }

    return `
      &::after {
        transform: translateX(2px);
      }
      
      &::before {
        background: #cccccc;
      }
    `;
  }}
`;

BooleanSwitch.displayName = 'StyledToggleSwitch';

export default BooleanSwitch;
