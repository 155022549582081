import Theme from '../../../theme';

const VideoCallContainerStyle = `
  background: ${Theme.colors.darkAlternate.hexCode};
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  .local-participant video {
    bottom: 0;
    height: auto;
    position: absolute;
    right: 0;
    width: 400px;
    z-index: 2;
  }

  .remote-participants video {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &.minimized-video-call {
    .local-participant video {
      height: 40%;
      width: auto;
    }
  }
`;

export default VideoCallContainerStyle;
