import InputGroupProps from '../../InputGroup/@types/InputGroupProps';
import React from 'react';
import { RequirementType } from '../../../__generated__/graphql';
import SimpleFormSection from '../../../templates/SimpleFormSection';

interface MetaDataProps {
  index: number;
  option: string;
}

export const Metadata = ({ index, option }: MetaDataProps): JSX.Element | null => {
  if (option === RequirementType.IdVerification || option === RequirementType.MrzIdVerification) {
    const inputGroups: InputGroupProps[] = [];
    inputGroups.push({
      isFieldArray: true,
      label: 'Minimum Age',
      min: 0,
      name: `defaultRequirements.values[${index}].metadata.minimumAge`,
      type: 'number',
    });

    return <SimpleFormSection columns="1fr 1fr" inputs={inputGroups} />;
  }
  return null;
};
