import { ApolloClient, InMemoryCache } from '@apollo/client';

import { createUploadLink } from 'apollo-upload-client';
import { generateRequestId } from './util/generateRequestId';
import { setContext } from '@apollo/client/link/context';

export const apolloRequirePreflightHeader = {
  'Apollo-Require-Preflight': 'true',
};

const authLink = setContext((_, { sendAccessToken = true, headers }) => {
  /* get the authentication token from local storage if it exists */
  const token = localStorage.getItem('ACCESS_TOKEN');

  const reqId = generateRequestId();

  const requestHeaders = {
    ...headers,
    authorization: token && sendAccessToken ? `Bearer ${token}` : '',
    'X-Request-Id': reqId,
    'X-Virdee-Request-Origin': 'dashboard',
  };

  /* return the headers to the context so httpLink can read them */
  return {
    headers: requestHeaders,
  };
});

const client = new ApolloClient({
  // Provide required constructor fields
  cache: new InMemoryCache({}),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
  link: authLink.concat(
    createUploadLink({
      uri: `${process.env.REACT_APP_GRAPHQL_URI}/graphql`,
    })
  ),

  // Provide some optional constructor fields
  name: 'virdee-web-client',
  queryDeduplication: false,
  version: '1.3',
});

export default client;
