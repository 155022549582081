import 'styled-components/macro';

import DataTable from '../../../components/DataTable';
import { EntityEnum } from '../../../@types/client/DashboardUserRolePermissions';
import React from 'react';
import { RetryCheckinStatus } from '../../../__generated__/graphql';
import useAccessGrantsTableData from '../../../hooks/useAccessGrantsTableData';

const RoomNotReadyQueueTab: React.FC<{}> = () => {
  const tableData = useAccessGrantsTableData({
    dataTableKey: 'accessGrants-RoomNotReadyQueue',
    permission: EntityEnum.NONE,
    queryOptions: {
      where: {
        retryCheckin: {
          equals: RetryCheckinStatus.Active,
        },
      },
    },
  });

  return <DataTable {...tableData} />;
};

export default RoomNotReadyQueueTab;
