import { Credential, Location } from '../../../__generated__/graphql';

import DataTable from '../../../components/DataTable';
import React from 'react';
import { UserOnAG } from '../@types/GetAccessGrantPageInfo';
import useGuestsOnAccessGrantTableData from '../../../hooks/useGuestsOnAccessGrantTableData';

export type AccessGrantGuests = {
  id: string;
  credentials: Pick<Credential, 'id' | 'isActive' | 'userId'>[];
  location: Pick<Location, 'timezone'>;
  user: UserOnAG;
  secondaryUsers: { user: UserOnAG }[];
};
interface GuestsOnAGTabProps {
  accessGrant: AccessGrantGuests;
}

const GuestsTab: React.FC<GuestsOnAGTabProps> = ({ accessGrant }: GuestsOnAGTabProps) => {
  const tableData = useGuestsOnAccessGrantTableData({
    accessGrant,
  });

  return <DataTable {...tableData} />;
};

export default GuestsTab;
