import DataTableReduxState from '../../@types/DataTableReduxState';
import { SetSortOptionsPayload } from '../../../actions/@types/SetSortAction';

export default function setSortAction(
  currentState: DataTableReduxState,
  actionPayload: SetSortOptionsPayload
): DataTableReduxState {
  const { sortOptions, dataTableKey: sortKey } = actionPayload;
  const newSortOptions = { ...currentState.sortOptions };

  newSortOptions[sortKey] = sortOptions;

  return {
    ...currentState,
    sortOptions: newSortOptions,
  };
}
