import React, { useContext } from 'react';

import Form from '../../Form';
import FormContext from '../../../contexts/form.context';
import LocationFormInputs from '../@types/LocationFormInputs';
import MultiSelect from '../../MultiSelect';
import { RequirementType } from '../../../__generated__/graphql';
import SubmitRow from '../../SubmitRow';
import { flashApolloError } from '../../../util/errorUtils';
import formatRequirement from '../functions/formatRequirement';
import { openConfirmationModal } from '../../../redux/actions/modals.actions';
import useCreateLocation from '../../../hooks/useCreateLocation';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import useUpdateLocation from '../../../hooks/useUpdateLocation';
import values from 'lodash/values';

const DefaultRequirementsSection: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { handleSubmit } = useFormContext();

  const { identifier } = useContext(FormContext);

  const useMutation = identifier ? useUpdateLocation : useCreateLocation;

  const { loading, mutation } = useMutation({
    onError: flashApolloError,
  });

  function submitHandler(inputs: LocationFormInputs): void {
    dispatch(
      openConfirmationModal({
        action: (): void => {
          mutation(inputs, identifier);
        },
      })
    );
  }

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <MultiSelect
        formatOption={formatRequirement}
        name="defaultRequirements.values"
        options={values(RequirementType)}
      />
      <SubmitRow loading={loading} submitText="Submit" />
    </Form>
  );
};

export default DefaultRequirementsSection;
