import GetKiosksLiveUsageParams, {
  KioskAndAccessGrantParams,
} from './@types/GetKiosksLiveUsageArgs';

import GET_KIOSKS_LIVE_USAGE_DATA from './graphql/getKiosksLiveUsageDataQuery';
import GetKiosksLiveUsageData from './@types/GetKiosksLiveUsageData';
import { flashApolloError } from '../../util/errorUtils';
import { loadKioskLiveUsageData } from '../../redux/actions/kiosksLiveUsage.actions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLazyQuery } from '@apollo/client';

type UseGetKiosksLiveUsageDataResponse = {
  isLoading: boolean;
  query: (kioskAndAccessGrantParams: KioskAndAccessGrantParams[]) => void;
};

export default function useGetKiosksLiveUsageData(): UseGetKiosksLiveUsageDataResponse {
  const dispatch = useDispatch();

  const [getKiosksLiveUsage, { loading }] = useLazyQuery<
    GetKiosksLiveUsageData,
    GetKiosksLiveUsageParams
  >(GET_KIOSKS_LIVE_USAGE_DATA, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getKiosksLiveUsageData: { data } }: GetKiosksLiveUsageData): void => {
      if (data && data.length > 0) {
        dispatch(loadKioskLiveUsageData(data));
      }
    },
    onError: flashApolloError,
  });

  const query = useCallback(
    (kioskAndAccessGrantParams: KioskAndAccessGrantParams[]) => {
      getKiosksLiveUsage({
        variables: {
          kioskAndAccessGrantParams,
        },
      });
    },
    [getKiosksLiveUsage]
  );

  return {
    isLoading: loading,
    query,
  };
}
