import { Kiosk } from '../../__generated__/graphql';
import { MINUTE } from '../../constants/time';

const TIME_TO_DETERMINATE_PAYMENT_TERMINAL_IS_OFFLINE = 5 * MINUTE;

export function getPaymentTerminalOfflineMS(): number {
  const isOfflineIf = Date.now() - TIME_TO_DETERMINATE_PAYMENT_TERMINAL_IS_OFFLINE;
  return isOfflineIf;
}

export default function isPaymentTerminal(kiosk: Pick<Kiosk, 'terminalLastSeenAt'>): boolean {
  if (kiosk.terminalLastSeenAt) {
    const dateInSeconds = Date.parse(kiosk.terminalLastSeenAt);

    if (dateInSeconds) {
      return dateInSeconds >= getPaymentTerminalOfflineMS();
    }
  }

  return false;
}
