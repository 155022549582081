import { gql } from '../__generated__/gql';

const GET_USER_MEMBERSHIPS = gql(`
  query GetUserMemberships {
    getUserMemberships {
      id
      name
      locations {
        id
        name
      }
    }
  }
`);

export default GET_USER_MEMBERSHIPS;
