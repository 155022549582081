import 'styled-components/macro';

import React, { useEffect, useRef } from 'react';
import { WidgetProps } from '@rjsf/core';

const CustomAutoGeneratedIdWidget: React.FC<WidgetProps> = (props: WidgetProps) => {
  const ref = useRef<HTMLInputElement | null>(null);

  const styles = {
    borderRadius: 5,
    color: 'black',
    height: 40,
    padding: '0px 10px',
    width: '100%',
  };

  useEffect(() => {
    if (props.value === undefined) {
      const timeoutId = setTimeout(() => {
        props.onChange(`${crypto.randomUUID()}-${new Date().getTime()}`);
        const event = new Event('input', { bubbles: true });
        ref.current?.dispatchEvent(event);
      }, 100);
      return (): void => clearTimeout(timeoutId);
    }

    return (): void => {};
  }, []);

  return (
    <div>
      <input
        ref={ref}
        type="text"
        className="custom"
        style={styles}
        value={props.value ?? ''}
        required={props.required}
        disabled={true}
      />
    </div>
  );
};

export default CustomAutoGeneratedIdWidget;
