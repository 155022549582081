import { Role } from '../../__generated__/graphql';

export enum EntityEnum {
  ACCESS_GRANT = 'ACCESS_GRANT', // Currently, the only permission on backend it's ACCESS_GRANT
  ALL_PERMISSIONS = 'ALL_PERMISSIONS',
  AMENITY = 'AMENITY',
  CREDENTIAL = 'CREDENTIAL',
  INFO_ITEM = 'INFO_ITEM',
  KIOSK = 'KIOSK',
  LOCATION = 'LOCATION',
  PAYMENT = 'PAYMENT',
  NONE = 'NONE',
  RESOURCE_GROUP = 'RESOURCE_GROUP',
  RESOURCE = 'RESOURCE',
  SERVICE = 'SERVICE',
  TENANT = 'TENANT',
  UNIT_TYPE = 'UNIT_TYPE',
  USER = 'USER',
}

export enum ActionEnum {
  CREATE = 'CREATE',
  CREATE_ADMIN = 'CREATE_ADMIN',
  DELETE = 'DELETE',
  DELETE_ADMIN = 'DELETE_ADMIN',
  IMPORT = 'IMPORT',
  READ = 'READ',
  READ_ADMIN = 'READ_ADMIN',
  UPDATE = 'UPDATE',
  UPDATE_ADMIN = 'UPDATE_ADMIN',
}

export type DashboardUserPermissions = {
  [entity in EntityEnum]?: {
    [action in ActionEnum]?: boolean;
  };
};

type DashboardUserRolePermissions = {
  [role in Role]?: DashboardUserPermissions;
};

export default DashboardUserRolePermissions;
