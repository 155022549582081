import Video from 'twilio-video';

export default function disconnectCall(room: Video.Room | null): void {
  if (room?.localParticipant.state === 'connected') {
    room.localParticipant.tracks.forEach((trackPublication) => {
      (trackPublication.track as { stop: () => void }).stop();
    });

    room.disconnect();
  }
}
