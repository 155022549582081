import { gql } from '../__generated__/gql';

const UPDATE_VENDOR_SERVER = gql(`
  mutation UpdateOneVendorServer(
    $data: VendorServerUpdateInput!
    $where: VendorServerWhereUniqueInput!
  ) {
    updateOneVendorServer(data: $data, where: $where) {
      id
      vendor
    }
  }
`);

export default UPDATE_VENDOR_SERVER;
