import { SubmitHandler, useFormContext } from 'react-hook-form';
import { closeForm, openConfirmationModal } from '../../../redux/actions/modals.actions';
import { useDispatch, useSelector } from 'react-redux';

import Form from '../../Form';
import ISSUE_KEY_FOR_KIOSK_WITH_ACCESS_GRANT_ID from '../../../graphql/issueKeyForKioskWithAccessGrantId';
import InputGroupProps from '../../InputGroup/@types/InputGroupProps';
import KioskIssueKeycardFormInputs from '../@types/KioskIssueKeycardFormInputs';
import React from 'react';
import { RootState } from '../../../redux/reducers';
import SimpleFormSection from '../../../templates/SimpleFormSection';
import SubmitRow from '../../SubmitRow';
import { flashApolloError } from '../../../util/errorUtils';
import useLoadAccessGrantOptions from '../../../hooks/useLoadAccessGrantOptions';
import { useMutation } from '@apollo/client';
import useSearchAccessGrantsByConfCode from '../../../hooks/useSearchAccessGrants';

const KioskIssueKeycardSection: React.FC<{}> = () => {
  const kioskId = useSelector((state: RootState) => state.modals.form.id);

  const { handleSubmit } = useFormContext();
  const dispatch = useDispatch();
  const [issueKeyForKioskWithAccessGrantId, { loading }] = useMutation(
    ISSUE_KEY_FOR_KIOSK_WITH_ACCESS_GRANT_ID,
    {
      onCompleted: () => {
        dispatch(closeForm());

        window.flash({
          message: `Keycard has been issued successfully`,
        });
      },
      onError: flashApolloError,
    }
  );

  const submitHandler: SubmitHandler<KioskIssueKeycardFormInputs> = (
    inputs: KioskIssueKeycardFormInputs
  ) => {
    const variables = { accessGrantId: inputs.accessGrant.value, kioskId };
    dispatch(
      openConfirmationModal({
        action: (): void => {
          issueKeyForKioskWithAccessGrantId({
            variables,
          });
        },
      })
    );
  };

  const inputs: InputGroupProps[] = [
    {
      label: 'Search Access Grant (Confirmation #)',
      loadOptions: useLoadAccessGrantOptions,
      name: 'accessGrant',
      query: useSearchAccessGrantsByConfCode,
      type: 'async-select',
    },
  ];

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <SimpleFormSection inputs={inputs} />
      <SubmitRow loading={loading} submitText={'Submit'} />
    </Form>
  );
};

export default KioskIssueKeycardSection;
