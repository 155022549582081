import { Mutation, QueryAccessGrantArgs } from '../__generated__/graphql';

import CANCEL_ACCESS_GRANT from '../graphql/cancelAccessGrant';
import RemoveHookData from './@types/RemoveHookData';
import { closeRemoveModal } from '../redux/actions/modals.actions';
import { flashApolloError } from '../util/errorUtils';
import links from '../util/links';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

type CancelAccessGrantData = Pick<Mutation, 'cancelAccessGrant'>;

export default function useRemoveAccessGrant(): RemoveHookData {
  const dispatch = useDispatch();
  const history = useHistory();

  const [remove, { error, loading }] = useMutation<CancelAccessGrantData, QueryAccessGrantArgs>(
    CANCEL_ACCESS_GRANT,
    {
      onCompleted: ({ cancelAccessGrant }) => {
        dispatch(closeRemoveModal());

        window.flash({
          message: `AG: ${cancelAccessGrant.confirmationCode} removed successfully`,
        });

        history.replace(links.paths.ACCESS_GRANTS);
      },
      onError: flashApolloError,
    }
  );

  return {
    error,
    loading,
    mutation: (id: string): void => {
      remove({
        variables: {
          where: { id },
        },
      });
    },
  };
}
