import React from 'react';
import Theme from '../../theme';

interface SVGProps {
  height?: string;
  fill?: string;
  width?: string;
}

const MarkerIcon: React.FunctionComponent<SVGProps> = ({
  height = '19px',
  fill = Theme.colors.dark.hexCode,
  width = '16px',
}: SVGProps) => {
  const viewBox = `0 0 ${width} ${height}`.replace(/px/g, '');

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Locations</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Dashboard_-1-Copy-12" transform="translate(-36.000000, -346.000000)" fill={fill}>
          <g id="Group-17" transform="translate(24.660000, 335.721289)">
            <g id="Group-9" transform="translate(12.150000, 10.530000)">
              <path
                d="M11.049615,12.348045 C12.184263,10.773081 12.96,9.042111 12.96,7.29 C12.96,4.1585481 10.42146,1.62 7.29,1.62 C4.1585481,1.62 1.62,4.1585481 1.62,7.29 C1.62,9.042111 2.3957289,10.773081 3.5303526,12.348045 C4.6564308,13.911102 6.041061,15.187743 7.028289,15.996285 C7.190208,16.128882 7.389792,16.128882 7.551711,15.996285 C8.538939,15.187743 9.923553,13.911102 11.049615,12.348045 Z M8.578224,17.249517 C10.671426,15.535071 14.58,11.738277 14.58,7.29 C14.58,3.2638464 11.316186,7.28306304e-14 7.29,7.28306304e-14 C3.2638464,7.28306304e-14 0,3.2638464 0,7.29 C0,11.738277 3.9085497,15.535071 6.001776,17.249517 C6.760746,17.871111 7.819254,17.871111 8.578224,17.249517 Z"
                id="Shape"
              ></path>
              <path
                d="M7.29,5.67 C6.395274,5.67 5.67,6.3952983 5.67,7.29 C5.67,8.184726 6.395274,8.91 7.29,8.91 C8.184726,8.91 8.91,8.184726 8.91,7.29 C8.91,6.3952983 8.184726,5.67 7.29,5.67 Z M4.05,7.29 C4.05,5.5005966 5.5005966,4.05 7.29,4.05 C9.079371,4.05 10.53,5.5005966 10.53,7.29 C10.53,9.079371 9.079371,10.53 7.29,10.53 C5.5005966,10.53 4.05,9.079371 4.05,7.29 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MarkerIcon;
