// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isStringArray = (arr: any[]): boolean => {
  return (
    arr.length > 0 &&
    arr.every((value) => {
      return typeof value === 'string';
    })
  );
};

export default isStringArray;
