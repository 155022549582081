import { gql } from '../__generated__/gql';

const UPDATE_SERVICE = gql(`
  mutation updateServiceAndUploadImageFile(
    $data: CustomServiceUpdateInput!
    $where: ServiceWhereUniqueInput!
  ) {
    updateServiceAndUploadImageFile(data: $data, where: $where) {
      ...ServiceFields
    }
  }
`);

export default UPDATE_SERVICE;
