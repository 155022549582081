/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationPackagesSchema: NodeSchemaProperties = {
  packagesScreen: {
    title: '#7 Packages Screen',
    type: 'object',
    properties: {
      'skipButton:enable': {
        description: '[packagesScreen.skipButton]',
        title: '#1 Skip Button',
        type: 'boolean',
      },
      skipButton: {
        type: 'string',
      },
      'viewCart:enable': {
        description: '[packagesScreen.viewCart]',
        title: '#2 View Cart',
        type: 'boolean',
      },
      viewCart: {
        type: 'string',
      },
      'booked:enable': {
        description: '[packagesScreen.booked]',
        title: '#3 Booked',
        type: 'boolean',
      },
      booked: {
        type: 'string',
      },
      'packages:enable': {
        description: '[packagesScreen.packages]',
        title: '#4 Packages',
        type: 'boolean',
      },
      packages: {
        type: 'string',
      },
      'subheader:enable': {
        description: '[packagesScreen.subheader]',
        title: '#5 Subheader',
        type: 'boolean',
      },
      subheader: {
        type: 'string',
      },
      'description:enable': {
        description: '[packagesScreen.description]',
        title: '#6 Description',
        type: 'boolean',
      },
      description: {
        type: 'string',
      },
      'category:enable': {
        description: '[packagesScreen.category]',
        title: '#7 Category',
        type: 'boolean',
      },
      category: {
        type: 'string',
      },
      success: {
        title: '#8 Success',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[packagesScreen.success.title]',
            title: '#8.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'description:enable': {
            description: '[packagesScreen.success.description]',
            title: '#8.2 Description',
            type: 'boolean',
          },
          description: {
            type: 'string',
          },
          'buttonTitle:enable': {
            description: '[packagesScreen.success.buttonTitle]',
            title: '#8.3 Button Title',
            type: 'boolean',
          },
          buttonTitle: {
            type: 'string',
          },
        },
      },
      failure: {
        title: '#9 Failure',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[packagesScreen.failure.title]',
            title: '#9.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'description:enable': {
            description: '[packagesScreen.failure.description]',
            title: '#9.2 Description',
            type: 'boolean',
          },
          description: {
            type: 'string',
          },
          'buttonTitle:enable': {
            description: '[packagesScreen.failure.buttonTitle]',
            title: '#9.3 Button Title',
            type: 'boolean',
          },
          buttonTitle: {
            type: 'string',
          },
        },
      },
      cannotRemovePrebooked: {
        title: '#10 Cannot Remove Prebooked',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[packagesScreen.cannotRemovePrebooked.title]',
            title: '#10.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'description:enable': {
            description: '[packagesScreen.cannotRemovePrebooked.description]',
            title: '#10.2 Description',
            type: 'boolean',
          },
          description: {
            type: 'string',
          },
          'buttonTitle:enable': {
            description: '[packagesScreen.cannotRemovePrebooked.buttonTitle]',
            title: '#10.3 Button Title',
            type: 'boolean',
          },
          buttonTitle: {
            type: 'string',
          },
        },
      },
      reachMaximumAvailableQuantity: {
        title: '#11 Reach Maximum Available Quantity',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[packagesScreen.reachMaximumAvailableQuantity.title]',
            title: '#11.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'description:enable': {
            description: '[packagesScreen.reachMaximumAvailableQuantity.description]',
            title: '#11.2 Description',
            type: 'boolean',
          },
          description: {
            type: 'string',
          },
          'buttonTitle:enable': {
            description: '[packagesScreen.reachMaximumAvailableQuantity.buttonTitle]',
            title: '#11.3 Button Title',
            type: 'boolean',
          },
          buttonTitle: {
            type: 'string',
          },
        },
      },
      itemCard: {
        title: '#12 Item Card',
        type: 'object',
        properties: {
          'add:enable': {
            description: '[packagesScreen.itemCard.add]',
            title: '#12.1 Add',
            type: 'boolean',
          },
          add: {
            type: 'string',
          },
        },
      },
      cartModal: {
        title: '#13 Cart Modal',
        type: 'object',
        properties: {
          'cart:enable': {
            description: '[packagesScreen.cartModal.cart]',
            title: '#13.1 Cart',
            type: 'boolean',
          },
          cart: {
            type: 'string',
          },
          'total:enable': {
            description: '[packagesScreen.cartModal.total]',
            title: '#13.2 Total',
            type: 'boolean',
          },
          total: {
            type: 'string',
          },
          'submit:enable': {
            description: '[packagesScreen.cartModal.submit]',
            title: '#13.3 Submit',
            type: 'boolean',
          },
          submit: {
            type: 'string',
          },
          'cartSubheader:enable': {
            description: '[packagesScreen.cartModal.cartSubheader]',
            title: '#13.4 Cart Subheader',
            type: 'boolean',
          },
          cartSubheader: {
            type: 'string',
          },
          'cartDescription:enable': {
            description: '[packagesScreen.cartModal.cartDescription]',
            title: '#13.5 Cart Description',
            type: 'boolean',
          },
          cartDescription: {
            type: 'string',
          },
          'emptyCartTitle:enable': {
            description: '[packagesScreen.cartModal.emptyCartTitle]',
            title: '#13.6 Empty Cart Title',
            type: 'boolean',
          },
          emptyCartTitle: {
            type: 'string',
          },
          'emptyCartDescription:enable': {
            description: '[packagesScreen.cartModal.emptyCartDescription]',
            title: '#13.7 Empty Cart Description',
            type: 'boolean',
          },
          emptyCartDescription: {
            type: 'string',
          },
        },
      },
      filterModal: {
        title: '#14 Filter Modal',
        type: 'object',
        properties: {
          'categories:enable': {
            description: '[packagesScreen.filterModal.categories]',
            title: '#14.1 Categories',
            type: 'boolean',
          },
          categories: {
            type: 'string',
          },
          'cancel:enable': {
            description: '[packagesScreen.filterModal.cancel]',
            title: '#14.2 Cancel',
            type: 'boolean',
          },
          cancel: {
            type: 'string',
          },
          'apply:enable': {
            description: '[packagesScreen.filterModal.apply]',
            title: '#14.3 Apply',
            type: 'boolean',
          },
          apply: {
            type: 'string',
          },
          'filter:enable': {
            description: '[packagesScreen.filterModal.filter]',
            title: '#14.4 Filter',
            type: 'boolean',
          },
          filter: {
            type: 'string',
          },
        },
      },
      itemDetailsModal: {
        title: '#15 Item Details Modal',
        type: 'object',
        properties: {
          'addModal:enable': {
            description: '[packagesScreen.itemDetailsModal.addModal]',
            title: '#15.1 Add Modal',
            type: 'boolean',
          },
          addModal: {
            type: 'string',
          },
          'prebookedAmountText:enable': {
            description: '[packagesScreen.itemDetailsModal.prebookedAmountText]',
            title: '#15.2 Prebooked Amount Text',
            type: 'boolean',
          },
          prebookedAmountText: {
            type: 'string',
          },
          error: {
            title: '#15.3 Error',
            type: 'object',
            properties: {
              'title:enable': {
                description: '[packagesScreen.itemDetailsModal.error.title]',
                title: '#15.3.1 Title',
                type: 'boolean',
              },
              title: {
                type: 'string',
              },
              'description:enable': {
                description: '[packagesScreen.itemDetailsModal.error.description]',
                title: '#15.3.2 Description',
                type: 'boolean',
              },
              description: {
                type: 'string',
              },
              'buttonTitle:enable': {
                description: '[packagesScreen.itemDetailsModal.error.buttonTitle]',
                title: '#15.3.3 Button Title',
                type: 'boolean',
              },
              buttonTitle: {
                type: 'string',
              },
            },
          },
        },
      },
      widget: {
        title: '#16 Widget',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[packagesScreen.widget.title]',
            title: '#16.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'seeMore:enable': {
            description: '[packagesScreen.widget.seeMore]',
            title: '#16.2 See More',
            type: 'boolean',
          },
          seeMore: {
            type: 'string',
          },
        },
      },
      'currency:enable': {
        description: '[packagesScreen.currency]',
        title: '#17 Currency',
        type: 'boolean',
      },
      currency: {
        type: 'string',
      },
      'add:enable': {
        description: '[packagesScreen.add]',
        title: '#18 Package detail add button',
        type: 'boolean',
      },
      add: {
        type: 'string',
      },
      'title:enable': {
        description: '[packagesScreen.title]',
        title: '#19 Title',
        type: 'boolean',
      },
      title: {
        type: 'string',
      },
    },
  },
};

export const mobileV3LocalizationPackagesUiSchema: NodeUiSchemaProperties = {
  packagesScreen: {
    skipButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    viewCart: {
      'ui:label': false,
      'ui:readonly': true,
    },
    booked: {
      'ui:label': false,
      'ui:readonly': true,
    },
    packages: {
      'ui:label': false,
      'ui:readonly': true,
    },
    subheader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    category: {
      'ui:label': false,
      'ui:readonly': true,
    },
    success: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
      buttonTitle: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    failure: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
      buttonTitle: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    cannotRemovePrebooked: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
      buttonTitle: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    reachMaximumAvailableQuantity: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
      buttonTitle: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    itemCard: {
      add: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    cartModal: {
      cart: {
        'ui:label': false,
        'ui:readonly': true,
      },
      total: {
        'ui:label': false,
        'ui:readonly': true,
      },
      submit: {
        'ui:label': false,
        'ui:readonly': true,
      },
      cartSubheader: {
        'ui:label': false,
        'ui:readonly': true,
      },
      cartDescription: {
        'ui:label': false,
        'ui:readonly': true,
      },
      emptyCartTitle: {
        'ui:label': false,
        'ui:readonly': true,
      },
      emptyCartDescription: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    filterModal: {
      categories: {
        'ui:label': false,
        'ui:readonly': true,
      },
      cancel: {
        'ui:label': false,
        'ui:readonly': true,
      },
      apply: {
        'ui:label': false,
        'ui:readonly': true,
      },
      filter: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    itemDetailsModal: {
      addModal: {
        'ui:label': false,
        'ui:readonly': true,
      },
      prebookedAmountText: {
        'ui:label': false,
        'ui:readonly': true,
      },
      error: {
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
        buttonTitle: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
    },
    widget: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      seeMore: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    currency: {
      'ui:label': false,
      'ui:readonly': true,
    },
    add: {
      'ui:label': false,
      'ui:readonly': true,
    },
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
};
