import DataTable from '../../../components/DataTable';
import { EntityEnum } from '../../../@types/client/DashboardUserRolePermissions';
import React from 'react';
import useCallRecordsTableData from '../../../hooks/useCallRecordsTableData';

const CallRecordsTab: React.FC<{}> = () => {
  const tableData = useCallRecordsTableData({
    permission: EntityEnum.NONE,
  });

  return <DataTable {...tableData} />;
};

export default CallRecordsTab;
