import { ResourceWhereInput } from '../../../__generated__/graphql';
import SearchField from '../../../@types/SearchField';
import createSearchField from '../../../util/tableData/createSearchField';
import transformStringFilter from '../../../util/tableData/transformStringFilter';

const RESOURCES_TABLE_SEARCH_FIELDS: SearchField<ResourceWhereInput>[] = [
  createSearchField<ResourceWhereInput, 'name'>({
    label: 'Name',
    queryField: 'name',
    transform: transformStringFilter,
  }),
];

export default RESOURCES_TABLE_SEARCH_FIELDS;
