import { Colors, RGB } from '../../../theme/colors';

const DropdownButtonStyle = `
    background: ${Colors.dodgerBlue};
    border-radius: 6px;
    color: ${Colors.white};
    font-size: 12px;
    max-width: 200px;
    padding: 5px 10px;
    text-transform: capitalize;
    width: max-content;

    &:hover {
        background: rgba(${RGB.dodgerBlue}, 0.85);
        color: ${Colors.white};
        opacity: 1;
    }
`;

export default DropdownButtonStyle;
