import Button from '../../Button';
import ButtonModel from '../../Button/enums/ButtonModel.enum';
import React from 'react';

interface CallButtonProps {
  children: React.ReactElement;
  color: string;
  onClick: () => void;
  isLoading: boolean;
  isEnabled?: boolean;
}

const CallButton: React.FC<CallButtonProps> = ({
  children,
  color,
  onClick,
  isLoading,
  isEnabled = true,
}: CallButtonProps) => {
  const buttonEnabled = isEnabled && !isLoading;

  return (
    <Button
      background={color}
      height="55px"
      disabled={!buttonEnabled}
      model={ButtonModel.CIRCLE_ICON_BUTTON_DARK}
      onClick={(): void => {
        if (buttonEnabled) {
          onClick();
        }
      }}
      width="55px"
      style={{ cursor: !buttonEnabled ? 'not-allowed' : '', opacity: !buttonEnabled ? '0.5' : '1' }}
    >
      {children}
    </Button>
  );
};

export default CallButton;
