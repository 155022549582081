import MeReduxState from '../../../redux/reducers/@types/MeReduxState';
import { Role } from '../../../__generated__/graphql';

interface UserIsAbleToSetPasswordInterface {
  currentUser: MeReduxState;
  editingUserId?: string;
  userRole?: Role;
}
const userIsAbleToSetPassword = ({
  currentUser,
  editingUserId,
  userRole,
}: UserIsAbleToSetPasswordInterface): boolean => {
  const {
    id: loggedUserId,
    activeMembership: { role: loggedUserRole },
  } = currentUser;

  const userIsStaff = userRole && (userRole === Role.Admin || userRole === Role.Agent);
  const isEditingUser = Boolean(editingUserId);
  if (!userIsStaff && !isEditingUser) {
    return false;
  }

  const loggedUserIsAdmin = loggedUserRole === Role.Admin;
  const userIsEditingYourself = isEditingUser && editingUserId === loggedUserId;

  return !isEditingUser || loggedUserIsAdmin || userIsEditingYourself;
};

export default userIsAbleToSetPassword;
