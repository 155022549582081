import DataTable from '../../../components/DataTable';
import React from 'react';
import { useParams } from 'react-router-dom';
import usePaymentTransactionsTableData from '../../../hooks/usePaymentTransactionsTableData';

const PaymentTransactionsTab: React.FC<{}> = () => {
  const { accessGrantId } = useParams<{ accessGrantId: string }>();

  const tableData = usePaymentTransactionsTableData({
    queryOptions: {
      where: {
        accessGrantId: {
          equals: accessGrantId,
        },
      },
    },
  });

  return <DataTable {...tableData} />;
};

export default PaymentTransactionsTab;
