/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationReservationSchema: NodeSchemaProperties = {
  reservationScreen: {
    title: '#5 Reservation Screen',
    type: 'object',
    properties: {
      'header:enable': {
        description: '[reservationScreen.header]',
        title: '#1 Header',
        type: 'boolean',
      },
      header: {
        type: 'string',
      },
      detailsScreen: {
        title: '#2 Details Screen',
        type: 'object',
        properties: {
          title: {
            title: '#2.1 Title',
            type: 'object',
            properties: {
              'text:enable': {
                description: '[reservationScreen.detailsScreen.title.text]',
                title: '#2.1.1 Text',
                type: 'boolean',
              },
              text: {
                type: 'string',
              },
              'type:enable': {
                description: '[reservationScreen.detailsScreen.title.type]',
                title: '#2.1.2 Type',
                type: 'boolean',
              },
              type: {
                enum: ['PLAIN_TEXT', 'HTML'],
                type: 'string',
              },
              'useDefaultStyles:enable': {
                description: '[reservationScreen.detailsScreen.title.useDefaultStyles]',
                title: '#2.1.3 Use Default Styles',
                type: 'boolean',
              },
              useDefaultStyles: {
                type: undefined,
                enum: [true, false],
              },
            },
          },
          description: {
            title: '#2.2 Description',
            type: 'object',
            properties: {
              'text:enable': {
                description: '[reservationScreen.detailsScreen.description.text]',
                title: '#2.2.1 Text',
                type: 'boolean',
              },
              text: {
                type: 'string',
              },
              'type:enable': {
                description: '[reservationScreen.detailsScreen.description.type]',
                title: '#2.2.2 Type',
                type: 'boolean',
              },
              type: {
                enum: ['PLAIN_TEXT', 'HTML'],
                type: 'string',
              },
              'useDefaultStyles:enable': {
                description: '[reservationScreen.detailsScreen.description.useDefaultStyles]',
                title: '#2.2.3 Use Default Styles',
                type: 'boolean',
              },
              useDefaultStyles: {
                type: undefined,
                enum: [true, false],
              },
            },
          },
          'continueButton:enable': {
            description: '[reservationScreen.detailsScreen.continueButton]',
            title: '#2.3 Continue Button',
            type: 'boolean',
          },
          continueButton: {
            type: 'string',
          },
          listTitle: {
            title: '#2.4 List Title',
            type: 'object',
            properties: {
              'text:enable': {
                description: '[reservationScreen.detailsScreen.listTitle.text]',
                title: '#2.4.1 Text',
                type: 'boolean',
              },
              text: {
                type: 'string',
              },
              'type:enable': {
                description: '[reservationScreen.detailsScreen.listTitle.type]',
                title: '#2.4.2 Type',
                type: 'boolean',
              },
              type: {
                enum: ['PLAIN_TEXT', 'HTML'],
                type: 'string',
              },
              'useDefaultStyles:enable': {
                description: '[reservationScreen.detailsScreen.listTitle.useDefaultStyles]',
                title: '#2.4.3 Use Default Styles',
                type: 'boolean',
              },
              useDefaultStyles: {
                type: undefined,
                enum: [true, false],
              },
            },
          },
          firstUserTitle: {
            title: '#2.5 First User Title',
            type: 'object',
            properties: {
              'text:enable': {
                description: '[reservationScreen.detailsScreen.firstUserTitle.text]',
                title: '#2.5.1 Text',
                type: 'boolean',
              },
              text: {
                type: 'string',
              },
              'type:enable': {
                description: '[reservationScreen.detailsScreen.firstUserTitle.type]',
                title: '#2.5.2 Type',
                type: 'boolean',
              },
              type: {
                enum: ['PLAIN_TEXT', 'HTML'],
                type: 'string',
              },
              'useDefaultStyles:enable': {
                description: '[reservationScreen.detailsScreen.firstUserTitle.useDefaultStyles]',
                title: '#2.5.3 Use Default Styles',
                type: 'boolean',
              },
              useDefaultStyles: {
                type: undefined,
                enum: [true, false],
              },
            },
          },
          secondaryUsersTitle: {
            title: '#2.6 Secondary Users Title',
            type: 'object',
            properties: {
              'text:enable': {
                description: '[reservationScreen.detailsScreen.secondaryUsersTitle.text]',
                title: '#2.6.1 Text',
                type: 'boolean',
              },
              text: {
                type: 'string',
              },
              'type:enable': {
                description: '[reservationScreen.detailsScreen.secondaryUsersTitle.type]',
                title: '#2.6.2 Type',
                type: 'boolean',
              },
              type: {
                enum: ['PLAIN_TEXT', 'HTML'],
                type: 'string',
              },
              'useDefaultStyles:enable': {
                description:
                  '[reservationScreen.detailsScreen.secondaryUsersTitle.useDefaultStyles]',
                title: '#2.6.3 Use Default Styles',
                type: 'boolean',
              },
              useDefaultStyles: {
                type: undefined,
                enum: [true, false],
              },
            },
          },
          'editButton:enable': {
            description: '[reservationScreen.detailsScreen.editButton]',
            title: '#2.7 Edit Button',
            type: 'boolean',
          },
          editButton: {
            type: 'string',
          },
          'listEmpty:enable': {
            description: '[reservationScreen.detailsScreen.listEmpty]',
            title: '#2.8 List Empty',
            type: 'boolean',
          },
          listEmpty: {
            type: 'string',
          },
          'priceCurrent:enable': {
            description: '[reservationScreen.detailsScreen.priceCurrent]',
            title: '#2.9 Price Current',
            type: 'boolean',
          },
          priceCurrent: {
            type: 'string',
          },
          'room:enable': {
            description: '[reservationScreen.detailsScreen.room]',
            title: '#2.10 Room',
            type: 'boolean',
          },
          room: {
            type: 'string',
          },
          'night:enable': {
            description: '[reservationScreen.detailsScreen.night]',
            title: '#2.11 Night',
            type: 'boolean',
          },
          night: {
            type: 'string',
          },
        },
      },
      editScreen: {
        title: '#3 Edit Screen',
        type: 'object',
        properties: {
          'nameLabel:enable': {
            description: '[reservationScreen.editScreen.nameLabel]',
            title: '#3.1 Name Label',
            type: 'boolean',
          },
          nameLabel: {
            type: 'string',
          },
          'emailLabel:enable': {
            description: '[reservationScreen.editScreen.emailLabel]',
            title: '#3.2 Email Label',
            type: 'boolean',
          },
          emailLabel: {
            type: 'string',
          },
          'phoneLabel:enable': {
            description: '[reservationScreen.editScreen.phoneLabel]',
            title: '#3.3 Phone Label',
            type: 'boolean',
          },
          phoneLabel: {
            type: 'string',
          },
          'saveButton:enable': {
            description: '[reservationScreen.editScreen.saveButton]',
            title: '#3.4 Save Button',
            type: 'boolean',
          },
          saveButton: {
            type: 'string',
          },
        },
      },
    },
  },
};

export const mobileV3LocalizationReservationUiSchema: NodeUiSchemaProperties = {
  reservationScreen: {
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    detailsScreen: {
      title: {
        text: {
          'ui:label': false,
          'ui:readonly': true,
        },
        type: {
          'ui:label': false,
          'ui:readonly': true,
        },
        useDefaultStyles: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'select',
        },
      },
      description: {
        text: {
          'ui:label': false,
          'ui:readonly': true,
        },
        type: {
          'ui:label': false,
          'ui:readonly': true,
        },
        useDefaultStyles: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'select',
        },
      },
      continueButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
      listTitle: {
        text: {
          'ui:label': false,
          'ui:readonly': true,
        },
        type: {
          'ui:label': false,
          'ui:readonly': true,
        },
        useDefaultStyles: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'select',
        },
      },
      firstUserTitle: {
        text: {
          'ui:label': false,
          'ui:readonly': true,
        },
        type: {
          'ui:label': false,
          'ui:readonly': true,
        },
        useDefaultStyles: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'select',
        },
      },
      secondaryUsersTitle: {
        text: {
          'ui:label': false,
          'ui:readonly': true,
        },
        type: {
          'ui:label': false,
          'ui:readonly': true,
        },
        useDefaultStyles: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'select',
        },
      },
      editButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
      listEmpty: {
        'ui:label': false,
        'ui:readonly': true,
      },
      priceCurrent: {
        'ui:label': false,
        'ui:readonly': true,
      },
      room: {
        'ui:label': false,
        'ui:readonly': true,
      },
      night: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    editScreen: {
      nameLabel: {
        'ui:label': false,
        'ui:readonly': true,
      },
      emailLabel: {
        'ui:label': false,
        'ui:readonly': true,
      },
      phoneLabel: {
        'ui:label': false,
        'ui:readonly': true,
      },
      saveButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
  },
};
