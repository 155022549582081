import { Query, QueryUserArgs, User } from '../__generated__/graphql';

import GET_USER from '../graphql/getUser';
import QueryOneHookData from './@types/QueryOneHookData';
import { flashApolloError } from '../util/errorUtils';
import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';

type GetUserData = Pick<Query, 'user'>;

export default function useGetUserById(): QueryOneHookData<User> {
  const [getUser, { called, data, error, loading }] = useLazyQuery<GetUserData, QueryUserArgs>(
    GET_USER,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {},
      onError: flashApolloError,
    }
  );

  const query = useCallback(
    (id: string) => {
      if (!called) {
        getUser({
          variables: {
            where: {
              id: {
                equals: id,
              },
            },
          },
        });
      }
    },
    [called, getUser]
  );

  return {
    called,
    data: data?.user ?? undefined,
    error,
    loading,
    query,
  };
}
