import { gql } from '../__generated__/gql';

const GET_RESOURCE_TABLE_DATA = gql(`
  query GetResourceTableData(
    $orderBy: [ResourceOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: ResourceWhereInput
  ) {
    resources(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
      ...ResourceTableFields
    }
  }
`);

export default GET_RESOURCE_TABLE_DATA;
