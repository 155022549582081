import { CallRecordWhereInput, Query, QueryCallRecordsArgs } from '../../__generated__/graphql';
import { useCallback, useEffect, useRef, useState } from 'react';

import CALL_RECORDS_SEARCH_FIELDS from './constants/CallRecordsTableSearchFields';
import CALL_RECORDS_TABLE_HEADERS from './constants/CallRecordsTableHeaders';
import { EntityEnum } from '../../@types/client/DashboardUserRolePermissions';
import GET_CALL_RECORDS from '../../graphql/getCallRecords';
import GET_CALL_RECORD_COUNT from '../../graphql/aggregateCallRecord';
import OrderByParams from '../../@types/client/OrderByParams';
import { RootState } from '../../redux/reducers';
import SearchField from '../../@types/SearchField';
import TableHookData from '../@types/TableHookData';
import TableHookProps from '../@types/TableHookProps';
import generateCallRecordTableData from './functions/generateCallRecordsTableData';
import generateMembershipQuery from '../../util/api/generateMembershipQuery';
import parseCallRecordQueryParameters from './util/parseCallRecordQueryParameters';
import parseOrderByDataTableParams from '../../components/DataTable/util/parseOrderByDataTableParams';
import useCallRecordFilters from './hooks/useCallRecordFilters';
import useGetMany from '../useGetMany';
import useRemoveUser from '../useRemoveUser';
import { useSelector } from 'react-redux';

type CallRecordsQueryParams = {
  orderBy?: OrderByParams;
  where: Partial<CallRecordWhereInput>;
};

type GetCallRecordsData = Pick<Query, 'callRecords'>;
type AggregateCallRecordQueryData = Pick<Query, 'aggregateCallRecord'>;

export default function useCallRecordsTableData({
  dataTableKey = 'callRecords',
  formName = 'callRecord',
  headers = CALL_RECORDS_TABLE_HEADERS,
  isSearchable = true,
  queryOptions,
  permission = EntityEnum.NONE,
  searchFields = CALL_RECORDS_SEARCH_FIELDS,
}: TableHookProps<QueryCallRecordsArgs, CallRecordWhereInput>): TableHookData<
  CallRecordWhereInput
> {
  const membership = useSelector((state: RootState) => state.me.activeMembership);
  const filters = useCallRecordFilters();
  const options = useRef<Partial<QueryCallRecordsArgs>>(queryOptions ?? {});
  const fieldsFiltered = useSelector((state: RootState) => state.dataTable.filters);
  const sortOptions = useSelector((state: RootState) => state.dataTable.sortOptions);

  const [searchParameters, setSearchParameters] = useState<CallRecordWhereInput>({});

  const { data, error, loading, offset, query, setOffset } = useGetMany<
    GetCallRecordsData,
    QueryCallRecordsArgs
  >({
    graphqlQuery: GET_CALL_RECORDS,
  });

  const {
    data: countData,
    error: countError,
    loading: countLoading,
    query: countQuery,
  } = useGetMany<AggregateCallRecordQueryData, QueryCallRecordsArgs>({
    graphqlQuery: GET_CALL_RECORD_COUNT,
  });

  const createParams = useCallback((): CallRecordsQueryParams => {
    const whereArgs = parseCallRecordQueryParameters(fieldsFiltered[dataTableKey]);
    const sortParams = parseOrderByDataTableParams(sortOptions[dataTableKey]);

    return {
      ...(sortParams && { orderBy: sortParams }),
      where: {
        ...generateMembershipQuery(membership),
        ...options.current?.where,
        ...searchParameters,
        ...whereArgs,
      },
    };
  }, [membership, fieldsFiltered, sortOptions, dataTableKey, options, searchParameters]);

  const get = useCallback(() => {
    const params = createParams();

    query({
      ...options.current,
      ...(params.orderBy && { orderBy: params.orderBy }),
      skip: options.current?.skip ?? offset,
      take: options.current?.take ?? 10,
      where: params.where,
    });
  }, [createParams, offset, query]);

  const getCount = useCallback(() => {
    const params = createParams();

    countQuery({
      where: params.where,
    });
  }, [countQuery, createParams]);

  useEffect(() => {
    get();
  }, [get]);

  useEffect(() => {
    getCount();
  }, [getCount]);

  return {
    count: countData?.aggregateCallRecord?._count?._all ?? 0,
    data: generateCallRecordTableData(data),
    dataTableKey,
    dataType: 'CallRecord',
    error: error || countError,
    filters,
    formName,
    headers,
    isLoading: loading || countLoading,
    isSearchable,
    offset,
    permission,
    remove: useRemoveUser,
    search: (searchField: SearchField<CallRecordWhereInput>, searchValue: string): void => {
      setSearchParameters({
        [searchField.queryField]: searchField.transform(searchValue),
      });

      setOffset(0);
    },
    searchFields,
    setOffset,
  };
}
