import './styles/override-datepicker.css';
import 'styled-components/macro';

import { DateRangePicker, SingleDatePicker } from 'react-dates';
import React, { useEffect, useState } from 'react';
import moment, { Moment, tz } from 'moment-timezone';

import Container from '../Container';
import Label from '../Label';
import SameDayToggleContainerStyle from './styles/SameDayToggleContainer.style';
import ToggleBooleanSwitch from '../ToggleBooleanSwitch';
import useDateTimeFormatter from '../../hooks/useDateTimeFormatter';
import { useFormContext } from 'react-hook-form';

interface DateInputProps {
  className?: string;
  name: string;
  required?: boolean;
  timezone?: string;
}

interface DateRange {
  endDate: Moment | null;
  startDate: Moment | null;
}

/* DateInput must be used within a React Hook Form Form Provider */
const DateInput: React.FC<DateInputProps> = ({
  className,
  name,
  required = false,
  timezone,
}: DateInputProps) => {
  const [focusedInput, focusInput] = useState<'startDate' | 'endDate' | null>(null);
  const { clearErrors, getValues, register, setValue } = useFormContext();
  const { dateFormat } = useDateTimeFormatter();
  const [startName, endName] = name.split('/');
  const values = getValues([startName, endName]);

  function createDate(value?: Date): Moment | null {
    if (!value) {
      return null;
    }

    if (timezone) {
      const date = tz(value, timezone);
      return date;
    }

    return moment(value);
  }

  const [dates, setDates] = useState<DateRange>({
    endDate: createDate(values[endName]),
    startDate: createDate(values[startName]),
  });

  const [isSameDay, setSameDay] = useState<boolean>(
    Boolean(dates.endDate?.isSame(dates.startDate, 'day'))
  );

  useEffect(() => {
    register(startName, { required });
    register(endName, { required });
  });

  function handleDatesChange({
    endDate,
    startDate,
  }: {
    startDate: Moment | null;
    endDate: Moment | null;
  }): void {
    setValue(startName, startDate?.toDate() ?? undefined);
    setValue(endName, endDate?.toDate() ?? undefined);
    clearErrors([startName, endName]);

    setDates({
      endDate,
      startDate,
    });
  }

  function handleDateChange(date: Moment | null): void {
    setValue(startName, date?.toDate() ?? undefined);
    setValue(endName, date?.toDate() ?? undefined);
    clearErrors([startName, endName]);

    setDates({
      endDate: date,
      startDate: date,
    });
  }

  function toggle(): void {
    if (dates.startDate && !dates.startDate.isSame(dates.endDate, 'day')) {
      setValue(startName, dates.startDate.toDate());
      setValue(endName, dates.startDate.toDate());

      setDates({
        endDate: dates.startDate,
        startDate: dates.startDate,
      });
    }

    setSameDay(!isSameDay);
  }

  return (
    <Container
      className={className}
      css={`
        .DateRangePickerInput {
          justify-content: center;
        }

        .DateRangePicker .DateInput_input {
          padding: 0;
          text-align: center;
        }
      `}
    >
      {isSameDay && (
        <SingleDatePicker
          date={dates.startDate}
          id="single-date-picker"
          focused={focusedInput === 'startDate'}
          onDateChange={handleDateChange}
          onFocusChange={({ focused }): void => {
            if (focused) {
              focusInput('startDate');
            } else {
              focusInput(null);
            }
          }}
          displayFormat={dateFormat()}
        />
      )}
      {!isSameDay && (
        <DateRangePicker
          endDate={dates.endDate}
          endDateId="date-picker-end"
          focusedInput={focusedInput}
          onDatesChange={handleDatesChange}
          onFocusChange={focusInput}
          startDate={dates.startDate}
          startDateId="date-picker-start"
          displayFormat={dateFormat()}
        />
      )}
      <Container css={SameDayToggleContainerStyle}>
        <ToggleBooleanSwitch isActive={isSameDay} toggleActive={toggle} />
        <Label>Same Day?</Label>
      </Container>
    </Container>
  );
};

export default DateInput;
