import { gql } from '../__generated__/gql';

const GET_PAYMENT_TRANSACTION_TABLE_DATA = gql(`
  query GetPaymentTransactionTableData(
    $skip: Int
    $take: Int
    $where: PaymentTransactionWhereInput
  ) {
    paymentTransactions(skip: $skip, take: $take, where: $where) {
      id
      invoiceNumber
      amount
      status
      createdAt
    }
  }
`);

export default GET_PAYMENT_TRANSACTION_TABLE_DATA;
