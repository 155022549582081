import { gql } from '../__generated__/gql';

const TAKE_SCREENSHOT = gql(`
  mutation takeScreenshot($kioskId: String!) {
    takeScreenshot(kioskId: $kioskId) {
      success
    }
  }
`);

export default TAKE_SCREENSHOT;
