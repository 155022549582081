import ConciergeTree from '../../../components/ConciergeTree';
import Container from '../../../components/Container';
import { LocationPageInfo } from '../@types/GetLocationPageInfo';
import React from 'react';
import { RootState } from '../../../redux/reducers';
import { useSelector } from 'react-redux';

interface InfoItemTabProps {
  location: LocationPageInfo;
}

const InfoItemsTab: React.FC<InfoItemTabProps> = () => {
  const modals = useSelector((state: RootState) => state.modals);

  return (
    <Container style={{ padding: '0px 45px' }}>
      <ConciergeTree modals={modals} />
    </Container>
  );
};

export default InfoItemsTab;
