import React from 'react';

const LineGraphPositionContext = React.createContext({
  height: 600,
  innerHeight: 600,
  innerWidth: 800,
  margin: {
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
  },
  tooltipLeft: 0,
  tooltipTop: 0,
  width: 800,
});

export default LineGraphPositionContext;
