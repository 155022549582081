import { AccessGrantState } from '../../../__generated__/graphql';
import { QuickActionCTAType } from '../@types/QuickActionBarTypes';

type ActionState = {
  [key in QuickActionCTAType]: boolean;
};

const actionStates: Record<AccessGrantState, ActionState> = {
  PRE_CHECK_IN: {
    checkIn: true,
    checkOut: false,
    blockCheckIn: true,
    getAutenticationCredentials: true,
    remotePrintKeyCard: true,
    repollReservation: true,
    error: false,
  },
  IN_HOUSE: {
    checkIn: false,
    checkOut: true,
    blockCheckIn: false,
    getAutenticationCredentials: true,
    remotePrintKeyCard: true,
    repollReservation: true,
    error: false,
  },
  CHECKED_OUT: {
    checkIn: false,
    checkOut: false,
    blockCheckIn: false,
    getAutenticationCredentials: true,
    remotePrintKeyCard: true,
    repollReservation: true,
    error: false,
  },
  CANCELED: {
    checkIn: false,
    checkOut: false,
    blockCheckIn: false,
    getAutenticationCredentials: false,
    remotePrintKeyCard: false,
    repollReservation: false,
    error: false,
  },
};

export const getActionButtonState = (
  ctaType: QuickActionCTAType,
  agState: AccessGrantState
): boolean => {
  return actionStates[agState][ctaType];
};
