import { gql } from '../__generated__/gql';

export const SEND_NOTIFICATION = gql(`
  mutation sendNotification(
    $accessGrantId: String!
    $transport: String!
    $notificationId: String!
  ) {
    sendNotification(
      accessGrantId: $accessGrantId
      transport: $transport
      notificationId: $notificationId
    )
  }
`);
