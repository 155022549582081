import { gql } from '../__generated__/gql';

const GET_LOCATION = gql(`
  query Location($where: LocationWhereUniqueInput!) {
    location(where: $where) {
      ...LocationTableFields
    }
  }
`);

export default GET_LOCATION;
