import { AccessGrant } from '../../__generated__/graphql';
import AccessGrantAction from '../../enums/AccessGrantAction.enum';
import { AccessGrantPageInfo } from '../../pages/AccessGrant/@types/GetAccessGrantPageInfo';
import { AnyAction } from 'redux';

export const receiveAccessGrant = (accessGrant: AccessGrant): AnyAction => ({
  payload: { accessGrant },
  type: AccessGrantAction.RECEIVE_ACCESS_GRANT,
});

export const receiveAccessGrantError = (): AnyAction => ({
  type: AccessGrantAction.RECEIVE_ACCESS_GRANT_ERROR,
});

export const receiveAccessGrants = (accessGrants: AccessGrant[]): AnyAction => ({
  payload: { accessGrants },
  type: AccessGrantAction.RECEIVE_ACCESS_GRANTS,
});

export const receiveMoreAccessGrants = (accessGrants: AccessGrant[]): AnyAction => ({
  payload: { accessGrants },
  type: AccessGrantAction.RECEIVE_MORE_ACCESS_GRANTS,
});

export const loadAccessGrants = (): AnyAction => ({
  type: AccessGrantAction.LOAD_ACCESS_GRANT,
});

export const loadAccessGrant = (): AnyAction => ({
  type: AccessGrantAction.LOAD_ACCESS_GRANT,
});

export const clearAccessGrant = (): AnyAction => ({
  type: AccessGrantAction.CLEAR_ACCESS_GRANT,
});

export const resetAccessGrantStore = (): AnyAction => ({
  type: AccessGrantAction.RESET_ACCESS_GRANT_STORE,
});

export const setAccessGrantPageInfo = (payload: AccessGrantPageInfo): AnyAction => ({
  payload,
  type: AccessGrantAction.SET_ACCESS_GRANT_PAGE_INFO,
});
