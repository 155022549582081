import InputGroupProps from '../../InputGroup/@types/InputGroupProps';
import SelectOption from '../../../@types/react/SelectOption';

export const dateFormatOptions: SelectOption[] = [
  { label: 'MM/DD/YYYY', value: 'MM/DD/YYYY' },
  { label: 'DD/MM/YYYY', value: 'DD/MM/YYYY' },
  { label: 'YYYY-MM-DD', value: 'YYYY-MM-DD' },
];

export const timeFormatOptions: SelectOption[] = [
  { label: 'AM / PM', value: 'hh:mm A' },
  { label: '24 hours', value: 'HH:mm' },
];

const DASHBOARD_PREFERENCES_INPUTS: InputGroupProps[] = [
  {
    label: 'Date format',
    name: 'dashboardPreferences.preferredDateFormat',
    options: dateFormatOptions,
    required: false,
    type: 'select',
  },
  {
    label: 'Time format',
    name: 'dashboardPreferences.preferredTimeFormat',
    options: timeFormatOptions,
    required: false,
    type: 'select',
  },
];

export default DASHBOARD_PREFERENCES_INPUTS;
