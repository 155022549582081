import { Mutation, QueryAmenityArgs } from '../__generated__/graphql';

import DELETE_AMENITY from '../graphql/deleteAmenity';
import RemoveHookData from './@types/RemoveHookData';
import { closeRemoveModal } from '../redux/actions/modals.actions';
import { flashApolloError } from '../util/errorUtils';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

type DeleteAmenityData = Pick<Mutation, 'deleteOneAmenity'>;

export default function useRemoveAmenity(): RemoveHookData {
  const dispatch = useDispatch();
  const history = useHistory();

  const [remove, { error, loading }] = useMutation<DeleteAmenityData, QueryAmenityArgs>(
    DELETE_AMENITY,
    {
      onCompleted: ({ deleteOneAmenity }) => {
        dispatch(closeRemoveModal());

        if (deleteOneAmenity) {
          window.flash({
            message: `Amenity: ${deleteOneAmenity.title} removed successfully`,
          });
        } else {
          window.flash({ message: 'Fail to remove amenity' });
        }

        history.go(0);
      },
      onError: flashApolloError,
    }
  );

  return {
    error,
    loading,
    mutation: (id: string): void => {
      remove({
        variables: {
          where: { id },
        },
      });
    },
  };
}
