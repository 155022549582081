/* eslint-disable no-param-reassign */
import 'styled-components/macro';

import React, { memo, useEffect, useState } from 'react';

import Button from '../Button';
import ButtonModel from '../Button/enums/ButtonModel.enum';
import CallInfo from './components/CallInfo';
import Container from '../Container';
import ExpandButtonStyle from './styles/ExpandButton.style';
import ExpandedVideoCallModalStyle from './constants/ExpandedVideoCallModalStyle';
import HangUpButtonStyle from './styles/HangUpButton.style';
import { ImPhoneHangUp } from 'react-icons/im';
import IncomingCallRedux from '../../redux/reducers/@types/IncomingCallRedux';
import { MdZoomOutMap } from 'react-icons/md';
import MinimizedVideoCallModalStyle from './constants/MinimizedVideoCallModalStyle';
import Modal from 'react-modal';
import Room from './components/Room';
import Theme from '../../theme';
import VideoCallContainerStyle from './styles/VideoCallContainer.style';
import { closeModals } from '../../redux/actions/modals.actions';
import { useDispatch } from 'react-redux';
import useEndCall from '../../hooks/useEndCall';

interface VideoCallModalProps {
  activeCall: IncomingCallRedux;
  videoToken: string;
}

const VideoCallModal: React.FC<VideoCallModalProps> = ({
  activeCall,
  videoToken,
}: VideoCallModalProps) => {
  const dispatch = useDispatch();

  const [isExpanded, setExpanded] = useState<boolean>(false);

  const { endCall } = useEndCall();

  function close(): void {
    dispatch(closeModals(['kioskActions', 'videoCall']));
  }

  function toggleExpand(): void {
    setExpanded(!isExpanded);
  }

  const handleEndCall = (): void => {
    endCall({ callRecordId: activeCall.callRecordId, userId: activeCall.caller?.id || '' });
  };

  const modalStyle = isExpanded ? ExpandedVideoCallModalStyle : MinimizedVideoCallModalStyle;

  useEffect(() => {
    window.onmousemove = (): void => {
      const buttons = Array.from(
        document.getElementsByClassName('video-action-buttons') as HTMLCollectionOf<HTMLElement>
      );

      buttons.forEach((button) => {
        button.style.opacity = '1';

        button.addEventListener('mouseenter', (event) => {
          (event.target as HTMLElement).dataset.hovered = 'true';
        });

        button.addEventListener('mouseleave', (event) => {
          (event.target as HTMLElement).dataset.hovered = 'false';
        });
      });

      window.setTimeout(() => {
        buttons.forEach((button) => {
          if (button.getAttribute('data-hovered') !== 'true') {
            button.style.opacity = '0';
          }
        });
      }, 1500);
    };
  });

  return (
    <Modal
      isOpen
      onRequestClose={close}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      style={modalStyle}
    >
      <Container
        className={isExpanded ? 'expanded-video-call' : 'minimized-video-call'}
        css={VideoCallContainerStyle}
      >
        <Room
          callRecordId={activeCall.callRecordId}
          isExpanded={isExpanded}
          roomName={activeCall.room}
          token={videoToken}
          type={activeCall.type}
        />
        <Button
          className="video-action-buttons"
          css={ExpandButtonStyle}
          model={ButtonModel.CIRCLE_ICON_BUTTON_DARK}
          onClick={toggleExpand}
        >
          <MdZoomOutMap color={Theme.colors.borderDark.hexCode} size="30px" />
        </Button>
        <Button
          className="video-action-buttons"
          css={HangUpButtonStyle}
          model={ButtonModel.CIRCLE_ICON_BUTTON_DARK}
          onClick={handleEndCall}
        >
          <ImPhoneHangUp color={Theme.colors.white.hexCode} size="20px" />
        </Button>
        <CallInfo incomingCall={activeCall} />
      </Container>
    </Modal>
  );
};

export default memo(VideoCallModal);
