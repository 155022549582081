import { DispenserType } from '../../../__generated__/graphql';
import InputGroupProps from '../../InputGroup/@types/InputGroupProps';
import { RfidTagType } from '../../../enums/RfidTagType.enum';
import generateDayTimes from '../../../util/generateDayTimes';

const getKioskConfigurationInputs = (maintenanceModeIsActive: boolean): InputGroupProps[] => {
  let KIOSK_CONFIGURATION_INPUTS: InputGroupProps[] = [
    {
      label: 'Power Switch ID',
      name: 'powerSwitchId',
      type: 'text',
    },
    {
      label: 'Dispenser type',
      name: 'dispenserType',
      options: Object.values(DispenserType),
      type: 'select',
    },
    {
      defaultChecked: false,
      label: 'Monitor dispenser',
      name: 'hasDispenser',
      required: true,
      type: 'checkbox',
    },
    {
      label: 'RFID Tag Type',
      name: 'rfidTagType',
      options: Object.values(RfidTagType),
      type: 'select',
    },
    {
      defaultChecked: false,
      label: 'Monitor payment terminal',
      name: 'hasTerminal',
      required: true,
      type: 'checkbox',
    },
    {
      defaultChecked: false,
      label: 'Maintenance mode',
      name: 'maintenanceMode',
      required: true,
      type: 'checkbox',
    },
  ];

  if (maintenanceModeIsActive) {
    KIOSK_CONFIGURATION_INPUTS = [
      ...KIOSK_CONFIGURATION_INPUTS,
      {
        label: 'Maintenance Dates',
        name: 'maintenanceStartDate/maintenanceEndDate',
        required: true,
        type: 'date',
      },
      {
        label: 'Maintenance start time',
        name: 'maintenanceStartTime',
        options: generateDayTimes(),
        required: true,
        type: 'select',
      },
      {
        label: 'Maintenance end time',
        name: 'maintenanceEndTime',
        options: generateDayTimes(),
        required: true,
        type: 'select',
      },
    ];
  }

  return KIOSK_CONFIGURATION_INPUTS;
};

export default getKioskConfigurationInputs;
