interface SimpleFormSectionStyleProps {
  columns?: number | string;
}

export default function generateSimpleFormSectionStyle({
  columns,
}: SimpleFormSectionStyleProps): string {
  if (typeof columns === 'number' || !columns) {
    return `
      grid-auto-flow: row;
      grid-column-gap: 15px;
      grid-row-gap: 25px;
      grid-template-columns: repeat(${columns ?? 2}, 1fr);
    `;
  }

  return `
      grid-auto-flow: row;
      grid-column-gap: 15px;
      grid-row-gap: 25px;
      grid-template-columns: ${columns};
  `;
}
