import InputGroupProps from '../../InputGroup/@types/InputGroupProps';

export const generateKioskConfigInputs = (index: number): InputGroupProps[] => {
  return [
    {
      defaultChecked: false,
      label: 'Active',
      name: `dispenserConfigList[${index}].isActive`,
      required: true,
      type: 'checkbox',
    },
    {
      label: 'Serial Number',
      name: `dispenserConfigList[${index}].serial`,
      type: 'text',
    },
    {
      label: 'Usage',
      name: `dispenserConfigList[${index}].usage`,
      type: 'text',
    },
  ];
};
