export default function jsonGetObjectFromKey(
  data: Record<string, unknown>,
  mainKey: string
): Record<string, unknown> {
  const dataValue = {};

  if (data) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(data)) {
      if (mainKey === key) {
        return data[key] as Record<string, unknown>;
      }
    }
  }

  return dataValue;
}
