/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3ThemeProfileSchema: NodeSchemaProperties = {
  profile: {
    title: '#9 Profile',
    type: 'object',
    properties: {
      items: {
        title: '#1 Items',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[profile.items.backgroundColor]',
            title: '#1.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'textColor:enable': {
            description: '[profile.items.textColor]',
            title: '#1.2 Text Color',
            type: 'boolean',
          },
          textColor: {
            type: 'string',
          },
          'borderColor:enable': {
            description: '[profile.items.borderColor]',
            title: '#1.3 Border Color',
            type: 'boolean',
          },
          borderColor: {
            type: 'string',
          },
          'borderWidth:enable': {
            description: '[profile.items.borderWidth]',
            title: '#1.4 Border Width',
            type: 'boolean',
          },
          borderWidth: {
            type: 'integer',
          },
          'borderRadius:enable': {
            description: '[profile.items.borderRadius]',
            title: '#1.5 Border Radius',
            type: 'boolean',
          },
          borderRadius: {
            type: 'integer',
          },
          'iconBackgroundColor:enable': {
            description: '[profile.items.iconBackgroundColor]',
            title: '#1.6 Icon Background Color',
            type: 'boolean',
          },
          iconBackgroundColor: {
            type: 'string',
          },
          'iconColor:enable': {
            description: '[profile.items.iconColor]',
            title: '#1.7 Icon Color',
            type: 'boolean',
          },
          iconColor: {
            type: 'string',
          },
          'arrowColor:enable': {
            description: '[profile.items.arrowColor]',
            title: '#1.8 Arrow Color',
            type: 'boolean',
          },
          arrowColor: {
            type: 'string',
          },
        },
      },
      accountTabs: {
        title: '#2 Account Tabs Visibility',
        type: 'object',
        properties: {
          'showPendingTask:enable': {
            description: '[profile.accountTabs.showPendingTask]',
            title: '#2.1 Pending Task visibility',
            type: 'boolean',
          },
          showPendingTask: {
            type: undefined,
            enum: [true, false],
          },
          'showGuests:enable': {
            description: '[profile.accountTabs.showGuests]',
            title: '#2.2 Guests visibility',
            type: 'boolean',
          },
          showGuests: {
            type: undefined,
            enum: [true, false],
          },
          'showPayment:enable': {
            description: '[profile.accountTabs.showPayment]',
            title: '#2.3 Payment visibility',
            type: 'boolean',
          },
          showPayment: {
            type: undefined,
            enum: [true, false],
          },
          'showSetLanguage:enable': {
            description: '[profile.accountTabs.showSetLanguage]',
            title: '#2.4 Set Language visibility',
            type: 'boolean',
          },
          showSetLanguage: {
            type: undefined,
            enum: [true, false],
          },
          'showLogout:enable': {
            description: '[profile.accountTabs.showLogout]',
            title: '#2.5 Logout visibility',
            type: 'boolean',
          },
          showLogout: {
            type: undefined,
            enum: [true, false],
          },
          'showQrCode:enable': {
            description: '[profile.accountTabs.showQrCode]',
            title: '#2.6 QR code visibility',
            type: 'boolean',
          },
          showQrCode: {
            type: undefined,
            enum: [true, false],
          },
        },
      },
      supportTabs: {
        title: '#3 Support Tabs Visibility',
        type: 'object',
        properties: {
          'showRemoteAssistance:enable': {
            description: '[profile.supportTabs.showRemoteAssistance]',
            title: '#3.1 Remote Assistance visibility',
            type: 'boolean',
          },
          showRemoteAssistance: {
            type: undefined,
            enum: [true, false],
          },
          'showRateOurApp:enable': {
            description: '[profile.supportTabs.showRateOurApp]',
            title: '#3.2 Rate Our App visibility',
            type: 'boolean',
          },
          showRateOurApp: {
            type: undefined,
            enum: [true, false],
          },
          'showContactUs:enable': {
            description: '[profile.supportTabs.showContactUs]',
            title: '#3.3 Contact Us visibility',
            type: 'boolean',
          },
          showContactUs: {
            type: undefined,
            enum: [true, false],
          },
          'contactUsEmail:enable': {
            description: '[profile.supportTabs.contactUsEmail]',
            title: '#3.4 Contact Us Email',
            type: 'boolean',
          },
          contactUsEmail: {
            type: 'string',
          },
          'showPrivacyPolicy:enable': {
            description: '[profile.supportTabs.showPrivacyPolicy]',
            title: '#3.5 Privacy Policy visibility',
            type: 'boolean',
          },
          showPrivacyPolicy: {
            type: undefined,
            enum: [true, false],
          },
          'privacyPolicyUrl:enable': {
            description: '[profile.supportTabs.privacyPolicyUrl]',
            title: '#3.6 Privacy Policy Url',
            type: 'boolean',
          },
          privacyPolicyUrl: {
            type: 'string',
          },
          'showTermsOfService:enable': {
            description: '[profile.supportTabs.showTermsOfService]',
            title: '#3.7 Terms Of Service visibility',
            type: 'boolean',
          },
          showTermsOfService: {
            type: undefined,
            enum: [true, false],
          },
          'termsOfServiceUrl:enable': {
            description: '[profile.supportTabs.termsOfServiceUrl]',
            title: '#3.8 Terms Of Service Url',
            type: 'boolean',
          },
          termsOfServiceUrl: {
            type: 'string',
          },
        },
      },
    },
  },
};

export const mobileV3ThemeProfileUiSchema: NodeUiSchemaProperties = {
  profile: {
    items: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderWidth: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
      borderRadius: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
      iconBackgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      iconColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      arrowColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    accountTabs: {
      showPendingTask: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      showGuests: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      showPayment: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      showSetLanguage: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      showLogout: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      showQrCode: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
    },
    supportTabs: {
      showRemoteAssistance: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      showRateOurApp: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      showContactUs: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      contactUsEmail: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:title': '‎',
      },
      showPrivacyPolicy: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      privacyPolicyUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
      showTermsOfService: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      termsOfServiceUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
    },
  },
};
