import { ResourceGroupWhereInput } from '../../../__generated__/graphql';
import SearchField from '../../../@types/SearchField';
import createSearchField from '../../../util/tableData/createSearchField';
import transformStringFilter from '../../../util/tableData/transformStringFilter';

const RESOURCE_GROUP_SEARCH_FIELDS: SearchField<ResourceGroupWhereInput>[] = [
  createSearchField<Partial<ResourceGroupWhereInput>, 'name'>({
    label: 'Name',
    queryField: 'name',
    transform: transformStringFilter,
  }),
];

export default RESOURCE_GROUP_SEARCH_FIELDS;
