export default function generateNumbersFromCount(count: number, perPage?: number): Array<number> {
  const pageCapacity = perPage || 5;

  if (count <= pageCapacity) {
    return [1];
  }

  const numbers = [];

  for (let i = 0; i < count; i += pageCapacity) {
    numbers.push(numbers.length + 1);
  }

  return numbers;
}
