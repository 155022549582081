import 'styled-components/macro';

import React, { useState } from 'react';

import Banner from '../../components/Banner';
import BaseLayout from '../../templates/BaseLayout';
import Button from '../../components/Button';
import ButtonModel from '../../components/Button/enums/ButtonModel.enum';
import Container from '../../components/Container';
import KiosksLiveUsageTab from './components/KiosksLiveUsage';
import WaitingCallsTab from './components/WaitingCalls';

enum RemoteAssistanceTabEnum {
  WAITING_CALLS = 'waitingCalls',
  KIOSKS_LIVE_USAGE = 'kiosksLiveUsage',
}

const RemoteAssistancePage: React.FC<{}> = () => {
  const defaultTab: RemoteAssistanceTabEnum = RemoteAssistanceTabEnum.WAITING_CALLS;
  const [currentTab, setTab] = useState<RemoteAssistanceTabEnum>(defaultTab);

  const tabButtons: { tab: RemoteAssistanceTabEnum; title: string }[] = [
    {
      tab: RemoteAssistanceTabEnum.WAITING_CALLS,
      title: 'Waiting Calls',
    },
    {
      tab: RemoteAssistanceTabEnum.KIOSKS_LIVE_USAGE,
      title: 'Kiosks Live Usage',
    },
  ];

  return (
    <BaseLayout>
      <Container
        css={`
          padding: 30px 35px 75px 25px;
        `}
      >
        <Banner subText="Virdee" text="Remote Assistance" />
        <Container
          css={`
            grid-auto-flow: column;
            grid-auto-columns: max-content;
            grid-column-gap: 10px;
            margin: 20px 0;
          `}
        >
          {tabButtons.map((tabButton) => {
            const { tab: tabButtonType, title: tabTitle } = tabButton;

            return (
              <Button
                active={currentTab === tabButtonType}
                model={ButtonModel.TAB}
                onClick={(): void => {
                  setTab(tabButtonType);
                }}
                key={tabButton.tab}
              >
                {tabTitle}
              </Button>
            );
          })}
        </Container>
        {currentTab === RemoteAssistanceTabEnum.WAITING_CALLS && <WaitingCallsTab />}
        {currentTab === RemoteAssistanceTabEnum.KIOSKS_LIVE_USAGE && <KiosksLiveUsageTab />}
      </Container>
    </BaseLayout>
  );
};

export default RemoteAssistancePage;
