import { gql } from '../__generated__/gql';

const UPDATE_UNIT_TYPE = gql(`
  mutation updateUnitTypeAndUploadImageFile(
    $data: CustomUnitTypeUpdateInput!
    $where: UnitTypeWhereUniqueInput!
  ) {
    updateUnitTypeAndUploadImageFile(data: $data, where: $where) {
      id
      imageUrl
      locationId
      numberOfGuests
      type
    }
  }
`);

export default UPDATE_UNIT_TYPE;
