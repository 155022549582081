import { gql } from '../__generated__/gql';

const GET_KIOSK_COUNT = gql(`
  query GetKioskCount($where: KioskWhereInput!) {
    aggregateKiosk(where: $where) {
      _count {
        _all
      }
    }
  }
`);

export default GET_KIOSK_COUNT;
