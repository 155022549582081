import { gql } from '../../../__generated__/gql';

const GET_KIOSK_PAGE_INFO = gql(`
  query GetKioskPageInfo($where: KioskWhereUniqueInput!) {
    kiosk(where: $where) {
      id
      location {
        id
        name
        tenantId
      }
      name
      dispenserType
      batteryLevel
      chargingState
      hasDispenser
      dispenserLastSeenAt
      hasTerminal
      lastSeenAt
      terminalLastSeenAt
      buildNumber
      metadata
    }
  }
`);

export default GET_KIOSK_PAGE_INFO;
