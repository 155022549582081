import { Datum } from '../../../components/DataTable';
import { Query } from '../../../__generated__/graphql';
import { serviceTypeLabels } from '../../../enums/ServiceType.enum';

type GetServiceTableData = Pick<Query, 'services'>;

export default function generateServicesTableData(data?: GetServiceTableData): Datum[] {
  const tableData =
    data?.services?.map((service) => ({
      dataType: 'service',
      fields: [
        {
          key: 'Title',
          value: service.title,
        },
        {
          key: 'Description',
          value: service.description,
        },
        {
          key: 'Image URL',
          ...(service.imageUrl && { href: service.imageUrl }),
          value: service.imageUrl,
        },
        {
          key: 'Type',
          value: serviceTypeLabels[service.type],
        },
      ],
      key: service.id,
    })) || [];

  return tableData;
}
