import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Theme from '../../../../theme';

export const AuthCredentialsSkeleton: React.FC = () => {
  return (
    <>
      <Skeleton
        baseColor={Theme.colors.white.hexCode}
        highlightColor={Theme.colors.whisper.hexCode}
        height={83}
        width={560}
        borderRadius={5}
      />
      <Skeleton
        baseColor={Theme.colors.white.hexCode}
        highlightColor={Theme.colors.whisper.hexCode}
        height={83}
        width={560}
        borderRadius={5}
      />
      <Skeleton
        baseColor={Theme.colors.white.hexCode}
        highlightColor={Theme.colors.whisper.hexCode}
        height={308}
        width={560}
        borderRadius={5}
      />
    </>
  );
};
