import { Container } from '../styles/GracePeriodInput.styles';
import { ControlTitle } from '../styles';
import { EarlyCheckInFormValues } from '../@types/EarlyCheckInFormValues';
import { EarlyCheckInInputGrid } from '../styles/GracePeriodsController.styles';
import { EarlyCheckInTimeConstrainedMinutesInput } from './EarlyCheckInTimeConstrainedMinutesInput';
import { EarlyCheckInTypeEnum } from '../enums';
import React from 'react';
import ToggleSelector from '../../ToggleSelector';
import { useFormContext } from 'react-hook-form';

const earlyCheckInTypeFieldName = 'earlyCheckInType';

const EarlyCheckInInput: React.FC = () => {
  const { register, getValues } = useFormContext<EarlyCheckInFormValues>();

  const earlyCheckInType = getValues(earlyCheckInTypeFieldName);

  return (
    <EarlyCheckInInputGrid>
      <input type="checkbox" name={'allowEarlyCheckIn'} ref={register} />
      <ControlTitle>Allow early check-in</ControlTitle>
      <ToggleSelector.Form
        name={earlyCheckInTypeFieldName}
        themeMode="dark"
        options={[EarlyCheckInTypeEnum.ALL_DAY, EarlyCheckInTypeEnum.TIME_CONSTRAINED]}
      />
      <Container maxWidth="">
        {earlyCheckInType === EarlyCheckInTypeEnum.TIME_CONSTRAINED && (
          <EarlyCheckInTimeConstrainedMinutesInput />
        )}
      </Container>
    </EarlyCheckInInputGrid>
  );
};

export default EarlyCheckInInput;
