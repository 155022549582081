import { Query, QueryLocationsArgs } from '../../../__generated__/graphql';

import GET_LOCATIONS from '../../../graphql/getLocations';
import { RootState } from '../../../redux/reducers';
import { useApolloClient } from '@apollo/client';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

type GetLocationsData = Pick<Query, 'locations'>;

export default function useSetTimesByLocation(): () => void {
  const client = useApolloClient();
  const me = useSelector((state: RootState) => state.me);
  const activeTenantId = me.activeMembership?.tenantId ?? '';
  const { setValue, trigger, watch } = useFormContext();

  return (): void => {
    const locationId: string = watch('location.value');

    client
      .query<GetLocationsData, QueryLocationsArgs>({
        query: GET_LOCATIONS,
        variables: {
          where: {
            id: { equals: locationId },
            tenantId: { equals: activeTenantId },
          },
        },
      })
      .then(({ data }) => {
        const [location] = data.locations;
        const END_TIME_PATH = 'endTime';
        const START_TIME_PATH = 'startTime';

        setValue(END_TIME_PATH, location.defaultEndTime);
        setValue(START_TIME_PATH, location.defaultStartTime);
        trigger([END_TIME_PATH, START_TIME_PATH]);
      });
  };
}
