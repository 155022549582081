import { InfoType } from '../../../../__generated__/graphql';
import { RootState } from '../../../../redux/reducers';
import SelectOption from '../../../../@types/react/SelectOption';
import { getParents } from '@minoru/react-dnd-treeview';
import { useSelector } from 'react-redux';

type TLoadOptionsPayload = {
  contentType: SelectOption;
  type: SelectOption;
};

export const useConciergeParentCategoriesWithState = (payload: TLoadOptionsPayload) => {
  const useConciergeParentCategories: () => () => Promise<SelectOption[]> = () => {
    const { id } = useSelector((state: RootState) => state.modals.form);
    const { treeData } = useSelector((state: RootState) => state.concierge);
    return async (): Promise<SelectOption[]> => {
      return (
        treeData
          ?.filter(({ data }) => {
            const { type } = payload || {};
            const { value: typeValue } = type || {};
            if (typeValue === InfoType.Category) {
              const allParents = getParents(treeData, data?.id as string).filter(
                (item) => item.data?.type === InfoType.Category
              );
              return data?.type === InfoType.Category && data.id !== id && !allParents.length;
            }
            return data?.type === InfoType.Category && data.id !== id;
          })
          .map((val) => ({
            label: val.text,
            value: val.id as string,
          })) ?? []
      );
    };
  };
  return useConciergeParentCategories;
};
