import 'react-intl-tel-input/dist/main.css';
import 'styled-components/macro';

import React, { useEffect } from 'react';

import Container from '../Container';
import IntlTelInput from 'react-intl-tel-input';
import PhoneInputContainerStyle from './styles/PhoneInputContainer.style';
import isValidPhoneNumber from '../../util/isValidPhoneNumber';
import { useFormContext } from 'react-hook-form';

interface PhoneInputProps {
  className?: string;
  name: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  required: boolean;
}

interface PhoneNumber {
  fullNumber: string;
  isValid: boolean;
  newNumber: string;
}

const PhoneInput: React.FC<PhoneInputProps> = ({
  className,
  name,
  onChange,
  placeholder,
  required,
}: PhoneInputProps) => {
  const { clearErrors, getValues, register, setError, setValue, trigger } = useFormContext();

  const errorMessage = 'Invalid Phone Number';

  const initialValue = getValues(name);

  useEffect(() => {
    register(name);
  });

  function handlePhoneNumberChange(_: boolean, __: string, ___: unknown, fullNumber: string): void {
    const isValid = isValidPhoneNumber(fullNumber);
    setValue(name, fullNumber);
    if (isValid) {
      clearErrors(name);
    } else if (fullNumber && fullNumber.length > 0) {
      setError(name, { message: errorMessage });
    } else {
      trigger(name);
    }

    if (onChange) {
      onChange(fullNumber);
    }
  }

  function handleSetFlag(): void {
    const isValid = isValidPhoneNumber(getValues(name));
    if (!isValid) {
      setError(name, { message: errorMessage });
    }
  }

  function handleBlur(): void {
    const phoneNumber = getValues(name);

    const isValid = isValidPhoneNumber(phoneNumber);
    if (required && !isValid) {
      setValue(name, '');
      setError(name, { message: errorMessage });
    } else if (phoneNumber?.length && !isValid) {
      setError(name, { message: errorMessage });
    } else if (!required && !phoneNumber?.length) {
      setValue(name, '');
    }
  }

  return (
    <Container className={className} css={PhoneInputContainerStyle}>
      <IntlTelInput
        defaultValue={initialValue}
        onPhoneNumberBlur={handleBlur}
        onPhoneNumberChange={handlePhoneNumberChange}
        onSelectFlag={handleSetFlag}
        placeholder={placeholder}
        telInputProps={{
          maxLength: 20,
        }}
      />
    </Container>
  );
};

export default PhoneInput;
