import { DataTableFilterValues } from '../../../@types/client/DataTableFilterValues';
import { VendorServerWhereInput } from '../../../__generated__/graphql';

export default function parseVendorServerQueryParameters(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fieldsFiltered: DataTableFilterValues[]
): Partial<VendorServerWhereInput> {
  const whereInput: Partial<VendorServerWhereInput> = {};

  return whereInput;
}
