export enum FontSize {
  bodyLarge = '14px',
  bodySmall = '12px',
  display = '14px',
  firstHeading = '40px',
  fourthHeading = '14px',
  label = '12px',
  secondHeading = '22px',
  tableHeading = '13px',
  thirdHeading = '16px',
}

export default {
  bodyLarge: FontSize.bodyLarge,
  bodySmall: FontSize.bodySmall,
  display: FontSize.display,
  firstHeading: FontSize.firstHeading,
  fourthHeading: FontSize.fourthHeading,
  label: FontSize.label,
  secondHeading: FontSize.secondHeading,
  tableHeading: FontSize.tableHeading,
  thirdHeading: FontSize.thirdHeading,
};
