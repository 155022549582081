import OrderByParams, { OrderByParam } from '../../../@types/client/OrderByParams';

import OrderByDataTableParams from '../../../redux/actions/@types/DataTableOrderByParams';
import SortType from '../@types/SortType.enum';

const parseOrderByDataTableParams = (params: OrderByDataTableParams[]): OrderByParams => {
  if (!params) {
    return [];
  }

  return params.map(
    ({ key, sortOrder, sortType }): OrderByParam => {
      if (sortType === SortType.SortOrder) {
        return { [key]: sortOrder };
      }

      return { [key]: { sort: sortOrder } };
    }
  );
};

export default parseOrderByDataTableParams;
