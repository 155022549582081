import { AreaClosed, Bar } from '@visx/shape';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { GridColumns, GridRows } from '@visx/grid';
import React, { useCallback, useMemo } from 'react';
import { accentColor, background, background2 } from '../constants/colors';
import { bisector, extent, max } from 'd3-array';
import { scaleLinear, scaleTime } from '@visx/scale';

import Container from '../../Container';
import { Group } from '@visx/group';
import LGToolTipLine from './LgToolTipLine';
import LGTooltip from './LGToolTip';
import LineGraphPositionContext from '../contexts/LineGraphPositionContext';
import LineGraphProps from '../@types/LineGraphProps';
import { LinearGradient } from '@visx/gradient';
import SingleDayAggregate from '../../../@types/client/SingleDayAggregate';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { curveMonotoneX } from '@visx/curve';
import { localPoint } from '@visx/event';
import moment from 'moment-timezone';
import { withTooltip } from '@visx/tooltip';

type TooltipData = SingleDayAggregate;

interface DatumWithDateString {
  date: string;
}

interface DatumWithNumericValue {
  count: number;
}

// accessors
const getDate = (datum: DatumWithDateString): Date => {
  return moment(datum.date, 'YYYY-MM-DD').toDate();
};

const getValue = (datum: DatumWithNumericValue): number => datum.count;

const bisectDate = bisector<DatumWithDateString, Date>((d) => new Date(d.date)).left;

const getAxisXNumTicks = (width: number, dataLength: number): number => {
  if (width < 520) {
    return 5;
  }

  if (dataLength > 15) {
    return 15;
  }

  return dataLength;
};

const LineGraphContainer = withTooltip<LineGraphProps, TooltipData>(
  ({
    data,
    width,
    height,
    margin = {
      bottom: 50,
      left: 50,
      right: 50,
      top: 50,
    },
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0,
  }: LineGraphProps & WithTooltipProvidedProps<TooltipData>) => {
    if (width < 10) {
      return null;
    }

    // bounds
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // scales
    const dateScale = useMemo(
      () =>
        scaleTime({
          domain: extent(data, getDate) as [Date, Date],
          range: [margin.left, innerWidth + margin.left],
        }),
      [data, innerWidth, margin.left]
    );

    const valueScale = useMemo(
      () =>
        scaleLinear({
          domain: [0, (max(data, getValue) || 0) + 10],
          nice: true,
          range: [innerHeight + margin.top, margin.top],
        }),
      [data, margin.top, innerHeight]
    );

    // tooltip handler
    const handleTooltip = useCallback(
      (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
        const { x } = localPoint(event) || { x: 0 };
        const x0 = dateScale.invert(x - margin.left);
        const index = bisectDate(data, x0, 1);
        const d0 = data[index - 1];
        const d1 = data[index];
        let d = d0;

        if (d1 && getDate(d1)) {
          d = x0.valueOf() - getDate(d0).valueOf() > getDate(d1).valueOf() - x0.valueOf() ? d1 : d0;
        }

        showTooltip({
          tooltipData: d,
          tooltipLeft: dateScale(getDate(d)),
          tooltipTop: valueScale(getValue(d)),
        });
      },
      [data, margin.left, showTooltip, valueScale, dateScale]
    );

    dateScale.range([0, innerWidth]);
    valueScale.range([innerHeight, 0]);

    return (
      <LineGraphPositionContext.Provider
        value={{ height, innerHeight, innerWidth, margin, tooltipLeft, tooltipTop, width }}
      >
        <Container
          css={`
            border-radius: 0;
          `}
        >
          <svg width={width} height={height}>
            <AxisLeft left={margin.left} scale={valueScale} top={margin.top} />
            <AxisBottom
              tickFormat={(tick: { valueOf(): number }): string => {
                const date = new Date(tick.valueOf());
                return moment(date).format('MMM DD');
              }}
              left={margin.left}
              numTicks={getAxisXNumTicks(width, data.length)}
              scale={dateScale}
              top={innerHeight + margin.top}
              tickLabelProps={() =>
                ({
                  angle: -45,
                  fill: '#222',
                  fontFamily: 'Arial',
                  fontSize: 10,
                  textAnchor: 'middle',
                } as const)
              }
            />
            <Group left={margin.left} top={margin.top}>
              <rect
                x={0}
                y={0}
                width={innerWidth}
                height={innerHeight}
                fill="url(#area-background-gradient)"
              />
              <LinearGradient id="area-background-gradient" from={background} to={background2} />
              <LinearGradient
                id="area-gradient"
                from={accentColor}
                to={accentColor}
                toOpacity={0.1}
              />
              <GridRows
                scale={valueScale}
                width={innerWidth}
                strokeDasharray="1,3"
                stroke={accentColor}
                strokeOpacity={0}
                pointerEvents="none"
              />
              <GridColumns
                top={margin.top}
                scale={dateScale}
                height={innerHeight}
                strokeDasharray="1,3"
                stroke={accentColor}
                strokeOpacity={0.2}
                pointerEvents="none"
              />
              <AreaClosed<SingleDayAggregate>
                data={data}
                x={(d): number => dateScale(getDate(d)) ?? 0}
                y={(d): number => valueScale(getValue(d)) ?? 0}
                yScale={valueScale}
                strokeWidth={1}
                stroke="url(#area-gradient)"
                fill="url(#area-gradient)"
                curve={curveMonotoneX}
              />
              <Bar
                width={innerWidth}
                height={innerHeight}
                fill="transparent"
                rx={14}
                onTouchStart={handleTooltip}
                onTouchMove={handleTooltip}
                onMouseMove={handleTooltip}
                onMouseLeave={(): void => hideTooltip()}
              />
              <LGToolTipLine data={tooltipData} />
            </Group>
          </svg>
          <LGTooltip data={tooltipData} />
        </Container>
      </LineGraphPositionContext.Provider>
    );
  }
);

export default LineGraphContainer;
