import { LocationWhereInput, Query, QueryLocationsArgs } from '../../../../__generated__/graphql';

import Filter from '../../../../components/DataTable/@types/Filter';
import GET_LOCATIONS from '../../../../graphql/getLocations';
import { RootState } from '../../../../redux/reducers';
import addDatesFilters from './util/addDatesFilters';
import addIdVerificationStatusFilters from './util/addIdVerificationStatusFilters';
import addLocationFilters from '../../../util/filters/addLocationFilters';
import addStateFilters from './util/addStateFilters';
import addStatusFilters from './util/addStatusFilters';
import { useEffect } from 'react';
import useGetMany from '../../../useGetMany';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

type GetLocationsData = Pick<Query, 'locations'>;

export default function useAccessGrantFilters(): Filter[] {
  const { locationId } = useParams<{ locationId?: string }>();
  const activeMembership = useSelector((state: RootState) => state.me.activeMembership);
  const filters: Filter[] = [];

  const { called, data, loading, query } = useGetMany<GetLocationsData, QueryLocationsArgs>({
    graphqlQuery: GET_LOCATIONS,
  });

  useEffect(() => {
    if (!called) {
      const params: LocationWhereInput = {
        tenantId: {
          equals: activeMembership.tenantId,
        },
      };

      query({
        where: params,
      });
    }
  }, [called, query]);

  if (!locationId && !activeMembership.location?.id) {
    addLocationFilters(data?.locations || [], filters);
  }

  addIdVerificationStatusFilters(filters);
  addDatesFilters(filters);
  addStateFilters(filters);
  addStatusFilters(filters);

  if (loading) {
    return [];
  }

  return filters;
}
