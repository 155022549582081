const DetailPageTabContainerStyle = `
  padding: 40px 45px;

  h3 {
    margin: 0 0 40px;
    padding: 0 35px;
  }
`;

export default DetailPageTabContainerStyle;
