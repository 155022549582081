import 'styled-components/macro';

import React, { useEffect, useState } from 'react';

import Bus from '../../services/events/bus';
import Button from '../Button';
import ButtonModel from '../Button/enums/ButtonModel.enum';
import Container from '../Container';
import FlashContainerStyle from './styles/FlashContainer.style';
import FlashMessageContainerStyle from './styles/FlashMessageContainer.style';
import FlashSubTextStyle from './styles/FlashSubText.style';
import { Link } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import TextContent from '../TextContent';
import links from '../../util/links';

const FlashMessage: React.FC<{}> = () => {
  const [visibility, setVisibility] = useState<boolean>(false);
  const [link, setLink] = useState<string>(links.paths.HOME);
  const [message, setMessage] = useState<string>('');
  const [subText, setSubText] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [onCloseAction, setOnCloseAction] = useState<Function>();

  function close(onClose?: Function): void {
    const onCloseActionFunction = onClose ?? onCloseAction;
    if (onCloseActionFunction) {
      onCloseActionFunction();
    }
    setVisibility(false);
    setMessage('');
    setType('');
    setOnCloseAction(undefined);
  }

  function closeMessage(): void {
    return close();
  }

  useEffect(() => {
    /* ensure that EventEmitter isn't constantly adding listeners */
    if (Bus.listenerCount('flash') < 1) {
      Bus.addListener(
        'flash',
        ({
          link: messageLink,
          message: messageText,
          subText: messageSubText,
          type: messageType,
          timeout: messageTimeout,
          onClose: onCloseMessage,
        }) => {
          setVisibility(true);
          setLink(messageLink);
          setMessage(messageText);
          setSubText(messageSubText);
          setType(messageType);
          setOnCloseAction(() => onCloseMessage);
          if (messageTimeout === undefined || messageTimeout > 0) {
            setTimeout(() => close(onCloseMessage), messageTimeout ?? 4000);
          }
        }
      );
    }
  }, [close]);

  return (
    <>
      {visibility && (
        <Container className={`flash-message flash-message-${type}`} css={FlashContainerStyle}>
          <Container css={FlashMessageContainerStyle} key={`flash-message`}>
            <Button model={ButtonModel.CIRCLE_ICON_BUTTON_DARK} onClick={closeMessage}>
              <MdClose size="18px" />
            </Button>
            <Container>
              <TextContent>
                {message}
                {link && (
                  <>
                    {'. '}
                    <Link onClick={closeMessage} to={link}>
                      Navigate.
                    </Link>
                  </>
                )}
              </TextContent>
              {subText && <TextContent css={FlashSubTextStyle}>{subText}</TextContent>}
            </Container>
          </Container>
        </Container>
      )}
    </>
  );
};

export default FlashMessage;
