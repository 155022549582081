import Form from '../../Form';
import React from 'react';
import { RootState } from '../../../redux/reducers';
import SimpleFormSection from '../../../templates/SimpleFormSection';
import SubmitRow from '../../SubmitRow';
import generateInfoItemSectionInputs from '../constants/infoItemSectionInputs';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useSubmitInfoItem from '../hooks/useSubmitInfoItem';

const InfoItemSection: React.FC<{}> = () => {
  const id = useSelector((state: RootState) => state.modals.form.id);
  const { handleSubmit, watch } = useFormContext();
  const imageType: string = watch('imageType');

  const { loadingMutation, submitHandler } = useSubmitInfoItem();

  const INFO_ITEM_SECTION_INPUTS = generateInfoItemSectionInputs({ imageType });

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <SimpleFormSection inputs={INFO_ITEM_SECTION_INPUTS} />
      <SubmitRow loading={loadingMutation} submitText={id ? 'Update Info Item' : 'Set Info Item'} />
    </Form>
  );
};

export default InfoItemSection;
