import { TimePeriodType } from '../enums';

export default function convert24To12HoursFormat(
  timeString: string
): {
  period: TimePeriodType;
  time: string;
} {
  const [hour, minutes] = timeString.split(':');

  if (!hour || !minutes) {
    throw new Error('convert24To12HoursFormat error: Malformed time string');
  }

  const hourNumber = Number.parseInt(hour, 10);

  if (hourNumber === 0) {
    return {
      period: TimePeriodType.AM,
      time: `00:${minutes}`,
    };
  }

  if (hourNumber > 12) {
    const convertedHour = hourNumber - 12;
    const finalHour = convertedHour.toString().length === 1 ? `0${convertedHour}` : convertedHour;

    return {
      period: TimePeriodType.PM,
      time: `${finalHour}:${minutes}`,
    };
  }

  return {
    period: TimePeriodType.AM,
    time: `${hour}:${minutes}`,
  };
}
