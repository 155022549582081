/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationHomeSchema: NodeSchemaProperties = {
  home: {
    title: '#3 Home',
    type: 'object',
    properties: {
      'title:enable': {
        description: '[home.title]',
        title: '#1 Title',
        type: 'boolean',
      },
      title: {
        type: 'string',
      },
      'locationDirections:enable': {
        description: '[home.locationDirections]',
        title: '#2 Location Directions',
        type: 'boolean',
      },
      locationDirections: {
        type: 'string',
      },
      'remoteAssistance:enable': {
        description: '[home.remoteAssistance]',
        title: '#3 Remote Assistance',
        type: 'boolean',
      },
      remoteAssistance: {
        type: 'string',
      },
      reservationInfo: {
        title: '#4 Reservation Info',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[home.reservationInfo.title]',
            title: '#4.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'checkIn:enable': {
            description: '[home.reservationInfo.checkIn]',
            title: '#4.2 Check In',
            type: 'boolean',
          },
          checkIn: {
            type: 'string',
          },
          'checkOut:enable': {
            description: '[home.reservationInfo.checkOut]',
            title: '#4.3 Check Out',
            type: 'boolean',
          },
          checkOut: {
            type: 'string',
          },
          'roomNumber:enable': {
            description: '[home.reservationInfo.roomNumber]',
            title: '#4.4 Room Number',
            type: 'boolean',
          },
          roomNumber: {
            type: 'string',
          },
        },
      },
      checkInButton: {
        title: '#5 Check In Button',
        type: 'object',
        properties: {
          'beforeCheckInCompleteRequirements:enable': {
            description: '[home.checkInButton.beforeCheckInCompleteRequirements]',
            title: '#5.1 Before Check In Complete Requirements',
            type: 'boolean',
          },
          beforeCheckInCompleteRequirements: {
            type: 'string',
          },
          'beforeCheckInIncompleteRequirements:enable': {
            description: '[home.checkInButton.beforeCheckInIncompleteRequirements]',
            title: '#5.2 Before Check In Incomplete Requirements',
            type: 'boolean',
          },
          beforeCheckInIncompleteRequirements: {
            type: 'string',
          },
          'nowCheckInCompleteRequirements:enable': {
            description: '[home.checkInButton.nowCheckInCompleteRequirements]',
            title: '#5.3 Now Check In Complete Requirements',
            type: 'boolean',
          },
          nowCheckInCompleteRequirements: {
            type: 'string',
          },
          'nowCheckInIncompleteRequirements:enable': {
            description: '[home.checkInButton.nowCheckInIncompleteRequirements]',
            title: '#5.4 Now Check In Incomplete Requirements',
            type: 'boolean',
          },
          nowCheckInIncompleteRequirements: {
            type: 'string',
          },
        },
      },
      checkOutButton: {
        title: '#6 Check Out Button',
        type: 'object',
        properties: {
          'nowCheckOut:enable': {
            description: '[home.checkOutButton.nowCheckOut]',
            title: '#6.1 Now Check Out',
            type: 'boolean',
          },
          nowCheckOut: {
            type: 'string',
          },
        },
      },
      quickAccess: {
        title: '#7 Quick Access',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[home.quickAccess.title]',
            title: '#7.1 Quick access widget title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          items: {
            title: '#7.2 Quick items localization',
            type: 'object',
            properties: {
              'hotelLocation:enable': {
                description: '[home.quickAccess.items.hotelLocation]',
                title: '#7.2.1 Hotel location',
                type: 'boolean',
              },
              hotelLocation: {
                type: 'string',
              },
              'remoteAssistance:enable': {
                description: '[home.quickAccess.items.remoteAssistance]',
                title: '#7.2.2 Remote assistance',
                type: 'boolean',
              },
              remoteAssistance: {
                type: 'string',
              },
              'accessQrCode:enable': {
                description: '[home.quickAccess.items.accessQrCode]',
                title: '#7.2.3 Access QR code',
                type: 'boolean',
              },
              accessQrCode: {
                type: 'string',
              },
              'seeMoreServices:enable': {
                description: '[home.quickAccess.items.seeMoreServices]',
                title: '#7.2.4 See more services',
                type: 'boolean',
              },
              seeMoreServices: {
                type: 'string',
              },
              'concierge:enable': {
                description: '[home.quickAccess.items.concierge]',
                title: '#7.2.5 Concierge',
                type: 'boolean',
              },
              concierge: {
                type: 'string',
              },
            },
          },
        },
      },
    },
  },
};

export const mobileV3LocalizationHomeUiSchema: NodeUiSchemaProperties = {
  home: {
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    locationDirections: {
      'ui:label': false,
      'ui:readonly': true,
    },
    remoteAssistance: {
      'ui:label': false,
      'ui:readonly': true,
    },
    reservationInfo: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      checkIn: {
        'ui:label': false,
        'ui:readonly': true,
      },
      checkOut: {
        'ui:label': false,
        'ui:readonly': true,
      },
      roomNumber: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    checkInButton: {
      beforeCheckInCompleteRequirements: {
        'ui:label': false,
        'ui:readonly': true,
      },
      beforeCheckInIncompleteRequirements: {
        'ui:label': false,
        'ui:readonly': true,
      },
      nowCheckInCompleteRequirements: {
        'ui:label': false,
        'ui:readonly': true,
      },
      nowCheckInIncompleteRequirements: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    checkOutButton: {
      nowCheckOut: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    quickAccess: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      items: {
        hotelLocation: {
          'ui:label': false,
          'ui:readonly': true,
        },
        remoteAssistance: {
          'ui:label': false,
          'ui:readonly': true,
        },
        accessQrCode: {
          'ui:label': false,
          'ui:readonly': true,
        },
        seeMoreServices: {
          'ui:label': false,
          'ui:readonly': true,
        },
        concierge: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
    },
  },
};
