import { gql } from '../__generated__/gql';

const GET_PAYMENT_TRANSACTION_COUNT = gql(`
  query GetPaymentTransaction($where: PaymentTransactionWhereInput!) {
    aggregatePaymentTransaction(where: $where) {
      _count {
        _all
      }
    }
  }
`);

export default GET_PAYMENT_TRANSACTION_COUNT;
