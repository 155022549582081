import 'styled-components/macro';
import BYPASS_VERIFICATION from '../../graphql/bypassVerification';
import Button from '../Button';
import ButtonModel from '../Button/enums/ButtonModel.enum';
import Container from '../Container';
import ISSUE_KEY_FOR_KIOSK from '../../graphql/issueKeyForKiosk';
import KioskActionButtonStyle from './styles/KioskActionButton.style';
import KioskActionsModalContainerStyle from './styles/KioskActionsModalContainer.style';
import KioskActionsModalStyle from './constants/KioskActionsModalStyle';
import Modal from 'react-modal';
import React from 'react';
import TAKE_SCREENSHOT from '../../graphql/takeScreenshot';
import { useMutation } from '@apollo/client';

interface KioskActionsModalProps {
  accessGrantId?: string;
  close: () => void;
  kioskId: string;
}

const KioskActionsModal: React.FC<KioskActionsModalProps> = ({
  close,
  kioskId,
}: KioskActionsModalProps) => {
  const [issueKeyForKiosk, { loading: isIssuingKey }] = useMutation(ISSUE_KEY_FOR_KIOSK, {
    variables: {
      kioskId,
    },
  });

  function issueKey(): void {
    issueKeyForKiosk();
  }

  const [takeScreenshot] = useMutation(TAKE_SCREENSHOT, {
    variables: {
      kioskId,
    },
  });

  function screenshot(): void {
    takeScreenshot();
  }

  const [bypassVerification, { loading: isBypassingVerification }] = useMutation(
    BYPASS_VERIFICATION,
    {
      variables: {
        kioskId,
      },
    }
  );

  function bypass(): void {
    bypassVerification();
  }

  if (kioskId) {
    return (
      <Modal onRequestClose={close} isOpen style={KioskActionsModalStyle}>
        <Container css={KioskActionsModalContainerStyle}>
          <Button
            css={KioskActionButtonStyle}
            disabled={isIssuingKey}
            model={ButtonModel.ACTIVE_CTA_DARK}
            onClick={issueKey}
          >
            Issue Key
          </Button>
          {/* Do not render until screenshot feature has been fixed */}
          {false && (
            <Button
              css={KioskActionButtonStyle}
              disabled
              model={ButtonModel.ACTIVE_CTA_DARK}
              onClick={screenshot}
            >
              Take Screenshot
            </Button>
          )}
          <Button
            css={KioskActionButtonStyle}
            disabled={isBypassingVerification}
            model={ButtonModel.ACTIVE_CTA_DARK}
            onClick={bypass}
          >
            Bypass Verification
          </Button>
        </Container>
      </Modal>
    );
  }

  return <></>;
};

export default KioskActionsModal;
