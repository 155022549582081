import { FileType, RequirementStatus, RequirementType } from '../../../__generated__/graphql';

import { Datum } from '../../../components/DataTable';
import GetAccessGrantPageRequirements from '../@types/GetAccessGrantPageRequirements';
import { UseDateTimeFormatterInterface } from '../../../hooks/useDateTimeFormatter';

export default function generateAccessGrantRequirementTableData(
  formatter: UseDateTimeFormatterInterface,
  data?: GetAccessGrantPageRequirements,
  locationTimezone?: string
): {
  count: number;
  tableData: Datum[];
} {
  const { formatDate } = formatter;

  const requirements = data?.requirements ?? [];
  const count = 0;

  const tableData = requirements.map((requirement) => {
    const isTermsAndConditionSigned =
      requirement.type === RequirementType.TermsAndConditionsConfirmation &&
      requirement.status === RequirementStatus.Completed;
    const termsAndConditionsSignedFile =
      isTermsAndConditionSigned &&
      requirement.files.find(
        (fileToEntity) => fileToEntity.file.fileType === FileType.SignedAgreementFile
      );

    return {
      dataType: 'Requirement',
      fields: [
        {
          key: 'Type',
          ...(termsAndConditionsSignedFile &&
            termsAndConditionsSignedFile.file.url && {
              href: termsAndConditionsSignedFile.file.url,
            }),
          value: requirement.type,
        },
        {
          key: 'Status',
          value: requirement.status,
        },
        {
          key: 'Created At',
          value: formatDate(requirement.createdAt, { timezone: locationTimezone }),
        },
        {
          key: 'Updated At',
          value: formatDate(requirement.updatedAt, { timezone: locationTimezone }),
        },
      ],
      key: requirement.id,
    };
  });

  return {
    count,
    tableData,
  };
}
