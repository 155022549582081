/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationAssistanceSchema: NodeSchemaProperties = {
  remoteAssistance: {
    title: '#12 Assistance Screen',
    type: 'object',
    properties: {
      callStatus: {
        title: '#1 Call status',
        type: 'object',
        properties: {
          'connecting:enable': {
            title: '#1.1 Connecting',
            type: 'boolean',
            description: '[remoteAssistance.callStatus.connecting]',
          },
          connecting: { type: 'string' },
          'connected:enable': {
            title: '#1.2 Connected',
            type: 'boolean',
            description: '[remoteAssistance.callStatus.connected]',
          },
          connected: { type: 'string' },
          'disconnected:enable': {
            title: '#1.3 Disconnected',
            type: 'boolean',
            description: '[remoteAssistance.callStatus.disconnected]',
          },
          disconnected: { type: 'string' },
        },
      },
      confirmationOnEndCall: {
        title: '#2 Confirmation on end call',
        type: 'object',
        properties: {
          'text:enable': {
            title: '#2.1 Text',
            type: 'boolean',
            description: '[remoteAssistance.confirmationOnEndCall.text]',
          },
          text: { type: 'string' },
          'yes:enable': {
            title: '#2.2 Yes',
            type: 'boolean',
            description: '[remoteAssistance.confirmationOnEndCall.yes]',
          },
          yes: { type: 'string' },
          'cancel:enable': {
            title: '#2.3 Cancel',
            type: 'boolean',
            description: '[remoteAssistance.confirmationOnEndCall.cancel]',
          },
          cancel: { type: 'string' },
        },
      },
      calling: {
        title: '#3 Calling',
        type: 'object',
        properties: {
          'title:enable': {
            title: '#3.1 Title',
            type: 'boolean',
            description: '[remoteAssistance.calling.title]',
          },
          title: { type: 'string' },
        },
      },
      selectCallMethod: {
        title: '#4 Select call method',
        type: 'object',
        properties: {
          'header:enable': {
            title: '#4.1 Header',
            type: 'boolean',
            description: '[remoteAssistance.selectCallMethod.header]',
          },
          header: { type: 'string' },
          'subHeader:enable': {
            title: '#4.2 Sub header',
            type: 'boolean',
            description: '[remoteAssistance.selectCallMethod.subHeader]',
          },
          subHeader: { type: 'string' },
          'video:enable': {
            title: '#4.3 Video',
            type: 'boolean',
            description: '[remoteAssistance.selectCallMethod.video]',
          },
          video: { type: 'string' },
          'whatsApp:enable': {
            title: '#4.4 WhatsApp',
            type: 'boolean',
            description: '[remoteAssistance.selectCallMethod.whatsApp]',
          },
          whatsApp: { type: 'string' },
          'phone:enable': {
            title: '#4.5 Phone',
            type: 'boolean',
            description: '[remoteAssistance.selectCallMethod.phone]',
          },
          phone: { type: 'string' },
        },
      },
      'headerTitle:enable': {
        title: '#5 Header title',
        type: 'boolean',
        description: '[remoteAssistance.headerTitle]',
      },
      headerTitle: { type: 'string' },
      permission: {
        title: '#6 Permission',
        type: 'object',
        properties: {
          error: {
            title: '#6.1 Error',
            type: 'object',
            properties: {
              'voiceNotGranted:enable': {
                description: '[remoteAssistance.permission.error.voiceNotGranted]',
                title: '#6.1.1 Voice Not Granted',
                type: 'boolean',
              },
              voiceNotGranted: {
                type: 'string',
              },
              'cameraNotGranted:enable': {
                description: '[remoteAssistance.permission.error.cameraNotGranted]',
                title: '#6.1.2 Camera Not Granted',
                type: 'boolean',
              },
              cameraNotGranted: {
                type: 'string',
              },
              'voiceAndCameraNotGranted:enable': {
                description: '[remoteAssistance.permission.error.voiceAndCameraNotGranted]',
                title: '#6.1.3 Voice And Camera Not Granted',
                type: 'boolean',
              },
              voiceAndCameraNotGranted: {
                type: 'string',
              },
            },
          },
        },
      },
      'title:enable': {
        description: '[remoteAssistance.title]',
        title: '#7 Title',
        type: 'boolean',
      },
      title: {
        type: 'string',
      },
    },
  },
};

export const mobileV3LocalizationAssistanceUiSchema: NodeUiSchemaProperties = {
  remoteAssistance: {
    callStatus: {
      connecting: {
        'ui:label': false,
        'ui:readonly': true,
      },
      connected: {
        'ui:label': false,
        'ui:readonly': true,
      },
      disconnected: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    confirmationOnEndCall: {
      text: {
        'ui:label': false,
        'ui:readonly': true,
      },
      yes: {
        'ui:label': false,
        'ui:readonly': true,
      },
      cancel: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    calling: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    selectCallMethod: {
      header: {
        'ui:label': false,
        'ui:readonly': true,
      },
      subHeader: {
        'ui:label': false,
        'ui:readonly': true,
      },
      video: {
        'ui:label': false,
        'ui:readonly': true,
      },
      whatsApp: {
        'ui:label': false,
        'ui:readonly': true,
      },
      phone: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    headerTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    permission: {
      error: {
        voiceNotGranted: {
          'ui:label': false,
          'ui:readonly': true,
        },
        cameraNotGranted: {
          'ui:label': false,
          'ui:readonly': true,
        },
        voiceAndCameraNotGranted: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
    },
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
};
