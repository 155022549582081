import { Kiosk } from '../../__generated__/graphql';
import { MINUTE } from '../../constants/time';

const TIME_TO_DETERMINATE_KIOSK_IS_OFFLINE = 1 * MINUTE;

export function getKioskOfflineMS(): number {
  const isOfflineIf = Date.now() - TIME_TO_DETERMINATE_KIOSK_IS_OFFLINE;
  return isOfflineIf;
}

export default function isKioskOnline(kiosk: Pick<Kiosk, 'lastSeenAt'>): boolean {
  if (kiosk.lastSeenAt) {
    const dateInSeconds = Date.parse(kiosk.lastSeenAt);

    if (dateInSeconds) {
      return dateInSeconds >= getKioskOfflineMS();
    }
  }

  return false;
}
