import { gql } from '../__generated__/gql';

const GET_LANGUAGES = gql(`
  query GetLanguages(
    $orderBy: [LanguageOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: LanguageWhereInput
  ) {
    getLanguages(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
      id
      code
      name
      isRTL
    }
  }
`);

export default GET_LANGUAGES;
