import { LocationRelationFilter } from '../../__generated__/graphql';
import { ReduxMembership } from '../../redux/reducers/@types/MeReduxState';

type MembershipQueryWhere = {
  location: LocationRelationFilter;
};

export default function generateLocationMembershipQuery(
  membership: ReduxMembership
): MembershipQueryWhere {
  return {
    location: {
      is: {
        tenant: { is: { id: { equals: membership.tenant.id } } },
        ...(membership.location && { id: { equals: membership.location.id } }),
      },
    },
  };
}
