import isValidPhoneNumber from './isValidPhoneNumber';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export default function formatPhoneForDisplay(phoneNumber: string): string {
  const parsedNumber = parsePhoneNumberFromString(phoneNumber);

  if (!isValidPhoneNumber(phoneNumber) || !parsedNumber) {
    return 'Invalid Phone Number';
  }

  return parsedNumber.formatInternational().replace(/\s/g, '-');
}
