import 'styled-components/macro';

import {
  mobileThemeCustomizationSchema,
  mobileThemeCustomizationUiSchema,
} from '../../../../../components/JsonEditor/constants/ThemeSchema/mobileThemeCustomizationSchema';

import CustomizeTheme from '../../../../../components/CustomizeTheme';
import { CustomizeThemeLocalizationEntityLevel } from '../../../../../enums/CustomizeThemeLocalizationEntityLevel';
import React from 'react';
import { ThemeComponentName } from '../../../../../enums/ComponentNameEnum';
import { useParams } from 'react-router-dom';

const CustomizeMobileTheme: React.FC<{}> = () => {
  const { tenantId } = useParams<{ tenantId: string }>();

  return (
    <CustomizeTheme
      schema={mobileThemeCustomizationSchema}
      uiSchema={mobileThemeCustomizationUiSchema}
      componentName={ThemeComponentName.MOBILE}
      fileNameToDownloadDefaultJson={`Default Mobile theme - Tenant ${tenantId}.json`}
      fileNameToDownloadCurrentJson={`Current Mobile theme - Tenant ${tenantId}.json`}
      title={'Customize mobile app & web theme'}
      entityLevel={CustomizeThemeLocalizationEntityLevel.TENANT}
      entityId={tenantId}
    />
  );
};

export default CustomizeMobileTheme;
