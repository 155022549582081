import { isInteger } from 'lodash';

type IValidateDecimalPlaces = {
  value?: number;
  decimalPlacesAllowed: number;
};
export const validateDecimalPlaces = ({
  value,
  decimalPlacesAllowed,
}: IValidateDecimalPlaces): boolean => {
  if (value === undefined) {
    return true;
  }

  if (decimalPlacesAllowed === 0) {
    return isInteger(value);
  }

  return Boolean(`${value}`.match(new RegExp(`^\\d+(\\.\\d{1,${decimalPlacesAllowed}})?$`)));
};
