import { Query, QueryLocationsArgs, SortOrder } from '../__generated__/graphql';

import QueryHookData from './@types/QueryHookData';
import QueryHookParams from './@types/QueryHookParams';
import SEARCH_LOCATIONS from '../graphql/searchLocations';
import { useLazyQuery } from '@apollo/client';

type SearchLocationsData = Pick<Query, 'locations'>;

export default function useSearchLocationsByName(params?: QueryHookParams): QueryHookData {
  const { onCompleted = (): void => {}, onError = (): void => {}, searchValue = '' } = params ?? {};

  const [getLocations, { data, error, loading }] = useLazyQuery<
    SearchLocationsData,
    QueryLocationsArgs
  >(SEARCH_LOCATIONS, {
    onCompleted,
    onError,
  });

  const locations = data?.locations ?? [];

  return {
    data: locations.map((location) => ({ id: location.id, name: location.name })),
    error,
    loading,
    query: (name = ''): void => {
      getLocations({
        variables: {
          orderBy: [{ name: SortOrder.Asc }],
          take: 10,
          where: {
            name: { contains: name ?? searchValue },
          },
        },
      });
    },
  };
}
