import Theme from '../../../theme';

const CustomizeThemeCrudButton = `
    :disabled {
        cursor: pointer;
        opacity: .5;
    }

    :not(:disabled) {
        &:hover {
            background: ${Theme.colors.buttonDarkBackground.hexCode};
        }
    }    
`;

export default CustomizeThemeCrudButton;
