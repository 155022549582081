import { gql } from '../__generated__/gql';

const GET_AMENITY = gql(`
  query GetAmenity($where: AmenityWhereUniqueInput!) {
    amenity(where: $where) {
      id
      title
      description
      imageUrl
      amenityGroupId
      locationId
      location {
        id
        name
      }
      group {
        id
        title
      }
    }
  }
`);

export default GET_AMENITY;
