import 'styled-components/macro';

import React, { useState } from 'react';

import { DetailPageItem } from '../../templates/DetailPage';
import { IconType } from 'react-icons/lib';
import SettingsTabStyle from './styles/SettingsTab.style';
import { createButtonsFromDetailItems } from '../../templates/DetailPage/createButtonsFromDetailItems';

export interface SettingsTabOption {
  key: string;
  value: string;
  icon: IconType;
  Component: React.ComponentType;
}

interface SettingsTabInterface {
  tabs: Array<SettingsTabOption>;
}

const SettingsTab: React.FC<SettingsTabInterface> = ({ tabs }: SettingsTabInterface) => {
  const [activeTab, setActiveTab] = useState<string | null>();

  const detailsPageButtons: DetailPageItem[] = tabs.map((button) => {
    return {
      buttonIsSelected: button.key === activeTab,
      disabled: false,
      icon: button.icon,
      onClick: (): void => {
        let newActiveTab: string | null = null;
        if (button.key !== activeTab) {
          newActiveTab = button.key;
        }

        setActiveTab(newActiveTab);
      },
      text: button.value,
    };
  });

  const TabComponent = tabs.find((tab) => tab.key === activeTab)?.Component;

  return (
    <div css={SettingsTabStyle}>
      <div>{createButtonsFromDetailItems(detailsPageButtons)}</div>
      <div
        css={`
          width: 100%;
        `}
      >
        {TabComponent && <TabComponent />}
      </div>
    </div>
  );
};

export default SettingsTab;
