import DataTable from '../../../components/DataTable';
import { LocationPageInfo } from '../@types/GetLocationPageInfo';
import React from 'react';
import { ServiceStatus } from '../../../__generated__/graphql';
import { useParams } from 'react-router-dom';
import useServicesTableData from '../../../hooks/useServicesTableData';

interface ServiceTabProps {
  location: LocationPageInfo;
}

const ServicesTab: React.FC<ServiceTabProps> = ({ location }: ServiceTabProps) => {
  const { locationId } = useParams<{ locationId: string }>();
  const tableData = useServicesTableData({
    queryOptions: {
      where: {
        locationId: {
          equals: locationId,
        },
        status: {
          equals: ServiceStatus.Active,
        },
      },
    },
    relatedLocation: location,
  });

  return <DataTable {...tableData} />;
};

export default ServicesTab;
