import FormTemplateStep from '../../../templates/Form/@types/FormTemplateStep';
import ImportUnitTypesSection from '../components/ImportUnitTypesSection';
import { Mutation } from '../../../__generated__/graphql';
import UnitTypeImportFormInputs from '../@types/UnitTypeImportFormInputs';

type UploadUnitTypesData = Pick<Mutation, 'uploadUnitTypes'>;

const UNIT_TYPE_IMPORT_FORM_STEPS: FormTemplateStep<
  UnitTypeImportFormInputs,
  UploadUnitTypesData
>[] = [
  {
    canBeAccessed: (): boolean => true,
    component: ImportUnitTypesSection,
    name: 'Upload Unit Types',
  },
];

export default UNIT_TYPE_IMPORT_FORM_STEPS;
