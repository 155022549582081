import ACCESS_MEMBERSHIP from '../graphql/accessMembership';
import { flashApolloError } from '../util/errorUtils';
import { useMutation } from '@apollo/client';

export default function useAccessMembership(): { query: (id: string) => void } {
  const [query] = useMutation(ACCESS_MEMBERSHIP, {
    onCompleted: () => {},
    onError: flashApolloError,
  });

  return {
    query: (id: string): void => {
      query({
        variables: {
          membershipId: id,
        },
      });
    },
  };
}
