import * as yup from 'yup';

import FormCrudAction from '../../../enums/FormCrudAction.enum';

export const getEnrollKioskSectionValidationSchema = (
  formAction: FormCrudAction
): yup.AnyObjectSchema => {
  const schema = yup.object().shape({
    location: yup.object().shape({
      label: yup.string().required('Location is required'),
      value: yup.string().required('Location is required'),
    }),
    name: yup.string().required('Name is required'),
  });

  if (formAction === FormCrudAction.CREATE) {
    return schema.concat(
      yup.object().shape({
        code: yup.string().required('Code is required'),
      })
    );
  }

  return schema;
};

export const CONFIGURATION_KIOSK_SECTION_VALIDATION_SCHEMA = yup.object().shape({
  dispenserType: yup.string().nullable(),
  hasDispenser: yup.boolean(),
  hasTerminal: yup.boolean(),
  powerSwitchId: yup.string().nullable(),
  rfidTagType: yup.string().nullable(),
});
