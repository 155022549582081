import 'styled-components/macro';

import Heading, { HeadingModel } from '../../../Heading';
import { MdCloudDownload, MdFileDownload, MdFileUpload } from 'react-icons/md';

import Container from '../../../Container';
import CustomizeThemeButtons from '../../../CustomizeJson/styles/CustomizeThemeButtons.style';
import CustomizeThemeHeader from '../../../CustomizeJson/styles/CustomizeThemeHeader.style';
import JsonCustomizationActionButton from '../JsonCustomizationButtons/ActionButton';
import JsonCustomizationSaveButton from '../JsonCustomizationButtons/SaveButton';
import React from 'react';
import Tooltip from '../../../Tooltip';

interface JsonCustomizationHeader {
  onTapDownloadButton: () => void;
  onTapDownloadCurrentButton: () => void;
  onTapSubmitButton: () => void;
  onUploadJSONFile: (content: string) => void;
  enableButtons?: boolean;
  title: string;
}

const JsonCustomizationHeader: React.FC<JsonCustomizationHeader> = ({
  onTapDownloadButton,
  onTapSubmitButton,
  onTapDownloadCurrentButton,
  onUploadJSONFile,
  enableButtons = true,
  title,
}: JsonCustomizationHeader) => {
  const selectJSONFileInputButtonRef = React.createRef<HTMLInputElement>();

  const onUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files) {
      const file = e.target.files[0];

      const fileReader = new FileReader();
      fileReader.onloadend = (): void => {
        if (fileReader.result) {
          const result = fileReader.result as string;
          onUploadJSONFile(result);
        }
      };
      fileReader.readAsText(file);
    }
  };

  return (
    <div css={CustomizeThemeHeader}>
      <Heading margin="0 0 15px 0" model={HeadingModel.SECONDARY} color="#ffffff">
        {title}
      </Heading>
      <Container css={CustomizeThemeButtons}>
        <Tooltip content="Download default JSON" direction="top">
          <JsonCustomizationActionButton
            enabled={enableButtons}
            icon={<MdCloudDownload />}
            onClick={onTapDownloadButton}
          />
        </Tooltip>
        <Tooltip content="Download current JSON" direction="top">
          <JsonCustomizationActionButton
            enabled={enableButtons}
            icon={<MdFileDownload />}
            onClick={onTapDownloadCurrentButton}
          />
        </Tooltip>
        <Tooltip content="Upload JSON" direction="top">
          <div>
            <JsonCustomizationActionButton
              enabled={enableButtons}
              icon={<MdFileUpload />}
              onClick={(): void => selectJSONFileInputButtonRef.current?.click()}
            />
          </div>
          <input
            type="file"
            accept=".json"
            ref={selectJSONFileInputButtonRef}
            onChange={onUpload}
            required={false}
            hidden
            style={{ display: 'none' }}
          />
        </Tooltip>
        <Tooltip content="Save" direction="top">
          <JsonCustomizationSaveButton
            enabled={enableButtons}
            onTapSubmitButton={onTapSubmitButton}
          />
        </Tooltip>
      </Container>
    </div>
  );
};

export default JsonCustomizationHeader;
