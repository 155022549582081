import {
  CustomImageInput,
  CustomLocalizationInput,
  CustomUnitTypeCreateInput,
  Mutation,
} from '../../../__generated__/graphql';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { closeForm, openConfirmationModal } from '../../../redux/actions/modals.actions';

import { ApolloError } from '@apollo/client';
import FormContext from '../../../contexts/form.context';
import FormCrudAction from '../../../enums/FormCrudAction.enum';
import { ImageTypeEnum } from '../enums/ImageTypeEnum.enum';
import UNIT_TYPE_LOCALIZATION_COMPONENT_NAME from '../constants/unitTypeLocalizationComponentName';
import UnitTypeFormInputs from '../@types/UnitTypeFormInputs';
import UnitTypeLanguageLocalization from '../@types/UnitTypeLanguageLocalization';
import { flashApolloError } from '../../../util/errorUtils';
import { useContext } from 'react';
import useCreateUnitTypeAndUploadImage from '../../../hooks/useCreateUnitType';
import { useDispatch } from 'react-redux';
import useUpdateUnitTypeAndUploadImage from '../../../hooks/useUpdateUnitType';
import useUpsertOrDeleteUnitTypeLocalizations from '../../../hooks/useUpsertOrDeleteUnitTypeLocalizations';

interface UseSubmitUnitTypeInterface {
  originalLocalizations: UnitTypeLanguageLocalization[];
  crudAction: FormCrudAction;
}

type CreateUnitTypeData = Pick<Mutation, 'createUnitTypeAndUploadImageFile'>;
type UpdateUnitTypeData = Pick<Mutation, 'updateUnitTypeAndUploadImageFile'>;

const useSubmitUnitType = ({
  originalLocalizations,
  crudAction,
}: UseSubmitUnitTypeInterface): {
  loadingMutation: boolean;
  loadingUpsertUnitTypeLocalization: boolean;
  submitHandler: SubmitHandler<UnitTypeFormInputs>;
} => {
  const dispatch = useDispatch();

  // Form properties
  const { getValues, setValue, setError } = useFormContext();
  const { identifier } = useContext(FormContext);

  // Mutations - Unit Type Localizations
  const {
    mutation: mutationUpsertUnitTypeLocalization,
    loading: loadingUpsertUnitTypeLocalization,
  } = useUpsertOrDeleteUnitTypeLocalizations({
    onCompleted: (): void => {
      const actionExecuted = crudAction === FormCrudAction.CREATE ? 'created' : 'edited';
      window.flash({
        message: `Unit Type: ${getValues('type')} ${actionExecuted} successfully`,
      });

      dispatch(closeForm());
    },
  });

  // Submit
  const submitSetUnitTypeLocalization = (): void => {
    let newLocalizations =
      (getValues()?.languages?.values as Array<UnitTypeLanguageLocalization>) ?? [];

    if (crudAction === FormCrudAction.EDIT && originalLocalizations?.length > 0) {
      const deletedLocalizations = originalLocalizations.filter((_original) => {
        const languageId = _original.language.id;
        return !newLocalizations.map((_new) => _new.language.id).includes(languageId);
      });
      if (deletedLocalizations?.length > 0) {
        newLocalizations = [
          ...newLocalizations,
          ...deletedLocalizations.map(
            (_): UnitTypeLanguageLocalization => {
              return {
                language: {
                  id: _.language.id,
                  isRTL: _.language.isRTL,
                  name: _.language.name,
                },
              };
            }
          ),
        ];
      }
    }

    if (newLocalizations.length > 0) {
      const localizationsInputs = newLocalizations.map(
        (_localization): CustomLocalizationInput => {
          let localizationJson: string | undefined;
          if (!(!_localization.description && !_localization.name)) {
            localizationJson = JSON.stringify({
              description: _localization.description,
              name: _localization.name,
            });
          }
          return {
            languageId: _localization.language.id,
            localization: localizationJson,
          };
        }
      );

      mutationUpsertUnitTypeLocalization({
        componentName: UNIT_TYPE_LOCALIZATION_COMPONENT_NAME,
        unitTypeId: getValues('id'),
        unitTypeLocalizations: {
          localizationsInputs,
        },
      });
    } else {
      window.flash({
        message: `No localizations set for Unit Type`,
      });

      dispatch(closeForm());
    }
  };

  // Mutations - Create/Update Unit Type
  const onCompletedCreateUpdateUnitType = (data: CreateUnitTypeData | UpdateUnitTypeData): void => {
    const [unitTypeData] = Object.values(data);
    setValue('id', unitTypeData.id);

    submitSetUnitTypeLocalization();
  };

  const useMutation = identifier
    ? useUpdateUnitTypeAndUploadImage
    : useCreateUnitTypeAndUploadImage;

  const { mutation: mutationCrud, loading: loadingMutation } = useMutation({
    onCompleted: onCompletedCreateUpdateUnitType,
  });

  const validateUnitTypeLocalizationInputs = (): void => {
    // Validate Localizations
    const newLocalizations =
      (getValues()?.languages?.values as Array<UnitTypeLanguageLocalization>) ?? [];

    if (newLocalizations?.length === 0) {
      setError('language', {
        message: 'At least one localization is required',
      });
      window.flash({
        message: 'At least one localization is required',
        type: 'error',
      });
      throw new Error();
    }

    let hasError = false;
    newLocalizations
      .filter((_localization) => !_localization.name)
      .forEach((_undefinedLocalization) => {
        hasError = true;

        const undefinedLocalizationIndex = newLocalizations.findIndex(
          (_localization) => _localization.language.id === _undefinedLocalization.language.id
        );

        const invalidField = `languages.values[${undefinedLocalizationIndex}].name`;
        setError(invalidField, {
          message: 'Required field',
          types: {
            required: true,
          },
        });
      });

    if (hasError) {
      throw new Error();
    }
  };

  const submitCreateEditUnitType: SubmitHandler<UnitTypeFormInputs> = async (inputs) => {
    const { imageFile, imageUrl, ...inputData } = inputs;
    const { imageType } = inputData;

    try {
      delete inputData.imageType;
      delete inputData.languages;
      delete inputData.localizations;

      const imageInput: CustomImageInput = {
        imageFile: imageType === ImageTypeEnum.UPLOAD ? imageFile : undefined,
        imageUrl: imageType === ImageTypeEnum.URL ? imageUrl : undefined,
      };

      const unitTypeCreateInput: CustomUnitTypeCreateInput = {
        ...inputData,
        image: imageInput,
        location: {
          connect: {
            id: inputData.location.value,
          },
        },
      };

      dispatch(
        openConfirmationModal({
          action: (): void => {
            mutationCrud(unitTypeCreateInput, identifier);
          },
        })
      );
    } catch (error) {
      flashApolloError(error as ApolloError);
      throw error;
    }
  };

  const submitHandler: SubmitHandler<UnitTypeFormInputs> = (inputs): void => {
    try {
      validateUnitTypeLocalizationInputs();
      submitCreateEditUnitType(inputs);
      // eslint-disable-next-line no-empty
    } catch {}
  };

  return {
    loadingMutation,
    loadingUpsertUnitTypeLocalization,
    submitHandler,
  };
};

export default useSubmitUnitType;
