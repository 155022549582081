import 'styled-components/macro';

import Banner from '../../components/Banner';
import BaseLayout from '../BaseLayout';
import Container from '../../components/Container';
import DataTable from '../../components/DataTable';
import DataTableProps from '../../components/DataTable/@types/DataTableProps';
import ListPageBannerStyle from './styles/ListPageBanner.style';
import React from 'react';

interface DataTablePageProps extends DataTableProps {
  bannerText: string;
}

function DataTablePage({
  bannerText,
  dataType = 'Unknown',
  formName = '',
  permission,
  ...props
}: DataTablePageProps): React.ReactElement {
  return (
    <BaseLayout>
      <Container>
        <Banner css={ListPageBannerStyle} subText="Virdee" text={bannerText} />
        <DataTable dataType={dataType} formName={formName} permission={permission} {...props} />
      </Container>
    </BaseLayout>
  );
}

export default React.memo(DataTablePage);
