import React, { memo, useEffect, useState } from 'react';
import Video, { Participant as VideoParticipant } from 'twilio-video';
import { useDispatch, useSelector } from 'react-redux';

import { CallType } from '../../../../__generated__/graphql';
import Participant from '../Participant';
import { RootState } from '../../../../redux/reducers';
import { setActiveRoom } from '../../../../redux/actions/remoteAssistance.actions';
import useEndCall from '../../../../hooks/useEndCall';

interface RoomProps {
  callRecordId: string;
  roomName: string | undefined | null;
  token: string;
  type: CallType;
  isExpanded: boolean;
}

const Room = ({
  callRecordId,
  roomName,
  token,
  type,
  isExpanded,
}: RoomProps): React.ReactElement => {
  const dispatch = useDispatch();
  const me = useSelector((state: RootState) => state.me);

  const [activeRoom, setLocalActiveRoom] = useState<Video.Room | null>(null);

  const [participants, setParticipants] = useState<VideoParticipant[]>([]);

  const { endCall } = useEndCall();

  useEffect(() => {
    const participantConnected = (participant: VideoParticipant): void => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };

    const participantDisconnected = (participant: VideoParticipant): void => {
      setParticipants((prevParticipants) => prevParticipants.filter((p) => p !== participant));

      endCall({ callRecordId, userId: me.id! });
    };

    if (!activeRoom) {
      Video.connect(token, {
        audio: true,
        name: roomName,
        video: type === 'VIDEO',
      })
        .then((room: Video.Room) => {
          setLocalActiveRoom(room);
          dispatch(setActiveRoom(room));

          room.on('participantConnected', participantConnected);
          room.on('participantDisconnected', participantDisconnected);
          room.participants.forEach(participantConnected);
        })
        .catch(() => {});
    }
  }, [activeRoom, endCall, roomName, token]);

  const remoteParticipants = participants.map((participant: VideoParticipant) => (
    <Participant
      key={participant.sid}
      participant={participant}
      isExpanded={isExpanded}
      participantType="REMOTE"
    />
  ));

  if (!remoteParticipants.length || !activeRoom?.localParticipant) {
    return <div>Loading</div>;
  }

  return (
    <div className="room">
      <div className="local-participant">
        {activeRoom && (
          <Participant
            key={activeRoom.localParticipant.sid}
            participant={activeRoom.localParticipant}
            isExpanded={isExpanded}
            participantType="LOCAL"
          />
        )}
      </div>
      <div className="remote-participants">{remoteParticipants}</div>
    </div>
  );
};

export default memo(Room);
