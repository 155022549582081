import { gql } from '../__generated__/gql';

const UPDATE_KIOSK = gql(`
  mutation UpdateKiosk($data: CustomKioskUpdateInput!, $where: KioskWhereUniqueInput!) {
    updateKiosk(data: $data, where: $where) {
      ...KioskTableFields
    }
  }
`);

export default UPDATE_KIOSK;
