import { gql } from '../__generated__/gql';

const GET_AMENITY_TABLE_DATA = gql(`
  query GetAmenityTableData(
    $orderBy: [AmenityOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: AmenityWhereInput
  ) {
    amenities(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
      ...AmenityTableFields
    }
  }
`);

export default GET_AMENITY_TABLE_DATA;
