import {
  QueryGetKioskThemeArgs,
  QueryGetLocationThemeArgs,
  QueryGetTenantThemeArgs,
} from '../../../__generated__/graphql';

import { CustomizeThemeLocalizationEntityLevel } from '../../../enums/CustomizeThemeLocalizationEntityLevel';
import { NotSupportedError } from '../constants/defaultError';

const retrieveThemeInputProperties = (
  componentName: string,
  entityLevel: CustomizeThemeLocalizationEntityLevel,
  entityId: string
): QueryGetTenantThemeArgs | QueryGetLocationThemeArgs | QueryGetKioskThemeArgs => {
  switch (entityLevel) {
    case CustomizeThemeLocalizationEntityLevel.TENANT: {
      return {
        componentName,
        tenantId: entityId,
      };
    }

    case CustomizeThemeLocalizationEntityLevel.LOCATION: {
      return {
        componentName,
        locationId: entityId,
      };
    }

    case CustomizeThemeLocalizationEntityLevel.KIOSK: {
      return {
        componentName,
        kioskId: entityId,
      };
    }

    default:
      throw NotSupportedError(entityLevel);
  }
};

export default retrieveThemeInputProperties;
