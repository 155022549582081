import { GET_KIOSK_THEME, GET_LOCATION_THEME, GET_TENANT_THEME } from '../../../graphql/queries';

import { CustomizeThemeLocalizationEntityLevel } from '../../../enums/CustomizeThemeLocalizationEntityLevel';
import { DocumentNode } from 'graphql';
import { NotSupportedError } from '../constants/defaultError';

const getLoadQuery = (entityLevel: CustomizeThemeLocalizationEntityLevel): DocumentNode => {
  switch (entityLevel) {
    case CustomizeThemeLocalizationEntityLevel.TENANT:
      return GET_TENANT_THEME;

    case CustomizeThemeLocalizationEntityLevel.LOCATION:
      return GET_LOCATION_THEME;

    case CustomizeThemeLocalizationEntityLevel.KIOSK:
      return GET_KIOSK_THEME;

    default:
      throw NotSupportedError(entityLevel);
  }
};

export default getLoadQuery;
