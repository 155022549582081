import { gql } from '../__generated__/gql';

const DELETE_PAYMENT = gql(`
  mutation DeleteOnePayment($where: PaymentWhereUniqueInput!) {
    deleteOnePayment(where: $where) {
      id
    }
  }
`);

export default DELETE_PAYMENT;
