/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationProfileSchema: NodeSchemaProperties = {
  profile: {
    title: '#9 Profile',
    type: 'object',
    properties: {
      'headerTitle:enable': {
        description: '[profile.headerTitle]',
        title: '#1 Header Title',
        type: 'boolean',
      },
      headerTitle: {
        type: 'string',
      },
      items: {
        title: '#2 Items',
        type: 'object',
        properties: {
          'profile:enable': {
            description: '[profile.items.profile]',
            title: '#2.1 Profile',
            type: 'boolean',
          },
          profile: {
            type: 'string',
          },
          'account:enable': {
            description: '[profile.items.account]',
            title: '#2.2 Account',
            type: 'boolean',
          },
          account: {
            type: 'string',
          },
          'reviewDetails:enable': {
            description: '[profile.items.reviewDetails]',
            title: '#2.3 Review Details',
            type: 'boolean',
          },
          reviewDetails: {
            type: 'string',
          },
          'pendingTasks:enable': {
            description: '[profile.items.pendingTasks]',
            title: '#2.4 Pending Tasks',
            type: 'boolean',
          },
          pendingTasks: {
            type: 'string',
          },
          'guests:enable': {
            description: '[profile.items.guests]',
            title: '#2.5 Guests',
            type: 'boolean',
          },
          guests: {
            type: 'string',
          },
          'payment:enable': {
            description: '[profile.items.payment]',
            title: '#2.6 Payment',
            type: 'boolean',
          },
          payment: {
            type: 'string',
          },
          'setLanguage:enable': {
            description: '[profile.items.setLanguage]',
            title: '#2.7 Set Language',
            type: 'boolean',
          },
          setLanguage: {
            type: 'string',
          },
          'logout:enable': {
            description: '[profile.items.logout]',
            title: '#2.8 Logout',
            type: 'boolean',
          },
          logout: {
            type: 'string',
          },
          'support:enable': {
            description: '[profile.items.support]',
            title: '#2.9 Support',
            type: 'boolean',
          },
          support: {
            type: 'string',
          },
          'remoteAssistance:enable': {
            description: '[profile.items.remoteAssistance]',
            title: '#2.10 Remote Assistance',
            type: 'boolean',
          },
          remoteAssistance: {
            type: 'string',
          },
          'rateOurApp:enable': {
            description: '[profile.items.rateOurApp]',
            title: '#2.11 Rate Our App',
            type: 'boolean',
          },
          rateOurApp: {
            type: 'string',
          },
          'contactUs:enable': {
            description: '[profile.items.contactUs]',
            title: '#2.12 Contact Us',
            type: 'boolean',
          },
          contactUs: {
            type: 'string',
          },
          'privacyPolicy:enable': {
            description: '[profile.items.privacyPolicy]',
            title: '#2.13 Privacy Policy',
            type: 'boolean',
          },
          privacyPolicy: {
            type: 'string',
          },
          'termsOfService:enable': {
            description: '[profile.items.termsOfService]',
            title: '#2.14 Terms Of Service',
            type: 'boolean',
          },
          termsOfService: {
            type: 'string',
          },
        },
      },
      language: {
        title: '#3 Language',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[profile.language.title]',
            title: '#3.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'selectLanguage:enable': {
            description: '[profile.language.selectLanguage]',
            title: '#3.2 Select Language',
            type: 'boolean',
          },
          selectLanguage: {
            type: 'string',
          },
        },
      },
      pendingTasks: {
        title: '#4 Pending Tasks',
        type: 'object',
        properties: {
          'subtitle:enable': {
            description: '[profile.pendingTasks.subtitle]',
            title: '#4.1 Subtitle',
            type: 'boolean',
          },
          subtitle: {
            type: 'string',
          },
          'description:enable': {
            description: '[profile.pendingTasks.description]',
            title: '#4.2 Description',
            type: 'boolean',
          },
          description: {
            type: 'string',
          },
          'okText:enable': {
            description: '[profile.pendingTasks.okText]',
            title: '#4.3 Ok Text',
            type: 'boolean',
          },
          okText: {
            type: 'string',
          },
        },
      },
      logoutAlert: {
        title: '#5 Logout Alert',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[profile.logoutAlert.title]',
            title: '#5.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'text:enable': {
            description: '[profile.logoutAlert.text]',
            title: '#5.2 Text',
            type: 'boolean',
          },
          text: {
            type: 'string',
          },
          'cancel:enable': {
            description: '[profile.logoutAlert.cancel]',
            title: '#5.3 Cancel',
            type: 'boolean',
          },
          cancel: {
            type: 'string',
          },
        },
      },
    },
  },
};

export const mobileV3LocalizationProfileUiSchema: NodeUiSchemaProperties = {
  profile: {
    headerTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    items: {
      profile: {
        'ui:label': false,
        'ui:readonly': true,
      },
      account: {
        'ui:label': false,
        'ui:readonly': true,
      },
      reviewDetails: {
        'ui:label': false,
        'ui:readonly': true,
      },
      pendingTasks: {
        'ui:label': false,
        'ui:readonly': true,
      },
      guests: {
        'ui:label': false,
        'ui:readonly': true,
      },
      payment: {
        'ui:label': false,
        'ui:readonly': true,
      },
      setLanguage: {
        'ui:label': false,
        'ui:readonly': true,
      },
      logout: {
        'ui:label': false,
        'ui:readonly': true,
      },
      support: {
        'ui:label': false,
        'ui:readonly': true,
      },
      remoteAssistance: {
        'ui:label': false,
        'ui:readonly': true,
      },
      rateOurApp: {
        'ui:label': false,
        'ui:readonly': true,
      },
      contactUs: {
        'ui:label': false,
        'ui:readonly': true,
      },
      privacyPolicy: {
        'ui:label': false,
        'ui:readonly': true,
      },
      termsOfService: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    language: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      selectLanguage: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    pendingTasks: {
      subtitle: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
      okText: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    logoutAlert: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      text: {
        'ui:label': false,
        'ui:readonly': true,
      },
      cancel: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
  },
};
