const FlashContainerStyle = `
  align-content: center;
  left: calc(50vw - 200px);
  min-height: 50px;
  position: fixed;
  right: calc(50vw - 200px);
  top: 25px;
  width: 400px;
  z-index: 999;
`;

export default FlashContainerStyle;
