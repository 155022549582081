import { gql } from '../__generated__/gql';

const SET_LIMIT_KEYS_CONFIG = gql(`
  mutation setLimitKeysConfig(
    $accessGrantId: String
    $locationId: String
    $tenantId: String
    $limitKeysConfigLevel: String!
    $maxKeys: Float
  ) {
    setLimitKeysConfig(
      accessGrantId: $accessGrantId
      locationId: $locationId
      tenantId: $tenantId
      limitKeysConfigLevel: $limitKeysConfigLevel
      maxKeys: $maxKeys
    ) {
      success
    }
  }
`);

export default SET_LIMIT_KEYS_CONFIG;
