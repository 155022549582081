import { gql } from '../__generated__/gql';

const ACCESS_MEMBERSHIP = gql(`
  mutation AccessMembership($membershipId: String!) {
    accessMembership(membershipId: $membershipId) {
      lastSeenAt
    }
  }
`);

export default ACCESS_MEMBERSHIP;
