import { gql } from '../../__generated__/gql';

export const GET_LOCATION = gql(`
  query Location($where: LocationWhereUniqueInput!) {
    location(where: $where) {
      ...LocationTableFields
    }
  }
`);

export const GET_LOCATION_THEME = gql(`
  query getLocationTheme($componentName: String!, $locationId: String!) {
    getLocationTheme(componentName: $componentName, locationId: $locationId) {
      ...BaseSettingAnswerFields
    }
  }
`);

export const GET_LOCATION_LOCALIZATION = gql(`
  query getLocationLocalization(
    $componentName: String!
    $languageId: String!
    $locationId: String!
  ) {
    getLocationLocalization(
      componentName: $componentName
      languageId: $languageId
      locationId: $locationId
    ) {
      ...BaseSettingAnswerFields
    }
  }
`);
