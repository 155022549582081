import { DeepMap, FieldError } from 'react-hook-form';
import AccessGrantFormInputs from '../@types/AccessGrantFormInputs';
import GRANT_FORM_FIELDS from '../components/GrantFormSection/constants/formFields';
import get from 'lodash/get';

export default function canAccessRequirementsSection(
  inputs: Partial<AccessGrantFormInputs>,
  errors: DeepMap<AccessGrantFormInputs, FieldError>
): boolean {
  const isFilled = GRANT_FORM_FIELDS.every((field) => get(inputs, field ?? ''));
  const strippedFieldNames = GRANT_FORM_FIELDS.map((field) => field.split('.')[0]);

  const hasNoErrors = strippedFieldNames.every(
    (field) => !errors[field as keyof AccessGrantFormInputs]
  );

  return isFilled && hasNoErrors;
}
