import 'styled-components/macro';
import isValidColorString from '../../util/css/isValidColorString';
import isValidPixelString from '../../util/css';
import styled from 'styled-components';

export interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  alignContent?: 'start' | 'end' | 'center' | 'baseline';
  background?: string;
  borderColor?: string;
  columns?: string;
  flow?: 'column' | 'row';
  justify?: 'left' | 'right' | 'center';
  margin?: string;
  rowGap?: string;
  width?: string;
  columnTemplate?: string;
  columnGap?: string;
}

const Container = styled.div<ContainerProps>`
  align-content: ${({ alignContent }): string => alignContent || 'initial'};
  align-items: ${({ alignContent }): string => alignContent || 'initial'};
  background: ${({ background }): string => {
    if (background && isValidColorString(background)) {
      return background;
    }

    return 'transparent';
  }};
  border-color: ${({ borderColor }): string => {
    if (borderColor && isValidColorString(borderColor)) {
      return borderColor;
    }

    return 'auto';
  }};
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
  display: grid;
  justify-content: ${({ justify }): string => justify || 'initial'};
  margin: ${({ margin }): string => margin || '0 0 0 0'};
  grid-auto-columns: ${({ columns }): string => columns ?? 'initial'};
  grid-auto-rows: min-content;
  grid-auto-flow: ${({ flow }): string => flow ?? 'initial'};
  grid-row-gap: ${({ rowGap }): string => {
    if (rowGap && isValidPixelString(rowGap)) {
      return rowGap;
    }

    return '0';
  }};
  width: ${({ width }): string => {
    if (width && isValidPixelString(width)) {
      return width;
    }

    return 'auto';
  }};
  grid-template-columns: ${({ columnTemplate }): string => columnTemplate ?? 'initial'};
  grid-column-gap: ${({ columnGap }): string => {
    if (columnGap && isValidPixelString(columnGap)) {
      return columnGap;
    }

    return '0';
  }};
`;

Container.displayName = 'Container';

export default Container;
