import { gql } from '../__generated__/gql';

const GET_LIMIT_KEYS_CONFIG = gql(`
  query getLimitKeysConfig(
    $accessGrantId: String
    $locationId: String
    $tenantId: String
    $limitKeysConfigLevel: String!
  ) {
    getLimitKeysConfig(
      accessGrantId: $accessGrantId
      locationId: $locationId
      tenantId: $tenantId
      limitKeysConfigLevel: $limitKeysConfigLevel
    ) {
      maxKeys
      numKeysUsed
    }
  }
`);

export default GET_LIMIT_KEYS_CONFIG;
