import React, { useContext } from 'react';
import { Tooltip, TooltipWithBounds, defaultStyles } from '@visx/tooltip';

import Container from '../../Container';
import LineGraphPositionContext from '../contexts/LineGraphPositionContext';
import SingleDayAggregate from '../../../@types/client/SingleDayAggregate';
import formatDate from '../util/formatDate';
import { tooltipBackground } from '../constants/colors';

interface LGTooltipProps {
  data?: SingleDayAggregate;
}

export default function LGTooltip({ data }: LGTooltipProps): React.ReactElement {
  const { innerHeight, margin, tooltipLeft, tooltipTop } = useContext(LineGraphPositionContext);

  const tooltipStyles = {
    ...defaultStyles,
    background: tooltipBackground,
    border: '1px solid white',
    color: 'white',
  };

  if (data) {
    return (
      <Container>
        <TooltipWithBounds
          key={Math.random()}
          top={tooltipTop - 12}
          left={tooltipLeft + 12}
          style={tooltipStyles}
        >
          {`${data.count} Arrivals`}
        </TooltipWithBounds>
        <Tooltip
          top={innerHeight + margin.top - 14}
          left={tooltipLeft}
          style={{
            ...defaultStyles,
            minWidth: 72,
            textAlign: 'center',
            transform: 'translateX(-50%)',
          }}
        >
          {formatDate(data.date)}
        </Tooltip>
      </Container>
    );
  }

  return <></>;
}
