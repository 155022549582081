import { AccessGrantState } from '../../../../../__generated__/graphql';
import Filter from '../../../../../components/DataTable/@types/Filter';

export default function addStateFilters(filters: Filter[]): void {
  Object.keys(AccessGrantState).forEach((state) => {
    filters.push({
      displayKey: 'State',
      displayValue: AccessGrantState[state as keyof typeof AccessGrantState],
      key: 'state',
      value: AccessGrantState[state as keyof typeof AccessGrantState],
    });
  });
}
