import { DisplayHeaderMap } from '../@types/DisplayHeader';
import { Header } from '../@types/DataTableProps';

export default function mapHeadersToDisplayHeaders(headers: Header[]): DisplayHeaderMap {
  const displayHeaders: DisplayHeaderMap = {};

  headers.forEach((header) => {
    displayHeaders[header.displayValue] = {
      ...header,
      sortOrder: null,
    };
  });

  return displayHeaders;
}
