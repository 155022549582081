import DISCONNECT_CLOUDBEDS_APP from '../../graphql/disconnectCloudbedsApp';
import DisconnectCloudbedsAppData from './@types/DisconnectCloudbedsAppData';
import DisconnectCloudbedsAppInputs from './@types/DisconnectCloudbedsAppInputs';
import MutationHookData from '../@types/MutationHookData';
import MutationHookParams from '../@types/MutationHookParams';
import { closeForm } from '../../redux/actions/modals.actions';
import { flashApolloError } from '../../util/errorUtils';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';

export default function useDisconnectCloudbedsApp({
  onCompleted = (): void => {},
  onError = flashApolloError,
}: MutationHookParams<DisconnectCloudbedsAppData> = {}): MutationHookData<
  DisconnectCloudbedsAppInputs,
  DisconnectCloudbedsAppData
> {
  const dispatch = useDispatch();

  const [create, { data, error, loading }] = useMutation<
    DisconnectCloudbedsAppData,
    DisconnectCloudbedsAppInputs
  >(DISCONNECT_CLOUDBEDS_APP, {
    onCompleted: ({ cloudbedsDisconnectApp }) => {
      if (cloudbedsDisconnectApp?.success) {
        window.flash({
          message: `Cloudbeds App is successfully disconnected.`,
        });

        onCompleted({ cloudbedsDisconnectApp });

        dispatch(closeForm());
      } else {
        window.flash({
          message: `Fail to disconnect Cloudbeds App. Please try again.`,
        });
      }
    },
    onError,
  });

  return {
    data,
    error,
    loading,
    mutation: (inputs: DisconnectCloudbedsAppInputs): void => {
      create({
        variables: {
          ...inputs,
        },
      });
    },
  };
}
