import { Query, QueryUserRecordsArgs, UserRecordWhereInput } from '../../__generated__/graphql';
import { useCallback, useEffect, useRef, useState } from 'react';

import { EntityEnum } from '../../@types/client/DashboardUserRolePermissions';
import { GET_USER_RECORDS_TABLE_DATA } from '../../graphql/getUserRecordsTableData';
import GET_USER_RECORD_COUNT from '../../graphql/aggregateUserRecord';
import OrderByParams from '../../@types/client/OrderByParams';
import { RootState } from '../../redux/reducers';
import SearchField from '../../@types/SearchField';
import TableHookData from '../@types/TableHookData';
import TableHookProps from '../@types/TableHookProps';
import USER_RECORDS_TABLE_HEADERS from './constants/UserRecordsTableHeaders';
import USER_RECORDS_TABLE_SEARCH_FIELDS from './constants/UserRecordsTableSearchFields';
import generateNestedMembershipQuery from '../../util/api/generateNestedMembershipQuery';
import generateUserRecordsTableData from './functions/generateUserRecordsTableData';
import parseOrderByDataTableParams from '../../components/DataTable/util/parseOrderByDataTableParams';
import useGetMany from '../useGetMany';
import { useSelector } from 'react-redux';

type UserRecordsQueryParams = {
  orderBy?: OrderByParams;
  where: Partial<UserRecordWhereInput>;
};

type GetUserRecordsData = Pick<Query, 'userRecords'>;
type AggregateUserRecordsQueryData = Pick<Query, 'aggregateUserRecord'>;

export default function useUserRecordsTableData({
  dataTableKey = 'userRecords',
  formName = 'userRecord',
  headers = USER_RECORDS_TABLE_HEADERS,
  isSearchable = true,
  queryOptions,
  searchFields = USER_RECORDS_TABLE_SEARCH_FIELDS,
}: TableHookProps<QueryUserRecordsArgs, UserRecordWhereInput>): TableHookData<
  UserRecordWhereInput
> {
  const membership = useSelector((state: RootState) => state.me.activeMembership);
  const options = useRef<Partial<QueryUserRecordsArgs>>(queryOptions ?? {});
  const sortOptions = useSelector((state: RootState) => state.dataTable.sortOptions);

  const [searchParameters, setSearchParameters] = useState<UserRecordWhereInput>({});

  const { data, error, loading, offset, query, setOffset } = useGetMany<
    GetUserRecordsData,
    QueryUserRecordsArgs
  >({
    graphqlQuery: GET_USER_RECORDS_TABLE_DATA,
  });

  const {
    data: countData,
    error: countError,
    loading: countLoading,
    query: countQuery,
  } = useGetMany<AggregateUserRecordsQueryData, QueryUserRecordsArgs>({
    graphqlQuery: GET_USER_RECORD_COUNT,
  });

  const createParams = useCallback((): UserRecordsQueryParams => {
    const sortParams = parseOrderByDataTableParams(sortOptions[dataTableKey]);

    return {
      ...(sortParams && { orderBy: sortParams }),
      where: {
        user: { is: generateNestedMembershipQuery(membership) },
        ...options.current?.where,
        ...searchParameters,
      },
    };
  }, [membership, options, searchParameters, sortOptions, dataTableKey]);

  const get = useCallback(() => {
    const params = createParams();

    query({
      ...options.current,
      ...(params.orderBy && { orderBy: params.orderBy }),
      skip: options.current?.skip ?? offset,
      take: options.current?.take ?? 10,
      where: params.where,
    });
  }, [createParams, offset, query]);

  const getCount = useCallback(() => {
    const params = createParams();

    countQuery({
      where: params.where,
    });
  }, [countQuery, createParams]);

  useEffect(() => {
    get();
  }, [get]);

  useEffect(() => {
    getCount();
  }, [getCount]);

  return {
    count: countData?.aggregateUserRecord?._count?._all ?? 0,
    data: generateUserRecordsTableData(data),
    dataTableKey,
    dataType: 'UserRecords',
    error: error || countError,
    formName,
    headers,
    isLoading: loading || countLoading,
    isSearchable,
    offset,
    permission: EntityEnum.NONE,
    search: (searchField: SearchField<UserRecordWhereInput>, searchValue: string): void => {
      setSearchParameters({
        [searchField.queryField]: searchField.transform(searchValue),
      });

      setOffset(0);
    },
    searchFields,
    setOffset,
  };
}
