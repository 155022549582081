import Modal from 'react-modal';
import { RGB } from '../../../theme/colors';

const ConfirmationModalStyle: Modal.Styles = {
  content: {
    alignSelf: 'center',
    border: 0,
    bottom: 'unset',
    boxShadow: `0px 10px 32px 0px rgba(${RGB.neroDark}, 0.21)`,
    gridColumn: '3 / -3',
    justifySelf: 'center',
    left: 'unset',
    padding: '30px 40px',
    right: 'unset',
    top: '250px',
    width: '450px',
    zIndex: 999,
  },
  overlay: {
    background: 'transparent',
    display: 'grid',
    gridColumnGap: '25px',
    gridTemplateColumns: 'repeat(12, 1fr)',
    zIndex: 999,
  },
};

export default ConfirmationModalStyle;
