import { ImageTypeEnum } from '../../UnitTypeForm/enums/ImageTypeEnum.enum';
import { InfoType } from '../../../__generated__/graphql';
import InputGroupProps from '../../InputGroup/@types/InputGroupProps';
import { MAX_IMAGE_SIZE } from '../../../util/fileUtil';
import useLoadInfoTypeOptions from '../../../hooks/useLoadInfoTypeOptions';
import useLoadLocationOptions from '../../../hooks/useLoadLocationOptions';
import useSearchLocationsByName from '../../../hooks/useSearchLocations';
import { values } from 'lodash';

interface InfoItemImageFormInputProps {
  imageType: string;
}

export default function generateInfoItemSectionInputs({
  imageType,
}: InfoItemImageFormInputProps): InputGroupProps[] {
  let infoitemInputs: InputGroupProps[] = [
    {
      label: 'Title',
      name: 'title',
      required: true,
      type: 'text',
    },
    {
      label: 'Description',
      name: 'description',
      required: true,
      type: 'textarea',
    },
    {
      label: 'Search Location',
      loadOptions: useLoadLocationOptions,
      name: 'location',
      query: useSearchLocationsByName,
      required: true,
      type: 'async-select',
    },
    {
      label: 'Image type',
      name: 'imageType',
      options: Object.values(ImageTypeEnum),
      type: 'select',
    },
  ];

  if (imageType === ImageTypeEnum.URL) {
    infoitemInputs.push({
      label: 'Image URL',
      name: 'imageUrl',
      required: true,
      type: 'url',
    });
  } else if (imageType === ImageTypeEnum.UPLOAD) {
    infoitemInputs.push({
      accept: 'image/*',
      label: 'Image file',
      maxFileSize: MAX_IMAGE_SIZE,
      name: `imageFile`,
      required: true,
      type: 'file',
    });
  }

  infoitemInputs = [
    ...infoitemInputs,
    {
      label: 'Type',
      name: 'type',
      options: values(InfoType),
      required: true,
      type: 'select',
    },
    {
      label: 'Category',
      loadOptions: useLoadInfoTypeOptions,
      name: 'infoType',
      required: false,
      type: 'async-select',
    },
    {
      label: 'More info link',
      name: 'moreInfoLink',
      type: 'url',
    },
  ];

  return infoitemInputs;
}
