import 'styled-components/macro';

import Checkbox, { CheckboxModel } from '../Checkbox';
import { MdAddCircle, MdDelete, MdFileUpload, MdModeEdit } from 'react-icons/md';
import React, { useEffect, useState } from 'react';
import TableHeading, { StyledTableHeading } from './components/TableHeading';
import { clearSortOptions, setSortOptions } from '../../redux/actions/dataTable.actions';
import { openForm, openRemoveModal } from '../../redux/actions/modals.actions';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../Button';
import ButtonModel from '../Button/enums/ButtonModel.enum';
import { CSSProperties } from 'styled-components/macro';
import Container from '../Container';
import DataTableProps from './@types/DataTableProps';
import DataTableStyle from './styles/DataTable.style';
import { DisplayHeaderMap } from './@types/DisplayHeader';
import ErrorTextStyle from './styles/ErrorText.style';
import Filters from './components/Filters';
import { Link } from 'react-router-dom';
import { LoadingSpinner } from '../LoadingSpinner';
import NoResultsTextStyle from './styles/NoResultsText.style';
import PageSelector from './components/PageSelector';
import RemoveHookData from '../../hooks/@types/RemoveHookData';
import { RootState } from '../../redux/reducers';
import SearchInput from './components/SearchInput';
import Table from './components/Table';
import TableActionsContainerStyle from './styles/TableActionsContainer.style';
import TableBody from './components/TableBody';
import TableCRUDActionsContainerStyle from './styles/TableCRUDActionsContainer.style';
import TableData from './components/TableData';
import TableDataContext from './contexts/TableDataContext';
import TableHead from './components/TableHead';
import TableRow from './components/TableRow';
import TextContent from '../TextContent';
import Tooltip from '../Tooltip';
import generateSortSearchQueryString from './util/generateSortSearchQueryString';
import mapHeadersToDisplayHeaders from './util/mapHeadersToDisplayHeaders';
import useGetPermission from '../../hooks/useGetPermission';
import values from 'lodash/values';

interface Field {
  button?: React.ReactElement;
  href?: string;
  link?: string;
  key?: string;
  value: React.ReactElement | string | number;
  style?: CSSProperties;
}

export interface Datum {
  dataType: string;
  key: string;
  fields: Field[];
}

const DataTable: React.FunctionComponent<DataTableProps> = ({
  aditionalDataOnOpeningForm,
  className,
  count,
  dataTableTexts,
  data,
  dataTableKey,
  dataType = 'Unknown',
  error,
  filters,
  formName = '',
  headers,
  isLoading,
  isSearchEnabled = true,
  isImportEnabled = false,
  offset = 0,
  permission,
  remove = (): RemoveHookData => ({
    error: undefined,
    loading: false,
    mutation: (): void => {},
  }),
  search = (): void => {},
  searchFields = [],
  setOffset = (): void => {},
}: DataTableProps) => {
  const dispatch = useDispatch();

  const [displayHeaders, setDisplayHeaders] = useState<DisplayHeaderMap>(
    mapHeadersToDisplayHeaders(headers)
  );

  const [selectedRow, selectRow] = useState<string>('');
  const selection = data.find((datum) => datum.key === selectedRow);

  const { getPermission } = useGetPermission();
  const activePermissions = getPermission(permission);

  const sortOptions = useSelector((state: RootState) => state.dataTable.sortOptions);

  function handleCheckboxClick(e: React.SyntheticEvent<HTMLDivElement>): void {
    const identifier = e.currentTarget.getAttribute('data-identifier');

    if (selectedRow === identifier) {
      selectRow('');
    } else if (identifier) {
      selectRow(identifier);
    }
  }

  function openEditModal(): void {
    dispatch(openForm(formName, selectedRow, aditionalDataOnOpeningForm));
  }

  function openCreateModal(): void {
    dispatch(openForm(formName, undefined, aditionalDataOnOpeningForm));
  }

  function openImportModal(): void {
    const importCSVFormName = `${formName}-import`;
    dispatch(openForm(importCSVFormName, undefined, aditionalDataOnOpeningForm));
  }

  function openRemove(): void {
    selectRow('');

    dispatch(
      openRemoveModal({
        dataType,
        hook: remove,
        id: selectedRow,
        verbiage: dataTableTexts?.removeFormVerbiage ?? 'remove',
      })
    );
  }

  useEffect(() => {
    /* reset sort orders when url no longer contains sort parameter (ex. reset) */
    if (dataTableKey) {
      const sortParams = sortOptions[dataTableKey];

      if (!sortParams) {
        setDisplayHeaders(mapHeadersToDisplayHeaders(headers));
      }
    }
  }, [headers, setDisplayHeaders, sortOptions, dataTableKey]);

  useEffect(() => {
    if (dataTableKey) {
      dispatch(setSortOptions(dataTableKey, generateSortSearchQueryString(displayHeaders)));
    }
  }, [displayHeaders, dispatch, dataTableKey]);

  useEffect(() => {
    return (): void => {
      if (dataTableKey) {
        dispatch(clearSortOptions(dataTableKey));
      }
    };
  }, [dispatch, dataTableKey]);

  const noResults: boolean = !data.length && !isLoading && !error;

  return (
    <>
      <Container className={className} css={DataTableStyle}>
        <Container>
          <Container css={TableActionsContainerStyle}>
            {isSearchEnabled && <SearchInput search={search} searchFields={searchFields} />}
            <Container css={TableCRUDActionsContainerStyle}>
              {activePermissions.CREATE && (
                <Tooltip content={dataTableTexts?.createText ?? 'Create'} direction="top">
                  <Button
                    disabled={Boolean(selectedRow)}
                    model={ButtonModel.PASSIVE_ICON_BUTTON}
                    onClick={openCreateModal}
                  >
                    <MdAddCircle />
                  </Button>
                </Tooltip>
              )}
              {activePermissions.IMPORT && (
                <Tooltip content={dataTableTexts?.importText ?? 'Import'} direction="top">
                  <Button
                    disabled={Boolean(selectedRow) || !isImportEnabled}
                    model={ButtonModel.PASSIVE_ICON_BUTTON}
                    onClick={openImportModal}
                  >
                    <MdFileUpload />
                  </Button>
                </Tooltip>
              )}
              {activePermissions.UPDATE && (
                <Tooltip content={dataTableTexts?.editText ?? 'Edit'} direction="top">
                  <Button
                    disabled={!selection}
                    model={ButtonModel.PASSIVE_ICON_BUTTON}
                    onClick={openEditModal}
                  >
                    <MdModeEdit />
                  </Button>
                </Tooltip>
              )}
              {activePermissions.DELETE && (
                <Tooltip content={dataTableTexts?.deleteText ?? 'Delete'} direction="top">
                  <Button
                    disabled={!selection || !remove}
                    model={ButtonModel.PASSIVE_ICON_BUTTON}
                    onClick={openRemove}
                  >
                    <MdDelete />
                  </Button>
                </Tooltip>
              )}
            </Container>
          </Container>
          <Container>
            <Filters dataTableKey={dataTableKey} filters={filters} />
          </Container>
        </Container>
        <Table columns={headers.length}>
          <TableDataContext.Provider
            value={{
              displayHeaders,
              setDisplayHeaders,
            }}
          >
            <TableHead>
              <TableRow>
                <StyledTableHeading>
                  <Checkbox checked model={CheckboxModel.TABLE_DARK} />
                </StyledTableHeading>
                {values(displayHeaders).map(
                  (header): React.ReactElement => (
                    <TableHeading
                      header={header}
                      key={`table-header-${header.displayValue}`}
                      setDisplayHeaders={setDisplayHeaders}
                      noResults={noResults}
                    />
                  )
                )}
              </TableRow>
            </TableHead>
          </TableDataContext.Provider>
          <TableBody>
            {!isLoading &&
              data.map((datum: Datum) => (
                <TableRow key={`table-datum-${datum.key}`}>
                  <TableData>
                    <Checkbox
                      checked={datum.key === selectedRow}
                      data-identifier={datum.key}
                      model={CheckboxModel.TABLE_DARK}
                      onClick={handleCheckboxClick}
                    />
                  </TableData>
                  {datum.fields.map(
                    (field: Field, index: number): React.ReactElement => {
                      if (field.link) {
                        return (
                          <TableData
                            key={`table-data-${datum.key}-${field.value}-${index}`}
                            style={field.style}
                          >
                            <Link to={field.link}>{field.value}</Link>
                          </TableData>
                        );
                      }
                      if (field.href) {
                        return (
                          <TableData
                            key={`table-data-${datum.key}-${field.value}-${index}`}
                            style={field.style}
                          >
                            <a href={field.href} rel="noopener noreferrer" target="_blank">
                              {field.value}
                            </a>
                          </TableData>
                        );
                      }

                      return (
                        <TableData
                          key={`table-data-${datum.key}-${field.value}-${index}`}
                          style={field.style}
                        >
                          {field.value}
                        </TableData>
                      );
                    }
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {noResults && <TextContent css={NoResultsTextStyle}>No Results</TextContent>}
        {error && (
          <Container>
            {error.graphQLErrors.map((graphQLError) => (
              <TextContent css={NoResultsTextStyle} key={`error-table-${graphQLError.name}`}>
                {graphQLError.message}
              </TextContent>
            ))}
            {!error.graphQLErrors.length && (
              <TextContent css={ErrorTextStyle}>Network Error</TextContent>
            )}
          </Container>
        )}
        {isLoading && <LoadingSpinner />}
        {!isLoading && count > 10 && (
          <PageSelector count={count} handlePageClick={setOffset} offset={offset} />
        )}
      </Container>
    </>
  );
};

export default React.memo(DataTable);
