import DataTableReduxState from '../../@types/DataTableReduxState';
import { SetFilterPayload } from '../../../actions/@types/SetFilterAction';

export default function setFilterAction(
  currentState: DataTableReduxState,
  actionPayload: SetFilterPayload
): DataTableReduxState {
  const { valuesFiltered, dataTableKey: filterKey } = actionPayload;
  const newFilters = { ...currentState.filters };

  // Check if the filter is already setup
  if (!newFilters[filterKey]) {
    newFilters[filterKey] = [valuesFiltered];
  } else {
    const indexFieldKey = newFilters[filterKey].findIndex(
      (value) => value.fieldKey === valuesFiltered.fieldKey
    );
    // Check if there is some filter for that field
    if (indexFieldKey > -1) {
      if (valuesFiltered.values.length === 0) {
        newFilters[filterKey].splice(indexFieldKey, 1);
      } else {
        newFilters[filterKey][indexFieldKey] = valuesFiltered;
      }
    } else {
      newFilters[filterKey].push(valuesFiltered);
    }
  }

  return {
    ...currentState,
    filters: newFilters,
  };
}
