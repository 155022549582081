import { CredentialWhereInput } from '../../../__generated__/graphql';
import SearchField from '../../../@types/SearchField';
import createSearchField from '../../../util/tableData/createSearchField';
import transformWhereRelationFilterInput from '../../../util/tableData/transformWhereRelationFilterInput';

const CREDENTIALS_TABLE_SEARCH_FIELDS: SearchField<CredentialWhereInput>[] = [
  createSearchField<CredentialWhereInput, 'user'>({
    label: 'Guest',
    queryField: 'user',
    transform: transformWhereRelationFilterInput,
  }),
];

export default CREDENTIALS_TABLE_SEARCH_FIELDS;
