import { gql } from '../../../__generated__/gql';

const GET_ACCESS_GRANT_PAGE_RESOURCES = gql(`
  query GetAccessGrantPageResources($skip: Int, $take: Int, $where: ResourceWhereInput) {
    resources(skip: $skip, take: $take, where: $where) {
      code
      controlType
      defaultEnd
      defaultStart
      id
      name
      type
      vendor
    }
  }
`);

export default GET_ACCESS_GRANT_PAGE_RESOURCES;
