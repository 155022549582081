import { RGB } from '../../../theme/colors';

const SameDayToggleContainerStyle = `
  align-items: center;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  margin: 15px 0 0;

  label {
    color: rgba(${RGB.shuttleGrey}, 0.5);
    font-weight: bold;
  }
`;

export default SameDayToggleContainerStyle;
