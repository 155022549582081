import {
  DELETE_KIOSK_LOCALIZATION,
  DELETE_LOCATION_LOCALIZATION,
  DELETE_TENANT_LOCALIZATION,
} from '../../../graphql/mutations';

import { CustomizeThemeLocalizationEntityLevel } from '../../../enums/CustomizeThemeLocalizationEntityLevel';
import { DocumentNode } from 'graphql';
import { NotSupportedError } from '../../CustomizeTheme/constants/defaultError';

const getDeleteQuery = (entityLevel: CustomizeThemeLocalizationEntityLevel): DocumentNode => {
  switch (entityLevel) {
    case CustomizeThemeLocalizationEntityLevel.TENANT:
      return DELETE_TENANT_LOCALIZATION;

    case CustomizeThemeLocalizationEntityLevel.LOCATION:
      return DELETE_LOCATION_LOCALIZATION;

    case CustomizeThemeLocalizationEntityLevel.KIOSK:
      return DELETE_KIOSK_LOCALIZATION;

    default:
      throw NotSupportedError(entityLevel);
  }
};

export default getDeleteQuery;
