/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3ThemeSuccessSchema: NodeSchemaProperties = {
  successScreen: {
    title: '#17 Success Screen',
    type: 'object',
    properties: {
      'successIconUrl:enable': {
        description: '[successScreen.successIconUrl]',
        title: '#1 Success Icon Url',
        type: 'boolean',
      },
      successIconUrl: {
        type: 'string',
      },
      'successIconColor:enable': {
        description: '[successScreen.successIconColor]',
        title: '#2 Success icon color',
        type: 'boolean',
      },
      successIconColor: {
        type: 'string',
      },
      'closeIconUrl:enable': {
        description: '[successScreen.closeIconUrl]',
        title: '#3 Close Icon Url',
        type: 'boolean',
      },
      closeIconUrl: {
        type: 'string',
      },
      'hideQRCode:enable': {
        description: '[successScreen.hideQRCode]',
        title: '#4 Hide QR Code',
        type: 'boolean',
      },
      hideQRCode: {
        type: undefined,
        enum: [true, false],
      },
      'bottomStickyBackgroundColor:enable': {
        description: '[successScreen.bottomStickyBackgroundColor]',
        title: '#5 Bottom Sticky Background Color',
        type: 'boolean',
      },
      bottomStickyBackgroundColor: {
        type: 'string',
      },
      'titleColor:enable': {
        description: '[successScreen.titleColor]',
        title: '#6 Title Color',
        type: 'boolean',
      },
      titleColor: {
        type: 'string',
      },
    },
  },
};

export const mobileV3ThemeSuccessUiSchema: NodeUiSchemaProperties = {
  successScreen: {
    successIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    successIconColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    closeIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    hideQRCode: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    bottomStickyBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    titleColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
  },
};
