import {
  LocationWhereInput,
  Query,
  QueryLocationsArgs,
} from '../../../../../../../__generated__/graphql';

import Filter from '../../../../../../../components/DataTable/@types/Filter';
import GET_LOCATIONS from '../../../../../../../graphql/getLocations';
import { RootState } from '../../../../../../../redux/reducers';
import addLocationFilters from '../../../../../../../hooks/util/filters/addLocationFilters';
import { useEffect } from 'react';
import useGetMany from '../../../../../../../hooks/useGetMany';
import { useSelector } from 'react-redux';

type GetLocationsData = Pick<Query, 'locations'>;

export default function useKiosksLiveUsageFilters(): Filter[] {
  const filters: Filter[] = [];

  const activeMembership = useSelector((state: RootState) => state.me.activeMembership);

  const { called, data, loading, query } = useGetMany<GetLocationsData, QueryLocationsArgs>({
    graphqlQuery: GET_LOCATIONS,
  });

  useEffect(() => {
    if (!called) {
      const params: LocationWhereInput = {
        tenantId: {
          equals: activeMembership.tenantId,
        },
      };

      query({
        where: params,
      });
    }
  }, [called, query]);

  if (!activeMembership.location?.id) {
    addLocationFilters(data?.locations || [], filters);
  }

  if (loading) {
    return [];
  }

  return filters;
}
