import { gql } from '../__generated__/gql';

const GET_CALL_RECORDS = gql(`
  query callRecords(
    $distinct: [CallRecordScalarFieldEnum!]
    $orderBy: [CallRecordOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: CallRecordWhereInput
  ) {
    callRecords(distinct: $distinct, orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
      accessGrant {
        id
        startsAt
        endsAt
        metadata
        user {
          name
          email
          phoneNumber
        }
        location {
          name
        }
      }
      createdAt
      duration
      kiosk {
        id
        name
      }
      id
      location {
        id
        name
        address
      }
      room
      status
      tenant {
        name
      }
      type
      caller {
        id
        email
        name
        phoneNumber
      }
      agent {
        id
        email
        name
        phoneNumber
      }
    }
  }
`);

export default GET_CALL_RECORDS;
