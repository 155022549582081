const FormHeaderStyle = `
  grid-auto-flow: column;
  grid-template-columns: 1fr max-content;

  button {
    grid-column: 2;
    position: fixed;
    right: 50px;
    top: 50px;
  }
`;

export default FormHeaderStyle;
