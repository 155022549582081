import Filter from '../../../../components/DataTable/@types/Filter';
import { Location } from '../../../../__generated__/graphql';

export const locationFilterKey = 'loc';

export default function addLocationFilters(
  locations: Pick<Location, 'id' | 'name'>[],
  filters: Filter[]
): Filter[] {
  const sortedLocations: Pick<Location, 'id' | 'name'>[] = [...locations];

  sortedLocations
    .sort((locationA, locationB) =>
      locationA.name.toLowerCase().localeCompare(locationB.name.toLowerCase())
    )
    .forEach((location) => {
      filters.push({
        displayKey: 'Location',
        displayValue: location.name,
        key: locationFilterKey,
        value: location.id,
      });
    });

  return filters;
}
