import {
  CustomImageInput,
  CustomServiceCreateInput,
  ServiceType,
} from '../../../__generated__/graphql';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { ApolloError } from '@apollo/client';
import FormCrudAction from '../../../enums/FormCrudAction.enum';
import { ImageTypeEnum } from '../../UnitTypeForm/enums/ImageTypeEnum.enum';
import { RootState } from '../../../redux/reducers';
import ServiceFormInputs from '../@types/ServiceFormInputs';
import { flashApolloError } from '../../../util/errorUtils';
import { openConfirmationModal } from '../../../redux/actions/modals.actions';
import useCreateServiceAndUploadImage from '../../../hooks/useCreateServiceAndUploadImage';
import useUpdateServiceAndUploadImage from '../../../hooks/useUpdateServiceAndUploadImage';

interface UseSubmitServiceInterface {
  loadingMutation: boolean;
  submitHandler: SubmitHandler<ServiceFormInputs>;
}

const useSubmitService = (): UseSubmitServiceInterface => {
  // Form Properties
  const dispatch = useDispatch();
  const id = useSelector((state: RootState) => state.modals.form.id);
  const crudAction = id ? FormCrudAction.EDIT : FormCrudAction.CREATE;
  const { setError } = useFormContext();

  // Mutations - Create/Update Service
  const useMutation =
    crudAction === FormCrudAction.CREATE
      ? useCreateServiceAndUploadImage
      : useUpdateServiceAndUploadImage;

  const { mutation, loading: loadingMutation } = useMutation();

  // Submit
  const submitHandler: SubmitHandler<ServiceFormInputs> = (inputs: ServiceFormInputs): void => {
    const { imageFile, imageUrl, ...mutationInputs } = inputs;
    let { metadata } = inputs;

    if (!metadata) {
      metadata = '{}';
    }
    try {
      metadata = JSON.parse(metadata);
      mutationInputs.metadata = metadata;
    } catch {
      setError('metadata', { message: 'Invalid metadata' });
      return;
    }

    // Image validations
    const { imageType } = mutationInputs;
    let imageErrorField;
    if (imageType === ImageTypeEnum.URL && (!imageUrl || imageUrl.length === 0)) {
      imageErrorField = 'imageUrl';
    } else if (imageType === ImageTypeEnum.UPLOAD && !imageFile) {
      imageErrorField = 'imageFile';
    }

    if (imageErrorField) {
      setError(imageErrorField, { message: 'Image is required.', types: { required: true } });
      return;
    }

    try {
      delete mutationInputs.imageType;

      const imageInput: CustomImageInput = {
        imageFile: imageType === ImageTypeEnum.UPLOAD ? imageFile : undefined,
        imageUrl: imageType === ImageTypeEnum.URL ? imageUrl : undefined,
      };

      const price = parseFloat(parseFloat(mutationInputs.price.toString()).toFixed(2));

      const serviceCreateInput: CustomServiceCreateInput = {
        ...mutationInputs,
        image: imageInput,
        location: {
          connect: {
            id: mutationInputs.location.value,
          },
        },
        price,
        type: mutationInputs.type.value as ServiceType,
      };

      dispatch(
        openConfirmationModal({
          action: (): void => {
            if (id) {
              mutation(serviceCreateInput, id);
            } else {
              mutation(serviceCreateInput);
            }
          },
        })
      );
    } catch (e) {
      flashApolloError(e as ApolloError);
    }
  };

  return {
    loadingMutation,
    submitHandler,
  };
};

export default useSubmitService;
