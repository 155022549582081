import 'styled-components/macro';

import { FormValidation, IChangeEvent } from '@rjsf/core';
import JsonFieldsEditor, { JsonFieldsEditorInterfaceProps } from './components/JsonFieldsEditor';
import React, { createRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import _, { merge } from 'lodash';

import Button from '../Button';
import ButtonModel from '../Button/enums/ButtonModel.enum';
import CustomizeJsonData from '../CustomizeJson/@types/CustomizeJsonData';
import { JSONSchema7 } from 'json-schema';
import { JsonEditorTabType } from './@types/JsonEditorTabType';
import JsonValidationError from './@types/JsonValidationError';
import TabButtonsStyle from './styles/TabButtons.style';
import fillCheckBoxesOnStarting from './functions/fillCheckboxesOnStarting';
import fillJsonWithEditedData from './functions/fillJsonWithEditedData';
import freezeObject from './functions/freezeObject';
import getNotMappedProperties from './functions/getNotMappedProperties';
import jsonGetObjectFromKey from './functions/jsonGetObjectFromKey';
import onChangeJsonField from './functions/onChangeJsonField';
import removeNullValues from './functions/removeNullValues';
import validateFieldsOnSubmit from './functions/validateFieldsOnSubmit';
import validateFormData from './functions/validateFormData';

export interface JsonEditorInterfaceProps {
  jsonEditorSchema: JSONSchema7;
  jsonEditorUiSchema: Record<string, unknown>;
  data: CustomizeJsonData;
  onSubmit: (data: Record<string, unknown>) => Promise<void>;
  saveButton?: JSX.Element;
}

type JsonFieldsEditorRef = unknown;

export type ReferenceJsonEditorInterface = {
  getData(): Record<string, unknown>;
  handleSubmit(): void;
  importJsonData(jsonData: Record<string, unknown>): void;
};

const JsonEditor = React.forwardRef<JsonFieldsEditorRef, JsonEditorInterfaceProps>(
  (
    {
      jsonEditorSchema,
      jsonEditorUiSchema,
      data: _json,
      onSubmit,
      saveButton,
    }: JsonEditorInterfaceProps,
    ref
  ) => {
    const submitFormRef = useRef<HTMLButtonElement>(null);

    // JsonData
    const previousJson = (_json.mergedSettingsData as unknown) as Record<string, unknown>;
    const [previousJsonCurrentLevel, setPreviousJsonCurrentLevel] = useState<
      Record<string, unknown>
    >((_json.settingsData as unknown) as Record<string, unknown>);
    const defaultJson = _.mergeWith(
      _.cloneDeep(previousJson),
      _.cloneDeep(previousJsonCurrentLevel),
      (objValue, srcValue) => {
        if (Array.isArray(objValue)) {
          return srcValue;
        }
        return undefined;
      }
    );
    const [jsonData, setJsonData] = useState<Record<string, unknown>>(_.cloneDeep(defaultJson));
    freezeObject(jsonData);

    const updateJsonData = (updatedJsonData: Record<string, unknown>): void => {
      const newJsonData = {
        ...updatedJsonData,
      };
      setJsonData(newJsonData);
    };

    // Schema
    const [schema, setSchema] = useState<Record<string, unknown>>(
      _.cloneDeep(jsonEditorSchema as Record<string, unknown>)
    );
    freezeObject(schema);

    // UiSchema
    const [uiSchema, setUiSchema] = useState<Record<string, unknown>>(jsonEditorUiSchema);
    const updateUiSchema = (updatedUiSchema: Record<string, unknown>): void => {
      const newUiSchema = {
        ...updatedUiSchema,
      };
      setUiSchema(newUiSchema);
    };
    freezeObject(uiSchema);

    // Reload data
    const [reloadData, setReloadData] = useState<boolean>(true);

    // Tabs
    const [tabs, setTabs] = useState<JsonEditorTabType>(
      Object.keys((schema as JSONSchema7).properties!).reduce((newTabs: JsonEditorTabType, key) => {
        // eslint-disable-next-line no-param-reassign
        newTabs[key] = null;

        return newTabs;
      }, {})
    );
    const [activeTab, setActiveTab] = useState<string>();
    const tabsReferences = useRef([]);
    tabsReferences.current = Object.keys(tabs).map(
      (__, iCount) => tabsReferences.current[iCount] ?? createRef<React.ReactElement>()
    );
    const [pendingSubmitOnInitializeTab, setPendingSubmitOnInitializeTab] = useState<boolean>(
      false
    );
    const referenceJsonEditor: ReferenceJsonEditorInterface = {
      getData(): Record<string, unknown> {
        const data = fillJsonWithEditedData(jsonData);

        return _.merge(defaultJson, data);
      },
      handleSubmit(): void {
        const setFirstActiveTab = (): void => {
          if (!activeTab && Object.entries(tabs)?.length > 0) {
            setPendingSubmitOnInitializeTab(true);
            setActiveTab(Object.keys(tabs)[0]);
          }
        };

        if (submitFormRef && submitFormRef.current) {
          submitFormRef.current?.click();
        } else {
          setFirstActiveTab();
        }
      },
      importJsonData(newJsonData: Record<string, unknown>): void {
        setReloadData(true);

        const notMappedProperties = getNotMappedProperties(previousJsonCurrentLevel, uiSchema);
        setPreviousJsonCurrentLevel(merge(notMappedProperties, newJsonData));

        setJsonData(newJsonData);

        window.flash({
          message: 'JSON imported successfully',
          type: 'sucess',
        });
      },
    };
    useImperativeHandle(ref, () => referenceJsonEditor);

    // JsonFieldsEditor functions
    const submitData = async (): Promise<void> => {
      try {
        validateFieldsOnSubmit(
          {
            jsonData,
            schema,
          },
          {
            tabs,
            tabsReferences,
          }
        );
      } catch (error) {
        if ((error as JsonValidationError).tabKey) {
          setActiveTab((error as JsonValidationError).tabKey);
          return;
        }
      }

      const dataToBeSubmited = fillJsonWithEditedData(jsonData, previousJsonCurrentLevel, uiSchema);

      await onSubmit(dataToBeSubmited);
      setActiveTab(undefined);
    };

    const getTabData = (tabKey: string, _schema?: JSONSchema7): React.ReactElement => {
      const tabSchema =
        _schema ??
        ((schema as JSONSchema7)?.properties![tabKey] as JSONSchema7) ??
        ({} as JSONSchema7);
      const tabData = jsonGetObjectFromKey(jsonData, tabKey);
      const tabUiSchema = jsonGetObjectFromKey(uiSchema, tabKey);
      const { title: tabTitle } = tabSchema;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const formOnChange = (key: string, event?: IChangeEvent<any> | undefined): void =>
        onChangeJsonField(
          {
            jsonData,
            previousJsonData: previousJson,
            schema,
            uiSchema,
          },
          {
            setSchema,
            updateJsonData,
            updateUiSchema,
          },
          key,
          event
        );

      const formValidateFormData = (
        nodeKey: string,
        formData: Record<string, unknown>,
        errors: FormValidation
      ): FormValidation => validateFormData(uiSchema, nodeKey, formData, errors);

      return (
        <JsonFieldsEditor
          title={tabTitle ?? tabKey}
          nodeKey={tabKey}
          key={tabKey}
          data={tabData}
          schema={tabSchema}
          uiSchema={tabUiSchema}
          onSubmit={submitData}
          onChange={formOnChange}
          validateFormData={formValidateFormData}
          formReference={tabsReferences.current[Object.keys(tabs).indexOf(tabKey)]}
        >
          <button key={tabKey} ref={submitFormRef} type="submit" style={{ display: 'none' }} />
          {saveButton}
        </JsonFieldsEditor>
      );
    };

    const initializeTabs = (): void => {
      // eslint-disable-next-line no-restricted-syntax
      for (const tabKey of Object.keys(tabs)) {
        tabs[tabKey] = getTabData(tabKey);
      }
      if (pendingSubmitOnInitializeTab) {
        setPendingSubmitOnInitializeTab(false);
        referenceJsonEditor.handleSubmit();
      }
    };

    useEffect(() => {
      setPreviousJsonCurrentLevel(_json.settingsData as Record<string, unknown>);
    }, [_json]);

    useEffect(() => {
      // Load new data
      if (reloadData) {
        const newJsonData = removeNullValues(jsonData);
        updateJsonData(newJsonData);

        fillCheckBoxesOnStarting(
          {
            jsonData: newJsonData,
            previousJsonCurrentLevel,
            schema,
            uiSchema,
          },
          updateJsonData,
          updateUiSchema
        );

        setReloadData(false);
      }
    }, [jsonData]);

    useEffect(() => {
      // Active tab has been changed
      initializeTabs();
    }, [activeTab]);

    useEffect(() => {
      // Schema/UiSchema has been changed
      //  It is necessary to update tabs
      const newTabs = _.cloneDeep(tabs);

      Object.keys(newTabs).forEach((tabKey) => {
        newTabs[tabKey] = getTabData(tabKey);
      });

      setTabs(newTabs);
    }, [schema, uiSchema]);

    useEffect(() => {
      // JSON input data has been changed and all data must be updated
      setReloadData(true);

      updateJsonData(defaultJson);
    }, [previousJson, previousJsonCurrentLevel]);

    const tabsComponent = Object.entries(tabs).map(
      (entry): JSX.Element => {
        const [key, value] = entry;
        const title = (value?.props as JsonFieldsEditorInterfaceProps)?.title ?? key;

        return (
          <Button
            active={key === activeTab}
            data-tab={key}
            key={key}
            model={ButtonModel.TAB}
            onClick={(): void => setActiveTab(key !== activeTab ? key : undefined)}
          >
            {title}
          </Button>
        );
      }
    );

    return (
      <div>
        <div css={TabButtonsStyle}>{tabsComponent}</div>
        {activeTab ? tabs[activeTab] : <div></div>}
      </div>
    );
  }
);
JsonEditor.displayName = 'JsonEditor';

export default JsonEditor;
