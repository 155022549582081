import 'styled-components/macro';

import { SubmitHandler, useForm } from 'react-hook-form';

import AuthenticationBaseContainer from '../../components/AuthenticationBaseComponent';
import AuthenticationFormActionTextStyle from '../../components/AuthenticationBaseComponent/styles/AuthenticationFormActionText.style';
import AuthenticationFormErrorTextStyle from '../../components/AuthenticationBaseComponent/styles/AuthenticationFormErrorText.style';
import AuthenticationFormInputStyle from '../../components/AuthenticationBaseComponent/styles/AuthenticationFormInput.style';
import AuthenticationFormSubmit1stButtonStyle from '../../components/AuthenticationBaseComponent/styles/AuthenticationFormSubmit1stButtonStyle.style';
import Container from '../../components/Container';
import FORGOT_PASSWORD_FIELDS_SCHEMA from './constants/ForgotPasswordFieldsSchema';
import ForgotPasswordFormInputs from './@types/ForgotPasswordFormInputs';
import { ISSUE_RESET_PASSWORD_BY_EMAIL_CHALLENGE } from '../../graphql/mutations/resetPassword';
import { Link } from 'react-router-dom';
import React from 'react';
import TextContent from '../../components/TextContent';
import TextInput from '../../components/TextInput';
import capitalize from 'lodash/capitalize';
import { flashApolloError } from '../../util/errorUtils';
import links from '../../util/links';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';

type IssueResetPasswordByEmailChallengeResult = boolean;

function ForgotPassword(): React.ReactElement {
  const { errors, handleSubmit, register, getValues } = useForm<ForgotPasswordFormInputs>({
    mode: 'onTouched',
    resolver: yupResolver(FORGOT_PASSWORD_FIELDS_SCHEMA),
  });

  const [issueResetPassword, { loading }] = useMutation(ISSUE_RESET_PASSWORD_BY_EMAIL_CHALLENGE, {
    onCompleted: (result: IssueResetPasswordByEmailChallengeResult) => {
      const email = getValues('email');
      if (result) {
        window.flash({
          message: `Recovery password email sent successfully to ${email}`,
        });
      } else {
        window.flash({
          message: 'Failure to send recovery password to email. Please try again.',
        });
      }
    },
    onError: flashApolloError,
  });

  const onSubmit: SubmitHandler<ForgotPasswordFormInputs> = ({ email }): void => {
    issueResetPassword({
      variables: { email },
    });
  };

  return (
    <AuthenticationBaseContainer
      title="Welcome to Virdee"
      subtitle="Enter your email address and we will send you a password reset link"
    >
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            className={`${errors.email && 'error'}`}
            css={AuthenticationFormInputStyle}
            name="email"
            placeholder="Email"
            ref={register({ required: true })}
            type="email"
          />
          {errors.email && (
            <TextContent css={AuthenticationFormErrorTextStyle}>
              {capitalize(errors.email.message)}
            </TextContent>
          )}
          <input
            css={AuthenticationFormSubmit1stButtonStyle}
            disabled={loading}
            type="submit"
            value="Send"
          />
        </form>
        <Link to={links.paths.LOGIN}>
          <TextContent style={{ ...AuthenticationFormActionTextStyle, textAlign: 'center' }}>
            ← Back to login
          </TextContent>
        </Link>
      </Container>
    </AuthenticationBaseContainer>
  );
}
export default ForgotPassword;
