import iterateInJson from './iterateInJson';
import jsonSchemaConstantKeys from '../constants/jsonSchemaConstantKeys';

export default function enableField(
  uiSchemaToIterate: Record<string, unknown>,
  fieldToEnableKey: string,
  fieldIsEnabled: boolean
): void {
  iterateInJson(uiSchemaToIterate, (_data, parentKey, key): boolean => {
    let mainKey = `${parentKey}.${key}`;
    mainKey = mainKey.substring(0, mainKey.indexOf(jsonSchemaConstantKeys.uiDirective));

    if (mainKey === fieldToEnableKey && key === jsonSchemaConstantKeys.uiReadOnly) {
      // eslint-disable-next-line no-param-reassign
      _data[key] = !fieldIsEnabled;
      return true;
    }

    return false;
  });
}
