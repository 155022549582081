import { Query, QueryMode, QueryResourcesArgs, SortOrder } from '../__generated__/graphql';

import GET_RESOURCE_NAMES from '../graphql/getResourceNames';
import { RootState } from '../redux/reducers';
import SelectOption from '../@types/react/SelectOption';
import { useApolloClient } from '@apollo/client';
import { useSelector } from 'react-redux';

interface Options {
  notIn?: string[];
}

type TGetResourceData = Pick<Query, 'resources'>;

export default function useLoadResourceOptions(
  locationId: string,
  options?: Options
): (input: string) => Promise<SelectOption[]> {
  const client = useApolloClient();
  const me = useSelector((state: RootState) => state.me);
  const activeTenantId = me.activeMembership?.tenantId ?? '';

  return async (input: string): Promise<SelectOption[]> => {
    const { data } = await client.query<TGetResourceData, QueryResourcesArgs>({
      query: GET_RESOURCE_NAMES,
      variables: {
        orderBy: [
          {
            name: SortOrder.Asc,
          },
        ],
        take: 10,
        where: {
          location: {
            is: {
              id: {
                equals: locationId,
              },
            },
          },
          name: {
            contains: input,
            mode: QueryMode.Insensitive,
            ...(options?.notIn && { notIn: options.notIn }),
          },
          tenantId: {
            equals: activeTenantId,
          },
        },
      },
    });

    return (
      data.resources?.map((resource) => ({
        label: resource.name,
        value: resource.id,
      })) ?? []
    );
  };
}
