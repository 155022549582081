import { Kiosk, Query, QueryKioskArgs } from '../__generated__/graphql';

import GET_KIOSK from '../graphql/getKiosk';
import QueryOneHookData from './@types/QueryOneHookData';
import { flashApolloError } from '../util/errorUtils';
import { useLazyQuery } from '@apollo/client';

type GetKioskData = Pick<Query, 'kiosk'>;

export default function useGetKioskById(): QueryOneHookData<Kiosk> {
  const [getKiosk, { called, data, error, loading }] = useLazyQuery<GetKioskData, QueryKioskArgs>(
    GET_KIOSK,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {},
      onError: flashApolloError,
    }
  );

  return {
    called,
    data: data?.kiosk ?? undefined,
    error,
    loading,
    query: (id: string): void => {
      getKiosk({
        variables: {
          where: { id },
        },
      });
    },
  };
}
