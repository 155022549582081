import SetPreferencesAction, {
  SetPreferencesPayload,
} from './@types/dashboardPreferences/SetPreferencesAction';

import ClearPreferencesAction from './@types/dashboardPreferences/ClearPreferencesAction';
import DashboardPreferencesActionEnum from './@types/dashboardPreferences/DashboardPreferencesAction.enum';

export const setPreferences = (payload: SetPreferencesPayload): SetPreferencesAction => ({
  payload,
  type: DashboardPreferencesActionEnum.SET_PREFERENCES,
});

export const clearPreferences = (): ClearPreferencesAction => ({
  payload: {},
  type: DashboardPreferencesActionEnum.CLEAR_PREFERENCES,
});

export type DashboardPreferencesAction = SetPreferencesAction | ClearPreferencesAction;
