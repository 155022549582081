import iterateInJson from './iterateInJson';
import jsonSchemaConstantKeys from '../constants/jsonSchemaConstantKeys';

export default function makeFieldAsRequired(
  schemaToIterate: Record<string, unknown>,
  parentKeyToBeRequired: string,
  fieldToBeRequiredKey: string,
  fieldIsRequired: boolean
): void {
  iterateInJson(schemaToIterate, (_data, parentKey): boolean => {
    const newParentKey = parentKey.replace(`.${jsonSchemaConstantKeys.schemaProperties}`, '');
    if (newParentKey === parentKeyToBeRequired) {
      const fieldType = ((_data.properties as Record<string, unknown>)[
        fieldToBeRequiredKey
      ] as Record<string, unknown>)[jsonSchemaConstantKeys.schemaType];

      if (fieldType === 'array' || fieldType === 'string') {
        return true;
      }

      if (typeof _data === 'object' && _data.required && _data.required instanceof Array) {
        const fieldIndex = _data.required.indexOf(fieldToBeRequiredKey);
        if (fieldIsRequired && fieldIndex === -1) {
          _data.required.push(fieldToBeRequiredKey);
        } else if (!fieldIsRequired && fieldIndex > -1) {
          _data.required.splice(fieldIndex, 1);
        }
      } else if (fieldIsRequired) {
        // eslint-disable-next-line no-param-reassign
        _data.required = [fieldToBeRequiredKey];
      }

      return true;
    }

    return false;
  });
}
