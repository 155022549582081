import { AmenityWhereInput, Query, QueryAmenitiesArgs } from '../../__generated__/graphql';
import { useCallback, useEffect, useRef, useState } from 'react';

import AMENITIES_TABLE_HEADERS from './constants/AmenitiesTableHeaders';
import AMENITIES_TABLE_SEARCH_FIELDS from './constants/AmenitiesTableSearchFields';
import { EntityEnum } from '../../@types/client/DashboardUserRolePermissions';
import GET_AMENITY_COUNT from '../../graphql/aggregateAmenity';
import GET_AMENITY_TABLE_DATA from '../../graphql/getAmenityTableData';
import { LocationPageInfo } from '../../pages/Location/@types/GetLocationPageInfo';
import OrderByParams from '../../@types/client/OrderByParams';
import { RootState } from '../../redux/reducers';
import SearchField from '../../@types/SearchField';
import TableHookData from '../@types/TableHookData';
import TableHookProps from '../@types/TableHookProps';
import generateAmenitiesTableData from './functions/generateAmenitiesTableData';
import generateLocationMembershipQuery from '../../util/api/generateLocationMembershipQuery';
import parseOrderByDataTableParams from '../../components/DataTable/util/parseOrderByDataTableParams';
import useGetMany from '../useGetMany';
import useRemoveAmenity from '../useRemoveAmenity';
import { useSelector } from 'react-redux';

type ResourcesQueryParams = {
  orderBy?: OrderByParams;
  where: Partial<AmenityWhereInput>;
};

interface ServicesTableData extends TableHookProps<QueryAmenitiesArgs, AmenityWhereInput> {
  relatedLocation?: LocationPageInfo;
}

type GetAmenityTableData = Pick<Query, 'amenities'>;
type AggregateAmenityQueryData = Pick<Query, 'aggregateAmenity'>;

export default function useAmenitiesTableData({
  dataTableKey = 'amenities',
  formName = 'amenity',
  headers = AMENITIES_TABLE_HEADERS,
  isSearchable = true,
  queryOptions,
  searchFields = AMENITIES_TABLE_SEARCH_FIELDS,
  relatedLocation,
}: ServicesTableData): TableHookData<AmenityWhereInput> {
  const membership = useSelector((state: RootState) => state.me.activeMembership);
  const options = useRef<Partial<QueryAmenitiesArgs>>(queryOptions ?? {});
  const sortOptions = useSelector((state: RootState) => state.dataTable.sortOptions);

  const [searchParameters, setSearchParameters] = useState<AmenityWhereInput>({});

  const { data, error, loading, offset, query, setOffset } = useGetMany<
    GetAmenityTableData,
    QueryAmenitiesArgs
  >({
    graphqlQuery: GET_AMENITY_TABLE_DATA,
  });

  const {
    data: countData,
    error: countError,
    loading: countLoading,
    query: countQuery,
  } = useGetMany<AggregateAmenityQueryData, QueryAmenitiesArgs>({
    graphqlQuery: GET_AMENITY_COUNT,
  });

  const createParams = useCallback((): ResourcesQueryParams => {
    const sortParams = parseOrderByDataTableParams(sortOptions[dataTableKey]);

    return {
      ...(sortParams && { orderBy: sortParams }),
      where: {
        ...generateLocationMembershipQuery(membership),
        ...options.current?.where,
        ...searchParameters,
      },
    };
  }, [membership, options, sortOptions, dataTableKey, searchParameters]);

  const get = useCallback(() => {
    const params = createParams();

    query({
      ...options.current,
      ...(params.orderBy && { orderBy: params.orderBy }),
      skip: options.current?.skip ?? offset,
      take: options.current?.take ?? 10,
      where: params.where,
    });
  }, [createParams, offset, query]);

  const getCount = useCallback(() => {
    const params = createParams();

    countQuery({
      where: params.where,
    });
  }, [countQuery, createParams]);

  useEffect(() => {
    get();
  }, [get]);

  useEffect(() => {
    getCount();
  }, [getCount]);

  return {
    aditionalDataOnOpeningForm: relatedLocation
      ? {
          location: {
            id: relatedLocation.id,
            name: relatedLocation.name,
          },
        }
      : undefined,
    count: countData?.aggregateAmenity?._count?._all ?? 0,
    data: generateAmenitiesTableData(data),
    dataTableKey,
    dataType: 'Amenity',
    error: error || countError,
    formName,
    headers,
    isLoading: loading || countLoading,
    isSearchable,
    offset,
    permission: EntityEnum.AMENITY,
    remove: useRemoveAmenity,
    search: (searchField: SearchField, searchValue: string): void => {
      setSearchParameters({
        [searchField.queryField]: searchField.transform(searchValue),
      });

      setOffset(0);
    },
    searchFields,
    setOffset,
  };
}
