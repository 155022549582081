import { AuthAnswer } from '../../../__generated__/graphql';
import NavigationParams from '../../../pages/Login/@types/NavigationParams';
import { channelsClient } from '../../../util/pusher';
import { onTokenReceived } from './onTokenReceived';
import { signInCallback } from './signInCallback';
import { v4 as uuidv4 } from 'uuid';
const { REACT_APP_VIRDEE_ENV = 'local' } = process.env;

export const oktaSignIn = (navigationParams: NavigationParams): void => {
  const { REACT_APP_GRAPHQL_URI } = process.env;
  const requestId = uuidv4();
  const authenticateViaOktaUrl = `${REACT_APP_GRAPHQL_URI}/sso/signin?authenticationType=okta&requestId=${requestId}`;
  const authWindow = window.open(authenticateViaOktaUrl, '_blank');

  const authenticationChannel = channelsClient.subscribe(
    `AUTHENTICATION-${REACT_APP_VIRDEE_ENV}-OKTA-${requestId}`
  );

  const OKTA_SIGN_IN_CALLBACK = 'OKTA_SIGN_IN_CALLBACK';

  // Unbind the listener if the authentication do not happens in 3 minutes
  const timeoutToSignIn = 3 * 60; // 3 minutes
  setTimeout(() => {
    authenticationChannel.unbind(OKTA_SIGN_IN_CALLBACK);
    window.flash({
      message: 'Failure on authenticate via Okta',
      subText: 'An error occurred while trying to authenticate via Okta. Authentication timeout.',
      type: 'error',
    });
  }, timeoutToSignIn * 1000);

  authenticationChannel.bind(OKTA_SIGN_IN_CALLBACK, (data: { authAnswer: AuthAnswer }) => {
    onTokenReceived(data.authAnswer);

    const {
      authAnswer: { accessToken, refreshToken },
    } = data;
    signInCallback({ accessToken, refreshToken }, navigationParams);
    authWindow?.close();
    authenticationChannel.unbind(OKTA_SIGN_IN_CALLBACK);
  });
};
