import { DataTableFilterValues } from '../../../../../@types/client/DataTableFilterValues';
import Filter from '../../../@types/Filter';
import getDefaultFilters from './getDefaultFilters';
import { isEqual } from 'lodash';

const sortDataTableFilterValues = (
  defaultFilterValueA: DataTableFilterValues,
  defaultFilterValueB: DataTableFilterValues
): number => {
  return defaultFilterValueA.fieldKey.localeCompare(defaultFilterValueB.fieldKey);
};

const checkIfFilterIsDefault = (
  filters: Filter[],
  activeFilters: DataTableFilterValues[] | undefined
): boolean => {
  const defaultFilterValues = getDefaultFilters(filters);

  return !isEqual(
    defaultFilterValues.sort(sortDataTableFilterValues),
    (activeFilters ?? []).sort(sortDataTableFilterValues)
  );
};

export default checkIfFilterIsDefault;
