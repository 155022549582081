import { Header } from '../../../components/DataTable/@types/DataTableProps';
import SortType from '../../../components/DataTable/@types/SortType.enum';

const PAYMENTS_TABLE_HEADERS: Header[] = [
  { displayValue: 'Token', isSortable: true, sortType: SortType.SortOrder, value: 'token' },
  {
    displayValue: 'Card Type',
    isSortable: true,
    sortType: SortType.SortOrderInput,
    value: 'cardType',
  },
  {
    displayValue: 'Last Four',
    isSortable: true,
    sortType: SortType.SortOrderInput,
    value: 'lastFour',
  },
  {
    displayValue: 'Expiration Year',
    isSortable: true,
    sortType: SortType.SortOrderInput,
    value: 'expYear',
  },
  {
    displayValue: 'Expiration Month',
    isSortable: true,
    sortType: SortType.SortOrderInput,
    value: 'expMonth',
  },
  {
    displayValue: 'Created At',
    isSortable: true,
    sortType: SortType.SortOrder,
    value: 'createdAt',
  },
];

export default PAYMENTS_TABLE_HEADERS;
