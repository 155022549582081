interface CreateSearchFieldInputs<T, K extends keyof T> {
  label: string;
  queryField: K;
  transform: (key: string) => T[K];
}

export default function createSearchField<T, K extends keyof T>({
  label,
  queryField,
  transform,
}: CreateSearchFieldInputs<T, K>): CreateSearchFieldInputs<T, K> {
  return {
    label,
    queryField,
    transform,
  };
}
