import Theme from '../../../theme';

const LogoutContainerStyle = `
  margin: 200px 0 0;

  button:hover {
    background: ${Theme.colors.white.hexCode};

    svg g {
      fill: ${Theme.colors.dark.hexCode};
    }
  }

  @media only screen and (max-width: 1200px), 
              screen and (max-height: 900px) {
    margin: 50px 0 0;

    button {
      border-radius: 6px;
      height: 25px;
      width: 25px;
    }

    svg {
      height: 12px;
      width: 12px;
    }
  }
`;

export default LogoutContainerStyle;
