import { Query, QueryGetDashboardLocationsArgs, SortOrder } from '../__generated__/graphql';

import QueryHookData from './@types/QueryHookData';
import QueryHookParams from './@types/QueryHookParams';
import SEARCH_DASHBOARD_LOCATIONS from '../graphql/searchDashboardLocations';
import { useLazyQuery } from '@apollo/client';

type SearchDashboardLocationsData = Pick<Query, 'getDashboardLocations'>;

export default function useSearchDashboardLocationsByName(params?: QueryHookParams): QueryHookData {
  const { onCompleted = (): void => {}, onError = (): void => {}, searchValue = '' } = params ?? {};

  const [getLocations, { data, error, loading }] = useLazyQuery<
    SearchDashboardLocationsData,
    QueryGetDashboardLocationsArgs
  >(SEARCH_DASHBOARD_LOCATIONS, {
    onCompleted,
    onError,
  });

  const locations = data?.getDashboardLocations ?? [];

  return {
    data: locations.map((location) => ({ id: location.id, name: location.name })),
    error,
    loading,
    query: (name = ''): void => {
      getLocations({
        variables: {
          orderBy: [{ name: SortOrder.Asc }],
          take: 10,
          where: {
            name: { contains: name ?? searchValue },
          },
        },
      });
    },
  };
}
