import InputGroupProps from '../../InputGroup/@types/InputGroupProps';
import { Role } from '../../../__generated__/graphql';
import values from 'lodash/values';

interface GenerateUserSectionInputsInterface {
  isAbleToSetPassword: boolean;
  isEditing: boolean;
  trigger: (name?: string | string[] | undefined) => Promise<boolean>;
}

const generateUserSectionInputs = ({
  isAbleToSetPassword,
  isEditing,
  trigger,
}: GenerateUserSectionInputsInterface): InputGroupProps[] => {
  let userSectionInputs: InputGroupProps[] = [
    {
      label: 'Name',
      name: 'name',
      type: 'text',
    },
    {
      label: 'Email',
      name: 'email',
      onChange: (): void => {
        trigger('phoneNumber');
      },
      type: 'email',
    },
    {
      label: 'Phone Number',
      name: 'phoneNumber',
      onChange: (): void => {
        trigger('email');
      },
      required: false,
      type: 'phone',
    },
    {
      label: 'Org. Role',
      name: 'role',
      options: values(Role),
      type: 'select',
    },
  ];

  if (isAbleToSetPassword) {
    userSectionInputs = [
      ...userSectionInputs,
      {
        label: !isEditing ? 'Password' : 'New password',
        matchField: 'confirmPassword',
        name: 'password',
        type: 'password',
      },
      {
        label: !isEditing ? 'Confirm Password' : 'Confirm new password',
        matchField: 'password',
        name: 'confirmPassword',
        shouldRegister: false,
        type: 'password',
      },
    ];
  }

  return userSectionInputs;
};

export default generateUserSectionInputs;
