import { ConciegeReduxState } from './@types/ConciergeReduxState';
import ConciergeActionEnum from '../../enums/ConciergeAction.enum';
import SetConciergeTreeAction from '../actions/@types/ConciergeTreeAction';

const initialState: ConciegeReduxState = {
  treeData: [],
};

export default function conciergeReducer(
  state: ConciegeReduxState = initialState,
  action: SetConciergeTreeAction
): ConciegeReduxState {
  switch (action.type) {
    case ConciergeActionEnum.SET_CONCIERGE_TREE: {
      return {
        ...state,
        treeData: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
