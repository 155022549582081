import { useCallback, useEffect, useState } from 'react';

import { AccessGrantGuests } from '../../pages/AccessGrant/components/GuestsTab';
import { EntityEnum } from '../../@types/client/DashboardUserRolePermissions';
import GUESTS_DETAILS_ON_AG_TABLE_HEADERS from './constants/GuestsDetailsOnAGTableHeaders';
import GUESTS_DETAILS_ON_AG_TABLE_SEARCH_FIELDS from './constants/GuestsDetailsOnAGTableSearchFields';
import GuestOnAGArgs from './@types/GuestOnAGArgs';
import GuestOnAGWhereInput from './@types/GuestOnAGWhereInput';
import OrderByParams from '../../@types/client/OrderByParams';
import { RootState } from '../../redux/reducers';
import SearchField from '../../@types/SearchField';
import TableHookData from '../@types/TableHookData';
import TableHookProps from '../@types/TableHookProps';
import { UserOnAG } from '../../pages/AccessGrant/@types/GetAccessGrantPageInfo';
import _ from 'lodash';
import { filterData } from './functions/filterData';
import generateGuestsOnAGTableData from './functions/generateGuestsOnAGTableData';
import parseGuestsOnAGQueryParameters from './util/parseGuestsOnAGQueryParameters';
import parseOrderByDataTableParams from '../../components/DataTable/util/parseOrderByDataTableParams';
import { sortData } from './functions/sortData';
import useDateTimeFormatter from '../useDateTimeFormatter';
import useGuestsOnAGFilters from './hooks/useGuestsOnAGFilters';
import { useSelector } from 'react-redux';

type GuestsOnAGQueryParams = {
  orderBy?: OrderByParams;
  where: Partial<GuestOnAGWhereInput>;
};

interface GuestsOnAGTableDataProps extends TableHookProps<GuestOnAGArgs, GuestOnAGWhereInput> {
  accessGrant: AccessGrantGuests;
}

export default function useGuestsOnAccessGrantTableData({
  accessGrant,
  dataTableKey = 'guestsOnAccessGrant',
  formName = 'guestsOnAccessGrant',
  headers = GUESTS_DETAILS_ON_AG_TABLE_HEADERS,
  isSearchable = true,
  isImportEnabled = true,
  permission = EntityEnum.NONE,
  searchFields = GUESTS_DETAILS_ON_AG_TABLE_SEARCH_FIELDS,
}: GuestsOnAGTableDataProps): TableHookData<GuestOnAGWhereInput> {
  const fieldsFiltered = useSelector((state: RootState) => state.dataTable.filters);
  const sortOptions = useSelector((state: RootState) => state.dataTable.sortOptions);
  const filters = useGuestsOnAGFilters();
  const [data, setData] = useState<UserOnAG[]>([]);
  const dateTimeFormatter = useDateTimeFormatter();

  const [searchParameters, setSearchParameters] = useState<GuestOnAGWhereInput>({});

  const query = useCallback((args?: GuestOnAGArgs) => {
    let guests: UserOnAG[] = [
      accessGrant.user,
      ...accessGrant.secondaryUsers.map((guest) => guest.user),
    ];

    if (args) {
      const { orderBy, where } = args;

      // Where
      guests = filterData(guests, accessGrant, where);

      // Order by
      guests = sortData(guests, orderBy);
    }

    setData(guests);
  }, []);

  const createParams = useCallback((): GuestsOnAGQueryParams => {
    const whereArgs = parseGuestsOnAGQueryParameters(fieldsFiltered[dataTableKey], accessGrant);
    const sortParams = parseOrderByDataTableParams(sortOptions[dataTableKey]);

    return {
      ...(sortParams && { orderBy: sortParams }),
      where: _.merge(whereArgs, searchParameters),
    };
  }, [sortOptions, fieldsFiltered, dataTableKey, searchParameters]);

  const get = useCallback(() => {
    const params = createParams();

    query({
      ...(params.orderBy && { orderBy: params.orderBy }),
      where: params.where,
    });
  }, [createParams, query]);

  useEffect(() => {
    get();
  }, [get]);

  return {
    count: data?.length ?? 0,
    data: generateGuestsOnAGTableData(
      accessGrant,
      dateTimeFormatter,
      data,
      accessGrant.location.timezone
    ),
    dataTableKey,
    dataType: 'GuestOnAccessGrant',
    error: undefined,
    filters,
    formName,
    headers,
    isImportEnabled,
    isLoading: false,
    isSearchable,
    offset: 0,
    permission,
    search: (searchField: SearchField<GuestOnAGWhereInput>, searchValue: string): void => {
      setSearchParameters({
        [searchField.queryField]: searchField.transform(searchValue),
      });
    },
    searchFields,
    setOffset: (): void => {},
  };
}
