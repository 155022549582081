import { FieldValidation, FormValidation } from '@rjsf/core';

import _ from 'lodash';
import isValidColor from '../util/css/isValidColor';
import { isValidLink } from '../../../util/validators/isValidLink';
import iterateInJson from './iterateInJson';
import jsonSchemaConstantKeys from '../constants/jsonSchemaConstantKeys';

const getFieldType = (uiSchema: Record<string, unknown>, fieldKey: string): string | undefined => {
  let fieldType = '';

  const uiSchemaToIterate = _.cloneDeep(uiSchema);

  iterateInJson(uiSchemaToIterate, (_data, parentKey, key): boolean => {
    let mainKey = `${parentKey}.${key}`;
    mainKey = mainKey.substring(0, mainKey.indexOf(jsonSchemaConstantKeys.uiDirective));

    if (mainKey === fieldKey && key === jsonSchemaConstantKeys.uiFieldType) {
      fieldType = _data[key] as string;
      return true;
    }

    return false;
  });

  return fieldType;
};

export default function validateFormData(
  uiSchema: Record<string, unknown>,
  nodeKey: string,
  formData: Record<string, unknown>,
  errors: FormValidation
): FormValidation {
  iterateInJson(formData, (_data, parentKey, key): boolean => {
    const mainKey = `${parentKey}.${key}`;

    if (
      mainKey.indexOf(jsonSchemaConstantKeys.schemaEnable) === -1 &&
      _data[`${key}${jsonSchemaConstantKeys.schemaEnable}`]
    ) {
      const value = _data[key] as string;

      if (value && value.length > 0) {
        const fieldType = getFieldType(uiSchema[nodeKey] as Record<string, unknown>, mainKey);

        const addError = (errorValue: string): void => {
          iterateInJson(errors, (_errorData, errorParentKey, errorKey): boolean => {
            const errorMainKey = `${errorParentKey}.${errorKey}`;
            if (`${mainKey}${jsonSchemaConstantKeys.errorAddError}` === errorMainKey) {
              (_errorData as FieldValidation).addError(errorValue);
              return true;
            }

            return false;
          });
        };

        switch (fieldType) {
          case 'link':
            if (!isValidLink(value)) {
              addError('invalid link');
            }
            break;

          case 'color':
            if (!isValidColor(value)) {
              addError('invalid color');
            }
            break;

          default:
            break;
        }
      }
    }

    return false;
  });

  return errors;
}
