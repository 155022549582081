import { Header } from '../../../components/DataTable/@types/DataTableProps';
import SortType from '../../../components/DataTable/@types/SortType.enum';

const USER_RECORDS_TABLE_HEADERS: Header[] = [
  {
    displayValue: 'Record Type',
    isSortable: true,
    sortType: SortType.SortOrder,
    value: 'recordType',
  },
  {
    displayValue: 'Verification Status',
    isSortable: true,
    sortType: SortType.SortOrderInput,
    value: 'verificationStatus',
  },
  {
    displayValue: 'Date Record',
    isSortable: true,
    sortType: SortType.SortOrder,
    value: 'timeStamp',
  },
  { displayValue: 'Record ID', isSortable: true, sortType: SortType.SortOrder, value: 'recordId' },
];

export default USER_RECORDS_TABLE_HEADERS;
