import { AuthAnswer } from '../../../__generated__/graphql';
import NavigationParams from '../../../pages/Login/@types/NavigationParams';
import links from '../../../util/links';
import { receiveTokens } from '../../../redux/actions/auth.actions';

export function signInCallback(
  token: Pick<AuthAnswer, 'accessToken' | 'refreshToken'>,
  { dispatch, history, redirectPath }: NavigationParams
): void {
  if (Object.values(token).every((field) => field)) {
    const newToken = { accessToken: token.accessToken!, refreshToken: token.refreshToken! };
    dispatch(receiveTokens(newToken));
    history.push(redirectPath ?? links.paths.HOME);
  }
}
