import { gql } from '../../__generated__/gql';

export const GET_KIOSK_THEME = gql(`
  query getKioskTheme($componentName: String!, $kioskId: String!) {
    getKioskTheme(componentName: $componentName, kioskId: $kioskId) {
      ...BaseSettingAnswerFields
    }
  }
`);

export const GET_KIOSK_LOCALIZATION = gql(`
  query getKioskLocalization($componentName: String!, $languageId: String!, $kioskId: String!) {
    getKioskLocalization(
      componentName: $componentName
      languageId: $languageId
      kioskId: $kioskId
    ) {
      ...BaseSettingAnswerFields
    }
  }
`);
