import INVALIDATE_CREDENTIAL from '../../graphql/invalidateCredential';
import InvalidateCredentialData from './@types/InvalidateCredentialData';
import RemoveHookData from '../@types/RemoveHookData';
import { flashApolloError } from '../../util/errorUtils';
import useCompleteRemoveCredential from './hooks/useCompleteRemoveCredential';
import { useMutation } from '@apollo/client';

export default function useRemoveCredential(): RemoveHookData {
  const { onCompleteRemoveCredential } = useCompleteRemoveCredential();

  const [remove, { error, loading }] = useMutation<
    InvalidateCredentialData,
    { credentialId: string }
  >(INVALIDATE_CREDENTIAL, {
    onCompleted: ({ invalidateCredential }) => onCompleteRemoveCredential(invalidateCredential),
    onError: flashApolloError,
  });

  return {
    error,
    loading,
    mutation: (id: string): void => {
      remove({
        variables: {
          credentialId: id,
        },
      });
    },
  };
}
