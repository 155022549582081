import 'styled-components/macro';

import { CallRecord, Query, QueryCallRecordsArgs } from '../../../../__generated__/graphql';
import DisplayBox, { DisplayBoxModel } from '../../../../components/DisplayBox';
import React, { memo } from 'react';

import CallRecordsList from './components/CallRecordsList';
import Container from '../../../../components/Container';
import GET_CALL_RECORDS from '../../../../graphql/callRecords';
import RemoteAssistanceAsideStyle from './styles/RemoteAssistanceAside.style';
import RemoteAssistanceDisplayBoxStyle from './styles/RemoteAssistanceDisplayBox.style';
import { RootState } from '../../../../redux/reducers';
import links from '../../../../util/links';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

interface RemoteAssistanceAsideProps {
  oneDayAgo: Date;
}

type CallRecordsQueryData = Pick<Query, 'callRecords'>;

const RemoteAssistanceAside: React.FC<RemoteAssistanceAsideProps> = ({
  oneDayAgo,
}: RemoteAssistanceAsideProps) => {
  const membership = useSelector((state: RootState) => state.me.activeMembership);
  const history = useHistory();

  const { data, error, loading } = useQuery<CallRecordsQueryData, QueryCallRecordsArgs>(
    GET_CALL_RECORDS,
    {
      variables: {
        where: {
          createdAt: { gte: oneDayAgo },
          tenant: {
            is: {
              id: {
                equals: membership?.tenantId,
              },
            },
          },
        },
      },
    }
  );

  const callRecords: CallRecord[] = data?.callRecords || [];

  function navigateToReporting(): void {
    history.push(links.paths.REPORTING);
  }

  return (
    <Container css={RemoteAssistanceAsideStyle}>
      <DisplayBox
        css={RemoteAssistanceDisplayBoxStyle}
        ctaText="Report"
        model={DisplayBoxModel.LIGHT}
        onClick={navigateToReporting}
        title="Call Log"
      >
        <CallRecordsList callRecords={callRecords} error={error} loading={loading} />
      </DisplayBox>
    </Container>
  );
};

export default memo(RemoteAssistanceAside);
