import { CredentialWhereInput, Query, QueryCredentialsArgs } from '../../__generated__/graphql';
import { useCallback, useEffect, useRef, useState } from 'react';

import { AccessGrantPageInfo } from '../../pages/AccessGrant/@types/GetAccessGrantPageInfo';
import CREDENTIALS_TABLE_HEADERS from './constants/CredentialsTableHeaders';
import CREDENTIALS_TABLE_SEARCH_FIELDS from './constants/CredentialsTableSearchFields';
import { EntityEnum } from '../../@types/client/DashboardUserRolePermissions';
import GET_CREDENTIALS_TABLE_DATA from '../../graphql/getCredentialsTableData';
import GET_CREDENTIAL_COUNT from '../../graphql/aggregateCredential';
import OrderByParams from '../../@types/client/OrderByParams';
import { RootState } from '../../redux/reducers';
import SearchField from '../../@types/SearchField';
import TableHookData from '../@types/TableHookData';
import TableHookProps from '../@types/TableHookProps';
import generateCredentialsTableData from './functions/generateCredentialsTableData';
import parseCredentialQueryParameters from './util/parseCredentialQueryParameters';
import parseOrderByDataTableParams from '../../components/DataTable/util/parseOrderByDataTableParams';
import useCredentialsFilters from './hooks/useCredentialsFilters';
import useDateTimeFormatter from '../useDateTimeFormatter';
import useGetMany from '../useGetMany';
import useRemoveCredential from '../useRemoveCredential';
import { useSelector } from 'react-redux';

type CredentialsQueryParams = {
  orderBy?: OrderByParams;
  where: Partial<CredentialWhereInput>;
};

type GetCredentialTableData = Pick<Query, 'getCredentials'>;
type AggregateCredentialQueryData = Pick<Query, 'aggregateCredential'>;

interface CredentialsTableData extends TableHookProps<QueryCredentialsArgs, CredentialWhereInput> {
  relatedAccessGrant?: AccessGrantPageInfo;
}

export default function useCredentialsTableData({
  dataTableKey = 'credentials',
  formName = 'credentials',
  headers = CREDENTIALS_TABLE_HEADERS,
  isSearchable = true,
  isImportEnabled = true,
  permission = EntityEnum.CREDENTIAL,
  queryOptions,
  relatedAccessGrant,
  searchFields = CREDENTIALS_TABLE_SEARCH_FIELDS,
}: CredentialsTableData): TableHookData<CredentialWhereInput> {
  const options = useRef<Partial<QueryCredentialsArgs>>(queryOptions ?? {});
  const fieldsFiltered = useSelector((state: RootState) => state.dataTable.filters);
  const sortOptions = useSelector((state: RootState) => state.dataTable.sortOptions);
  const filters = useCredentialsFilters(relatedAccessGrant);
  const credentialsBeingProcessed = useSelector(
    (state: RootState) => state.pendingTasks.credentialsBeingProcessed
  );
  const dateTimeFormatter = useDateTimeFormatter();

  const [searchParameters, setSearchParameters] = useState<CredentialWhereInput>({});

  const { data, error, loading, offset, query, setOffset } = useGetMany<
    GetCredentialTableData,
    QueryCredentialsArgs
  >({
    graphqlQuery: GET_CREDENTIALS_TABLE_DATA,
  });

  const {
    data: countData,
    error: countError,
    loading: countLoading,
    query: countQuery,
  } = useGetMany<AggregateCredentialQueryData, QueryCredentialsArgs>({
    graphqlQuery: GET_CREDENTIAL_COUNT,
  });

  const createParams = useCallback((): CredentialsQueryParams => {
    const whereArgs = parseCredentialQueryParameters(
      fieldsFiltered[dataTableKey],
      relatedAccessGrant
    );
    const sortParams = parseOrderByDataTableParams(sortOptions[dataTableKey]);

    return {
      ...(sortParams && { orderBy: sortParams }),
      where: {
        ...options.current?.where,
        ...searchParameters,
        ...whereArgs,
      },
    };
  }, [sortOptions, fieldsFiltered, dataTableKey, options, searchParameters, relatedAccessGrant]);

  const get = useCallback(() => {
    const params = createParams();

    query({
      ...options.current,
      ...(params.orderBy && { orderBy: params.orderBy }),
      skip: options.current?.skip ?? offset,
      take: options.current?.take ?? 10,
      where: params.where,
    });
  }, [createParams, offset, query]);

  const getCount = useCallback(() => {
    const params = createParams();

    countQuery({
      where: params.where,
    });
  }, [countQuery, createParams]);

  useEffect(() => {
    get();
  }, [get]);

  useEffect(() => {
    getCount();
  }, [getCount]);

  return {
    count: countData?.aggregateCredential?._count?._all ?? 0,
    data: generateCredentialsTableData(
      credentialsBeingProcessed.map((credential) => credential.credentialId),
      dateTimeFormatter,
      data,
      relatedAccessGrant?.location.timezone
    ),
    dataTableKey,
    dataTableTexts: {
      deleteText: 'Revoke',
      removeFormVerbiage: 'revoke',
    },
    dataType: 'Credential',
    error: error || countError,
    filters,
    formName,
    headers,
    isImportEnabled,
    isLoading: loading || countLoading,
    isSearchable,
    offset,
    permission,
    remove: useRemoveCredential,
    search: (searchField: SearchField<CredentialWhereInput>, searchValue: string): void => {
      setSearchParameters({
        [searchField.queryField]: searchField.transform(searchValue),
      });

      setOffset(0);
    },
    searchFields,
    setOffset,
  };
}
