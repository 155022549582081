import { gql } from '../../__generated__/gql';

export const DASHBOARD_SIGN_IN = gql(`
  mutation DashboardSignIn($password: String!, $email: String!) {
    dashboardSignIn(password: $password, email: $email) {
      accessToken
      expiresIn
      refreshToken
    }
  }
`);
