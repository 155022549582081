import Theme from '../../../theme';
import styled from 'styled-components';

interface ToggleSelectorContainerProps {
  widthType?: 'compact' | 'normal' | 'parentWidth';
  disableCursor?: boolean;
  themeMode: 'light' | 'dark';
  numberOfOptions: number;
}

const determineContainerWidth = ({
  widthType,
  numberOfOptions,
}: ToggleSelectorContainerProps): string => {
  if (widthType === 'parentWidth') {
    return '100%';
  }

  const sizePerOption = widthType === 'compact' ? 50 : 135;
  return `${sizePerOption * numberOfOptions}px`;
};

const ToggleSelectorContainer = styled.div<ToggleSelectorContainerProps>`
  width: 100%;
  max-width: ${determineContainerWidth};
  width: ${determineContainerWidth};
  max-height: 35px;
  height: 35px;
  border-radius: 10px;
  font-weight: 600;
  position: relative;
  background-color: ${({ themeMode }): string => {
    if (themeMode === 'light') {
      return Theme.colors.borderTable.hexCode;
    }
    if (themeMode === 'dark') {
      return Theme.colors.borderDark.hexCode;
    }
    return '';
  }};
  color: ${({ themeMode }): string => {
    if (themeMode === 'light') {
      return Theme.colors.borderDark.hexCode;
    }
    if (themeMode === 'dark') {
      return Theme.colors.white.hexCode;
    }
    return '';
  }};
  border-color: ${({ themeMode }): string => {
    if (themeMode === 'light') {
      return Theme.colors.borderTable.hexCode;
    }
    if (themeMode === 'dark') {
      return Theme.colors.borderDark.hexCode;
    }
    return '';
  }};
  border-style: solid;
  border-width: 2px;
  cursor: ${({ disableCursor }): string => {
    return disableCursor ? 'not-allowed' : 'pointer';
  }};

  & > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
`;

export default ToggleSelectorContainer;
