import ClearActiveCallAction from '../actions/@types/ClearActiveCallAction';
import LogOutAction from '../actions/@types/LogOutAction';
import MeActionEnum from '../../enums/MeAction.enum';
import RemoteAssistanceAction from '../../enums/RemoteAssistanceAction.enum';
import RemoteAssistanceReduxState from './@types/RemoteAssistanceReduxState';
import RemoveIncomingCallAction from '../actions/@types/RemoveIncomingCallAction';
import SetActiveCallAction from '../actions/@types/SetActiveCallAction';
import SetActiveRoomAction from '../actions/@types/SetActiveRoomAction';
import SetIncomingCallAction from '../actions/@types/SetIncomingCallAction';
import SetJoinerChallengeAction from '../actions/@types/SetJoinerChallengeAction';
import SetVideoTokenAction from '../actions/@types/SetVideoTokenAction';

const initialState: RemoteAssistanceReduxState = {
  activeRoom: null,
  incomingCalls: [],
};

export default function remoteAssistanceReducer(
  state: RemoteAssistanceReduxState = initialState,
  action:
    | RemoveIncomingCallAction
    | ClearActiveCallAction
    | SetIncomingCallAction
    | SetActiveCallAction
    | SetVideoTokenAction
    | LogOutAction
    | SetJoinerChallengeAction
    | SetActiveRoomAction
): RemoteAssistanceReduxState {
  switch (action.type) {
    case MeActionEnum.LOG_OUT: {
      return initialState;
    }

    case RemoteAssistanceAction.REMOVE_INCOMING_CALL: {
      const { incomingCalls } = state;
      const { callRecordIdToBeRemoved } = action.payload;

      const newIncomingCalls = [...incomingCalls];

      const incomingCallToBeRemovedIndex = newIncomingCalls.findIndex(
        (incomingCall) => incomingCall.callRecordId === callRecordIdToBeRemoved
      );
      if (incomingCallToBeRemovedIndex > -1) {
        newIncomingCalls.splice(incomingCallToBeRemovedIndex, 1);
      }

      return {
        ...state,
        incomingCalls: newIncomingCalls,
      };
    }

    case RemoteAssistanceAction.CLEAR_ACTIVE_CALL: {
      return {
        ...state,
        activeCall: undefined,
        activeRoom: null,
        videoToken: undefined,
      };
    }

    case RemoteAssistanceAction.SET_INCOMING_CALL: {
      const { incomingCalls } = state;
      const { incomingCall } = action.payload;

      const newIncomingCalls = [...incomingCalls];

      newIncomingCalls.push(incomingCall);

      return {
        ...state,
        incomingCalls: newIncomingCalls,
      };
    }

    case RemoteAssistanceAction.SET_ACTIVE_CALL: {
      const { activeCall } = action.payload;

      return {
        ...state,
        activeCall: {
          ...activeCall,
        },
      };
    }

    case RemoteAssistanceAction.SET_VIDEO_TOKEN: {
      return {
        ...state,
        videoToken: action.payload.videoToken,
      };
    }

    case RemoteAssistanceAction.SET_JOINER_CHALLENGE: {
      return {
        ...state,
        joinerChallenge: action.payload.joinerChallenge,
      };
    }

    case RemoteAssistanceAction.SET_ACTIVE_ROOM: {
      return {
        ...state,
        activeRoom: action.payload.activeRoom,
      };
    }

    default: {
      return state;
    }
  }
}
