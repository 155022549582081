import { gql } from '../__generated__/gql';

const GET_USER_COUNT = gql(`
  query GetUserCount($where: UserWhereInput!) {
    aggregateUser(where: $where) {
      _count {
        _all
      }
    }
  }
`);

export default GET_USER_COUNT;
