import LoadKioskLiveUsageReservationUpdatedDataAction, {
  ReservationUpdatedType,
} from './@types/kiosksLiveUsage/LoadKioskLiveUsageReservationUpdatedDataAction';

import { KioskLiveUsageData } from '../../__generated__/graphql';
import { KioskLiveUsagePayloadEvent } from '../reducers/kiosksLiveUsage.reducer/@types/KiosksLiveUsageReduxState';
import KiosksLiveUsageActionEnum from './@types/kiosksLiveUsage/KiosksLiveUsageAction.enum';
import LoadKioskLiveUsageDataAction from './@types/kiosksLiveUsage/LoadKioskLiveUsageDataAction';
import SetKioskLiveUsageAction from './@types/kiosksLiveUsage/SetKioskLiveUsageAction';

export const setKioskLiveUsage = (data: KioskLiveUsagePayloadEvent): SetKioskLiveUsageAction => ({
  payload: data,
  type: KiosksLiveUsageActionEnum.SET_KIOSK_LIVE_USAGE,
});

export const loadKioskLiveUsageData = (
  data: KioskLiveUsageData[]
): LoadKioskLiveUsageDataAction => ({
  payload: data,
  type: KiosksLiveUsageActionEnum.LOAD_KIOSK_LIVE_USAGE_DATA,
});

export const loadKioskLiveUsageReservationUpdatedData = (
  data: ReservationUpdatedType
): LoadKioskLiveUsageReservationUpdatedDataAction => ({
  payload: data,
  type: KiosksLiveUsageActionEnum.LOAD_KIOSK_LIVE_USAGE_RESERVATION_UPDATED_DATA,
});

export type KiosksLiveUsageAction =
  | SetKioskLiveUsageAction
  | LoadKioskLiveUsageDataAction
  | LoadKioskLiveUsageReservationUpdatedDataAction;
