import { gql } from '../__generated__/gql';

export const GET_PAYMENT_TABLE_DATA = gql(`
  query GetPaymentsPageInfo(
    $cursor: PaymentWhereUniqueInput
    $distinct: [PaymentScalarFieldEnum!]
    $orderBy: [PaymentOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: PaymentWhereInput
  ) {
    payments(
      cursor: $cursor
      distinct: $distinct
      orderBy: $orderBy
      skip: $skip
      take: $take
      where: $where
    ) {
      id
      cardType
      lastFour
      expYear
      expMonth
      createdAt
      token
    }
  }
`);
