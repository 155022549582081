/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationAccessManagementSchema: NodeSchemaProperties = {
  accessManagement: {
    title: '#14 Access Management',
    type: 'object',
    properties: {
      'digitalKeyTitle:enable': {
        description: '[accessManagement.digitalKeyTitle]',
        title: '#1 Digital Key Title',
        type: 'boolean',
      },
      digitalKeyTitle: {
        type: 'string',
      },
      askUserDeviceChange: {
        title: '#2 Ask User Device Change',
        type: 'object',
        properties: {
          'newDeviceAlertTitle:enable': {
            description: '[accessManagement.askUserDeviceChange.newDeviceAlertTitle]',
            title: '#2.1 New Device Alert Title',
            type: 'boolean',
          },
          newDeviceAlertTitle: {
            type: 'string',
          },
          'newDeviceAlertText:enable': {
            description: '[accessManagement.askUserDeviceChange.newDeviceAlertText]',
            title: '#2.2 New Device Alert Text',
            type: 'boolean',
          },
          newDeviceAlertText: {
            type: 'string',
          },
          'cancelText:enable': {
            description: '[accessManagement.askUserDeviceChange.cancelText]',
            title: '#2.3 Cancel Text',
            type: 'boolean',
          },
          cancelText: {
            type: 'string',
          },
          'confirmText:enable': {
            description: '[accessManagement.askUserDeviceChange.confirmText]',
            title: '#2.4 Confirm Text',
            type: 'boolean',
          },
          confirmText: {
            type: 'string',
          },
        },
      },
      instructions: {
        title: '#3 Instructions',
        type: 'object',
        properties: {
          resourceTitle: {
            title: '#3.1 Resource Title',
            type: 'object',
            properties: {
              title: {
                title: '#3.1.1 Title',
                type: 'object',
                properties: {
                  'text:enable': {
                    description: '[accessManagement.instructions.resourceTitle.title.text]',
                    title: '#3.1.1.1 Text',
                    type: 'boolean',
                  },
                  text: {
                    type: 'string',
                  },
                  'type:enable': {
                    description: '[accessManagement.instructions.resourceTitle.title.type]',
                    title: '#3.1.1.2 Type',
                    type: 'boolean',
                  },
                  type: {
                    enum: ['PLAIN_TEXT', 'HTML'],
                    type: 'string',
                  },
                  'useDefaultStyles:enable': {
                    description:
                      '[accessManagement.instructions.resourceTitle.title.useDefaultStyles]',
                    title: '#3.1.1.3 Use Default Styles',
                    type: 'boolean',
                  },
                  useDefaultStyles: {
                    type: undefined,
                    enum: [true, false],
                  },
                },
              },
              titleOnUnlocked: {
                title: '#3.1.2 Title On Unlocked',
                type: 'object',
                properties: {
                  'text:enable': {
                    description:
                      '[accessManagement.instructions.resourceTitle.titleOnUnlocked.text]',
                    title: '#3.1.2.1 Text',
                    type: 'boolean',
                  },
                  text: {
                    type: 'string',
                  },
                  'type:enable': {
                    description:
                      '[accessManagement.instructions.resourceTitle.titleOnUnlocked.type]',
                    title: '#3.1.2.2 Type',
                    type: 'boolean',
                  },
                  type: {
                    enum: ['PLAIN_TEXT', 'HTML'],
                    type: 'string',
                  },
                  'useDefaultStyles:enable': {
                    description:
                      '[accessManagement.instructions.resourceTitle.titleOnUnlocked.useDefaultStyles]',
                    title: '#3.1.2.3 Use Default Styles',
                    type: 'boolean',
                  },
                  useDefaultStyles: {
                    type: undefined,
                    enum: [true, false],
                  },
                },
              },
              titleOnUnlocking: {
                title: '#3.1.3 Title On Unlocking',
                type: 'object',
                properties: {
                  'text:enable': {
                    description:
                      '[accessManagement.instructions.resourceTitle.titleOnUnlocking.text]',
                    title: '#3.1.3.1 Text',
                    type: 'boolean',
                  },
                  text: {
                    type: 'string',
                  },
                  'type:enable': {
                    description:
                      '[accessManagement.instructions.resourceTitle.titleOnUnlocking.type]',
                    title: '#3.1.3.2 Type',
                    type: 'boolean',
                  },
                  type: {
                    enum: ['PLAIN_TEXT', 'HTML'],
                    type: 'string',
                  },
                  'useDefaultStyles:enable': {
                    description:
                      '[accessManagement.instructions.resourceTitle.titleOnUnlocking.useDefaultStyles]',
                    title: '#3.1.3.3 Use Default Styles',
                    type: 'boolean',
                  },
                  useDefaultStyles: {
                    type: undefined,
                    enum: [true, false],
                  },
                },
              },
              'default:enable': {
                description: '[accessManagement.instructions.resourceTitle.default]',
                title: '#3.1.4 Default',
                type: 'boolean',
              },
              default: {
                type: 'string',
              },
              'loading:enable': {
                description: '[accessManagement.instructions.resourceTitle.loading]',
                title: '#3.1.5 Loading',
                type: 'boolean',
              },
              loading: {
                type: 'string',
              },
            },
          },
          resourceMessage: {
            title: '#3.2 Resource Message',
            type: 'object',
            properties: {
              message: {
                title: '#3.2.1 Message',
                type: 'object',
                properties: {
                  'text:enable': {
                    description: '[accessManagement.instructions.resourceMessage.message.text]',
                    title: '#3.2.1.1 Text',
                    type: 'boolean',
                  },
                  text: {
                    type: 'string',
                  },
                  'type:enable': {
                    description: '[accessManagement.instructions.resourceMessage.message.type]',
                    title: '#3.2.1.2 Type',
                    type: 'boolean',
                  },
                  type: {
                    enum: ['PLAIN_TEXT', 'HTML'],
                    type: 'string',
                  },
                  'useDefaultStyles:enable': {
                    description:
                      '[accessManagement.instructions.resourceMessage.message.useDefaultStyles]',
                    title: '#3.2.1.3 Use Default Styles',
                    type: 'boolean',
                  },
                  useDefaultStyles: {
                    type: undefined,
                    enum: [true, false],
                  },
                },
              },
              messageOnUnlocking: {
                title: '#3.2.2 Message On Unlocking',
                type: 'object',
                properties: {
                  'text:enable': {
                    description:
                      '[accessManagement.instructions.resourceMessage.messageOnUnlocking.text]',
                    title: '#3.2.2.1 Text',
                    type: 'boolean',
                  },
                  text: {
                    type: 'string',
                  },
                  'type:enable': {
                    description:
                      '[accessManagement.instructions.resourceMessage.messageOnUnlocking.type]',
                    title: '#3.2.2.2 Type',
                    type: 'boolean',
                  },
                  type: {
                    enum: ['PLAIN_TEXT', 'HTML'],
                    type: 'string',
                  },
                  'useDefaultStyles:enable': {
                    description:
                      '[accessManagement.instructions.resourceMessage.messageOnUnlocking.useDefaultStyles]',
                    title: '#3.2.2.3 Use Default Styles',
                    type: 'boolean',
                  },
                  useDefaultStyles: {
                    type: undefined,
                    enum: [true, false],
                  },
                },
              },
              messageOnUnlocked: {
                title: '#3.2.3 Message On Unlocked',
                type: 'object',
                properties: {
                  'text:enable': {
                    description:
                      '[accessManagement.instructions.resourceMessage.messageOnUnlocked.text]',
                    title: '#3.2.3.1 Text',
                    type: 'boolean',
                  },
                  text: {
                    type: 'string',
                  },
                  'type:enable': {
                    description:
                      '[accessManagement.instructions.resourceMessage.messageOnUnlocked.type]',
                    title: '#3.2.3.2 Type',
                    type: 'boolean',
                  },
                  type: {
                    enum: ['PLAIN_TEXT', 'HTML'],
                    type: 'string',
                  },
                  'useDefaultStyles:enable': {
                    description:
                      '[accessManagement.instructions.resourceMessage.messageOnUnlocked.useDefaultStyles]',
                    title: '#3.2.3.3 Use Default Styles',
                    type: 'boolean',
                  },
                  useDefaultStyles: {
                    type: undefined,
                    enum: [true, false],
                  },
                },
              },
              'loading:enable': {
                description: '[accessManagement.instructions.resourceMessage.loading]',
                title: '#3.2.4 Loading',
                type: 'boolean',
              },
              loading: {
                type: 'string',
              },
            },
          },
          errorMessage: {
            title: '#3.3 Error Message',
            type: 'object',
            properties: {
              title: {
                title: '#3.3.1 Title',
                type: 'object',
                properties: {
                  'text:enable': {
                    description: '[accessManagement.instructions.errorMessage.title.text]',
                    title: '#3.3.1.1 Text',
                    type: 'boolean',
                  },
                  text: {
                    type: 'string',
                  },
                  'type:enable': {
                    description: '[accessManagement.instructions.errorMessage.title.type]',
                    title: '#3.3.1.2 Type',
                    type: 'boolean',
                  },
                  type: {
                    enum: ['PLAIN_TEXT', 'HTML'],
                    type: 'string',
                  },
                  'useDefaultStyles:enable': {
                    description:
                      '[accessManagement.instructions.errorMessage.title.useDefaultStyles]',
                    title: '#3.3.1.3 Use Default Styles',
                    type: 'boolean',
                  },
                  useDefaultStyles: {
                    type: undefined,
                    enum: [true, false],
                  },
                },
              },
              description: {
                title: '#3.3.2 Description',
                type: 'object',
                properties: {
                  'text:enable': {
                    description: '[accessManagement.instructions.errorMessage.description.text]',
                    title: '#3.3.2.1 Text',
                    type: 'boolean',
                  },
                  text: {
                    type: 'string',
                  },
                  'type:enable': {
                    description: '[accessManagement.instructions.errorMessage.description.type]',
                    title: '#3.3.2.2 Type',
                    type: 'boolean',
                  },
                  type: {
                    enum: ['PLAIN_TEXT', 'HTML'],
                    type: 'string',
                  },
                  'useDefaultStyles:enable': {
                    description:
                      '[accessManagement.instructions.errorMessage.description.useDefaultStyles]',
                    title: '#3.3.2.3 Use Default Styles',
                    type: 'boolean',
                  },
                  useDefaultStyles: {
                    type: undefined,
                    enum: [true, false],
                  },
                },
              },
            },
          },
          frontDeskStateError: {
            title: '#3.4 Front Desk State Error',
            type: 'object',
            properties: {
              'title:enable': {
                description: '[accessManagement.instructions.frontDeskStateError.title]',
                title: '#3.4.1 Title',
                type: 'boolean',
              },
              title: {
                type: 'string',
              },
              'description:enable': {
                description: '[accessManagement.instructions.frontDeskStateError.description]',
                title: '#3.4.2 Description',
                type: 'boolean',
              },
              description: {
                type: 'string',
              },
            },
          },
        },
      },
      entryScreen: {
        title: '#4 Entry Screen',
        type: 'object',
        properties: {
          'openLockerButton:enable': {
            description: '[accessManagement.entryScreen.openLockerButton]',
            title: '#4.1 Open Locker Button',
            type: 'boolean',
          },
          openLockerButton: {
            type: 'string',
          },
          'openLockerButtonTryAgain:enable': {
            description: '[accessManagement.entryScreen.openLockerButtonTryAgain]',
            title: '#4.2 Open Locker Button Try Again',
            type: 'boolean',
          },
          openLockerButtonTryAgain: {
            type: 'string',
          },
          'openLockerCancelButton:enable': {
            description: '[accessManagement.entryScreen.openLockerCancelButton]',
            title: '#4.3 Open Locker Cancel Button',
            type: 'boolean',
          },
          openLockerCancelButton: {
            type: 'string',
          },
          'callFrontDeskButton:enable': {
            description: '[accessManagement.entryScreen.callFrontDeskButton]',
            title: '#4.4 Call Front Desk Button',
            type: 'boolean',
          },
          callFrontDeskButton: {
            type: 'string',
          },
          'bluetoothOffMessage:enable': {
            description: '[accessManagement.entryScreen.bluetoothOffMessage]',
            title: '#4.5 Bluetooth Off Message',
            type: 'boolean',
          },
          bluetoothOffMessage: {
            type: 'string',
          },
          unlockedMessage: {
            title: '#4.6 Unlocked Message',
            type: 'object',
            properties: {
              'text:enable': {
                description: '[accessManagement.entryScreen.unlockedMessage.text]',
                title: '#4.6.1 Text',
                type: 'boolean',
              },
              text: {
                type: 'string',
              },
              'type:enable': {
                description: '[accessManagement.entryScreen.unlockedMessage.type]',
                title: '#4.6.2 Type',
                type: 'boolean',
              },
              type: {
                enum: ['PLAIN_TEXT', 'HTML'],
                type: 'string',
              },
              'useDefaultStyles:enable': {
                description: '[accessManagement.entryScreen.unlockedMessage.useDefaultStyles]',
                title: '#4.6.3 Use Default Styles',
                type: 'boolean',
              },
              useDefaultStyles: {
                type: undefined,
                enum: [true, false],
              },
            },
          },
          lockedMessage: {
            title: '#4.7 Locked Message',
            type: 'object',
            properties: {
              'text:enable': {
                description: '[accessManagement.entryScreen.lockedMessage.text]',
                title: '#4.7.1 Text',
                type: 'boolean',
              },
              text: {
                type: 'string',
              },
              'type:enable': {
                description: '[accessManagement.entryScreen.lockedMessage.type]',
                title: '#4.7.2 Type',
                type: 'boolean',
              },
              type: {
                enum: ['PLAIN_TEXT', 'HTML'],
                type: 'string',
              },
              'useDefaultStyles:enable': {
                description: '[accessManagement.entryScreen.lockedMessage.useDefaultStyles]',
                title: '#4.7.3 Use Default Styles',
                type: 'boolean',
              },
              useDefaultStyles: {
                type: undefined,
                enum: [true, false],
              },
            },
          },
          mobileKeyError: {
            title: '#4.8 Mobile Key Error',
            type: 'object',
            properties: {
              title: {
                title: '#4.8.1 Title',
                type: 'object',
                properties: {
                  'text:enable': {
                    description: '[accessManagement.entryScreen.mobileKeyError.title.text]',
                    title: '#4.8.1.1 Text',
                    type: 'boolean',
                  },
                  text: {
                    type: 'string',
                  },
                  'type:enable': {
                    description: '[accessManagement.entryScreen.mobileKeyError.title.type]',
                    title: '#4.8.1.2 Type',
                    type: 'boolean',
                  },
                  type: {
                    enum: ['PLAIN_TEXT', 'HTML'],
                    type: 'string',
                  },
                  'useDefaultStyles:enable': {
                    description:
                      '[accessManagement.entryScreen.mobileKeyError.title.useDefaultStyles]',
                    title: '#4.8.1.3 Use Default Styles',
                    type: 'boolean',
                  },
                  useDefaultStyles: {
                    type: undefined,
                    enum: [true, false],
                  },
                },
              },
              description: {
                title: '#4.8.2 Description',
                type: 'object',
                properties: {
                  'text:enable': {
                    description: '[accessManagement.entryScreen.mobileKeyError.description.text]',
                    title: '#4.8.2.1 Text',
                    type: 'boolean',
                  },
                  text: {
                    type: 'string',
                  },
                  'type:enable': {
                    description: '[accessManagement.entryScreen.mobileKeyError.description.type]',
                    title: '#4.8.2.2 Type',
                    type: 'boolean',
                  },
                  type: {
                    enum: ['PLAIN_TEXT', 'HTML'],
                    type: 'string',
                  },
                  'useDefaultStyles:enable': {
                    description:
                      '[accessManagement.entryScreen.mobileKeyError.description.useDefaultStyles]',
                    title: '#4.8.2.3 Use Default Styles',
                    type: 'boolean',
                  },
                  useDefaultStyles: {
                    type: undefined,
                    enum: [true, false],
                  },
                },
              },
            },
          },
          'locationOffMessage:enable': {
            description: '[accessManagement.entryScreen.locationOffMessage]',
            title: '#4.9 Location Off Message',
            type: 'boolean',
          },
          locationOffMessage: {
            type: 'string',
          },
        },
      },
      mobileKeyError: {
        title: '#5 Mobile Key Error',
        type: 'object',
        properties: {
          title: {
            title: '#5.1 Title',
            type: 'object',
            properties: {
              'text:enable': {
                description: '[accessManagement.mobileKeyError.title.text]',
                title: '#5.1.1 Text',
                type: 'boolean',
              },
              text: {
                type: 'string',
              },
              'type:enable': {
                description: '[accessManagement.mobileKeyError.title.type]',
                title: '#5.1.2 Type',
                type: 'boolean',
              },
              type: {
                enum: ['PLAIN_TEXT', 'HTML'],
                type: 'string',
              },
              'useDefaultStyles:enable': {
                description: '[accessManagement.mobileKeyError.title.useDefaultStyles]',
                title: '#5.1.3 Use Default Styles',
                type: 'boolean',
              },
              useDefaultStyles: {
                type: undefined,
                enum: [true, false],
              },
            },
          },
          description: {
            title: '#5.2 Description',
            type: 'object',
            properties: {
              'text:enable': {
                description: '[accessManagement.mobileKeyError.description.text]',
                title: '#5.2.1 Text',
                type: 'boolean',
              },
              text: {
                type: 'string',
              },
              'type:enable': {
                description: '[accessManagement.mobileKeyError.description.type]',
                title: '#5.2.2 Type',
                type: 'boolean',
              },
              type: {
                enum: ['PLAIN_TEXT', 'HTML'],
                type: 'string',
              },
              'useDefaultStyles:enable': {
                description: '[accessManagement.mobileKeyError.description.useDefaultStyles]',
                title: '#5.2.3 Use Default Styles',
                type: 'boolean',
              },
              useDefaultStyles: {
                type: undefined,
                enum: [true, false],
              },
            },
          },
        },
      },
      tutorial: {
        title: '#6 Tutorial',
        type: 'object',
        properties: {
          'showMeHowNextButton:enable': {
            description: '[accessManagement.tutorial.showMeHowNextButton]',
            title: '#6.1 Show Me How Next Button',
            type: 'boolean',
          },
          showMeHowNextButton: {
            type: 'string',
          },
          'showMeHowFinishButton:enable': {
            description: '[accessManagement.tutorial.showMeHowFinishButton]',
            title: '#6.2 Show Me How Finish Button',
            type: 'boolean',
          },
          showMeHowFinishButton: {
            type: 'string',
          },
          'replayStepsButtonTitle:enable': {
            description: '[accessManagement.tutorial.replayStepsButtonTitle]',
            title: '#6.3 Replay Steps Button Title',
            type: 'boolean',
          },
          replayStepsButtonTitle: {
            type: 'string',
          },
        },
      },
      frontDesk: {
        title: '#7 Front Desk',
        type: 'object',
        properties: {
          'cancel:enable': {
            description: '[accessManagement.frontDesk.cancel]',
            title: '#7.1 Cancel',
            type: 'boolean',
          },
          cancel: {
            type: 'string',
          },
        },
      },
      authentication: {
        title: '#8 Authentication',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[accessManagement.authentication.title]',
            title: '#8.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'description:enable': {
            description: '[accessManagement.authentication.description]',
            title: '#8.2 Description',
            type: 'boolean',
          },
          description: {
            type: 'string',
          },
          'action:enable': {
            description: '[accessManagement.authentication.action]',
            title: '#8.3 Action',
            type: 'boolean',
          },
          action: {
            type: 'string',
          },
          'understand:enable': {
            description: '[accessManagement.authentication.understand]',
            title: '#8.4 Understand',
            type: 'boolean',
          },
          understand: {
            type: 'string',
          },
          'passcodeTitle:enable': {
            description: '[accessManagement.authentication.passcodeTitle]',
            title: '#8.5 Passcode Title',
            type: 'boolean',
          },
          passcodeTitle: {
            type: 'string',
          },
          'passcodeDescription:enable': {
            description: '[accessManagement.authentication.passcodeDescription]',
            title: '#8.6 Passcode Description',
            type: 'boolean',
          },
          passcodeDescription: {
            type: 'string',
          },
          'touchIdTitle:enable': {
            description: '[accessManagement.authentication.touchIdTitle]',
            title: '#8.7 TouchId Title',
            type: 'boolean',
          },
          touchIdTitle: {
            type: 'string',
          },
          'touchIdDescription:enable': {
            description: '[accessManagement.authentication.touchIdDescription]',
            title: '#8.8 TouchId Description',
            type: 'boolean',
          },
          touchIdDescription: {
            type: 'string',
          },
          'faceIdTitle:enable': {
            description: '[accessManagement.authentication.faceIdTitle]',
            title: '#8.9 FaceId Title',
            type: 'boolean',
          },
          faceIdTitle: {
            type: 'string',
          },
          'faceIdDescription:enable': {
            description: '[accessManagement.authentication.faceIdDescription]',
            title: '#8.10 FaceId Description',
            type: 'boolean',
          },
          faceIdDescription: {
            type: 'string',
          },
        },
      },
      'locationName:enable': {
        description: '[accessManagement.locationName]',
        title: '#9 Location name for digital key',
        type: 'boolean',
      },
      locationName: {
        type: 'string',
      },
      'title:enable': {
        description: '[accessManagement.title]',
        title: '#10 Title',
        type: 'boolean',
      },
      title: {
        type: 'string',
      },
    },
  },
};

export const mobileV3LocalizationAccessManagementUiSchema: NodeUiSchemaProperties = {
  accessManagement: {
    digitalKeyTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    locationName: {
      'ui:label': false,
      'ui:readonly': true,
    },
    askUserDeviceChange: {
      newDeviceAlertTitle: {
        'ui:label': false,
        'ui:readonly': true,
      },
      newDeviceAlertText: {
        'ui:label': false,
        'ui:readonly': true,
      },
      cancelText: {
        'ui:label': false,
        'ui:readonly': true,
      },
      confirmText: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    instructions: {
      resourceTitle: {
        title: {
          text: {
            'ui:label': false,
            'ui:readonly': true,
          },
          type: {
            'ui:label': false,
            'ui:readonly': true,
          },
          useDefaultStyles: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'select',
          },
        },
        titleOnUnlocked: {
          text: {
            'ui:label': false,
            'ui:readonly': true,
          },
          type: {
            'ui:label': false,
            'ui:readonly': true,
          },
          useDefaultStyles: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'select',
          },
        },
        titleOnUnlocking: {
          text: {
            'ui:label': false,
            'ui:readonly': true,
          },
          type: {
            'ui:label': false,
            'ui:readonly': true,
          },
          useDefaultStyles: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'select',
          },
        },
        default: {
          'ui:label': false,
          'ui:readonly': true,
        },
        loading: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      resourceMessage: {
        message: {
          text: {
            'ui:label': false,
            'ui:readonly': true,
          },
          type: {
            'ui:label': false,
            'ui:readonly': true,
          },
          useDefaultStyles: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'select',
          },
        },
        messageOnUnlocking: {
          text: {
            'ui:label': false,
            'ui:readonly': true,
          },
          type: {
            'ui:label': false,
            'ui:readonly': true,
          },
          useDefaultStyles: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'select',
          },
        },
        messageOnUnlocked: {
          text: {
            'ui:label': false,
            'ui:readonly': true,
          },
          type: {
            'ui:label': false,
            'ui:readonly': true,
          },
          useDefaultStyles: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'select',
          },
        },
        loading: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      errorMessage: {
        title: {
          text: {
            'ui:label': false,
            'ui:readonly': true,
          },
          type: {
            'ui:label': false,
            'ui:readonly': true,
          },
          useDefaultStyles: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'select',
          },
        },
        description: {
          text: {
            'ui:label': false,
            'ui:readonly': true,
          },
          type: {
            'ui:label': false,
            'ui:readonly': true,
          },
          useDefaultStyles: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'select',
          },
        },
      },
      frontDeskStateError: {
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
    },
    entryScreen: {
      openLockerButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
      openLockerButtonTryAgain: {
        'ui:label': false,
        'ui:readonly': true,
      },
      openLockerCancelButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
      callFrontDeskButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
      bluetoothOffMessage: {
        'ui:label': false,
        'ui:readonly': true,
      },
      locationOffMessage: {
        'ui:label': false,
        'ui:readonly': true,
      },
      unlockedMessage: {
        text: {
          'ui:label': false,
          'ui:readonly': true,
        },
        type: {
          'ui:label': false,
          'ui:readonly': true,
        },
        useDefaultStyles: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'select',
        },
      },
      lockedMessage: {
        text: {
          'ui:label': false,
          'ui:readonly': true,
        },
        type: {
          'ui:label': false,
          'ui:readonly': true,
        },
        useDefaultStyles: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'select',
        },
      },
      mobileKeyError: {
        title: {
          text: {
            'ui:label': false,
            'ui:readonly': true,
          },
          type: {
            'ui:label': false,
            'ui:readonly': true,
          },
          useDefaultStyles: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'select',
          },
        },
        description: {
          text: {
            'ui:label': false,
            'ui:readonly': true,
          },
          type: {
            'ui:label': false,
            'ui:readonly': true,
          },
          useDefaultStyles: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'select',
          },
        },
      },
    },
    mobileKeyError: {
      title: {
        text: {
          'ui:label': false,
          'ui:readonly': true,
        },
        type: {
          'ui:label': false,
          'ui:readonly': true,
        },
        useDefaultStyles: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'select',
        },
      },
      description: {
        text: {
          'ui:label': false,
          'ui:readonly': true,
        },
        type: {
          'ui:label': false,
          'ui:readonly': true,
        },
        useDefaultStyles: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'select',
        },
      },
    },
    tutorial: {
      showMeHowNextButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
      showMeHowFinishButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
      replayStepsButtonTitle: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    frontDesk: {
      cancel: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    authentication: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
      action: {
        'ui:label': false,
        'ui:readonly': true,
      },
      understand: {
        'ui:label': false,
        'ui:readonly': true,
      },
      passcodeTitle: {
        'ui:label': false,
        'ui:readonly': true,
      },
      passcodeDescription: {
        'ui:label': false,
        'ui:readonly': true,
      },
      touchIdTitle: {
        'ui:label': false,
        'ui:readonly': true,
      },
      touchIdDescription: {
        'ui:label': false,
        'ui:readonly': true,
      },
      faceIdTitle: {
        'ui:label': false,
        'ui:readonly': true,
      },
      faceIdDescription: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
};
