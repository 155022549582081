import { RGB } from '../../../theme/colors';
import fontSizes from '../../../theme/fontSizes';

export const inputGroupLabelColor = `rgba(${RGB.comet}, .5)`;

const InputGroupLabelStyle = `
  color: ${inputGroupLabelColor};
  font-size: ${fontSizes.bodyLarge};
  font-weight: bold;
  margin: 0 0 8px;
`;

export default InputGroupLabelStyle;
