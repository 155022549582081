import { gql } from '../__generated__/gql';

const GET_UNIT_TYPE_COUNT = gql(`
  query GetUnitTypeCount($where: UnitTypeWhereInput!) {
    aggregateUnitType(where: $where) {
      _count {
        _all
      }
    }
  }
`);

export default GET_UNIT_TYPE_COUNT;
