const NavContainerStyle = `
  grid-row-gap: 35px;
  margin: 10px 0 0;

  svg {
    height: 22px;
    width: 22px;
  }

  @media only screen and (max-width: 1200px), 
              screen and (max-height: 900px) {
    button {
      border-radius: 6px;
      height: 25px;
      width: 25px;
    }

    svg {
      height: 12px;
      width: 12px
    }
  }
`;

export default NavContainerStyle;
