import { Header } from '../../../components/DataTable/@types/DataTableProps';
import SortType from '../../../components/DataTable/@types/SortType.enum';

const KIOSKS_TABLE_HEADERS: Header[] = [
  { displayValue: 'Name', isSortable: true, sortType: SortType.SortOrder, value: 'name' },
  { displayValue: 'Location', isSortable: false, value: 'location.name' },
  { displayValue: 'Status', isSortable: false },
  { displayValue: 'Payment Terminal', isSortable: false },
  { displayValue: 'Dispenser', isSortable: false },
  { displayValue: 'Build Number', isSortable: false },
  { displayValue: 'App Version', isSortable: false },
];

export default KIOSKS_TABLE_HEADERS;
