import { Container, Form } from '../styles';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { GracePeriod, GracePeriodValues } from '../../../__generated__/graphql';
import React, { useCallback, useEffect, useMemo } from 'react';
import { TimeControlCategory, TimeControlType } from '../enums';

import { Bars } from 'svg-loaders-react';
import { Colors } from '../../../theme/colors';
import CustomSectionTitle from './CustomSectionTitle';
import { GRACE_PERIODS_VALIDATION_SCHEMA } from '../constants/gracePeriodValidationSchema';
import GracePeriodsController from './GracePeriodsController';
import { SubmitButton } from '../styles/GracePeriodSection.styles';
import { cloneDeep } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';

interface GracePeriodSectionProps {
  title: string;
  description: string[];
  category: TimeControlCategory;
  loading: boolean;
  gracePeriods: GracePeriod | undefined;
  setGracePeriodsConfigCallback: (gracePeriodsData: GracePeriod) => void;
  isSubmitting: boolean;
}

const GracePeriodSection: React.FC<GracePeriodSectionProps> = ({
  title,
  category,
  description,
  gracePeriods,
  loading,
  isSubmitting,
  setGracePeriodsConfigCallback,
}: GracePeriodSectionProps) => {
  const form = useForm<GracePeriodValues>({
    defaultValues: {},
    mode: 'onTouched',
    resolver: yupResolver(GRACE_PERIODS_VALIDATION_SCHEMA),
    reValidateMode: 'onChange',
  });
  const { reset, trigger } = form;
  const isGuestRoomCategory = category === TimeControlCategory.GUEST_ROOM;

  const gracePeriodValues = useMemo(() => {
    return isGuestRoomCategory ? gracePeriods?.rooms : gracePeriods?.commonAreas;
  }, [gracePeriods, isGuestRoomCategory]);

  const resetValues = useMemo(() => cloneDeep(gracePeriodValues), [gracePeriodValues]);

  const getNewGracePeriodsObject = useCallback(
    (data: GracePeriodValues) => {
      if (gracePeriods?.rooms && gracePeriods?.commonAreas) {
        const newGracePeriods = cloneDeep(gracePeriods);

        if (isGuestRoomCategory) {
          newGracePeriods.rooms = data;
        } else {
          newGracePeriods.commonAreas = data;
        }

        return newGracePeriods;
      }

      return gracePeriods;
    },
    [gracePeriods, isGuestRoomCategory]
  );

  const onSubmit: SubmitHandler<GracePeriodValues> = (data): void => {
    const newGracePeriods = getNewGracePeriodsObject(data);

    if (newGracePeriods) {
      setGracePeriodsConfigCallback(newGracePeriods);
    }
  };

  useEffect(() => {
    if (gracePeriodValues) {
      reset(gracePeriodValues);
      trigger();
    }
  }, [gracePeriodValues, reset, trigger]);

  return (
    <Container>
      <CustomSectionTitle title={title} description={description} smallerTitle />
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          <GracePeriodsController
            controlType={TimeControlType.CHECK_IN}
            controlCategory={category}
            resetValues={resetValues}
            currentGracePeriodValues={gracePeriodValues}
            loading={loading}
          />
          <GracePeriodsController
            controlType={TimeControlType.CHECK_OUT}
            controlCategory={category}
            resetValues={resetValues}
            currentGracePeriodValues={gracePeriodValues}
            loading={loading}
          />
          <SubmitButton type="submit" disabled={isSubmitting} isSubmitting={isSubmitting}>
            {isSubmitting ? (
              <Bars
                fill={Colors.shuttleGrey}
                height="25px"
                stroke={Colors.shuttleGrey}
                width="25px"
              />
            ) : (
              'Save changes'
            )}
          </SubmitButton>
        </Form>
      </FormProvider>
    </Container>
  );
};

export default GracePeriodSection;
