import {
  kioskLocalizationSchema,
  kioskLocalizationUiSchema,
} from '../../../../../components/JsonEditor/constants/LocalizationSchema/kioskLocalizationSchema';

import CustomizeLanguageLocalization from '../../../../../components/CustomizeLocalization/components/CustomizeLanguageLocalization';
import { CustomizeThemeLocalizationEntityLevel } from '../../../../../enums/CustomizeThemeLocalizationEntityLevel';
import { LocalizationComponentName } from '../../../../../enums/ComponentNameEnum';
import React from 'react';
import { useParams } from 'react-router-dom';

const CustomizeKioskLocalization: React.FC<{}> = () => {
  const { tenantId } = useParams<{ tenantId: string }>();

  return (
    <CustomizeLanguageLocalization
      title={'Customize kiosk localization'}
      componentName={LocalizationComponentName.KIOSK}
      schema={kioskLocalizationSchema}
      uiSchema={kioskLocalizationUiSchema}
      fileNameToDownloadDefaultJson={`Default kiosk localization - Tenant ${tenantId} - Language \${LANGUAGE_NAME}.json`}
      fileNameToDownloadCurrentJson={`Current kiosk localization - Tenant ${tenantId} - Language \${LANGUAGE_NAME}.json`}
      entityLevel={CustomizeThemeLocalizationEntityLevel.TENANT}
      entityId={tenantId}
    />
  );
};

export default CustomizeKioskLocalization;
