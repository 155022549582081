import { ActionMeta, OptionsType } from 'react-select';

import { Role } from '../../../__generated__/graphql';
import SelectOption from '../../../@types/react/SelectOption';
import UserFormMembershipMap from '../@types/UserFormMembershipMap';
import { useFormContext } from 'react-hook-form';

function isSelectOption(option: SelectOption | OptionsType<SelectOption>): option is SelectOption {
  return Boolean((option as SelectOption).value);
}

export default function useAdjustMembershipsMap(): {
  adjustMembershipRole: (option: SelectOption | OptionsType<SelectOption> | null) => void;
  adjustMembershipsMap: (
    option: SelectOption | OptionsType<SelectOption> | null,
    action: ActionMeta<SelectOption>
  ) => void;
} {
  const { getValues, setValue } = useFormContext();
  const MAP_NAME = 'locations.map';

  return {
    adjustMembershipRole(option: SelectOption | OptionsType<SelectOption> | null): void {
      if (option && isSelectOption(option)) {
        const membershipsMap: UserFormMembershipMap = getValues(MAP_NAME);
        const [role, locationId] = option.value.split('.');
        const currentMembership = membershipsMap[locationId];

        setValue(MAP_NAME, {
          ...membershipsMap,
          [locationId]: {
            ...currentMembership,
            ...(currentMembership.membershipId && { shouldUpdate: true }),
            withRole: role,
          },
        });
      }
    },
    adjustMembershipsMap(
      option: SelectOption | OptionsType<SelectOption> | null,
      action: ActionMeta<SelectOption>
    ): void {
      const currentMap: UserFormMembershipMap = getValues(MAP_NAME);

      if (action.action === 'select-option') {
        const currentOption = currentMap[action.option?.value ?? ''];

        if (currentOption && currentOption.membershipId) {
          setValue(MAP_NAME, {
            ...currentMap,
            [currentOption.option.value]: {
              ...currentOption,
              shouldDelete: false,
            },
          });
        } else if (currentOption) {
          setValue(MAP_NAME, {
            ...currentMap,
            [currentOption.option.value]: {
              ...currentOption,
              shouldCreate: true,
            },
          });
        } else if (action.option?.value) {
          setValue(MAP_NAME, {
            ...currentMap,
            [action.option.value]: {
              option: action.option,
              shouldCreate: true,
              shouldDelete: false,
              withRole: Role.User,
            },
          });
        }
      } else if (action.action === 'remove-value') {
        const currentOption = currentMap[action.removedValue.value];

        if (!currentOption.shouldCreate) {
          setValue(MAP_NAME, {
            ...currentMap,
            [currentOption.option.value]: {
              ...currentOption,
              shouldDelete: true,
            },
          });
        } else {
          setValue(MAP_NAME, {
            ...currentMap,
            [currentOption.option.value]: {
              ...currentOption,
              shouldCreate: false,
            },
          });
        }
      } else if (action.action === 'pop-value') {
        const { removedValue } = action;

        if (removedValue) {
          const currentOption = currentMap[removedValue.value];

          if (!currentOption.shouldCreate) {
            setValue(MAP_NAME, {
              ...currentMap,
              [removedValue.value]: {
                ...currentOption,
                shouldDelete: true,
              },
            });
          } else {
            setValue(MAP_NAME, {
              ...currentMap,
              [removedValue.value]: {
                ...currentOption,
                shouldCreate: false,
              },
            });
          }
        }
      }
    },
  };
}
