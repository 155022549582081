import Filter from '../../../../components/DataTable/@types/Filter';
import { Role } from '../../../../__generated__/graphql';
import capitalize from 'lodash/capitalize';

function addRoleFilters(filters: Filter[]): Filter[] {
  Object.keys(Role).forEach((role) => {
    filters.push({
      displayKey: 'Role',
      displayValue: capitalize(Role[role as keyof typeof Role]),
      key: 'role',
      value: Role[role as keyof typeof Role],
    });
  });

  return filters;
}

export default function useUserFilters(): Filter[] {
  const filters: Filter[] = [];

  addRoleFilters(filters);

  return filters;
}
