import 'styled-components/macro';

import Checkbox, { CheckboxModel } from '../Checkbox';
import { FieldError, useFormContext } from 'react-hook-form';
import TextArea, { TextAreaModel } from '../TextArea';
import TextContent, { TextContentModel } from '../TextContent';

import AsyncSelectInput from '../AsyncSelectInput';
import { Colors } from '../../theme/colors';
import Container from '../Container';
import DateInput from '../DateInput';
import FileInput from '../FileInput';
import InputGroupLabelStyle from './styles/InputGroupLabel.style';
import InputGroupProps from './@types/InputGroupProps';
import Label from '../Label';
import PhoneInput from '../PhoneInput';
import React from 'react';
import SearchInputWithResults from '../SearchInputWithResults';
import SelectInput from '../SelectInput';
import SelectOption from '../../@types/react/SelectOption';
import TextInput from '../TextInput';
import UrlInput from '../UrlInput';
import get from 'lodash/get';

function isTextInput(type: string): type is 'email' | 'password' | 'text' {
  return ['email', 'password', 'text'].includes(type);
}

function isNumberInput(type: string): type is 'number' {
  return ['number'].includes(type);
}

/* InputGroup component must live within a FormProvider or will fail */
function InputGroup({
  columnSpan = 1,
  isEditable,
  isFieldArray = false,
  isMulti = false,
  label = '',
  onChange,
  onSelect,
  loadOptions = () => (): Promise<SelectOption[]> => new Promise((resolve) => resolve([])),
  name,
  options,
  query,
  required = false,
  rules,
  matchField,
  min,
  pattern,
  shouldRegister = true,
  timezone,
  type = 'text',
  accept,
  style,
  maxFileSize,
  inputMode,
  step,
  ...props
}: InputGroupProps): React.ReactElement {
  const { errors, formState, setValue, getValues, register, trigger } = useFormContext();
  const [fieldName] = name.split(/[/]+/);

  function getError(): FieldError | undefined {
    if (type === 'async-select') {
      return get(errors, `${name}.value`);
    }

    return get(errors, fieldName);
  }

  const error: FieldError | undefined = getError();
  const currentValue = !['date', 'search'].includes(type) ? getValues(name) : undefined;
  const loadOptionsFn = loadOptions();

  function handleCheckboxClick(): void {
    setValue(name, !getValues(name));
  }

  function handleChangeTextArea(value: string): void {
    setValue(name, value);
  }

  function handleInvalidNumber(e: React.KeyboardEvent<HTMLDivElement>): void {
    // eslint-disable-next-line no-restricted-globals
    if (type === 'number' && inputMode !== 'decimal' && isNaN(Number(e.key))) {
      e.preventDefault();
    }
  }

  return (
    <Container
      style={style}
      css={`
        grid-column: span ${columnSpan};
      `}
    >
      {label && <Label css={InputGroupLabelStyle}>{label}</Label>}
      {type === 'async-select' && (
        <AsyncSelectInput
          className={error ? 'error' : ''}
          isEditable={isEditable}
          isMulti={isMulti}
          loadOptions={loadOptionsFn}
          name={name}
          onChange={onSelect}
          required={required}
        />
      )}
      {type === 'date' && (
        <DateInput
          className={error ? 'error' : ''}
          name={name}
          required={required}
          timezone={timezone}
        />
      )}
      {isTextInput(type) && (
        <TextInput
          className={error ? 'error' : ''}
          defaultValue={currentValue}
          model="DEFAULT"
          name={name}
          onBlur={(): void => {
            if (currentValue !== undefined && String(currentValue)) {
              setValue(name, String(currentValue).trim());
            }
          }}
          onChange={(
            event: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>
          ): void => {
            if (onChange) {
              onChange(event);
            }
            if (matchField && formState.touched[matchField]) {
              trigger([name, matchField]);
            }
          }}
          pattern={pattern}
          ref={register({ required })}
          type={type}
          {...(shouldRegister && { ref: register({ required }) })}
          {...props}
        />
      )}
      {isNumberInput(type) && (
        <TextInput
          className={error ? 'error' : ''}
          defaultValue={currentValue}
          model="DEFAULT"
          min={min}
          name={name}
          onKeyPress={handleInvalidNumber}
          onChange={(
            event: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>
          ): void => {
            if (onChange) {
              onChange(event);
            }
            if (matchField && formState.touched[matchField]) {
              trigger([name, matchField]);
            }
          }}
          pattern={pattern}
          ref={register({ required })}
          type={'number'}
          step={step}
          {...(inputMode === 'decimal' && { inputMode, step: step ?? 0.1 })}
          {...(shouldRegister && { ref: register({ required }) })}
          {...props}
        />
      )}
      {type === 'select' && options && (
        <SelectInput
          className={error ? 'error' : ''}
          isFieldArray={isFieldArray}
          name={name}
          options={options}
          {...props}
        />
      )}
      {type === 'phone' && (
        <PhoneInput
          className={error ? 'error' : ''}
          name={name}
          onChange={(value: string): void => {
            if (onChange) {
              onChange(value);
            }
          }}
          required={required}
        />
      )}
      {type === 'file' && (
        <FileInput
          className={error ? 'error' : ''}
          onChange={(e): void => {
            setValue(name, e);
          }}
          name={name}
          required={required}
          accept={accept}
          maxFileSize={maxFileSize}
        />
      )}
      {type === 'checkbox' && (
        <Checkbox
          checked={getValues(name)}
          model={CheckboxModel.TABLE_DARK}
          onClick={handleCheckboxClick}
        />
      )}
      {type === 'textarea' && (
        <TextArea
          className={error ? 'error' : ''}
          value={getValues(name) ?? ''}
          minRows={3}
          maxRows={6}
          model={TextAreaModel.DEFAULT}
          onChange={(event): void => handleChangeTextArea(event.currentTarget.value)}
        />
      )}
      {type === 'search' && query && (
        <SearchInputWithResults
          className={error ? 'error' : ''}
          name={name}
          query={query}
          required={required}
          {...props}
        />
      )}
      {type === 'url' && (
        <UrlInput
          className={error ? 'error' : ''}
          {...(onChange && { onChange })}
          defaultValue={currentValue}
          model="DEFAULT"
          name={name}
          onChange={(): void => {
            if (matchField && formState.touched[matchField]) {
              trigger([name, matchField]);
            }
          }}
          {...props}
        />
      )}

      {error && (
        <TextContent
          color={Colors.salmon}
          margin="5px 0 0"
          model={TextContentModel.BODY_SMALL}
          role="alert"
        >
          {error.message}
        </TextContent>
      )}
    </Container>
  );
}

export default InputGroup;
