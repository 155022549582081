import { Colors, RGB } from '../../../theme/colors';

import { CSSPropertyMap } from '../../../@types/common';

const DefaultTextInputStyle: {
  default: CSSPropertyMap;
  breakpoints: {
    [breakpoint: string]: Partial<CSSPropertyMap> | undefined;
  };
} = {
  breakpoints: {},
  default: {
    active: {
      background: `rgba(${RGB.linkWater}, .2)`,
      borderColor: Colors.mountainMeadow,
      color: Colors.blackRussian,
    },
    background: `rgba(${RGB.linkWater}, .2)`,
    borderColor: `rgba(${RGB.linkWater}, .2)`,
    borderRadius: '8px',
    color: Colors.blackRussian,
    fontWeight: 'bold',
    width: '100%',
  },
};

export default DefaultTextInputStyle;
