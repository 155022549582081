import { useCallback, useState } from 'react';
import QueryHookParams from './@types/QueryHookParams';
import QueryHookProps from './@types/QueryHookProps';
import { useLazyQuery } from '@apollo/client';

export default function useGetMany<Data, Args>({
  fetchPolicy,
  graphqlQuery,
  onCompleted,
  onError,
}: QueryHookParams): QueryHookProps<Data, Args> {
  const [offset, setOffset] = useState<number>(0);

  const [get, { called, data, error, loading }] = useLazyQuery<Data, Args>(graphqlQuery!, {
    fetchPolicy,
    onCompleted,
    onError,
  });

  const query = useCallback(
    (args?: Args) => {
      get({
        variables: args,
      });
    },
    [get]
  );

  return {
    called,
    data,
    error,
    loading,
    offset,
    query,
    setOffset,
  };
}
