import { DELETE_RESOURCE_GROUP } from '../graphql/deleteResourceGroup';
import { Mutation } from '../__generated__/graphql';
import RemoveHookData from './@types/RemoveHookData';
import { closeRemoveModal } from '../redux/actions/modals.actions';
import { flashApolloError } from '../util/errorUtils';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';

type DeleteResourceGroupData = Pick<Mutation, 'deleteResourceGroup'>;

export default function useRemoveResourceGroup(): RemoveHookData {
  const dispatch = useDispatch();

  const [remove, { error, loading }] = useMutation<DeleteResourceGroupData, { id: string }>(
    DELETE_RESOURCE_GROUP,
    {
      onCompleted: ({ deleteResourceGroup }) => {
        dispatch(closeRemoveModal());

        window.flash({
          message: `Resource Group: ${deleteResourceGroup.name} removed successfully`,
        });
      },
      onError: flashApolloError,
    }
  );

  return {
    error,
    loading,
    mutation: (id: string): void => {
      remove({
        variables: { id },
      });
    },
  };
}
