import React, { useLayoutEffect, useRef, useState } from 'react';

import { RootState } from '../../../redux/reducers';
import { useSelector } from 'react-redux';

const ExternalReportingTab: React.FC<{}> = () => {
  const dashboardPreferences = useSelector((state: RootState) => state.dashboardPreferences);

  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>();
  const [width, setWidth] = useState<number>();

  const loadDivSize = (): void => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
      setWidth(ref.current.clientWidth);
    }
  };

  useLayoutEffect(() => {
    loadDivSize();
  }, []);

  if (!dashboardPreferences?.reportingServices) {
    return <></>;
  }

  return (
    <div className="content" ref={ref} style={{ display: 'flex', flex: 1 }} onLoad={loadDivSize}>
      <iframe
        style={{
          height: height ?? 720,
          width: width && width > 0 ? width * 0.85 : 960,
        }}
        src={dashboardPreferences.reportingServices.reportUrl}
      ></iframe>
    </div>
  );
};

export default ExternalReportingTab;
