import 'styled-components/macro';

import Heading, { HeadingModel } from '../Heading';
import { MdPhone, MdPhoneMissed } from 'react-icons/md';
import React, { memo, useState } from 'react';

import Avatar from 'react-avatar';
import AvatarContainerStyle from './styles/AvatarContainer.style';
import CallButton from './components/CallButton';
import CallButtonsContainerStyle from './styles/CallButtonsContainer.style';
import CallerTextStyle from './styles/CallerText.style';
import { Colors } from '../../theme/colors';
import Container from '../Container';
import DatesInfo from './components/DatesInfo';
import IncomingCallContainerStyle from './styles/IncomingCallContainer.style';
import IncomingCallRedux from '../../redux/reducers/@types/IncomingCallRedux';
import KioskInfo from './components/KioskInfo';
import LocationInfo from './components/LocationInfo';
import { RootState } from '../../redux/reducers';
import TextContent from '../TextContent';
import callActiveMessage from './constants/callActiveMessage';
import { flashApolloError } from '../../util/errorUtils';
import random from 'lodash/random';
import unauthenticatedUserName from './constants/unauthenticatedUserName';
import useJoinVideo from '../../hooks/useJoinVideo';
import { useSelector } from 'react-redux';

interface IncomingCallModalProps {
  close: (callRecordId: string) => void;
  incomingCall: IncomingCallRedux;
}

const IncomingCallModal: React.FC<IncomingCallModalProps> = ({
  close,
  incomingCall,
}: IncomingCallModalProps) => {
  const activeCall = useSelector((state: RootState) => state.remoteAssistance.activeCall);

  const [avatarColor] = useState<string>(
    `rgba(${random(0, 255)}, ${random(75, 200)}, ${random(75, 200)})`
  );

  const { room, caller, accessGrant, location, kiosk } = incomingCall;

  const { joinVideo, loading: loadingJoinVideo } = useJoinVideo({
    incomingCall,
    onCompleted: () => {
      close(incomingCall.callRecordId);
    },
    onError: flashApolloError,
    room,
  });

  const answerCall = (): void => {
    joinVideo();
  };

  const ignoreCall = (): void => {
    close(incomingCall.callRecordId);
  };

  return (
    <Container style={IncomingCallContainerStyle}>
      {incomingCall && (
        <>
          <div>
            <Container css={AvatarContainerStyle}>
              <Avatar
                color={avatarColor}
                name={caller?.name ?? unauthenticatedUserName}
                size="120"
                round
              />
            </Container>
            <Container>
              <Heading color={Colors.white} css={CallerTextStyle} model={HeadingModel.SECONDARY}>
                {caller?.name ?? unauthenticatedUserName}
              </Heading>
              {location && <LocationInfo locationName={location.name} />}
              {kiosk && <KioskInfo kioskName={kiosk.name} />}
              {accessGrant && <DatesInfo {...accessGrant} />}
            </Container>
          </div>
          <div>
            <Container css={CallButtonsContainerStyle}>
              <CallButton
                color={Colors.turquoiseBlue}
                onClick={answerCall}
                isLoading={loadingJoinVideo}
                isEnabled={!activeCall}
              >
                <MdPhone size="35px" />
              </CallButton>
              <CallButton color={Colors.neonPink} onClick={ignoreCall} isLoading={loadingJoinVideo}>
                <MdPhoneMissed size="35px" />
              </CallButton>
            </Container>
            {activeCall && <TextContent css="color: white;">{callActiveMessage}</TextContent>}
          </div>
        </>
      )}
    </Container>
  );
};

export default memo(IncomingCallModal);
