import { gql } from '../__generated__/gql';

const GET_AMENITY_COUNT = gql(`
  query GetAmenityCount($where: AmenityWhereInput!) {
    aggregateAmenity(where: $where) {
      _count {
        _all
      }
    }
  }
`);

export default GET_AMENITY_COUNT;
