import iterateInJson from './iterateInJson';

export default function jsonGetValueFromKey(
  data: Record<string, unknown>,
  findKey: string
): unknown {
  let value;

  if (data) {
    iterateInJson(data, (_data, parentKey, key): boolean => {
      const mainKey = `${parentKey}.${key}`;

      if (findKey === mainKey) {
        value = _data[key];
        return true;
      }

      return false;
    });
  }

  return value;
}
