import 'styled-components/macro';

import { useDispatch, useSelector } from 'react-redux';

import AppHeaderStyle from './styles/AppHeader.style';
import Container from '../Container';
import MembershipContainerStyle from './styles/MembershipContainer.style';
import React from 'react';
import { RootState } from '../../redux/reducers';
import Select from 'react-select';
import SelectOption from '../../@types/react/SelectOption';
import generateMembershipOptions from './functions/generateMembershipOptions';
import { selectMembership } from '../../redux/actions/me.actions';
import useAccessMembership from '../../hooks/useAccessMembership';
import useLoadDashboardPreferences from '../../hooks/useLoadDashboardPreferences';

const AppHeader: React.FC = () => {
  const { activeMembership, id: userId } = useSelector((state: RootState) => state.me);
  const dispatch = useDispatch();
  const memberships = useSelector((state: RootState) => state.me.memberships);
  const { query: accessMembership } = useAccessMembership();
  const { loadDashboardPreferences } = useLoadDashboardPreferences();

  const defaultValue = {
    label: activeMembership.location?.name ?? activeMembership.tenant.name,
    value: activeMembership.id,
  };

  const handleSelect = (option: SelectOption | null): void => {
    if (option?.value) {
      const membershipId = option?.value;
      accessMembership(membershipId);
      dispatch(selectMembership(membershipId));

      const selectedMembership = memberships.find((membership) => membership.id === membershipId);
      if (selectedMembership && userId) {
        loadDashboardPreferences({
          locationId: selectedMembership.location?.id,
          tenantId: selectedMembership.tenantId,
          userId,
        });
      }
    }
  };

  return (
    <Container alignContent="center" css={AppHeaderStyle}>
      <Container css={MembershipContainerStyle}>
        <Select
          onChange={handleSelect}
          isClearable={false}
          isSearchable={false}
          options={generateMembershipOptions(memberships)}
          value={defaultValue}
        />
      </Container>
    </Container>
  );
};

export default AppHeader;
