import { gql } from '../__generated__/gql';

const GET_RESOURCE_COUNT = gql(`
  query GetResourceCount($where: ResourceWhereInput!) {
    aggregateResource(where: $where) {
      _count {
        _all
      }
    }
  }
`);

export default GET_RESOURCE_COUNT;
