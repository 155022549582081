import { ApolloError, useLazyQuery } from '@apollo/client';
import { loadMe, login } from '../redux/actions/me.actions';
import { useDispatch, useSelector } from 'react-redux';

import ME from '../graphql/me';
import { Query } from '../__generated__/graphql';
import { RootState } from '../redux/reducers';
import { flashApolloError } from '../util/errorUtils';
import isTokenExpired from '../util/isTokenExpired';
import useAccessMembership from './useAccessMembership';
import useLoadDashboardPreferences from './useLoadDashboardPreferences';
import useLogoutOfApp from './useLogoutOfApp';

interface UseMeInterface {
  called: boolean;
  getMe: () => void;
  loading: boolean;
}

type MeQueryData = Pick<Query, 'meWithUserRolePermissions'>;

export default function useMe(): UseMeInterface {
  const dispatch = useDispatch();
  const loggedIn = useSelector<RootState>((state) => state.me.initialized);
  const { query: accessMembership } = useAccessMembership();
  const logout = useLogoutOfApp();

  const { loadDashboardPreferences } = useLoadDashboardPreferences();

  const [getMe, { called, error, loading }] = useLazyQuery<MeQueryData>(ME, {
    onCompleted: ({ meWithUserRolePermissions }) => {
      const { id: userId, activeMembership } = meWithUserRolePermissions;

      if (activeMembership) {
        accessMembership(activeMembership.id);
        loadDashboardPreferences({
          locationId: activeMembership.location?.id,
          tenantId: activeMembership.tenantId,
          userId,
        });

        dispatch(
          login({
            ...meWithUserRolePermissions,
            activeMembership: meWithUserRolePermissions.activeMembership!,
          })
        );
      }
    },
    onError: (e: ApolloError) => {
      flashApolloError(e);
      logout();
    },
  });

  return {
    called,
    getMe: (): void => {
      const tokenExpired = isTokenExpired();

      if (!error && !tokenExpired && !loggedIn && !loading) {
        dispatch(loadMe());
        getMe();
      }
    },
    loading,
  };
}
