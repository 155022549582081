import { cloneDeep, isEmpty } from 'lodash';

import iterateInJson from './iterateInJson';
import jsonGetObjectFromKey from './jsonGetObjectFromKey';

export default function getNotMappedProperties(
  propertiesRemoved: Record<string, unknown>,
  uiSchema: Record<string, unknown>
): Record<string, unknown> {
  const properties = cloneDeep(propertiesRemoved);

  iterateInJson(properties, (data, _parentKey, key, parentKeys): boolean => {
    let parentKey = _parentKey;

    if (parentKey[0] === '.') {
      parentKey = parentKey.substring(1);
    }

    let currentUiSchema: Record<string, unknown> = uiSchema;
    for (let parentKeyCount = 0; parentKeyCount < parentKeys.length; parentKeyCount += 1) {
      const parentKeyValue = parentKeys[parentKeyCount];
      if (currentUiSchema[parentKeyValue]) {
        currentUiSchema = currentUiSchema[parentKeyValue] as Record<string, unknown>;
      }
    }

    const schemaData = jsonGetObjectFromKey(currentUiSchema as Record<string, unknown>, key);
    if (!isEmpty(schemaData)) {
      // eslint-disable-next-line no-param-reassign
      delete data[key];
    }

    return false;
  });

  return properties;
}
