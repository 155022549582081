import { QueryMode, StringFilter } from '../../__generated__/graphql';

export default function createStringFilter(
  value: string,
  options?: {
    method: keyof StringFilter;
    mode: QueryMode.Default | QueryMode.Insensitive;
  }
): StringFilter {
  return {
    [options?.method ?? 'contains']: value,
    mode: options?.mode ?? QueryMode.Insensitive,
  };
}
