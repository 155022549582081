import {
  clearActiveCall,
  removeIncomingCall,
  setIncomingCall,
  setJoinerChallenge,
} from '../redux/actions/remoteAssistance.actions';
import { closeModal, openModal } from '../redux/actions/modals.actions';
import { ring, ringOnceAndLower, stopRinging } from '../modules/Ringing';
import { useDispatch, useSelector } from 'react-redux';

import IncomingCallRedux from '../redux/reducers/@types/IncomingCallRedux';
import { RootState } from '../redux/reducers';
import { channelsClient } from '../util/pusher';
import { generateRealtimeNotificationsChannelName } from '../util/realTimeNotificationUtils';

const { REACT_APP_VIRDEE_ENV = 'local' } = process.env;

type AnsweredCallEventType = {
  id: string;
  joinerChallenge: string;
};

const incomingVideoEventName = 'incoming-video';
const incomingVoiceEventName = 'incoming-voice';
const endCurrentCallEventName = 'end-current-call';
const answeredCallEventName = 'answered-call';

export default function useBindRAChannels(): void {
  const dispatch = useDispatch();

  const remoteAssistance = useSelector((store: RootState) => store.remoteAssistance);
  const activeMembership = useSelector((store: RootState) => store.me.activeMembership);

  const tenantId = activeMembership?.tenantId;

  if (tenantId) {
    const CHANNEL_NAME = generateRealtimeNotificationsChannelName(tenantId);
    const existingChannel = channelsClient.channel(CHANNEL_NAME);
    const channel = existingChannel || channelsClient.subscribe(CHANNEL_NAME);
    channel.unbind(incomingVideoEventName);
    channel.unbind(incomingVoiceEventName);
    channel.unbind(endCurrentCallEventName);
    channel.unbind(answeredCallEventName);

    if (['local', 'dev'].includes(REACT_APP_VIRDEE_ENV)) {
      // eslint-disable-next-line no-console
      console.log(`RA Channel: `, CHANNEL_NAME);
    }

    const incomingCallHandler = (incomingCall: IncomingCallRedux): void => {
      if (remoteAssistance.activeCall) {
        ringOnceAndLower();
      } else {
        ring();
      }
      dispatch(setIncomingCall(incomingCall));
      dispatch(openModal('incomingCall'));
    };

    channel.bind(incomingVideoEventName, incomingCallHandler);

    channel.bind(incomingVoiceEventName, incomingCallHandler);

    const callbackToEndCall = ({ id }: { id: string }): void => {
      const isRingingCall = remoteAssistance.incomingCalls.find(
        (_incomingCall) => _incomingCall.callRecordId === id
      );
      const isActiveCall = id === remoteAssistance.activeCall?.callRecordId;

      // Call is ringing
      if (isRingingCall) {
        dispatch(removeIncomingCall(isRingingCall.callRecordId));

        // Verify if is the last incoming call
        if (remoteAssistance.incomingCalls.length === 1) {
          stopRinging();
          dispatch(closeModal('incomingCall'));
        }
      }

      // Call is active
      if (isActiveCall) {
        dispatch(clearActiveCall());
      }
    };

    channel.bind(endCurrentCallEventName, callbackToEndCall);

    channel.bind(
      answeredCallEventName,
      ({ id: incomingCallId, joinerChallenge }: AnsweredCallEventType): void => {
        // Someone else has answered the call and needs to stop ring here
        if (remoteAssistance.joinerChallenge !== joinerChallenge) {
          callbackToEndCall({ id: incomingCallId });
        }

        dispatch(setJoinerChallenge(undefined));
      }
    );
  }
}
