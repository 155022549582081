import { gql } from '../__generated__/gql';

const GET_ACCESS_GRANT_COUNT = gql(`
  query GetAccessGrantCount($where: AccessGrantWhereInput!) {
    aggregateDashboardAccessGrants(where: $where) {
      _count {
        _all
      }
    }
  }
`);

export default GET_ACCESS_GRANT_COUNT;
