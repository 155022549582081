import React, { useContext } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';

import AccessGrantFormInputs from '../../@types/AccessGrantFormInputs';
import AsyncSelectInput from '../../../AsyncSelectInput';
import Form from '../../../Form';
import FormContext from '../../../../contexts/form.context';
import SelectOption from '../../../../@types/react/SelectOption';
import SubmitRow from '../../../SubmitRow';
import useAdjustResourcesMap from '../../../../hooks/util/useAdjustResourcesMap';
import useLoadResourceOptions from '../../../../hooks/useLoadResourceOptions';

const ResourcesSection: React.FC<{}> = () => {
  const { getValues, handleSubmit, watch } = useFormContext();
  const { setStep, step } = useContext(FormContext);

  const currentValue = watch('resources.connect') ?? [];
  const LOCATION_NAME = 'location.value';
  const locationId: string = getValues(LOCATION_NAME);

  const { adjustResourcesMap } = useAdjustResourcesMap();

  const submitHandler: SubmitHandler<AccessGrantFormInputs> = (): void => {
    setStep((step ?? 0) + 1);
  };

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <AsyncSelectInput
        isMulti
        loadOptions={useLoadResourceOptions(locationId, {
          notIn: currentValue.map((value: SelectOption) => value.label),
        })}
        name="resources.values"
        onChange={adjustResourcesMap}
      />
      <SubmitRow submitText="Set Secondary Users" />
    </Form>
  );
};

export default ResourcesSection;
