const FormContainerStyle = `
  background: #fff;
  grid-template-rows: repeat(2, max-content) 1fr;
  height: max-content;
  min-height: 100vh;
  padding: 50px 25px 150px;
  width: 600px;
`;

export default FormContainerStyle;
