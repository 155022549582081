import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Theme from '../../../theme';

interface CustomSkeletonGridProps {
  skeletonDimensionsList: Array<{
    width: number;
    height: number;
  }>;
  rounded?: boolean;
}

const CustomSkeletonGrid: React.FC<CustomSkeletonGridProps> = ({
  skeletonDimensionsList,
  rounded,
}: CustomSkeletonGridProps) => {
  return (
    <>
      {skeletonDimensionsList.map(({ width, height }, key) => (
        <Skeleton
          key={key}
          baseColor={Theme.colors.borderDark.hexCode}
          highlightColor={Theme.colors.overlay.hexCode}
          height={height}
          width={width}
          borderRadius={rounded ? 10 : 0}
        />
      ))}
    </>
  );
};

export default CustomSkeletonGrid;
