import {
  Query,
  QueryUnitTypesArgs,
  ResourceType,
  ResourceVendor,
  UnitType,
  UnitTypeWhereInput,
} from '../../../../__generated__/graphql';

import Filter from '../../../../components/DataTable/@types/Filter';
import GET_UNIT_TYPE_TABLE_DATA from '../../../../graphql/getUnitTypeTableData';
import capitalize from 'lodash/capitalize';
import { useEffect } from 'react';
import useGetMany from '../../../useGetMany';
import { useParams } from 'react-router-dom';

type GetUnitTypeTableData = Pick<Query, 'unitTypes'>;

function addVendorFilters(filters: Filter[]): void {
  Object.keys(ResourceVendor).forEach((vendor) => {
    filters.push({
      displayKey: 'Vendor',
      displayValue: capitalize(ResourceVendor[vendor as keyof typeof ResourceVendor]),
      key: 'v',
      value: ResourceVendor[vendor as keyof typeof ResourceVendor],
    });
  });
}

function addTypeFilters(filters: Filter[]): void {
  Object.keys(ResourceType).forEach((type) => {
    filters.push({
      displayKey: 'Type',
      displayValue: capitalize(ResourceType[type as keyof typeof ResourceType]),
      key: 't',
      value: ResourceType[type as keyof typeof ResourceType],
    });
  });
}

function addUnitTypeFilters(unitTypes: Pick<UnitType, 'id' | 'type'>[], filters: Filter[]): void {
  const sortedUnitTypes: Pick<UnitType, 'id' | 'type'>[] = [...unitTypes];

  filters.push({
    displayKey: 'Unit Type',
    displayValue: 'Unassigned',
    key: 'unitType',
    value: '',
  });

  sortedUnitTypes
    .sort((unitTypeA, unitTypeB) =>
      unitTypeA.type.toLowerCase().localeCompare(unitTypeB.type.toLowerCase())
    )
    .forEach((unitType) => {
      filters.push({
        displayKey: 'Unit Type',
        displayValue: unitType.type,
        key: 'unitType',
        value: unitType.id,
      });
    });
}

export default function useResourceFilters(): Filter[] {
  const { locationId } = useParams<{ locationId?: string }>();

  const filters: Filter[] = [];

  const { called, data, loading, query } = useGetMany<GetUnitTypeTableData, QueryUnitTypesArgs>({
    graphqlQuery: GET_UNIT_TYPE_TABLE_DATA,
  });

  useEffect(() => {
    if (!called && locationId) {
      const params: UnitTypeWhereInput = {
        locationId: {
          equals: locationId,
        },
      };

      query({
        where: params,
      });
    }
  }, [called, query, locationId]);

  if (loading) {
    return [];
  }

  addTypeFilters(filters);
  addVendorFilters(filters);
  if (locationId) {
    addUnitTypeFilters(data?.unitTypes || [], filters);
  }

  return filters;
}
