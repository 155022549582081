export default function iterateInJson(
  data: Record<string, unknown>,
  onValueFunction: (
    data: Record<string, unknown>,
    parentKey: string,
    key: string,
    parentKeys: string[]
  ) => boolean
): void {
  const forEachFunction = (
    _data: Record<string, unknown>,
    parentKey: string,
    key: string,
    parentKeys: string[]
  ): boolean => {
    if (typeof _data[key] === 'object' && _data[key] && !Array.isArray(_data[key])) {
      const newParentKey = `${parentKey}.${key}`;

      // eslint-disable-next-line no-restricted-syntax
      for (const childKey of Object.keys(_data[key] as Record<string, unknown>)) {
        if (key) {
          // Will reset if the current key is present on levels above
          if (parentKeys.length > 1) {
            while (parentKeys.indexOf(key) > -1) {
              parentKeys.pop();
            }
          }

          // Will insert the new parent key
          //  if the parentKeys is not set; or
          //  if the new one is no the same as the oldest
          if (parentKeys.length === 0 || key !== parentKeys[parentKeys.length - 1]) {
            parentKeys.push(key);
          }
        }
        const forEachFunctionResult = forEachFunction(
          _data[key] as Record<string, unknown>,
          newParentKey,
          childKey,
          parentKeys
        );
        if (forEachFunctionResult) {
          return true;
        }
      }
    } else if (onValueFunction(_data, parentKey, key, parentKeys)) {
      return true;
    }

    return false;
  };

  if (data) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(data)) {
      if (forEachFunction(data, '', key, [])) {
        break;
      }
    }
  }
}
