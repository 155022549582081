import { gql } from '../__generated__/gql';

const SEARCH_DASHBOARD_LOCATIONS = gql(`
  query GetDashboardLocations(
    $orderBy: [LocationOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: CustomLocationWhereInput
  ) {
    getDashboardLocations(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
      id
      name
    }
  }
`);

export default SEARCH_DASHBOARD_LOCATIONS;
