import { gql } from '../__generated__/gql';

const GET_SERVICE = gql(`
  query GetService($where: ServiceWhereUniqueInput!) {
    service(where: $where) {
      ...ServiceFields
    }
  }
`);

export default GET_SERVICE;
