import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

/**
 * Will not trigger the effect on starting, but only when the dependencies changes
 */
const useDidMountEffect = (effect: EffectCallback, deps: DependencyList): void => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      effect();
    } else {
      didMount.current = true;
    }
  }, deps);
};

export default useDidMountEffect;
