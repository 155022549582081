import { AccessGrantPageInfo } from '../@types/GetAccessGrantPageInfo';
import DataTable from '../../../components/DataTable';
import React from 'react';
import useCredentialsTableData from '../../../hooks/useCredentialsTableData';

interface CredentialsTabProps {
  accessGrant: AccessGrantPageInfo;
}

const CredentialsTab: React.FC<CredentialsTabProps> = ({ accessGrant }: CredentialsTabProps) => {
  const tableData = useCredentialsTableData({
    dataTableKey: 'credentials-AccessGrant',
    queryOptions: {
      where: {
        accessGrantId: {
          equals: accessGrant.id,
        },
      },
    },
    relatedAccessGrant: accessGrant,
  });

  return <DataTable {...tableData} />;
};

export default CredentialsTab;
