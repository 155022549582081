import { ActionEnum, EntityEnum } from '../../@types/client/DashboardUserRolePermissions';

import { Role } from '../../__generated__/graphql';

export type DashboardActionPermissions = {
  [action in ActionEnum]?: boolean;
};

export type DashboardEntityPermissions = {
  [role in Role]: DashboardActionPermissions;
};

type DashboardUserPermissions = {
  [entity in EntityEnum]: DashboardEntityPermissions;
};

const PERMISSIONS: DashboardUserPermissions = {
  ACCESS_GRANT: {
    ADMIN: {
      CREATE: true,
      DELETE: true,
      IMPORT: true,
      UPDATE: true,
    },
    AGENT: {
      CREATE: true,
      DELETE: true,
      IMPORT: true,
      UPDATE: true,
    },
    USER: {
      CREATE: false,
      DELETE: false,
      IMPORT: false,
      UPDATE: false,
    },
  },
  ALL_PERMISSIONS: {
    ADMIN: {
      CREATE: true,
      CREATE_ADMIN: true,
      DELETE: true,
      DELETE_ADMIN: true,
      UPDATE: true,
      UPDATE_ADMIN: true,
    },
    AGENT: {
      CREATE: true,
      CREATE_ADMIN: true,
      DELETE: true,
      DELETE_ADMIN: true,
      UPDATE: true,
      UPDATE_ADMIN: true,
    },
    USER: {
      CREATE: false,
      DELETE: false,
      UPDATE: false,
    },
  },
  AMENITY: {
    ADMIN: {
      CREATE: true,
      DELETE: true,
      UPDATE: true,
    },
    AGENT: {
      CREATE: true,
      DELETE: true,
      UPDATE: true,
    },
    USER: {
      CREATE: false,
      DELETE: false,
      UPDATE: false,
    },
  },
  CREDENTIAL: {
    ADMIN: {
      CREATE: false,
      DELETE: true,
      UPDATE: false,
    },
    AGENT: {
      CREATE: false,
      DELETE: false,
      UPDATE: false,
    },
    USER: {
      CREATE: false,
      DELETE: false,
      UPDATE: false,
    },
  },
  INFO_ITEM: {
    ADMIN: {
      CREATE: true,
      DELETE: true,
      IMPORT: true,
      UPDATE: true,
    },
    AGENT: {
      CREATE: true,
      DELETE: true,
      IMPORT: true,
      UPDATE: true,
    },
    USER: {
      CREATE: false,
      DELETE: false,
      IMPORT: false,
      UPDATE: false,
    },
  },
  KIOSK: {
    ADMIN: {
      CREATE: true,
      DELETE: true,
      UPDATE: true,
    },
    AGENT: {
      CREATE: true,
      DELETE: true,
      UPDATE: true,
    },
    USER: {
      CREATE: false,
      DELETE: false,
      UPDATE: false,
    },
  },
  LOCATION: {
    ADMIN: {
      CREATE: true,
      DELETE: true,
      UPDATE: true,
    },
    AGENT: {
      CREATE: true,
      DELETE: true,
      UPDATE: true,
    },
    USER: {
      CREATE: false,
      DELETE: false,
      UPDATE: false,
    },
  },
  NONE: {
    ADMIN: {
      CREATE: false,
      CREATE_ADMIN: false,
      DELETE: false,
      DELETE_ADMIN: false,
      UPDATE: false,
      UPDATE_ADMIN: false,
    },
    AGENT: {
      CREATE: false,
      CREATE_ADMIN: false,
      DELETE: false,
      DELETE_ADMIN: false,
      UPDATE: false,
      UPDATE_ADMIN: false,
    },
    USER: {
      CREATE: false,
      DELETE: false,
      UPDATE: false,
    },
  },
  PAYMENT: {
    ADMIN: {
      CREATE: false,
      CREATE_ADMIN: false,
      DELETE: false,
      DELETE_ADMIN: false,
      UPDATE: false,
      UPDATE_ADMIN: false,
    },
    AGENT: {
      CREATE: false,
      CREATE_ADMIN: false,
      DELETE: false,
      DELETE_ADMIN: false,
      UPDATE: false,
      UPDATE_ADMIN: false,
    },
    USER: {
      CREATE: false,
      DELETE: false,
      UPDATE: false,
    },
  },
  RESOURCE: {
    ADMIN: {
      CREATE: true,
      DELETE: true,
      IMPORT: true,
      UPDATE: true,
    },
    AGENT: {
      CREATE: true,
      DELETE: true,
      IMPORT: true,
      UPDATE: true,
    },
    USER: {
      CREATE: false,
      DELETE: false,
      IMPORT: false,
      UPDATE: false,
    },
  },
  RESOURCE_GROUP: {
    ADMIN: {
      CREATE: true,
      DELETE: true,
      UPDATE: true,
    },
    AGENT: {
      CREATE: true,
      DELETE: true,
      UPDATE: true,
    },
    USER: {
      CREATE: false,
      DELETE: false,
      IMPORT: false,
      UPDATE: false,
    },
  },
  SERVICE: {
    ADMIN: {
      CREATE: true,
      DELETE: true,
      UPDATE: true,
    },
    AGENT: {
      CREATE: true,
      DELETE: true,
      UPDATE: true,
    },
    USER: {
      CREATE: false,
      DELETE: false,
      UPDATE: false,
    },
  },
  TENANT: {
    ADMIN: {
      CREATE: false,
      DELETE: false,
      UPDATE: true,
    },
    AGENT: {
      CREATE: false,
      DELETE: false,
      UPDATE: false,
    },
    USER: {
      CREATE: false,
      DELETE: false,
      UPDATE: false,
    },
  },
  UNIT_TYPE: {
    ADMIN: {
      CREATE: true,
      DELETE: true,
      IMPORT: true,
      UPDATE: true,
    },
    AGENT: {
      CREATE: true,
      DELETE: true,
      IMPORT: true,
      UPDATE: true,
    },
    USER: {
      CREATE: false,
      DELETE: false,
      IMPORT: false,
      UPDATE: false,
    },
  },
  USER: {
    ADMIN: {
      CREATE: true,
      CREATE_ADMIN: true,
      DELETE: false,
      DELETE_ADMIN: false,
      IMPORT: true,
      UPDATE: true,
      UPDATE_ADMIN: true,
    },
    AGENT: {
      CREATE: true,
      CREATE_ADMIN: false,
      DELETE: false,
      DELETE_ADMIN: false,
      IMPORT: false,
      UPDATE: true,
      UPDATE_ADMIN: false,
    },
    USER: {
      CREATE: false,
      DELETE: false,
      IMPORT: false,
      UPDATE: false,
    },
  },
};

export default PERMISSIONS;
