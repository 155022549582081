import { CSSProperties } from 'styled-components';
import { Colors } from '../../../theme/colors';

const AuthenticationFormActionTextStyle: CSSProperties = {
  color: Colors.whisper,
  textAlign: 'right',
  textDecoration: 'underline',
};

export default AuthenticationFormActionTextStyle;
