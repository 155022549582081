import { gql } from '../__generated__/gql';

const GET_CREDENTIAL_COUNT = gql(`
  query GetCredentialCount($where: CredentialWhereInput!) {
    aggregateCredential(where: $where) {
      _count {
        _all
      }
    }
  }
`);

export default GET_CREDENTIAL_COUNT;
