import React from 'react';
import TextContent from '../../TextContent';
import styles from '../index.module.css';

const ConciergeTreeExplain = () => {
  return (
    <div className={styles.explainContainer}>
      <div className={styles.explainWrapper}>
        <TextContent fontSize="16px" color="#FFFFFF">
          Concierge items are listed below. Drag and drop to reorder or nest items within categories
          or subcategories. Three hierarchy levels are supported.
        </TextContent>
      </div>
    </div>
  );
};

export default ConciergeTreeExplain;
