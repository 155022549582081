import 'styled-components/macro';

import {
  CustomImageInput,
  ResourceControlType,
  ResourceVendor,
  UnitTypeUpdateOneWithoutResourcesNestedInput,
} from '../../../__generated__/graphql';
import React, { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';

import { ApolloError } from '@apollo/client';
import Container from '../../Container';
import Form from '../../Form';
import FormContext from '../../../contexts/form.context';
import { ImageTypeEnum } from '../../UnitTypeForm/enums/ImageTypeEnum.enum';
import InputGroupProps from '../../InputGroup/@types/InputGroupProps';
import LOCKS_WITH_CODES from '../../../constants/vendors/locksWithCodes';
import PASS_LEVEL_LOCKS from '../../../constants/vendors/passLevelLocks';
import ResourceFormInputs from '../@types/ResourceFormInputs';
import SimpleFormSection from '../../../templates/SimpleFormSection';
import SubmitRow from '../../SubmitRow';
import appropriateLevels from '../functions/appropriateLevels';
import { flashApolloError } from '../../../util/errorUtils';
import { openConfirmationModal } from '../../../redux/actions/modals.actions';
import useCreateResourceAndUploadImage from '../../../hooks/useCreateResourceAndUploadImage';
import { useDispatch } from 'react-redux';
import useUpdateResourceAndUploadImage from '../../../hooks/useUpdateResourceAndUploadImage';
import values from 'lodash/values';

const VendorSection: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { handleSubmit, watch } = useFormContext();
  const [inputs, setInputs] = useState<InputGroupProps[]>([]);
  const { identifier } = useContext(FormContext);
  const initialValues = useContext(FormContext).initialValues as
    | Partial<ResourceFormInputs>
    | undefined;
  const vendor = watch('vendor');
  const imageType: string = watch('imageType');

  const useMutation = identifier
    ? useUpdateResourceAndUploadImage
    : useCreateResourceAndUploadImage;

  const { mutation } = useMutation();

  const getUnitType = (
    params: ResourceFormInputs
  ): UnitTypeUpdateOneWithoutResourcesNestedInput | undefined => {
    if (params.unitType?.value) {
      return {
        connect: {
          id: params.unitType.value,
        },
      };
    }

    if (initialValues?.unitType) {
      return {
        disconnect: true,
      };
    }

    return undefined;
  };

  const submitHandler: SubmitHandler<ResourceFormInputs> = (params) => {
    try {
      const { imageFile, imageUrl, ...mutationInputs } = params;

      delete mutationInputs.imageType;

      let imageInput: CustomImageInput | undefined = {
        imageFile: imageType === ImageTypeEnum.UPLOAD ? imageFile : undefined,
        imageUrl: imageType === ImageTypeEnum.URL ? imageUrl : undefined,
      };
      if (!imageInput.imageFile && !imageInput.imageUrl) {
        imageInput = undefined;
      }

      dispatch(
        openConfirmationModal({
          action: (): void => {
            mutation(
              {
                ...mutationInputs,
                image: imageInput,
                location: {
                  connect: {
                    id: params.location.value,
                  },
                },
                unitType: getUnitType(params),
              },
              identifier
            );
          },
        })
      );
    } catch (e) {
      flashApolloError(e as ApolloError);
    }
  };

  useEffect(() => {
    const newInputs: InputGroupProps[] = [];

    if (PASS_LEVEL_LOCKS.has(vendor)) {
      newInputs.push({
        label: 'Pass Level',
        name: 'passLevel',
        options: appropriateLevels(vendor),
        type: 'select',
      });
    }

    if (LOCKS_WITH_CODES.has(vendor)) {
      newInputs.push({
        label: 'Code',
        name: 'code',
        type: 'text',
      });
    }

    setInputs(newInputs);
  }, [setInputs, vendor]);

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Container
        css={`
          grid-auto-rows: max-content;
          grid-row-gap: 25px;
        `}
      >
        <SimpleFormSection
          inputs={[
            {
              label: 'Vendor',
              name: 'vendor',
              options: values(ResourceVendor),
              required: true,
              type: 'select',
            },
            {
              label: 'Vendor ID',
              name: 'vendorId',
              type: 'text',
            },
            {
              label: 'PMS ID',
              name: 'pmsId',
              type: 'text',
            },
            {
              label: 'Control Type',
              name: 'controlType',
              options: values(ResourceControlType),
              type: 'select',
            },
          ]}
        />
        <SimpleFormSection inputs={inputs} />
      </Container>
      <SubmitRow submitText="Submit" />
    </Form>
  );
};

export default VendorSection;
