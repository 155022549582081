import 'styled-components/macro';

import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import AggregateArrivalsTab from './components/AggregateArrivalsTab';
import ArrivalsTab from './components/ArrivalsTab';
import Banner from '../../components/Banner';
import BaseLayout from '../../templates/BaseLayout';
import Button from '../../components/Button';
import ButtonModel from '../../components/Button/enums/ButtonModel.enum';
import CallRecordsTab from './components/CallRecordsTab';
import Container from '../../components/Container';
import ExternalReportingTab from './components/ExternalReportingTab';
import RoomNotReadyQueueTab from './components/RoomNotReadyQueueTab';
import { RootState } from '../../redux/reducers';
import links from '../../util/links';
import qs from 'query-string';
import { useSelector } from 'react-redux';

enum ReportingPageTabEnum {
  EXTERNAL_REPORTING_TAB = 'externalReporting',
  ARRIVALS_TAB = 'arrivals',
  CALL_RECORDS_TAB = 'callRecords',
  ARRIVALS_BY_DAY_TAB = 'arrivalsByDay',
  ROOM_NOT_READY_QUEUE = 'roomNotReadyQueue',
}

const ReportingPage: React.FC<{}> = () => {
  const location = useLocation();
  const history = useHistory();
  const dashboardPreferences = useSelector((state: RootState) => state.dashboardPreferences);

  const queryParameters = qs.parse(location.search);
  const { tab } = queryParameters;
  const activeTab = tab ? (tab as ReportingPageTabEnum) : undefined;

  const defaultTab: ReportingPageTabEnum = dashboardPreferences?.reportingServices
    ? ReportingPageTabEnum.EXTERNAL_REPORTING_TAB
    : ReportingPageTabEnum.ARRIVALS_TAB;

  const [currentTab, setTab] = useState<ReportingPageTabEnum>(activeTab || defaultTab);

  useEffect(() => {
    if (!activeTab) {
      history.push(`${links.paths.REPORTING}?tab=${defaultTab}`);
    }
  });

  useEffect(() => {
    if (!currentTab) {
      setTab(activeTab ? (activeTab as ReportingPageTabEnum) : defaultTab);
    }
  }, [currentTab, activeTab]);

  const tabButtons: { tab: ReportingPageTabEnum; title: string }[] = [
    ...(dashboardPreferences?.reportingServices
      ? [
          {
            tab: ReportingPageTabEnum.EXTERNAL_REPORTING_TAB,
            title: dashboardPreferences?.reportingServices?.reportTitle ?? '',
          },
        ]
      : []),
    {
      tab: ReportingPageTabEnum.ARRIVALS_TAB,
      title: 'Arrivals',
    },
    {
      tab: ReportingPageTabEnum.CALL_RECORDS_TAB,
      title: 'Call Records',
    },
    {
      tab: ReportingPageTabEnum.ARRIVALS_BY_DAY_TAB,
      title: 'Arrivals By Day',
    },
    {
      tab: ReportingPageTabEnum.ROOM_NOT_READY_QUEUE,
      title: 'Room Not Ready Queue',
    },
  ];

  return (
    <BaseLayout>
      <Container
        css={`
          padding: 30px 35px 75px 25px;
        `}
      >
        <Banner subText="Virdee" text="Reporting" />
        <Container
          css={`
            grid-auto-flow: column;
            grid-auto-columns: max-content;
            grid-column-gap: 10px;
            margin: 20px 0;
          `}
        >
          {tabButtons.map((tabButton) => {
            const { tab: tabButtonType, title: tabTitle } = tabButton;

            return (
              <Button
                active={activeTab === tabButtonType}
                model={ButtonModel.TAB}
                onClick={(): void => {
                  setTab(tabButtonType);
                  history.push(`${links.paths.REPORTING}?tab=${tabButtonType}`);
                }}
                key={tabButton.tab}
              >
                {tabTitle}
              </Button>
            );
          })}
        </Container>
        {currentTab === ReportingPageTabEnum.EXTERNAL_REPORTING_TAB && <ExternalReportingTab />}
        {currentTab === ReportingPageTabEnum.ARRIVALS_TAB && <ArrivalsTab />}
        {currentTab === ReportingPageTabEnum.CALL_RECORDS_TAB && <CallRecordsTab />}
        {currentTab === ReportingPageTabEnum.ARRIVALS_BY_DAY_TAB && <AggregateArrivalsTab />}
        {currentTab === ReportingPageTabEnum.ROOM_NOT_READY_QUEUE && <RoomNotReadyQueueTab />}
      </Container>
    </BaseLayout>
  );
};

export default ReportingPage;
