import { TimeControlCategory } from '../enums';

interface InputData {
  title: string;
  description: string[];
  category: TimeControlCategory;
}

const GRACE_PERIOD_INPUTS: InputData[] = [
  {
    category: TimeControlCategory.GUEST_ROOM,
    description: [
      'Configure guest room access Override Periods in this section. The Override Period will allow guests to check in and access their rooms before the default check-in time if the room is in a ready status, i.e. Inspected or Clean, or after the default check-out time.',
      'To configure the Override Period as an Absolute value, choose the time of day (HH:MM, AM/PM) after which you would like to allow guests to check in and access their room.  This should be BEFORE the default check-in time configured for the location. The Override of the check-out time should be AFTER the default check-out time.',
      'To configure the Override Period as a Relative value, choose the number of minutes before the default check-in time, or after the default check-out time you would like guests to be able to check in and access their rooms.',
    ],
    title: 'Guest Rooms',
  },
  {
    category: TimeControlCategory.COMMON_AREAS,
    description: [
      'Configure common areas access Override Periods in this section. The Override Period will allow guests to check in and access common areas before the default check-in time, or after the default check-out time. ',
      'To configure the Override Period as an Absolute value, choose the time of day (HH:MM, AM/PM) after which you would like to allow guests to check in and access common areas.  This should be BEFORE the default check-in time configured for the location. The Override of the check-out time should be AFTER the default check-out time.',
      'To configure the Override Period as a Relative value, choose the number of minutes before the default check-in time, or after the default check-out time you would like guests to be able to check in and access common areas.',
    ],
    title: 'Common Areas',
  },
];

export default GRACE_PERIOD_INPUTS;
