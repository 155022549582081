import { RequirementStatus, RequirementType } from '../../../../../__generated__/graphql';

import InputGroupProps from '../../../../InputGroup/@types/InputGroupProps';
import { values } from 'lodash';

export default function generateRequirementFormInputs(
  index: number,
  getValues: (arg0: string) => string
): InputGroupProps[] {
  const requirementStatused = values(RequirementStatus).filter(
    (status) => status !== RequirementStatus.Processing
  );

  const KEY_REQUIREMENT_TYPE = `requirements.values[${index}].type`;
  const inputGroups: InputGroupProps[] = [
    {
      isFieldArray: true,
      label: 'Type',
      name: KEY_REQUIREMENT_TYPE,
      options: values(RequirementType),
      type: 'select',
    },
    {
      isFieldArray: true,
      label: 'Status',
      name: `requirements.values[${index}].status`,
      options: requirementStatused,
      type: 'select',
    },
  ];

  if (getValues(KEY_REQUIREMENT_TYPE) === RequirementType.CaptureSecurityDeposit) {
    inputGroups.push({
      label: 'Amount',
      min: 1,
      name: `requirements.values[${index}].metadata.amount`,
      required: true,
      type: 'number',
    });
  }

  if (getValues(KEY_REQUIREMENT_TYPE) === RequirementType.TermsAndConditionsConfirmation) {
    inputGroups.push({
      accept: 'application/pdf',
      label: 'PDF File',
      name: `file`,
      type: 'file',
    });
  }

  if (
    getValues(KEY_REQUIREMENT_TYPE) === RequirementType.IdVerification ||
    getValues(KEY_REQUIREMENT_TYPE) === RequirementType.MrzIdVerification
  ) {
    inputGroups.push({
      isFieldArray: true,
      label: 'Minimum Age',
      min: 0,
      name: `requirements.values[${index}].metadata.minimumAge`,
      type: 'number',
    });
  }

  return inputGroups;
}
