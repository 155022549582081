import {
  Query,
  QueryGetDashboardLocationsArgs,
  QueryMode,
  SortOrder,
} from '../__generated__/graphql';

import { RootState } from '../redux/reducers';
import SEARCH_DASHBOARD_LOCATIONS from '../graphql/searchDashboardLocations';
import SelectOption from '../@types/react/SelectOption';
import { useApolloClient } from '@apollo/client';
import { useSelector } from 'react-redux';

type SearchDashboardLocationsData = Pick<Query, 'getDashboardLocations'>;

export default function useLoadDashboardLocationOptions(): (
  input: string
) => Promise<SelectOption[]> {
  const client = useApolloClient();
  const me = useSelector((state: RootState) => state.me);
  const activeTenantId = me.activeMembership?.tenantId ?? '';

  return async (input: string): Promise<SelectOption[]> => {
    const { data } = await client.query<
      SearchDashboardLocationsData,
      QueryGetDashboardLocationsArgs
    >({
      query: SEARCH_DASHBOARD_LOCATIONS,
      variables: {
        orderBy: [
          {
            name: SortOrder.Asc,
          },
        ],
        take: 10,
        where: {
          name: {
            contains: input,
            mode: QueryMode.Insensitive,
          },
          supportsPolling: {
            equals: true,
          },
          tenantId: {
            equals: activeTenantId,
          },
        },
      },
    });

    return (
      data.getDashboardLocations?.map((location) => ({
        label: location.name,
        value: location.id,
      })) ?? []
    );
  };
}
