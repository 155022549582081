import { gql } from '../../../__generated__/gql';

export const UserOnAgFragment = gql(`
  fragment UserOnAGFields on User {
    id
    name
    email
    phoneNumber
    lastSeenAt
  }
`);

export const AccessGrantPageInfoFragment = gql(`
  fragment AccessGrantPageInfoFields on DashboardAccessGrant {
    credentials {
      id
      userId
      isActive
    }
    confirmationCode
    id
    endsAt
    endDate
    endTime
    lastPolledAt
    location {
      id
      name
      timezone
      supportsPolling
    }
    metadata
    requirements {
      createdAt
      id
      status
      type
      updatedAt
      files {
        id
        file {
          fileType
          url
        }
      }
    }
    resources {
      name
      type
    }
    secondaryUsers {
      user {
        ...UserOnAGFields
      }
    }
    startsAt
    startDate
    startTime
    arrivalTime
    state
    status
    tenantId
    user {
      ...UserOnAGFields
    }
  }
`);

export const GET_ACCESS_GRANT_PAGE_INFO_AND_CHECK_NOTIFICATION_CAPABILITY = gql(`
  query GetAccessGrantPageInfoAndCheckNotificationCapability(
    $accessGrantId: String!
    $where: AccessGrantWhereInput!
  ) {
    findDashboardAccessGrant(where: $where) {
      ...AccessGrantPageInfoFields
    }
    accessGrantIsEnabledToSendNotificationToExternalAPI(accessGrantId: $accessGrantId)
  }
`);
