/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3ThemeUserDetailsSchema: NodeSchemaProperties = {
  userDetailsScreen: {
    title: '#10 User Details Screen',
    type: 'object',
    properties: {
      detailsScreen: {
        title: '#1 Details Screen',
        type: 'object',
        properties: {
          'listTitleTextColor:enable': {
            description: '[userDetailsScreen.detailsScreen.listTitleTextColor]',
            title: '#1.1 List Title Text Color',
            type: 'boolean',
          },
          listTitleTextColor: {
            type: 'string',
          },
          'nameTextColor:enable': {
            description: '[userDetailsScreen.detailsScreen.nameTextColor]',
            title: '#1.2 Name Text Color',
            type: 'boolean',
          },
          nameTextColor: {
            type: 'string',
          },
          'emailTextColor:enable': {
            description: '[userDetailsScreen.detailsScreen.emailTextColor]',
            title: '#1.3 Email Text Color',
            type: 'boolean',
          },
          emailTextColor: {
            type: 'string',
          },
          'phoneTextColor:enable': {
            description: '[userDetailsScreen.detailsScreen.phoneTextColor]',
            title: '#1.4 Phone Text Color',
            type: 'boolean',
          },
          phoneTextColor: {
            type: 'string',
          },
          'subtitleTextColor:enable': {
            description: '[userDetailsScreen.detailsScreen.subtitleTextColor]',
            title: '#1.5 Subtitle Text Color',
            type: 'boolean',
          },
          subtitleTextColor: {
            type: 'string',
          },
        },
      },
      resources: {
        title: '#2 Resources',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[userDetailsScreen.resources.backgroundColor]',
            title: '#2.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          card: {
            title: '#2.2 Card',
            type: 'object',
            properties: {
              'backgroundColor:enable': {
                description: '[userDetailsScreen.resources.card.backgroundColor]',
                title: '#2.2.1 Background Color',
                type: 'boolean',
              },
              backgroundColor: {
                type: 'string',
              },
              'primaryTextColor:enable': {
                description: '[userDetailsScreen.resources.card.primaryTextColor]',
                title: '#2.2.2 Primary Text Color',
                type: 'boolean',
              },
              primaryTextColor: {
                type: 'string',
              },
              'secondaryTextColor:enable': {
                description: '[userDetailsScreen.resources.card.secondaryTextColor]',
                title: '#2.2.3 Secondary Text Color',
                type: 'boolean',
              },
              secondaryTextColor: {
                type: 'string',
              },
            },
          },
        },
      },
      header: {
        title: '#3 Header',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[userDetailsScreen.header.backgroundColor]',
            title: '#3.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'textColor:enable': {
            description: '[userDetailsScreen.header.textColor]',
            title: '#3.2 Text Color',
            type: 'boolean',
          },
          textColor: {
            type: 'string',
          },
        },
      },
      'roomInfoVisible:enable': {
        description: '[userDetailsScreen.roomInfoVisible]',
        title: '#4 Room Info Visible',
        type: 'boolean',
      },
      roomInfoVisible: {
        type: undefined,
        enum: [true, false],
      },
      'initialBackgroundColor:enable': {
        description: '[userDetailsScreen.initialBackgroundColor]',
        title: '#5 Initial Background Color',
        type: 'boolean',
      },
      initialBackgroundColor: {
        type: 'string',
      },
      'disableResourceRate:enable': {
        description: '[userDetailsScreen.disableResourceRate]',
        title: '#6 Disable Resource Rate',
        type: 'boolean',
      },
      disableResourceRate: {
        type: undefined,
        enum: [true, false],
      },
      'disableUsersEditButton:enable': {
        description: '[userDetailsScreen.disableUsersEditButton]',
        title: '#7 Disable Users Edit Button',
        type: 'boolean',
      },
      disableUsersEditButton: {
        type: undefined,
        enum: [true, false],
      },
    },
  },
};

export const mobileV3ThemeUserDetailsUiSchema: NodeUiSchemaProperties = {
  userDetailsScreen: {
    detailsScreen: {
      listTitleTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      nameTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      emailTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      phoneTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      subtitleTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    resources: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      card: {
        backgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        primaryTextColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        secondaryTextColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
    },
    header: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    roomInfoVisible: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    initialBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    disableResourceRate: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    disableUsersEditButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
  },
};
