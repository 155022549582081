import { QueryMode, StringFilter } from '../../__generated__/graphql';

export interface WhereInput {
  [key: string]: StringFilter;
}

export default function createWhereFilter<T>(
  value: string,
  options?: {
    key?: keyof T;
    method?: keyof StringFilter;
    mode?: QueryMode.Default | QueryMode.Insensitive;
  }
): WhereInput {
  return {
    [options?.key ?? 'name']: {
      [options?.method ?? 'contains']: value,
      mode: options?.mode ?? QueryMode.Insensitive,
    },
  };
}
