import Theme from '../../../theme';

const DisplayBoxContainerStyle = `
  border-radius: 24px;
  box-shadow: 0px 10px 32px 0px rgba(${Theme.colors.boxShadow.rgb}, 0.91);
  grid-template-rows: max-content 1fr max-content;
  min-height: 480px;
  width: 370px;

  @media only screen and (max-width: 1200px),
              screen and (max-width: 1450px) {
    min-height: min-content;
    width: 275px;
  }
`;

export default DisplayBoxContainerStyle;
