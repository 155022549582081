import { gql } from '../__generated__/gql';

const EXCHANGE_REFRESH_TOKEN = gql(`
  mutation ExchangeRefreshToken($token: String!) {
    exchangeRefreshToken(token: $token) {
      accessToken
      authErrorCode
      expiresIn
      refreshToken
    }
  }
`);

export default EXCHANGE_REFRESH_TOKEN;
