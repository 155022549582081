import {
  CustomUnitTypeUpdateInput,
  Mutation,
  MutationUpdateUnitTypeAndUploadImageFileArgs,
} from '../__generated__/graphql';

import MutationHookData from './@types/MutationHookData';
import MutationHookParams from './@types/MutationHookParams';
import UPDATE_UNIT_TYPE from '../graphql/updateUnitType';
import { apolloRequirePreflightHeader } from '../client';
import { flashApolloError } from '../util/errorUtils';
import { useMutation } from '@apollo/client';

type UpdateUnitTypeData = Pick<Mutation, 'updateUnitTypeAndUploadImageFile'>;

export default function useUpdateUnitTypeAndUploadImage({
  onCompleted = (): void => {},
  onError = flashApolloError,
}: MutationHookParams<UpdateUnitTypeData> = {}): MutationHookData<
  CustomUnitTypeUpdateInput,
  UpdateUnitTypeData
> {
  const [update, { data, error, loading }] = useMutation<
    UpdateUnitTypeData,
    MutationUpdateUnitTypeAndUploadImageFileArgs
  >(UPDATE_UNIT_TYPE, {
    context: {
      headers: apolloRequirePreflightHeader,
    },
    onCompleted: ({ updateUnitTypeAndUploadImageFile }) => {
      onCompleted({ updateUnitTypeAndUploadImageFile });
    },
    onError,
  });

  return {
    data,
    error,
    loading,
    mutation: (inputs: CustomUnitTypeUpdateInput, id = ''): void => {
      const newInputs = { ...inputs };
      if ((newInputs as Record<string, unknown>).id) {
        delete (newInputs as Record<string, unknown>).id;
      }
      update({
        variables: {
          data: newInputs,
          where: { id },
        },
      });
    },
  };
}
