import React from 'react';

interface SVGProps {
  height?: string;
  fill?: string;
  width?: string;
}

const SearchIcon: React.FunctionComponent<SVGProps> = ({
  height = '21px',
  fill = '#ffffff',
  width = '21px',
}: SVGProps) => {
  const viewBox = `0 0 ${width} ${height}`.replace(/px/g, '');
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g id="Dashboard_-1-Copy-24" transform="translate(-523.000000, -211.000000)">
          <g id="🍱Large-Comp/6-products" transform="translate(114.000000, 161.200000)">
            <g id="actions" transform="translate(39.350929, 32.000000)">
              <g id="search" transform="translate(352.000000, 0.000000)">
                <g id="search-box/simple" transform="translate(16.000000, 16.000000)">
                  <g id="Group" fill={fill} fillRule="nonzero">
                    <g id="Search" transform="translate(2.000000, 2.000000)">
                      <path d="M9.61154085,8.52651283e-14 C14.9198483,8.52651283e-14 19.2230817,4.20819209 19.2230817,9.39926012 C19.2230817,11.849049 18.2646994,14.0799396 16.6945817,15.7529407 L19.7825141,18.7636123 C20.0719628,19.0459331 20.0725723,19.5042615 19.7838754,19.7873175 C19.5214236,20.0446411 19.1102258,20.0685267 18.8201644,19.8586312 L18.73705,19.7886487 L15.6127041,16.7417321 C13.9681424,18.0288264 11.8817575,18.7985202 9.61154085,18.7985202 C4.30323342,18.7985202 0,14.5903281 0,9.39926012 C0,4.20819209 4.30323342,8.52651283e-14 9.61154085,8.52651283e-14 Z M9.61154085,1.44773894 C5.12085558,1.44773894 1.48043588,5.00775623 1.48043588,9.39926012 C1.48043588,13.790764 5.12085558,17.3507813 9.61154085,17.3507813 C14.1022261,17.3507813 17.7426458,13.790764 17.7426458,9.39926012 C17.7426458,5.00775623 14.1022261,1.44773894 9.61154085,1.44773894 Z"></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SearchIcon;
