import 'styled-components/macro';

import Checkbox, { CheckboxModel } from '../../../../../Checkbox';
import { Colors, RGB } from '../../../../../../theme/colors';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../../../Button';
import ButtonModel from '../../../../../Button/enums/ButtonModel.enum';
import Container from '../../../../../Container';
import Filter from '../../../../@types/Filter';
import Form from '../../../../../Form';
import { RootState } from '../../../../../../redux/reducers';
import Tooltip from '../../../../../Tooltip';
import { setFilter } from '../../../../../../redux/actions/dataTable.actions';

interface FiltersFormProps {
  dataTableKey: string;
  filterName: string;
  filters: Pick<Filter, 'displayValue' | 'defaultValue' | 'tooltip' | 'value'>[];
  closeDropdown: () => void;
}

const FiltersForm: React.FC<FiltersFormProps> = ({
  dataTableKey,
  filterName,
  filters,
  closeDropdown,
}: FiltersFormProps) => {
  const [values, setValues] = useState<{ [key: string]: boolean }>({});
  const dispatch = useDispatch();
  const fieldsFiltered = useSelector((state: RootState) => state.dataTable.filters);

  useEffect(() => {
    // Fill the checkboxes with the selected filters
    const fieldsFilters = fieldsFiltered[dataTableKey] ?? [];
    const indexCurrentFilters = fieldsFilters.findIndex((value) => value.fieldKey === filterName);

    if (indexCurrentFilters > -1) {
      const currentFilters = fieldsFiltered[dataTableKey][indexCurrentFilters].values;

      const optionsChecked: { [key: string]: boolean } = {};
      currentFilters.forEach((value) => {
        optionsChecked[value] = true;
      });
      setValues(optionsChecked);
    }
  }, [filterName, dataTableKey, fieldsFiltered, setValues]);

  function handleSubmit(e: React.FormEvent): void {
    e.preventDefault();
    closeDropdown();
    const selectedValues = Object.keys(values).filter((value) => values[value]);

    dispatch(
      setFilter(dataTableKey, {
        fieldKey: filterName,
        values: selectedValues,
      })
    );
  }

  return (
    <Form
      css={`
        height: 100%;
        min-height: max-content;
        padding: 0;
        border: 1px solid rgba(${RGB.shuttleGrey}, 0.25);
        border-radius: 10px;
      `}
      onSubmit={handleSubmit}
    >
      <Container
        css={`
          grid-row-gap: 7px;
          padding: 10px 5px;
        `}
      >
        {filters.map((filter) => {
          if (filter.tooltip) {
            return (
              <Tooltip
                content={filter.tooltip ?? ''}
                direction="right"
                key={`filter-${filter.displayValue}`}
              >
                <Checkbox
                  checked={values[filter.value] ?? false}
                  label={filter.displayValue}
                  model={CheckboxModel.TABLE_DARK}
                  onClick={(): void =>
                    setValues({
                      ...values,
                      [filter.value]: !values[filter.value],
                    })
                  }
                />
              </Tooltip>
            );
          }

          return (
            <Checkbox
              checked={values[filter.value] ?? false}
              label={filter.displayValue}
              key={`filter-${filter.displayValue}`}
              model={CheckboxModel.TABLE_DARK}
              onClick={(): void =>
                setValues({
                  ...values,
                  [filter.value]: !values[filter.value],
                })
              }
            />
          );
        })}
      </Container>
      <Container
        css={`
          align-items: center;
          background: rgba(255, 255, 255, 0.95);
          bottom: 0;
          justify-content: end;
          justify-self: end;
          padding: 10px 0;
          position: sticky;
          width: 100%;
        `}
      >
        <Button
          css={`
            color: ${Colors.manatee};
            padding: 4px 10px;
          `}
          model={ButtonModel.DEFAULT}
          type="submit"
        >
          Apply Filters
        </Button>
      </Container>
    </Form>
  );
};

export default FiltersForm;
