import 'styled-components/macro';

import React, { useContext } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';

import AccessGrantFormInputs from '../../@types/AccessGrantFormInputs';
import { AccessGrantType } from '../../../../__generated__/graphql';
import ButtonSelect from '../../../ButtonSelect';
import Container from '../../../Container';
import Form from '../../../Form';
import FormContext from '../../../../contexts/form.context';
import FormCrudAction from '../../../../enums/FormCrudAction.enum';
import Label from '../../../Label';
import { RootState } from '../../../../redux/reducers';
import SelectTypeContainerStyle from './styles/SelectTypeContainer.style';
import SelectTypeLabelStyle from './styles/SelectTypeLabel.style';
import SimpleFormSection from '../../../../templates/SimpleFormSection';
import SubmitRow from '../../../SubmitRow';
import canAccessRequirementsSection from '../../functions/canAccessRequirementsSection';
import generateGrantFormInputs from './functions/generateGrantFormInputs';
import { useEffect } from 'react';
import useGetAccessGrantById from '../../../../hooks/useGetAccessGrantById';
import { useSelector } from 'react-redux';
import useSetTimesByLocation from '../../hooks/useSetTimesByLocation';

const GrantFormSection: React.FC<{}> = () => {
  const id = useSelector((state: RootState) => state.modals.form.id);
  const { called, data, query } = useGetAccessGrantById();
  const { errors, handleSubmit } = useFormContext();
  const { setStep, step } = useContext(FormContext);
  const setTimes = useSetTimesByLocation();

  const GRANT_FORM_SIMPLE_INPUTS = generateGrantFormInputs({
    formCrudAction: id ? FormCrudAction.EDIT : FormCrudAction.CREATE,
    onSelectLocation: setTimes,
    timezone: data?.location.timezone ?? '',
  });

  const submitHandler: SubmitHandler<AccessGrantFormInputs> = (inputs): void => {
    if (canAccessRequirementsSection(inputs, errors)) {
      setStep((step ?? 0) + 1);
    }
  };

  useEffect(() => {
    if (!called && id) {
      query(id);
    }
  });

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Container>
        <Container css={SelectTypeContainerStyle}>
          <Label css={SelectTypeLabelStyle}>Type</Label>
          <ButtonSelect
            options={[
              {
                name: 'type',
                value: AccessGrantType.Hotel,
              },
              {
                name: 'type',
                value: AccessGrantType.Leasing,
              },
            ]}
          />
        </Container>
        <SimpleFormSection inputs={GRANT_FORM_SIMPLE_INPUTS} />
      </Container>
      <SubmitRow submitText="Set Requirements" />
    </Form>
  );
};

export default GrantFormSection;
