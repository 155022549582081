import { DataTableFilterValues } from '../../../@types/client/DataTableFilterValues';
import { KioskWhereInput } from '../../../__generated__/graphql';
import { getDispenserOfflineMS } from '../../../util/kiosk/isDispenserOnline';
import { getKioskOfflineMS } from '../../../util/kiosk/isKioskOnline';
import { getPaymentTerminalOfflineMS } from '../../../util/kiosk/isPaymentTerminalOnline';

export default function parseKioskFiltersParameters(
  fieldsFiltered: DataTableFilterValues[]
): Partial<KioskWhereInput> {
  const whereInput: Partial<KioskWhereInput> = {};

  if (fieldsFiltered && fieldsFiltered.length > 0) {
    // Dispenser filter
    const dispFieldsIndex = fieldsFiltered.findIndex((field) => field.fieldKey === 'disp');
    if (dispFieldsIndex > -1) {
      const dispFilters = fieldsFiltered[dispFieldsIndex].values;

      if (dispFilters.length === 1) {
        const offlineCriteria = getDispenserOfflineMS();
        const [value] = dispFilters;

        if (value === 'off') {
          whereInput.OR = [
            {
              dispenserLastSeenAt: {
                equals: null,
              },
            },
            {
              dispenserLastSeenAt: {
                lte: new Date(offlineCriteria),
              },
            },
          ];
        } else if (value === 'on') {
          whereInput.dispenserLastSeenAt = {};
          whereInput.dispenserLastSeenAt.gte = new Date(offlineCriteria);
        }
      }
    }

    // Payment reader filter
    const readFieldsIndex = fieldsFiltered.findIndex((field) => field.fieldKey === 'read');
    if (readFieldsIndex > -1) {
      const readFilters = fieldsFiltered[readFieldsIndex].values;

      if (readFilters.length === 1) {
        const offlineCriteria = getPaymentTerminalOfflineMS();
        const [value] = readFilters;

        if (value === 'lte') {
          whereInput.OR = [
            {
              terminalLastSeenAt: {
                equals: null,
              },
            },
            {
              terminalLastSeenAt: {
                lte: new Date(offlineCriteria),
              },
            },
          ];
        } else if (value === 'gte') {
          whereInput.terminalLastSeenAt = {};
          whereInput.terminalLastSeenAt.gte = new Date(offlineCriteria);
        }
      }
    }

    // Status filter
    const statusFieldsIndex = fieldsFiltered.findIndex((field) => field.fieldKey === 'status');
    if (statusFieldsIndex > -1) {
      const statusFilters = fieldsFiltered[statusFieldsIndex].values;

      if (statusFilters.length === 1) {
        const offlineCriteria = getKioskOfflineMS();
        const [value] = statusFilters;

        if (value === 'lte') {
          whereInput.OR = [
            {
              lastSeenAt: {
                equals: null,
              },
            },
            {
              lastSeenAt: {
                lte: new Date(offlineCriteria),
              },
            },
          ];
        } else if (value === 'gte') {
          whereInput.lastSeenAt = {};
          whereInput.lastSeenAt.gte = new Date(offlineCriteria);
        }
      }
    }
  }

  return whereInput;
}
