/* eslint-disable sort-keys */
import React from 'react';
import Theme from '../../../theme';

const IncomingCallContainerStyle: React.CSSProperties = {
  backgroundColor: Theme.colors.darkAlternate.hexCode,
  height: '100%',
  justifyContent: 'space-evenly',
  width: '300px',
  boxShadow: `0px 10px 32px 0px rgba(${Theme.colors.borderDark.rgb}, 0.91)`,
  borderRadius: '24px',

  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyItems: 'flex-end',
};

export default IncomingCallContainerStyle;
