import { ResourceVendor, VendorServerCreateInput } from '../../../__generated__/graphql';
import { useDispatch, useSelector } from 'react-redux';

import { ApolloError } from '@apollo/client';
import FormCrudAction from '../../../enums/FormCrudAction.enum';
import OnityAdditionalConfig from '../@types/OnityAdditionalConfig';
import OpenPathAdditionalConfig from '../@types/OpenPathAdditionalConfig';
import { RootState } from '../../../redux/reducers';
import { SubmitHandler } from 'react-hook-form';
import VendorServerFormInputs from '../@types/VendorServerFormInputs';
import { flashApolloError } from '../../../util/errorUtils';
import { openConfirmationModal } from '../../../redux/actions/modals.actions';
import useCreateVendorServer from './useCreateVendorServer';
import { useParams } from 'react-router-dom';
import useUpdateVendorServer from './useUpdateVendorServer';

interface UseSubmitVendorServerInterface {
  loadingMutation: boolean;
  submitHandler: SubmitHandler<VendorServerFormInputs>;
}

const useSubmitVendorServer = (): UseSubmitVendorServerInterface => {
  const { locationId } = useParams<{ locationId: string }>();

  // Form properties
  const dispatch = useDispatch();
  const id = useSelector((state: RootState) => state.modals.form.id);
  const crudAction = id ? FormCrudAction.EDIT : FormCrudAction.CREATE;

  // Mutations - Create/Update Vendor Server
  const useMutation =
    crudAction === FormCrudAction.CREATE ? useCreateVendorServer : useUpdateVendorServer;

  const { mutation, loading: loadingMutation } = useMutation();

  const getFieldsAccordingToVendor = (inputs: VendorServerFormInputs): VendorServerCreateInput => {
    const {
      vendor,
      port,
      host,
      username,
      password,
      shouldAllJoin,
      isActive,
      lockServiceCode,
      trailerBlock,
      accessID,
      uuid,
      assaCardType,
      onityCoreOwnerId,
      onityOnPortalHost,
      onityOnPortalPort,
      onityCoreOwnerName,
      onityFrameworkHost,
      onityOnPortalPassword,
      onityOnPortalUsername,
      onityCoreCertificateBase64,
      onityCoreCertificatePassword,
      namespaceId,
      credentialTypeId,
      userScheduleTypeId,
    } = inputs;

    const fieldsDependsOnVendor: Partial<VendorServerCreateInput> = {
      accessID: null,
      assaCardType: null,
      lockServiceCode: null,
      trailerBlock: null,
      uuid: null,
    };

    const baseInput: VendorServerCreateInput = {
      host,
      isActive,
      password,
      port,
      shouldAllJoin,
      username,
      vendor,
      additionalConfig: {},
      ...fieldsDependsOnVendor,
    };

    switch (vendor) {
      case ResourceVendor.AssaAbloy: {
        return {
          ...baseInput,
          assaCardType,
          lockServiceCode,
        };
      }

      case ResourceVendor.Salto: {
        return {
          ...baseInput,
          trailerBlock,
        };
      }

      case ResourceVendor.AssaAbloyVisionline: {
        return {
          ...baseInput,
          accessID,
          assaCardType: assaCardType || null,
          lockServiceCode,
          uuid,
        };
      }

      case ResourceVendor.Onity: {
        const onityAdditionalConfig: OnityAdditionalConfig = {
          onityCoreCertificateBase64: onityCoreCertificateBase64!,
          onityCoreCertificatePassword: onityCoreCertificatePassword!,
          onityCoreOwnerId: onityCoreOwnerId!,
          onityCoreOwnerName: onityCoreOwnerName!,
          onityFrameworkHost: onityFrameworkHost!,
          onityOnPortalHost: onityOnPortalHost!,
          onityOnPortalPassword: onityOnPortalPassword!,
          onityOnPortalPort: onityOnPortalPort!,
          onityOnPortalUsername: onityOnPortalUsername!,
        };

        return {
          ...baseInput,
          additionalConfig: onityAdditionalConfig,
        };
      }

      case ResourceVendor.Openpath: {
        const onityAdditionalConfig: OpenPathAdditionalConfig = {
          credentialTypeId: credentialTypeId!,
          namespaceId: namespaceId!,
          userScheduleTypeId: userScheduleTypeId!,
        };

        return {
          ...baseInput,
          additionalConfig: onityAdditionalConfig,
        };
      }

      default: {
        return baseInput;
      }
    }
  };

  // Submit
  const submitHandler: SubmitHandler<VendorServerFormInputs> = (
    inputs: VendorServerFormInputs
  ): void => {
    const { port } = inputs;

    try {
      const formInputs = getFieldsAccordingToVendor(inputs);

      const mutationInputs: VendorServerCreateInput = {
        ...formInputs,
        Location: {
          connect: {
            id: locationId,
          },
        },
        port: Number(port),
      };

      dispatch(
        openConfirmationModal({
          action: (): void => {
            if (id) {
              mutation(mutationInputs, id);
            } else {
              mutation(mutationInputs);
            }
          },
        })
      );
    } catch (e) {
      flashApolloError(e as ApolloError);
    }
  };

  return {
    loadingMutation,
    submitHandler,
  };
};

export default useSubmitVendorServer;
