import Theme from '../../../theme';

const ExpandButtonStyle = `
  background: transparent;
  color: ${Theme.colors.borderTable.hexCode};
  height: 40px;
  opacity: 0;
  position: absolute;
  right: 25px;
  top: 25px;
  width: 40px;
  z-index: 2;

  &:hover {
    opacity: 1;

    svg, path {
      fill: ${Theme.colors.white.hexCode};
    }
  }
`;

export default ExpandButtonStyle;
