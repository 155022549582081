import { gql } from '../__generated__/gql';

export const CREATE_INFO_ITEM = gql(`
  mutation CreateInfoItemAndUploadImageFile($data: CustomInfoItemCreateInput!) {
    createInfoItemAndUploadImageFile(data: $data) {
      id
      title
    }
  }
`);
