import React, { useContext } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';

import Form from '../../Form';
import FormContext from '../../../contexts/form.context';
import { ImageTypeEnum } from '../enums/ImageTypeEnum.enum';
import SimpleFormSection from '../../../templates/SimpleFormSection';
import SubmitRow from '../../SubmitRow';
import UnitTypeFormInputs from '../@types/UnitTypeFormInputs';
import generateUnitTypeSectionInputs from '../constants/generateUnitTypeSectionInputs';

const UnitTypeSection: React.FC<{}> = () => {
  const { handleSubmit, watch, setError } = useFormContext();
  const { step, setStep } = useContext(FormContext);

  const imageType: string = watch('imageType');

  const validateUnitTypeInputs = (inputs: UnitTypeFormInputs): void => {
    // Validate UnitType
    const { imageFile, imageUrl } = inputs;

    let imageErrorField;
    if (imageType === ImageTypeEnum.URL && (!imageUrl || imageUrl.length === 0)) {
      imageErrorField = 'imageUrl';
    } else if (imageType === ImageTypeEnum.UPLOAD && !imageFile) {
      imageErrorField = 'imageFile';
    }

    if (imageErrorField) {
      setError(imageErrorField, { message: 'Image is required.', types: { required: true } });
      throw new Error();
    }
  };

  const submitHandler: SubmitHandler<UnitTypeFormInputs> = (inputs) => {
    try {
      validateUnitTypeInputs(inputs);
      setStep((step ?? 0) + 1);
      // eslint-disable-next-line no-empty
    } catch {}
  };

  const UNIT_TYPE_SECTION_INPUTS = generateUnitTypeSectionInputs({ imageType });

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <SimpleFormSection inputs={UNIT_TYPE_SECTION_INPUTS} />
      <SubmitRow submitText="Set localizations" />
    </Form>
  );
};

export default UnitTypeSection;
