import styled from 'styled-components';

export const BaseModalFooterContainer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: end;
  height: 50px;
  margin-top: 20px;

  & > :only-child {
    margin: 0 auto;
  }
`;
