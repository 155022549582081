import ConciergeActionEnum from '../../enums/ConciergeAction.enum';
import { ConciergeItem } from '../../__generated__/graphql';
import { NodeModel } from '@minoru/react-dnd-treeview';
import SetConciergeTreeAction from './@types/ConciergeTreeAction';

export const setConciergeTree = (
  payload: Array<NodeModel<ConciergeItem>>
): SetConciergeTreeAction => ({
  type: ConciergeActionEnum.SET_CONCIERGE_TREE,
  payload,
});
