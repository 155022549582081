import InputGroupProps from '../../InputGroup/@types/InputGroupProps';

const IMPORT_FROM_CSV_SECTION_INPUTS: InputGroupProps[] = [
  {
    accept: '.csv',
    label: 'Select CSV File',
    name: 'csvFile',
    required: true,
    type: 'file',
  },
];

export default IMPORT_FROM_CSV_SECTION_INPUTS;
