import React, { useMemo } from 'react';

export const ActionBarAuthenticationCredentials: React.FC<{
  color?: string;
}> = ({ color }) => {
  const fillColor = useMemo(() => color || '#fff', [color]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3878 2.59046C12.4852 2.30235 11.5152 2.30235 10.6126 2.59046L5.88328 4.10004C4.08742 4.67328 2.88005 6.34593 2.88005 8.21523C2.88005 13.2794 5.38737 18.0382 9.58202 20.8834C11.0418 21.8736 12.9586 21.8736 14.4184 20.8834C18.613 18.0382 21.1204 13.2794 21.1204 8.21523C21.1204 6.34593 19.913 4.67328 18.1171 4.10004L13.3878 2.59046ZM11.0142 3.8114C11.6558 3.60663 12.3446 3.60663 12.9862 3.8114L17.7155 5.321C18.9603 5.71832 19.8175 6.88802 19.8175 8.21523C19.8175 12.8647 17.5148 17.2249 13.6795 19.8263C12.6646 20.5147 11.3358 20.5147 10.3209 19.8263C6.48563 17.2249 4.18293 12.8647 4.18293 8.21523C4.18293 6.88802 5.04006 5.71832 6.28489 5.321L11.0142 3.8114Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.76599 3.74389L10.4953 2.2343C11.474 1.9219 12.526 1.9219 13.5047 2.2343L18.234 3.74389C20.1906 4.36843 21.5 6.1878 21.5 8.21503C21.5 13.4001 18.933 18.2751 14.6336 21.1914C13.0441 22.2695 10.9559 22.2695 9.3664 21.1914C5.06697 18.2751 2.5 13.4001 2.5 8.21503C2.5 6.1878 3.80939 4.36843 5.76599 3.74389ZM17.5982 5.67676L12.8689 4.16716C12.3035 3.98668 11.6965 3.98668 11.1311 4.16716L6.40178 5.67676C5.31746 6.02285 4.56257 7.04601 4.56257 8.21503C4.56257 12.7436 6.80562 16.9875 10.5361 19.5179C11.4213 20.1183 12.5787 20.1183 13.4639 19.5179C17.1944 16.9875 19.4374 12.7436 19.4374 8.21503C19.4374 7.04601 18.6825 6.02285 17.5982 5.67676ZM6.28489 5.321C5.04006 5.71832 4.18293 6.88802 4.18293 8.21523C4.18293 12.8647 6.48563 17.2249 10.3209 19.8263C11.3358 20.5147 12.6646 20.5147 13.6795 19.8263C17.5148 17.2249 19.8175 12.8647 19.8175 8.21523C19.8175 6.88802 18.9603 5.71832 17.7155 5.321L12.9862 3.8114C12.3446 3.60663 11.6558 3.60663 11.0142 3.8114L6.28489 5.321ZM10.6126 2.59046C11.5152 2.30235 12.4852 2.30235 13.3878 2.59046L18.1171 4.10004C19.913 4.67328 21.1204 6.34593 21.1204 8.21523C21.1204 13.2794 18.613 18.0382 14.4184 20.8834C12.9586 21.8736 11.0418 21.8736 9.58202 20.8834C5.38737 18.0382 2.88005 13.2794 2.88005 8.21523C2.88005 6.34593 4.08742 4.67328 5.88328 4.10004L10.6126 2.59046Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9704 9.05991C16.3541 9.43797 16.3541 10.0509 15.9704 10.429L12.0394 14.3013C11.6556 14.6794 11.0334 14.6794 10.6496 14.3013L8.68408 12.3652C8.30029 11.9871 8.30029 11.3741 8.68408 10.9961C9.06787 10.618 9.69011 10.618 10.0739 10.9961L11.3445 12.2477L14.5805 9.05991C14.9643 8.68185 15.5866 8.68185 15.9704 9.05991Z"
        fill={fillColor}
      />
    </svg>
  );
};
