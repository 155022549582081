import { AccessGrantGuests } from '../../../pages/AccessGrant/components/GuestsTab';
import { DataTableFilterValues } from '../../../@types/client/DataTableFilterValues';
import GuestOnAGWhereInput from '../@types/GuestOnAGWhereInput';
import { UserTypeEnum } from '../../useCredentialsTableData/enums/UserType.enum';

export default function parseGuestsOnAGQueryParameters(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fieldsFiltered: DataTableFilterValues[],
  accessGrant: AccessGrantGuests
): Partial<GuestOnAGWhereInput> {
  const whereInput: Partial<GuestOnAGWhereInput> = {};

  if (fieldsFiltered && fieldsFiltered.length > 0) {
    // Guest type filter
    const guestTypeFieldsIndex = fieldsFiltered.findIndex(
      (field) => field.fieldKey === 'guestType'
    );
    if (guestTypeFieldsIndex > -1) {
      const guestTypeFilters = fieldsFiltered[guestTypeFieldsIndex].values;
      if (guestTypeFilters.length === 1) {
        const [value] = guestTypeFilters;
        const isPrimaryGuest = value === UserTypeEnum.PRIMARY_GUEST;

        whereInput.user = {};
        whereInput.user.id = {};
        if (isPrimaryGuest) {
          whereInput.user.id.equals = accessGrant.user.id;
        } else {
          whereInput.user.id.not = {};
          whereInput.user.id.not.equals = accessGrant.user.id;
        }
      }
    }

    // Has credentials filter
    const hasCredentialFieldsIndex = fieldsFiltered.findIndex(
      (field) => field.fieldKey === 'hasCredential'
    );
    if (hasCredentialFieldsIndex > -1) {
      const credentialFilters = fieldsFiltered[hasCredentialFieldsIndex].values;
      if (credentialFilters.length === 1) {
        const guestsIds: string[] = [
          accessGrant.user.id,
          ...accessGrant.secondaryUsers.map((guest) => guest.user.id),
        ];

        if (!whereInput.user) {
          whereInput.user = {};
        }
        whereInput.user.Credential = {};
        const [value] = credentialFilters;
        if (value === 'true') {
          whereInput.user.Credential.some = {};
          whereInput.user.Credential.some = {
            accessGrantId: {
              equals: accessGrant.id,
            },
            isActive: {
              equals: true,
            },
            userId: { in: guestsIds },
          };
        } else {
          whereInput.user.Credential.every = {};
          whereInput.user.Credential.every = {
            accessGrantId: {
              equals: accessGrant.id,
            },
            isActive: {
              equals: true,
            },
            userId: { notIn: guestsIds },
          };
        }
      }
    }
  }

  return whereInput;
}
