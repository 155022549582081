import { gql } from '../__generated__/gql';

const DELETE_USER = gql(`
  mutation DeleteUser($where: UserWhereUniqueInput!) {
    deleteUser(where: $where) {
      email
      id
      name
    }
  }
`);

export default DELETE_USER;
