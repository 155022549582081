import { Query, QueryVendorServerArgs, VendorServer } from '../__generated__/graphql';

import GET_VENDOR_SERVER from '../graphql/getVendorServer';
import QueryOneHookData from './@types/QueryOneHookData';
import { flashApolloError } from '../util/errorUtils';
import { useLazyQuery } from '@apollo/client';

type GetVendorServerData = Pick<Query, 'vendorServer'>;

type GetVendorServerByIdType = VendorServer;

export default function useGetVendorServerById(): QueryOneHookData<GetVendorServerByIdType> {
  const [getVendorServer, { called, data, error, loading }] = useLazyQuery<
    GetVendorServerData,
    QueryVendorServerArgs
  >(GET_VENDOR_SERVER, {
    fetchPolicy: 'network-only',
    onCompleted: () => {},
    onError: flashApolloError,
  });

  return {
    called,
    data: data?.vendorServer ?? undefined,
    error,
    loading,
    query: (id: string): void => {
      getVendorServer({
        variables: {
          where: { id },
        },
      });
    },
  };
}
