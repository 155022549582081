const SelectTypeContainerStyle = `
  border-bottom: 1px;
  border-color: #E4E4E4;
  border-style: solid;
  grid-auto-columns: 1fr;
  justify-content: center;
  margin: 0 0 25px;
  padding: 5px 0 20px;
  width: 100%;

  div {
    justify-self: center;
  }
`;

export default SelectTypeContainerStyle;
