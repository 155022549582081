export const Colors = {
  black: '#000000',
  cgBlue: '#247BA0',
  cgblue: '#247BA0',
  crimson: '#CE0000',
  darkGrey: '#333333',
  green: '#13CA91',
  grey: '#AAAAAA',
  hotPink: '#FE1C80',
  lighten: 'rgba(255, 255, 255, .15)',
  lightGrey: '#DDDDDD',
  lightPink: '#FFE3F1',
  orange: '#FF5F01',
  orangeCream: '#FF5F01',
  prussianBlue: '#13293D',
  sapphireBlue: '#006494',
  white: '#FFFFFF',
};

export const ThemeColors = {
  backgroundDark: Colors.sapphireBlue,
  backgroundLight: Colors.lightGrey,
  borderDark: Colors.prussianBlue,
  borderLight: Colors.lightGrey,
  error: Colors.crimson,
  fontLight: Colors.cgblue,
  fontMain: Colors.prussianBlue,
  fontRegular: Colors.sapphireBlue,
  link: Colors.cgblue,
  linkHover: Colors.sapphireBlue,
  main: Colors.prussianBlue,
};

export default Colors;
