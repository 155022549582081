/* eslint-disable sort-keys */

import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3ThemeSimplifiedIDVerificationSchema: NodeSchemaProperties = {
  simplifiedIdVerification: {
    title: '#15 Simplified Id Verification',
    type: 'object',
    properties: {
      'scanModes:enable': {
        description: '[simplifiedIdVerification.scanModes]',
        title: '#1 Scan Modes',
        type: 'boolean',
      },
      scanModes: {
        enum: ['PASSPORT_MRZ', 'BARCODE', 'MRZ', 'DEFAULT'],
        type: 'string',
      },
      'selectionCardColor:enable': {
        description: '[simplifiedIdVerification.selectionCardColor]',
        title: '#2 Selection Card Color',
        type: 'boolean',
      },
      selectionCardColor: {
        type: 'string',
      },
      'selectionCardButtonColor:enable': {
        description: '[simplifiedIdVerification.selectionCardButtonColor]',
        title: '#3 Selection Card Button Color',
        type: 'boolean',
      },
      selectionCardButtonColor: {
        type: 'string',
      },
      'selectionCardButtonTextColor:enable': {
        description: '[simplifiedIdVerification.selectionCardButtonTextColor]',
        title: '#4 Selection Card Button Text Color',
        type: 'boolean',
      },
      selectionCardButtonTextColor: {
        type: 'string',
      },
      'selectionCardTextColor:enable': {
        description: '[simplifiedIdVerification.selectionCardTextColor]',
        title: '#5 Selection Card Text Color',
        type: 'boolean',
      },
      selectionCardTextColor: {
        type: 'string',
      },
      'selectionCardPassportImage:enable': {
        description: '[simplifiedIdVerification.selectionCardPassportImage]',
        title: '#6 Selection Card Passport Image',
        type: 'boolean',
      },
      selectionCardPassportImage: {
        type: 'string',
      },
      'selectionCardBarcodeImage:enable': {
        description: '[simplifiedIdVerification.selectionCardBarcodeImage]',
        title: '#7 Selection Card Barcode Image',
        type: 'boolean',
      },
      selectionCardBarcodeImage: {
        type: 'string',
      },
      'selectionCardMrzImage:enable': {
        description: '[simplifiedIdVerification.selectionCardMrzImage]',
        title: '#8 Selection Card Mrz Image',
        type: 'boolean',
      },
      selectionCardMrzImage: {
        type: 'string',
      },
      'infoModalIcon:enable': {
        description: '[simplifiedIdVerification.infoModalIcon]',
        title: '#9 Info Modal Icon',
        type: 'boolean',
      },
      infoModalIcon: {
        type: 'string',
      },
      'infoModalPassaportImage:enable': {
        description: '[simplifiedIdVerification.infoModalPassaportImage]',
        title: '#10 Info Modal Passaport Image',
        type: 'boolean',
      },
      infoModalPassaportImage: {
        type: 'string',
      },
      'infoModalMrzImage:enable': {
        description: '[simplifiedIdVerification.infoModalMrzImage]',
        title: '#11 Info Modal Mrz Image',
        type: 'boolean',
      },
      infoModalMrzImage: {
        type: 'string',
      },
      'infoModalBarcodeImage:enable': {
        description: '[simplifiedIdVerification.infoModalBarcodeImage]',
        title: '#12 Info Modal Barcode Image',
        type: 'boolean',
      },
      infoModalBarcodeImage: {
        type: 'string',
      },
      'successImage:enable': {
        description: '[simplifiedIdVerification.successImage]',
        title: '#13 Success Image',
        type: 'boolean',
      },
      successImage: {
        type: 'string',
      },
      'errorImage:enable': {
        description: '[simplifiedIdVerification.errorImage]',
        title: '#14 Error Image',
        type: 'boolean',
      },
      errorImage: {
        type: 'string',
      },
    },
  },
};

export const mobileV3ThemeSimplifiedIDVerificationUiSchema: NodeUiSchemaProperties = {
  simplifiedIdVerification: {
    scanModes: {
      'ui:label': false,
      'ui:readonly': true,
    },
    selectionCardColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    selectionCardButtonColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    selectionCardButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    selectionCardTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    selectionCardPassportImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    selectionCardBarcodeImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    selectionCardMrzImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    infoModalIcon: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    infoModalPassaportImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    infoModalMrzImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    infoModalBarcodeImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    successImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    errorImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
  },
};
