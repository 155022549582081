import { LocationWhereInput, Query, QueryLocationsArgs } from '../../__generated__/graphql';
import { useCallback, useEffect, useRef, useState } from 'react';

import { EntityEnum } from '../../@types/client/DashboardUserRolePermissions';
import GET_LOCATIONS from '../../graphql/getLocations';
import GET_LOCATION_COUNT from '../../graphql/aggregateLocation';
import LOCATIONS_SEARCH_FIELDS from './constants/LocationsTableSearchFields';
import LOCATIONS_TABLE_HEADERS from './constants/LocationsTableHeaders';
import OrderByParams from '../../@types/client/OrderByParams';
import { RootState } from '../../redux/reducers';
import SearchField from '../../@types/SearchField';
import TableHookData from '../@types/TableHookData';
import TableHookProps from '../@types/TableHookProps';
import generateLocationsTableData from './functions/generateLocationsTableData';
import parseOrderByDataTableParams from '../../components/DataTable/util/parseOrderByDataTableParams';
import useGetMany from '../useGetMany';
import useRemoveLocation from '../useRemoveLocation';
import { useSelector } from 'react-redux';

type LocationsQueryParams = {
  orderBy?: OrderByParams;
  where: Partial<LocationWhereInput>;
};

type GetLocationsData = Pick<Query, 'locations'>;
type AggregateLocationQueryData = Pick<Query, 'aggregateLocation'>;

export default function useLocationsTableData({
  dataTableKey = 'locations',
  formName = 'location',
  headers = LOCATIONS_TABLE_HEADERS,
  isSearchable = true,
  queryOptions,
  searchFields = LOCATIONS_SEARCH_FIELDS,
}: TableHookProps<QueryLocationsArgs, LocationWhereInput>): TableHookData<LocationWhereInput> {
  const membership = useSelector((state: RootState) => state.me.activeMembership?.tenantId);
  const options = useRef<Partial<QueryLocationsArgs>>(queryOptions ?? {});
  const sortOptions = useSelector((state: RootState) => state.dataTable.sortOptions);

  const [searchParameters, setSearchParameters] = useState<LocationWhereInput>({});

  const { data, error, loading, offset, query, setOffset } = useGetMany<
    GetLocationsData,
    QueryLocationsArgs
  >({
    graphqlQuery: GET_LOCATIONS,
  });

  const {
    data: countData,
    error: countError,
    loading: countLoading,
    query: countQuery,
  } = useGetMany<AggregateLocationQueryData, QueryLocationsArgs>({
    graphqlQuery: GET_LOCATION_COUNT,
  });

  const createParams = useCallback((): LocationsQueryParams => {
    const sortParams = parseOrderByDataTableParams(sortOptions[dataTableKey]);

    return {
      ...(sortParams && { orderBy: sortParams }),
      where: {
        tenantId: {
          equals: membership,
        },
        ...options.current?.where,
        ...searchParameters,
      },
    };
  }, [membership, options, searchParameters, sortOptions, dataTableKey]);

  const get = useCallback(() => {
    const params = createParams();

    query({
      ...options.current,
      ...(params.orderBy && { orderBy: params.orderBy }),
      skip: options.current?.skip ?? offset,
      take: options.current?.take ?? 10,
      where: params.where,
    });
  }, [createParams, offset, query]);

  const getCount = useCallback(() => {
    const params = createParams();

    countQuery({
      where: params.where,
    });
  }, [countQuery, createParams]);

  useEffect(() => {
    get();
  }, [get]);

  useEffect(() => {
    getCount();
  }, [getCount]);

  return {
    count: countData?.aggregateLocation?._count?._all ?? 0,
    data: generateLocationsTableData(data).tableData,
    dataTableKey,
    dataType: 'Location',
    error: error || countError,
    formName,
    headers,
    isLoading: loading || countLoading,
    isSearchable,
    offset,
    permission: EntityEnum.LOCATION,
    remove: useRemoveLocation,
    search: (searchField: SearchField<LocationWhereInput>, searchValue: string): void => {
      setSearchParameters({
        [searchField.queryField]: searchField.transform(searchValue),
      });

      setOffset(0);
    },
    searchFields,
    setOffset,
  };
}
