import DOWNLOAD_UNIT_TYPE_TEMPLATE_CSV_FILE from '../graphql/downloadUnitTypeTemplateCSVFile';
import QueryOneHookDataWithoutParams from './@types/QueryOneHookDataWithoutParams';
import { decodeFromBase64 } from '../util/base64Utils';
import { flashApolloError } from '../util/errorUtils';
import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';

type DownloadCSVAnswer = {
  downloadUnitTypeTemplateCSVFile: {
    data: string;
  };
};

export default function useDownloadUnitTypeCSVTemplate(): QueryOneHookDataWithoutParams<
  DownloadCSVAnswer
> {
  const makeFileAndDownloadIt = (templateCSVFile: DownloadCSVAnswer): void => {
    try {
      const { downloadUnitTypeTemplateCSVFile } = templateCSVFile;
      const template = decodeFromBase64(downloadUnitTypeTemplateCSVFile.data);
      const fileName = 'importUnitTypes.csv';

      const url = window.URL.createObjectURL(new Blob([template], { type: 'text/csv;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);

      document.body.appendChild(link);

      link.click();

      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }

      window.flash({
        message: 'Template to upload unit types downloaded successfully',
        subText: fileName,
        type: 'sucess',
      });
    } catch (error) {
      window.flash({
        message: 'Error on download template to import unit types',
        subText: String(error) ?? '',
        type: 'error',
      });
    }
  };

  const [getTemplate, { called, data, error, loading }] = useLazyQuery<DownloadCSVAnswer, {}>(
    DOWNLOAD_UNIT_TYPE_TEMPLATE_CSV_FILE,
    {
      onCompleted: (downloadUnitTypeTemplateCSVFile: DownloadCSVAnswer) =>
        makeFileAndDownloadIt(downloadUnitTypeTemplateCSVFile),
      onError: flashApolloError,
    }
  );

  const query = useCallback(() => {
    if (!called) {
      getTemplate();
    }
  }, [called, getTemplate]);

  return {
    called,
    data: data ?? undefined,
    error,
    loading,
    query,
  };
}
