/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationCurrentBookingSchema: NodeSchemaProperties = {
  currentBookingScreen: {
    title: '#4 Current Booking Screen',
    type: 'object',
    properties: {
      'title:enable': {
        description: '[currentBookingScreen.title]',
        title: '#1 Title',
        type: 'boolean',
      },
      title: {
        type: 'string',
      },
      label: {
        title: '#2 Label',
        type: 'object',
        properties: {
          'hotelName:enable': {
            description: '[currentBookingScreen.label.hotelName]',
            title: '#2.1 Hotel Name',
            type: 'boolean',
          },
          hotelName: {
            type: 'string',
          },
          'arrivalDetails:enable': {
            description: '[currentBookingScreen.label.arrivalDetails]',
            title: '#2.2 Arrival Details',
            type: 'boolean',
          },
          arrivalDetails: {
            type: 'string',
          },
          'checkIn:enable': {
            description: '[currentBookingScreen.label.checkIn]',
            title: '#2.3 Check In',
            type: 'boolean',
          },
          checkIn: {
            type: 'string',
          },
          'checkOut:enable': {
            description: '[currentBookingScreen.label.checkOut]',
            title: '#2.4 Check Out',
            type: 'boolean',
          },
          checkOut: {
            type: 'string',
          },
        },
      },
      'instructionTextHtml:enable': {
        description: '[currentBookingScreen.instructionTextHtml]',
        title: '#3 Instruction Text Html',
        type: 'boolean',
      },
      instructionTextHtml: {
        type: 'string',
      },
      'checkInSuccessTitle:enable': {
        description: '[currentBookingScreen.checkInSuccessTitle]',
        title: '#4 Check In Success Title',
        type: 'boolean',
      },
      checkInSuccessTitle: {
        type: 'string',
      },
      'checkInSuccessMessage:enable': {
        description: '[currentBookingScreen.checkInSuccessMessage]',
        title: '#5 Check In Success Message',
        type: 'boolean',
      },
      checkInSuccessMessage: {
        type: 'string',
      },
      incompleteRequirements: {
        title: '#6 Incomplete Requirements',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[currentBookingScreen.incompleteRequirements.title]',
            title: '#6.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'description:enable': {
            description: '[currentBookingScreen.incompleteRequirements.description]',
            title: '#6.2 Description',
            type: 'boolean',
          },
          description: {
            type: 'string',
          },
          'button:enable': {
            description: '[currentBookingScreen.incompleteRequirements.button]',
            title: '#6.3 Button',
            type: 'boolean',
          },
          button: {
            type: 'string',
          },
        },
      },
      'disabledCheckInText:enable': {
        description: '[currentBookingScreen.disabledCheckInText]',
        title: '#7 Disabled Check In Text',
        type: 'boolean',
      },
      disabledCheckInText: {
        type: 'string',
      },
      'checkInButtonText:enable': {
        description: '[currentBookingScreen.checkInButtonText]',
        title: '#8 Check In Button Text',
        type: 'boolean',
      },
      checkInButtonText: {
        type: 'string',
      },
      'nextStepButtonText:enable': {
        description: '[currentBookingScreen.nextStepButtonText]',
        title: '#9 Next Step Button Text',
        type: 'boolean',
      },
      nextStepButtonText: {
        type: 'string',
      },
    },
  },
};

export const mobileV3LocalizationCurrentBookingUiSchema: NodeUiSchemaProperties = {
  currentBookingScreen: {
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    label: {
      hotelName: {
        'ui:label': false,
        'ui:readonly': true,
      },
      arrivalDetails: {
        'ui:label': false,
        'ui:readonly': true,
      },
      checkIn: {
        'ui:label': false,
        'ui:readonly': true,
      },
      checkOut: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    instructionTextHtml: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkInSuccessTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkInSuccessMessage: {
      'ui:label': false,
      'ui:readonly': true,
    },
    incompleteRequirements: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
      button: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    disabledCheckInText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkInButtonText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    nextStepButtonText: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
};
