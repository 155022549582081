enum RemoteAssistanceActionEnum {
  REMOVE_INCOMING_CALL = 'REMOVE_INCOMING_CALL',
  CLEAR_ACTIVE_CALL = 'CLEAR_ACTIVE_CALL',
  SET_INCOMING_CALL = 'SET_INCOMING_CALL',
  SET_ACTIVE_CALL = 'SET_ACTIVE_CALL',
  SET_JOINER_CHALLENGE = 'SET_JOINER_CHALLENGE',
  SET_VIDEO_TOKEN = 'SET_VIDEO_TOKEN',
  SET_ACTIVE_ROOM = 'SET_ACTIVE_ROOM',
}

export default RemoteAssistanceActionEnum;
