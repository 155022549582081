import { Header } from '../../../components/DataTable/@types/DataTableProps';

const ACCESS_GRANT_REQUIREMENT_HEADERS: Header[] = [
  { displayValue: 'Type', isSortable: false },
  { displayValue: 'Status', isSortable: false },
  { displayValue: 'Created At', isSortable: false },
  { displayValue: 'Updated At', isSortable: false },
];

export default ACCESS_GRANT_REQUIREMENT_HEADERS;
