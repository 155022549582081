import { CredentialProcessedPayload } from '../../../actions/@types/pendingTasks/CredentialProcessedAction';
import PendingTasksReduxState from '../../@types/PendingTasksReduxState';

export default function credentialBeingProcessedAction(
  currentState: PendingTasksReduxState,
  actionPayload: CredentialProcessedPayload
): PendingTasksReduxState {
  const { credentialId } = actionPayload;
  const newCredentialsBeingProcessed = [...currentState.credentialsBeingProcessed];

  const credentialIndex = newCredentialsBeingProcessed.findIndex(
    (credential) => credential.credentialId === credentialId
  );
  if (credentialIndex > -1) {
    newCredentialsBeingProcessed.splice(credentialIndex, 1);
  }

  return {
    ...currentState,
    credentialsBeingProcessed: newCredentialsBeingProcessed,
  };
}
