import {
  CONFIGURATION_KIOSK_SECTION_VALIDATION_SCHEMA,
  getEnrollKioskSectionValidationSchema,
} from './kioskValidationSchema';
import { Mutation, MutationEnrollKioskArgs } from '../../../__generated__/graphql';

import FormCrudAction from '../../../enums/FormCrudAction.enum';
import FormTemplateStep from '../../../templates/Form/@types/FormTemplateStep';
import KioskConfigurationsSection from '../components/KioskConfigurationsSection';
import KioskSection from '../components/KioskSection';

type EnrollKioskData = Pick<Mutation, 'enrollKiosk'>;

const getKioskSteps = (
  formAction: FormCrudAction
): FormTemplateStep<MutationEnrollKioskArgs, EnrollKioskData>[] => {
  const KIOSK_FORM_STEPS: FormTemplateStep<MutationEnrollKioskArgs, EnrollKioskData>[] = [
    {
      canBeAccessed: (): boolean => true,
      component: KioskSection,
      name: 'Kiosk',
      validationSchema: getEnrollKioskSectionValidationSchema(formAction),
    },
    {
      canBeAccessed: (): boolean => false,
      component: KioskConfigurationsSection,
      name: 'Configurations',
      validationSchema: CONFIGURATION_KIOSK_SECTION_VALIDATION_SCHEMA,
    },
  ];

  return KIOSK_FORM_STEPS;
};

export default getKioskSteps;
