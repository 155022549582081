import { gql } from '../__generated__/gql';

const UPLOAD_RESOURCES = gql(`
  mutation UploadResources($file: Upload!, $locationId: String!) {
    uploadResources(file: $file, locationId: $locationId) {
      id
      name
    }
  }
`);

export default UPLOAD_RESOURCES;
