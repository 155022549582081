import { Datum } from '../../../components/DataTable';
import { Query } from '../../../__generated__/graphql';

type GetVendorServerTableData = Pick<Query, 'vendorServers'>;

export default function generateVendorsServerTableData(data?: GetVendorServerTableData): Datum[] {
  const tableData =
    data?.vendorServers?.map((vendorServer) => {
      return {
        dataType: 'Vendor Server',
        fields: [
          {
            key: 'Vendor',
            value: vendorServer.vendor,
          },
          {
            key: 'Host',
            value: vendorServer.host,
          },
          {
            key: 'Port',
            value: vendorServer.port,
          },
          {
            key: 'isActive',
            value: vendorServer.isActive ? 'Yes' : 'No',
          },
          {
            key: 'shouldAllJoin',
            value: vendorServer.shouldAllJoin ? 'Yes' : 'No',
          },
        ],
        key: vendorServer.id,
      };
    }) || [];

  return tableData;
}
