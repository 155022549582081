import { gql } from '../__generated__/gql';

const GET_SERVICE_TABLE_DATA = gql(`
  query GetServiceTableData(
    $orderBy: [ServiceOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: ServiceWhereInput
  ) {
    services(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
      ...ServiceFields
    }
  }
`);

export default GET_SERVICE_TABLE_DATA;
