import {
  CustomLocationWhereInput,
  Query,
  QueryGetDashboardLocationsArgs,
} from '../../../__generated__/graphql';

import { GET_DASHBOARD_LOCATION_COUNT } from '../../../graphql/aggregateLocation';
import { RootState } from '../../../redux/reducers';
import { useCallback } from 'react';
import useGetMany from '../../../hooks/useGetMany';
import { useSelector } from 'react-redux';

export interface CheckLocationsAbleToPollReservationData {
  thereIsLocationEnabledToPoll: boolean;
}

interface CheckLocationsAbleToPollReservationResult {
  data?: CheckLocationsAbleToPollReservationData;
  loading: boolean;
  query: () => void;
}

type LocationsQueryParams = {
  where: Partial<CustomLocationWhereInput>;
};

type AggregateDashboardLocationQueryData = Pick<Query, 'aggregateDashboardLocation'>;

// eslint-disable-next-line max-len
export default function useCheckLocationsAbleToPollReservation(): CheckLocationsAbleToPollReservationResult {
  const tenantId = useSelector((state: RootState) => state.me.activeMembership?.tenantId);

  const {
    called: countCalled,
    data: countData,
    error: countError,
    loading: countLoading,
    query: countQuery,
  } = useGetMany<AggregateDashboardLocationQueryData, QueryGetDashboardLocationsArgs>({
    graphqlQuery: GET_DASHBOARD_LOCATION_COUNT,
  });

  const createParams = useCallback((): LocationsQueryParams => {
    return {
      where: {
        supportsPolling: {
          equals: true,
        },
        tenantId: {
          equals: tenantId,
        },
      },
    };
  }, [tenantId]);

  const getCount = useCallback(() => {
    const params = createParams();

    countQuery({
      where: params.where,
    });
  }, [countQuery, createParams]);

  const queryCount = useCallback(() => {
    if (!countCalled) {
      getCount();
    }
  }, [getCount, countCalled]);

  return {
    data:
      countLoading || countError || !countData
        ? undefined
        : {
            thereIsLocationEnabledToPoll:
              (countData.aggregateDashboardLocation?._count?._all ?? 0) > 0,
          },
    loading: countLoading,
    query: queryCount,
  };
}
