/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3ThemeTermsAndConditionsSchema: NodeSchemaProperties = {
  termsAndConditionsScreen: {
    title: '#16 Terms And Conditions Screen',
    type: 'object',
    properties: {
      signatureBox: {
        title: '#1 Signature Box',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[termsAndConditionsScreen.signatureBox.backgroundColor]',
            title: '#1.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'penColor:enable': {
            description: '[termsAndConditionsScreen.signatureBox.penColor]',
            title: '#1.2 Pen Color',
            type: 'boolean',
          },
          penColor: {
            type: 'string',
          },
          'hintColor:enable': {
            description: '[termsAndConditionsScreen.signatureBox.hintColor]',
            title: '#1.3 Hint Text Color',
            type: 'boolean',
          },
          hintColor: {
            type: 'string',
          },
        },
      },
      clearButton: {
        title: '#2 Clear Button',
        type: 'object',
        properties: {
          'borderColor:enable': {
            description: '[termsAndConditionsScreen.clearButton.borderColor]',
            title: '#2.1 Border Color',
            type: 'boolean',
          },
          borderColor: {
            type: 'string',
          },
          'textColor:enable': {
            description: '[termsAndConditionsScreen.clearButton.textColor]',
            title: '#2.2 Text Color',
            type: 'boolean',
          },
          textColor: {
            type: 'string',
          },
          'borderWidth:enable': {
            description: '[termsAndConditionsScreen.clearButton.borderWidth]',
            title: '#2.3 Border width',
            type: 'boolean',
          },
          borderWidth: {
            type: 'number',
          },
          'borderRadius:enable': {
            description: '[termsAndConditionsScreen.clearButton.borderRadius]',
            title: '#2.4 Border Radius',
            type: 'boolean',
          },
          borderRadius: {
            type: 'number',
          },
        },
      },
      submitButton: {
        title: '#3 Submit Button',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[termsAndConditionsScreen.submitButton.backgroundColor]',
            title: '#3.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'textColor:enable': {
            description: '[termsAndConditionsScreen.submitButton.textColor]',
            title: '#3.2 Text Color',
            type: 'boolean',
          },
          textColor: {
            type: 'string',
          },
          'borderColor:enable': {
            description: '[termsAndConditionsScreen.submitButton.borderColor]',
            title: '#3.3 Border Color',
            type: 'boolean',
          },
          borderColor: {
            type: 'string',
          },
          'borderWidth:enable': {
            description: '[termsAndConditionsScreen.submitButton.borderWidth]',
            title: '#3.4 Border width',
            type: 'boolean',
          },
          borderWidth: {
            type: 'number',
          },
          'borderRadius:enable': {
            description: '[termsAndConditionsScreen.submitButton.borderRadius]',
            title: '#3.5 Border Radius',
            type: 'boolean',
          },
          borderRadius: {
            type: 'number',
          },
        },
      },
    },
  },
};

export const mobileV3ThemeTermsAndConditionsUiSchema: NodeUiSchemaProperties = {
  termsAndConditionsScreen: {
    signatureBox: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      hintColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      penColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    clearButton: {
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderWidth: {
        'ui:label': false,
        'ui:readonly': true,
      },
      borderRadius: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    submitButton: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderWidth: {
        'ui:label': false,
        'ui:readonly': true,
      },
      borderRadius: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
  },
};
