import { CallRecordWhereInput } from '../../../__generated__/graphql';
import createSearchField from '../../../util/tableData/createSearchField';
import transformWhereRelationFilterInput from '../../../util/tableData/transformWhereRelationFilterInput';

const CALL_RECORDS_SEARCH_FIELDS = [
  createSearchField<CallRecordWhereInput, 'accessGrant'>({
    label: 'Code',
    queryField: 'accessGrant',
    transform: (key: string) => transformWhereRelationFilterInput(key, 'confirmationCode'),
  }),
  createSearchField<CallRecordWhereInput, 'caller'>({
    label: 'Guest',
    queryField: 'caller',
    transform: transformWhereRelationFilterInput,
  }),
  createSearchField<CallRecordWhereInput, 'location'>({
    label: 'Location',
    queryField: 'location',
    transform: transformWhereRelationFilterInput,
  }),
];

export default CALL_RECORDS_SEARCH_FIELDS;
