import { SubmitHandler, useFormContext } from 'react-hook-form';

import { ApolloError } from '@apollo/client';
import FormContext from '../../../contexts/form.context';
import FormCrudAction from '../../../enums/FormCrudAction.enum';
import KioskFormInputs from '../@types/KioskFormInputs';
import buildFieldUpdateOperationsInput from '../../../util/api/buildFieldUpdateOperationsInput';
import { flashApolloError } from '../../../util/errorUtils';
import { maintenanceTimeWindowToKioskMaintenance } from '../functions/maintenanceTimeWindowUtils';
import { omit } from 'lodash';
import { openConfirmationModal } from '../../../redux/actions/modals.actions';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import useEnrollKiosk from '../../../hooks/useEnrollKiosk';
import useUpdateKiosk from '../../../hooks/useUpdateKiosk';

interface UseSubmitInfoItemInterface {
  loadingMutation: boolean;
  submitHandler: SubmitHandler<KioskFormInputs>;
}

const useSubmitInfoItem = (): UseSubmitInfoItemInterface => {
  // Form properties
  const dispatch = useDispatch();
  const { setIdentifier, identifier: formId, step, setStep } = useContext(FormContext);
  const { setError } = useFormContext();
  const crudAction = formId ? FormCrudAction.EDIT : FormCrudAction.CREATE;

  // Mutations - Create/Update Info Item
  const { mutation: create, loading: loadingCreate } = useEnrollKiosk({
    onCompleted: (data): void => {
      setStep((step ?? 0) + 1);
      setIdentifier(data.enrollKiosk.id);
    },
  });
  const { mutation: update, loading: loadingUpdate } = useUpdateKiosk();

  // Validate
  const validateField = (fieldName: string, fieldValue: Date | string | undefined): boolean => {
    if (!fieldValue) {
      setError(fieldName, {
        message: 'Required field',
        types: {
          required: true,
        },
      });
      return false;
    }

    return true;
  };

  // Submit
  const submitHandler: SubmitHandler<KioskFormInputs> = (inputs: KioskFormInputs): void => {
    dispatch(
      openConfirmationModal({
        action: (): void => {
          try {
            const partialInputs = omit(inputs, [
              'code',
              'location',
              'maintenanceMode',
              'maintenanceStartDate',
              'maintenanceStartTime',
              'maintenanceEndDate',
              'maintenanceEndTime',
            ]);

            if (crudAction === FormCrudAction.CREATE) {
              create({
                ...partialInputs,
                code: inputs.code ?? '',
                locationId: inputs.location.value,
              });
            } else {
              let maintenanceStartsAt: Date | undefined;
              let maintenanceEndsAt: Date | undefined;

              const {
                maintenanceMode,
                maintenanceEndDate,
                maintenanceEndTime,
                maintenanceStartDate,
                maintenanceStartTime,
              } = inputs;
              if (maintenanceMode) {
                const validateStartDate = validateField(
                  'maintenanceStartDate',
                  maintenanceStartDate
                );
                const validateStartTime = validateField(
                  'maintenanceStartTime',
                  maintenanceStartTime
                );
                const validateEndTime = validateField('maintenanceEndTime', maintenanceEndTime);

                if (!validateStartDate || !validateStartTime || !validateEndTime) {
                  return;
                }

                ({
                  maintenanceStartsAt,
                  maintenanceEndsAt,
                } = maintenanceTimeWindowToKioskMaintenance({
                  maintenanceEndDate,
                  maintenanceStartDate,
                }));
              }

              update(
                {
                  hasDispenser: { set: partialInputs.hasDispenser },
                  hasTerminal: { set: partialInputs.hasTerminal },
                  name: { set: partialInputs.name },
                  powerSwitchId: { set: partialInputs.powerSwitchId },
                  dispenserConfigList: partialInputs.dispenserConfigList,
                  dispenserType: { set: partialInputs.dispenserType },
                  rfidTagType: partialInputs.rfidTagType
                    ? { set: partialInputs.rfidTagType }
                    : null,
                  location: {
                    connect: {
                      id: inputs.location.value,
                    },
                  },
                  maintenanceEndsAt: buildFieldUpdateOperationsInput(maintenanceEndsAt),
                  maintenanceEndsTime: maintenanceEndTime ?? null,
                  maintenanceStartsAt: buildFieldUpdateOperationsInput(maintenanceStartsAt),
                  maintenanceStartsTime: maintenanceStartTime ?? null,
                },
                formId
              );
            }
          } catch (e) {
            flashApolloError(e as ApolloError);
          }
        },
      })
    );
  };

  return {
    loadingMutation: loadingCreate || loadingUpdate,
    submitHandler,
  };
};

export default useSubmitInfoItem;
