import { gql } from '../__generated__/gql';

const GET_CALL_RECORD_COUNT = gql(`
  query GetCallRecordCount($where: CallRecordWhereInput!) {
    aggregateCallRecord(where: $where) {
      _count {
        _all
      }
    }
  }
`);

export default GET_CALL_RECORD_COUNT;
