import Theme from '../../../../../theme';

const SearchInputInnerContainerStyle = `
  background: rgba(${Theme.colors.white.rgb}, 0.05);
  border-radius: 16px;
  grid-auto-flow: column;
  grid-template-columns: max-content 1fr max-content;
  height: 56px;
  overflow: hidden;
  padding: 4px 4px 4px 15px;
  width: 320px;

  @media only screen and (max-width: 1450px) {
    border-radius: 8px;
    height: 45px;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  @media only screen and (max-width: 1200px) {
    border-radius: 8px;
    height: 45px;
    
    svg {
      height: 16px;
      width: 16px;
    }
  }
`;

export default SearchInputInnerContainerStyle;
