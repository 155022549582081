/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationIdVerificationResultSchema: NodeSchemaProperties = {
  idVerificationResultScreen: {
    title: '#17 Id Verification Result Screen',
    type: 'object',
    properties: {
      waitingIdVerificationResult: {
        title: '#1 Waiting Id Verification Result',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[idVerificationResultScreen.waitingIdVerificationResult.title]',
            title: '#1.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'description:enable': {
            description: '[idVerificationResultScreen.waitingIdVerificationResult.description]',
            title: '#1.2 Description',
            type: 'boolean',
          },
          description: {
            type: 'string',
          },
        },
      },
      idVerificationFailed: {
        title: '#2 Id Verification Failed',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[idVerificationResultScreen.idVerificationFailed.title]',
            title: '#2.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
        },
      },
      success: {
        title: '#3 Success',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[idVerificationResultScreen.success.title]',
            title: '#3.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'description:enable': {
            description: '[idVerificationResultScreen.success.description]',
            title: '#3.2 Description',
            type: 'boolean',
          },
          description: {
            type: 'string',
          },
        },
      },
      error: {
        title: '#4 Error',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[idVerificationResultScreen.error.title]',
            title: '#4.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
        },
      },
    },
  },
};

export const mobileV3LocalizationIdVerificationResultUiSchema: NodeUiSchemaProperties = {
  idVerificationResultScreen: {
    waitingIdVerificationResult: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    idVerificationFailed: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    success: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    error: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
  },
};
