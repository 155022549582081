import { gql } from '../../../__generated__/gql';

const GET_KIOSKS_LIVE_USAGE_DATA = gql(`
  query GetKiosksLiveUsageData($kioskAndAccessGrantParams: [KioskAndAccessGrantParams!]!) {
    getKiosksLiveUsageData(kioskAndAccessGrantParams: $kioskAndAccessGrantParams) {
      data {
        kiosk {
          id
          name
          location {
            id
            name
            timezone
          }
        }
        accessGrant {
          id
          confirmationCode
          startsAt
          user {
            id
            name
            email
          }
          totalGuests {
            adults
            children
            total
          }
          bookedPackages {
            title
            quantity
          }
        }
      }
    }
  }
`);

export default GET_KIOSKS_LIVE_USAGE_DATA;
