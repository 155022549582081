import { gql } from '../__generated__/gql';

const UPDATE_LOCATION = gql(`
  mutation UpdateLocation($data: LocationUpdateInput!, $where: LocationWhereUniqueInput!) {
    updateLocation(data: $data, where: $where) {
      address
      createdAt
      defaultEndTime
      defaultStartTime
      defaultRequirements {
        id
        type
      }
      email
      id
      imageUrl
      name
      phoneNumber
      updatedAt
      logoUrl
      timezone
      vendorServers {
        id
        host
        port
        vendor
      }
    }
  }
`);

export default UPDATE_LOCATION;
