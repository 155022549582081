import { Datum } from '../../../components/DataTable';
import { Query } from '../../../__generated__/graphql';
import { UseDateTimeFormatterInterface } from '../../useDateTimeFormatter';
import links from '../../../util/links';

type GetAccessGrantTableData = Pick<Query, 'findDashboardAccessGrants'>;

export default function generateAccessGrantsTableData(
  formatter: UseDateTimeFormatterInterface,
  data?: GetAccessGrantTableData
): Datum[] {
  const { formatDate, formatDateAndTime } = formatter;
  const accessGrants = data?.findDashboardAccessGrants || [];

  const tableData = accessGrants.map((accessGrant) => {
    return {
      dataType: 'AccessGrant',
      fields: [
        {
          key: 'Guest',
          link: `${links.paths.USERS}/${accessGrant.user.id}`,
          value: accessGrant.user.name ?? accessGrant.user.id,
        },
        {
          key: 'State',
          value: accessGrant.state ?? 'Unknown',
        },
        {
          key: 'Confirmation #',
          link: `${links.paths.ACCESS_GRANTS}/${accessGrant.id}`,
          value: accessGrant.confirmationCode || 'N/A',
        },
        {
          key: 'ETA',
          value: accessGrant.arrivalTime
            ? formatDateAndTime(accessGrant.arrivalTime as string, {
                timezone: accessGrant.location.timezone,
              })
            : '',
        },
        {
          key: 'Location',
          value: accessGrant.location.name,
        },
        {
          key: 'Start',
          value: formatDate(accessGrant.startsAt, { timezone: accessGrant.location.timezone }),
        },
        {
          key: 'End',
          value: formatDate(accessGrant.endsAt, { timezone: accessGrant.location.timezone }),
        },
        {
          key: 'In/Out',
          value: `${accessGrant.startTime ?? '?'}/${accessGrant.endTime ?? '?'}`,
        },
        {
          key: 'LastSeenAt',
          value: accessGrant.user.lastSeenAt
            ? formatDate(accessGrant.user.lastSeenAt, { timezone: accessGrant.location.timezone })
            : 'N/A',
        },
        {
          key: 'Status',
          value: accessGrant.status ?? 'Unknown',
        },
      ],
      key: accessGrant.id,
    };
  });

  return tableData;
}
