export default function parseEmailWithDisplayName(
  emailString: string
): { name?: string; email: string } {
  const [partA, partB] = emailString.split(/\s*</g);

  if (partB) {
    return {
      email: partB.replace(/[>\s]/g, ''),
      name: partA.trimEnd(),
    };
  }

  return {
    email: partA.trimEnd(),
    name: undefined,
  };
}
