import MutationHookData from '../@types/MutationHookData';
import MutationHookParams from '../@types/MutationHookParams';
import POLL_RESERVATION_FROM_PMS from '../../graphql/pollReservationFromPMS';
import PollReservationFormInputs from '../../components/AccessGrantPollReservationForm/@types/PollReservationFormInputs';
import PollReservationInputs from '../../components/AccessGrantPollReservationForm/@types/PollReservationInputs';
import PollReservationTypesData from '../../components/AccessGrantPollReservationForm/@types/PollReservationTypesData';
import { RootState } from '../../redux/reducers';
import { flashApolloError } from '../../util/errorUtils';
import useCompleteCallPollReservation from './hooks/useCompleteCallPollReservation';
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useState } from 'react';

export default function usePollReservations({
  onError = flashApolloError,
}: MutationHookParams<PollReservationTypesData> = {}): MutationHookData<
  PollReservationFormInputs,
  PollReservationTypesData
> {
  const me = useSelector((state: RootState) => state.me);
  const activeTenantId = me.activeMembership?.tenantId ?? '';

  const [confirmationCode, setConfirmationCode] = useState<string>();
  const [locationId, setLocationId] = useState<string>();

  const { onCompleteCallPollReservation } = useCompleteCallPollReservation();

  const [create, { data, error, loading }] = useMutation<
    PollReservationTypesData,
    PollReservationInputs
  >(POLL_RESERVATION_FROM_PMS, {
    onCompleted: ({ pollReservationFromPms }): void => {
      onCompleteCallPollReservation(
        pollReservationFromPms,
        activeTenantId ?? '',
        locationId ?? '',
        confirmationCode
      );
    },
    onError,
  });

  return {
    data,
    error,
    loading,
    mutation: (inputs: PollReservationFormInputs): void => {
      setConfirmationCode(inputs.confirmationCode);
      setLocationId(inputs.location.value);

      create({
        variables: {
          confirmationCode: inputs.confirmationCode,
          locationId: inputs.location.value,
          tenantId: activeTenantId,
        },
      });
    },
  };
}
