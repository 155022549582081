import Filter from '../../../components/DataTable/@types/Filter';

export default function addKioskStatusFilters(filters: Filter[]): Filter[] {
  filters.push(
    {
      displayKey: 'Status',
      displayValue: 'Online',
      key: 'status',
      value: 'gte',
    },
    {
      displayKey: 'Status',
      displayValue: 'Offline',
      key: 'status',
      value: 'lte',
    }
  );

  return filters;
}
