import 'styled-components/macro';

import React, { PropsWithChildren, useState } from 'react';

import Container from '../Container';
import TooltipContainerStyle from './styles/TooltipContainer.style';

interface TooltipProps {
  content: string;
  direction?: 'top' | 'down' | 'left' | 'right';
}

const Tooltip: React.FC<TooltipProps> = ({
  content,
  children,
  direction = 'top',
}: PropsWithChildren<TooltipProps>) => {
  const [revealTimeout, setRevealTimeout] = useState<number | null>(null);
  const [isVisible, setVisible] = useState<boolean>(false);

  function toggleOn(): void {
    const interval = setTimeout(() => {
      setVisible(true);
    }, 400);

    setRevealTimeout(interval);
  }

  function toggleOff(): void {
    if (revealTimeout) {
      clearInterval(revealTimeout);
    }

    setRevealTimeout(null);
    setVisible(false);
  }

  return (
    <Container css={TooltipContainerStyle} onPointerEnter={toggleOn} onPointerLeave={toggleOff}>
      {children}
      {isVisible && <Container className={`tooltipContainer ${direction}`}>{content}</Container>}
    </Container>
  );
};

export default Tooltip;
