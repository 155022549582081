import 'styled-components/macro';

import TextContent, { TextContentModel } from '../../TextContent';

import Container from '../../Container';
import DetailsTextStyle from '../styles/DetailsText.style';
import { MdDateRange } from 'react-icons/md';
import React from 'react';
import useDateTimeFormatter from '../../../hooks/useDateTimeFormatter';

interface DatesInfoProps {
  startsAt: Date;
  endsAt: Date;
}
const DatesInfo: React.FC<DatesInfoProps> = ({ startsAt, endsAt }: DatesInfoProps) => {
  const { formatDate } = useDateTimeFormatter();

  const datesText = `${formatDate(startsAt)} - ${formatDate(endsAt)}`;

  return (
    <Container css={DetailsTextStyle}>
      <span>
        <MdDateRange size="22px" />
      </span>{' '}
      <TextContent model={TextContentModel.BODY_LARGE}>{datesText}</TextContent>
    </Container>
  );
};

export default DatesInfo;
