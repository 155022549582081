/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3ThemeExpectedArrivalSchema: NodeSchemaProperties = {
  expectedArrivalScreen: {
    title: '#12 Expected Arrival Screen',
    type: 'object',
    properties: {
      'displayExpectedArrival:enable': {
        description: '[expectedArrivalScreen.displayExpectedArrival]',
        title: '#1 Display Expected Arrival',
        type: 'boolean',
      },
      displayExpectedArrival: {
        type: undefined,
        enum: [true, false],
      },
      'confirmImageUrl:enable': {
        description: '[expectedArrivalScreen.confirmImageUrl]',
        title: '#2 Confirm Image Url',
        type: 'boolean',
      },
      confirmImageUrl: {
        type: 'string',
      },
      'successImageUrl:enable': {
        description: '[expectedArrivalScreen.successImageUrl]',
        title: '#3 Success Image Url',
        type: 'boolean',
      },
      successImageUrl: {
        type: 'string',
      },
      'errorImageUrl:enable': {
        description: '[expectedArrivalScreen.errorImageUrl]',
        title: '#4 Error Image Url',
        type: 'boolean',
      },
      errorImageUrl: {
        type: 'string',
      },
      'datePickerBackgroundColor:enable': {
        description: '[expectedArrivalScreen.datePickerBackgroundColor]',
        title: '#5 Date Picker Background Color',
        type: 'boolean',
      },
      datePickerBackgroundColor: {
        type: 'string',
      },
      'datePickerTextColor:enable': {
        description: '[expectedArrivalScreen.datePickerTextColor]',
        title: '#6 Date Picker Text Color',
        type: 'boolean',
      },
      datePickerTextColor: {
        type: 'string',
      },
      'datePickerBorderColor:enable': {
        description: '[expectedArrivalScreen.datePickerBorderColor]',
        title: '#7 Date Picker Border Color',
        type: 'boolean',
      },
      datePickerBorderColor: {
        type: 'string',
      },
    },
  },
};

export const mobileV3ThemeExpectedArrivalUiSchema: NodeUiSchemaProperties = {
  expectedArrivalScreen: {
    displayExpectedArrival: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    confirmImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    successImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    errorImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    datePickerBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    datePickerTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    datePickerBorderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
  },
};
