import { gql } from '../__generated__/gql';

const ARRIVALS_BY_DAY = gql(`
  query ArrivalsByDay($days: Float, $tenantId: String!, $locationId: String) {
    generateArrivalsByDayReport(days: $days, tenantId: $tenantId, locationId: $locationId) {
      success
    }
  }
`);

export default ARRIVALS_BY_DAY;
