import { gql } from '../__generated__/gql';

const GET_CALL_RECORDS = gql(`
  query GetCallRecordsTableData(
    $cursor: CallRecordWhereUniqueInput
    $distinct: [CallRecordScalarFieldEnum!]
    $orderBy: [CallRecordOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: CallRecordWhereInput
  ) {
    callRecords(
      cursor: $cursor
      distinct: $distinct
      orderBy: $orderBy
      skip: $skip
      take: $take
      where: $where
    ) {
      accessGrant {
        confirmationCode
      }
      agent {
        id
        name
      }
      caller {
        id
        name
      }
      duration
      id
      kiosk {
        id
        name
      }
      location {
        id
        name
      }
      status
      type
      users {
        id
        name
      }
    }
  }
`);

export default GET_CALL_RECORDS;
