import ReceiveAggregateArrivalsAction from './@types/ReceiveAggregateArrivalsAction';
import ReportingActions from './enums/actions.enum';
import ReportingReduxState from './@types/ReportingReduxState';

const INITIAL_STATE = {
  arrivalsByDay: [],
  loading: false,
};

export default function reportingReducer(
  state: ReportingReduxState = INITIAL_STATE,
  action: ReceiveAggregateArrivalsAction
): ReportingReduxState {
  switch (action.type) {
    case ReportingActions.RECEIVE_AGGREGATE_ARRIVALS: {
      return {
        ...state,
        arrivalsByDay: action.payload.aggregateArrivals,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}
