import styled from 'styled-components';

const Form = styled.form`
  box-sizing: border-box;
  position: relative;
  padding: 0 10px;
  display: grid;
  grid-template-rows: 1fr max-content;
  height: 100%;
`;

Form.displayName = 'Form';

export default Form;
