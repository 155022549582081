import Filter from '../../../../components/DataTable/@types/Filter';
import { ServiceType } from '../../../../__generated__/graphql';
import { serviceTypeLabels } from '../../../../enums/ServiceType.enum';

function addTypeFilters(filters: Filter[]): void {
  Object.values(ServiceType)
    .sort()
    .forEach((type) => {
      const displayValue = serviceTypeLabels[type.toUpperCase()];

      filters.push({
        displayKey: 'Type',
        displayValue: displayValue ?? type,
        key: 'type',
        value: type,
      });
    });
}

export default function useServiceFilters(): Filter[] {
  const filters: Filter[] = [];

  addTypeFilters(filters);

  return filters;
}
