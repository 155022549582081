import DELETE_PAYMENT from '../graphql/deletePayment';
import { QueryPaymentArgs } from '../__generated__/graphql';
import RemoveHookData from './@types/RemoveHookData';
import { closeRemoveModal } from '../redux/actions/modals.actions';
import { flashApolloError } from '../util/errorUtils';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

export default function useRemovePayment(): RemoveHookData {
  const dispatch = useDispatch();
  const history = useHistory();

  const [remove, { error, loading }] = useMutation<
    { deletePayment: { id: string } },
    QueryPaymentArgs
  >(DELETE_PAYMENT, {
    onCompleted: () => {
      dispatch(closeRemoveModal());

      window.flash({
        message: `Card removed successfully`,
      });

      history.go(0);
    },
    onError: flashApolloError,
  });

  return {
    error,
    loading,
    mutation: (id: string): void => {
      remove({
        variables: {
          where: { id },
        },
      });
    },
  };
}
