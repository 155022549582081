import * as yup from 'yup';

const BASE_VENDOR_SERVER_VALIDATION_SCHEMA = yup.object().shape({
  host: yup.string().required('Host is required'),
  password: yup.string().when('isActive', {
    is: true,
    then: yup.string().required('Password is required'),
  }),
  port: yup.string().required('Port is required'),
  username: yup.string().when('isActive', {
    is: true,
    then: yup.string().required('Username is required'),
  }),
  vendor: yup.string().required('Vendor is required'),
});

const ASSA_ABLOY_VALIDATION_SCHEMA = yup.object().shape({
  assaCardType: yup.string().nullable(),
  lockServiceCode: yup
    .string()
    .nullable()
    .when('vendor', {
      is: 'ASSA_ABLOY',
      then: yup.string().nullable().required('Lock service code is required'),
    }),
});

const SALTO_VALIDATION_SCHEMA = yup.object().shape({
  trailerBlock: yup
    .string()
    .nullable()
    .when('vendor', {
      is: 'SALTO',
      then: yup.string().nullable().required('Trailer block code is required'),
    }),
});

const ASSA_ABLOY_VISIONLINE_VALIDATION_SCHEMA = yup.object().shape({
  accessID: yup
    .string()
    .nullable()
    .when('vendor', {
      is: 'ASSA_ABLOY_VISIONLINE',
      then: yup.string().nullable().required('Access ID is required'),
    }),
  assaCardType: yup.string().nullable(),
  lockServiceCode: yup
    .string()
    .nullable()
    .when('vendor', {
      is: 'ASSA_ABLOY_VISIONLINE',
      then: yup.string().nullable().required('Lock service code is required'),
    }),
  uuid: yup
    .string()
    .nullable()
    .when('vendor', {
      is: 'ASSA_ABLOY_VISIONLINE',
      then: yup.string().nullable().required('UUID is required'),
    }),
});

const ONITY_VALIDATION_SCHEMA = yup.object().shape({
  onityCoreCertificateBase64: yup
    .string()
    .nullable()
    .when('vendor', {
      is: 'ONITY',
      then: yup.string().nullable().required('Core certificate base64 is required'),
    }),
  onityCoreCertificatePassword: yup
    .string()
    .nullable()
    .when('vendor', {
      is: 'ONITY',
      then: yup.string().nullable().required('Core certificate password is required'),
    }),
  onityCoreOwnerId: yup
    .string()
    .nullable()
    .when('vendor', {
      is: 'ONITY',
      then: yup.string().nullable().required('Core owner Id is required'),
    }),
  onityCoreOwnerName: yup
    .string()
    .nullable()
    .when('vendor', {
      is: 'ONITY',
      then: yup.string().nullable().required('Core owner name is required'),
    }),
  onityFrameworkHost: yup
    .string()
    .nullable()
    .when('vendor', {
      is: 'ONITY',
      then: yup.string().nullable().required('Framework host is required'),
    }),
  onityOnPortalHost: yup
    .string()
    .nullable()
    .when('vendor', {
      is: 'ONITY',
      then: yup.string().nullable().required('OnPortal host is required'),
    }),
  onityOnPortalPassword: yup
    .string()
    .nullable()
    .when('vendor', {
      is: 'ONITY',
      then: yup.string().nullable().required('OnPortal password is required'),
    }),
  onityOnPortalPort: yup
    .string()
    .nullable()
    .when('vendor', {
      is: 'ONITY',
      then: yup.string().nullable().required('OnPortal port is required'),
    }),
  onityOnPortalUsername: yup
    .string()
    .nullable()
    .when('vendor', {
      is: 'ONITY',
      then: yup.string().nullable().required('OnPortal username is required'),
    }),
});

const OPEN_PATH_VALIDATION_SCHEMA = yup.object().shape({
  credentialTypeId: yup
    .string()
    .nullable()
    .when('vendor', {
      is: 'OPENPATH',
      then: yup.string().nullable().required('Credential type id is required'),
    }),
  namespaceId: yup
    .string()
    .nullable()
    .when('vendor', {
      is: 'OPENPATH',
      then: yup.string().nullable().required('Namespace id is required'),
    }),
  userScheduleTypeId: yup
    .string()
    .nullable()
    .when('vendor', {
      is: 'OPENPATH',
      then: yup.string().nullable().required('User schedule type id is required'),
    }),
});

const VENDOR_SERVER_VALIDATION_SCHEMA = BASE_VENDOR_SERVER_VALIDATION_SCHEMA.concat(
  ASSA_ABLOY_VALIDATION_SCHEMA
)
  .concat(SALTO_VALIDATION_SCHEMA)
  .concat(ASSA_ABLOY_VISIONLINE_VALIDATION_SCHEMA)
  .concat(ONITY_VALIDATION_SCHEMA)
  .concat(OPEN_PATH_VALIDATION_SCHEMA);

export default VENDOR_SERVER_VALIDATION_SCHEMA;
