import DataTable from '../../../components/DataTable';
import { LocationPageInfo } from '../@types/GetLocationPageInfo';
import React from 'react';
import { useParams } from 'react-router-dom';
import useResourcesTableData from '../../../hooks/useResourcesTableData';

interface ResourcesTabProps {
  location: LocationPageInfo;
}

const ResourcesTab: React.FC<ResourcesTabProps> = ({ location }: ResourcesTabProps) => {
  const { locationId } = useParams<{ locationId: string }>();

  const tableData = useResourcesTableData({
    dataTableKey: 'resources-Location',
    queryOptions: {
      where: {
        locationId: {
          equals: locationId,
        },
      },
    },
    relatedLocation: location,
  });

  return <DataTable {...tableData} />;
};

export default ResourcesTab;
