import 'styled-components/macro';

import Button from '../../components/Button';
import ButtonModel from '../../components/Button/enums/ButtonModel.enum';
import Container from '../../components/Container';
import { DetailPageItem } from '.';
import DetailPageItemStyle from './styles/DetailPageItem.style';
import { Link } from 'react-router-dom';
import React from 'react';
import TextContent from '../../components/TextContent';

export const createButtonsFromDetailItems = (detailItems: DetailPageItem[]): JSX.Element[] => {
  const disabledTextStyle = `
    flex: 3;
  `;
  const mainTextStyle = `
    ${disabledTextStyle}
    font-weight: bold;
  `;

  const getLinkText = (item: DetailPageItem): JSX.Element => {
    return (
      <Link css={item.disabled ? disabledTextStyle : mainTextStyle} to={item.link!}>
        {item.text}
      </Link>
    );
  };

  const getLabel = (label: string): JSX.Element => {
    return (
      <TextContent
        css={`
          flex: 1;
          padding-right: 10px;
        `}
      >
        {`${label}:`}
      </TextContent>
    );
  };

  const getText = (item: DetailPageItem): JSX.Element => {
    if (item.link) {
      return getLinkText(item);
    }

    return (
      <TextContent css={item.disabled ? disabledTextStyle : mainTextStyle} style={item.textStyle}>
        {item.text}
      </TextContent>
    );
  };

  const getClickableItem = (item: DetailPageItem): JSX.Element => {
    return (
      <Button
        className={`detail-page-item__button ${
          item.buttonIsSelected ? 'detail-page-item__button__selected' : ''
        }`}
        css={DetailPageItemStyle}
        model={ButtonModel.DEFAULT}
        onClick={item.onClick}
        disabled={item.disabled}
      >
        {item.icon ? <item.icon /> : <div />}
        <Container>
          {item.label && getLabel(item.label)}
          {getText(item)}
        </Container>
      </Button>
    );
  };

  const getItem = (item: DetailPageItem): JSX.Element => {
    return (
      <Container css={DetailPageItemStyle} onClick={item.onClick}>
        {item.icon ? <item.icon /> : <div />}
        <Container
          css={`
            display: flex;
            align-items: center;
          `}
        >
          {item.label && getLabel(item.label)}
          {getText(item)}
        </Container>
      </Container>
    );
  };

  return detailItems.map((item) => (
    <React.Fragment key={item.text}>
      {item.onClick ? getClickableItem(item) : getItem(item)}
    </React.Fragment>
  ));
};
