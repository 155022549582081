import {
  Mutation,
  MutationUpdateServiceAndUploadImageFileArgs,
  ServiceStatus,
} from '../__generated__/graphql';

import RemoveHookData from './@types/RemoveHookData';
import UPDATE_SERVICE from '../graphql/updateService';
import { closeRemoveModal } from '../redux/actions/modals.actions';
import { flashApolloError } from '../util/errorUtils';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

type DeleteServiceData = Pick<Mutation, 'updateServiceAndUploadImageFile'>;

export default function useRemoveServiceSoftly(): RemoveHookData {
  const dispatch = useDispatch();
  const history = useHistory();

  const [update, { error, loading }] = useMutation<
    DeleteServiceData,
    MutationUpdateServiceAndUploadImageFileArgs
  >(UPDATE_SERVICE, {
    onCompleted: () => {
      dispatch(closeRemoveModal());

      window.flash({
        message: `Service removed successfully`,
      });

      history.push({
        pathname: history.location.pathname,
        search: history.location.search,
      });
    },
    onError: flashApolloError,
  });

  return {
    error,
    loading,
    mutation: (id = ''): void => {
      update({
        variables: {
          data: {
            status: ServiceStatus.Inactive,
          },
          where: { id },
        },
      });
    },
  };
}
