import { gql } from '../__generated__/gql';

const GET_RESOURCE_GROUPS = gql(`
  query ResourceGroups(
    $orderBy: [ResourceGroupOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: ResourceGroupWhereInput
  ) {
    resourceGroups(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
      id
      isDefault
      name
      resources {
        id
        name
      }
    }
  }
`);

export default GET_RESOURCE_GROUPS;
