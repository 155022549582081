import { Query, QueryAccessGrantsArgs, QueryMode, SortOrder } from '../__generated__/graphql';

import { RootState } from '../redux/reducers';
import SEARCH_ACCESS_GRANTS from '../graphql/searchAccessGrants';
import SelectOption from '../@types/react/SelectOption';
import generateMembershipQuery from '../util/api/generateMembershipQuery';
import { useApolloClient } from '@apollo/client';
import { useSelector } from 'react-redux';

type SearchAccessGrantsData = Pick<Query, 'accessGrants'>;

export default function useLoadAccessGrantOptions(): (input: string) => Promise<SelectOption[]> {
  const client = useApolloClient();
  const me = useSelector((state: RootState) => state.me);
  const membership = me.activeMembership;

  return async (input: string): Promise<SelectOption[]> => {
    const { data } = await client.query<SearchAccessGrantsData, QueryAccessGrantsArgs>({
      query: SEARCH_ACCESS_GRANTS,
      variables: {
        orderBy: [
          {
            confirmationCode: { sort: SortOrder.Asc },
          },
        ],
        take: 10,
        where: {
          confirmationCode: {
            contains: input,
            mode: QueryMode.Insensitive,
          },
          ...generateMembershipQuery(membership),
        },
      },
    });

    return (
      data.accessGrants?.map((accessGrant) => ({
        label: accessGrant.confirmationCode ?? accessGrant.id,
        value: accessGrant.id,
      })) ?? []
    );
  };
}
