import { gql } from '../__generated__/gql';

const DELETE_UNIT_TYPE = gql(`
  mutation DeleteOneUnitType($where: UnitTypeWhereUniqueInput!) {
    deleteOneUnitType(where: $where) {
      id
      type
    }
  }
`);

export default DELETE_UNIT_TYPE;
