import { Mutation, QueryUnitTypeArgs } from '../__generated__/graphql';

import DELETE_UNIT_TYPE from '../graphql/deleteUnitType';
import RemoveHookData from './@types/RemoveHookData';
import { closeRemoveModal } from '../redux/actions/modals.actions';
import { flashApolloError } from '../util/errorUtils';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

type DeleteUnitTypeData = Pick<Mutation, 'deleteOneUnitType'>;

export default function useRemoveUnitType(): RemoveHookData {
  const dispatch = useDispatch();
  const history = useHistory();

  const [remove, { error, loading }] = useMutation<DeleteUnitTypeData, QueryUnitTypeArgs>(
    DELETE_UNIT_TYPE,
    {
      onCompleted: ({ deleteOneUnitType }) => {
        dispatch(closeRemoveModal());

        if (deleteOneUnitType) {
          window.flash({
            message: `Unit Type: ${deleteOneUnitType.type} removed successfully`,
          });
        } else {
          window.flash({ message: 'Fail to remove unit type' });
        }

        history.go(0);
      },
      onError: flashApolloError,
    }
  );

  return {
    error,
    loading,
    mutation: (id: string): void => {
      remove({
        variables: {
          where: { id },
        },
      });
    },
  };
}
