import Theme from '../../../theme';

const SettingsTabStyle = `
  background: ${Theme.colors.darkAlternate.hexCode};
  border-radius: 24px;
  padding: 30px 30px 35px;
  display: flex;
  min-height: 800px;
`;

export default SettingsTabStyle;
