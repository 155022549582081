import { Colors } from '../../../theme/colors';

const DetailPageBannerHeaderStyle = `
  align-items: center;
  border-bottom: 1px solid ${Colors.shuttleGrey};
  color: ${Colors.white};
  display: flex;
  padding: 0 0 22px;

  > button {
    height: 50px;
    margin: 0 30px 0 0;
    width: 50px;
  }

  @media only screen and (min-width: 1450px) {
    font-size: 30px;
  }
`;

export default DetailPageBannerHeaderStyle;
