const ListPageBannerStyle = `
  margin: 0 0 60px;

  @media only screen and (max-width: 1450px) {
    margin: 0 0 25px;    
  }

  @media only screen and (max-width: 1450px) {
    margin: 0 0 25px;
  }
`;

export default ListPageBannerStyle;
