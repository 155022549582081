import { Mutation, QueryUserArgs } from '../__generated__/graphql';

import DELETE_USER from '../graphql/deleteUser';
import RemoveHookData from './@types/RemoveHookData';
import { closeRemoveModal } from '../redux/actions/modals.actions';
import { flashApolloError } from '../util/errorUtils';
import links from '../util/links';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';

type DeleteUserData = Pick<Mutation, 'deleteUser'>;

export default function useRemoveUser(): RemoveHookData {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [remove, { error, loading }] = useMutation<DeleteUserData, QueryUserArgs>(DELETE_USER, {
    onCompleted: ({ deleteUser }) => {
      dispatch(closeRemoveModal());

      window.flash({
        message: `User: ${deleteUser.name} removed successfully`,
      });

      if (location.pathname === links.paths.USERS) {
        history.go(0);
      } else {
        history.push(links.paths.USERS);
      }
    },
    onError: flashApolloError,
  });

  return {
    error,
    loading,
    mutation: (id: string): void => {
      remove({
        variables: {
          where: {
            id: { equals: id },
          },
        },
      });
    },
  };
}
