import * as yup from 'yup';

import URL_VALIDATION from '../../../constants/yupValidations/constants/url';

const AMENITY_VALIDATION_SCHEMA = yup.object().shape({
  description: yup.string(),
  group: yup.object().shape({
    label: yup.string().required('Groups is required'),
    value: yup.string().required('Groups is required'),
  }),
  imageType: yup.string().required('Image type is required'),
  imageUrl: URL_VALIDATION,
  location: yup.object().shape({
    label: yup.string().required('Location is required'),
    value: yup.string().required('Location is required'),
  }),
  title: yup.string().required('Title is required'),
});

export default AMENITY_VALIDATION_SCHEMA;
