import { gql } from '../__generated__/gql';

const GET_UNIT_TYPE_TABLE_DATA = gql(`
  query GetUnitTypeTableData(
    $orderBy: [UnitTypeOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: UnitTypeWhereInput
  ) {
    unitTypes(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
      ...UnitTypeFields
    }
  }
`);

export default GET_UNIT_TYPE_TABLE_DATA;
