import differenceBetweenObjects from './differenceBetweenObjects';
import getNotMappedProperties from './getNotMappedProperties';
import iterateInJson from './iterateInJson';
import jsonSchemaConstantKeys from '../constants/jsonSchemaConstantKeys';
import { merge } from 'lodash';

export default function fillJsonWithEditedData(
  jsonData: Record<string, unknown>,
  previousJsonCurrentLevel?: Record<string, unknown>,
  uiSchema?: Record<string, unknown>
): Record<string, unknown> {
  const dataToBeSubmited = {};

  iterateInJson(jsonData, (_data, parentKey, key): boolean => {
    const mainKey = `${parentKey}.${key}`;
    if (
      mainKey.indexOf(jsonSchemaConstantKeys.schemaEnable) ===
      mainKey.length - jsonSchemaConstantKeys.schemaEnable.length
    ) {
      if (_data[key]) {
        const dataMainKey = mainKey.substring(
          0,
          mainKey.length - jsonSchemaConstantKeys.schemaEnable.length
        );
        const dataKey = key.substring(0, key.length - jsonSchemaConstantKeys.schemaEnable.length);

        const value = _data[dataKey];

        const decomposedKeys = dataMainKey.split('.');

        const indexEmpty = decomposedKeys.indexOf('', 0);
        if (indexEmpty > -1) {
          decomposedKeys.splice(indexEmpty, 1);
        }

        let iCount = 0;

        const forEachFunction = (data: Record<string, unknown>): void => {
          const decomposedKey = decomposedKeys[iCount];

          iCount += 1;

          if (iCount < decomposedKeys.length) {
            // eslint-disable-next-line no-param-reassign
            data[decomposedKey] = { ...(data[decomposedKey] as Record<string, unknown>) };
            forEachFunction(data[decomposedKey] as Record<string, unknown>);
          } else {
            // eslint-disable-next-line no-param-reassign
            data[decomposedKey] = value;
          }
        };

        forEachFunction(dataToBeSubmited);
      }
    }

    return false;
  });

  if (previousJsonCurrentLevel && uiSchema) {
    const propertiesRemoved = differenceBetweenObjects(dataToBeSubmited, previousJsonCurrentLevel);
    const notMapped = getNotMappedProperties(propertiesRemoved, uiSchema);

    return merge(dataToBeSubmited, notMapped);
  }

  return dataToBeSubmited;
}
