import { Colors } from '../../../theme/colors';

const FormOuterContainerStyle = `
  background: ${Colors.manatee};
  justify-content: center;
  min-height: 100%;
  overflow: hidden;
  width: 100%;
`;

export default FormOuterContainerStyle;
