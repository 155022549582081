import { gql } from '../__generated__/gql';

const END_VIDEO = gql(`
  mutation endVideo($callRecordId: String!, $userId: String) {
    endVideo(callRecordId: $callRecordId, userId: $userId) {
      success
    }
  }
`);

export default END_VIDEO;
