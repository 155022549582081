import { Query, QueryUnitTypeArgs } from '../__generated__/graphql';

import GET_UNIT_TYPE_LOCALIZATION from '../graphql/getUnitType';
import QueryOneHookData from './@types/QueryOneHookData';
import UNIT_TYPE_LOCALIZATION_COMPONENT_NAME from '../components/UnitTypeForm/constants/unitTypeLocalizationComponentName';
import { flashApolloError } from '../util/errorUtils';
import { useLazyQuery } from '@apollo/client';

interface UnitTypeLocalizationArgs extends QueryUnitTypeArgs {
  componentName: string;
  unitTypeId: string;
}

type GetUnitTypeLocalizationData = Pick<Query, 'unitType'> &
  Pick<Query, 'getUnitTypeLocalizations'>;

export default function useGetUnitTypeById(): QueryOneHookData<GetUnitTypeLocalizationData> {
  const [getUnitType, { called, data, error, loading }] = useLazyQuery<
    GetUnitTypeLocalizationData,
    UnitTypeLocalizationArgs
  >(GET_UNIT_TYPE_LOCALIZATION, {
    fetchPolicy: 'network-only',
    onError: flashApolloError,
  });

  return {
    called,
    data: data
      ? {
          getUnitTypeLocalizations: data.getUnitTypeLocalizations,
          unitType: data.unitType,
        }
      : undefined,
    error,
    loading,
    query: (id: string): void => {
      getUnitType({
        variables: {
          componentName: UNIT_TYPE_LOCALIZATION_COMPONENT_NAME,
          unitTypeId: id,
          where: { id },
        },
      });
    },
  };
}
