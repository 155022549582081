import 'styled-components/macro';
import ButtonSelectContainerStyle from './styles/ButtonSelectContainer.style';
import Container from '../Container';
import React from 'react';
import SelectButtonStyle from './styles/SelectButton.style';
import capitalize from 'lodash/capitalize';
import { useFormContext } from 'react-hook-form';

interface ButtonSelectProps {
  options: Array<{
    name: string;
    value: string;
  }>;
}

/* Must be used within a React Hook Form Provider */
const ButtonSelect: React.FC<ButtonSelectProps> = ({ options }: ButtonSelectProps) => {
  const { register, watch } = useFormContext();

  const checkedValue = watch(options[0].name);

  return (
    <Container css={ButtonSelectContainerStyle}>
      {options.map((option, index) => (
        <Container
          css={SelectButtonStyle({ active: option.value === checkedValue })}
          key={`radio-option-${option.name}-${index}`}
        >
          <input
            defaultChecked={option.value === checkedValue}
            id={option.value}
            name={option.name}
            ref={register({ required: true })}
            style={{ opacity: 0 }}
            type="radio"
            value={option.value}
          />
          {capitalize(option.value)}
        </Container>
      ))}
    </Container>
  );
};

export default ButtonSelect;
