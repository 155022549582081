import { AnyObjectSchema } from 'yup';
import BASE_USER_VALIDATION_SCHEMA from '../constants/baseUserValidationSchema';
import { OPTIONAL_PASSWORD_VALIDATION_SCHEMA } from '../constants/passwordValidationSchema';

interface GenerateEditUserValidationSchemaInterface {
  isAbleToEditPassword: boolean;
}
const generateEditUserValidationSchema = ({
  isAbleToEditPassword,
}: GenerateEditUserValidationSchemaInterface): AnyObjectSchema => {
  if (isAbleToEditPassword) {
    return BASE_USER_VALIDATION_SCHEMA.concat(OPTIONAL_PASSWORD_VALIDATION_SCHEMA);
  }
  return BASE_USER_VALIDATION_SCHEMA;
};

export default generateEditUserValidationSchema;
