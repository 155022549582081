import InputGroupProps from '../../InputGroup/@types/InputGroupProps';
import generateDayTimes from '../../../util/generateDayTimes';

const LOCATION_SECTION_INPUTS: InputGroupProps[] = [
  {
    label: 'Name',
    name: 'name',
    type: 'text',
  },
  {
    label: 'Address',
    name: 'address',
    type: 'text',
  },
  {
    label: 'Email',
    name: 'email',
    required: true,
    type: 'email',
  },
  {
    label: 'Outgoing Email Display Name',
    name: 'emailFromName',
    type: 'text',
  },
  {
    label: 'Phone Number',
    name: 'phoneNumber',
    required: true,
    type: 'phone',
  },
  {
    label: 'Outgoing Email',
    name: 'emailFromEmail',
    required: true,
    type: 'email',
  },
  {
    label: 'Logo URL',
    name: 'logoUrl',
    required: false,
    type: 'url',
  },
  {
    label: 'Image URL',
    name: 'imageUrl',
    required: false,
    type: 'url',
  },
  {
    label: 'Check-in',
    name: 'defaultStartTime',
    options: generateDayTimes(),
    placeholder: 'Example: 11:00',
    type: 'select',
  },
  {
    label: 'Check-out',
    name: 'defaultEndTime',
    options: generateDayTimes(),
    placeholder: 'Example: 16:00',
    type: 'select',
  },
];

export default LOCATION_SECTION_INPUTS;
