import { gql } from '../../__generated__/gql';

export const ID_RESULT = gql(`
  query retrieveVerficationResult($scanReference: String!) {
    retrieveVerificationResult(scanReference: $scanReference) {
      dob
      expiry
      firstName
      images {
        classifier
        href
      }
      issuingCountry
      issuingDate
      lastName
      number
      scanReference
      status
      timestamp
      type
    }
  }
`);
