/* eslint-disable sort-keys */

import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationSimplifiedIDVerificationSchema: NodeSchemaProperties = {
  simplifiedIdVerification: {
    title: '#16 Simplified Id Verification',
    type: 'object',
    properties: {
      selectScreen: {
        title: '#1 Select Screen',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[simplifiedIdVerification.selectScreen.title]',
            title: '#1.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'description:enable': {
            description: '[simplifiedIdVerification.selectScreen.description]',
            title: '#1.2 Description',
            type: 'boolean',
          },
          description: {
            type: 'string',
          },
          'passport:enable': {
            description: '[simplifiedIdVerification.selectScreen.passport]',
            title: '#1.3 Passport',
            type: 'boolean',
          },
          passport: {
            type: 'string',
          },
          'barcode:enable': {
            description: '[simplifiedIdVerification.selectScreen.barcode]',
            title: '#1.4 Barcode',
            type: 'boolean',
          },
          barcode: {
            type: 'string',
          },
          'mrz:enable': {
            description: '[simplifiedIdVerification.selectScreen.mrz]',
            title: '#1.5 Mrz',
            type: 'boolean',
          },
          mrz: {
            type: 'string',
          },
          'selectButton:enable': {
            description: '[simplifiedIdVerification.selectScreen.selectButton]',
            title: '#1.6 Select Button',
            type: 'boolean',
          },
          selectButton: {
            type: 'string',
          },
        },
      },
      infoScreen: {
        title: '#2 Info Screen',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[simplifiedIdVerification.infoScreen.title]',
            title: '#2.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'okButton:enable': {
            description: '[simplifiedIdVerification.infoScreen.okButton]',
            title: '#2.2 Ok Button',
            type: 'boolean',
          },
          okButton: {
            type: 'string',
          },
          passport: {
            title: '#2.3 Passport',
            type: 'object',
            properties: {
              'subtitle:enable': {
                description: '[simplifiedIdVerification.infoScreen.passport.subtitle]',
                title: '#2.3.1 Subtitle',
                type: 'boolean',
              },
              subtitle: {
                type: 'string',
              },
              'description:enable': {
                description: '[simplifiedIdVerification.infoScreen.passport.description]',
                title: '#2.3.2 Description',
                type: 'boolean',
              },
              description: {
                type: 'string',
              },
            },
          },
          barcode: {
            title: '#2.4 Barcode',
            type: 'object',
            properties: {
              'subtitle:enable': {
                description: '[simplifiedIdVerification.infoScreen.barcode.subtitle]',
                title: '#2.4.1 Subtitle',
                type: 'boolean',
              },
              subtitle: {
                type: 'string',
              },
              'description:enable': {
                description: '[simplifiedIdVerification.infoScreen.barcode.description]',
                title: '#2.4.2 Description',
                type: 'boolean',
              },
              description: {
                type: 'string',
              },
            },
          },
          mrz: {
            title: '#2.5 Mrz',
            type: 'object',
            properties: {
              'subtitle:enable': {
                description: '[simplifiedIdVerification.infoScreen.mrz.subtitle]',
                title: '#2.5.1 Subtitle',
                type: 'boolean',
              },
              subtitle: {
                type: 'string',
              },
              'description:enable': {
                description: '[simplifiedIdVerification.infoScreen.mrz.description]',
                title: '#2.5.2 Description',
                type: 'boolean',
              },
              description: {
                type: 'string',
              },
            },
          },
        },
      },
      scanScreen: {
        title: '#3 Scan Screen',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[simplifiedIdVerification.scanScreen.title]',
            title: '#3.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'description:enable': {
            description: '[simplifiedIdVerification.scanScreen.description]',
            title: '#3.2 Description',
            type: 'boolean',
          },
          description: {
            type: 'string',
          },
          'captureButton:enable': {
            description: '[simplifiedIdVerification.scanScreen.captureButton]',
            title: '#3.3 Capture Button',
            type: 'boolean',
          },
          captureButton: {
            type: 'string',
          },
        },
      },
      successModal: {
        title: '#4 Success Modal',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[simplifiedIdVerification.successModal.title]',
            title: '#4.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'description:enable': {
            description: '[simplifiedIdVerification.successModal.description]',
            title: '#4.2 Description',
            type: 'boolean',
          },
          description: {
            type: 'string',
          },
          'button:enable': {
            description: '[simplifiedIdVerification.successModal.button]',
            title: '#4.3 Button',
            type: 'boolean',
          },
          button: {
            type: 'string',
          },
        },
      },
      errorModal: {
        title: '#5 Error Modal',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[simplifiedIdVerification.errorModal.title]',
            title: '#5.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'description:enable': {
            description: '[simplifiedIdVerification.errorModal.description]',
            title: '#5.2 Description',
            type: 'boolean',
          },
          description: {
            type: 'string',
          },
          'button:enable': {
            description: '[simplifiedIdVerification.errorModal.button]',
            title: '#5.3 Button',
            type: 'boolean',
          },
          button: {
            type: 'string',
          },
        },
      },
      resultModal: {
        title: '#6 Result Modal',
        type: 'object',
        properties: {
          'success:enable': {
            description: '[simplifiedIdVerification.resultModal.success]',
            title: '#6.1 Success',
            type: 'boolean',
          },
          success: {
            type: 'string',
          },
          'error:enable': {
            description: '[simplifiedIdVerification.resultModal.error]',
            title: '#6.2 Error',
            type: 'boolean',
          },
          error: {
            type: 'string',
          },
        },
      },
      'scannerLoading:enable': {
        description: '[simplifiedIdVerification.scannerLoading]',
        title: '#7 Scanner Loading',
        type: 'boolean',
      },
      scannerLoading: {
        type: 'string',
      },
    },
  },
};

export const mobileV3LocalizationSimplifiedIDVerificationUiSchema: NodeUiSchemaProperties = {
  simplifiedIdVerification: {
    selectScreen: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
      passport: {
        'ui:label': false,
        'ui:readonly': true,
      },
      barcode: {
        'ui:label': false,
        'ui:readonly': true,
      },
      mrz: {
        'ui:label': false,
        'ui:readonly': true,
      },
      selectButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    infoScreen: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      okButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
      passport: {
        subtitle: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      barcode: {
        subtitle: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      mrz: {
        subtitle: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
    },
    scanScreen: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
      captureButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    successModal: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
      button: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    errorModal: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
      button: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    resultModal: {
      success: {
        'ui:label': false,
        'ui:readonly': true,
      },
      error: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    scannerLoading: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
};
