/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationIdVerificationSchema: NodeSchemaProperties = {
  idVerification: {
    title: '#18 Id Verification',
    type: 'object',
    properties: {
      'title:enable': {
        description: '[idVerification.title]',
        title: '#1 Title',
        type: 'boolean',
      },
      title: {
        type: 'string',
      },
      'waitingForIdVerification:enable': {
        description: '[idVerification.waitingForIdVerification]',
        title: '#2 Waiting For Id Verification',
        type: 'boolean',
      },
      waitingForIdVerification: {
        type: 'string',
      },
    },
  },
};

export const mobileV3LocalizationIdVerificationUiSchema: NodeUiSchemaProperties = {
  idVerification: {
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    waitingForIdVerification: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
};
