import { Colors } from '../../../../../theme/colors';

const SearchResultsItemStyle = `
  cursor: pointer;
  padding: 5px 10px;

  &:hover {
    background: ${Colors.slateBlue};
    color: #fff;
    font-weight: bold;
  }
`;

export default SearchResultsItemStyle;
