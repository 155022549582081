import {
  CustomAmenityUpdateInput,
  Mutation,
  MutationUpdateAmenityAndUploadImageFileArgs,
} from '../__generated__/graphql';

import MutationHookData from './@types/MutationHookData';
import MutationHookParams from './@types/MutationHookParams';
import UPDATE_AMENITY from '../graphql/updateAmenity';
import { apolloRequirePreflightHeader } from '../client';
import { closeForm } from '../redux/actions/modals.actions';
import { flashApolloError } from '../util/errorUtils';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';

type UpdateAmenityData = Pick<Mutation, 'updateAmenityAndUploadImageFile'>;

export default function useUpdateAmenityAndUploadImage({
  onCompleted = (): void => {},
  onError = flashApolloError,
}: MutationHookParams<UpdateAmenityData> = {}): MutationHookData<
  CustomAmenityUpdateInput,
  UpdateAmenityData
> {
  const dispatch = useDispatch();

  const [update, { data, error, loading }] = useMutation<
    UpdateAmenityData,
    MutationUpdateAmenityAndUploadImageFileArgs
  >(UPDATE_AMENITY, {
    context: {
      headers: apolloRequirePreflightHeader,
    },
    onCompleted: ({ updateAmenityAndUploadImageFile }) => {
      dispatch(closeForm());

      window.flash({
        message: `Amenity: ${updateAmenityAndUploadImageFile.title} updated successfully`,
      });

      onCompleted({ updateAmenityAndUploadImageFile });
    },
    onError,
  });

  return {
    data,
    error,
    loading,
    mutation: (inputs: CustomAmenityUpdateInput, id = ''): void => {
      update({
        variables: {
          data: inputs,
          where: { id },
        },
      });
    },
  };
}
