import { gql } from '../__generated__/gql';

const JOIN_VIDEO = gql(`
  mutation joinVideo($room: String!, $callRecordId: String!, $joinerChallenge: String!) {
    joinVideo(room: $room, callRecordId: $callRecordId, joinerChallenge: $joinerChallenge) {
      accessToken
    }
  }
`);

export default JOIN_VIDEO;
