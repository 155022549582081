import {
  mobileLocalizationSchema,
  mobileLocalizationUiSchema,
} from '../../../../../components/JsonEditor/constants/LocalizationSchema/mobileLocalizationSchema';

import CustomizeLanguageLocalization from '../../../../../components/CustomizeLocalization/components/CustomizeLanguageLocalization';
import { CustomizeThemeLocalizationEntityLevel } from '../../../../../enums/CustomizeThemeLocalizationEntityLevel';
import { LocalizationComponentName } from '../../../../../enums/ComponentNameEnum';
import React from 'react';
import { useParams } from 'react-router-dom';

const CustomizeMobileLocalization: React.FC<{}> = () => {
  const { tenantId } = useParams<{ tenantId: string }>();

  return (
    <CustomizeLanguageLocalization
      title={'Customize mobile app & web localization'}
      componentName={LocalizationComponentName.MOBILE}
      schema={mobileLocalizationSchema}
      uiSchema={mobileLocalizationUiSchema}
      fileNameToDownloadDefaultJson={`Default mobile localization - Tenant ${tenantId} - Language \${LANGUAGE_NAME}.json`}
      fileNameToDownloadCurrentJson={`Current mobile localization - Tenant ${tenantId} - Language \${LANGUAGE_NAME}.json`}
      entityLevel={CustomizeThemeLocalizationEntityLevel.TENANT}
      entityId={tenantId}
    />
  );
};

export default CustomizeMobileLocalization;
