import {
  openQuickActionConfirmationModal,
  openQuickActionProcessingModal,
} from '../../../redux/actions/modals.actions';
import { QuickActionProcessingPayload } from '../../../redux/reducers/@types/ModalsReduxState';
import { useDispatch } from 'react-redux';

interface UseConfirmBeforeProcessingAction {
  confirmBeforeProcessingAction: (payload: QuickActionProcessingPayload) => void;
}

export const useConfirmBeforeProcessingAction = (): UseConfirmBeforeProcessingAction => {
  const dispatch = useDispatch();

  const confirmBeforeProcessingAction = (payload: QuickActionProcessingPayload): void => {
    dispatch(
      openQuickActionConfirmationModal({
        action: () => {
          dispatch(openQuickActionProcessingModal(payload));
        },
        ctaType: payload.ctaType,
      })
    );
  };

  return {
    confirmBeforeProcessingAction,
  };
};
