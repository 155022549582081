import FormTemplateStep from '../../../templates/Form/@types/FormTemplateStep';
import ImportResourcesSection from '../components/ImportResourcesSection';
import { Mutation } from '../../../__generated__/graphql';
import ResourceImportFormInputs from '../@types/ResourceImportFormInputs';

type UploadResourcesData = Pick<Mutation, 'uploadResources'>;

const RESOURCE_FORM_STEPS: FormTemplateStep<ResourceImportFormInputs, UploadResourcesData>[] = [
  {
    canBeAccessed: (): boolean => true,
    component: ImportResourcesSection,
    name: 'Upload Resources',
  },
];

export default RESOURCE_FORM_STEPS;
