import KiosksLiveUsageReduxState, {
  KioskLiveUsagePayloadData,
  KioskLiveUsageState,
} from '../@types/KiosksLiveUsageReduxState';

import { KioskLiveUsageData } from '../../../../__generated__/graphql';

export default function loadKioskLiveUsageDataAction(
  currentState: KiosksLiveUsageReduxState,
  actionPayload: KioskLiveUsageData[]
): KiosksLiveUsageReduxState {
  const newState = { ...currentState };
  actionPayload.forEach((kioskLiveUsageData) => {
    const kioskPayload = newState[kioskLiveUsageData.kiosk.id].payload;

    // Load the bookedPackages from the payload when it was loaded previously
    const newKioskLiveUsageData: KioskLiveUsageData = {
      ...kioskLiveUsageData,
      ...(kioskLiveUsageData.accessGrant && {
        accessGrant: {
          ...kioskLiveUsageData.accessGrant,
          bookedPackages: kioskPayload.accessGrant?.bookedPackages ?? [],
        },
      }),
    };

    // Clear the bookedPackages after be loaded on KioskLiveUsageData
    const newKioskPayload: KioskLiveUsagePayloadData = {
      ...kioskPayload,
      ...(kioskPayload.accessGrant && {
        accessGrant: {
          ...kioskPayload.accessGrant,
          bookedPackages: undefined,
        },
      }),
    };

    const newKiosk: KioskLiveUsageState = {
      data: newKioskLiveUsageData,
      payload: newKioskPayload,
    };

    newState[kioskLiveUsageData.kiosk.id] = newKiosk;
  });

  return newState;
}
