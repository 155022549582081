import { AuthenticationFormCommonInputStyle } from '../../../../../components/AuthenticationBaseComponent/styles/AuthenticationFormInput.style';

const ChooseLocationInputStyle = `
  ${AuthenticationFormCommonInputStyle}

  .SelectInputStyle__indicators {
    height: inherit;
    align-items: flex-start;
  }

  .SelectInputStyle__single-value, .SelectInputStyle__input {
    color: white;
  }
`;

export default ChooseLocationInputStyle;
