import React, { memo } from 'react';

import Container from '../../Container';
import CustomSectionTitle from './CustomSectionTitle';
import { KeyLimitForm } from './KeyLimitForm';
import LimitKeysSkeleton from './LimitKeysSkeleton';
import { QueryGetLimitKeysConfigArgs } from '../../../__generated__/graphql';
import { Separator } from '../styles';
import useLimitKeysConfig from '../../../hooks/useLimitKeysData';

const KeyLimitSectionComponent: React.FC<QueryGetLimitKeysConfigArgs> = (
  props: QueryGetLimitKeysConfigArgs
) => {
  const { limitKeysData, isLoadingLimitKeys } = useLimitKeysConfig(props);

  return (
    <Container>
      <Separator />
      <CustomSectionTitle title="Other access configurations" margin="25px 0" />
      <CustomSectionTitle
        title={'Number of keys limit'}
        description={[
          'Configure the maximum number of keys that can be issued per access grant. The number referrers to both physical and digital keys combined',
          'Set to 0 if not limit is required.',
        ]}
        smallerTitle
      />
      {isLoadingLimitKeys ? (
        <LimitKeysSkeleton />
      ) : (
        <KeyLimitForm
          {...props}
          initialLimitKey={limitKeysData?.maxKeys ? String(limitKeysData?.maxKeys) : undefined}
        />
      )}
    </Container>
  );
};

export const KeyLimitSection = memo(KeyLimitSectionComponent);
