import 'styled-components/macro';

import Heading, { HeadingModel } from '../Heading';

import Block from './components/Block';
import Button from '../Button';
import ButtonModel from '../Button/enums/ButtonModel.enum';
import { ComponentModelProperties } from '../../@types/common';
import Container from '../Container';
import DisplayBoxCTAContainerStyle from './styles/DisplayBoxCTAContainer.style';
import DisplayBoxContainerStyle from './styles/DisplayBoxContainer.style';
import DisplayBoxHeadingStyle from './styles/DisplayBoxHeading.style';
import React from 'react';
import Theme from '../../theme';

export interface DisplayBoxProps {
  className?: string;
  ctaText: string;
  onClick?: () => void;
  model?: string;
  title: string;
}

type InjectedDisplayBoxProps<P> = React.FunctionComponent<P> & {
  Block: typeof Block;
};

export enum DisplayBoxModel {
  DARK = 'display_box--dark',
  LIGHT = 'display_box--light',
}

const DISPLAY_BOX_CSS_PROPERTY_MAP: ComponentModelProperties = {
  [DisplayBoxModel.DARK]: {
    breakpoints: {},
    default: {
      background: Theme.colors.darkAlternate.hexCode,
      borderColor: Theme.colors.borderDark.hexCode,
      color: Theme.colors.white.hexCode,
    },
  },
  [DisplayBoxModel.LIGHT]: {
    breakpoints: {},
    default: {
      background: Theme.colors.white.hexCode,
      border: ``,
      borderColor: Theme.colors.white.hexCode,
      color: Theme.colors.dark.hexCode,
    },
  },
};

const DisplayBox: InjectedDisplayBoxProps<DisplayBoxProps> = ({
  children,
  className,
  ctaText,
  model = DisplayBoxModel.DARK,
  onClick = (): void => {},
  title,
}: DisplayBoxProps & { children?: React.ReactNode }) => {
  return (
    <Container
      background={DISPLAY_BOX_CSS_PROPERTY_MAP[model].default.background}
      className={className}
      css={DisplayBoxContainerStyle}
    >
      <Heading
        borderColor={DISPLAY_BOX_CSS_PROPERTY_MAP[model].default.borderColor}
        color={DISPLAY_BOX_CSS_PROPERTY_MAP[model].default.color}
        css={DisplayBoxHeadingStyle}
        fontWeight="normal"
        model={HeadingModel.QUARTERNARY}
      >
        {title}
      </Heading>
      <Container>{children}</Container>
      <Container
        borderColor={DISPLAY_BOX_CSS_PROPERTY_MAP[model].default.borderColor}
        css={DisplayBoxCTAContainerStyle}
      >
        <Button model={ButtonModel.ACTIVE_CTA_DARK} onClick={onClick}>
          {ctaText}
        </Button>
      </Container>
    </Container>
  );
};

DisplayBox.Block = Block;

export default DisplayBox;
