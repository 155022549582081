import { FetchResult, useMutation } from '@apollo/client';
import {
  Mutation,
  MutationCreateAccessGrantArgs,
  SecondaryUsersOnAccessGrantsWhereUniqueInput,
} from '../__generated__/graphql';
import { useDispatch, useSelector } from 'react-redux';

import AccessGrantFormInputs from '../components/AccessGrantForm/@types/AccessGrantFormInputs';
import CREATE_ACCESS_GRANT from '../graphql/createAccessGrant';
import MutationHookDataWithValue from './@types/MutationHookDataWithValue';
import MutationHookParams from './@types/MutationHookParams';
import { RootState } from '../redux/reducers';
import { closeForm } from '../redux/actions/modals.actions';
import { flashApolloError } from '../util/errorUtils';
import forIn from 'lodash/forIn';
import links from '../util/links';

type CreateAccessGrantData = Pick<Mutation, 'createAccessGrant'>;

export default function useCreateAccessGrant({
  onCompleted = (): void => {},
  onError = flashApolloError,
}: MutationHookParams<CreateAccessGrantData> = {}): MutationHookDataWithValue<
  AccessGrantFormInputs,
  CreateAccessGrantData
> {
  const dispatch = useDispatch();
  const me = useSelector((state: RootState) => state.me);
  const activeTenantId = me.activeMembership?.tenantId ?? '';

  const [create, { data, error, loading }] = useMutation<
    CreateAccessGrantData,
    MutationCreateAccessGrantArgs
  >(CREATE_ACCESS_GRANT, {
    onCompleted: ({ createAccessGrant }) => {
      window.flash({
        link: `${links.paths.ACCESS_GRANTS}/${createAccessGrant.id}`,
        message: `Access Grant: ${createAccessGrant.confirmationCode} created successfully`,
      });
      dispatch(closeForm());
      onCompleted({ createAccessGrant });
    },
    onError,
  });

  return {
    data,
    error,
    loading,
    mutation: async ({
      originalAgreementFileId,
      ...inputs
    }: AccessGrantFormInputs): Promise<FetchResult<CreateAccessGrantData>> => {
      const secondaryUsersToConnect: SecondaryUsersOnAccessGrantsWhereUniqueInput[] = [];

      forIn(inputs.secondaryUsers?.map, (value, key) => {
        if (value.shouldConnect) {
          secondaryUsersToConnect.push({
            userId_accessGrantId: {
              accessGrantId: '',
              userId: key,
            },
          });
        }
      });

      const result = await create({
        variables: {
          data: {
            ...inputs,
            location: {
              connect: { id: inputs.location.value },
            },
            metadata: {},
            requirements: {
              create: inputs.requirements.values,
            },
            resources: {
              connect: inputs.resources.values.map((resource) => ({ id: resource.value })),
            },
            secondaryUsers: {
              connect: secondaryUsersToConnect,
            },
            tenant: {
              connect: {
                id: activeTenantId,
              },
            },
            user: {
              connect: {
                id: inputs.user.value,
              },
            },
          },
          originalAgreementFileId,
        },
      });
      return result;
    },
  };
}
