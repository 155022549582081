import SearchField from '../../../@types/SearchField';
import { UserWhereInput } from '../../../__generated__/graphql';
import createSearchField from '../../../util/tableData/createSearchField';
import transformStringFilter from '../../../util/tableData/transformStringFilter';

const USER_SEARCH_FIELDS: SearchField<UserWhereInput>[] = [
  createSearchField<UserWhereInput, 'name'>({
    label: 'Name',
    queryField: 'name',
    transform: transformStringFilter,
  }),
  createSearchField<UserWhereInput, 'email'>({
    label: 'Email',
    queryField: 'email',
    transform: transformStringFilter,
  }),
  createSearchField<UserWhereInput, 'phoneNumber'>({
    label: 'Phone #',
    queryField: 'phoneNumber',
    transform: transformStringFilter,
  }),
];

export default USER_SEARCH_FIELDS;
