const AuthenticationInnerContainerStyle = `
  margin: 145px auto 0;
  max-width: 400px;
  min-width: 300px;
  width: auto;

  svg {
    justify-self: center;
    margin: 0 0 40px;
  }
`;

export default AuthenticationInnerContainerStyle;
