import * as yup from 'yup';

const RESOURCE_GROUP_VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().required('Name is required'),
  resources: yup.object().shape({
    map: yup.object(),
    values: yup.array(
      yup.object().shape({
        label: yup.string(),
        value: yup.string(),
      })
    ),
  }),
});

export default RESOURCE_GROUP_VALIDATION_SCHEMA;
