import { Mutation, QueryLocationArgs } from '../__generated__/graphql';

import DELETE_LOCATION from '../graphql/deleteLocation';
import RemoveHookData from './@types/RemoveHookData';
import { closeRemoveModal } from '../redux/actions/modals.actions';
import { flashApolloError } from '../util/errorUtils';
import links from '../util/links';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';

type DeleteLocationData = Pick<Mutation, 'deleteOneLocation'>;

export default function useRemoveLocation(): RemoveHookData {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [remove, { error, loading }] = useMutation<DeleteLocationData, QueryLocationArgs>(
    DELETE_LOCATION,
    {
      onCompleted: ({ deleteOneLocation }) => {
        dispatch(closeRemoveModal());

        if (deleteOneLocation) {
          window.flash({
            message: `Location: ${deleteOneLocation.name} removed successfully`,
          });
        } else {
          window.flash({ message: 'Fail to remove location' });
        }

        if (location.pathname === links.paths.LOCATIONS) {
          history.go(0);
        } else {
          history.push(links.paths.LOCATIONS);
        }
      },
      onError: flashApolloError,
    }
  );

  return {
    error,
    loading,
    mutation: (id: string): void => {
      remove({
        variables: {
          where: { id },
        },
      });
    },
  };
}
