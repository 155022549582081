import 'styled-components/macro';
import Banner from '../../components/Banner';
import BaseLayout from '../../templates/BaseLayout';
import Container from '../../components/Container';
import IncomingStaysTable from './components/IncomingStays';
import React from 'react';
import { RootState } from '../../redux/reducers';
import Verifications from './components/Verifications';
import WelcomeBanner from '../../components/WelcomeBanner';
import { useSelector } from 'react-redux';

const DashboardContentContainerStyle = `
  grid-auto-rows: min-content;
  padding: 35px;
`;

function LandingPage(): React.ReactElement {
  const name = useSelector((state: RootState) => state.me.name);

  return (
    <BaseLayout>
      <Container css={DashboardContentContainerStyle}>
        <Banner subText="Virdee" text="Dashboard" />
        <WelcomeBanner text={`Hello ${name}, \n Welcome back!`} />
        <IncomingStaysTable />
        <Verifications />
      </Container>
    </BaseLayout>
  );
}

export default LandingPage;
