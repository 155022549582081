/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationAmenitiesSchema: NodeSchemaProperties = {
  amenities: {
    title: '#6 Amenities',
    type: 'object',
    properties: {
      'title:enable': {
        description: '[amenities.title]',
        title: '#1 Title',
        type: 'boolean',
      },
      title: {
        type: 'string',
      },
      'moreDetailsButton:enable': {
        description: '[amenities.moreDetailsButton]',
        title: '#2 More details button text',
        type: 'boolean',
      },
      moreDetailsButton: {
        type: 'string',
      },
    },
  },
};

export const mobileV3LocalizationAmenitiesUiSchema: NodeUiSchemaProperties = {
  amenities: {
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    moreDetailsButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
};
