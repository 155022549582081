import 'styled-components/macro';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { AuthCredentialsGrid } from './styles/AccessGrantAuthenticationCredentialsStyle';

import { AuthCredentialsSkeleton } from './components/AuthCredentialsSkeleton';
import BaseQuickActionModal from '../QuickActionBar/components/BaseQuickActionModal';
import CopyToClipboardIcon from './components/CopyToClipboardIcon';
import QRCodeViewer from './components/QRCodeViewer';
import RowInfo from './components/RowInfo';
import ToggleSelector from '../ToggleSelector';
import { closeForm } from '../../redux/actions/modals.actions';
import useCopyToClipboard from '../../util/useCopyToClipboard';
import { useDispatch } from 'react-redux';
import useGetAccessGrantAuthenticationCredentials from '../../hooks/useGetAccessGrantEmailCredentials';
import { valueTextStyle } from './components/RowInfo/styles/RowItem.style';

export const ACCESS_GRANT_AUTHENTICATION_CREDENTIALS_FORM =
  'ACCESS_GRANT_AUTHENTICATION_CREDENTIALS_FORM';

interface AccessGrantAuthenticationCredentialsProps {
  accessGrantId?: string;
}

enum QRCodeToDisplay {
  MobileWeb = 'MobileWeb',
  AccessGrant = 'AccessGrant',
}

const AccessGrantAuthenticationCredentials: React.FC<AccessGrantAuthenticationCredentialsProps> = ({
  accessGrantId,
}: AccessGrantAuthenticationCredentialsProps) => {
  const [qrCodeType, setQrCodeType] = useState<string>(QRCodeToDisplay.MobileWeb);

  const {
    query: queryMobileCredentials,
    loading: loadingMobileCredentials,
    data: mobileCredentialsData,
    called: mobileCredentialsIsCalled,
    error: mobileCredentialsError,
  } = useGetAccessGrantAuthenticationCredentials();
  const { copyText: copyTextToClipboard, copyImage: copyImageToClipboard } = useCopyToClipboard();

  const qrCodeRef = React.createRef<HTMLImageElement>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!mobileCredentialsIsCalled && accessGrantId) {
      queryMobileCredentials(accessGrantId);
    }
  }, [mobileCredentialsIsCalled, accessGrantId, queryMobileCredentials]);

  const copyText = (label: string, value: string): void => {
    copyTextToClipboard(value).then(() => {
      window.flash({
        message: `${label} successfully copied to clipboard`,
      });
    });
  };

  const copyImage = (label: string, imageRef: React.RefObject<HTMLImageElement>): void => {
    if (imageRef.current && imageRef.current.firstChild instanceof HTMLCanvasElement) {
      copyImageToClipboard(imageRef.current.firstChild).then(() => {
        window.flash({
          message: `${label} successfully copied to clipboard`,
        });
      });
    }
  };

  const getQRCodeTypeMessage = (): string => {
    switch (qrCodeType) {
      case QRCodeToDisplay.MobileWeb: {
        return 'Mobile Web QR code';
      }
      case QRCodeToDisplay.AccessGrant: {
        return 'Access Grant QR code';
      }
      default: {
        return 'QR code';
      }
    }
  };

  const closeModal = useCallback((): void => {
    dispatch(closeForm());
  }, []);

  const tryLoadingCredentialsAgain = useCallback(async () => {
    if (accessGrantId) {
      await queryMobileCredentials(accessGrantId);
    }
  }, []);

  return (
    <BaseQuickActionModal
      headerTitle="Authentication Credentials"
      ctaType="getAutenticationCredentials"
      confirmButton={{
        action: closeModal,
        text: 'Close',
      }}
      error={
        mobileCredentialsError && {
          message: 'There was an error loading the credentials',
          tryAgainAction: tryLoadingCredentialsAgain,
        }
      }
    >
      <AuthCredentialsGrid>
        {mobileCredentialsData && !loadingMobileCredentials ? (
          <>
            <RowInfo
              label="Mobile App Deep link"
              suffixIcon={
                <CopyToClipboardIcon
                  onCopyFunction={(): void =>
                    copyText('Mobile App Deep link', mobileCredentialsData.deeplink)
                  }
                />
              }
            >
              <a
                href={mobileCredentialsData.deeplink}
                title="Deep link"
                style={valueTextStyle}
                target="_blank"
                rel="noopener noreferrer"
              >
                {mobileCredentialsData.deeplink}
              </a>
            </RowInfo>
            <RowInfo
              label="Mobile Web URL"
              suffixIcon={
                <CopyToClipboardIcon
                  onCopyFunction={(): void =>
                    copyText('Mobile Web URL', mobileCredentialsData.mobileWebUrl)
                  }
                />
              }
            >
              <a
                href={mobileCredentialsData.mobileWebUrl}
                title="Mobile web"
                style={valueTextStyle}
                target="_blank"
                rel="noopener noreferrer"
              >
                {mobileCredentialsData.mobileWebUrl}
              </a>
            </RowInfo>
            <RowInfo
              label="QR Code"
              suffixIcon={
                <CopyToClipboardIcon
                  onCopyFunction={(): void => copyImage(getQRCodeTypeMessage(), qrCodeRef)}
                />
              }
              iconPosition="header"
            >
              <div>
                <ToggleSelector.State
                  options={[
                    { displayText: 'Mobile Web', value: QRCodeToDisplay.MobileWeb },
                    { displayText: 'Access Grant', value: QRCodeToDisplay.AccessGrant },
                  ]}
                  stateObject={[qrCodeType, setQrCodeType]}
                  themeMode="light"
                  widthType="parentWidth"
                />
                <QRCodeViewer
                  qrCodeRef={qrCodeRef}
                  value={
                    qrCodeType === QRCodeToDisplay.MobileWeb
                      ? mobileCredentialsData.mobileWebUrl
                      : mobileCredentialsData.qrCode
                  }
                />
              </div>
            </RowInfo>
          </>
        ) : (
          <AuthCredentialsSkeleton />
        )}
      </AuthCredentialsGrid>
    </BaseQuickActionModal>
  );
};

export default memo(AccessGrantAuthenticationCredentials);
