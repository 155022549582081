import * as yup from 'yup';

import URL_VALIDATION from '../../../constants/yupValidations/constants/url';

const INFO_ITEM_VALIDATION_SCHEMA = yup.object().shape({
  description: yup.string().required('Description is required'),
  imageType: yup.string().required('Image type is required'),
  imageUrl: URL_VALIDATION,
  location: yup.object().shape({
    label: yup.string().required('Location is required'),
    value: yup.string().required('Location is required'),
  }),
  moreInfoLink: URL_VALIDATION,
  title: yup.string().required('Title is required'),
  type: yup.string().required('Type is required'),
});

export default INFO_ITEM_VALIDATION_SCHEMA;
