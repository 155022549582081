import { gql } from '../__generated__/gql';

const GET_ACCESS_GRANT_AUTHENTICATED_CREDENTIALS = gql(`
  query GetAccessGrantAuthenticationCredentials($accessGrantId: String!) {
    getAccessGrantAuthenticationCredentials(accessGrantId: $accessGrantId) {
      deeplink
      mobileWebUrl
      qrCode
    }
  }
`);

export default GET_ACCESS_GRANT_AUTHENTICATED_CREDENTIALS;
