/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationCheckOutSchema: NodeSchemaProperties = {
  checkoutScreen: {
    title: '#26 Checkout Screen',
    type: 'object',
    properties: {
      'title:enable': {
        description: '[checkoutScreen.title]',
        title: '#1 Title',
        type: 'boolean',
      },
      title: {
        type: 'string',
      },
      'cardOnFileTitle:enable': {
        description: '[checkoutScreen.cardOnFileTitle]',
        title: '#2 Card On File Title',
        type: 'boolean',
      },
      cardOnFileTitle: {
        type: 'string',
      },
      'itemHeader:enable': {
        description: '[checkoutScreen.itemHeader]',
        title: '#3 Item Header',
        type: 'boolean',
      },
      itemHeader: {
        type: 'string',
      },
      'priceHeader:enable': {
        description: '[checkoutScreen.priceHeader]',
        title: '#4 Price Header',
        type: 'boolean',
      },
      priceHeader: {
        type: 'string',
      },
      'totalPriceHeader:enable': {
        description: '[checkoutScreen.totalPriceHeader]',
        title: '#5 Total Price Header',
        type: 'boolean',
      },
      totalPriceHeader: {
        type: 'string',
      },
      'totalFooter:enable': {
        description: '[checkoutScreen.totalFooter]',
        title: '#6 Total Footer',
        type: 'boolean',
      },
      totalFooter: {
        type: 'string',
      },
      'checkoutButton:enable': {
        description: '[checkoutScreen.checkoutButton]',
        title: '#7 Checkout Button',
        type: 'boolean',
      },
      checkoutButton: {
        type: 'string',
      },
      'checkoutFailed:enable': {
        description: '[checkoutScreen.checkoutFailed]',
        title: '#8 Checkout Failed',
        type: 'boolean',
      },
      checkoutFailed: {
        type: 'string',
      },
      'checkOutDescription1:enable': {
        description: '[checkoutScreen.checkOutDescription1]',
        title: '#9 Check Out Description 1',
        type: 'boolean',
      },
      checkOutDescription1: {
        type: 'string',
      },
      'checkOutDescription2:enable': {
        description: '[checkoutScreen.checkOutDescription2]',
        title: '#10 Check Out Description 2',
        type: 'boolean',
      },
      checkOutDescription2: {
        type: 'string',
      },
      'contactFrontDesk:enable': {
        description: '[checkoutScreen.contactFrontDesk]',
        title: '#11 Contact Front Desk',
        type: 'boolean',
      },
      contactFrontDesk: {
        type: 'string',
      },
      'bookingLabel:enable': {
        description: '[checkoutScreen.bookingLabel]',
        title: '#12 Booking Label',
        type: 'boolean',
      },
      bookingLabel: {
        type: 'string',
      },
      'guestNameLabel:enable': {
        description: '[checkoutScreen.guestNameLabel]',
        title: '#13 Guest Name Label',
        type: 'boolean',
      },
      guestNameLabel: {
        type: 'string',
      },
      'dateLabel:enable': {
        description: '[checkoutScreen.dateLabel]',
        title: '#14 Date Label',
        type: 'boolean',
      },
      dateLabel: {
        type: 'string',
      },
      'receiptLabel:enable': {
        description: '[checkoutScreen.receiptLabel]',
        title: '#15 Receipt Label',
        type: 'boolean',
      },
      receiptLabel: {
        type: 'string',
      },
      'totalLabel:enable': {
        description: '[checkoutScreen.totalLabel]',
        title: '#16 Total Label',
        type: 'boolean',
      },
      totalLabel: {
        type: 'string',
      },
      'currency:enable': {
        description: '[checkoutScreen.currency]',
        title: '#17 Currency',
        type: 'boolean',
      },
      currency: {
        type: 'string',
      },
      'subtotalLabel:enable': {
        description: '[checkoutScreen.subtotalLabel]',
        title: '#18 Subtotal Label',
        type: 'boolean',
      },
      subtotalLabel: {
        type: 'string',
      },
      'paymentsLabel:enable': {
        description: '[checkoutScreen.paymentsLabel]',
        title: '#19 Payments Label',
        type: 'boolean',
      },
      paymentsLabel: {
        type: 'string',
      },
      'checkOutButton:enable': {
        description: '[checkoutScreen.checkOutButton]',
        title: '#20 Check Out Button',
        type: 'boolean',
      },
      checkOutButton: {
        type: 'string',
      },
      'checkOutSuccessTitle:enable': {
        description: '[checkoutScreen.checkOutSuccessTitle]',
        title: '#21 Check Out Success Title',
        type: 'boolean',
      },
      checkOutSuccessTitle: {
        type: 'string',
      },
      'checkOutSuccessDescription:enable': {
        description: '[checkoutScreen.checkOutSuccessDescription]',
        title: '#22 Check Out Success Description',
        type: 'boolean',
      },
      checkOutSuccessDescription: {
        type: 'string',
      },
      'checkOutSuccessButton:enable': {
        description: '[checkoutScreen.checkOutSuccessButton]',
        title: '#23 Check Out Success Button',
        type: 'boolean',
      },
      checkOutSuccessButton: {
        type: 'string',
      },
      'checkOutFailedTitle:enable': {
        description: '[checkoutScreen.checkOutFailedTitle]',
        title: '#24 Check Out Failed Title',
        type: 'boolean',
      },
      checkOutFailedTitle: {
        type: 'string',
      },
      'checkOutFailedDescription:enable': {
        description: '[checkoutScreen.checkOutFailedDescription]',
        title: '#25 Check Out Failed Description',
        type: 'boolean',
      },
      checkOutFailedDescription: {
        type: 'string',
      },
      'checkOutFailedButton:enable': {
        description: '[checkoutScreen.checkOutFailedButton]',
        title: '#26 Check Out Failed Button',
        type: 'boolean',
      },
      checkOutFailedButton: {
        type: 'string',
      },
      'checkOutFailedHelpButton:enable': {
        description: '[checkoutScreen.checkOutFailedHelpButton]',
        title: '#27 Check Out Failed Help Button',
        type: 'boolean',
      },
      checkOutFailedHelpButton: {
        type: 'string',
      },
      'header:enable': {
        description: '[checkoutScreen.header]',
        title: '#28 Header',
        type: 'boolean',
      },
      header: {
        type: 'string',
      },
      'checkOutNotToday:enable': {
        description: '[checkoutScreen.checkOutNotToday]',
        title: '#29 Checkout not today text',
        type: 'boolean',
      },
      checkOutNotToday: {
        type: 'string',
      },
      'tableCellHeader:enable': {
        description: '[checkoutScreen.tableCellHeader]',
        title: '#30 Transaction table header',
        type: 'boolean',
      },
      tableCellHeader: {
        type: 'string',
      },
      'tableCellQuanity:enable': {
        description: '[checkoutScreen.tableCellQuanity]',
        title: '#31 Transaction table cell quantity text',
        type: 'boolean',
      },
      tableCellQuanity: {
        type: 'string',
      },
      'tableCellEmpty:enable': {
        description: '[checkoutScreen.tableCellEmpty]',
        title: '#32 Transaction table empty state text',
        type: 'boolean',
      },
      tableCellEmpty: {
        type: 'string',
      },
    },
  },
};

export const mobileV3LocalizationCheckOutUiSchema: NodeUiSchemaProperties = {
  checkoutScreen: {
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cardOnFileTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    itemHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    priceHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    totalPriceHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    totalFooter: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkoutButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkoutFailed: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkOutDescription1: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkOutDescription2: {
      'ui:label': false,
      'ui:readonly': true,
    },
    contactFrontDesk: {
      'ui:label': false,
      'ui:readonly': true,
    },
    bookingLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    guestNameLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    dateLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    receiptLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    totalLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    currency: {
      'ui:label': false,
      'ui:readonly': true,
    },
    subtotalLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    paymentsLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkOutButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkOutSuccessTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkOutSuccessDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkOutSuccessButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkOutFailedTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkOutFailedDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkOutFailedButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkOutFailedHelpButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkOutNotToday: {
      'ui:label': false,
      'ui:readonly': true,
    },
    tableCellHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    tableCellQuanity: {
      'ui:label': false,
      'ui:readonly': true,
    },
    tableCellEmpty: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
};
