import 'styled-components/macro';

import {
  AccessGrantType,
  Location,
  Mutation,
  Query,
  QueryAccessGrantArgs,
} from '../../__generated__/graphql';
import React, { useEffect } from 'react';

import ACCESS_GRANT_FORM_STEPS from './constants/accessGrantFormSteps';
import ACCESS_GRANT_VALIDATION_SCHEMA from './constants/accessGrantValidationSchema';
import AccessGrantFormInputs from './@types/AccessGrantFormInputs';
import FormTemplate from '../../templates/Form';
import GET_ACCESS_GRANT from '../../graphql/getAccessGrant';
import { flashApolloError } from '../../util/errorUtils';
import generateAccessGrantFormValues from './functions/generateInitialValues';
import { useLazyQuery } from '@apollo/client';

interface AccessGrantAditionalDataOnOpeningFormInterface {
  location: Pick<Location, 'id' | 'name' | 'defaultStartTime' | 'defaultEndTime'>;
}

interface AccessGrantProps {
  id?: string;
  aditionalDataOnOpeningForm?: unknown;
}

type CreateAccessGrantData = Pick<Mutation, 'createAccessGrant'>;
type GetAccessGrantData = Pick<Query, 'accessGrant'>;

const AccessGrantForm: React.FC<AccessGrantProps> = ({
  id,
  aditionalDataOnOpeningForm,
}: AccessGrantProps) => {
  const [getAccessGrant, { data }] = useLazyQuery<GetAccessGrantData, QueryAccessGrantArgs>(
    GET_ACCESS_GRANT,
    {
      fetchPolicy: 'network-only',
      onError: flashApolloError,
    }
  );

  useEffect(() => {
    if (id) {
      getAccessGrant({
        variables: {
          where: { id },
        },
      });
    }
  }, [getAccessGrant, id]);

  const accessGrant = data?.accessGrant ?? undefined;

  if (id && !accessGrant) {
    return <div>Loading...</div>;
  }

  const initialValues = (): Partial<AccessGrantFormInputs> => {
    if (accessGrant) {
      return generateAccessGrantFormValues(accessGrant);
    }

    const defaultInitialValues: Partial<AccessGrantFormInputs> = {
      resources: {
        map: {},
        values: [],
      },
      secondaryUsers: {
        map: {},
        values: [],
      },
      type: AccessGrantType.Hotel,
    };

    try {
      if (aditionalDataOnOpeningForm) {
        const {
          location,
        } = aditionalDataOnOpeningForm as AccessGrantAditionalDataOnOpeningFormInterface;
        return {
          ...defaultInitialValues,
          endTime: location.defaultEndTime,
          location: {
            label: location.name,
            value: location.id,
          },
          startTime: location.defaultStartTime,
        };
      }
      // eslint-disable-next-line no-empty
    } catch {}

    return defaultInitialValues;
  };

  return (
    <FormTemplate<AccessGrantFormInputs, CreateAccessGrantData>
      initialValues={initialValues()}
      formHeader={id ? 'Edit Access Grant' : 'Create Access Grant'}
      identifier={id}
      steps={ACCESS_GRANT_FORM_STEPS}
      validationSchema={ACCESS_GRANT_VALIDATION_SCHEMA}
    />
  );
};

export default AccessGrantForm;
