import 'styled-components/macro';

import { ApolloError } from '@apollo/client';
import CallRecordsListStyle from './styles/CallRecordsList.style';
import { Colors } from '../../../../../../theme/colors';
import DisplayBox from '../../../../../../components/DisplayBox';
import { DisplayBoxCallRecord } from '../../@types/DisplayBoxCallRecord';
import List from '../../../../../../components/List';
import React from 'react';
import TextContent from '../../../../../../components/TextContent';
import groupBy from 'lodash/groupBy';

interface CallRecordsListProps {
  callRecords: DisplayBoxCallRecord[];
  error?: ApolloError;
  loading: boolean;
}
const CallRecordsList: React.FC<CallRecordsListProps> = ({ callRecords }: CallRecordsListProps) => {
  const groupedCalls = groupBy(callRecords, 'status');
  const waitingCalls = groupedCalls.WAITING || [];
  const inProgressCalls = groupedCalls.IN_PROGRESS || [];
  const missedCalls = groupedCalls.MISSED || [];

  const completedCalls = (groupedCalls.COMPLETED || []).filter((call) => {
    return Boolean(call.agent);
  });

  return (
    <List css={CallRecordsListStyle}>
      <li>
        <DisplayBox.Block background={Colors.neonPink} />
        <TextContent>Missed: {missedCalls.length}</TextContent>
      </li>
      <li>
        <DisplayBox.Block background={Colors.selectiveYellow} />
        <TextContent>In Progress: {waitingCalls.length + inProgressCalls.length}</TextContent>
      </li>
      <li>
        <DisplayBox.Block background={Colors.turquoiseBlue} />
        <TextContent>Completed: {completedCalls.length}</TextContent>
      </li>
    </List>
  );
};

export default CallRecordsList;
