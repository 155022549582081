import {
  GET_KIOSK_LOCALIZATION,
  GET_LOCATION_LOCALIZATION,
  GET_TENANT_LOCALIZATION,
} from '../../../graphql/queries';

import { CustomizeThemeLocalizationEntityLevel } from '../../../enums/CustomizeThemeLocalizationEntityLevel';
import { DocumentNode } from 'graphql';
import { NotSupportedError } from '../../CustomizeTheme/constants/defaultError';

const getLoadQuery = (entityLevel: CustomizeThemeLocalizationEntityLevel): DocumentNode => {
  switch (entityLevel) {
    case CustomizeThemeLocalizationEntityLevel.TENANT:
      return GET_TENANT_LOCALIZATION;

    case CustomizeThemeLocalizationEntityLevel.LOCATION:
      return GET_LOCATION_LOCALIZATION;

    case CustomizeThemeLocalizationEntityLevel.KIOSK:
      return GET_KIOSK_LOCALIZATION;

    default:
      throw NotSupportedError(entityLevel);
  }
};

export default getLoadQuery;
