import 'styled-components/macro';
import Button, { ButtonProps } from '../../Button';
import Container from '../../Container';
import NavigationButtonLabelStyle from '../styles/NavigationButtonLabel.style';
import NavigationButtonStyle from '../styles/NavigationButton.style';
import React from 'react';
import TextContent from '../../TextContent';

interface NavigationButtonProps extends ButtonProps {
  labelText: string;
}

const NavigationButton: React.FC<NavigationButtonProps> = ({
  labelText,
  ...props
}: NavigationButtonProps) => {
  return (
    <Container css={NavigationButtonStyle}>
      <Button {...props}>
        {props.children}
        <TextContent className="label" css={NavigationButtonLabelStyle} fontSize="8px">
          {labelText}
        </TextContent>
      </Button>
    </Container>
  );
};

export default NavigationButton;
