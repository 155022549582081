import { DeepMap, FieldError } from 'react-hook-form';

import LOCATION_SECTION_REQUIRED_FIELDS from '../inputs/locationSectionFields';
import LocationFormInputs from '../@types/LocationFormInputs';
import get from 'lodash/get';

export default function canAccessLocationRequirementsSection(
  inputs: Partial<LocationFormInputs>,
  errors: DeepMap<LocationFormInputs, FieldError>
): boolean {
  const isFilled = LOCATION_SECTION_REQUIRED_FIELDS.every((field) => get(inputs, field));
  const strippedFieldNames = LOCATION_SECTION_REQUIRED_FIELDS.map((field) => field.split('.')[0]);

  const hasNoErrors = strippedFieldNames.every(
    (field) => !errors[field as keyof LocationFormInputs]
  );

  return isFilled && hasNoErrors;
}
