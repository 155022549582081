import { gql } from '../__generated__/gql';

const GET_VENDOR_SERVER = gql(`
  query GetVendorServer($where: VendorServerWhereUniqueInput!) {
    vendorServer(where: $where) {
      ...VendorServerDetailsFields
    }
  }
`);

export default GET_VENDOR_SERVER;
