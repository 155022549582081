import 'styled-components/macro';

import {
  kioskThemeCustomizationSchema,
  kioskThemeCustomizationUiSchema,
} from '../../../../../components/JsonEditor/constants/ThemeSchema/kioskThemeCustomization';

import CustomizeTheme from '../../../../../components/CustomizeTheme';
import { CustomizeThemeLocalizationEntityLevel } from '../../../../../enums/CustomizeThemeLocalizationEntityLevel';
import React from 'react';
import { ThemeComponentName } from '../../../../../enums/ComponentNameEnum';
import { useParams } from 'react-router-dom';

const CustomizeKioskTheme: React.FC<{}> = () => {
  const { locationId } = useParams<{ locationId: string }>();

  return (
    <CustomizeTheme
      schema={kioskThemeCustomizationSchema}
      uiSchema={kioskThemeCustomizationUiSchema}
      componentName={ThemeComponentName.KIOSK}
      fileNameToDownloadDefaultJson={`Default Kiosk theme - Location ${locationId}.json`}
      fileNameToDownloadCurrentJson={`Current Kiosk theme - Location ${locationId}.json`}
      title="Customize kiosk theme"
      entityLevel={CustomizeThemeLocalizationEntityLevel.LOCATION}
      entityId={locationId}
    />
  );
};

export default CustomizeKioskTheme;
