import FormTemplateStep from '../../../templates/Form/@types/FormTemplateStep';
import { Mutation } from '../../../__generated__/graphql';
import VENDOR_SERVER_VALIDATION_SCHEMA from './vendorServerValidationSchema';
import VendorServerFormInputs from '../@types/VendorServerFormInputs';
import VendorServerSection from '../components/VendorServerSection';

type CreateVendorServerData = Pick<Mutation, 'createOneVendorServer'>;

const VENDOR_SERVER_FORM_STEPS: FormTemplateStep<
  VendorServerFormInputs,
  CreateVendorServerData
>[] = [
  {
    canBeAccessed: (): boolean => true,
    component: VendorServerSection,
    name: 'Vendor Server',
    validationSchema: VENDOR_SERVER_VALIDATION_SCHEMA,
  },
];

export default VENDOR_SERVER_FORM_STEPS;
