import { gql } from '../__generated__/gql';

const DELETE_KIOSK = gql(`
  mutation DeleteOneKiosk($where: KioskWhereUniqueInput!) {
    deleteOneKiosk(where: $where) {
      id
      name
    }
  }
`);

export default DELETE_KIOSK;
