import { DataTableFilterValues } from '../../../../../@types/client/DataTableFilterValues';
import Filter from '../../../@types/Filter';

const getDefaultFilters = (filters: Filter[]): DataTableFilterValues[] => {
  const defaultFilterValues: DataTableFilterValues[] = [];

  filters
    .filter((filterField) => Boolean(filterField.defaultValue))
    .forEach((filterField) => {
      if (filterField.defaultValue) {
        const currentFilter = defaultFilterValues.findIndex(
          (defaultFilterValue) => defaultFilterValue.fieldKey === filterField.key
        );

        if (currentFilter > -1) {
          defaultFilterValues[currentFilter].values = [
            ...defaultFilterValues[currentFilter].values,
            filterField.value,
          ];
        } else {
          defaultFilterValues.push({
            fieldKey: filterField.key,
            values: [filterField.value],
          });
        }
      }
    });

  return defaultFilterValues;
};

export default getDefaultFilters;
