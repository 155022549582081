import { gql } from '../__generated__/gql';

const UPLOAD_UNIT_TYPES = gql(`
  mutation UploadUnitTypes($file: Upload!, $locationId: String!, $languageId: String!) {
    uploadUnitTypes(file: $file, locationId: $locationId, languageId: $languageId) {
      id
      type
    }
  }
`);

export default UPLOAD_UNIT_TYPES;
