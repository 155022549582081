const schemaType = 'type';

const jsonSchemaConstantKeys = {
  errorAddError: '.addError',
  schemaEnable: ':enable',
  schemaProperties: 'properties',
  schemaType,
  schemaTypeDot: `.${schemaType}`,
  uiDirective: '.ui:',
  uiFieldType: 'ui:fieldtype',
  uiReadOnly: 'ui:readonly',
};

export default jsonSchemaConstantKeys;
