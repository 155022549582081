import {
  MdBuild,
  MdCreditCard,
  MdLocationOn,
  MdPayment,
  MdPermDeviceInformation,
  MdPlace,
  MdSettingsApplications,
  MdSignalWifi4Bar,
  MdSignalWifiOff,
  MdTablet,
} from 'react-icons/md';
import { Query, QueryKioskArgs } from '../../__generated__/graphql';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ActionsTab from './components/ActionsTab';
import DetailPageTemplate from '../../templates/DetailPage';
import { EntityEnum } from '../../@types/client/DashboardUserRolePermissions';
import GET_KIOSK_PAGE_INFO from './graphql/getKioskPageInfo';
import KioskSettingsTab from './components/KioskSettingsTab';
import React from 'react';
import { RootState } from '../../redux/reducers';
import { flashApolloError } from '../../util/errorUtils';
import getBatteryAndChargingIcon from './functions/getBatteryAndChargingIcon';
import getKioskLocation from '../../util/kiosk/getKioskLocation';
import getOnlineStyle from './functions/getOnlineStyle';
import isDispenserOnline from '../../util/kiosk/isDispenserOnline';
import isKioskOnline from '../../util/kiosk/isKioskOnline';
import isPaymentTerminal from '../../util/kiosk/isPaymentTerminalOnline';
import links from '../../util/links';
import { openForm } from '../../redux/actions/modals.actions';
import { useQuery } from '@apollo/client';
import useRemoveKiosk from '../../hooks/useRemoveKiosk';
import { validateAccessByMembership } from '../../util/authorization';

type GetKioskData = Pick<Query, 'kiosk'>;

const KioskPage: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const membership = useSelector((state: RootState) => state.me.activeMembership);
  const { kioskId } = useParams<{ kioskId: string }>();

  const history = useHistory();

  const { data: kioskData, loading: kioskLoading } = useQuery<GetKioskData, QueryKioskArgs>(
    GET_KIOSK_PAGE_INFO,
    {
      onError: flashApolloError,
      variables: {
        where: { id: kioskId },
      },
    }
  );
  const kiosk = kioskData?.kiosk ?? null;

  if (!kiosk || kioskLoading) {
    return (
      <DetailPageTemplate
        dataType="Kiosk"
        detailItems={[]}
        identifier={kioskId}
        permission={EntityEnum.KIOSK}
        primaryHeader=""
        secondaryHeader="Kiosk Details"
        tabs={[]}
      />
    );
  }

  try {
    validateAccessByMembership({
      membership,
      objectMembership: { locationId: kiosk.location.id, tenantId: kiosk.location.tenantId },
      validateLocation: true,
    });
  } catch {
    return <Redirect to={links.paths.KIOSKS} />;
  }

  const kioskIsOnline = isKioskOnline(kiosk);
  const paymentTerminalIsOnline = isPaymentTerminal(kiosk);
  const dispenserIsOnline = isDispenserOnline(kiosk);
  const location = getKioskLocation(kiosk);

  return (
    <DetailPageTemplate
      dataType="Kiosk"
      detailItems={[
        {
          icon: MdTablet,
          label: 'Name',
          text: kiosk.name,
        },
        {
          icon: MdPlace,
          label: 'Location',
          link: `${links.paths.LOCATIONS}/${kiosk.location.id}`,
          text: kiosk.location.name,
        },
        {
          icon: kioskIsOnline ? MdSignalWifi4Bar : MdSignalWifiOff,
          label: 'Status',
          text: kioskIsOnline ? 'Online' : 'Offline',
          textStyle: getOnlineStyle(kioskIsOnline),
        },
        {
          icon: MdCreditCard,
          label: 'Dispenser type',
          text: kiosk.dispenserType?.toString() ?? 'Unknown',
        },
        {
          icon: MdCreditCard,
          label: 'Monitor dispenser',
          text: kiosk.hasDispenser ? 'Yes' : 'No',
        },
        {
          icon: MdCreditCard,
          label: 'Dispenser is connected',
          text: dispenserIsOnline ? 'Yes' : 'No',
          textStyle: getOnlineStyle(dispenserIsOnline ?? false),
        },
        {
          icon: MdPayment,
          label: 'Monitor payment terminal',
          text: kiosk.hasTerminal ? 'Yes' : 'No',
        },
        {
          icon: MdPayment,
          label: 'Payment terminal is connected',
          text: paymentTerminalIsOnline ? 'Yes' : 'No',
          textStyle: getOnlineStyle(paymentTerminalIsOnline ?? false),
        },
        {
          icon: getBatteryAndChargingIcon(kiosk.batteryLevel, kiosk.chargingState),
          label: 'Battery level',
          text: kiosk.batteryLevel?.toString() ?? 'Unknown',
        },
        {
          icon: getBatteryAndChargingIcon(kiosk.batteryLevel, kiosk.chargingState),
          label: 'Charging state',
          text: kiosk.chargingState?.toString() ?? 'Unknown',
        },
        {
          icon: MdBuild,
          label: 'Build number',
          text: kiosk.buildNumber ?? 'Unknown',
        },
        {
          icon: MdSettingsApplications,
          label: 'App version',
          text: kiosk.metadata?.appVersion ?? 'Unknown',
        },
        {
          icon: MdLocationOn,
          label: 'Coordinates',
          text: location ? `${location.lat}, ${location.lng}` : 'Unknown',
        },
        {
          icon: MdPermDeviceInformation,
          label: 'Device ID',
          text: kiosk.metadata?.deviceId ?? 'Unknown',
        },
        {
          icon: MdPermDeviceInformation,
          label: 'Device OS',
          text: kiosk.metadata?.deviceOS ?? 'Unknown',
        },
        {
          icon: MdPermDeviceInformation,
          label: 'Device version',
          text: kiosk.metadata?.deviceVersion ?? 'Unknown',
        },
        {
          icon: MdPermDeviceInformation,
          label: 'Device model',
          text: kiosk.metadata?.deviceModel ?? 'Unknown',
        },
      ]}
      identifier={kioskId}
      onEditButtonClick={(): void => {
        dispatch(openForm('kiosk', kioskId));
      }}
      onRemoveCompleted={(): void => {
        history.push(links.paths.KIOSKS);
      }}
      permission={EntityEnum.KIOSK}
      primaryHeader={`Kiosk: ${kiosk.name}`}
      remove={useRemoveKiosk}
      secondaryHeader="Kiosk Details"
      tabs={[
        {
          Component: ActionsTab,
          id: 'Actions',
        },
        {
          Component: KioskSettingsTab,
          id: 'Settings',
        },
      ]}
    />
  );
};

export default KioskPage;
