import { gql } from '../__generated__';

export const GET_TENANT_CUSTOMIZEABLE_COMPONENTS = gql(`
  query GetTenantDashboardCustomizeableComponents($entityId: String!) {
    getTenantDashboardCustomizeableComponents(tenantId: $entityId) {
      id
      name
    }
  }
`);

export const GET_LOCATION_CUSTOMIZEABLE_COMPONENTS = gql(`
  query GetLocationDashboardCustomizeableComponents($entityId: String!) {
    getLocationDashboardCustomizeableComponents(locationId: $entityId) {
      id
      name
    }
  }
`);
