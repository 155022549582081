import InputGroupLabelStyle from '../../../../../components/InputGroup/styles/InputGroupLabel.style';

const ChooseLocationLabelStyle = `
  ${InputGroupLabelStyle}

  margin: 10px 0 0 0;
  color: white;
`;

export default ChooseLocationLabelStyle;
