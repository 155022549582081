import styled from 'styled-components';

export const List = styled.div`
  display: grid;
  gap: 10px;

  @media only screen and (max-height: 768px) {
    gap: 5px;
  }
`;

export const ActionSection = styled.div``;
