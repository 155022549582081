import { AssaCardType, ResourceVendor } from '../../../__generated__/graphql';

import InputGroupProps from '../../InputGroup/@types/InputGroupProps';
import { values } from 'lodash';

const VENDOR_SECTION_INPUTS: InputGroupProps[] = [
  {
    label: 'Is active',
    name: 'isActive',
    type: 'checkbox',
  },
  {
    label: 'Vendor',
    name: 'vendor',
    options: values(ResourceVendor),
    type: 'select',
  },
  {
    label: 'Host',
    name: 'host',
    type: 'text',
  },
  {
    label: 'Port',
    name: 'port',
    type: 'number',
  },
  {
    label: 'Username',
    name: 'username',
    type: 'text',
  },
  {
    label: 'Password',
    name: 'password',
    type: 'text',
  },
  {
    label: 'Should all join',
    name: 'shouldAllJoin',
    type: 'checkbox',
  },
];

interface VendorSectionInputsProps {
  vendor: ResourceVendor;
}

const ASSA_ABLOY_INPUTS: InputGroupProps[] = [
  {
    label: 'Lock service code',
    name: 'lockServiceCode',
    type: 'text',
  },
  {
    label: 'Assa card type',
    name: 'assaCardType',
    options: values(AssaCardType),
    type: 'select',
  },
];

const SALTO_INPUTS: InputGroupProps[] = [
  {
    label: 'Trailer block',
    name: 'trailerBlock',
    type: 'text',
  },
];

const ASSA_ABLOY_VISIONLINE_INPUTS: InputGroupProps[] = [
  {
    label: 'Lock service code',
    name: 'lockServiceCode',
    type: 'text',
  },
  {
    label: 'Access ID',
    name: 'accessID',
    type: 'text',
  },
  {
    label: 'UUID',
    name: 'uuid',
    type: 'text',
  },
  {
    label: 'Assa card type',
    name: 'assaCardType',
    options: values(AssaCardType),
    type: 'select',
  },
];

const ONITY_INPUTS: InputGroupProps[] = [
  { label: 'Framework host', name: 'onityFrameworkHost', type: 'text' },
  { label: 'OnPortal host', name: 'onityOnPortalHost', type: 'text' },
  { label: 'OnPortal port', name: 'onityOnPortalPort', type: 'text' },
  { label: 'OnPortal username', name: 'onityOnPortalUsername', type: 'text' },
  { label: 'OnPortal password', name: 'onityOnPortalPassword', type: 'text' },
  { label: 'Core owner Id', name: 'onityCoreOwnerId', type: 'number' },
  { label: 'Core owner name', name: 'onityCoreOwnerName', type: 'text' },
  { label: 'Core certificate password', name: 'onityCoreCertificatePassword', type: 'text' },
  { label: 'Core certificate base64', name: 'onityCoreCertificateBase64', type: 'text' },
];

const OPEN_PATH_INPUTS: InputGroupProps[] = [
  { label: 'Namespace id', name: 'namespaceId', type: 'number' },
  { label: 'Credential type id', name: 'credentialTypeId', type: 'number' },
  { label: 'User schedule type id', name: 'userScheduleTypeId', type: 'number' },
];

const generateVendorSectionInputs = ({ vendor }: VendorSectionInputsProps): InputGroupProps[] => {
  if (vendor === ResourceVendor.AssaAbloy) {
    return [...VENDOR_SECTION_INPUTS, ...ASSA_ABLOY_INPUTS];
  }
  if (vendor === ResourceVendor.Salto) {
    return [...VENDOR_SECTION_INPUTS, ...SALTO_INPUTS];
  }
  if (vendor === ResourceVendor.AssaAbloyVisionline) {
    return [...VENDOR_SECTION_INPUTS, ...ASSA_ABLOY_VISIONLINE_INPUTS];
  }
  if (vendor === ResourceVendor.Onity) {
    return [...VENDOR_SECTION_INPUTS, ...ONITY_INPUTS];
  }
  if (vendor === ResourceVendor.Openpath) {
    return [...VENDOR_SECTION_INPUTS, ...OPEN_PATH_INPUTS];
  }

  return VENDOR_SECTION_INPUTS;
};

export default generateVendorSectionInputs;
