import { AccessGrantPageInfo } from '../../../../pages/AccessGrant/@types/GetAccessGrantPageInfo';
import { CredentialBasicTypeEnum } from '../../enums/CredentialBasicType.enum';
import FILTERABLE_CREDENTIAL_STATUS from '../../constants/FilterableCredentialStatus';
import Filter from '../../../../components/DataTable/@types/Filter';
import { UserTypeEnum } from '../../enums/UserType.enum';
import { getCredentialTypeName } from '../../functions/credentialType';
import { getUserTypeName } from '../../functions/userType';

function getUserTypeFilter(): Filter[] {
  const newFilters: Filter[] = [];

  Object.keys(UserTypeEnum).forEach((userType) => {
    newFilters.push({
      displayKey: 'Guest type',
      displayValue: getUserTypeName(UserTypeEnum[userType as keyof typeof UserTypeEnum]),
      key: 'userType',
      value: UserTypeEnum[userType as keyof typeof UserTypeEnum],
    });
  });

  return newFilters;
}

function getTypeFilter(): Filter[] {
  const newFilters: Filter[] = [];

  Object.keys(CredentialBasicTypeEnum).forEach((credentialType) => {
    newFilters.push({
      displayKey: 'Type',
      displayValue: getCredentialTypeName(
        CredentialBasicTypeEnum[credentialType as keyof typeof CredentialBasicTypeEnum]
      ),
      key: 'type',
      value: CredentialBasicTypeEnum[credentialType as keyof typeof CredentialBasicTypeEnum],
    });
  });

  return newFilters;
}

function getStatus(): Filter[] {
  const newFilters: Filter[] = [];

  Object.values(FILTERABLE_CREDENTIAL_STATUS).forEach((value) => {
    newFilters.push({
      displayKey: 'Status',
      displayValue: value,
      key: 'status',
      value,
    });
  });

  return newFilters;
}

export default function useCredentialsFilters(relatedAccessGrant?: AccessGrantPageInfo): Filter[] {
  let filters: Filter[] = [];

  if (relatedAccessGrant) {
    filters = [...filters, ...getUserTypeFilter()];
  }
  filters = [...filters, ...getTypeFilter()];
  filters = [...filters, ...getStatus()];

  return filters;
}
