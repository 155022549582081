/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationGeneralSchema: NodeSchemaProperties = {
  general: {
    title: '#1 General',
    type: 'object',
    properties: {
      'continue:enable': {
        title: '#1 Continue',
        type: 'boolean',
        description: '[general.continue]',
      },
      continue: { type: 'string' },
      'submit:enable': {
        title: '#2 Submit',
        type: 'boolean',
        description: '[general.submit]',
      },
      submit: { type: 'string' },
      'cancel:enable': {
        title: '#3 Cancel',
        type: 'boolean',
        description: '[general.cancel]',
      },
      cancel: { type: 'string' },
      'start:enable': {
        title: '#4 Start',
        type: 'boolean',
        description: '[general.start]',
      },
      start: { type: 'string' },
      'verifyId:enable': {
        title: '#5 Verify ID',
        type: 'boolean',
        description: '[general.verifyId]',
      },
      verifyId: { type: 'string' },
      'paymentAuthorization:enable': {
        title: '#6 Payment authorization',
        type: 'boolean',
        description: '[general.paymentAuthorization]',
      },
      paymentAuthorization: { type: 'string' },
      'paymentStatus:enable': {
        title: '#7 Payment status',
        type: 'boolean',
        description: '[general.paymentStatus]',
      },
      paymentStatus: { type: 'string' },
      'paymentCapture:enable': {
        title: '#8 Payment capture',
        type: 'boolean',
        description: '[general.paymentCapture]',
      },
      paymentCapture: { type: 'string' },
      'termsAndConditions:enable': {
        title: '#9 Terms and conditions',
        type: 'boolean',
        description: '[general.termsAndConditions]',
      },
      termsAndConditions: { type: 'string' },
      'internalServerError:enable': {
        description: '[general.internalServerError]',
        title: '#10 Internal Server Error',
        type: 'boolean',
      },
      internalServerError: {
        type: 'string',
      },
      format: {
        title: '#11 Format',
        type: 'object',
        properties: {
          'time:enable': {
            description: '[general.format.time]',
            title: '#11.1 Time',
            type: 'boolean',
          },
          time: {
            type: 'string',
          },
          'date:enable': {
            description: '[general.format.date]',
            title: '#11.2 Date',
            type: 'boolean',
          },
          date: {
            type: 'string',
          },
        },
      },
      bottomMenu: {
        title: '#12 Bottom Menu',
        type: 'object',
        properties: {
          'home:enable': {
            description: '[general.bottomMenu.home]',
            title: '#12.1 Home',
            type: 'boolean',
          },
          home: {
            type: 'string',
          },
          'access:enable': {
            description: '[general.bottomMenu.access]',
            title: '#12.2 Access',
            type: 'boolean',
          },
          access: {
            type: 'string',
          },
          'services:enable': {
            description: '[general.bottomMenu.services]',
            title: '#12.3 Services',
            type: 'boolean',
          },
          services: {
            type: 'string',
          },
          'trips:enable': {
            description: '[general.bottomMenu.trips]',
            title: '#12.4 Trips',
            type: 'boolean',
          },
          trips: {
            type: 'string',
          },
          'remoteAssistance:enable': {
            description: '[general.bottomMenu.remoteAssistance]',
            title: '#12.5 Remote Assistance',
            type: 'boolean',
          },
          remoteAssistance: {
            type: 'string',
          },
        },
      },
    },
  },
};

export const mobileV3LocalizationGeneralUiSchema: NodeUiSchemaProperties = {
  general: {
    continue: { 'ui:label': false, 'ui:readonly': true },
    submit: { 'ui:label': false, 'ui:readonly': true },
    cancel: { 'ui:label': false, 'ui:readonly': true },
    start: { 'ui:label': false, 'ui:readonly': true },
    verifyId: { 'ui:label': false, 'ui:readonly': true },
    paymentAuthorization: { 'ui:label': false, 'ui:readonly': true },
    paymentStatus: { 'ui:label': false, 'ui:readonly': true },
    paymentCapture: { 'ui:label': false, 'ui:readonly': true },
    termsAndConditions: { 'ui:label': false, 'ui:readonly': true },
    internalServerError: { 'ui:label': false, 'ui:readonly': true },
    format: {
      time: {
        'ui:label': false,
        'ui:readonly': true,
      },
      date: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    bottomMenu: {
      home: {
        'ui:label': false,
        'ui:readonly': true,
      },
      access: {
        'ui:label': false,
        'ui:readonly': true,
      },
      services: {
        'ui:label': false,
        'ui:readonly': true,
      },
      trips: {
        'ui:label': false,
        'ui:readonly': true,
      },
      remoteAssistance: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
  },
};
