import * as yup from 'yup';

const POLL_RESERVATION_FROM_CSV_VALIDATION_SCHEMA = yup.object().shape({
  confirmationCode: yup.string().required('Confirmation code is required'),
  location: yup.object().shape({
    label: yup.string().required('Location is required'),
    value: yup.string().required('Location is required'),
  }),
});

export default POLL_RESERVATION_FROM_CSV_VALIDATION_SCHEMA;
