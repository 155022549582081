import 'styled-components/macro';

import Button from '../../../components/Button';
import ButtonModel from '../../../components/Button/enums/ButtonModel.enum';
import React from 'react';
import { openForm } from '../../../redux/actions/modals.actions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

const ActionsTab: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { kioskId } = useParams<{ kioskId: string }>();

  return (
    <div>
      <Button
        css={`
          padding: 16px;
        `}
        model={ButtonModel.ACTIVE_CTA}
        onClick={(): void => {
          dispatch(openForm('KioskIssueKeycardForm', kioskId));
        }}
      >
        Issue Key Card With Access Grant ID
      </Button>
    </div>
  );
};

export default ActionsTab;
