import JsonCustomizationActionButton from './ActionButton';
import { MdSave } from 'react-icons/md';
import React from 'react';

interface JsonCustomizationSaveButtonInterface {
  onTapSubmitButton: () => void;
  enabled?: boolean;
}

const JsonCustomizationSaveButton: React.FC<JsonCustomizationSaveButtonInterface> = ({
  onTapSubmitButton,
  enabled = true,
}: JsonCustomizationSaveButtonInterface) => {
  return (
    <JsonCustomizationActionButton
      enabled={enabled}
      icon={<MdSave />}
      onClick={onTapSubmitButton}
    />
  );
};

export default JsonCustomizationSaveButton;
