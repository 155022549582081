import { FetchResult, useMutation } from '@apollo/client';

import { Mutation } from '../__generated__/graphql';
import MutationHookDataWithValue from './@types/MutationHookDataWithValue';
import MutationHookParams from './@types/MutationHookParams';
import { MutationUploadFileArgs } from '../__generated__/graphql';
import UPLOAD_FILE from '../graphql/uploadFile';
import UploadFileInputs from '../components/UserForm/@types/UploadFileInputs';
import { apolloRequirePreflightHeader } from '../client';

type UploadFileData = Pick<Mutation, 'uploadFile'>;

export default function useUploadFile({
  onCompleted = (): void => {},
  onError,
}: MutationHookParams<UploadFileData> = {}): MutationHookDataWithValue<
  UploadFileInputs,
  UploadFileData
> {
  const [create, { data, error, loading }] = useMutation<UploadFileData, MutationUploadFileArgs>(
    UPLOAD_FILE,
    {
      context: {
        headers: apolloRequirePreflightHeader,
      },
      onCompleted: (result) => {
        onCompleted(result);
      },
      onError,
    }
  );

  return {
    data,
    error,
    loading,
    mutation: async (inputs: UploadFileInputs): Promise<FetchResult<UploadFileData>> => {
      const UploadFileResult = await create({
        variables: {
          file: inputs.file,
          fileType: inputs.fileType,
          locationId: inputs.id,
        },
      });
      return UploadFileResult;
    },
  };
}
