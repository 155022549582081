import 'styled-components/macro';

import {
  passInfoThemeCustomizationSchema,
  passInfoThemeCustomizationUiSchema,
} from '../../../../../components/JsonEditor/constants/ThemeSchema/passInfoThemeCustomizationSchema';

import CustomizeTheme from '../../../../../components/CustomizeTheme';
import { CustomizeThemeLocalizationEntityLevel } from '../../../../../enums/CustomizeThemeLocalizationEntityLevel';
import React from 'react';
import { ThemeComponentName } from '../../../../../enums/ComponentNameEnum';
import { useParams } from 'react-router-dom';

const CustomizePassInfoTheme: React.FC<{}> = () => {
  const { locationId } = useParams<{ locationId: string }>();

  return (
    <CustomizeTheme
      schema={passInfoThemeCustomizationSchema}
      uiSchema={passInfoThemeCustomizationUiSchema}
      componentName={ThemeComponentName.PASS_INFO}
      fileNameToDownloadDefaultJson={`Default pass info theme - Location ${locationId}.json`}
      fileNameToDownloadCurrentJson={`Current pass info theme - Location ${locationId}.json`}
      title="Customize pass info theme"
      entityLevel={CustomizeThemeLocalizationEntityLevel.LOCATION}
      entityId={locationId}
    />
  );
};

export default CustomizePassInfoTheme;
