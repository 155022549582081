import GrantFormSection from '../components/GrantFormSection';
import RequirementSection from '../components/RequirementSection';
import ResourcesSection from '../components/ResourceSection';
import SecondaryUsersSection from '../components/SecondaryUsersSection';
import canAccessRequirementsSection from '../functions/canAccessRequirementsSection';

const ACCESS_GRANT_FORM_STEPS = [
  {
    canBeAccessed: (): boolean => true,
    component: GrantFormSection,
    formHeader: 'Create Access Grant',
    name: 'Grant',
  },
  {
    canBeAccessed: canAccessRequirementsSection,
    component: RequirementSection,
    name: 'Requirements',
  },
  {
    canBeAccessed: canAccessRequirementsSection,
    component: ResourcesSection,
    name: 'Resources',
  },
  {
    canBeAccessed: canAccessRequirementsSection,
    component: SecondaryUsersSection,
    name: 'Secondary Users',
  },
];

export default ACCESS_GRANT_FORM_STEPS;
