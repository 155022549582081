import 'styled-components/macro';
import Heading, { HeadingModel } from '../Heading';
import Container from '../Container';
import React from 'react';

export interface BannerProps {
  className?: string;
  subText?: string;
  text?: string;
}

const BannerContainerStyle = `
  margin: 0 0 40px;
`;

const BannerSubheaderStyle = `
  font-size: 18px;
`;

const Banner: React.FunctionComponent<BannerProps> = ({
  className,
  subText,
  text,
}: BannerProps) => {
  return (
    <Container alignContent="start" className={className} css={BannerContainerStyle} justify="left">
      {subText && (
        <Heading css={BannerSubheaderStyle} fontWeight="normal" model={HeadingModel.TERTIARY}>
          {subText}
        </Heading>
      )}
      <Heading model={HeadingModel.PRIMARY}>{text}</Heading>
    </Container>
  );
};

export default Banner;
