/* eslint-disable sort-keys */

import { Colors } from '../../../../../../theme/colors';
import { FontSize } from '../../../../../../theme/fontSizes';

const KioskLiveUsageCardStyle: React.CSSProperties = {
  width: 400,
  height: 275,
  borderRadius: 15,
  padding: 20,
  backgroundColor: Colors.ghostWhite,
};

export const KioskLiveUsageCardHeaderStyle: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
};

export const KioskLiveUsageCardKioskNameStyle: React.CSSProperties = {
  fontSize: FontSize.secondHeading,
};

export const KioskLiveUsagePreBookedPackagesHeaderStyle: React.CSSProperties = {
  marginTop: 5,
  fontSize: FontSize.thirdHeading,
};

const KioskLiveUsageCardInfoBaseStyle: React.CSSProperties = {
  borderRadius: 8,
  paddingRight: 10,
  paddingLeft: 10,
  paddingTop: 5,
  paddingBottom: 5,
  color: Colors.white,
  textAlign: 'center',
};

export const KioskLiveUsageCardStatusStyle: React.CSSProperties = {
  ...KioskLiveUsageCardInfoBaseStyle,
  color: Colors.white,
  fontWeight: 'bold',
};

export const KioskLiveUsageCardStatusActiveStyle: React.CSSProperties = {
  backgroundColor: Colors.mountainMeadow,
};

export const KioskLiveUsageCardStatusIdleStyle: React.CSSProperties = {
  backgroundColor: Colors.dodgerBlue,
};

export const KioskLiveUsageReservationContainerInfoStyle: React.CSSProperties = {
  marginTop: 15,
};

export const KioskLiveUsageReservationContainerRowStyle: React.CSSProperties = {
  display: 'flex',
  gap: '15px',
  marginBottom: '10px',
};

const KioskLiveUsageReservationBaseStyle: React.CSSProperties = {
  ...KioskLiveUsageCardInfoBaseStyle,
  backgroundColor: Colors.white,
  color: Colors.blackTint,
  fontWeight: 500,
};

export const KioskLiveUsageReservationInfoStyle: React.CSSProperties = {
  ...KioskLiveUsageReservationBaseStyle,
  flex: 3,
};

export const KioskLiveUsageReservation1stColumnInfoStyle: React.CSSProperties = {
  flex: 5,
};

export const KioskLiveUsageReservationConfirmationNumberStyle: React.CSSProperties = {
  color: Colors.dodgerBlue,
  fontWeight: 'bold',
};

export const KioskLiveUsagePreBookedPackagesContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  flex: 1,
};

export const KioskLiveUsagePreBookedPackagesStyle: React.CSSProperties = {
  ...KioskLiveUsageReservationBaseStyle,
  flex: '45%',
};

export default KioskLiveUsageCardStyle;
