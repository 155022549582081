import { Colors, RGB } from '../../../theme/colors';

const TextAreaContainerStyle = `
  textarea {
    border: solid 2px ${`rgba(${RGB.linkWater}, .2)`};
    background: ${Colors.ghostWhite};
    padding: 10px;
    border-radius: 5px;
    resize: none;

    &.error {
      border-color: ${Colors.salmon};
    }
    
    &:focus {
      outline: none !important;
      border-color: ${Colors.mountainMeadow};
    }
  }
`;

export default TextAreaContainerStyle;
