import { CredentialBasicTypeEnum } from '../enums/CredentialBasicType.enum';
import { CredentialType } from '../../../__generated__/graphql';

export const getCredentialTypeName = (type: CredentialBasicTypeEnum): string => {
  switch (type) {
    case CredentialBasicTypeEnum.KEYCARD:
      return 'Keycard';

    case CredentialBasicTypeEnum.MOBILE_KEY:
      return 'Mobile key';

    case CredentialBasicTypeEnum.QR:
      return 'QR';

    default:
      return 'Other';
  }
};

export const credentialTypesByBasicType: Record<CredentialBasicTypeEnum, CredentialType[]> = {
  KEYCARD: [
    CredentialType.SaltoCardBlob,
    CredentialType.MifareCard_1K,
    CredentialType.MifareUltralight,
    CredentialType.MifarePlus,
    CredentialType.MifareUltralightC,
  ],
  MOBILE_KEY: [
    CredentialType.SaltoMobile,
    CredentialType.DormakabaSystem_6000Mobile,
    CredentialType.DormakabaAmbianceMobile,
    CredentialType.AssaAbloyMobile,
    CredentialType.MiwaMobile,
  ],
  OTHER: [CredentialType.WebsiteToken],
  QR: [CredentialType.Qr],
};

export const getCredentialBasicType = (type: CredentialType): CredentialBasicTypeEnum => {
  const credentialsValues = Object.values(credentialTypesByBasicType);

  for (let iCount = 0; iCount < credentialsValues.length; iCount += 1) {
    const values = credentialsValues[iCount];
    if (values.includes(type)) {
      return Object.keys(credentialTypesByBasicType)[iCount] as CredentialBasicTypeEnum;
    }
  }

  return CredentialBasicTypeEnum.OTHER;
};
