/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationTermsAndConditionsSchema: NodeSchemaProperties = {
  termsAndConditionsScreen: {
    title: '#19 Terms And Conditions Screen',
    type: 'object',
    properties: {
      signature: {
        title: '#1 Signature',
        type: 'object',
        properties: {
          'signaturePlaceholder:enable': {
            description: '[termsAndConditionsScreen.signature.signaturePlaceholder]',
            title: '#1.1 Signature Placeholder',
            type: 'boolean',
          },
          signaturePlaceholder: {
            type: 'string',
          },
          'clearButton:enable': {
            description: '[termsAndConditionsScreen.signature.clearButton]',
            title: '#1.2 Clear Button',
            type: 'boolean',
          },
          clearButton: {
            type: 'string',
          },
          'submitButton:enable': {
            description: '[termsAndConditionsScreen.signature.submitButton]',
            title: '#1.3 Submit Button',
            type: 'boolean',
          },
          submitButton: {
            type: 'string',
          },
          'signatureError:enable': {
            description: '[termsAndConditionsScreen.signature.signatureError]',
            title: '#1.4 Signature Error',
            type: 'boolean',
          },
          signatureError: {
            type: 'string',
          },
          'signatureEmptyWarning:enable': {
            description: '[termsAndConditionsScreen.signature.signatureEmptyWarning]',
            title: '#1.5 Signature Empty Warning',
            type: 'boolean',
          },
          signatureEmptyWarning: {
            type: 'string',
          },
        },
      },
    },
  },
};

export const mobileV3LocalizationTermsAndConditionsUiSchema: NodeUiSchemaProperties = {
  termsAndConditionsScreen: {
    signature: {
      signaturePlaceholder: {
        'ui:label': false,
        'ui:readonly': true,
      },
      clearButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
      submitButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
      signatureError: {
        'ui:label': false,
        'ui:readonly': true,
      },
      signatureEmptyWarning: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
  },
};
