/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationTripsSchema: NodeSchemaProperties = {
  trips: {
    title: '#8 Trips',
    type: 'object',
    properties: {
      currentTrip: {
        title: '#1 Current Trip',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[trips.currentTrip.title]',
            title: '#1.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          noTrip: {
            title: '#1.2 No Trip',
            type: 'object',
            properties: {
              'title:enable': {
                description: '[trips.currentTrip.noTrip.title]',
                title: '#1.2.1 Title',
                type: 'boolean',
              },
              title: {
                type: 'string',
              },
              'description:enable': {
                description: '[trips.currentTrip.noTrip.description]',
                title: '#1.2.2 Description',
                type: 'boolean',
              },
              description: {
                type: 'string',
              },
            },
          },
        },
      },
      upcomingTrips: {
        title: '#2 Upcoming Trips',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[trips.upcomingTrips.title]',
            title: '#2.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          noTrips: {
            title: '#2.2 No Trips',
            type: 'object',
            properties: {
              'title:enable': {
                description: '[trips.upcomingTrips.noTrips.title]',
                title: '#2.2.1 Title',
                type: 'boolean',
              },
              title: {
                type: 'string',
              },
              'description:enable': {
                description: '[trips.upcomingTrips.noTrips.description]',
                title: '#2.2.2 Description',
                type: 'boolean',
              },
              description: {
                type: 'string',
              },
            },
          },
        },
      },
      bookingCard: {
        title: '#3 Booking Card',
        type: 'object',
        properties: {
          'detailsTitle:enable': {
            description: '[trips.bookingCard.detailsTitle]',
            title: '#3.1 Details Title',
            type: 'boolean',
          },
          detailsTitle: {
            type: 'string',
          },
          'checkIn:enable': {
            description: '[trips.bookingCard.checkIn]',
            title: '#3.2 Check In',
            type: 'boolean',
          },
          checkIn: {
            type: 'string',
          },
          'checkOut:enable': {
            description: '[trips.bookingCard.checkOut]',
            title: '#3.3 Check Out',
            type: 'boolean',
          },
          checkOut: {
            type: 'string',
          },
          'guests:enable': {
            description: '[trips.bookingCard.guests]',
            title: '#3.4 Guests',
            type: 'boolean',
          },
          guests: {
            type: 'string',
          },
          'adultsPlural:enable': {
            description: '[trips.bookingCard.adultsPlural]',
            title: '#3.5 Adults Plural',
            type: 'boolean',
          },
          adultsPlural: {
            type: 'string',
          },
          'adultsSingular:enable': {
            description: '[trips.bookingCard.adultsSingular]',
            title: '#3.6 Adults Singular',
            type: 'boolean',
          },
          adultsSingular: {
            type: 'string',
          },
        },
      },
      'title:enable': {
        description: '[trips.title]',
        title: '#4 Title',
        type: 'boolean',
      },
      title: {
        type: 'string',
      },
    },
  },
};

export const mobileV3LocalizationTripsUiSchema: NodeUiSchemaProperties = {
  trips: {
    currentTrip: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      noTrip: {
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
    },
    upcomingTrips: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      noTrips: {
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
    },
    bookingCard: {
      detailsTitle: {
        'ui:label': false,
        'ui:readonly': true,
      },
      checkIn: {
        'ui:label': false,
        'ui:readonly': true,
      },
      checkOut: {
        'ui:label': false,
        'ui:readonly': true,
      },
      guests: {
        'ui:label': false,
        'ui:readonly': true,
      },
      adultsPlural: {
        'ui:label': false,
        'ui:readonly': true,
      },
      adultsSingular: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
};
