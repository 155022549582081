import 'styled-components/macro';

import {
  JumioDocumentAndImage,
  JumioImage,
  Query,
  QueryUserRecordsArgs,
  SortOrder,
  UserRecord,
  UserRecordType,
} from '../../__generated__/graphql';
import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery, useQuery } from '@apollo/client';

import Button from '../Button';
import ButtonModel from '../Button/enums/ButtonModel.enum';
import CloseButtonStyle from '../ModalInformationComponent/styles/CloseButton.style';
import { Colors } from '../../theme/colors';
import Container from '../Container';
import { ID_RESULT } from '../../graphql/queries';
import ImageContainerStyle from './styles/ImageContainer.style';
import { LoadingSpinner } from '../LoadingSpinner';
import { MdClose } from 'react-icons/md';
import Modal from 'react-modal';
import ModalInformationStyle from '../ModalInformationComponent/styles/ModalInformationStyle';
import { RootState } from '../../redux/reducers';
import SpinnerContainerStyle from './styles/SpinnerContainer.style';
import { USER_RECORDS } from '../../graphql/queries/userRecords';
import { closeForm } from '../../redux/actions/modals.actions';
import startCase from 'lodash/startCase';

interface JumioIDModalProps {
  userId?: string;
}

type GetUserRecordsData = Pick<Query, 'userRecords'>;
type TRetrieveVerificationResultData = Pick<Query, 'retrieveVerificationResult'>;

const JumioIDModal: React.FC<JumioIDModalProps> = ({ userId }: JumioIDModalProps) => {
  const me = useSelector((state: RootState) => state.me);
  const [userRecord, setUserRecord] = useState<UserRecord | null>(null);
  const [jumioResult, setJumioResult] = useState<JumioDocumentAndImage | null>(null);

  const dispatch = useDispatch();

  const [retrieveIDResult, { loading: loadingIDResult }] = useLazyQuery(ID_RESULT, {
    onCompleted({ retrieveVerificationResult }: TRetrieveVerificationResultData) {
      setJumioResult(retrieveVerificationResult);
    },
    variables: { scanReference: userRecord?.recordId },
  });

  const activeTenantId = me.activeMembership?.tenantId ?? '';
  const { loading: loadingUserRecords } = useQuery<GetUserRecordsData, QueryUserRecordsArgs>(
    USER_RECORDS,
    {
      onCompleted({ userRecords }) {
        setUserRecord(userRecords[userRecords.length - 1]);
        retrieveIDResult();
      },
      variables: {
        orderBy: [
          {
            timeStamp: SortOrder.Asc,
          },
        ],
        where: {
          recordType: { equals: UserRecordType.IdVerification },
          tenantId: { equals: activeTenantId },
          userId: { equals: userId },
        },
      },
    }
  );

  function close(): void {
    dispatch(closeForm());
  }

  const imageMap = jumioResult?.images?.map((image: JumioImage, index: number) => {
    return (
      <div key={index}>
        <img
          src={`data:image/jpeg;base64,${image.href}`}
          style={{ height: 200 }}
          alt={image.classifier ?? ''}
        />
        <div>{startCase(image.classifier ?? '')}</div>
      </div>
    );
  });

  const RowItem: React.FC<{ label: string; value?: string }> = ({
    label,
    value,
  }: {
    label: string;
    value?: string;
  }) => {
    return (
      <div style={{ display: 'flex' }}>
        <span style={{ flex: 1, fontWeight: 'bold' }}>{label}</span>
        <span style={{ flex: 3 }}>{value || 'N/A'}</span>
      </div>
    );
  };

  return (
    <Modal
      isOpen
      onRequestClose={close}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      style={ModalInformationStyle}
    >
      <Container>
        <Button
          css={CloseButtonStyle}
          background={Colors.blackRussian}
          model={ButtonModel.PASSIVE_ICON_BUTTON}
          onClick={(): void => {
            close();
          }}
        >
          <MdClose size="22px" />
        </Button>
        {loadingUserRecords || loadingIDResult ? (
          <Container css={SpinnerContainerStyle}>
            <LoadingSpinner />
          </Container>
        ) : (
          <Container>
            <Container css={ImageContainerStyle}>{imageMap}</Container>
            <RowItem label="DOB" value={jumioResult?.dob ?? ''} />
            <RowItem label="First Name" value={jumioResult?.firstName ?? ''} />
            <RowItem label="Last Name" value={jumioResult?.lastName ?? ''} />
            <RowItem label="Issuing Country" value={jumioResult?.issuingCountry ?? ''} />
            <RowItem label="Status" value={startCase(jumioResult?.status ?? '')} />
            <RowItem label="Type" value={startCase(jumioResult?.type ?? '')} />
          </Container>
        )}
      </Container>
    </Modal>
  );
};

export default memo(JumioIDModal);
