import { InfoType } from '../../../../__generated__/graphql';

export enum ConciergeContentType {
  imageAndDescription = 'imageAndDescription',
  pdfViewer = 'pdfViewer',
  externalUrl = 'externalUrl',
}

export const InfoTypeLabel = {
  [InfoType.InfoItem]: 'Info Items',
  [InfoType.Category]: 'Category',
};

export default interface InfoItemFormInputs {
  pdfUrl?: string | null;
  externalUrl?: string | null;
  parentCategory?: string | null;
  imageUrl?: string | null;
  title?: string | null;
  description?: string | null;
  type?: InfoType | null;
  contentType?: keyof ConciergeContentType;
}
