import { gql } from '../__generated__/gql';

const CREATE_UNIT_TYPE = gql(`
  mutation CreateUnitTypeAndUploadImageFile($data: CustomUnitTypeCreateInput!) {
    createUnitTypeAndUploadImageFile(data: $data) {
      id
      imageUrl
      locationId
      numberOfGuests
      type
    }
  }
`);

export default CREATE_UNIT_TYPE;
