import { Colors } from '../../../theme/colors';
import { HEADER_HEIGHT } from '../../../constants/dimensions';

const AppHeaderStyle = `
  background: ${Colors.blackRussian};
  box-shadow: 0 3px 10px 2px rgba(57, 63, 72, 0.3);
  box-sizing: border-box;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 200px;
  height: ${HEADER_HEIGHT};
  left: 0;
  padding: 0 25px;
  position: sticky;
  right: 0;
  top: 0;
  z-index: 10;
`;

export default AppHeaderStyle;
