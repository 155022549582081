import Theme from '../../../theme';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 220px 120px 100px;
  gap: 20px;
  height: 35px;
  margin: 15px 0;

  input {
    width: 120px;
    background-color: ${Theme.colors.borderDark.hexCode};
    border-radius: 10px;
    box-sizing: border-box;
    border: none;
    color: ${Theme.colors.white.hexCode};
    text-align: center;
    font-size: 26px;
    text-transform: uppercase;
    cursor: not-allowed;
  }
`;
