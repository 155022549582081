import { AccessGrantStatus } from '../../../../../__generated__/graphql';
import Filter from '../../../../../components/DataTable/@types/Filter';

export default function addStatusFilters(filters: Filter[]): void {
  Object.keys(AccessGrantStatus).forEach((status) => {
    let initialValue: boolean | undefined;
    if (status.toUpperCase() === AccessGrantStatus.Active.toUpperCase()) {
      initialValue = true;
    }

    filters.push({
      defaultValue: initialValue,
      displayKey: 'Status',
      displayValue: AccessGrantStatus[status as keyof typeof AccessGrantStatus],
      key: 'status',
      value: AccessGrantStatus[status as keyof typeof AccessGrantStatus],
    });
  });
}
