import { Query, QueryAmenityGroupsArgs, SortOrder } from '../__generated__/graphql';

import QueryHookData from './@types/QueryHookData';
import QueryHookParams from './@types/QueryHookParams';
import SEARCH_AMENITY_GROUPS from '../graphql/searchAmenityGroups';
import { useLazyQuery } from '@apollo/client';

type SearchAmenityGroupsData = Pick<Query, 'amenityGroups'>;

export default function useSearchAmenityGroupsByName(params?: QueryHookParams): QueryHookData {
  const { onCompleted = (): void => {}, onError = (): void => {}, searchValue = '' } = params ?? {};

  const [getAmenityGroups, { data, error, loading }] = useLazyQuery<
    SearchAmenityGroupsData,
    QueryAmenityGroupsArgs
  >(SEARCH_AMENITY_GROUPS, {
    onCompleted,
    onError,
  });

  const amenityGroups = data?.amenityGroups ?? [];

  return {
    data: amenityGroups.map((amenityGroup) => ({ id: amenityGroup.id, name: amenityGroup.title })),
    error,
    loading,
    query: (title = ''): void => {
      getAmenityGroups({
        variables: {
          orderBy: [{ title: SortOrder.Asc }],
          take: 10,
          where: {
            title: { contains: title ?? searchValue },
          },
        },
      });
    },
  };
}
