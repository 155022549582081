import React, { useContext } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';

import Form from '../../Form';
import FormContext from '../../../contexts/form.context';
import LOCATION_SECTION_INPUTS from '../inputs/locationSectionInputs';
import LocationFormInputs from '../@types/LocationFormInputs';
import SimpleFormSection from '../../../templates/SimpleFormSection';
import SubmitRow from '../../SubmitRow';
import canAccessLocationRequirementsSection from '../functions/canAccessLocationRequirementsSection';

const LocationSection: React.FC<{}> = () => {
  const { errors, handleSubmit } = useFormContext();
  const { setStep, step } = useContext(FormContext);

  const submitHandler: SubmitHandler<LocationFormInputs> = (inputs) => {
    if (canAccessLocationRequirementsSection(inputs, errors)) {
      setStep((step ?? 0) + 1);
    }
  };

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <SimpleFormSection inputs={LOCATION_SECTION_INPUTS} />
      <SubmitRow submitText="Set Requirements" />
    </Form>
  );
};

export default LocationSection;
