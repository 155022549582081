import { AddDispenserFormSection } from './AddDispenserFormSection';
import Container from '../../Container';
import Form from '../../Form';
import InputGroup from '../../InputGroup';
import KioskFormInputs from '../@types/KioskFormInputs';
import React from 'react';
import SubmitRow from '../../SubmitRow';
import getKioskConfigurationInputs from '../constants/kioskConfigurationsInputs';
import { useFormContext } from 'react-hook-form';
import useKioskSubmit from '../hooks/useKioskSubmit';

const KioskConfigurationsSection: React.FC<{}> = () => {
  const { handleSubmit, getValues } = useFormContext<KioskFormInputs>();
  const { loadingMutation, submitHandler } = useKioskSubmit();
  const { maintenanceMode } = getValues();

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Container>
        <Container columnTemplate="1fr 1fr" rowGap="25px" columnGap="15px">
          {getKioskConfigurationInputs(maintenanceMode).map((input) => (
            <InputGroup key={`simple-form-section-input-${input.name}`} {...input} />
          ))}
        </Container>

        <AddDispenserFormSection />
      </Container>

      <SubmitRow loading={loadingMutation} submitText="Update Kiosk" />
    </Form>
  );
};

export default KioskConfigurationsSection;
