import { gql } from '../__generated__/gql';

const GET_USER_RECORD_COUNT = gql(`
  query GetUserRecordCount($where: UserRecordWhereInput!) {
    aggregateUserRecord(where: $where) {
      _count {
        _all
      }
    }
  }
`);

export default GET_USER_RECORD_COUNT;
