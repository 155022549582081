import Theme from '../../../../../theme';

export const imageStyle: React.CSSProperties = {
  height: '195px',
  marginBottom: '8px',
  marginTop: '20px',
};

export const mainContainerStyle: React.CSSProperties = {
  alignItems: 'center',
  backgroundColor: Theme.colors.white.hexCode,
  borderRadius: '12px',
  display: 'flex',
  flexDirection: 'row',
  gap: '15px',
  padding: '12px',
};

export const textContainer: React.CSSProperties = {
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
  width: '0px',
};

export const labelTextStyle: React.CSSProperties = {
  fontSize: '18px',
  fontWeight: 'bold',
};

export const labelContainer: React.CSSProperties = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '8px',
};

export const valueTextStyle: React.CSSProperties = {
  color: Theme.colors.quickActionButton.hexCode,
  fontSize: '18px',
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  wordBreak: 'break-all',
};
