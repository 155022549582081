import _ from 'lodash';
import enableField from './enableField';
import iterateInJson from './iterateInJson';
import jsonSchemaConstantKeys from '../constants/jsonSchemaConstantKeys';

/** This function should enable the `:enable` checkbox for the desired field */
const enableCheckbox = (
  jsonData: Record<string, unknown>,
  fieldKey: string,
  enable: boolean
): void => {
  iterateInJson(jsonData, (_data, parentKey, key): boolean => {
    const mainKey = `${parentKey}.${key}`;
    if (mainKey === fieldKey) {
      // eslint-disable-next-line no-param-reassign
      _data[`${key}${jsonSchemaConstantKeys.schemaEnable}`] = enable;
      return true;
    }

    return false;
  });
};

/** This function should detect whether that field is customized in the current level or not */
const fieldItIsSet = (
  previousJsonCurrentLevel: Record<string, unknown>,
  fieldKey: string
): boolean => {
  let found = false;

  iterateInJson(previousJsonCurrentLevel, (_data, parentKey, key): boolean => {
    const mainKey = `${parentKey}.${key}`;

    if (mainKey === fieldKey) {
      found = true;
      return true;
    }

    return false;
  });

  return found;
};

/** This function should go through the whole JSON received and
 * for every field customized in the current level, should:
 * - Check the `:enable` checkbox
 * - Enable the field since when not customized is in the read-only mode
 */
export default function fillCheckBoxesOnStarting(
  data: {
    jsonData: Record<string, unknown>;
    previousJsonCurrentLevel: Record<string, unknown>;
    schema: Record<string, unknown>;
    uiSchema: Record<string, unknown>;
  },
  updateJsonData: (updatedJsonData: Record<string, unknown>) => void,
  updateUiSchema: (updatedUiSchema: Record<string, unknown>) => void
): void {
  const { schema, previousJsonCurrentLevel } = data;
  const jsonData = _.cloneDeep(data.jsonData);
  const uiSchema = _.cloneDeep(data.uiSchema);

  iterateInJson(schema.properties as Record<string, unknown>, (_data, parentKey, key): boolean => {
    let mainKey = `${parentKey}.${key}`;

    const isNotTheEnableCheckBoxField = mainKey.indexOf(jsonSchemaConstantKeys.schemaEnable) === -1;

    // The type field should be used because it's the only field used for
    //  every theme/localization schema property
    const isTheTypeField =
      mainKey.substring(mainKey.length - jsonSchemaConstantKeys.schemaTypeDot.length) ===
      jsonSchemaConstantKeys.schemaTypeDot;

    const isNotAObjectField = _data[key] !== 'object';

    if (isNotTheEnableCheckBoxField && isTheTypeField && isNotAObjectField) {
      // Remove the ".type" string in the middle of the stirng
      mainKey = mainKey.substring(0, mainKey.lastIndexOf(jsonSchemaConstantKeys.schemaTypeDot));

      // Remove the "properties" string in the middle of the string
      mainKey = mainKey.replaceAll(`.${jsonSchemaConstantKeys.schemaProperties}`, '');

      const valueFieldItIsSet = fieldItIsSet(previousJsonCurrentLevel, mainKey);
      enableCheckbox(jsonData, mainKey, valueFieldItIsSet);
      enableField(uiSchema, mainKey, valueFieldItIsSet);
    }

    return false;
  });

  updateUiSchema(uiSchema);
  updateJsonData(jsonData);
}
