import {
  AccessGrantUpdateInput,
  AccessGrantWhereUniqueInput,
  Mutation,
  MutationUpdateAccessGrantArgs,
  SecondaryUsersOnAccessGrantsWhereUniqueInput,
} from '../__generated__/graphql';
import { FetchResult, useMutation } from '@apollo/client';

import AccessGrantFormInputs from '../components/AccessGrantForm/@types/AccessGrantFormInputs';
import { GET_ACCESS_GRANT_PAGE_INFO_AND_CHECK_NOTIFICATION_CAPABILITY } from '../pages/AccessGrant/graphql/getAccessGrantPageInfo';
import GET_ACCESS_GRANT_PAGE_REQUIREMENTS from '../pages/AccessGrant/graphql/getAccessGrantPageRequirements';
import GET_ACCESS_GRANT_PAGE_RESOURCES from '../pages/AccessGrant/graphql/getAccessGrantPageResources';
import GET_USER from '../graphql/getUser';
import MutationHookDataWithValue from './@types/MutationHookDataWithValue';
import MutationHookParams from './@types/MutationHookParams';
import UPDATE_ACCESS_GRANT from '../graphql/updateAccessGrant';
import buildFieldUpdateOperationsInput from '../util/api/buildFieldUpdateOperationsInput';
import { closeForm } from '../redux/actions/modals.actions';
import { flashApolloError } from '../util/errorUtils';
import forIn from 'lodash/forIn';
import links from '../util/links';
import transformCreateInputIntoUpdateInput from '../util/api/transformCreateInputIntoUpdateInput';
import { useDispatch } from 'react-redux';

type UpdateAccessGrantData = Pick<Mutation, 'updateAccessGrant'>;

export default function useUpdateAccessGrant({
  onCompleted = (): void => {},
  onError = flashApolloError,
}: MutationHookParams<UpdateAccessGrantData> = {}): MutationHookDataWithValue<
  AccessGrantFormInputs,
  UpdateAccessGrantData
> {
  const dispatch = useDispatch();

  const [update, { data, error, loading }] = useMutation<
    UpdateAccessGrantData,
    MutationUpdateAccessGrantArgs
  >(UPDATE_ACCESS_GRANT, {
    onCompleted: ({ updateAccessGrant }) => {
      dispatch(closeForm());

      window.flash({
        link: `${links.paths.ACCESS_GRANTS}/${updateAccessGrant.id}`,
        message: `Access Grant: ${updateAccessGrant.confirmationCode} updated successfully`,
      });

      onCompleted({ updateAccessGrant });
    },
    onError,
  });

  return {
    data,
    error,
    loading,
    mutation: async (
      { originalAgreementFileId, ...inputs }: AccessGrantFormInputs,
      id = ''
    ): Promise<FetchResult<UpdateAccessGrantData>> => {
      const startsAt = new Date(
        Date.UTC(
          inputs.startsAt.getFullYear(),
          inputs.startsAt.getMonth(),
          inputs.startsAt.getDate()
        )
      );

      const endsAt = new Date(
        Date.UTC(inputs.endsAt.getFullYear(), inputs.endsAt.getMonth(), inputs.endsAt.getDate())
      );

      const requirementsToUpdate = inputs.requirements.values
        .filter((currentReq) =>
          inputs.requirements.existing
            .map((existingReq) => existingReq.type)
            .includes(currentReq.type)
        )
        .map((currentReq) => {
          return {
            ...inputs.requirements.existing.find(
              (existingReq) => existingReq.type === currentReq.type
            ),
            ...currentReq,
          };
        })
        .filter((currentReq) => currentReq.requirementId)
        .map((currentReq) => ({
          data: {
            metadata: currentReq.metadata ? currentReq.metadata : undefined,
            status: { set: currentReq.status },
            type: { set: currentReq.type },
          },
          where: {
            id: currentReq.requirementId!,
          },
        }));

      const requirementsToCreate = inputs.requirements.values
        .filter((currentReq) => {
          return (
            currentReq.type &&
            !inputs.requirements.existing.find((existingReq) => {
              return existingReq.type === currentReq.type;
            })
          );
        })
        .map((currentReq) => ({
          metadata: currentReq.metadata ? currentReq.metadata : undefined,
          status: currentReq.status,
          type: currentReq.type,
        }));

      const requirementsToDelete = inputs.requirements.existing
        .filter((existingReq) => {
          return (
            existingReq.requirementId &&
            inputs.requirements.delete.find((currentReq) => {
              return (
                currentReq === existingReq.requirementId &&
                !requirementsToUpdate
                  .map((requirement) => requirement.data.type.set)
                  .includes(existingReq.type)
              );
            })
          );
        })
        .map((existingReq) => ({ id: existingReq.requirementId! }));

      const resourcesToConnect: AccessGrantWhereUniqueInput[] = [];
      const resourcesToDisconnect: AccessGrantWhereUniqueInput[] = [];

      forIn(inputs.resources.map, (value, key) => {
        if (value.shouldDisconnect) {
          resourcesToDisconnect.push({ id: key });
        } else if (value.shouldConnect) {
          resourcesToConnect.push({ id: key });
        }
      });

      const secondaryUsersToConnect: SecondaryUsersOnAccessGrantsWhereUniqueInput[] = [];
      const secondaryUsersToDisconnect: SecondaryUsersOnAccessGrantsWhereUniqueInput[] = [];

      forIn(inputs.secondaryUsers.map, (value, key) => {
        const itemToPush = {
          userId_accessGrantId: {
            accessGrantId: id,
            userId: key,
          },
        };

        if (value.shouldDisconnect) {
          secondaryUsersToDisconnect.push(itemToPush);
        } else if (value.shouldConnect) {
          secondaryUsersToConnect.push(itemToPush);
        }
      });

      const updateInputs: AccessGrantUpdateInput = {
        ...transformCreateInputIntoUpdateInput<AccessGrantUpdateInput>({
          ...inputs,
          endsAt: undefined,
          location: undefined,
          requirements: undefined,
          resources: undefined,
          retryCheckin: undefined,
          secondaryUsers: undefined,
          startsAt: undefined,
          user: undefined,
        }),
        endsAt: buildFieldUpdateOperationsInput(endsAt.toUTCString()),
        location: {
          connect: { id: inputs.location.value },
        },
        requirements: {
          create: requirementsToCreate,
          delete: requirementsToDelete,
          update: requirementsToUpdate,
        },
        resources: {
          connect: resourcesToConnect,
          disconnect: resourcesToDisconnect,
        },
        retryCheckin: buildFieldUpdateOperationsInput(
          (inputs.retryCheckin ?? '') === '' ? null : inputs.retryCheckin
        ),
        secondaryUsers: {
          connect: secondaryUsersToConnect,
          disconnect: secondaryUsersToDisconnect,
        },
        startsAt: buildFieldUpdateOperationsInput(startsAt.toUTCString()),
        user: {
          connect: { id: inputs.user.value },
        },
      };
      const updateResult = await update({
        refetchQueries: [
          {
            query: GET_ACCESS_GRANT_PAGE_INFO_AND_CHECK_NOTIFICATION_CAPABILITY,
            variables: {
              accessGrantId: id,
              where: { id: { equals: id } },
            },
          },
          {
            query: GET_USER,
            variables: {
              where: {
                id: {
                  equals: id,
                },
              },
            },
          },
          {
            query: GET_ACCESS_GRANT_PAGE_RESOURCES,
            variables: {
              where: {
                accessGrants: {
                  some: {
                    id: {
                      equals: id,
                    },
                  },
                },
              },
            },
          },
          {
            query: GET_ACCESS_GRANT_PAGE_REQUIREMENTS,
            variables: {
              where: {
                accessGrantId: {
                  equals: id,
                },
              },
            },
          },
        ],
        variables: {
          data: updateInputs,
          originalAgreementFileId,
          where: { id },
        },
      });

      return updateResult;
    },
  };
}
