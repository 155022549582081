import { gql } from '../__generated__/gql';

const DELETE_VENDOR_SERVER = gql(`
  mutation DeleteOneVendorServer($where: VendorServerWhereUniqueInput!) {
    deleteOneVendorServer(where: $where) {
      id
      vendor
    }
  }
`);

export default DELETE_VENDOR_SERVER;
