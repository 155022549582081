import { Datum } from '../../../components/DataTable';
import { Query } from '../../../__generated__/graphql';
import moment from 'moment';

type GetUserRecordsData = Pick<Query, 'userRecords'>;

export default function generateUserRecordsTableData(data?: GetUserRecordsData): Datum[] {
  const userRecords = data?.userRecords || [];

  const tableData = userRecords.map((userRecord) => {
    return {
      dataType: 'userRecord',
      fields: [
        {
          key: 'RecordType',
          value: userRecord.recordType,
        },
        {
          key: 'Verification Status',
          value: userRecord.verificationStatus || 'N/A',
        },
        {
          key: 'TimeStamp',
          value: moment(userRecord.timeStamp).format('LLL') || '',
        },
        {
          key: 'Record Id',
          value: userRecord.recordId || 'N/A',
        },
      ],
      key: userRecord.id,
    };
  });

  return tableData;
}
