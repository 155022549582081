import { gql } from '../__generated__/gql';

const UPDATE_INFO_ITEM = gql(`
  mutation UpdateInfoItemAndUploadImageFile(
    $data: CustomInfoItemUpdateInput!
    $where: InfoItemWhereUniqueInput!
  ) {
    updateInfoItemAndUploadImageFile(data: $data, where: $where) {
      id
      title
    }
  }
`);

export default UPDATE_INFO_ITEM;
