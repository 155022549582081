/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3ThemeGeneralSchema: NodeSchemaProperties = {
  general: {
    title: '#1 General',
    type: 'object',
    properties: {
      palette: {
        title: '#1 palette',
        description: 'Colors palette',
        type: 'object',
        properties: {
          primary: {
            title: '#1.1 Primary',
            type: 'object',
            properties: {
              'light:enable': {
                title: '#1.1.1 Light color',
                type: 'boolean',
                description: '[general.palette.primary.light] - RGBA / RGB / Hex / Color name',
              },
              light: {
                type: 'string',
              },
              'main:enable': {
                title: '#1.1.2 Main color',
                type: 'boolean',
                description: '[general.palette.primary.main] - RGBA / RGB / Hex / Color name',
              },
              main: {
                type: 'string',
              },
              'dark:enable': {
                title: '#1.1.3 Dark color',
                type: 'boolean',
                description: '[general.palette.primary.dark] - RGBA / RGB / Hex / Color name',
              },
              dark: {
                type: 'string',
              },
              'contrastText:enable': {
                title: '#1.1.4 Contrast text',
                type: 'boolean',
                description:
                  '[general.palette.primary.contrastText] - RGBA / RGB / Hex / Color name',
              },
              contrastText: {
                type: 'string',
              },
            },
          },
          secondary: {
            title: '#1.2 Secondary',
            type: 'object',
            properties: {
              'light:enable': {
                title: '#1.2.1 Light color',
                type: 'boolean',
                description: '[general.palette.secondary.light] - RGBA / RGB / Hex / Color name',
              },
              light: {
                type: 'string',
              },
              'main:enable': {
                title: '#1.2.2 Main color',
                type: 'boolean',
                description: '[general.palette.secondary.main] - RGBA / RGB / Hex / Color name',
              },
              main: {
                type: 'string',
              },
              'dark:enable': {
                title: '#1.2.3 Dark color',
                type: 'boolean',
                description: '[general.palette.secondary.dark] - RGBA / RGB / Hex / Color name',
              },
              dark: {
                type: 'string',
              },
              'contrastText:enable': {
                title: '#1.2.4 Contrast text',
                type: 'boolean',
                description:
                  '[general.palette.secondary.contrastText] - RGBA / RGB / Hex / Color name',
              },
              contrastText: {
                type: 'string',
              },
            },
          },
          text: {
            title: '#1.3 Text',
            type: 'object',
            properties: {
              'light:enable': {
                title: '#1.3.1 Light color',
                type: 'boolean',
                description: '[general.palette.text.light] - RGBA / RGB / Hex / Color name',
              },
              light: {
                type: 'string',
              },
              'main:enable': {
                title: '#1.3.2 Main color',
                type: 'boolean',
                description: '[general.palette.text.main] - RGBA / RGB / Hex / Color name',
              },
              main: {
                type: 'string',
              },
              'dark:enable': {
                title: '#1.3.3 Dark color',
                type: 'boolean',
                description: '[general.palette.text.dark] - RGBA / RGB / Hex / Color name',
              },
              dark: {
                type: 'string',
              },
            },
          },
          'danger:enable': {
            title: '#1.4 Danger',
            type: 'boolean',
            description: '[general.palette.danger] - RGBA / RGB / Hex / Color name',
          },
          danger: {
            type: 'string',
          },
          'success:enable': {
            title: '#1.5 Success',
            type: 'boolean',
            description: '[general.palette.success] - RGBA / RGB / Hex / Color name',
          },
          success: {
            type: 'string',
          },
          'warning:enable': {
            title: '#1.6 Warning',
            type: 'boolean',
            description: '[general.palette.warning] - RGBA / RGB / Hex / Color name',
          },
          warning: {
            type: 'string',
          },
          'info:enable': {
            title: '#1.7 Info',
            type: 'boolean',
            description: '[general.palette.info] - RGBA / RGB / Hex / Color name',
          },
          info: {
            type: 'string',
          },
          'contrastText:enable': {
            title: '#1.8 Contrast text',
            type: 'boolean',
            description: '[general.palette.contrastText] - RGBA / RGB / Hex / Color name',
          },
          contrastText: {
            type: 'string',
          },

          gray: {
            title: '#1.9 Gray',
            type: 'object',
            properties: {
              'light:enable': {
                title: '#1.9.1 Light',
                type: 'boolean',
                description: '[general.palette.gray.light] - RGBA / RGB / Hex / Color name',
              },
              light: {
                type: 'string',
              },
              'main:enable': {
                title: '#1.9.2 Main',
                type: 'boolean',
                description: '[general.palette.gray.main] - RGBA / RGB / Hex / Color name',
              },
              main: {
                type: 'string',
              },
              'dark:enable': {
                title: '#1.9.3 Dark',
                type: 'boolean',
                description: '[general.palette.gray.dark] - RGBA / RGB / Hex / Color name',
              },
              dark: {
                type: 'string',
              },
              'scale100:enable': {
                title: '#1.9.4 Scale 100',
                type: 'boolean',
                description: '[general.palette.gray.scale100] - RGBA / RGB / Hex / Color name',
              },
              scale100: {
                type: 'string',
              },
              'scale200:enable': {
                title: '#1.9.5 Scale 200',
                type: 'boolean',
                description: '[general.palette.gray.scale200] - RGBA / RGB / Hex / Color name',
              },
              scale200: {
                type: 'string',
              },
              'scale300:enable': {
                title: '#1.9.6 Scale 300',
                type: 'boolean',
                description: '[general.palette.gray.scale300] - RGBA / RGB / Hex / Color name',
              },
              scale300: {
                type: 'string',
              },
              'scale400:enable': {
                title: '#1.9.7 Scale 400',
                type: 'boolean',
                description: '[general.palette.gray.scale400] - RGBA / RGB / Hex / Color name',
              },
              scale400: {
                type: 'string',
              },
              'scale500:enable': {
                title: '#1.9.8 Scale 500',
                type: 'boolean',
                description: '[general.palette.gray.scale500] - RGBA / RGB / Hex / Color name',
              },
              scale500: {
                type: 'string',
              },
              'scale600:enable': {
                title: '#1.9.9 Scale 600',
                type: 'boolean',
                description: '[general.palette.gray.scale600] - RGBA / RGB / Hex / Color name',
              },
              scale600: {
                type: 'string',
              },
              'scale700:enable': {
                title: '#1.9.10 Scale 700',
                type: 'boolean',
                description: '[general.palette.gray.scale700] - RGBA / RGB / Hex / Color name',
              },
              scale700: {
                type: 'string',
              },
              'scale800:enable': {
                title: '#1.9.11 Scale 800',
                type: 'boolean',
                description: '[general.palette.gray.scale800] - RGBA / RGB / Hex / Color name',
              },
              scale800: {
                type: 'string',
              },
              'scale900:enable': {
                title: '#1.9.12 Scale 900',
                type: 'boolean',
                description: '[general.palette.gray.scale900] - RGBA / RGB / Hex / Color name',
              },
              scale900: {
                type: 'string',
              },
            },
          },
          red: {
            title: '#1.10 Red',
            type: 'object',
            properties: {
              'light:enable': {
                title: '#1.10.1 Light',
                type: 'boolean',
                description: '[general.palette.red.light] - RGBA / RGB / Hex / Color name',
              },
              light: {
                type: 'string',
              },
              'main:enable': {
                title: '#1.10.2 Main',
                type: 'boolean',
                description: '[general.palette.red.main] - RGBA / RGB / Hex / Color name',
              },
              main: {
                type: 'string',
              },
              'dark:enable': {
                title: '#1.10.3 Dark',
                type: 'boolean',
                description: '[general.palette.red.dark] - RGBA / RGB / Hex / Color name',
              },
              dark: {
                type: 'string',
              },
            },
          },
          green: {
            title: '#1.11 Green',
            type: 'object',
            properties: {
              'light:enable': {
                title: '#1.11.1 Light',
                type: 'boolean',
                description: '[general.palette.green.light] - RGBA / RGB / Hex / Color name',
              },
              light: {
                type: 'string',
              },
              'main:enable': {
                title: '#1.11.2 Main',
                type: 'boolean',
                description: '[general.palette.green.main] - RGBA / RGB / Hex / Color name',
              },
              main: {
                type: 'string',
              },
              'dark:enable': {
                title: '#1.11.3 Dark',
                type: 'boolean',
                description: '[general.palette.green.dark] - RGBA / RGB / Hex / Color name',
              },
              dark: {
                type: 'string',
              },
            },
          },
          'white:enable': {
            title: '#1.12 White',
            type: 'boolean',
            description: '[general.palette.white] - RGBA / RGB / Hex / Color name',
          },
          white: {
            type: 'string',
          },
          'background:enable': {
            title: '#1.13 Background',
            type: 'boolean',
            description: '[general.palette.background] - RGBA / RGB / Hex / Color name',
          },
          background: {
            type: 'string',
          },
          'darkBackground:enable': {
            title: '#1.14 Dark background',
            type: 'boolean',
            description: '[general.palette.darkBackground] - RGBA / RGB / Hex / Color name',
          },
          darkBackground: {
            type: 'string',
          },
        },
      },
      'spacingUnit:enable': {
        title: '#2 Spacing unit',
        type: 'boolean',
        description: 'Theme spacing unit [general.spacingUnit]',
      },
      spacingUnit: {
        type: 'number',
      },
      shadows: {
        title: '#3 Shadows',
        type: 'object',
        properties: {
          'shadowColor:enable': {
            description: '[general.shadows.shadowColor]',
            title: '#3.1 Shadow Color',
            type: 'boolean',
          },
          shadowColor: {
            type: 'string',
          },
          shadowOffset: {
            title: '#3.2 Shadow Offset',
            type: 'object',
            properties: {
              'width:enable': {
                description: '[general.shadows.shadowOffset.width]',
                title: '#3.2.1 Width',
                type: 'boolean',
              },
              width: {
                type: 'number',
              },
              'height:enable': {
                description: '[general.shadows.shadowOffset.height]',
                title: '#3.2.2 Height',
                type: 'boolean',
              },
              height: {
                type: 'number',
              },
            },
          },
          'shadowOpacity:enable': {
            description: '[general.shadows.shadowOpacity]',
            title: '#3.3 Shadow Opacity',
            type: 'boolean',
          },
          shadowOpacity: {
            type: 'number',
          },
          'shadowRadius:enable': {
            description: '[general.shadows.shadowRadius]',
            title: '#3.4 Shadow Radius',
            type: 'boolean',
          },
          shadowRadius: {
            type: 'number',
          },
          'elevation:enable': {
            description: '[general.shadows.elevation]',
            title: '#3.5 Elevation',
            type: 'boolean',
          },
          elevation: {
            type: 'number',
          },
        },
      },
      shapes: {
        title: '#4 Shapes',
        description: 'Theme shapes, like border radius',
        type: 'object',
        properties: {
          'borderRadius:enable': {
            title: '#4.1 Border radius',
            type: 'boolean',
            description: '[general.shapes.borderRadius]',
          },
          borderRadius: {
            type: 'number',
          },
        },
      },
      typography: {
        title: '#5 Typography',
        description: 'Theme typography, font sizes',
        type: 'object',
        properties: {
          fontSize: {
            title: '#5.1 Font size',
            type: 'object',
            properties: {
              'xs:enable': {
                title: '#5.1.1 xs',
                type: 'boolean',
                description: '[general.typography.fontSize.xs]',
              },
              xs: { type: 'number' },
              'sm:enable': {
                title: '#5.1.2 sm',
                type: 'boolean',
                description: '[general.typography.fontSize.sm]',
              },
              sm: { type: 'number' },
              'md:enable': {
                title: '#5.1.3 md',
                type: 'boolean',
                description: '[general.typography.fontSize.md]',
              },
              md: { type: 'number' },
              'lg:enable': {
                title: '#5.1.4 lg',
                type: 'boolean',
                description: '[general.typography.fontSize.lg]',
              },
              lg: { type: 'number' },
              'xl:enable': {
                title: '#5.1.5 xl',
                type: 'boolean',
                description: '[general.typography.fontSize.xl]',
              },
              xl: { type: 'number' },
              'xxl:enable': {
                title: '#5.1.6 xxl',
                type: 'boolean',
                description: '[general.typography.fontSize.xxl]',
              },
              xxl: { type: 'number' },
            },
          },
          fontWeight: {
            title: '#5.2 Font weight',
            type: 'object',
            properties: {
              'light:enable': {
                title: '#5.2.1 Light',
                type: 'boolean',
                description: '[general.typography.fontWeight.light]',
              },
              light: {
                type: 'string',
                enum: [
                  'normal',
                  'bold',
                  '100',
                  '200',
                  '300',
                  '400',
                  '500',
                  '600',
                  '700',
                  '800',
                  '900',
                ],
              },
              'regular:enable': {
                title: '#5.2.2 Regular',
                type: 'boolean',
                description: '[general.typography.fontWeight.regular]',
              },
              regular: {
                type: 'string',
                enum: [
                  'normal',
                  'bold',
                  '100',
                  '200',
                  '300',
                  '400',
                  '500',
                  '600',
                  '700',
                  '800',
                  '900',
                ],
              },
              'medium:enable': {
                title: '#5.2.3 Medium',
                type: 'boolean',
                description: '[general.typography.fontWeight.medium]',
              },
              medium: {
                type: 'string',
                enum: [
                  'normal',
                  'bold',
                  '100',
                  '200',
                  '300',
                  '400',
                  '500',
                  '600',
                  '700',
                  '800',
                  '900',
                ],
              },
              'bold:enable': {
                title: '#5.2.4 Bold',
                type: 'boolean',
                description: '[general.typography.fontWeight.bold]',
              },
              bold: {
                type: 'string',
                enum: [
                  'normal',
                  'bold',
                  '100',
                  '200',
                  '300',
                  '400',
                  '500',
                  '600',
                  '700',
                  '800',
                  '900',
                ],
              },
            },
          },
        },
      },
      mixins: {
        title: '#6 Mixins',
        type: 'object',
        properties: {
          button: {
            title: '#6.1 Button',
            type: 'object',
            properties: {
              'minHeight:enable': {
                title: '#6.1.1 Min height',
                type: 'boolean',
                description: '[general.mixins.button.minHeight]',
              },
              minHeight: {
                type: 'number',
              },
            },
          },
        },
      },
      'backIconUrl:enable': {
        description: '[general.backIconUrl]',
        title: '#7 Back Icon Url',
        type: 'boolean',
      },
      backIconUrl: {
        type: 'string',
      },
      'closeIconUrl:enable': {
        description: '[general.closeIconUrl]',
        title: '#8 Close Icon Url',
        type: 'boolean',
      },
      closeIconUrl: {
        type: 'string',
      },
      button: {
        title: '#9 Button',
        type: 'object',
        properties: {
          disabled: {
            title: '#9.1 Disabled',
            type: 'object',
            properties: {
              'backgroundColor:enable': {
                description: '[general.disabled.backgroundColor]',
                title: '#9.1.1 Background Color',
                type: 'boolean',
              },
              backgroundColor: {
                type: 'string',
              },
              'textColor:enable': {
                description: '[general.disabled.textColor]',
                title: '#9.1.2 Text Color',
                type: 'boolean',
              },
              textColor: {
                type: 'string',
              },
            },
          },
        },
      },
      'disabledCheckInTextColor:enable': {
        description: '[general.disabledCheckInTextColor]',
        title: '#10 Disabled Check In Text Color',
        type: 'boolean',
      },
      disabledCheckInTextColor: {
        type: 'string',
      },
      'disableCheckInButton:enable': {
        description: '[general.disableCheckInButton]',
        title: '#11 Disable Check In Button',
        type: 'boolean',
      },
      disableCheckInButton: {
        type: undefined,
        enum: [true, false],
      },
      'headerLogoImageUrl:enable': {
        description: '[general.headerLogoImageUrl]',
        title: '#12 Header Logo Image Url',
        type: 'boolean',
      },
      headerLogoImageUrl: {
        type: 'string',
      },
      'headerLogoHeight:enable': {
        description: '[general.headerLogoHeight]',
        title: '#13 Header Logo Height',
        type: 'boolean',
      },
      headerLogoHeight: {
        type: 'number',
      },
      'headerLogoWidth:enable': {
        description: '[general.headerLogoWidth]',
        title: '#14 Header Logo Width',
        type: 'boolean',
      },
      headerLogoWidth: {
        type: 'number',
      },
      'headerTextColor:enable': {
        description: '[general.headerTextColor]',
        title: '#15 Header Text Color',
        type: 'boolean',
      },
      headerTextColor: {
        type: 'string',
      },
      'passwordProtectionMode:enable': {
        title: '#16 Key access protection mode configuration',
        type: 'boolean',
        description: '[general.passwordProtectionMode] - Once / Never / Always',
      },
      passwordProtectionMode: {
        enum: ['once', 'never', 'always'],
      },
      bottomMenu: {
        title: '#17 Bottom menu configuration',
        type: 'object',
        properties: {
          'order:enable': {
            description: '[general.bottomMenu.order]',
            title: '#17.1 Order',
            type: 'boolean',
          },
          order: {
            type: 'array',
            items: {
              type: 'string',
              enum: ['access', 'services', 'trips', 'assistance'],
            },
          },
          config: {
            title: '#17.2 Bottom menu configuration',
            type: 'object',
            properties: {
              home: {
                title: '#17.2.1 Home tab configuration',
                type: 'object',
                properties: {
                  'icon:enable': {
                    title: '#17.2.1.1 Icon name',
                    description: '[general.bottomMenu.config.home.icon]',
                    type: 'boolean',
                  },
                  icon: {
                    type: 'string',
                  },
                  'image:enable': {
                    title: '#17.2.1.2 Image icon URL',
                    description: '[general.bottomMenu.config.home.image]',
                    type: 'boolean',
                  },
                  image: {
                    type: 'string',
                  },
                  'selectedImage:enable': {
                    title: '#17.2.1.3 Selected Image icon URL',
                    description: '[general.bottomMenu.config.home.selectedImage]',
                    type: 'boolean',
                  },
                  selectedImage: {
                    type: 'string',
                  },
                },
              },
              access: {
                title: '#17.2.2 Access tab configuration',
                type: 'object',
                properties: {
                  'icon:enable': {
                    title: '#17.2.3.1 Icon name',
                    description: '[general.bottomMenu.config.access.icon]',
                    type: 'boolean',
                  },
                  icon: {
                    type: 'string',
                  },
                  'image:enable': {
                    title: '#17.2.3.2 Image icon URL',
                    description: '[general.bottomMenu.config.access.image]',
                    type: 'boolean',
                  },
                  image: {
                    type: 'string',
                  },
                  'selectedImage:enable': {
                    title: '#17.2.3.3 Selected Image icon URL',
                    description: '[general.bottomMenu.config.access.selectedImage]',
                    type: 'boolean',
                  },
                  selectedImage: {
                    type: 'string',
                  },
                },
              },
              trips: {
                title: '#17.2.3 Trips tab configuration',
                type: 'object',
                properties: {
                  'icon:enable': {
                    title: '#17.2.2.1 Icon name',
                    description: '[general.bottomMenu.config.trips.icon]',
                    type: 'boolean',
                  },
                  icon: {
                    type: 'string',
                  },
                  'image:enable': {
                    title: '#17.2.2.2 Image icon URL',
                    description: '[general.bottomMenu.config.trips.image]',
                    type: 'boolean',
                  },
                  image: {
                    type: 'string',
                  },
                  'selectedImage:enable': {
                    title: '#17.2.2.3 Selected Image icon URL',
                    description: '[general.bottomMenu.config.trips.selectedImage]',
                    type: 'boolean',
                  },
                  selectedImage: {
                    type: 'string',
                  },
                },
              },
              services: {
                title: '#17.2.4 Trips tab configuration',
                type: 'object',
                properties: {
                  'icon:enable': {
                    title: '#17.2.4.1 Icon name',
                    description: '[general.bottomMenu.config.services.icon]',
                    type: 'boolean',
                  },
                  icon: {
                    type: 'string',
                  },
                  'image:enable': {
                    title: '#17.2.4.2 Image icon URL',
                    description: '[general.bottomMenu.config.services.image]',
                    type: 'boolean',
                  },
                  image: {
                    type: 'string',
                  },
                  'selectedImage:enable': {
                    title: '#17.2.4.3 Selected Image icon URL',
                    description: '[general.bottomMenu.config.services.selectedImage]',
                    type: 'boolean',
                  },
                  selectedImage: {
                    type: 'string',
                  },
                },
              },
              assistance: {
                title: '#17.2.5 Remote assistance tab configuration',
                type: 'object',
                properties: {
                  'icon:enable': {
                    title: '#17.2.5.1 Icon name',
                    description: '[general.bottomMenu.config.assistance.icon]',
                    type: 'boolean',
                  },
                  icon: {
                    type: 'string',
                  },
                  'image:enable': {
                    title: '#17.2.5.2 Image icon URL',
                    description: '[general.bottomMenu.config.assistance.image]',
                    type: 'boolean',
                  },
                  image: {
                    type: 'string',
                  },
                  'selectedImage:enable': {
                    title: '#17.2.5.3 Selected Image icon URL',
                    description: '[general.bottomMenu.config.assistance.selectedImage]',
                    type: 'boolean',
                  },
                  selectedImage: {
                    type: 'string',
                  },
                },
              },
            },
          },
        },
      },
      'defaultCountryCode:enable': {
        title: '#18 Default country code',
        type: 'boolean',
        description:
          '[general.defaultCountryCode] - Default country code, if not set, the app will use US as default',
      },
      defaultCountryCode: {
        type: 'string',
      },
    },
  },
};

export const mobileV3ThemeGeneralUiSchema: NodeUiSchemaProperties = {
  general: {
    palette: {
      primary: {
        light: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        main: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        dark: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        contrastText: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
      secondary: {
        light: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        main: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        dark: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        contrastText: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
      text: {
        light: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        main: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        dark: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
      danger: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      success: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      warning: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      info: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      contrastText: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      gray: {
        light: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        main: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        dark: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        scale100: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        scale200: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        scale300: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        scale400: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        scale500: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        scale600: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        scale700: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        scale800: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        scale900: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
      red: {
        light: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        main: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        dark: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
      green: {
        light: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        main: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        dark: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
      white: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      background: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      darkBackground: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    spacingUnit: {
      'ui:label': false,
      'ui:readonly': true,
    },
    shadows: {
      shadowColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      shadowOffset: {
        width: {
          'ui:label': false,
          'ui:readonly': true,
        },
        height: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      shadowOpacity: {
        'ui:label': false,
        'ui:readonly': true,
      },
      shadowRadius: {
        'ui:label': false,
        'ui:readonly': true,
      },
      elevation: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    shapes: {
      borderRadius: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    typography: {
      fontSize: {
        xs: { 'ui:label': false, 'ui:readonly': true },
        sm: { 'ui:label': false, 'ui:readonly': true },
        md: { 'ui:label': false, 'ui:readonly': true },
        lg: { 'ui:label': false, 'ui:readonly': true },
        xl: { 'ui:label': false, 'ui:readonly': true },
        xxl: { 'ui:label': false, 'ui:readonly': true },
      },
      fontWeight: {
        light: { 'ui:label': false, 'ui:readonly': true },
        regular: { 'ui:label': false, 'ui:readonly': true },
        medium: { 'ui:label': false, 'ui:readonly': true },
        bold: { 'ui:label': false, 'ui:readonly': true },
      },
    },
    mixins: {
      button: {
        minHeight: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
    },
    backIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    closeIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    button: {
      disabled: {
        backgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
    },
    disabledCheckInTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    disableCheckInButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    headerLogoImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    headerLogoHeight: {
      'ui:label': false,
      'ui:readonly': true,
    },
    headerLogoWidth: {
      'ui:label': false,
      'ui:readonly': true,
    },
    headerTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    passwordProtectionMode: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    bottomMenu: {
      order: {
        'ui:label': false,
        'ui:readonly': true,
        items: {
          'ui:label': false,
        },
      },
      config: {
        home: {
          icon: {
            'ui:label': false,
            'ui:readonly': true,
          },
          image: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'link',
            'ui:widget': 'uri',
          },
          selectedImage: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'link',
            'ui:widget': 'uri',
          },
        },
        access: {
          icon: {
            'ui:label': false,
            'ui:readonly': true,
          },
          image: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'link',
            'ui:widget': 'uri',
          },
          selectedImage: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'link',
            'ui:widget': 'uri',
          },
        },
        trips: {
          icon: {
            'ui:label': false,
            'ui:readonly': true,
          },
          image: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'link',
            'ui:widget': 'uri',
          },
          selectedImage: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'link',
            'ui:widget': 'uri',
          },
        },
        services: {
          icon: {
            'ui:label': false,
            'ui:readonly': true,
          },
          image: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'link',
            'ui:widget': 'uri',
          },
          selectedImage: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'link',
            'ui:widget': 'uri',
          },
        },
        assistance: {
          icon: {
            'ui:label': false,
            'ui:readonly': true,
          },
          image: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'link',
            'ui:widget': 'uri',
          },
          selectedImage: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'link',
            'ui:widget': 'uri',
          },
        },
      },
    },
    defaultCountryCode: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
};
