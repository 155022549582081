import React from 'react';
import Theme from '../../../theme';
import styled from 'styled-components';

interface CheckmarkProps {
  fill?: string;
  stroke?: string;
}

export const CheckmarkSVG = styled.svg`
  fill: none;
  stroke-width: 1px;
`;

const Checkmark: React.FunctionComponent<CheckmarkProps> = ({
  stroke = Theme.colors.white.hexCode,
}: CheckmarkProps) => {
  return (
    <CheckmarkSVG
      stroke={stroke}
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
    >
      <title>checkbox/element/uncheck copy</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Dashboard_-1-Copy-24" transform="translate(-153.000000, -294.000000)">
          <g id="🍱Large-Comp/6-products" transform="translate(114.000000, 161.200000)">
            <g id="checkbox/element/uncheck-copy" transform="translate(39.127962, 133.000000)">
              <g id="f-check" transform="translate(5.000000, 5.000000)" fill="#FFFFFF">
                <polygon
                  id="Path"
                  points="3.5 5.375 1.5 3.375 0 4.875 3.5 8.375 10 1.875 8.5 0.375"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </CheckmarkSVG>
  );
};

export default Checkmark;
