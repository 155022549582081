import { Query, QueryUsersArgs, UserWhereInput } from '../../__generated__/graphql';
import { useCallback, useEffect, useRef, useState } from 'react';

import { EntityEnum } from '../../@types/client/DashboardUserRolePermissions';
import GET_USERS_TABLE_DATA from '../../graphql/getUserTableData';
import GET_USER_COUNT from '../../graphql/aggregateUser';
import OrderByParams from '../../@types/client/OrderByParams';
import { RootState } from '../../redux/reducers';
import SearchField from '../../@types/SearchField';
import TableHookData from '../@types/TableHookData';
import TableHookProps from '../@types/TableHookProps';
import USER_SEARCH_FIELDS from './constants/UsersTableSearchFields';
import USER_TABLE_HEADERS from './constants/UsersTableHeaders';
import generateNestedMembershipQuery from '../../util/api/generateNestedMembershipQuery';
import generateUsersTableData from './functions/generateUsersTableData';
import parseOrderByDataTableParams from '../../components/DataTable/util/parseOrderByDataTableParams';
import parseUserQueryParameters from './util/parseUserQueryParameters';
import useDateTimeFormatter from '../useDateTimeFormatter';
import useGetMany from '../useGetMany';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import useUserFilters from './hooks/useUserFilters';

type UsersQueryParams = {
  orderBy?: OrderByParams;
  where: Partial<UserWhereInput>;
};

type GetUsersTableData = Pick<Query, 'users'>;

export default function useUsersTableData({
  dataTableKey = 'users',
  formName = 'user',
  headers = USER_TABLE_HEADERS,
  isSearchable = true,
  isImportEnabled = true,
  queryOptions,
  searchFields = USER_SEARCH_FIELDS,
}: TableHookProps<QueryUsersArgs, UserWhereInput>): TableHookData<UserWhereInput> {
  const location = useLocation();
  const membership = useSelector((state: RootState) => state.me.activeMembership);
  const options = useRef<Partial<QueryUsersArgs>>(queryOptions ?? {});
  const fieldsFiltered = useSelector((state: RootState) => state.dataTable.filters);
  const sortOptions = useSelector((state: RootState) => state.dataTable.sortOptions);
  const filters = useUserFilters();
  const dateTimeFormatter = useDateTimeFormatter();

  const [searchParameters, setSearchParameters] = useState<UserWhereInput>({});

  const { data, error, loading, offset, query, setOffset } = useGetMany<
    GetUsersTableData,
    QueryUsersArgs
  >({
    graphqlQuery: GET_USERS_TABLE_DATA,
  });

  const {
    data: countData,
    error: countError,
    loading: countLoading,
    query: countQuery,
  } = useGetMany<Pick<Query, 'aggregateUser'>, QueryUsersArgs>({
    graphqlQuery: GET_USER_COUNT,
  });

  const createParams = useCallback((): UsersQueryParams => {
    const whereArgs = parseUserQueryParameters(fieldsFiltered[dataTableKey], membership);
    const sortParams = parseOrderByDataTableParams(sortOptions[dataTableKey]);

    return {
      ...(sortParams && { orderBy: sortParams }),
      where: {
        ...generateNestedMembershipQuery({
          ...membership,
          location: undefined,
        }),
        ...options.current?.where,
        ...searchParameters,
        ...whereArgs,
      },
    };
  }, [
    membership,
    location.search,
    fieldsFiltered,
    sortOptions,
    dataTableKey,
    options,
    searchParameters,
  ]);

  const get = useCallback(() => {
    const params = createParams();

    query({
      ...options.current,
      ...(params.orderBy && { orderBy: params.orderBy }),
      skip: options.current?.skip ?? offset,
      take: options.current?.take ?? 10,
      where: params.where,
    });
  }, [createParams, offset, query]);

  const getCount = useCallback(() => {
    const params = createParams();

    countQuery({
      where: params.where,
    });
  }, [countQuery, createParams]);

  useEffect(() => {
    get();
  }, [get]);

  useEffect(() => {
    getCount();
  }, [getCount]);

  return {
    count: countData?.aggregateUser._count?._all ?? 0,
    data: generateUsersTableData(membership, dateTimeFormatter, data),
    dataTableKey,
    dataType: 'User',
    error: error || countError,
    filters,
    formName,
    headers,
    isImportEnabled,
    isLoading: loading || countLoading,
    isSearchable,
    offset,
    permission: EntityEnum.USER,
    search: (searchField: SearchField<UserWhereInput>, searchValue: string): void => {
      setSearchParameters({
        [searchField.queryField]: searchField.transform(searchValue),
      });

      setOffset(0);
    },
    searchFields,
    setOffset,
  };
}
