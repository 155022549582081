import Modal from 'react-modal';

const ModalInformationStyle: Modal.Styles = {
  content: {
    alignSelf: 'center',
    border: '0',
    borderRadius: '24px',
    bottom: 'unset',
    display: 'grid',
    gridAutoRows: '1fr',
    justifySelf: 'center',
    margin: '0 auto',
    minHeight: '480px',
    outline: '0',
    top: '10%',
    width: '600px',
    zIndex: 1,
  },
  overlay: {
    background: 'rgba(255, 255, 255, 0.1)',
    border: '0',
    outline: '0',
    zIndex: 10,
  },
};

export default ModalInformationStyle;
