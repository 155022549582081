import * as yup from 'yup';

const GRACE_PERIOD_OVERRIDE_VALUES_VALIDATION_SCHEMA = yup.object().shape({
  absoluteTime: yup
    .string()
    .min(5)
    .matches(/^(1[0-2]|0?[1-9]):[0-5][0-9]$/, '12h time is invalid'),
  absoluteTimePeriod: yup.string(),
  gracePeriodType: yup.string().required(),
  isActive: yup.boolean().required(),
  relativeTimeInMinutes: yup.number(),
});

export const GRACE_PERIODS_VALIDATION_SCHEMA = yup
  .object()
  .shape({
    checkIn: GRACE_PERIOD_OVERRIDE_VALUES_VALIDATION_SCHEMA.required(),
    checkOut: GRACE_PERIOD_OVERRIDE_VALUES_VALIDATION_SCHEMA.required(),
  })
  .default({});
