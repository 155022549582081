import Form from '../../Form';
import React from 'react';
import { ResourceVendor } from '../../../__generated__/graphql';
import { RootState } from '../../../redux/reducers';
import SimpleFormSection from '../../../templates/SimpleFormSection';
import SubmitRow from '../../SubmitRow';
import generateVendorSectionInputs from '../constants/vendorServerSectionInputs';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useSubmitVendorServer from '../hooks/useSubmitVendorServer';

const VendorServerSection: React.FC<{}> = () => {
  const id = useSelector((state: RootState) => state.modals.form.id);
  const { handleSubmit, watch } = useFormContext();
  const vendor: ResourceVendor = watch('vendor');

  const { loadingMutation, submitHandler } = useSubmitVendorServer();

  const VENDOR_SECTION_INPUTS = generateVendorSectionInputs({ vendor });

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <SimpleFormSection inputs={VENDOR_SECTION_INPUTS} />
      <SubmitRow
        loading={loadingMutation}
        submitText={id ? 'Update Vendor Server' : 'Create Vendor Server'}
      />
    </Form>
  );
};

export default VendorServerSection;
