import { ConciergeItem, ConciergeTreeViewNode, Query } from '../../../../__generated__/graphql';
import { useCallback, useEffect, useState } from 'react';

import { GET_CONCIERGE_TREE_VIEW } from '../../../../graphql/getInfoItemsTableData';
import { NodeModel } from '@minoru/react-dnd-treeview';
import _omit from 'lodash/omit';
import { setConciergeTree } from '../../../../redux/actions/concierge.actions';
import { useDispatch } from 'react-redux';
import useGetMany from '../../../../hooks/useGetMany';
import { useParams } from 'react-router-dom';

type TTreeViewNodeData = Pick<Query, 'getConciergeTreeView'>;

const useConciergeInfo = () => {
  const [treeData, setTreeData] = useState<Array<NodeModel<ConciergeItem>>>([]);
  const { locationId } = useParams<{ locationId: string }>();
  const dispatch = useDispatch();
  const { data: treeViewData, query: treeViewDataQuery } = useGetMany<
    ConciergeTreeViewNode,
    {
      locationId: string;
    }
  >({
    graphqlQuery: GET_CONCIERGE_TREE_VIEW,
    onCompleted: () => {
      const { getConciergeTreeView: conciergeTreeData } = treeViewData as TTreeViewNodeData;
      const newTree = (conciergeTreeData?.map((it) => {
        return _omit(it as ConciergeTreeViewNode, ['__typename']);
      }) as unknown) as Array<NodeModel<ConciergeItem>>;
      setTreeData(newTree);
      dispatch(setConciergeTree(newTree));
      return {};
    },
  });

  const query = useCallback(() => {
    treeViewDataQuery({ locationId });
  }, [treeViewDataQuery, locationId]);

  useEffect(() => {
    treeViewDataQuery({ locationId });
  }, []);

  return { treeData, setTreeData, query };
};

export default useConciergeInfo;
