import {
  CustomAccessGrantArgs,
  CustomGetAccessGrantPageInfo,
} from '../../../pages/AccessGrant/@types/GetAccessGrantPageInfo';
import { useApolloClient, useMutation } from '@apollo/client';
import { ActionResult } from '../@types/QuickActionBarTypes';
import { GET_ACCESS_GRANT_PAGE_INFO_AND_CHECK_NOTIFICATION_CAPABILITY } from '../../../pages/AccessGrant/graphql/getAccessGrantPageInfo';
import POLL_RESERVATION_FROM_PMS from '../../../graphql/pollReservationFromPMS';
import PollReservationInputs from '../../AccessGrantPollReservationForm/@types/PollReservationInputs';
import PollReservationTypesData from '../../AccessGrantPollReservationForm/@types/PollReservationTypesData';
import { channelsClient } from '../../../util/pusher';
import pollReservationTimeout from '../../../hooks/usePollReservations/constants/pollReservationTimeout';
import { setAccessGrantPageInfo } from '../../../redux/actions/accessGrant.actions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

const { REACT_APP_VIRDEE_ENV = 'local' } = process.env;

type UseRepollReservationResponse = {
  repollReservation: () => Promise<ActionResult>;
};

type UseRepollReservationArgs = PollReservationInputs & {
  accessGrantId: string;
};

export const useRepollReservation = (
  args: UseRepollReservationArgs
): UseRepollReservationResponse => {
  const [pollReservation] = useMutation<PollReservationTypesData, PollReservationInputs>(
    POLL_RESERVATION_FROM_PMS
  );
  const client = useApolloClient();
  const dispatch = useDispatch();

  const getPollingResponse = useCallback((): Promise<ActionResult> => {
    const { confirmationCode, tenantId, locationId } = args;
    const POLL_RESERVATION_EVENT_NAME = 'POLL_RESERVATION';
    const channelName = `ACCESS_GRANT-${REACT_APP_VIRDEE_ENV}-${tenantId}-${locationId}-${confirmationCode}-${POLL_RESERVATION_EVENT_NAME}`;
    const accessGrantChannel = channelsClient.subscribe(channelName);

    return Promise.race<ActionResult>([
      new Promise<ActionResult>((resolve) => {
        if (confirmationCode) {
          accessGrantChannel.bind(POLL_RESERVATION_EVENT_NAME, (data: { success: boolean }) => {
            accessGrantChannel.unbind(POLL_RESERVATION_EVENT_NAME);

            const { success } = data;
            resolve({
              success: data.success,
              errorMessage: !success ? 'Error polling reservation' : undefined,
            });
          });
        }
      }),
      new Promise<ActionResult>((resolve) => {
        setTimeout(() => {
          const result: ActionResult = {
            success: false,
            errorMessage: 'Polling operation timed out',
          };

          resolve(result);
        }, pollReservationTimeout);
      }),
    ]);
  }, []);

  const updateAccessGrantPageInfo = useCallback(async () => {
    try {
      const { data } = await client.query<CustomGetAccessGrantPageInfo, CustomAccessGrantArgs>({
        query: GET_ACCESS_GRANT_PAGE_INFO_AND_CHECK_NOTIFICATION_CAPABILITY,
        variables: {
          accessGrantId: args.accessGrantId,
          where: { id: { equals: args.accessGrantId } },
        },
        fetchPolicy: 'network-only',
      });

      const updatedAccessGrant = data.findDashboardAccessGrant;

      if (updatedAccessGrant) {
        dispatch(setAccessGrantPageInfo(updatedAccessGrant));
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }, []);

  const repollReservation = useCallback(async (): Promise<ActionResult> => {
    try {
      const response = await pollReservation({
        variables: {
          confirmationCode: args.confirmationCode,
          locationId: args.locationId,
          tenantId: args.tenantId,
        },
      });

      if (!response.data?.pollReservationFromPms) {
        return {
          success: false,
          errorMessage: 'Failed to poll reservation. Please try again',
        };
      }

      const pollingResponse = await getPollingResponse();

      if (pollingResponse.success) {
        await updateAccessGrantPageInfo();
      }

      return pollingResponse;
    } catch (error) {
      return {
        success: false,
        errorMessage: 'Exception while polling reservation',
      };
    }
  }, []);

  return {
    repollReservation,
  };
};
