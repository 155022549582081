import CreateUserFormInputs, { DashboardPreferencesInput } from './@types/CreateUserFormInputs';
import React, { useEffect } from 'react';
import { dateFormatOptions, timeFormatOptions } from './constants/dashboardPreferencesInputs';

import EditUserFormInputs from './@types/EditUserFormInputs';
import FormTemplate from '../../templates/Form';
import { Mutation } from '../../__generated__/graphql';
import { RootState } from '../../redux/reducers';
import USER_FORM_STEPS from './constants/userFormSteps';
import USER_VALIDATION_SCHEMA from './constants/userValidationSchema';
import { UserDashboardPreferences } from '../../@types/client/DashboardPreferences';
import UserFormMembershipMap from './@types/UserFormMembershipMap';
import generateEditUserValidationSchema from './functions/generateEditUserValidationSchema';
import useGetUserById from '../../hooks/useGetUserById';
import { useSelector } from 'react-redux';
import userIsAbleToSetPassword from './functions/userIsAbleToSetPassword';

type CreateUserData = Pick<Mutation, 'createUser'>;

const UserForm: React.FC<{}> = () => {
  const loggedUser = useSelector((state: RootState) => state.me);
  const id = useSelector((state: RootState) => state.modals.form.id);

  const { called, data: user, query } = useGetUserById();

  const activeMembership = useSelector((state: RootState) => state.me.activeMembership)!;

  useEffect(() => {
    if (id && !called) {
      query(id);
    }
  }, [id, called, query]);

  if (id && !user) {
    return <></>;
  }

  if (user) {
    const locationMemberships = user.memberships.filter((membership) =>
      Boolean(membership.location)
    );
    const locationsMap: UserFormMembershipMap = {};

    const valuesArray = locationMemberships.map((membership) => ({
      label: membership.location!.name,
      value: membership.location!.id,
    }));

    locationMemberships.forEach((membership) => {
      locationsMap[membership.location!.id] = {
        membershipId: membership.id,
        option: {
          label: membership.location!.name,
          value: membership.location!.id,
        },
        shouldCreate: false,
        shouldDelete: false,
        shouldUpdate: false,
        withRole: membership.role,
      };
    });

    const tenantMembership = user.memberships.find(
      (membership) => !membership.location && membership.tenantId === activeMembership.tenant.id
    );

    const EDIT_USER_VALIDATION_SCHEMA = generateEditUserValidationSchema({
      isAbleToEditPassword: userIsAbleToSetPassword({
        currentUser: loggedUser,
        editingUserId: id,
        userRole: tenantMembership?.role,
      }),
    });

    let dashboardPreferencesInput: DashboardPreferencesInput | undefined;
    if (user.dashboardPreferences) {
      const dashboardPreferences = user.dashboardPreferences as UserDashboardPreferences;

      dashboardPreferencesInput = {
        ...(dashboardPreferences.preferredDateFormat && {
          preferredDateFormat: {
            label:
              dateFormatOptions.find(
                (dateFormat) => dateFormat.value === dashboardPreferences.preferredDateFormat
              )?.label ?? dashboardPreferences.preferredDateFormat,
            value: dashboardPreferences.preferredDateFormat,
          },
        }),
        ...(dashboardPreferences.preferredTimeFormat && {
          preferredTimeFormat: {
            label:
              timeFormatOptions.find(
                (timeFormat) => timeFormat.value === dashboardPreferences.preferredTimeFormat
              )?.label ?? dashboardPreferences.preferredTimeFormat,
            value: dashboardPreferences.preferredTimeFormat,
          },
        }),
      };
    }

    const getInitialValues = (): Partial<EditUserFormInputs> => {
      return {
        dashboardPreferences: dashboardPreferencesInput,
        email: user.email ?? '',
        locations: {
          map: locationsMap,
          values: valuesArray,
        },
        membershipId: tenantMembership!.id,
        name: user.name ?? user.id,
        role: tenantMembership!.role,
        ...(user.phoneNumber && { phoneNumber: user.phoneNumber }),
      };
    };

    return (
      <FormTemplate<EditUserFormInputs, CreateUserData>
        formHeader="Edit User"
        identifier={id}
        steps={USER_FORM_STEPS}
        validationSchema={EDIT_USER_VALIDATION_SCHEMA}
        initialValues={getInitialValues()}
      />
    );
  }

  const getInitialValues = (): Partial<CreateUserFormInputs> => {
    return {
      locations: {
        map: {},
        values: [],
      },
    };
  };

  return (
    <FormTemplate<CreateUserFormInputs, CreateUserData>
      formHeader="Create User"
      initialValues={getInitialValues()}
      steps={USER_FORM_STEPS}
      validationSchema={USER_VALIDATION_SCHEMA}
    />
  );
};

export default React.memo(UserForm);
