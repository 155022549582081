import styled from 'styled-components';

const TableBody = styled.tbody`
  display: contents;
  position: relative;
`;

TableBody.displayName = 'StyledTableBody';

export default TableBody;
