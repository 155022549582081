import 'styled-components/macro';

import { Colors, RGB } from '../../../theme/colors';
import DisplayHeader, { DisplayHeaderMap } from '../@types/DisplayHeader';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import React, { useContext } from 'react';

import Button from '../../Button';
import ButtonModel from '../../Button/enums/ButtonModel.enum';
import { SortOrder } from '../../../__generated__/graphql';
import TableDataContext from '../contexts/TableDataContext';
import TextContent from '../../TextContent';
import Theme from '../../../theme';
import styled from 'styled-components';

interface TableHeadingProps {
  header: DisplayHeader;
  setDisplayHeaders: React.Dispatch<React.SetStateAction<DisplayHeaderMap>>;
  noResults: boolean;
}

export const StyledTableHeading = styled.th`
  align-items: center;
  background: transparent;
  color: ${Theme.colors.tableHeading.hexCode};
  display: flex;
  font-family: 'San Francisco Pro';
  font-size: ${Theme.fontSizes.tableHeading};
  font-weight: bold;
  position: sticky;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  top: 0;
  white-space: nowrap;
`;

StyledTableHeading.displayName = 'StyledTableHeading';

const TableHeading: React.FC<TableHeadingProps> = ({
  header: { displayValue, isSortable, sortOrder, value, sortType },
  noResults,
}: TableHeadingProps) => {
  const contextProps = useContext(TableDataContext);

  if (!contextProps) {
    return <></>;
  }

  const { displayHeaders, setDisplayHeaders } = contextProps;

  function sortColumn(): void {
    const activeHeader = displayHeaders[displayValue];
    const newHeader: DisplayHeader = { ...activeHeader };

    if (activeHeader?.sortOrder === SortOrder.Asc) {
      newHeader.sortOrder = SortOrder.Desc;
    } else if (activeHeader?.sortOrder === SortOrder.Desc) {
      newHeader.sortOrder = null;
    } else if (activeHeader) {
      newHeader.sortOrder = SortOrder.Asc;
    }

    setDisplayHeaders({
      ...displayHeaders,
      [activeHeader.displayValue]: newHeader,
    });
  }

  return (
    <StyledTableHeading
      css={`
        align-items: center;
        border-bottom: 1px solid rgba(${Theme.colors.borderTable.rgb}, 0.1);
        grid-auto-columns: max-content;
        grid-auto-flow: column;
        grid-column-gap: 5px;
        padding: 15px 25px 25px 0;
      `}
    >
      <TextContent
        css={`
          color: ${Theme.colors.tableHeading.hexCode};
        `}
      >
        {displayValue}
      </TextContent>
      {Boolean(value) && isSortable && sortType && !noResults && (
        <Button
          css={`
            background: ${sortOrder ? Colors.linkWater : Colors.manatee};
            border-radius: 2px;
            height: 15px;
            padding: 0;
            width: 15px;

            &:hover {
              background: rgba(${RGB.manatee}, 0.85);
            }
          `}
          model={ButtonModel.DEFAULT}
          onClick={sortColumn}
        >
          {sortOrder === SortOrder.Desc && <MdArrowDropDown size="18px" />}
          {sortOrder === SortOrder.Asc && <MdArrowDropUp size="18px" />}
        </Button>
      )}
    </StyledTableHeading>
  );
};

export default TableHeading;
