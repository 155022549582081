import { gql } from '../__generated__/gql';

const DOWNLOAD_UNIT_TYPE_TEMPLATE_CSV_FILE = gql(`
  query DownloadUnitTypeTemplateCSVFile {
    downloadUnitTypeTemplateCSVFile {
      data
    }
  }
`);

export default DOWNLOAD_UNIT_TYPE_TEMPLATE_CSV_FILE;
