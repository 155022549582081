import React, { useContext } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';

import AccessGrantFormInputs from '../../@types/AccessGrantFormInputs';
import { ApolloError } from '@apollo/client';
import AsyncSelectInput from '../../../AsyncSelectInput';
import { FileType } from '../../../../__generated__/graphql';
import Form from '../../../Form';
import FormContext from '../../../../contexts/form.context';
import SubmitRow from '../../../SubmitRow';
import { flashApolloError } from '../../../../util/errorUtils';
import { openConfirmationModal } from '../../../../redux/actions/modals.actions';
import useAdjustResourcesMap from '../../../../hooks/util/useAdjustResourcesMap';
import useCreateAccessGrant from '../../../../hooks/useCreateAccessGrant';
import { useDispatch } from 'react-redux';
import useLoadUserOptions from '../../../../hooks/useLoadUserOptions';
import useUpdateAccessGrant from '../../../../hooks/useUpdateAccessGrant';
import useUploadFile from '../../../../hooks/useUploadFile';

const SecondaryUsersSection: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { handleSubmit } = useFormContext();
  const { identifier } = useContext(FormContext);
  const useCreateOrUpdateMutation = identifier ? useUpdateAccessGrant : useCreateAccessGrant;
  const { mutation: uploadFile } = useUploadFile();
  const { loading, mutation } = useCreateOrUpdateMutation({
    onError: flashApolloError,
  });

  const { adjustResourcesMap } = useAdjustResourcesMap('secondaryUsers.map');

  const submitHandler: SubmitHandler<AccessGrantFormInputs> = async (inputs) => {
    const { file, ...inputData } = inputs;
    let fileId = '';

    try {
      if (file) {
        const uploadResult = await uploadFile({
          file,
          fileType: FileType.OriginalAgreementFile,
          id: inputs.location.value,
        });

        fileId = uploadResult.data?.uploadFile.id;
      }

      const augmentedInput = {
        ...inputData,
        originalAgreementFileId: fileId,
      };

      dispatch(
        openConfirmationModal({
          action: async (): Promise<void> => {
            if (identifier) {
              await mutation(augmentedInput, identifier);
            } else {
              await mutation(augmentedInput);
            }
          },
        })
      );
    } catch (e) {
      flashApolloError(e as ApolloError);
    }
  };

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <AsyncSelectInput
        isMulti
        loadOptions={useLoadUserOptions()}
        name="secondaryUsers.values"
        onChange={adjustResourcesMap}
      />
      <SubmitRow loading={loading} submitText="Submit" />
    </Form>
  );
};

export default SecondaryUsersSection;
