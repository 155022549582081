import FormTemplateStep from '../../../templates/Form/@types/FormTemplateStep';
import PollReservationFormInputs from '../@types/PollReservationFormInputs';
import PollReservationSection from '../components/PollReservationSection';
import PollReservationTypesData from '../@types/PollReservationTypesData';

const POLL_RESERVATION_FORM_STEPS: FormTemplateStep<
  PollReservationFormInputs,
  PollReservationTypesData
>[] = [
  {
    canBeAccessed: (): boolean => true,
    component: PollReservationSection,
    name: 'Poll reservation',
  },
];

export default POLL_RESERVATION_FORM_STEPS;
