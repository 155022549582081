import 'styled-components/macro';

import { Colors, RGB } from '../../../theme/colors';
import Heading, { HeadingModel } from '../../../components/Heading';
import React, { useEffect, useState } from 'react';

import AGGREGATE_ARRIVALS_HEADERS from '../constants/aggregateArrivalsHeaders';
import ARRIVALS_BY_DAY from '../../../graphql/arrivalsByDay';
import Button from '../../../components/Button';
import ButtonModel from '../../../components/Button/enums/ButtonModel.enum';
import { CSVLink } from 'react-csv';
import Container from '../../../components/Container';
import LineGraph from '../../../components/LineGraph';
import { QueryGenerateArrivalsByDayReportArgs } from '../../../__generated__/graphql';
import { RootState } from '../../../redux/reducers';
import SimpleTable from '../../../components/SimpleTable';
import arrivalsToCSV from '../util/arrivalsToCSV';
import { useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

const AggregateArrivalsTab: React.FC<{}> = () => {
  const activeMembership = useSelector((state: RootState) => state.me.activeMembership);
  const data = useSelector((state: RootState) => state.reporting.arrivalsByDay);
  const [numOfDays, setNumOfDays] = useState<number>(30);

  const [query] = useLazyQuery<QueryGenerateArrivalsByDayReportArgs>(ARRIVALS_BY_DAY);

  useEffect(() => {
    query({
      variables: {
        days: numOfDays,
        tenantId: activeMembership.tenantId,
        ...(activeMembership.location && { locationId: activeMembership.location.id }),
      },
    });
  }, [activeMembership, numOfDays, query]);

  const HEADING_TEXT = activeMembership.location?.name ?? activeMembership.tenant.name;

  const BUTTON_DAYS = [7, 14, 30, 90];
  const GRAPH_WIDTH = window.innerWidth > 1500 ? 900 : 600;

  function determineButtonClassName(num: number): 'active' | 'inactive' {
    if (num === numOfDays) {
      return 'active';
    }

    return 'inactive';
  }

  const CSV_DATA = arrivalsToCSV(data);

  return (
    <Container
      css={`
        grid-auto-flow: column;
        grid-column-gap: 15px;
        grid-template-columns: ${GRAPH_WIDTH} 1fr;
      `}
    >
      <Container
        css={`
          background: ${Colors.white};
          border-radius: 3px;
          box-shadow: 0 0 20px 5px rgba(${RGB.neroDarkC}, 0.2);
          padding: 15px 10px;
        `}
      >
        <Container
          css={`
            padding: 20px 30px 0;
            width: ${GRAPH_WIDTH}px;

            a {
              background: ${Colors.whisper};
              border: 1px solid ${Colors.shuttleGrey};
              font-size: 12px;
              border-radius: 4px;
              padding: 4px 6px;
              width: max-content;
            }
          `}
        >
          <Container
            css={`
              align-items: center;
              grid-auto-columns: max-content;
              grid-auto-flow: column;
              grid-column-gap: 15px;
              grid-template-columns: 1fr repeat(${BUTTON_DAYS.length}, max-content);

              > button {
                color: ${Colors.shuttleGrey};
              }

              > button.active {
                color: ${Colors.neroDarkC};
                font-weight: bold;
              }

              > button:hover {
                color: rgba(${RGB.shuttleGrey}, 0.8);
              }

              > button:disabled:hover {
                color: ${Colors.neroDarkC};
              }
            `}
          >
            <Heading model={HeadingModel.SECONDARY}>{HEADING_TEXT} Arrivals</Heading>
            {BUTTON_DAYS.map((day) => (
              <Button
                disabled={determineButtonClassName(day) === 'active'}
                className={determineButtonClassName(day)}
                key={`day-button-${day}`}
                model={ButtonModel.INLINE_BUTTON}
                onClick={(): void => setNumOfDays(day)}
              >
                {day}d
              </Button>
            ))}
          </Container>
          <CSVLink
            data={CSV_DATA}
            filename={`virdee_arrivals_past_${numOfDays}_days.csv`}
            hidden={!(CSV_DATA?.length > 1)}
          >
            Download CSV
          </CSVLink>
        </Container>
        <LineGraph data={data} height={600} width={GRAPH_WIDTH} />
      </Container>
      <Container
        css={`
          background: ${Colors.white};
          border-radius: 3px;
          box-shadow: 0 0 20px 5px rgba(${RGB.neroDarkC}, 0.2);
          height: max-content;
          padding: 15px 0;
        `}
      >
        <Container
          css={`
            max-height: 400px;
            overflow: scroll;
            padding: 0 25px;
          `}
        >
          <SimpleTable data={CSV_DATA.slice(1)} headers={AGGREGATE_ARRIVALS_HEADERS} />
        </Container>
      </Container>
    </Container>
  );
};

export default AggregateArrivalsTab;
