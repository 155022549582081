import {
  credentialBeingProcessed,
  credentialProcessed,
} from '../../../redux/actions/pendingTasks.actions';
import { getUserType, getUserTypeName } from '../../useCredentialsTableData/functions/userType';

import { Credential } from '../../../__generated__/graphql';
import { InvalidateCredentialTypeData } from '../@types/InvalidateCredentialData';
import { channelsClient } from '../../../util/pusher';
import { closeRemoveModal } from '../../../redux/actions/modals.actions';
import { getCredentialBasicType } from '../../useCredentialsTableData/functions/credentialType';
import links from '../../../util/links';
import revokeCredentialTimeout from '../constants/revokeCredentialTimeout';
import { useDispatch } from 'react-redux';

const { REACT_APP_VIRDEE_ENV = 'local' } = process.env;

type GetCredentialTableData = Array<Credential>;

const useCompleteRemoveCredential = (): {
  onCompleteRemoveCredential: (invalidateCredential: InvalidateCredentialTypeData) => void;
} => {
  const dispatch = useDispatch();

  const onCompleteRemoveCredential = (invalidateCredential: InvalidateCredentialTypeData): void => {
    dispatch(closeRemoveModal());
    dispatch(credentialBeingProcessed(invalidateCredential.id));

    window.flash({
      message: `Credential ${getCredentialBasicType(
        invalidateCredential.type
      )} for ${getUserTypeName(
        getUserType({
          accessGrantUserId: invalidateCredential.accessGrant.userId,
          credentialUserId: invalidateCredential.userId,
        })
      )} is being revoked.`,
    });

    const CREDENTIAL_INVALIDATED = 'CREDENTIAL_INVALIDATED';

    const accessGrantChannel = channelsClient.subscribe(
      `ACCESS_GRANT-${REACT_APP_VIRDEE_ENV}-${invalidateCredential.accessGrant.id}-${CREDENTIAL_INVALIDATED}`
    );

    // Send message notifying about timeout on invalidating credential
    setTimeout(() => {
      dispatch(credentialProcessed(invalidateCredential.id));
      accessGrantChannel.unbind(CREDENTIAL_INVALIDATED);

      window.flash({
        link: `${links.paths.ACCESS_GRANTS}/${invalidateCredential.accessGrant.id}`,
        message: `Fail on revoke credential ${getCredentialBasicType(
          invalidateCredential.type
        )} for ${getUserTypeName(
          getUserType({
            accessGrantUserId: invalidateCredential.accessGrant.userId,
            credentialUserId: invalidateCredential.userId,
          })
        )}`,
        subText: 'Timeout',
      });
    }, revokeCredentialTimeout);

    // Listen pusher message about credential revoked
    accessGrantChannel.bind(
      CREDENTIAL_INVALIDATED,
      (data: {
        credentials: GetCredentialTableData;
        accessGrant: { id: string; userId: string };
      }) => {
        dispatch(credentialProcessed(invalidateCredential.id));
        accessGrantChannel.unbind(CREDENTIAL_INVALIDATED);

        const { accessGrant, credentials } = data;
        if (credentials.length === 1) {
          const [credential] = credentials;

          const credentialBasicType = getCredentialBasicType(credential.type);
          const userType = getUserTypeName(
            getUserType({
              accessGrantUserId: accessGrant.userId,
              credentialUserId: credential.userId,
            })
          );

          window.flash({
            link: `${links.paths.ACCESS_GRANTS}/${accessGrant.id}`,
            message: `Credential ${credentialBasicType} for ${userType} revoked successfully`,
          });
        } else {
          window.flash({
            link: `${links.paths.ACCESS_GRANTS}/${accessGrant.id}`,
            message: `Credential revoked successfully`,
          });
        }
      }
    );
  };

  return {
    onCompleteRemoveCredential,
  };
};

export default useCompleteRemoveCredential;
