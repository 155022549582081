const RemoteAssistanceAsideStyle = `
  grid-area: aside;
  grid-auto-columns: 1fr;
  justify-items: center;
  min-height: 100vh;
  padding: 15px 0 25px;
  width: 500px;

  @media only screen and (max-width: 1450px) {
    width: 320px;
  }

  @media only screen and (max-width: 1200px) {
    width: 320px;
  }
`;

export default RemoteAssistanceAsideStyle;
