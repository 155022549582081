/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3ThemeCurrentBookingSchema: NodeSchemaProperties = {
  currentBookingScreen: {
    title: '#4 Current Booking Screen',
    type: 'object',
    properties: {
      'cardBackgroundColor:enable': {
        description: '[currentBookingScreen.cardBackgroundColor]',
        title: '#1 Card Background Color',
        type: 'boolean',
      },
      cardBackgroundColor: {
        type: 'string',
      },
      'cardTitleColor:enable': {
        description: '[currentBookingScreen.cardTitleColor]',
        title: '#2 Card Title Color',
        type: 'boolean',
      },
      cardTitleColor: {
        type: 'string',
      },
      'cardSubtitleColor:enable': {
        description: '[currentBookingScreen.cardSubtitleColor]',
        title: '#3 Card Subtitle Color',
        type: 'boolean',
      },
      cardSubtitleColor: {
        type: 'string',
      },
      'cardTextColor:enable': {
        description: '[currentBookingScreen.cardTextColor]',
        title: '#4 Card Text Color',
        type: 'boolean',
      },
      cardTextColor: {
        type: 'string',
      },
      'cardDividerColor:enable': {
        description: '[currentBookingScreen.cardDividerColor]',
        title: '#5 Card Divider Color',
        type: 'boolean',
      },
      cardDividerColor: {
        type: 'string',
      },
      cardShadow: {
        title: '#6 Card Shadow',
        type: 'object',
        properties: {
          'shadowColor:enable': {
            description: '[currentBookingScreen.cardShadow.shadowColor]',
            title: '#6.1 Shadow Color',
            type: 'boolean',
          },
          shadowColor: {
            type: 'string',
          },
          shadowOffset: {
            title: '#6.2 Shadow Offset',
            type: 'object',
            properties: {
              'width:enable': {
                description: '[currentBookingScreen.cardShadow.shadowOffset.width]',
                title: '#6.2.1 Width',
                type: 'boolean',
              },
              width: {
                type: 'number',
              },
              'height:enable': {
                description: '[currentBookingScreen.cardShadow.shadowOffset.height]',
                title: '#6.2.2 Height',
                type: 'boolean',
              },
              height: {
                type: 'number',
              },
            },
          },
          'shadowOpacity:enable': {
            description: '[currentBookingScreen.cardShadow.shadowOpacity]',
            title: '#6.3 Shadow Opacity',
            type: 'boolean',
          },
          shadowOpacity: {
            type: 'number',
          },
          'shadowRadius:enable': {
            description: '[currentBookingScreen.cardShadow.shadowRadius]',
            title: '#6.4 Shadow Radius',
            type: 'boolean',
          },
          shadowRadius: {
            type: 'number',
          },
          'elevation:enable': {
            description: '[currentBookingScreen.cardShadow.elevation]',
            title: '#6.5 Elevation',
            type: 'boolean',
          },
          elevation: {
            type: 'number',
          },
        },
      },
      requirementButton: {
        title: '#7 Requirement Button',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[currentBookingScreen.requirementButton.backgroundColor]',
            title: '#7.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'textColor:enable': {
            description: '[currentBookingScreen.requirementButton.textColor]',
            title: '#7.2 Text Color',
            type: 'boolean',
          },
          textColor: {
            type: 'string',
          },
          'borderColor:enable': {
            description: '[currentBookingScreen.requirementButton.borderColor]',
            title: '#7.3 Border Color',
            type: 'boolean',
          },
          borderColor: {
            type: 'string',
          },
          'borderWidth:enable': {
            description: '[currentBookingScreen.requirementButton.borderWidth]',
            title: '#7.4 Border Width',
            type: 'boolean',
          },
          borderWidth: {
            type: 'number',
          },
          'shadowColor:enable': {
            description: '[currentBookingScreen.requirementButton.shadowColor]',
            title: '#7.5 Shadow Color',
            type: 'boolean',
          },
          shadowColor: {
            type: 'string',
          },
          leftIcon: {
            title: '#7.6 Left Icon',
            type: 'object',
            properties: {
              'fillColor:enable': {
                description: '[currentBookingScreen.requirementButton.leftIcon.fillColor]',
                title: '#7.6.1 Fill Color',
                type: 'boolean',
              },
              fillColor: {
                type: 'string',
              },
              'backgroundColor:enable': {
                description: '[currentBookingScreen.requirementButton.leftIcon.backgroundColor]',
                title: '#7.6.2 Background Color',
                type: 'boolean',
              },
              backgroundColor: {
                type: 'string',
              },
            },
          },
          rightIcon: {
            title: '#7.7 Right Icon',
            type: 'object',
            properties: {
              completed: {
                title: '#7.7.1 Completed',
                type: 'object',
                properties: {
                  'fillColor:enable': {
                    description:
                      '[currentBookingScreen.requirementButton.rightIcon.completed.fillColor]',
                    title: '#7.7.1.1 Fill Color',
                    type: 'boolean',
                  },
                  fillColor: {
                    type: 'string',
                  },
                  'strokeColor:enable': {
                    description:
                      '[currentBookingScreen.requirementButton.rightIcon.completed.strokeColor]',
                    title: '#7.7.1.2 Stroke Color',
                    type: 'boolean',
                  },
                  strokeColor: {
                    type: 'string',
                  },
                  'customIconUrl:enable': {
                    description:
                      '[currentBookingScreen.requirementButton.rightIcon.completed.customIconUrl]',
                    title: '#7.7.1.3 Custom Icon Url',
                    type: 'boolean',
                  },
                  customIconUrl: {
                    type: 'string',
                  },
                },
              },
              incompleted: {
                title: '#7.7.2 Incompleted',
                type: 'object',
                properties: {
                  'fillColor:enable': {
                    description:
                      '[currentBookingScreen.requirementButton.rightIcon.incompleted.fillColor]',
                    title: '#7.7.2.1 Fill Color',
                    type: 'boolean',
                  },
                  fillColor: {
                    type: 'string',
                  },
                  'customIconUrl:enable': {
                    description:
                      '[currentBookingScreen.requirementButton.rightIcon.incompleted.customIconUrl]',
                    title: '#7.7.2.2 Custom Icon Url',
                    type: 'boolean',
                  },
                  customIconUrl: {
                    type: 'string',
                  },
                },
              },
            },
          },
          'termsAndConditionIconUrl:enable': {
            description: '[currentBookingScreen.requirementButton.termsAndConditionIconUrl]',
            title: '#7.8 Terms And Condition Icon Url',
            type: 'boolean',
          },
          termsAndConditionIconUrl: {
            type: 'string',
          },
          'paymentAuthorizationIconUrl:enable': {
            description: '[currentBookingScreen.requirementButton.paymentAuthorizationIconUrl]',
            title: '#7.9 Payment Authorization Icon Url',
            type: 'boolean',
          },
          paymentAuthorizationIconUrl: {
            type: 'string',
          },
          'paymentStatusIconUrl:enable': {
            description: '[currentBookingScreen.requirementButton.paymentStatusIconUrl]',
            title: '#7.10 Payment Status Icon Url',
            type: 'boolean',
          },
          paymentStatusIconUrl: {
            type: 'string',
          },
          'idVerificationIconUrl:enable': {
            description: '[currentBookingScreen.requirementButton.idVerificationIconUrl]',
            title: '#7.11 Id Verification Icon Url',
            type: 'boolean',
          },
          idVerificationIconUrl: {
            type: 'string',
          },
          'paymentCaptureIconUrl:enable': {
            description: '[currentBookingScreen.requirementButton.paymentCaptureIconUrl]',
            title: '#7.12 Payment Capture Icon Url',
            type: 'boolean',
          },
          paymentCaptureIconUrl: {
            type: 'string',
          },
        },
      },
      incompleteRequirements: {
        title: '#8 Incomplete Requirements',
        type: 'object',
        properties: {
          'iconUrl:enable': {
            description: '[currentBookingScreen.incompleteRequirements.iconUrl]',
            title: '#8.1 Icon Url',
            type: 'boolean',
          },
          iconUrl: {
            type: 'string',
          },
        },
      },
      fastPass: {
        title: '#9 Fast Pass',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[currentBookingScreen.fastPass.backgroundColor]',
            title: '#9.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'textColor:enable': {
            description: '[currentBookingScreen.fastPass.textColor]',
            title: '#9.2 Text Color',
            type: 'boolean',
          },
          textColor: {
            type: 'string',
          },
          'successTextColor:enable': {
            description: '[currentBookingScreen.fastPass.successTextColor]',
            title: '#9.3 Success Text Color',
            type: 'boolean',
          },
          successTextColor: {
            type: 'string',
          },
          'successIconUrl:enable': {
            description: '[currentBookingScreen.fastPass.successIconUrl]',
            title: '#9.4 Success Icon Url',
            type: 'boolean',
          },
          successIconUrl: {
            type: 'string',
          },
        },
      },
    },
  },
};

export const mobileV3ThemeCurrentBookingUiSchema: NodeUiSchemaProperties = {
  currentBookingScreen: {
    cardBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cardTitleColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cardSubtitleColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cardTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cardDividerColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cardShadow: {
      shadowColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      shadowOffset: {
        width: {
          'ui:label': false,
          'ui:readonly': true,
        },
        height: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      shadowOpacity: {
        'ui:label': false,
        'ui:readonly': true,
      },
      shadowRadius: {
        'ui:label': false,
        'ui:readonly': true,
      },
      elevation: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    requirementButton: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderWidth: {
        'ui:label': false,
        'ui:readonly': true,
      },
      shadowColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      leftIcon: {
        fillColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        backgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
      rightIcon: {
        completed: {
          fillColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          strokeColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          customIconUrl: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'link',
            'ui:widget': 'uri',
          },
        },
        incompleted: {
          fillColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          customIconUrl: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'link',
            'ui:widget': 'uri',
          },
        },
      },
      termsAndConditionIconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
      paymentAuthorizationIconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
      paymentStatusIconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
      idVerificationIconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
      paymentCaptureIconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
    },
    incompleteRequirements: {
      iconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
    },
    fastPass: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      successTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      successIconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
    },
  },
};
