import {
  RESOURCE_VALIDATION_SCHEMA,
  RESOURCE_VENDOR_VALIDATION_SCHEMA,
} from './resourceValidationSchema';

import FormTemplateStep from '../../../templates/Form/@types/FormTemplateStep';
import { Mutation } from '../../../__generated__/graphql';
import ResourceFormInputs from '../@types/ResourceFormInputs';
import ResourceSection from '../components/ResourceSection';
import VendorSection from '../components/VendorSection';
import canAccessResourceVendorsSection from '../functions/canAccessResourceVendorSection';

type CreateResourceData = Pick<Mutation, 'createResourceAndUploadImageFile'>;

const RESOURCE_FORM_STEPS: FormTemplateStep<ResourceFormInputs, CreateResourceData>[] = [
  {
    canBeAccessed: (): boolean => true,
    component: ResourceSection,
    name: 'Resource',
    validationSchema: RESOURCE_VALIDATION_SCHEMA,
  },
  {
    canBeAccessed: canAccessResourceVendorsSection,
    component: VendorSection,
    name: 'Vendor',
    validationSchema: RESOURCE_VENDOR_VALIDATION_SCHEMA,
  },
];

export default RESOURCE_FORM_STEPS;
