import { Colors } from '../../../theme/colors';

const DropdownListStyle = `
    background: ${Colors.white};
    border-radius: 6px;
    grid-template-rows: 1fr;
    height: max-content;
    min-height: 200px;
    min-width: 200px;
    max-width: 400px;
    position: absolute;
    top: calc(100% + 5px);
    z-index: 5;
`;

export default DropdownListStyle;
