export default function generateEmailWithDisplayName(email?: string, name?: string): string {
  if (name && email) {
    return `${name} <${email}>`;
  }

  if (email) {
    return email;
  }

  return '';
}
