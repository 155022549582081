import 'styled-components/macro';

import React, { PropsWithChildren, useEffect, useImperativeHandle, useRef, useState } from 'react';

import Button from '../Button';
import ButtonModel from '../Button/enums/ButtonModel.enum';
import Container from '../Container';
import DropdownButtonStyle from './styles/DropdownButton.style';
import DropdownListStyle from './styles/DropdownList.style';
import DropdownProps from './@types/DropdownProps';
import DropdownStyle from './styles/Dropdown.style';

type DropdownRef = unknown;

export interface ReferenceDropdownInterface {
  close(): void;
}

const Dropdown = React.forwardRef<DropdownRef, PropsWithChildren<DropdownProps>>(
  ({ children, label }: PropsWithChildren<DropdownProps>, ref) => {
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    function close(): void {
      if (isOpen) {
        setIsOpen(false);
      }
    }

    function toggle(): void {
      setIsOpen(!isOpen);
    }

    const referenceDropdown: ReferenceDropdownInterface = {
      close(): void {
        close();
      },
    };
    useImperativeHandle(ref, () => referenceDropdown);

    useEffect(() => {
      const checkIfClickedOutside = (e: Event): void => {
        if (dropdownRef.current && !dropdownRef.current?.contains(e.target as Node)) {
          close();
        }
      };

      document.addEventListener('mousedown', checkIfClickedOutside);

      return (): void => {
        document.removeEventListener('mousedown', checkIfClickedOutside);
      };
    });

    return (
      <Container css={DropdownStyle} ref={dropdownRef}>
        <Button css={DropdownButtonStyle} model={ButtonModel.DEFAULT} onClick={toggle}>
          {label}
        </Button>
        {isOpen && <Container css={DropdownListStyle}>{children}</Container>}
      </Container>
    );
  }
);

Dropdown.displayName = 'Dropdown';

export default Dropdown;
