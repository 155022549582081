import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import SingleDayAggregate from '../../@types/client/SingleDayAggregate';
import { channelsClient } from '../../util/pusher';
import receiveAggregateArrivals from '../../redux/reducers/reporting/actions/receiveAggregateArrivals';
import sortBy from 'lodash/sortBy';

const { REACT_APP_VIRDEE_ENV = 'local' } = process.env;

export default function useBindReportingChannels(): void {
  const dispatch = useDispatch();
  const userId = useSelector((store: RootState) => store.me.id);
  const reportingChannel = channelsClient.subscribe(`REPORTING-${REACT_APP_VIRDEE_ENV}-${userId}`);

  reportingChannel.bind(
    'GENERATE_AGGREGATE_ARRIVALS',
    ({ data }: { data: SingleDayAggregate[] }) => {
      const sortedData = sortBy(data, 'date');
      dispatch(receiveAggregateArrivals(sortedData));
    }
  );
}
