import DataTable from '../../../components/DataTable';
import { EntityEnum } from '../../../@types/client/DashboardUserRolePermissions';
import React from 'react';
import { useParams } from 'react-router';
import useResourcesTableData from '../../../hooks/useResourcesTableData';

const ResourcesTab: React.FC<{}> = () => {
  const { accessGrantId } = useParams<{ accessGrantId: string }>();

  const tableData = useResourcesTableData({
    dataTableKey: 'resources-AccessGrant',
    permission: EntityEnum.NONE,
    queryOptions: {
      where: {
        accessGrants: {
          some: {
            id: { equals: accessGrantId },
          },
        },
      },
    },
  });

  return <DataTable {...tableData} />;
};

export default ResourcesTab;
