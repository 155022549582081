import { Query, QueryLocationsArgs, QueryMode, SortOrder } from '../__generated__/graphql';

import { RootState } from '../redux/reducers';
import SEARCH_LOCATIONS from '../graphql/searchLocations';
import SelectOption from '../@types/react/SelectOption';
import { useApolloClient } from '@apollo/client';
import { useSelector } from 'react-redux';

type SearchLocationsData = Pick<Query, 'locations'>;

export default function useLoadLocationOptions(): (input: string) => Promise<SelectOption[]> {
  const client = useApolloClient();
  const me = useSelector((state: RootState) => state.me);
  const activeTenantId = me.activeMembership?.tenantId ?? '';

  return async (input: string): Promise<SelectOption[]> => {
    const { data } = await client.query<SearchLocationsData, QueryLocationsArgs>({
      query: SEARCH_LOCATIONS,
      variables: {
        orderBy: [
          {
            name: SortOrder.Asc,
          },
        ],
        take: 10,
        where: {
          name: {
            contains: input,
            mode: QueryMode.Insensitive,
          },
          tenantId: {
            equals: activeTenantId,
          },
        },
      },
    });

    return (
      data.locations?.map((location) => ({
        label: location.name,
        value: location.id,
      })) ?? []
    );
  };
}
