import {
  CustomUnitTypeCreateInput,
  Mutation,
  MutationCreateUnitTypeAndUploadImageFileArgs,
} from '../__generated__/graphql';

import CREATE_UNIT_TYPE from '../graphql/createUnitType';
import MutationHookData from './@types/MutationHookData';
import MutationHookParams from './@types/MutationHookParams';
import { apolloRequirePreflightHeader } from '../client';
import { flashApolloError } from '../util/errorUtils';
import { useMutation } from '@apollo/client';

type CreateUnitTypeData = Pick<Mutation, 'createUnitTypeAndUploadImageFile'>;

export default function useCreateUnitTypeAndUploadImage({
  onCompleted = (): void => {},
  onError = flashApolloError,
}: MutationHookParams<CreateUnitTypeData> = {}): MutationHookData<
  CustomUnitTypeCreateInput,
  CreateUnitTypeData
> {
  const [create, { data, error, loading }] = useMutation<
    CreateUnitTypeData,
    MutationCreateUnitTypeAndUploadImageFileArgs
  >(CREATE_UNIT_TYPE, {
    context: {
      headers: apolloRequirePreflightHeader,
    },
    onCompleted: ({ createUnitTypeAndUploadImageFile }) => {
      onCompleted({ createUnitTypeAndUploadImageFile });
    },
    onError,
  });

  return {
    data,
    error,
    loading,
    mutation: (inputs: CustomUnitTypeCreateInput): void => {
      create({
        variables: {
          data: inputs,
        },
      });
    },
  };
}
