import { CallType } from '../../../../__generated__/graphql';
import Filter from '../../../../components/DataTable/@types/Filter';
import capitalize from 'lodash/capitalize';

function addCallTypeFilters(filters: Filter[]): void {
  Object.keys(CallType).forEach((callType) => {
    filters.push({
      displayKey: 'Type',
      displayValue: capitalize(CallType[callType as keyof typeof CallType]),
      key: 'type',
      value: CallType[callType as keyof typeof CallType],
    });
  });
}

export default function useCallRecordFilters(): Filter[] {
  const filters: Filter[] = [];

  addCallTypeFilters(filters);

  return filters;
}
