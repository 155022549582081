import { $CombinedState, AnyAction, Store, createStore as createReduxStore } from 'redux';
import { devToolsEnhancer } from '@redux-devtools/extension';
import rootReducer from './reducers';

export default function createStore(): Store<
  {
    readonly [$CombinedState]?: undefined;
  },
  AnyAction
> {
  return createReduxStore(rootReducer, devToolsEnhancer());
}
