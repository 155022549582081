import DataTable from '../../../components/DataTable';
import { LocationPageInfo } from '../@types/GetLocationPageInfo';
import React from 'react';
import useVendorServersTableData from '../../../hooks/useVendorServersTableData';

interface AccessControlTabProps {
  location: LocationPageInfo;
}

const AccessControlTab: React.FC<AccessControlTabProps> = ({ location }: AccessControlTabProps) => {
  const tableData = useVendorServersTableData({
    queryOptions: {
      where: {
        Location: {
          is: {
            id: {
              equals: location.id,
            },
          },
        },
      },
    },
    relatedLocation: location,
  });

  return <DataTable {...tableData} />;
};

export default AccessControlTab;
