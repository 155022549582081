import 'styled-components/macro';

import { AccessGrant } from '../../../__generated__/graphql';
import { DetailPageItem } from '../../../templates/DetailPage';
import React from 'react';
import { createButtonsFromDetailItems } from '../../../templates/DetailPage/createButtonsFromDetailItems';
import determineResendInfoButtonIcon from '../functions/determineResendInfoButtonIcon';
import { openConfirmationModal } from '../../../redux/actions/modals.actions';
import { useDispatch } from 'react-redux';
import { useGetEmailButtons } from '../../../hooks/useGetEmailButtons';
import { useParams } from 'react-router-dom';
import useResendConfirmationInfo from '../../../hooks/useResendConfirmationInfo';

interface EmailTabProps {
  accessGrant: Pick<AccessGrant, 'id' | 'tenantId'>;
}

export const EmailsTab: React.FC<EmailTabProps> = ({
  accessGrant: { id, tenantId },
}: EmailTabProps) => {
  const dispatch = useDispatch();

  const { accessGrantId } = useParams<{ accessGrantId: string }>();

  const {
    mutation: resend,
    data: resendData,
    loading: loadingResend,
  } = useResendConfirmationInfo();

  const emailButtons: DetailPageItem[] = [
    {
      disabled: loadingResend,
      icon: determineResendInfoButtonIcon(
        Boolean(resendData?.resendConfirmationInfo.success),
        loadingResend
      ),
      loading: loadingResend,
      onClick: (): void => {
        dispatch(
          openConfirmationModal({
            action: (): void => resend(accessGrantId),
          })
        );
      },
      text: 'Resend Confirmation Info',
    },
    ...useGetEmailButtons({ id, tenantId }),
  ];

  return <div>{createButtonsFromDetailItems(emailButtons)}</div>;
};
