import moment, { tz } from 'moment-timezone';

import { RootState } from '../redux/reducers';
import { useSelector } from 'react-redux';

const defaultDateFormat = 'MM/DD/YYYY';
const defaultTimeFormat = 'hh:mm A';

interface FormatProps {
  timezone?: string;
  format?: string;
}

export interface UseDateTimeFormatterInterface {
  dateFormat: () => string;
  formatDate: (date: string | Date, props?: FormatProps) => string;
  timeFormat: () => string;
  formatTime: (time: string | Date, props?: FormatProps) => string;
  formatDateAndTime: (dateTime: string | Date, props?: FormatProps) => string;
}

const useDateTimeFormatter = (): UseDateTimeFormatterInterface => {
  const dashboardPreferences = useSelector((state: RootState) => state.dashboardPreferences);

  const dateFormat = (): string => {
    return dashboardPreferences?.preferredDateFormat ?? defaultDateFormat;
  };

  const formatDate = (date: string | Date, props?: FormatProps): string => {
    const { format, timezone } = props ?? {};

    if (timezone) {
      return tz(date, timezone).format(format ?? dateFormat());
    }

    return moment(date).format(format ?? dateFormat());
  };

  const timeFormat = (): string => {
    return dashboardPreferences?.preferredTimeFormat ?? defaultTimeFormat;
  };

  const formatTime = (time: string | Date, props?: FormatProps): string => {
    const { format, timezone } = props ?? {};

    if (timezone) {
      return tz(time, timezone).format(format ?? timeFormat());
    }

    return moment(time).format(format ?? timeFormat());
  };

  const formatDateAndTime = (dateTime: string | Date, props?: FormatProps): string => {
    const { format, timezone } = props ?? {};

    const dateTimeformat = format ?? `${dateFormat()} ${timeFormat()}`;

    if (timezone) {
      return tz(dateTime, timezone).format(dateTimeformat);
    }

    return moment(dateTime).format(dateTimeformat);
  };

  return {
    dateFormat,
    formatDate,
    formatDateAndTime,
    formatTime,
    timeFormat,
  };
};

export default useDateTimeFormatter;
