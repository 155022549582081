/* eslint-disable sort-keys */
import Modal from 'react-modal';

const IncomingCallsModalStyle: Modal.Styles = {
  content: {
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    justifySelf: 'center',
    gap: '50px',
    alignSelf: 'center',
    border: '0',
    borderRadius: '0',
    boxShadow: '0',
    height: '525px',
    top: '7.5%',
    padding: '25px',
    zIndex: 1,
  },
  overlay: {
    background: 'rgba(255, 255, 255, 0.1)',
    border: '0',
    outline: '0',
    zIndex: 10,
  },
};

export default IncomingCallsModalStyle;
