import { Colors } from '../../../theme/colors';

const FlashMessageContainerStyle = `
  background: #fff;
  border: 1px solid #000;
  border-radius: 6px;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  grid-template-columns: max-content 1fr;
  padding: 10px 15px;

  a {
    color: ${Colors.slateBlue};
    font-weight: bold;
    text-decoration: underline;
  }
`;

export default FlashMessageContainerStyle;
