import DashboardPreferencesReduxState from '../../@types/DashboardPreferencesReduxState';
import { SetPreferencesPayload } from '../../../actions/@types/dashboardPreferences/SetPreferencesAction';

export default function setPreferencesAction(
  currentState: DashboardPreferencesReduxState,
  actionPayload: SetPreferencesPayload
): DashboardPreferencesReduxState {
  return {
    ...currentState,
    ...actionPayload,
  };
}
