import 'styled-components/macro';

import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import AsyncSelectInput from '../../../AsyncSelectInput';
import Container from '../../../Container';
import Form from '../../../Form';
import InputGroupLabelStyle from '../../../InputGroup/styles/InputGroupLabel.style';
import Label from '../../../Label';
import RESOURCE_GROUP_INFO_SECTION_INPUTS from './constants/inputs';
import React from 'react';
import ResourceGroupFormInputs from '../../@types/ResourceGroupFormInputs';
import { RootState } from '../../../../redux/reducers';
import SelectOption from '../../../../@types/react/SelectOption';
import SimpleFormSection from '../../../../templates/SimpleFormSection';
import SubmitRow from '../../../SubmitRow';
import { openConfirmationModal } from '../../../../redux/actions/modals.actions';
import useAdjustResourcesMap from '../../../../hooks/util/useAdjustResourcesMap';
import useCreateResourceGroup from '../../../../hooks/useCreateResourceGroup';
import useLoadResourceOptions from '../../../../hooks/useLoadResourceOptions';
import { useParams } from 'react-router-dom';
import useUpdateResourceGroup from '../../../../hooks/useUpdateResourceGroup';

const ResourceGroupInfoSection: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { handleSubmit, watch } = useFormContext();
  const { locationId } = useParams<{ locationId: string }>();
  const id = useSelector((state: RootState) => state.modals.form.id);
  const useMutation = id ? useUpdateResourceGroup : useCreateResourceGroup;

  const { loading, mutation } = useMutation();

  const currentValue = watch('resources.connect') ?? [];

  const { adjustResourcesMap } = useAdjustResourcesMap();

  const submitHandler: SubmitHandler<ResourceGroupFormInputs> = (inputs) => {
    dispatch(
      openConfirmationModal({
        action: (): void => {
          if (id) {
            mutation(inputs, id);
          } else {
            mutation(inputs);
          }
        },
      })
    );
  };

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Container
        css={`
          grid-row-gap: 25px;
        `}
      >
        <SimpleFormSection inputs={[RESOURCE_GROUP_INFO_SECTION_INPUTS[0]]} />
        <Container>
          <Label css={InputGroupLabelStyle}>Resources</Label>
          <AsyncSelectInput
            isMulti
            loadOptions={useLoadResourceOptions(locationId, {
              notIn: currentValue.map((value: SelectOption) => value.label),
            })}
            name="resources.values"
            onChange={adjustResourcesMap}
          />
        </Container>

        <SimpleFormSection inputs={[RESOURCE_GROUP_INFO_SECTION_INPUTS[1]]} />
      </Container>
      <SubmitRow loading={loading} submitText="Submit" />
    </Form>
  );
};

export default ResourceGroupInfoSection;
