import { Datum } from '../../../components/DataTable';
import { Query } from '../../../__generated__/graphql';
import getOnlineStyle from '../../../pages/Kiosk/functions/getOnlineStyle';
import isDispenserOnline from '../../../util/kiosk/isDispenserOnline';
import isKioskOnline from '../../../util/kiosk/isKioskOnline';
import isPaymentTerminalOnline from '../../../util/kiosk/isPaymentTerminalOnline';
import links from '../../../util/links';

type GetKioskTableData = Pick<Query, 'kiosks'>;

export default function generateKiosksTableData(data?: GetKioskTableData): Datum[] {
  const kiosks = data?.kiosks || [];

  const tableData =
    kiosks.map(
      (kiosk): Datum => {
        const kioskIsOnline = isKioskOnline(kiosk);
        const paymentTerminalIsOnline = isPaymentTerminalOnline(kiosk);
        const dispenserIsOnline = isDispenserOnline(kiosk);

        return {
          dataType: 'kiosk',
          fields: [
            {
              key: 'Name',
              link: `${links.paths.KIOSKS}/${kiosk.id}`,
              value: kiosk.name,
            },
            {
              key: 'Location',
              link: `${links.paths.LOCATIONS}/${kiosk.location.id}`,
              value: kiosk.location.name,
            },
            {
              key: 'Status',
              style: getOnlineStyle(kioskIsOnline),
              value: kioskIsOnline ? 'Online' : 'Offline',
            },
            {
              key: 'Payment Terminal',
              style: getOnlineStyle(paymentTerminalIsOnline ?? false),
              value: paymentTerminalIsOnline ? 'Online' : 'Offline',
            },
            {
              key: 'Dispenser',
              style: getOnlineStyle(dispenserIsOnline ?? false),
              value: dispenserIsOnline ? 'Online' : 'Offline',
            },
            {
              key: 'Build Number',
              value: kiosk.buildNumber ?? '-',
            },
            {
              key: 'App Version',
              value: kiosk.metadata?.appVersion ?? '-',
            },
          ],
          key: kiosk.id,
        };
      }
    ) || [];

  return tableData;
}
