import {
  CustomServiceUpdateInput,
  MutationUpdateServiceAndUploadImageFileArgs,
} from '../__generated__/graphql';

import { Mutation } from '../__generated__/graphql';
import MutationHookData from './@types/MutationHookData';
import MutationHookParams from './@types/MutationHookParams';
import UPDATE_SERVICE from '../graphql/updateService';
import { apolloRequirePreflightHeader } from '../client';
import { closeForm } from '../redux/actions/modals.actions';
import { flashApolloError } from '../util/errorUtils';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';

type UpdateServiceData = Pick<Mutation, 'updateServiceAndUploadImageFile'>;

export default function useUpdateServiceAndUploadImage({
  onCompleted = (): void => {},
  onError = flashApolloError,
}: MutationHookParams<UpdateServiceData> = {}): MutationHookData<
  CustomServiceUpdateInput,
  UpdateServiceData
> {
  const dispatch = useDispatch();

  const [update, { data, error, loading }] = useMutation<
    UpdateServiceData,
    MutationUpdateServiceAndUploadImageFileArgs
  >(UPDATE_SERVICE, {
    context: {
      headers: apolloRequirePreflightHeader,
    },
    onCompleted: ({ updateServiceAndUploadImageFile }) => {
      dispatch(closeForm());

      window.flash({
        message: `Service: ${updateServiceAndUploadImageFile.title} updated successfully`,
      });

      onCompleted({ updateServiceAndUploadImageFile });
    },
    onError,
  });

  return {
    data,
    error,
    loading,
    mutation: (inputs: CustomServiceUpdateInput, id = ''): void => {
      update({
        variables: {
          data: inputs,
          where: { id },
        },
      });
    },
  };
}
