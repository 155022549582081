import { gql } from '../../__generated__/gql';

export const USER_RECORDS = gql(`
  query userRecords($orderBy: [UserRecordOrderByWithRelationInput!], $where: UserRecordWhereInput) {
    userRecords(orderBy: $orderBy, where: $where) {
      recordType
      recordId
      tenantId
    }
  }
`);
