import {
  GET_LOCATION_CUSTOMIZEABLE_COMPONENTS,
  GET_TENANT_CUSTOMIZEABLE_COMPONENTS,
} from '../../graphql/getDashboardCustomizeableComponents';

import { CustomizeComponentEnum } from '../../util/jsonEditorUtils/types/CustomizeComponent.enum';
import { flashApolloError } from '../../util/errorUtils';
import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';

export enum GetComponentsEntityTypeEnum {
  TENANT,
  LOCATION,
  KIOSK,
}

export type TGetCustomizeableComponentsData = {
  name: string;
}[];

type TGetTenantCustomizeableComponentsData = {
  getTenantDashboardCustomizeableComponents: TGetCustomizeableComponentsData;
};

type TGetLocationCustomizeableComponentsData = {
  getLocationDashboardCustomizeableComponents: TGetCustomizeableComponentsData;
};

type TGetCustomizeableComponentsArgs = {
  entityId: string;
};

type TUseGetCustomizeableComponentsResponse = {
  isLoading: boolean;
  query(entityId: string): void;
  data?: TGetCustomizeableComponentsData;
};

const useGetTenantCustomizeableComponents = (): TUseGetCustomizeableComponentsResponse => {
  const [getCustomizeableComponents, { called, data, loading }] = useLazyQuery<
    TGetTenantCustomizeableComponentsData,
    TGetCustomizeableComponentsArgs
  >(GET_TENANT_CUSTOMIZEABLE_COMPONENTS, {
    fetchPolicy: 'network-only',
    onError: flashApolloError,
  });

  const query = useCallback(
    (entityId: string) => {
      if (!called) {
        getCustomizeableComponents({
          variables: {
            entityId,
          },
        });
      }
    },
    [called, getCustomizeableComponents]
  );

  return {
    data: data?.getTenantDashboardCustomizeableComponents,
    isLoading: loading,
    query,
  };
};

const useGetLocationCustomizeableComponents = (): TUseGetCustomizeableComponentsResponse => {
  const [getCustomizeableComponents, { called, data, loading }] = useLazyQuery<
    TGetLocationCustomizeableComponentsData,
    TGetCustomizeableComponentsArgs
  >(GET_LOCATION_CUSTOMIZEABLE_COMPONENTS, {
    fetchPolicy: 'network-only',
    onError: flashApolloError,
  });

  const query = useCallback(
    (entityId: string) => {
      if (!called) {
        getCustomizeableComponents({
          variables: {
            entityId,
          },
        });
      }
    },
    [called, getCustomizeableComponents]
  );

  return {
    data: data?.getLocationDashboardCustomizeableComponents,
    isLoading: loading,
    query,
  };
};

const useGetKioskCustomizeableComponents = (): TUseGetCustomizeableComponentsResponse => {
  const data: TGetCustomizeableComponentsData = [
    {
      name: CustomizeComponentEnum.KIOSK,
    },
  ];

  return {
    data,
    isLoading: false,
    query: (): void => {},
  };
};

export const useGetCustomizeableComponents = (
  entityType: GetComponentsEntityTypeEnum
): TUseGetCustomizeableComponentsResponse => {
  const getTenantCustomizeableComponentsHook = useGetTenantCustomizeableComponents();
  const getLocationCustomizeableComponentsHook = useGetLocationCustomizeableComponents();
  const getKioskCustomizeableComponentsHook = useGetKioskCustomizeableComponents();

  switch (entityType) {
    case GetComponentsEntityTypeEnum.TENANT:
      return getTenantCustomizeableComponentsHook;
    case GetComponentsEntityTypeEnum.LOCATION:
      return getLocationCustomizeableComponentsHook;
    case GetComponentsEntityTypeEnum.KIOSK:
      return getKioskCustomizeableComponentsHook;
    default: {
      throw new Error(`Error\n${entityType} is not implemented for useGetCustomizeableComponents`);
    }
  }
};
