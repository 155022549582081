import { expandedVideoCallContainerHeight } from '../../../constants/ExpandedVideoCallModalStyle';
import { minimizedVideoCallContainerHeight } from '../../../constants/MinimizedVideoCallModalStyle';

const ParticipantViewStyle = (isExpanded: boolean): string => `
  justify-content: center;
  align-items: center;
  display: flex;
  height: ${isExpanded ? expandedVideoCallContainerHeight : minimizedVideoCallContainerHeight};
`;

export default ParticipantViewStyle;
