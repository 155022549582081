import { Maybe } from '../../__generated__/graphql';

const buildFieldUpdateOperationsInput = <T>(
  value: T | undefined | null
): Maybe<{ set: T } | undefined> => {
  if (value === null) {
    return null;
  }
  if (value === undefined) {
    return undefined;
  }
  return { set: value };
};

export default buildFieldUpdateOperationsInput;
