import { CallRecordWhereInput, CallType } from '../../../__generated__/graphql';

import { DataTableFilterValues } from '../../../@types/client/DataTableFilterValues';

export default function parseCallRecordQueryParameters(
  fieldsFiltered: DataTableFilterValues[]
): Partial<CallRecordWhereInput> {
  const whereInput: Partial<CallRecordWhereInput> = {};

  if (fieldsFiltered?.length > 0) {
    // Type filter
    const typeFieldsIndex = fieldsFiltered.findIndex((field) => field.fieldKey === 'type');
    if (typeFieldsIndex > -1) {
      const inInput = fieldsFiltered[typeFieldsIndex].values as CallType[];
      whereInput.type = {};
      whereInput.type.in = inInput;
    }
  }

  return whereInput;
}
