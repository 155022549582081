import {
  CreateConciergeItemInput,
  UpdateConciergeItemInput,
} from '../../../../__generated__/graphql';
import { useDispatch, useSelector } from 'react-redux';

import { ApolloError } from '@apollo/client';
import { RootState } from '../../../../redux/reducers';
import SelectOption from '../../../../@types/react/SelectOption';
import { SubmitHandler } from 'react-hook-form';
import _omit from 'lodash/omit';
import { flashApolloError } from '../../../../util/errorUtils';
import { openConfirmationModal } from '../../../../redux/actions/modals.actions';
import useConciergeCrud from './useConciergeCrud';
import { useParams } from 'react-router-dom';

interface UseSubmitInfoItemInterface {
  submitHandler: SubmitHandler<CreateConciergeItemInput | UpdateConciergeItemInput>;
  loadingMutation: boolean;
}

const useSubmitInfoItem = (): UseSubmitInfoItemInterface => {
  const { createConcierge, updateConcierge } = useConciergeCrud();
  const id = useSelector((state: RootState) => state.modals.form.id);
  const dispatch = useDispatch();
  const { locationId } = useParams<{ locationId: string }>();

  // Submit
  const submitHandler: SubmitHandler<CreateConciergeItemInput | UpdateConciergeItemInput> = (
    inputs: CreateConciergeItemInput | UpdateConciergeItemInput
  ): void => {
    try {
      dispatch(
        openConfirmationModal({
          action: (): void => {
            if (id) {
              updateConcierge(
                _omit(
                  {
                    ...inputs,
                    type: ((inputs.type as unknown) as SelectOption)?.value,
                    parentCategory: ((inputs.parentCategory as unknown) as SelectOption)?.value,
                    contentType: ((inputs.contentType as unknown) as SelectOption)?.value,
                  },
                  ['id']
                ) as UpdateConciergeItemInput,
                locationId,
                id
              );
            } else {
              createConcierge(
                _omit(
                  {
                    ...inputs,
                    type: ((inputs.type as unknown) as SelectOption)?.value,
                    parentCategory: ((inputs.parentCategory as unknown) as SelectOption)?.value,
                    contentType: ((inputs.contentType as unknown) as SelectOption)?.value,
                  },
                  ['id']
                ) as CreateConciergeItemInput,
                locationId
              );
            }
          },
        })
      );
    } catch (e) {
      flashApolloError(e as ApolloError);
    }
  };

  return {
    submitHandler,
    loadingMutation: false,
  };
};

export default useSubmitInfoItem;
