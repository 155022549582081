import Theme from '../../../../../theme';

const WaitingCallsContainerStyle = `
  background: ${Theme.colors.darkAlternate.hexCode};
  border-radius: 20px;
  box-shadow: 0px 10px 32px 0px rgba(${Theme.colors.boxShadow.rgb}, 0.91);
  height: 372px;
  margin: 35px 0 0;
  overflow: hidden;
  padding: 30px;
`;

export default WaitingCallsContainerStyle;
