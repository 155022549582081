import { gql } from '../__generated__/gql';

export const GET_CONCIERGE_TREE_VIEW = gql(`
  query GetConciergeTreeView(
    $locationId: String!
  ) {
    getConciergeTreeView(locationId: $locationId) {
      id
      parent
      text
      droppable
      data {
        id
        title
        description
        type
        thumbnailImage
        imageUrl
        moreInfoLink
        contentType
      }
    }
  }
`);

export const UPDATE_CONCIERGE_ITEM_ORDER = gql(`
  mutation UpdateConciergeItemOrder(
    $locationId: String!
    $data: [UpdateConciergeItemOrderInput!]!
  ) {
    updateConciergeItemOrder(locationId: $locationId, data: $data) {
      id
      parent
      order
    }
  }
`);

export const CREATE_CONCIERGE_ITEM = gql(`
    mutation CreateConciergeItem($data: CreateConciergeItemInput!) {
      createConciergeItem(data: $data) {
        title
      }
    }
  `);

export const UPDATE_CONCIERGE_ITEM = gql(`
    mutation UpdateConciergeItem(
      $data: UpdateConciergeItemInput!
      $where: InfoItemWhereInput!
    ) {
      updateConciergeItem(data: $data, where: $where) {
        title
      }
    }
  `);

export const DELETE_CONCIERGE_ITEM = gql(`
    mutation DeleteConciergeItem(
      $where: InfoItemWhereInput!
    ) {
      deleteConciergeItem(where: $where) {
        id
      }
    }
  `);

export const GET_CONCIERGE = gql(`
    query FetchConcierge(
      $locationId: String!
    ) {
      getConcierge(locationId: $locationId) {
        id
        title
        type
        thumbnailImage
        children {
          id
          title
          type
          children {
            id
            title
            type
            thumbnailImage
          }
        }
      }
    }
  `);

const GET_INFO_ITEMS_TABLE_DATA = gql(`
  query GetInfoItemsTableData(
    $orderBy: [InfoItemOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: InfoItemWhereInput
  ) {
    infos(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
      ...InfoItemFields
    }
  }
`);

export default GET_INFO_ITEMS_TABLE_DATA;

export const GET_INFO_ITEM_COUNT = gql(`
  query GetInfoItemCount($where: InfoItemWhereInput!) {
    aggregateInfoItem(where: $where) {
      _count {
        _all
      }
    }
  }
`);
