import { LocationWhereInput } from '../../../__generated__/graphql';
import SearchField from '../../../@types/SearchField';
import createSearchField from '../../../util/tableData/createSearchField';
import transformStringFilter from '../../../util/tableData/transformStringFilter';

const LOCATIONS_SEARCH_FIELDS: SearchField<LocationWhereInput>[] = [
  createSearchField<LocationWhereInput, 'name'>({
    label: 'Name',
    queryField: 'name',
    transform: transformStringFilter,
  }),
];

export default LOCATIONS_SEARCH_FIELDS;
