import { Query, QueryAmenityGroupsArgs, QueryMode, SortOrder } from '../__generated__/graphql';

import SEARCH_AMENITY_GROUPS from '../graphql/searchAmenityGroups';
import SelectOption from '../@types/react/SelectOption';
import { useApolloClient } from '@apollo/client';

type SearchAmenityGroupsData = Pick<Query, 'amenityGroups'>;

export default function useLoadAmenityGroupOptions(): (input: string) => Promise<SelectOption[]> {
  const client = useApolloClient();

  return async (input: string): Promise<SelectOption[]> => {
    const { data } = await client.query<SearchAmenityGroupsData, QueryAmenityGroupsArgs>({
      query: SEARCH_AMENITY_GROUPS,
      variables: {
        orderBy: [
          {
            title: SortOrder.Asc,
          },
        ],
        take: 10,
        where: {
          title: {
            contains: input,
            mode: QueryMode.Insensitive,
          },
        },
      },
    });

    return (
      data.amenityGroups?.map((amenityGroup) => ({
        label: amenityGroup.title,
        value: amenityGroup.id,
      })) ?? []
    );
  };
}
