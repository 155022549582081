import {
  Container,
  InputBox,
  InputWrapper,
  PeriodInfo,
  RelativeInputWrapper,
} from '../styles/GracePeriodInput.styles';
import { GracePeriodType, TimeControlType, TimePeriodType } from '../enums';

import { GracePeriodValues } from '../../../__generated__/graphql';
import InputMask from 'react-input-mask';
import React from 'react';
import ToggleSelector from '../../ToggleSelector';
import { useFormContext } from 'react-hook-form';

interface GracePeriodInputProps {
  controlType: TimeControlType;
}

const GracePeriodInput: React.FC<GracePeriodInputProps> = ({
  controlType,
}: GracePeriodInputProps) => {
  const { register, watch, getValues, errors } = useFormContext<GracePeriodValues>();
  const gracePeriodTypeFieldName = `${controlType}.gracePeriodType`;
  const gracePeriodType = getValues(gracePeriodTypeFieldName);
  watch(gracePeriodTypeFieldName);
  const absoluteTimeInputMask = [/[0-1]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];

  return (
    <Container>
      {gracePeriodType === GracePeriodType.ABSOLUTE ? (
        <InputWrapper error={errors[controlType]?.absoluteTime}>
          <InputMask mask={absoluteTimeInputMask} maskPlaceholder="">
            <input name={`${controlType}.absoluteTime`} ref={register} />
          </InputMask>
          <ToggleSelector.Form
            options={[TimePeriodType.AM, TimePeriodType.PM]}
            name={`${controlType}.absoluteTimePeriod`}
            themeMode="dark"
            widthType="compact"
          />
        </InputWrapper>
      ) : (
        <RelativeInputWrapper>
          <InputBox error={errors[controlType]?.relativeTimeInMinutes}>
            <InputMask mask="999" maskPlaceholder="">
              <input name={`${controlType}.relativeTimeInMinutes`} ref={register} />
            </InputMask>
            <span>mins</span>
          </InputBox>
          <PeriodInfo>{controlType === TimeControlType.CHECK_IN ? 'Before' : 'After'}</PeriodInfo>
        </RelativeInputWrapper>
      )}
    </Container>
  );
};

export default GracePeriodInput;
