import {
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

import { DASHBOARD_SIGN_IN } from '../../graphql/mutations';
import NavigationParams from '../../pages/Login/@types/NavigationParams';
import { SingleSignOnBehavior } from '../../pages/Login/@types/SingleSignOnBehavior';
import { flashApolloError } from '../../util/errorUtils';
import { oktaSignIn } from './functions/oktaSignIn';
import { onTokenReceived } from './functions/onTokenReceived';
import { signInCallback } from './functions/signInCallback';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

type MutationType = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: MutationFunctionOptions<any, OperationVariables> | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;

interface UseSignInReturn {
  loading: boolean;
  oktaSignIn: () => void;
  signIn: MutationType;
}

export default function useSignIn(redirectPath?: string): UseSignInReturn {
  const dispatch = useDispatch();
  const history = useHistory();

  const navigationParams: NavigationParams = {
    dispatch,
    history,
    redirectPath,
  };

  const oktaBehavior: SingleSignOnBehavior = {
    navigationParams,
    onTapSignInButton: oktaSignIn,
  };

  const [signIn, { data: authData, loading }] = useMutation(DASHBOARD_SIGN_IN, {
    onCompleted: ({ dashboardSignIn: { accessToken, refreshToken } }) => {
      signInCallback({ accessToken, refreshToken }, navigationParams);
    },
    onError: flashApolloError,
  });

  useEffect(() => {
    onTokenReceived(authData?.dashboardSignIn);
  }, [authData, dispatch]);

  return {
    loading,
    oktaSignIn: (): void => oktaBehavior.onTapSignInButton(navigationParams),
    signIn,
  };
}
