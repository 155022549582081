import { gql } from '../__generated__/gql';

const GET_USERS_TABLE_DATA = gql(`
  query GetUsersTableData(
    $cursor: UserWhereUniqueInput
    $orderBy: [UserOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: UserWhereInput
  ) {
    users(cursor: $cursor, orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
      email
      id
      lastSeenAt
      memberships {
        locationId
        role
        tenantId
      }
      name
      phoneNumber
    }
  }
`);

export default GET_USERS_TABLE_DATA;
