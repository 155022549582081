import 'styled-components/macro';

import Heading, { HeadingModel } from '../../Heading';
import React, { useContext, useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { BaseLocalizationAnswer } from '../../../__generated__/graphql';
import Button from '../../Button';
import ButtonModel from '../../Button/enums/ButtonModel.enum';
import { Colors } from '../../../theme/colors';
import Container from '../../Container';
import Form from '../../Form';
import FormContext from '../../../contexts/form.context';
import FormCrudAction from '../../../enums/FormCrudAction.enum';
import InputGroup from '../../InputGroup';
import { MdClose } from 'react-icons/md';
import SubmitRow from '../../SubmitRow';
import UnitTypeLanguageLocalization from '../@types/UnitTypeLanguageLocalization';
import generateSimpleFormSectionStyle from '../../../templates/SimpleFormSection/styles/SimpleFormSection.style';
import generateUnitTypeLocalizationInputs from '../functions/generateUnitTypeLocalizationInputs';
import { inputGroupLabelColor } from '../../InputGroup/styles/InputGroupLabel.style';
import useLoadLanguageOptions from '../../../hooks/useLoadLanguageOptions';
import useSubmitUnitType from '../functions/submitUnitType';

const UnitTypeLocalizationSection: React.FC<{}> = () => {
  // Form properties
  const { control, getValues, handleSubmit, setValue } = useFormContext();
  const { identifier } = useContext(FormContext);
  const [originalLocalizations, setOriginalLocalizations] = useState<
    UnitTypeLanguageLocalization[]
  >([]);

  const crudAction = identifier ? FormCrudAction.EDIT : FormCrudAction.CREATE;

  const { loadingMutation, loadingUpsertUnitTypeLocalization, submitHandler } = useSubmitUnitType({
    crudAction,
    originalLocalizations,
  });

  // Fields
  const { append, fields, remove } = useFieldArray({
    control,
    name: 'languages.values',
  });

  useEffect(() => {
    // Load localizations
    const loadedLocalizations = getValues('localizations') as BaseLocalizationAnswer[] | undefined;
    const languagesAdded = getValues('languages.values') as
      | UnitTypeLanguageLocalization[]
      | undefined;
    if (languagesAdded && languagesAdded.length > 0) {
      return;
    }

    if (loadedLocalizations) {
      if (loadedLocalizations.length > 0) {
        const newLocalizations: UnitTypeLanguageLocalization[] = [];

        loadedLocalizations.forEach((_localization) => {
          const name = _localization.settingsData?.name ?? '';
          const description = _localization.settingsData?.description ?? '';

          const newLocalization: UnitTypeLanguageLocalization = {
            description,
            language: {
              id: _localization.language.id,
              isRTL: _localization.language.isRTL ?? false,
              name: `${_localization.language.code} - ${_localization.language.name}`,
            },
            name,
          };
          newLocalizations.push(newLocalization);
          append(newLocalization);
        });
        setOriginalLocalizations(newLocalizations);
      }
    }
  }, [append]);

  const selectedLanguage = getValues('language');
  const addTranslationButtonIsActive = (): boolean => {
    const localizations = getValues('languages')?.values as Array<UnitTypeLanguageLocalization>;
    const languageIsAlreadyAdded = localizations?.find(
      (_language) => _language.language.id === selectedLanguage?.value
    );

    return selectedLanguage && !languageIsAlreadyAdded;
  };

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Container>
        <Container
          css={`
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            gap: 15px;
          `}
        >
          <InputGroup
            key="language-select"
            label="Search Language"
            loadOptions={useLoadLanguageOptions}
            name="language"
            type="async-select"
            style={{
              width: '100%',
            }}
          />
          <Button
            model={ButtonModel.ACTIVE_CTA_DARK}
            active={addTranslationButtonIsActive()}
            disabled={!addTranslationButtonIsActive()}
            onClick={(e: React.SyntheticEvent): void => {
              e.preventDefault();

              if (addTranslationButtonIsActive()) {
                const newLocalization: UnitTypeLanguageLocalization = {
                  description: '',
                  language: {
                    id: selectedLanguage.value,
                    isRTL: selectedLanguage.isRTL,
                    name: selectedLanguage.label,
                  },
                  name: '',
                };
                append(newLocalization);
                setValue('language', undefined);
              }
            }}
          >
            Add translation
          </Button>
        </Container>
        <Container
          css={`
            grid-row-gap: 10px;
          `}
        >
          {fields.map((field, index) => {
            const language = getValues(
              `languages.values[${index}]`
            ) as UnitTypeLanguageLocalization;
            return (
              <div key={`localization-form-${field.id}`}>
                <Heading
                  model={HeadingModel.SECONDARY}
                  css={`
                    margin-top: 15px;
                    color: ${inputGroupLabelColor};
                  `}
                >
                  {language.language.name}
                </Heading>
                <Container
                  css={`
                    align-items: flex-start;
                    grid-auto-flow: column;
                    grid-column-gap: 15px;
                    grid-template-columns: 1fr max-content;
                  `}
                >
                  <Container css={generateSimpleFormSectionStyle({ columns: '1fr 1fr' })}>
                    {generateUnitTypeLocalizationInputs(index).map((input, inputIndex) => (
                      <div
                        key={`div-simple-form-section-input-${input.name}-${inputIndex}`}
                        dir={language.language.isRTL ? 'RTL' : 'LTR'}
                      >
                        <InputGroup
                          key={`simple-form-section-input-${input.name}-${inputIndex}`}
                          {...input}
                        />
                      </div>
                    ))}
                  </Container>

                  <Button
                    background={Colors.freeSpeechRed}
                    css="margin-top: 28px"
                    model={ButtonModel.PASSIVE_ICON_BUTTON}
                    onClick={(e: React.SyntheticEvent): void => {
                      e.preventDefault();

                      remove(index);
                    }}
                  >
                    <MdClose color="#fff" size="16px" />
                  </Button>
                </Container>
              </div>
            );
          })}
        </Container>
      </Container>
      <SubmitRow
        loading={loadingMutation || loadingUpsertUnitTypeLocalization}
        submitText="Submit"
      />
    </Form>
  );
};

export default UnitTypeLocalizationSection;
