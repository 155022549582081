import { Redirect, useLocation } from 'react-router-dom';

import LoadingScreen from '../LoadingScreen';
import Modals from '../Modals';
import React from 'react';
import { RootState } from '../../redux/reducers';
import isTokenExpired from '../../util/isTokenExpired';
import links from '../../util/links';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import useLogoutOfApp from '../../hooks/useLogoutOfApp';
import useMe from '../../hooks/useMe';
import useRefreshToken from '../../hooks/useRefreshToken';
import { useSelector } from 'react-redux';

interface ProtectedRouteProps {
  children: React.ReactElement;
}

export interface RedirectProps {
  previousPath?: string;
  avoidRedirectAfterLogin?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = observer(
  ({ children }: ProtectedRouteProps): React.ReactElement => {
    const location = useLocation();
    const loggedIn = useSelector((state: RootState) => state.auth.accessToken);
    const { attemptTokenRefresh } = useRefreshToken();
    const logout = useLogoutOfApp();
    const userInitialized = useSelector((state: RootState) => state.me.initialized);
    const userLoading = useSelector((state: RootState) => state.me.loading);

    const { getMe, called } = useMe();

    const redirectParams: RedirectProps = {
      previousPath: `${location.pathname}${location.search}`,
    };

    function refresh(): void {
      const tokenExpired = isTokenExpired();

      if (loggedIn && tokenExpired) {
        attemptTokenRefresh();
      } else if (tokenExpired) {
        const { avoidRedirectAfterLogin } = (location.state as RedirectProps) ?? {};
        if (avoidRedirectAfterLogin) {
          logout();
        } else {
          logout(redirectParams);
        }
      }
    }

    /* when the tab becomes active ensure authentication state */
    window.addEventListener('focus', () => {
      refresh();
    });

    useEffect(() => {
      refresh();
    });

    useEffect(() => {
      if (!called && !userLoading) {
        getMe();
      } else if (called && !userLoading && !userInitialized) {
        getMe();
      }
    }, [called, getMe, userInitialized, userLoading]);

    if (userLoading || !userInitialized) {
      return <LoadingScreen />;
    }

    if (!loggedIn) {
      return (
        <Redirect
          to={{
            pathname: links.paths.LOGIN,
            state: redirectParams,
          }}
        />
      );
    }
    return (
      <>
        <Modals />
        {children}
      </>
    );
  }
);

export default ProtectedRoute;
