import 'styled-components/macro';

import React, { useEffect } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';

import Colors from '../../../util/colors';
import Container from '../../Container';
import { FileDownloadIcon } from '../../FileDownloadIcon';
import Form from '../../Form';
import IMPORT_UNIT_TYPE_FROM_CSV_SECTION_INPUTS from '../constants/importUnitTypeFromCSVSectionInputs';
import { MdFileDownload } from 'react-icons/md';
import { SelectLanguageOption } from '../../../hooks/useLoadLanguageOptions';
import SimpleFormSection from '../../../templates/SimpleFormSection';
import SubmitRow from '../../SubmitRow';
import UnitTypeImportFormInputs from '../@types/UnitTypeImportFormInputs';
import { openConfirmationModal } from '../../../redux/actions/modals.actions';
import { useDispatch } from 'react-redux';
import useDownloadUnitTypeCSVTemplate from '../../../hooks/useDownloadUnitTypeCSVTemplate';
import { useParams } from 'react-router-dom';
import useUploadUnitTypes from '../../../hooks/useUploadUnitTypes';

const ImportUnitTypesSection: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { handleSubmit, setValue, watch, getValues } = useFormContext();

  const { locationId } = useParams<{ locationId: string }>();

  const { mutation, loading } = useUploadUnitTypes();

  const csvFile: File = watch('csvFile');

  const submitHandler: SubmitHandler<UnitTypeImportFormInputs> = async (params) => {
    const language = getValues('language') as SelectLanguageOption;
    if (!language) {
      return;
    }

    dispatch(
      openConfirmationModal({
        action: (): void => {
          mutation({
            file: params.unitTypesFile,
            languageId: language.value,
            locationId,
          });
        },
      })
    );
  };

  useEffect(() => {
    if (csvFile) {
      setValue('csvFile', csvFile);
    }
  }, [csvFile, setValue]);

  const { query } = useDownloadUnitTypeCSVTemplate();

  const downloadCSVTemplate = (): void => {
    query();
  };

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Container>
        <Container>
          <SimpleFormSection inputs={IMPORT_UNIT_TYPE_FROM_CSV_SECTION_INPUTS} />
          <SubmitRow loading={loading} submitText="Upload unit types" />
        </Container>
        <Container
          css={`
            margin-top: 10px;
            width: fit-content;
          `}
        >
          <FileDownloadIcon
            color={Colors.green}
            label="Download CSV template"
            icon={<MdFileDownload color="#fff" size="16px" />}
            onClick={(e: React.SyntheticEvent): void => {
              e.preventDefault();
              downloadCSVTemplate();
            }}
          />
        </Container>
      </Container>
    </Form>
  );
};

export default ImportUnitTypesSection;
