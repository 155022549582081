import {
  GracePeriod,
  GracePeriodInput,
  Mutation,
  MutationSetGracePeriodsConfigArgs,
  QueryGetGracePeriodsConfigArgs,
} from '../../__generated__/graphql';

import SET_GRACE_PERIODS_CONFIG from '../../graphql/upsertGracePeriodsConfig';
import recursivelyRemoveTypenameProp from './util/recursivelyRemoveTypenameProp';
import { useMutation } from '@apollo/client';

interface UseSetGracePeriodsData {
  isSubmitting: boolean;
  setGracePeriodsConfigCallback: (data: GracePeriod) => void;
}

type SetGracePeriodsConfigData = Pick<Mutation, 'setGracePeriodsConfig'>;

export default function useSetGracePeriodsData({
  accessGrantId,
  gracePeriodsConfigLevel,
  locationId,
  tenantId,
}: QueryGetGracePeriodsConfigArgs): UseSetGracePeriodsData {
  const [setGracePeriodsConfig, { loading: isSubmitting }] = useMutation<
    SetGracePeriodsConfigData,
    MutationSetGracePeriodsConfigArgs
  >(SET_GRACE_PERIODS_CONFIG, {
    onCompleted: () => {
      window.flash({
        message: 'Grace periods saved successfully',
      });
    },
  });

  const setGracePeriodsConfigCallback = (data: GracePeriod): void => {
    // Doing this to make GraphQL __typename object properties removable.
    recursivelyRemoveTypenameProp(data);

    const variables: MutationSetGracePeriodsConfigArgs = {
      accessGrantId,
      data: data as GracePeriodInput,
      gracePeriodsConfigLevel,
      locationId,
      tenantId,
    };

    setGracePeriodsConfig({ variables });
  };

  return {
    isSubmitting,
    setGracePeriodsConfigCallback,
  };
}
