import React from 'react';
import Theme from '../../theme';

interface SVGProps {
  height?: string;
  fill?: string;
  width?: string;
}

const LogoutIcon: React.FunctionComponent<SVGProps> = ({
  height = '18px',
  fill = Theme.colors.white.hexCode,
  width = '18px',
}: SVGProps) => {
  const viewBox = `0 0 ${width} ${height}`.replace(/px/g, '');

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Logout</title>
      <g id="Dashboard_-1-Copy-12" fill="none" transform="translate(-35.000000, -796.000000)">
        <g id="Group-26">
          <g
            id="Group-29"
            fill={fill}
            fillOpacity="0.55"
            transform="translate(24.075000, 784.799808)"
          >
            <g id="Group-7" transform="translate(11.114974, 11.340000)" fill={fill}>
              <path
                d="M7.313652,10.530567 C7.760691,10.513719 8.136774,10.862424 8.153622,11.309463 C8.191692,12.317265 8.245071,13.052745 8.29764,13.580136 C8.349399,14.099508 8.66295,14.412006 9.099945,14.465466 C9.615348,14.528403 10.342323,14.58 11.34,14.58 C12.337677,14.58 13.064652,14.528403 13.580055,14.465466 C14.016888,14.412006 14.330601,14.099427 14.382279,13.579893 C14.479803,12.601008 14.58,10.909728 14.58,8.1 C14.58,5.2902558 14.479803,3.5990001 14.382279,2.6200908 C14.330601,2.1005973 14.016888,1.7879616 13.580055,1.7345664 C13.064652,1.6715646 12.337677,1.62 11.34,1.62 C10.342323,1.62 9.615348,1.6715565 9.099945,1.7345583 C8.66295,1.7879859 8.349399,2.1004758 8.29764,2.6198397 C8.245071,3.1472145 8.191692,3.8827674 8.153622,4.8905532 C8.136774,5.3375841 7.760691,5.6862972 7.313652,5.6694249 C6.866613,5.6525445 6.517908,5.2764777 6.534756,4.8294468 C6.573798,3.7950444 6.629202,3.0256983 6.685578,2.4592491 C6.804243,1.2684195 7.634493,0.281637 8.903439,0.1265301 C9.494091,0.0543267 10.287648,0 11.34,0 C12.392352,0 13.185909,0.0543267 13.776642,0.1265382 C15.045588,0.2816694 15.875757,1.2689055 15.994341,2.4595083 C16.09875,3.5078508 16.2,5.2556607 16.2,8.1 C16.2,10.944315 16.09875,12.692133 15.994341,13.740516 C15.875757,14.931054 15.045588,15.918363 13.776642,16.073478 C13.185909,16.145649 12.392352,16.2 11.34,16.2 C10.287648,16.2 9.494091,16.145649 8.903439,16.073478 C7.634493,15.918363 6.804243,14.931621 6.685578,13.740759 C6.629202,13.174326 6.573798,12.404988 6.534756,11.370537 C6.517908,10.923498 6.866613,10.547415 7.313652,10.530567 Z"
                id="Path"
              ></path>
              <path
                d="M4.2177591,10.362249 C4.5340803,10.678554 4.5340803,11.191446 4.2177591,11.507751 C3.9014298,11.824056 3.3885702,11.824056 3.0722409,11.507751 L0.2372409,8.672751 C-0.0790803,8.356446 -0.0790803,7.843554 0.2372409,7.527249 L3.0722409,4.6922409 C3.3885702,4.3759197 3.9014298,4.3759197 4.2177591,4.6922409 C4.5340803,5.0085702 4.5340803,5.5214298 4.2177591,5.8377591 L2.7655101,7.29 L10.53,7.29 C10.977363,7.29 11.34,7.652637 11.34,8.1 C11.34,8.547363 10.977363,8.91 10.53,8.91 L2.7655101,8.91 L4.2177591,10.362249 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LogoutIcon;
