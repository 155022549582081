import { ChargingState, Maybe } from '../../../__generated__/graphql';
import {
  MdBattery20,
  MdBattery30,
  MdBattery50,
  MdBattery60,
  MdBattery80,
  MdBattery90,
  MdBatteryCharging20,
  MdBatteryCharging30,
  MdBatteryCharging50,
  MdBatteryCharging60,
  MdBatteryCharging80,
  MdBatteryCharging90,
  MdBatteryFull,
  MdBatteryUnknown,
} from 'react-icons/md';

import { IconType } from 'react-icons';

const getBatteryAndChargingIcon = (
  batteryLevel?: Maybe<number>,
  chargingState?: Maybe<ChargingState>
): IconType => {
  if (chargingState === ChargingState.Full) {
    return MdBatteryFull;
  }

  if (batteryLevel) {
    if (batteryLevel > 90) {
      return chargingState === ChargingState.Charging ? MdBatteryCharging90 : MdBattery90;
    }
    if (batteryLevel > 80) {
      return chargingState === ChargingState.Charging ? MdBatteryCharging80 : MdBattery80;
    }
    if (batteryLevel > 60) {
      return chargingState === ChargingState.Charging ? MdBatteryCharging60 : MdBattery60;
    }
    if (batteryLevel > 50) {
      return chargingState === ChargingState.Charging ? MdBatteryCharging50 : MdBattery50;
    }
    if (batteryLevel > 30) {
      return chargingState === ChargingState.Charging ? MdBatteryCharging30 : MdBattery30;
    }
    if (batteryLevel > 20) {
      return chargingState === ChargingState.Charging ? MdBatteryCharging20 : MdBattery20;
    }
  }

  return MdBatteryUnknown;
};

export default getBatteryAndChargingIcon;
