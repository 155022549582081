import FormCrudAction from '../../../enums/FormCrudAction.enum';
import InputGroupProps from '../../InputGroup/@types/InputGroupProps';
import useLoadLocationOptions from '../../../hooks/useLoadLocationOptions';
import useSearchLocationsByName from '../../../hooks/useSearchLocations';

interface KioskSectionInputsProps {
  formCrudAction: FormCrudAction;
}

export default function getKioskSectionInputs({
  formCrudAction,
}: KioskSectionInputsProps): InputGroupProps[] {
  const KIOSK_SECTION_INPUTS: InputGroupProps[] = [
    {
      label: 'Name',
      name: 'name',
      type: 'text',
    },
    {
      label: 'Search Location',
      loadOptions: useLoadLocationOptions,
      name: 'location',
      query: useSearchLocationsByName,
      type: 'async-select',
    },
    {
      isEditable: false,
      label: 'Code',
      name: 'code',
      type: 'text',
    },
  ];

  if (formCrudAction === FormCrudAction.EDIT) {
    return KIOSK_SECTION_INPUTS.filter(
      (field) => field.isEditable || field.isEditable === undefined
    );
  }

  return KIOSK_SECTION_INPUTS;
}
