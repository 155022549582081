import 'styled-components/macro';

import React, { MouseEventHandler } from 'react';

import Button from '../Button';
import ButtonModel from '../Button/enums/ButtonModel.enum';
import Container from '../Container';
import Label from '../Label';

interface Props {
  color: string;
  label: string;
  icon: React.ReactElement;
  onClick: MouseEventHandler;
}

export const FileDownloadIcon = ({ color, label, icon, onClick }: Props): React.ReactElement => {
  return (
    <Container>
      <Button
        css={`
          justify-self: center;
        `}
        background={color}
        model={ButtonModel.PASSIVE_ICON_BUTTON}
        onClick={onClick}
      >
        {icon}
      </Button>
      <Label margin="3px 0 0 0">{label}</Label>
    </Container>
  );
};
