import React from 'react';
import Theme from '../../theme';

interface SVGProps {
  height?: string;
  fill?: string;
  width?: string;
}

const UserIcon: React.FunctionComponent<SVGProps> = ({
  height = '19px',
  fill = Theme.colors.dark.hexCode,
  width = '16px',
}: SVGProps) => {
  const viewBox = `0 0 ${width} ${height}`.replace(/px/g, '');

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Users</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Dashboard_-1-Copy-12" transform="translate(-36.000000, -557.000000)" fill={fill}>
          <g id="Group-13" transform="translate(24.660000, 547.242577)">
            <g id="Group-10" transform="translate(12.150000, 10.530000)">
              <path
                d="M7.29,9.72 C4.1585481,9.72 1.62,12.25854 1.62,15.39 L1.62,17.01 C1.62,17.457363 1.2573468,17.82 0.81,17.82 C0.3626532,17.82 0,17.457363 0,17.01 L0,15.39 C0,11.363814 3.2638464,8.1 7.29,8.1 C11.316186,8.1 14.58,11.363814 14.58,15.39 L14.58,17.01 C14.58,17.457363 14.217363,17.82 13.77,17.82 C13.322637,17.82 12.96,17.457363 12.96,17.01 L12.96,15.39 C12.96,12.25854 10.42146,9.72 7.29,9.72 Z"
                id="Path"
              ></path>
              <path
                d="M7.29,8.1 C9.079371,8.1 10.53,6.6494034 10.53,4.86 C10.53,3.0705966 9.079371,1.62 7.29,1.62 C5.5005966,1.62 4.05,3.0705966 4.05,4.86 C4.05,6.6494034 5.5005966,8.1 7.29,8.1 Z M7.29,9.72 C9.974097,9.72 12.15,7.544097 12.15,4.86 C12.15,2.1758949 9.974097,0 7.29,0 C4.6058949,0 2.43,2.1758949 2.43,4.86 C2.43,7.544097 4.6058949,9.72 7.29,9.72 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default UserIcon;
