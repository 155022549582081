import Heading, { HeadingModel } from '../../Heading';
import React, { useContext } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';

import AsyncSelectInput from '../../AsyncSelectInput';
import Button from '../../Button';
import ButtonModel from '../../Button/enums/ButtonModel.enum';
import { Colors } from '../../../theme/colors';
import Container from '../../Container';
import CreateUserFormInputs from '../@types/CreateUserFormInputs';
import EditUserFormInputs from '../@types/EditUserFormInputs';
import Form from '../../Form';
import FormContext from '../../../contexts/form.context';
import Label from '../../Label';
import { MdDelete } from 'react-icons/md';
import { Role } from '../../../__generated__/graphql';
import Select from 'react-select';
import SelectOption from '../../../@types/react/SelectOption';
import SubmitRow from '../../SubmitRow';
import UserFormMembershipMap from '../@types/UserFormMembershipMap';
import { openConfirmationModal } from '../../../redux/actions/modals.actions';
import useAdjustMembershipsMap from '../hooks/useAdjustMembershipsMap';
import useCreateUser from '../../../hooks/useCreateUser';
import { useDispatch } from 'react-redux';
import useLoadLocationOptions from '../../../hooks/useLoadLocationOptions';
import useUpdateUser from '../../../hooks/useUpdateUser';
import values from 'lodash/values';

const MembershipSection: React.FC = () => {
  const dispatch = useDispatch();
  const { identifier } = useContext(FormContext);
  const { getValues, handleSubmit, setValue } = useFormContext();

  const VALUES = 'locations.values';

  const hook = identifier ? useUpdateUser : useCreateUser;
  const { loading, mutation } = hook();

  const submitHandler: SubmitHandler<CreateUserFormInputs | EditUserFormInputs> = (
    inputs
  ): void => {
    dispatch(
      openConfirmationModal({
        action: (): void => {
          const mutationInputs: CreateUserFormInputs | EditUserFormInputs = { ...inputs };

          const dashboardPreferencesIsEmpty =
            !mutationInputs.dashboardPreferences ||
            Object.values(mutationInputs.dashboardPreferences).every(
              (field) => field === undefined
            );
          if (dashboardPreferencesIsEmpty || inputs.role === Role.User) {
            mutationInputs.dashboardPreferences = undefined;
          }

          if (identifier) {
            mutation(mutationInputs, identifier);
          } else {
            mutation(mutationInputs);
          }
        },
      })
    );
  };

  const { adjustMembershipRole, adjustMembershipsMap } = useAdjustMembershipsMap();

  const memberships: UserFormMembershipMap = getValues('locations.map');

  const activeMemberships = values(memberships).filter(
    (membership) => membership.shouldCreate || (membership.membershipId && !membership.shouldDelete)
  );

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Container>
        <Container margin="0 0 20px">
          <Heading margin="0 0 20px 0" model={HeadingModel.SECONDARY}>
            Select Locations
          </Heading>
          <AsyncSelectInput
            isMulti
            loadOptions={useLoadLocationOptions()}
            name="locations.values"
            onChange={adjustMembershipsMap}
          />
        </Container>
        {Boolean(activeMemberships.length) && (
          <Container>
            <Heading margin="0 0 20px 0" model={HeadingModel.SECONDARY}>
              Select Roles
            </Heading>
            {activeMemberships.map((membership) => (
              <Container
                key={`membership-list-item-${membership.option.value}`}
                margin="0 0 10px 0"
              >
                <Container
                  alignContent="center"
                  columns="1fr max-content"
                  flow="column"
                  margin="0 0 5px 0"
                >
                  <Label fontSize="16px">{membership.option.label}</Label>
                  <Button
                    borderRadius="6px"
                    color={Colors.freeSpeechRed}
                    height="20px"
                    model={ButtonModel.PASSIVE_ICON_BUTTON}
                    onClick={(): void => {
                      const currentValues: SelectOption[] = getValues(VALUES);

                      const newValues = currentValues.filter(
                        (m) => membership.option.value !== m.value
                      );

                      setValue(VALUES, newValues);

                      adjustMembershipsMap(membership.option, {
                        action: 'remove-value',
                        removedValue: membership.option,
                      });
                    }}
                    width="20px"
                  >
                    <MdDelete size="18px" />
                  </Button>
                </Container>
                <Select
                  onChange={adjustMembershipRole}
                  options={Object.values(Role).map((role) => ({
                    label: `${role}`,
                    value: `${role}.${membership.option.value}`,
                  }))}
                  value={{
                    label: `${membership.withRole}`,
                    value: `${membership.withRole}.${membership.option.value}`,
                  }}
                />
              </Container>
            ))}
          </Container>
        )}
      </Container>
      <SubmitRow loading={loading} submitText="Submit" />
    </Form>
  );
};

export default MembershipSection;
