import { Header } from '../../../components/DataTable/@types/DataTableProps';
import SortType from '../../../components/DataTable/@types/SortType.enum';

const CALL_RECORDS_TABLE_HEADERS: Header[] = [
  { displayValue: 'Access Grant', isSortable: false, value: 'confirmationCode' },
  { displayValue: 'Guest', isSortable: false, value: 'caller.name' },
  { displayValue: 'Agent', isSortable: false, value: 'agent.name' },
  { displayValue: 'Location', isSortable: false, value: 'location.name' },
  { displayValue: 'Status', isSortable: true, sortType: SortType.SortOrder, value: 'status' },
  {
    displayValue: 'Duration',
    isSortable: true,
    sortType: SortType.SortOrderInput,
    value: 'duration',
  },
  { displayValue: 'Type', isSortable: true, sortType: SortType.SortOrder, value: 'type' },
];

export default CALL_RECORDS_TABLE_HEADERS;
