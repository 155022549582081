import { ActionButtonContainer, IconContainer } from '../styles/ActionButton.styles';
import { ActionButtonProps } from '../@types/QuickActionBarTypes';
import { CTAIcon } from './CTAIcon';
import React from 'react';

export const ActionButton: React.FC<ActionButtonProps> = ({
  action,
  ctaType,
  text,
  tooltipText,
  disabled,
  displayTopSeparatorBar,
}: ActionButtonProps) => {
  const confirmAction = (): void => {
    if (!disabled) {
      action();
    }
  };

  return (
    <ActionButtonContainer
      disabled={disabled}
      displayTopSeparatorBar={displayTopSeparatorBar}
      onClick={confirmAction}
    >
      <IconContainer disabled={disabled}>
        <CTAIcon type={ctaType} />
      </IconContainer>
      <span>{text}</span>
      {tooltipText && <span className="abTooltip">{tooltipText}</span>}
    </ActionButtonContainer>
  );
};
