import { DeepMap, FieldError } from 'react-hook-form';
import RESOURCE_VENDOR_SECTION_REQUIRED_FIELDS from '../constants/resourceVendorSectionRequiredFields';
import ResourceFormInputs from '../@types/ResourceFormInputs';
import get from 'lodash/get';

export default function canAccessResourceVendorsSection(
  inputs: Partial<ResourceFormInputs>,
  errors: DeepMap<ResourceFormInputs, FieldError>
): boolean {
  const isFilled = RESOURCE_VENDOR_SECTION_REQUIRED_FIELDS.every((field) => get(inputs, field));

  const strippedFieldNames = RESOURCE_VENDOR_SECTION_REQUIRED_FIELDS.map(
    (field) => field.split('.')[0]
  );

  const hasNoErrors = strippedFieldNames.every(
    (field) => !errors[field as keyof ResourceFormInputs]
  );

  return isFilled && hasNoErrors;
}
