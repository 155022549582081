import 'styled-components/macro';

import Form, { FormValidation, IChangeEvent } from '@rjsf/core';
import React, { useCallback, useEffect, useState } from 'react';
import CustomAutoGeneratedIdWidget from '../widgets/CustomAutoGeneratedIdWidget';

import JSONEditorStyle from './styles/JsonFieldsEditor.style';
import { JSONSchema7 } from 'json-schema';
import _ from 'lodash';

export interface JsonFieldsEditorInterfaceProps {
  schema: JSONSchema7;
  uiSchema: Record<string, unknown>;
  data: Record<string, unknown>;
  onChange: (key: string, event?: IChangeEvent) => void;
  onSubmit: () => Promise<void>;
  validateFormData: (
    key: string,
    formData: Record<string, unknown>,
    errors: FormValidation
  ) => FormValidation;
  children?: React.ReactNode;
  title: string;
  nodeKey: string;
  formReference: React.MutableRefObject<() => void>;
}

const JsonFieldsEditor: React.FC<JsonFieldsEditorInterfaceProps> = ({
  schema: _schema,
  uiSchema: _uiSchema,
  data: _data,
  onChange,
  onSubmit,
  validateFormData,
  nodeKey,
  children,
  formReference,
}: JsonFieldsEditorInterfaceProps): React.ReactElement => {
  const jsonData = _data;
  const uiSchema = _uiSchema;
  const schema = _schema;

  const [jsonFormReference, setJsonFormReference] = useState<Form<
    Record<string, unknown>
  > | null>();

  const validate = useCallback((): Form<Record<string, unknown>> | null => {
    if (jsonFormReference) {
      return jsonFormReference;
    }
    return null;
  }, [jsonFormReference]);

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    formReference.current = validate;
  }, [formReference, validate]);

  const widgets = {
    CustomAutoGeneratedId: CustomAutoGeneratedIdWidget,
  };

  return (
    <Form
      widgets={widgets}
      schema={_.cloneDeep(schema)}
      uiSchema={_.cloneDeep(uiSchema)}
      css={JSONEditorStyle}
      formData={jsonData}
      liveValidate
      showErrorList={false}
      onChange={(event): void => onChange(nodeKey, event)}
      onSubmit={(): Promise<void> => onSubmit()}
      ref={(form): void => {
        setJsonFormReference(form ?? jsonFormReference);
      }}
      validate={(formData, errors): FormValidation => validateFormData(nodeKey, formData, errors)}
    >
      {children}
    </Form>
  );
};

export default JsonFieldsEditor;
