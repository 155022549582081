import { InfoContentType, InfoType } from '../../../../__generated__/graphql';

import { InfoTypeLabel } from '../@types/FormInputs';
import InputGroupProps from '../../../InputGroup/@types/InputGroupProps';
import SelectOption from '../../../../@types/react/SelectOption';

interface InfoItemImageFormInputProps {
  contentType: SelectOption;
  type: SelectOption;
  loadOptions: () => (input: string) => Promise<SelectOption[]>;
}

const getContentTypeDependentField = (key: string) => {
  switch (key) {
    case InfoContentType.ImageAndDescription:
      return [
        {
          label: 'Image URL',
          name: 'imageUrl',
          required: true,
          type: 'url',
        },
        {
          label: 'Description',
          name: 'description',
          required: true,
          type: 'textarea',
        },
        {
          label: 'External URL',
          name: 'moreInfoLink',
          required: true,
          type: 'url',
        },
      ];
    case InfoContentType.PdfViewer:
      return [
        {
          label: 'PDF URL',
          name: 'moreInfoLink',
          required: true,
          type: 'url',
        },
        {
          label: 'Description',
          name: 'description',
          required: true,
          type: 'textarea',
        },
      ];
    case InfoContentType.ExternalUrl:
      return [
        {
          label: 'External URL',
          name: 'moreInfoLink',
          required: true,
          type: 'url',
        },
        {
          label: 'Description',
          name: 'description',
          required: true,
          type: 'textarea',
        },
      ];

    default:
      return [];
  }
};

const getCategoryDependentFields = (key: string, contentType: SelectOption) => {
  if (key === InfoType.InfoItem) {
    return [
      {
        label: 'Content Type',
        name: 'contentType',
        options: [
          { label: 'Image And Description', value: InfoContentType.ImageAndDescription },
          { label: 'PDF Viewer', value: InfoContentType.PdfViewer },
          { label: 'External Url', value: InfoContentType.ExternalUrl },
        ],
        required: true,
        type: 'select',
        defaultValue: InfoContentType.ImageAndDescription,
      },
      ...(getContentTypeDependentField(contentType?.value) as Array<InputGroupProps>),
    ];
  }
  return [];
};

export default function generateInfoItemSectionInputs({
  contentType,
  type,
  loadOptions,
}: InfoItemImageFormInputProps): InputGroupProps[] {
  return [
    {
      label: 'Title',
      name: 'title',
      required: true,
      type: 'text',
    },
    {
      label: 'Type',
      name: 'type',
      options: [
        { label: InfoTypeLabel[InfoType.InfoItem], value: InfoType.InfoItem },
        { label: InfoTypeLabel[InfoType.Category], value: InfoType.Category },
      ],
      required: true,
      type: 'select',
      defaultValue: 'category',
    },
    {
      label: 'Parent Category',
      name: 'parentCategory',
      loadOptions,
      type: 'async-select',
    },

    {
      label: 'Thumbnail Image URL',
      name: 'thumbnailImage',
      required: true,
      type: 'url',
    },
    ...(getCategoryDependentFields(type?.value, contentType) as Array<InputGroupProps>),
  ];
}
