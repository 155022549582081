import { gql } from '../__generated__/gql';

export const DELETE_RESOURCE_GROUP = gql(`
  mutation DeleteResourceGroup($id: String!) {
    deleteResourceGroup(where: { id: $id }) {
      id
      name
    }
  }
`);
