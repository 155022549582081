import { ResourceType, ResourceVendor, ResourceWhereInput } from '../../../__generated__/graphql';

import { DataTableFilterValues } from '../../../@types/client/DataTableFilterValues';

export default function parseResourceQueryParameters(
  fieldsFiltered: DataTableFilterValues[]
): Partial<ResourceWhereInput> {
  const whereInput: Partial<ResourceWhereInput> = {};

  if (fieldsFiltered?.length > 0) {
    // Vendor filter
    const vendorFieldsIndex = fieldsFiltered.findIndex((field) => field.fieldKey === 'v');
    if (vendorFieldsIndex > -1) {
      const inInput = fieldsFiltered[vendorFieldsIndex].values as ResourceVendor[];
      whereInput.vendor = {};
      whereInput.vendor.in = inInput;
    }

    // Type Filter
    const typeFieldsIndex = fieldsFiltered.findIndex((field) => field.fieldKey === 't');
    if (typeFieldsIndex > -1) {
      const inInput = fieldsFiltered[typeFieldsIndex].values as ResourceType[];
      whereInput.type = {};
      whereInput.type.in = inInput;
    }

    // Unit Type Filters
    const unitTypeFieldsIndex = fieldsFiltered.findIndex((field) => field.fieldKey === 'unitType');
    if (unitTypeFieldsIndex > -1) {
      const inInput = fieldsFiltered[unitTypeFieldsIndex].values;

      const undefinedUnitTypeIndex = inInput.indexOf('');
      if (undefinedUnitTypeIndex > -1) {
        whereInput.OR = [
          {
            unitTypeId: null,
          },
          {
            unitTypeId: {
              in: inInput,
            },
          },
        ];
      } else {
        whereInput.unitTypeId = {};
        whereInput.unitTypeId.in = inInput;
      }
    }
  }

  return whereInput;
}
