import { Mutation } from '../__generated__/graphql';
import MutationHookData from './@types/MutationHookData';
import MutationHookParams from './@types/MutationHookParams';
import { MutationUploadResourcesArgs } from '../__generated__/graphql';
import UPLOAD_RESOURCES from '../graphql/uploadResources';
import { apolloRequirePreflightHeader } from '../client';
import { closeForm } from '../redux/actions/modals.actions';
import { flashApolloError } from '../util/errorUtils';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useMutation } from '@apollo/client';

type UploadResourcesData = Pick<Mutation, 'uploadResources'>;

export default function useUploadResources({
  onCompleted = (): void => {},
  onError = flashApolloError,
}: MutationHookParams<UploadResourcesData> = {}): MutationHookData<
  MutationUploadResourcesArgs,
  UploadResourcesData
> {
  const { watch } = useFormContext();
  const dispatch = useDispatch();

  const csvFile: File = watch('csvFile');

  const [mutationUploadResources, { data, error, loading }] = useMutation<
    UploadResourcesData,
    MutationUploadResourcesArgs
  >(UPLOAD_RESOURCES, {
    context: {
      headers: apolloRequirePreflightHeader,
    },
    onCompleted: ({ uploadResources: uploadedResources }) => {
      let message = 'created successfully.';
      if (!uploadedResources || uploadedResources.length === 0) {
        message = 'No resources have been created.';
      } else if (uploadedResources.length > 1) {
        message = `${uploadedResources.length} resources ${message}`;
      } else if (uploadedResources.length === 1) {
        message = `${uploadedResources.length} resource ${message}`;
      }

      window.flash({
        message,
      });

      onCompleted({ uploadResources: uploadedResources });

      dispatch(closeForm());
    },
    onError,
  });

  return {
    data,
    error,
    loading,
    mutation: (input: MutationUploadResourcesArgs): void => {
      mutationUploadResources({
        variables: {
          ...input,
          file: csvFile,
        },
      });
    },
  };
}
