import DefaultRequirementsSection from '../components/DefaultRequirementsSection';
import FormTemplateStep from '../../../templates/Form/@types/FormTemplateStep';
import LocationFormInputs from '../@types/LocationFormInputs';
import LocationSection from '../components/LocationSection';
import { Mutation } from '../../../__generated__/graphql';
import canAccessLocationRequirementsSection from '../functions/canAccessLocationRequirementsSection';

type CreateLocationData = Pick<Mutation, 'createLocation'>;

const LOCATION_FORM_STEPS: FormTemplateStep<LocationFormInputs, CreateLocationData>[] = [
  {
    canBeAccessed: (): boolean => true,
    component: LocationSection,
    name: 'Location',
  },
  {
    canBeAccessed: canAccessLocationRequirementsSection,
    component: DefaultRequirementsSection,
    name: 'Requirements',
  },
];

export default LOCATION_FORM_STEPS;
