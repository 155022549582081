import 'styled-components/macro';

import { MdPerson, MdPhone, MdVerifiedUser } from 'react-icons/md';
import { ObjectMembership, validateAccessByMemberships } from '../../util/authorization';
import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import AccessGrantTab from './components/AccessGrantTab';
import DetailPageTemplate from '../../templates/DetailPage';
import { EntityEnum } from '../../@types/client/DashboardUserRolePermissions';
import PaymentsTab from './components/PaymentsTab';
import { Role } from '../../__generated__/graphql';
import { RootState } from '../../redux/reducers';
import UserRecordsTab from './components/UserRecordsTab';
import links from '../../util/links';
import { openForm } from '../../redux/actions/modals.actions';
import useGetUserById from '../../hooks/useGetUserById';

const UserPage: React.FC<{}> = () => {
  const membership = useSelector((state: RootState) => state.me.activeMembership);
  const dispatch = useDispatch();
  const { userId } = useParams<{ userId: string }>();

  const {
    called: loadUserIsCalled,
    data: user,
    query: loadUser,
    loading: userIsLoading,
  } = useGetUserById();

  useEffect(() => {
    if (!loadUserIsCalled) {
      loadUser(userId);
    }
  }, [loadUserIsCalled]);

  if (!user || userIsLoading) {
    return (
      <DetailPageTemplate
        dataType="User"
        detailItems={[]}
        identifier={userId}
        permission={EntityEnum.USER}
        primaryHeader=""
        secondaryHeader="User Details"
        tabs={[]}
      />
    );
  }

  try {
    const userMemberships = user.memberships.map(
      (userMembership): ObjectMembership => {
        return {
          locationId: userMembership.locationId,
          tenantId: userMembership.tenantId,
        };
      }
    );
    validateAccessByMemberships({
      membership,
      objectMemberships: userMemberships,
      validateLocation: false,
    });
  } catch {
    return <Redirect to={links.paths.USERS} />;
  }

  return (
    <DetailPageTemplate
      dataType="User"
      detailItems={[
        {
          icon: MdVerifiedUser,
          label: 'Email',
          text: user.email?.length ? user.email : 'No Email Provided',
        },
        {
          icon: MdPhone,
          label: 'Phone',
          text: user.phoneNumber ?? 'No Phone Provided',
        },
        {
          icon: MdPerson,
          onClick: (): void => {
            dispatch(openForm('jumioID', userId));
          },
          text: 'Press to see ID',
        },
      ]}
      identifier={userId}
      onEditButtonClick={(): void => {
        dispatch(openForm('user', userId));
      }}
      permission={EntityEnum.USER}
      primaryHeader={user.name ?? user.id}
      secondaryHeader="User Details"
      tabs={[
        {
          Component: AccessGrantTab,
          id: 'Access Grants',
        },
        ...(membership.role === Role.Admin
          ? [
              {
                Component: PaymentsTab,
                id: 'Card on file',
              },
            ]
          : []),
        {
          Component: UserRecordsTab,
          id: 'User Records',
        },
      ]}
    />
  );
};

export default UserPage;
