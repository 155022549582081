import PendingTasksReduxState, { PendingTaskInterface } from '../../@types/PendingTasksReduxState';

import { CredentialBeingProcessedPayload } from '../../../actions/@types/pendingTasks/CredentialBeingProcessedAction';

export default function credentialBeingProcessedAction(
  currentState: PendingTasksReduxState,
  actionPayload: CredentialBeingProcessedPayload
): PendingTasksReduxState {
  const { credentialId } = actionPayload;
  const newCredentialsBeingProcessed = [...currentState.credentialsBeingProcessed];

  const credentialIncluded: PendingTaskInterface | undefined = newCredentialsBeingProcessed.find(
    (credential) => credential.credentialId === credentialId
  );
  if (!credentialIncluded) {
    newCredentialsBeingProcessed.push({
      credentialId,
      insertedAt: new Date(Date.now()),
    });
  }

  return {
    ...currentState,
    credentialsBeingProcessed: newCredentialsBeingProcessed,
  };
}
