import { Query, QueryUnitTypesArgs, SortOrder } from '../__generated__/graphql';

import QueryHookData from './@types/QueryHookData';
import QueryHookParams from './@types/QueryHookParams';
import SEARCH_UNIT_TYPES from '../graphql/searchUnitTypes';
import { useLazyQuery } from '@apollo/client';

type SearchUnitTypesData = Pick<Query, 'unitTypes'>;

export default function useSearchUnitTypeByType(params?: QueryHookParams): QueryHookData {
  const { onCompleted = (): void => {}, onError = (): void => {}, searchValue = '' } = params ?? {};

  const [getUnitTypes, { data, error, loading }] = useLazyQuery<
    SearchUnitTypesData,
    QueryUnitTypesArgs
  >(SEARCH_UNIT_TYPES, {
    onCompleted,
    onError,
  });

  const unitTypes = data?.unitTypes ?? [];

  return {
    data: unitTypes.map((unitType) => ({ id: unitType.id, name: unitType.type })),
    error,
    loading,
    query: (type = ''): void => {
      getUnitTypes({
        variables: {
          orderBy: [{ type: SortOrder.Asc }],
          take: 10,
          where: {
            type: { contains: type ?? searchValue },
          },
        },
      });
    },
  };
}
