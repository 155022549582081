import {
  MutationUpsertKioskThemeArgs,
  MutationUpsertLocationThemeArgs,
  MutationUpsertTenantThemeArgs,
} from '../../../__generated__/graphql';

import { CustomizeThemeLocalizationEntityLevel } from '../../../enums/CustomizeThemeLocalizationEntityLevel';
import { NotSupportedError } from '../constants/defaultError';

const retrieveUpsertThemeProperties = (
  componentName: string,
  setting: string,
  entityLevel: CustomizeThemeLocalizationEntityLevel,
  entityId: string
):
  | MutationUpsertTenantThemeArgs
  | MutationUpsertLocationThemeArgs
  | MutationUpsertKioskThemeArgs => {
  switch (entityLevel) {
    case CustomizeThemeLocalizationEntityLevel.TENANT: {
      return {
        componentName,
        setting,
        tenantId: entityId,
      };
    }

    case CustomizeThemeLocalizationEntityLevel.LOCATION: {
      return {
        componentName,
        locationId: entityId,
        setting,
      };
    }

    case CustomizeThemeLocalizationEntityLevel.KIOSK: {
      return {
        componentName,
        kioskId: entityId,
        setting,
      };
    }

    default:
      throw NotSupportedError(entityLevel);
  }
};

export default retrieveUpsertThemeProperties;
