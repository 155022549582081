import Theme from '../../../../../../../theme';

const CallRecordsListStyle = `
  li {
    align-items: center;
    border-bottom: 1px solid ${Theme.colors.borderTable.hexCode};
    display: flex;
    padding: 25px 30px;

    &:first-child {
      border-top: 1px solid ${Theme.colors.borderTable.hexCode};
    }
  }

  @media only screen and (max-width: 1200px) {
    li {
      padding: 10px 15px;
    }
  }
`;

export default CallRecordsListStyle;
