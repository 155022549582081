import 'styled-components/macro';
import Container from '../../components/Container';
import InputGroup from '../../components/InputGroup';
import InputGroupProps from '../../components/InputGroup/@types/InputGroupProps';
import React from 'react';
import generateStyle from './styles/SimpleFormSection.style';

interface SimpleFormSectionProps {
  columns?: number | string;
  inputs: InputGroupProps[];
}

const SimpleFormSection: React.FC<SimpleFormSectionProps> = ({
  columns,
  inputs,
}: SimpleFormSectionProps) => {
  return (
    <Container css={generateStyle({ columns })}>
      {inputs.map((input, index) => (
        <InputGroup key={`simple-form-section-input-${input.name}-${index}`} {...input} />
      ))}
    </Container>
  );
};

export default SimpleFormSection;
