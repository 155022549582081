import Theme from '../../theme';
import isValidColorString from '../../util/css/isValidColorString';
import styled from 'styled-components';

export enum HeadingModel {
  PRIMARY = 'firstHeading',
  SECONDARY = 'secondHeading',
  TERTIARY = 'thirdHeading',
  QUARTERNARY = 'fourthHeading',
}

const HEADING_CSS_PROPERTY_MAP = {
  [HeadingModel.PRIMARY]: {
    ...Theme.fontStyles.firstHeading,
  },
  [HeadingModel.SECONDARY]: {
    ...Theme.fontStyles.secondHeading,
  },
  [HeadingModel.TERTIARY]: {
    ...Theme.fontStyles.thirdHeading,
  },
  [HeadingModel.QUARTERNARY]: {
    ...Theme.fontStyles.fourthHeading,
  },
};

export interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  borderColor?: string;
  color?: string;
  fontWeight?: 'bold' | 'normal' | 'light';
  margin?: string;
  model: keyof typeof HEADING_CSS_PROPERTY_MAP;
  textTransform?: 'uppercase' | 'lowercase';
}

const Heading = styled.h3<HeadingProps>`
  border-color: ${({ borderColor }): string => {
    if (borderColor && isValidColorString(borderColor)) {
      return borderColor;
    }

    return 'auto';
  }};
  border-style: solid;
  border-width: 0;
  color: ${({ color }): string => {
    if (color && isValidColorString(color)) {
      return color;
    }

    return Theme.colors.dark.hexCode;
  }};
  font-family: 'San Francisco Pro';
  font-size: ${({ model }): string => {
    return HEADING_CSS_PROPERTY_MAP[model].size;
  }};
  font-weight: ${({ fontWeight }): string => {
    return fontWeight || 'bold';
  }};
  letter-spacing: ${({ model }): string => {
    return HEADING_CSS_PROPERTY_MAP[model].letterSpacing || 'initial';
  }};
  line-height: ${({ model }): string => {
    return HEADING_CSS_PROPERTY_MAP[model].lineHeight;
  }};
  margin: ${({ margin }): string => {
    return margin || '0';
  }};
  padding: 0;
  text-transform: ${({ textTransform }): string => {
    return textTransform || 'initial';
  }};
  white-space: pre-line;

  @media only screen and (max-width: 1450px) {
    font-size: ${({ model }): string => {
      const cssProperties = HEADING_CSS_PROPERTY_MAP[model];

      if (cssProperties.responsive['1200px']) {
        return cssProperties.responsive['1200px'].size;
      }

      return cssProperties.size;
    }};
    line-height: ${({ model }): string => {
      const cssProperties = HEADING_CSS_PROPERTY_MAP[model];

      if (cssProperties.responsive['1200px']) {
        return cssProperties.responsive['1200px'].lineHeight;
      }

      return cssProperties.lineHeight;
    }};
  }

  @media only screen and (max-width: 1200px) {
    font-size: ${({ model }): string => {
      const cssProperties = HEADING_CSS_PROPERTY_MAP[model];

      if (cssProperties.responsive['1200px']) {
        return cssProperties.responsive['1200px'].size;
      }

      return cssProperties.size;
    }};
    line-height: ${({ model }): string => {
      const cssProperties = HEADING_CSS_PROPERTY_MAP[model];

      if (cssProperties.responsive['1200px']) {
        return cssProperties.responsive['1200px'].lineHeight;
      }

      return cssProperties.lineHeight;
    }};
  }
`;

Heading.displayName = 'Heading';

export default Heading;
