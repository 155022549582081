import { Header } from '../../../components/DataTable/@types/DataTableProps';
import SortType from '../../../components/DataTable/@types/SortType.enum';

const RESOURCES_TABLE_HEADERS: Header[] = [
  { displayValue: 'Name', isSortable: true, sortType: SortType.SortOrder, value: 'name' },
  {
    displayValue: 'Description',
    isSortable: true,
    sortType: SortType.SortOrderInput,
    value: 'description',
  },
  { displayValue: 'Unit Type', isSortable: false },
  { displayValue: 'Vendor', isSortable: true, sortType: SortType.SortOrder, value: 'vendor' },
  { displayValue: 'Vendor ID', isSortable: false },
  { displayValue: 'PMS ID', isSortable: false },
  { displayValue: 'Image URL', isSortable: false },
];

export default RESOURCES_TABLE_HEADERS;
