import { SortOrder } from '../../../__generated__/graphql';
import { UserOnAG } from '../../../pages/AccessGrant/@types/GetAccessGrantPageInfo';

export const inverseOrder = (
  orderFunction: (guestA: UserOnAG, guestB: UserOnAG) => number,
  guestA: UserOnAG,
  guestB: UserOnAG
): number => {
  return orderFunction(guestA, guestB) * -1;
};

export const orderByGuestName = (
  guestA: UserOnAG,
  guestB: UserOnAG,
  sortOrder: SortOrder
): number => {
  const nameA = guestA.name || '';
  const nameB = guestB.name || '';

  const order: number = sortOrder === SortOrder.Asc ? 1 : -1;

  return nameA.localeCompare(nameB) * order;
};

export const orderByEmail = (guestA: UserOnAG, guestB: UserOnAG, sortOrder: SortOrder): number => {
  const emailA = guestA.email || '';
  const emailB = guestB.email || '';

  const order: number = sortOrder === SortOrder.Asc ? 1 : -1;

  return emailA.localeCompare(emailB) * order;
};

export const orderByPhoneNumber = (
  guestA: UserOnAG,
  guestB: UserOnAG,
  sortOrder: SortOrder
): number => {
  const phoneA = guestA.phoneNumber || '';
  const phoneB = guestB.phoneNumber || '';

  const order: number = sortOrder === SortOrder.Asc ? 1 : -1;

  return phoneA.localeCompare(phoneB) * order;
};

export const orderByLastSeen = (
  guestA: UserOnAG,
  guestB: UserOnAG,
  sortOrder: SortOrder
): number => {
  const lastSeenA = guestA.lastSeenAt || '';
  const lastSeenB = guestB.lastSeenAt || '';

  const order: number = sortOrder === SortOrder.Asc ? 1 : -1;

  return lastSeenA.localeCompare(lastSeenB) * order;
};
