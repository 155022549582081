import {
  AccessGrantAuthenticationCredentialsStyle,
  BaseModalContainer,
} from '../styles/BaseModal.styles';
import React, { useCallback } from 'react';
import { closeForm, closeQuickActionModal } from '../../../redux/actions/modals.actions';
import BaseModalFooter from './BaseModalFooter';
import BaseModalHeader from './BaseModalHeader';
import { BaseQuickActionModalProps } from '../@types/QuickActionBarTypes';
import Container from '../../Container';

import Modal from 'react-modal';
import { useDispatch } from 'react-redux';

const BaseQuickActionModal: React.FC<BaseQuickActionModalProps> = ({
  children,
  headerTitle,
  ctaType,
  confirmButton,
  cancelButton,
  shouldAllowSoftDismissing,
}) => {
  const dispatch = useDispatch();

  const closeModal = useCallback((): void => {
    dispatch(closeForm());
    dispatch(closeQuickActionModal('quickActionConfirmationModal'));
    dispatch(closeQuickActionModal('quickActionProcessingModal'));
  }, []);

  return (
    <Modal
      isOpen
      onRequestClose={closeModal}
      shouldCloseOnEsc={shouldAllowSoftDismissing !== false}
      shouldCloseOnOverlayClick={shouldAllowSoftDismissing !== false}
      style={AccessGrantAuthenticationCredentialsStyle}
    >
      <BaseModalContainer className="Modal Container">
        {headerTitle && (
          <BaseModalHeader headerTitle={headerTitle} onClose={closeModal} ctaType={ctaType} />
        )}
        <Container style={{ height: '100%' }}>{children}</Container>
        {(confirmButton?.action || cancelButton?.action) && (
          <BaseModalFooter confirmButton={confirmButton} cancelButton={cancelButton} />
        )}
      </BaseModalContainer>
    </Modal>
  );
};

export default BaseQuickActionModal;
