import { gql } from '../__generated__/gql';

const GET_CREDENTIALS_TABLE_DATA = gql(`
  query GetCredentialTableData(
    $orderBy: [CredentialOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: CredentialWhereInput
  ) {
    getCredentials(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
      ...CredentialFields
    }
  }
`);

export default GET_CREDENTIALS_TABLE_DATA;
