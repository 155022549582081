import Theme from '../../../theme';

const ErrorTextStyle = `
  color: ${Theme.colors.errorText.hexCode};
  font-family: 'San Francisco Pro';
  font-size: 24px;
  font-weight: bold;
  padding: 100px 15px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
`;

export default ErrorTextStyle;
