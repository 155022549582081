import { ActionButtonContainer } from '../styles/ActionButton.styles';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Theme from '../../../theme';

export const ActionButtonSkeleton: React.FC = () => {
  const items: Array<string> = Array(6).fill('skeleton');

  return (
    <>
      {items.map((item, idx) => (
        <ActionButtonContainer key={`${item}${idx}`} disabled>
          <Skeleton
            baseColor={Theme.colors.borderDark.hexCode}
            highlightColor={Theme.colors.overlay.hexCode}
            height={45}
            width={45}
            borderRadius={12}
          />
          <Skeleton
            baseColor={Theme.colors.borderDark.hexCode}
            highlightColor={Theme.colors.overlay.hexCode}
            height={15}
            width={70}
            borderRadius={4}
          />
        </ActionButtonContainer>
      ))}
    </>
  );
};
