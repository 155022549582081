import { gql } from '../__generated__/gql';

const GET_LEASE_FILE = gql(`
  query fileToEntities($where: FileToEntityWhereInput!) {
    fileToEntities(where: $where) {
      entityId
      file {
        url
        status
        fileType
      }
    }
  }
`);

export default GET_LEASE_FILE;
