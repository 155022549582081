import React from 'react';
import Theme from '../../theme';

interface SVGProps {
  height?: string;
  width?: string;
  fill?: string;
}

const TenantIcon: React.FunctionComponent<SVGProps> = ({
  height = '200px',
  width = '200px',
  fill = Theme.colors.dark.hexCode,
}: SVGProps) => {
  const viewBox = `0 0 ${width} ${height}`.replace(/px/g, '');

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g fill={fill}>
        <path d="M183.333 175H16.6666V158.333H25V33.3333C25 31.1232 25.8779 29.0036 27.4407 27.4408C29.0035 25.878 31.1232 25 33.3333 25H150C152.21 25 154.33 25.878 155.893 27.4408C157.455 29.0036 158.333 31.1232 158.333 33.3333V75H175V158.333H183.333V175ZM141.667 158.333H158.333V91.6667H108.333V158.333H125V108.333H141.667V158.333ZM141.667 75V41.6667H41.6666V158.333H91.6666V75H141.667ZM58.3333 91.6667H75V108.333H58.3333V91.6667ZM58.3333 125H75V141.667H58.3333V125ZM58.3333 58.3333H75V75H58.3333V58.3333Z" />
      </g>
    </svg>
  );
};

export default TenantIcon;
