import { gql } from '../__generated__/gql';

const UPDATE_AMENITY = gql(`
  mutation UpdateAmenityAndUploadImageFile(
    $data: CustomAmenityUpdateInput!
    $where: AmenityWhereUniqueInput!
  ) {
    updateAmenityAndUploadImageFile(data: $data, where: $where) {
      id
      title
      imageUrl
      description
      group {
        id
        title
      }
      location {
        id
        name
      }
    }
  }
`);

export default UPDATE_AMENITY;
