import React from 'react';
import Theme from '../../theme';

interface SVGProps {
  height?: string;
  fill?: string;
  width?: string;
}

const ShareIcon: React.FunctionComponent<SVGProps> = ({
  height = '16px',
  fill = Theme.colors.dark.hexCode,
  width = '19px',
}: SVGProps) => {
  const viewBox = `0 0 ${width} ${height}`.replace(/px/g, '');

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Kiosks</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Dashboard_-1-Copy-12" transform="translate(-35.000000, -418.000000)" fill={fill}>
          <g id="Group-19" transform="translate(24.660000, 406.768385)">
            <g id="Group-5" transform="translate(10.530000, 12.150000)">
              <path
                d="M0,2.43 C0,1.0879515 1.0879515,0 2.43,0 L15.39,0 C16.732089,0 17.82,1.0879515 17.82,2.43 L17.82,10.53 C17.82,11.872089 16.732089,12.96 15.39,12.96 L14.58,12.96 C14.132637,12.96 13.77,12.597363 13.77,12.15 C13.77,11.702637 14.132637,11.34 14.58,11.34 L15.39,11.34 C15.837363,11.34 16.2,10.977363 16.2,10.53 L16.2,2.43 C16.2,1.9826532 15.837363,1.62 15.39,1.62 L2.43,1.62 C1.9826532,1.62 1.62,1.9826532 1.62,2.43 L1.62,10.53 C1.62,10.977363 1.9826532,11.34 2.43,11.34 L3.24,11.34 C3.6873468,11.34 4.05,11.702637 4.05,12.15 C4.05,12.597363 3.6873468,12.96 3.24,12.96 L2.43,12.96 C1.0879515,12.96 0,11.872089 0,10.53 L0,2.43 Z"
                id="Path"
              ></path>
              <path
                d="M5.7748464,13.299228 L8.250822,9.832752 C8.573931,9.380529 9.246069,9.380529 9.569097,9.832752 L12.045105,13.299228 C12.428073,13.835286 12.044862,14.58 11.386008,14.58 L6.4339677,14.58 C5.775138,14.58 5.3919027,13.835286 5.7748464,13.299228 Z"
                id="Path"
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ShareIcon;
