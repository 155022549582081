import {
  MembershipCreateWithoutUserInput,
  MembershipUpdateManyWithWhereWithoutUserInput,
  StringFilter,
} from '../../../__generated__/graphql';

import UserFormMembershipMap from '../../../components/UserForm/@types/UserFormMembershipMap';
import generateMembershipWithoutUserInput from './generateMembershipWithoutUserInput';

export default function generateMembershipInputs(
  memberships: UserFormMembershipMap,
  tenantId: string
): {
  create: MembershipCreateWithoutUserInput[];
  delete: Array<{ id: StringFilter }>;
  update: MembershipUpdateManyWithWhereWithoutUserInput[];
} {
  const membershipsToCreate: MembershipCreateWithoutUserInput[] = [];
  const membershipsToDelete: Array<{ id: StringFilter }> = [];
  const membershipsToUpdate: MembershipUpdateManyWithWhereWithoutUserInput[] = [];

  Object.keys(memberships).forEach((locationId) => {
    const membership = memberships[locationId];
    const id = membership.membershipId;

    if (membership.shouldCreate) {
      membershipsToCreate.push(generateMembershipWithoutUserInput(membership, tenantId));
    } else if (membership.shouldDelete) {
      if (id) {
        membershipsToDelete.push({ id: { equals: id } });
      }
    } else if (membership.shouldUpdate) {
      if (id) {
        membershipsToUpdate.push({
          data: {
            role: { set: membership.withRole },
          },
          where: {
            id: { equals: id },
          },
        });
      }
    }
  });

  return {
    create: membershipsToCreate,
    delete: membershipsToDelete,
    update: membershipsToUpdate,
  };
}
