import DataTablePage from '../../templates/DataTablePage/DataTablePage';
import React from 'react';
import useLocationsTableData from '../../hooks/useLocationsTableData';

function LocationsPage(): React.ReactElement {
  const tableProps = useLocationsTableData({});
  return <DataTablePage bannerText="Locations" {...tableProps} />;
}

export default LocationsPage;
