import { isArray, isEmpty, isEqual, isObject, transform } from 'lodash';

const differenceBetweenObjects = (
  objectA: Record<string, unknown>,
  objectB: Record<string, unknown>
): Record<string, unknown> => {
  if (isEmpty(objectA)) {
    const returnValue = { ...objectB };
    return {
      ...returnValue,
    };
  }

  if (isEmpty(objectB)) {
    return {};
  }

  const changes = (
    childObjectB: Record<string, unknown>,
    childObjectA: Record<string, unknown>
  ): Record<string, unknown> => {
    let arrayIndexCounter = 0;

    return transform(
      childObjectB,
      (result: Record<string, unknown>, value: unknown, key: string) => {
        if (!isEqual(value, childObjectA[key])) {
          // eslint-disable-next-line no-plusplus
          const resultKey = isArray(childObjectA) ? arrayIndexCounter++ : key;

          // eslint-disable-next-line no-param-reassign
          result[resultKey] =
            isObject(value) && isObject(childObjectA[key])
              ? changes(
                  value as Record<string, unknown>,
                  childObjectA[key] as Record<string, unknown>
                )
              : value;
        }
      }
    );
  };

  return changes(objectB, objectA);
};

export default differenceBetweenObjects;
