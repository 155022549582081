import { gql } from '../__generated__/gql';

const GET_RESOURCE_NAMES = gql(`
  query GetResourceNames($skip: Int, $take: Int, $where: ResourceWhereInput) {
    resources(skip: $skip, take: $take, where: $where) {
      id
      name
    }
  }
`);

export default GET_RESOURCE_NAMES;
