import { gql } from '../__generated__/gql';

const CREATE_RESOURCE_GROUP = gql(`
  mutation CreateResourceGroup($data: ResourceGroupCreateInput!) {
    createResourceGroup(data: $data) {
      id
      isDefault
      name
      resources {
        id
        name
      }
    }
  }
`);

export default CREATE_RESOURCE_GROUP;
