import { CustomAmenityCreateInput, CustomImageInput } from '../../../__generated__/graphql';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import AmenityFormInputs from '../@types/AmenityFormInputs';
import { ApolloError } from '@apollo/client';
import Form from '../../Form';
import { ImageTypeEnum } from '../../UnitTypeForm/enums/ImageTypeEnum.enum';
import React from 'react';
import { RootState } from '../../../redux/reducers';
import SimpleFormSection from '../../../templates/SimpleFormSection';
import SubmitRow from '../../SubmitRow';
import { flashApolloError } from '../../../util/errorUtils';
import generateAmenitySectionInputs from '../constants/amenitySectionInputs';
import { openConfirmationModal } from '../../../redux/actions/modals.actions';
import useCreateAmenityAndUploadImage from '../../../hooks/useCreateAmenityAndUploadImage';
import useUpdateAmenityAndUploadImage from '../../../hooks/useUpdateAmenityAndUploadImage';

const AmenitySection: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const id = useSelector((state: RootState) => state.modals.form.id);
  const { handleSubmit, setError, watch } = useFormContext();

  const imageType: string = watch('imageType');

  const mutationHook = id ? useUpdateAmenityAndUploadImage : useCreateAmenityAndUploadImage;
  const { loading, mutation } = mutationHook();

  const submitHandler: SubmitHandler<AmenityFormInputs> = (inputs: AmenityFormInputs): void => {
    const { imageFile, imageUrl, ...mutationInputs } = inputs;

    // Image validations
    let imageErrorField;
    if (imageType === ImageTypeEnum.URL && (!imageUrl || imageUrl.length === 0)) {
      imageErrorField = 'imageUrl';
    } else if (imageType === ImageTypeEnum.UPLOAD && !imageFile) {
      imageErrorField = 'imageFile';
    }

    if (imageErrorField) {
      setError(imageErrorField, { message: 'Image is required.', types: { required: true } });
      return;
    }

    try {
      delete mutationInputs.imageType;

      const imageInput: CustomImageInput = {
        imageFile: imageType === ImageTypeEnum.UPLOAD ? imageFile : undefined,
        imageUrl: imageType === ImageTypeEnum.URL ? imageUrl : undefined,
      };

      const amenityCreateInput: CustomAmenityCreateInput = {
        ...mutationInputs,
        group: {
          connect: {
            id: mutationInputs.group.value,
          },
        },
        image: imageInput,
        location: {
          connect: {
            id: mutationInputs.location.value,
          },
        },
      };

      dispatch(
        openConfirmationModal({
          action: (): void => {
            if (id) {
              mutation(amenityCreateInput, id);
            } else {
              mutation(amenityCreateInput);
            }
          },
        })
      );
    } catch (e) {
      flashApolloError(e as ApolloError);
    }
  };

  const AMENITY_SECTION_INPUTS = generateAmenitySectionInputs({ imageType });

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <SimpleFormSection inputs={AMENITY_SECTION_INPUTS} />
      <SubmitRow loading={loading} submitText={id ? 'Update Amenity' : 'Set Amenity'} />
    </Form>
  );
};

export default AmenitySection;
