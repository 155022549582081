import {
  MutationUpsertKioskLocalizationArgs,
  MutationUpsertLocationLocalizationArgs,
  MutationUpsertTenantLocalizationArgs,
} from '../../../__generated__/graphql';

import { CustomizeThemeLocalizationEntityLevel } from '../../../enums/CustomizeThemeLocalizationEntityLevel';
import { NotSupportedError } from '../../CustomizeTheme/constants/defaultError';

const retrieveUpsertLocalizationProperties = (
  componentName: string,
  localization: string,
  languageId: string,
  entityLevel: CustomizeThemeLocalizationEntityLevel,
  entityId: string
):
  | MutationUpsertTenantLocalizationArgs
  | MutationUpsertLocationLocalizationArgs
  | MutationUpsertKioskLocalizationArgs => {
  switch (entityLevel) {
    case CustomizeThemeLocalizationEntityLevel.TENANT: {
      return {
        componentName,
        languageId,
        localization,
        tenantId: entityId,
      };
    }

    case CustomizeThemeLocalizationEntityLevel.LOCATION: {
      return {
        componentName,
        languageId,
        localization,
        locationId: entityId,
      };
    }

    case CustomizeThemeLocalizationEntityLevel.KIOSK: {
      return {
        componentName,
        kioskId: entityId,
        languageId,
        localization,
      };
    }

    default:
      throw NotSupportedError(entityLevel);
  }
};

export default retrieveUpsertLocalizationProperties;
