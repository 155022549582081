import { gql } from '../__generated__/gql';

const SEARCH_UNIT_TYPES = gql(`
  query SearchUnitTypes(
    $orderBy: [UnitTypeOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: UnitTypeWhereInput
  ) {
    unitTypes(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
      id
      type
    }
  }
`);

export default SEARCH_UNIT_TYPES;
