import { RGB } from '../../../../../theme/colors';
import fontSizes from '../../../../../theme/fontSizes';

const SelectTypeLabelStyle = `
  color: rgba(${RGB.comet}, .5);
  display: block;
  font-size: ${fontSizes.bodyLarge};
  font-weight: bold;
  margin: 0 0 15px;
  width: 100%;
`;

export default SelectTypeLabelStyle;
