import PERMISSIONS, { DashboardActionPermissions } from '../util/permissions';

import { EntityEnum } from '../@types/client/DashboardUserRolePermissions';
import { RootState } from '../redux/reducers';
import { merge } from 'lodash';
import { useSelector } from 'react-redux';

type IUseGetPermission = {
  getPermission: (entity: EntityEnum) => DashboardActionPermissions;
};

const useGetPermission = (): IUseGetPermission => {
  const { permissions: rolePermissions, role } = useSelector(
    (state: RootState) => state.me.activeMembership
  );

  const getPermission = (entityWithGeneric: EntityEnum): DashboardActionPermissions => {
    const defaultPermission = PERMISSIONS[entityWithGeneric][role];

    const entity = entityWithGeneric;

    if (!rolePermissions) {
      return defaultPermission;
    }

    const rolePermission = rolePermissions[entity as EntityEnum];

    const permissionValue = merge(defaultPermission, rolePermission);

    return permissionValue;
  };

  return {
    getPermission,
  };
};

export default useGetPermission;
