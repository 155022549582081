/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3ThemePackagesSchema: NodeSchemaProperties = {
  packagesScreen: {
    title: '#7 Packages Screen',
    type: 'object',
    properties: {
      'textColor:enable': {
        description: '[packagesScreen.textColor]',
        title: '#1 Text Color',
        type: 'boolean',
      },
      textColor: {
        type: 'string',
      },
      'backgroundColor:enable': {
        description: '[packagesScreen.backgroundColor]',
        title: '#2 Background Color',
        type: 'boolean',
      },
      backgroundColor: {
        type: 'string',
      },
      'reachMaximumAvailableQuantityImageUrl:enable': {
        description: '[packagesScreen.reachMaximumAvailableQuantityImageUrl]',
        title: '#3 Reach Maximum Available Quantity Image Url',
        type: 'boolean',
      },
      reachMaximumAvailableQuantityImageUrl: {
        type: 'string',
      },
      prebookedText: {
        title: '#4 Prebooked Text',
        type: 'object',
        properties: {
          'textColor:enable': {
            description: '[packagesScreen.prebookedText.textColor]',
            title: '#4.1 Text Color',
            type: 'boolean',
          },
          textColor: {
            type: 'string',
          },
          'fontSize:enable': {
            description: '[packagesScreen.prebookedText.fontSize]',
            title: '#4.2 Font Size',
            type: 'boolean',
          },
          fontSize: {
            type: 'number',
          },
        },
      },
      categoryButton: {
        title: '#5 Category Button',
        type: 'object',
        properties: {
          selected: {
            title: '#5.1 Selected',
            type: 'object',
            properties: {
              'backgroundColor:enable': {
                description: '[packagesScreen.categoryButton.selected.backgroundColor]',
                title: '#5.1.1 Background Color',
                type: 'boolean',
              },
              backgroundColor: {
                type: 'string',
              },
              'textColor:enable': {
                description: '[packagesScreen.categoryButton.selected.textColor]',
                title: '#5.1.2 Text Color',
                type: 'boolean',
              },
              textColor: {
                type: 'string',
              },
            },
          },
          unselected: {
            title: '#5.2 Unselected',
            type: 'object',
            properties: {
              'backgroundColor:enable': {
                description: '[packagesScreen.categoryButton.unselected.backgroundColor]',
                title: '#5.2.1 Background Color',
                type: 'boolean',
              },
              backgroundColor: {
                type: 'string',
              },
              'textColor:enable': {
                description: '[packagesScreen.categoryButton.unselected.textColor]',
                title: '#5.2.2 Text Color',
                type: 'boolean',
              },
              textColor: {
                type: 'string',
              },
            },
          },
        },
      },
      card: {
        title: '#6 Card',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[packagesScreen.card.backgroundColor]',
            title: '#6.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'primaryTextColor:enable': {
            description: '[packagesScreen.card.primaryTextColor]',
            title: '#6.2 Primary Text Color',
            type: 'boolean',
          },
          primaryTextColor: {
            type: 'string',
          },
          'highlightTextColor:enable': {
            description: '[packagesScreen.card.highlightTextColor]',
            title: '#6.3 Highlight Text Color',
            type: 'boolean',
          },
          highlightTextColor: {
            type: 'string',
          },
          addButton: {
            title: '#6.4 Add Button',
            type: 'object',
            properties: {
              'backgroundColor:enable': {
                description: '[packagesScreen.card.addButton.backgroundColor]',
                title: '#6.4.1 Background Color',
                type: 'boolean',
              },
              backgroundColor: {
                type: 'string',
              },
              'textColor:enable': {
                description: '[packagesScreen.card.addButton.textColor]',
                title: '#6.4.2 Text Color',
                type: 'boolean',
              },
              textColor: {
                type: 'string',
              },
              quantity: {
                title: '#6.4.3 Quantity',
                type: 'object',
                properties: {
                  'backgroundColor:enable': {
                    description: '[packagesScreen.card.addButton.quantity.backgroundColor]',
                    title: '#6.4.3.1 Background Color',
                    type: 'boolean',
                  },
                  backgroundColor: {
                    type: 'string',
                  },
                  'textColor:enable': {
                    description: '[packagesScreen.card.addButton.quantity.textColor]',
                    title: '#6.4.3.2 Text Color',
                    type: 'boolean',
                  },
                  textColor: {
                    type: 'string',
                  },
                },
              },
            },
          },
        },
      },
      primaryButton: {
        title: '#7 Primary Button',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[packagesScreen.primaryButton.backgroundColor]',
            title: '#7.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'textColor:enable': {
            description: '[packagesScreen.primaryButton.textColor]',
            title: '#7.2 Text Color',
            type: 'boolean',
          },
          textColor: {
            type: 'string',
          },
          'borderColor:enable': {
            description: '[packagesScreen.primaryButton.borderColor]',
            title: '#7.3 Border Color',
            type: 'boolean',
          },
          borderColor: {
            type: 'string',
          },
          'borderWidth:enable': {
            description: '[packagesScreen.primaryButton.borderWidth]',
            title: '#7.4 Border Width',
            type: 'boolean',
          },
          borderWidth: {
            type: 'number',
          },
          'borderRadius:enable': {
            description: '[packagesScreen.primaryButton.borderRadius]',
            title: '#7.4 Border Radius',
            type: 'boolean',
          },
          borderRadius: {
            type: 'number',
          },
        },
      },
      secondaryButton: {
        title: '#8 Secondary Button',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[packagesScreen.secondaryButton.backgroundColor]',
            title: '#8.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'textColor:enable': {
            description: '[packagesScreen.secondaryButton.textColor]',
            title: '#8.2 Text Color',
            type: 'boolean',
          },
          textColor: {
            type: 'string',
          },
          'borderColor:enable': {
            description: '[packagesScreen.secondaryButton.borderColor]',
            title: '#8.3 Border Color',
            type: 'boolean',
          },
          borderColor: {
            type: 'string',
          },
          'borderWidth:enable': {
            description: '[packagesScreen.secondaryButton.borderWidth]',
            title: '#8.4 Border Width',
            type: 'boolean',
          },
          borderWidth: {
            type: 'number',
          },
          'borderRadius:enable': {
            description: '[packagesScreen.secondaryButton.borderRadius]',
            title: '#8.4 Border Radius',
            type: 'boolean',
          },
          borderRadius: {
            type: 'number',
          },
        },
      },
      modal: {
        title: '#9 Modal',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[packagesScreen.modal.backgroundColor]',
            title: '#9.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'primaryTextColor:enable': {
            description: '[packagesScreen.modal.primaryTextColor]',
            title: '#9.2 Primary Text Color',
            type: 'boolean',
          },
          primaryTextColor: {
            type: 'string',
          },
          'secondaryTextColor:enable': {
            description: '[packagesScreen.modal.secondaryTextColor]',
            title: '#9.3 Secondary Text Color',
            type: 'boolean',
          },
          secondaryTextColor: {
            type: 'string',
          },
          'highlightTextColor:enable': {
            description: '[packagesScreen.modal.highlightTextColor]',
            title: '#9.4 Highlight Text Color',
            type: 'boolean',
          },
          highlightTextColor: {
            type: 'string',
          },
          'lightTextColor:enable': {
            description: '[packagesScreen.modal.lightTextColor]',
            title: '#9.5 Light Text Color',
            type: 'boolean',
          },
          lightTextColor: {
            type: 'string',
          },
          'closeIcon:enable': {
            description: '[packagesScreen.modal.closeIcon]',
            title: '#9.6 Close Icon',
            type: 'boolean',
          },
          closeIcon: {
            type: 'string',
          },
          'resultImageHeight:enable': {
            description: '[packagesScreen.modal.resultImageHeight]',
            title: '#9.7 Result Image Height',
            type: 'boolean',
          },
          resultImageHeight: {
            type: 'number',
          },
          'resultImageWidth:enable': {
            description: '[packagesScreen.modal.resultImageWidth]',
            title: '#9.8 Result Image Width',
            type: 'boolean',
          },
          resultImageWidth: {
            type: 'number',
          },
          counter: {
            title: '#9.9 Counter',
            type: 'object',
            properties: {
              'textColor:enable': {
                description: '[packagesScreen.modal.counter.textColor]',
                title: '#9.9.1 Text Color',
                type: 'boolean',
              },
              textColor: {
                type: 'string',
              },
              'minusIcon:enable': {
                description: '[packagesScreen.modal.counter.minusIcon]',
                title: '#9.9.2 Minus Icon',
                type: 'boolean',
              },
              minusIcon: {
                type: 'string',
              },
              'plusIcon:enable': {
                description: '[packagesScreen.modal.counter.plusIcon]',
                title: '#9.9.3 Plus Icon',
                type: 'boolean',
              },
              plusIcon: {
                type: 'string',
              },
            },
          },
        },
      },
      'dividerColor:enable': {
        description: '[packagesScreen.dividerColor]',
        title: '#10 Divider Color',
        type: 'boolean',
      },
      dividerColor: {
        type: 'string',
      },
      success: {
        title: '#11 Success',
        type: 'object',
        properties: {
          'imageUrl:enable': {
            description: '[packagesScreen.success.imageUrl]',
            title: '#11.1 Image Url',
            type: 'boolean',
          },
          imageUrl: {
            type: 'string',
          },
        },
      },
      fail: {
        title: '#12 Fail',
        type: 'object',
        properties: {
          'imageUrl:enable': {
            description: '[packagesScreen.fail.imageUrl]',
            title: '#12.1 Image Url',
            type: 'boolean',
          },
          imageUrl: {
            type: 'string',
          },
        },
      },
      cart: {
        title: '#13 Cart',
        type: 'object',
        properties: {
          'emptyCartImageUrl:enable': {
            description: '[packagesScreen.cart.emptyCartImageUrl]',
            title: '#13.1 Empty Cart Image Url',
            type: 'boolean',
          },
          emptyCartImageUrl: {
            type: 'string',
          },
          'emptyCartImageWidth:enable': {
            description: '[packagesScreen.cart.emptyCartImageWidth]',
            title: '#13.2 Empty Cart Image Width',
            type: 'boolean',
          },
          emptyCartImageWidth: {
            type: 'number',
          },
          'emptyCartImageHeight:enable': {
            description: '[packagesScreen.cart.emptyCartImageHeight]',
            title: '#13.3 Empty Cart Image Height',
            type: 'boolean',
          },
          emptyCartImageHeight: {
            type: 'number',
          },
          'removeIcon:enable': {
            description: '[packagesScreen.cart.removeIcon]',
            title: '#13.4 Remove Icon',
            type: 'boolean',
          },
          removeIcon: {
            type: 'string',
          },
          'minusIcon:enable': {
            description: '[packagesScreen.cart.minusIcon]',
            title: '#13.5 Minus Icon',
            type: 'boolean',
          },
          minusIcon: {
            type: 'string',
          },
          'plusIcon:enable': {
            description: '[packagesScreen.cart.plusIcon]',
            title: '#13.6 Plus Icon',
            type: 'boolean',
          },
          plusIcon: {
            type: 'string',
          },
        },
      },
      'cannotRemovePrebookedImageUrl:enable': {
        description: '[packagesScreen.cannotRemovePrebookedImageUrl]',
        title: '#14 Cannot Remove Prebooked Image Url',
        type: 'boolean',
      },
      cannotRemovePrebookedImageUrl: {
        type: 'string',
      },
      'hideInactivePackagesPrice:enable': {
        description: '[packagesScreen.hideInactivePackagesPrice]',
        title: '#15 Hide Inactive Packages Price',
        type: 'boolean',
      },
      hideInactivePackagesPrice: {
        type: undefined,
        enum: [true, false],
      },
    },
  },
};

export const mobileV3ThemePackagesUiSchema: NodeUiSchemaProperties = {
  packagesScreen: {
    textColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    backgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    reachMaximumAvailableQuantityImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    prebookedText: {
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      fontSize: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    categoryButton: {
      selected: {
        backgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
      unselected: {
        backgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
    },
    card: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      primaryTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      highlightTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      addButton: {
        backgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        quantity: {
          backgroundColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          textColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
        },
      },
    },
    primaryButton: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderWidth: {
        'ui:label': false,
        'ui:readonly': true,
      },
      borderRadius: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    secondaryButton: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderWidth: {
        'ui:label': false,
        'ui:readonly': true,
      },
      borderRadius: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    modal: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      primaryTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      secondaryTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      highlightTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      lightTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      closeIcon: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
      resultImageHeight: {
        'ui:label': false,
        'ui:readonly': true,
      },
      resultImageWidth: {
        'ui:label': false,
        'ui:readonly': true,
      },
      counter: {
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        minusIcon: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'link',
          'ui:widget': 'uri',
        },
        plusIcon: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'link',
          'ui:widget': 'uri',
        },
      },
    },
    dividerColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    success: {
      imageUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
    },
    fail: {
      imageUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
    },
    cart: {
      emptyCartImageUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
      emptyCartImageWidth: {
        'ui:label': false,
        'ui:readonly': true,
      },
      emptyCartImageHeight: {
        'ui:label': false,
        'ui:readonly': true,
      },
      removeIcon: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
      minusIcon: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
      plusIcon: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
    },
    cannotRemovePrebookedImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    hideInactivePackagesPrice: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
  },
};
