import Filter from '../../../../components/DataTable/@types/Filter';
import capitalize from 'lodash/capitalize';

function addIsDefaultFilters(filters: Filter[]): void {
  [true, false].forEach((value) => {
    filters.push({
      displayKey: 'Is default',
      displayValue: capitalize(String(value)),
      key: 'isDefault',
      value: String(value),
    });
  });
}

export default function useResourceGroupFilters(): Filter[] {
  const filters: Filter[] = [];

  addIsDefaultFilters(filters);

  return filters;
}
