import Theme from '../../../theme';

const SidebarContainerStyle = `
  background: ${Theme.colors.dark.hexCode};
  height: 100vh;
  position: sticky;
  top: 0;
  width: 165px;

  @media only screen and (max-width: 1200px), 
              screen and (max-height: 900px) {
    width: 85px;    
  }
`;

export default SidebarContainerStyle;
