enum ModalActionEnum {
  CLOSE_ALL = 'CLOSE_ALL',
  CLOSE_FORM = 'CLOSE_FORM',
  CLOSE_MODAL = 'CLOSE_MODAL',
  CLOSE_CONFIRMATION_MODAL = 'CLOSE_CONFIRMATION_MODAL',
  CLOSE_REMOVE_MODAL = 'CLOSE_REMOVE_MODAL',
  CLOSE_QUICK_ACTION_MODAL = 'CLOSE_QUICK_ACTION_MODAL',
  OPEN_ALL = 'OPEN_ALL',
  OPEN_FORM = 'OPEN_FORM',
  OPEN_MODAL = 'OPEN_MODAL',
  OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL',
  OPEN_QUICK_ACTION_CONFIRMATION_MODAL = 'OPEN_QUICK_ACTION_CONFIRMATION_MODAL',
  OPEN_QUICK_ACTION_PROCESSING_MODAL = 'OPEN_QUICK_ACTION_PROCESSING_MODAL',
  OPEN_REMOVE_MODAL = 'OPEN_REMOVE_MODAL',
  RESET_MODAL_STORE = 'RESET_MODAL_STORE',
  TOGGLE_MODAL = 'TOGGLE_MODAL',
}

export default ModalActionEnum;
