import { LocationRelationFilter, TenantRelationFilter } from '../../__generated__/graphql';

import { ReduxMembership } from '../../redux/reducers/@types/MeReduxState';

type MembershipQueryWhere = {
  tenant: TenantRelationFilter;
  location?: LocationRelationFilter;
};

export default function generateMembershipQuery(membership: ReduxMembership): MembershipQueryWhere {
  return {
    ...(membership.location && { location: { is: { id: { equals: membership.location.id } } } }),
    tenant: { is: { id: { equals: membership.tenant.id } } },
  };
}
