import {
  GuestInfoContainer,
  GuestInfoTextContainer,
  GuestInformationBox,
  GuestInformationIcon,
  GuestInformationText,
  GuestInformationTitle,
  MainMessage,
} from '../styles/ActionConfirmationModal.styles';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionBarConfirmationModalReservationIcon } from '../../../assets/icons/actionBarConfirmationModalReservationIcon';
import { ActionBarConfirmationModalUserIcon } from '../../../assets/icons/actionBarConfirmationModalUserIcon';
import { ActionConfirmationModalProps } from '../@types/QuickActionBarTypes';
import BaseQuickActionModal from './BaseQuickActionModal';
import { RootState } from '../../../redux/reducers';
import { closeQuickActionModal } from '../../../redux/actions/modals.actions';
import { useCtaBasedText } from '../hooks/useCtaBasedText';

const ActionConfirmationModal: React.FC<ActionConfirmationModalProps> = ({ ctaType, action }) => {
  const accessGrant = useSelector((state: RootState) => state.accessGrant);
  const dispatch = useDispatch();

  const { title, message } = useCtaBasedText(ctaType);

  const closeModal = useCallback(() => {
    dispatch(closeQuickActionModal('quickActionConfirmationModal'));
  }, []);

  return (
    <BaseQuickActionModal
      headerTitle={title}
      ctaType={ctaType}
      confirmButton={{
        action: (): void => {
          closeModal();
          action();
        },
      }}
      cancelButton={{
        action: (): void => closeModal(),
      }}
    >
      <GuestInformationBox>
        <GuestInfoContainer>
          <GuestInformationIcon>
            <ActionBarConfirmationModalUserIcon />
          </GuestInformationIcon>
          <GuestInfoTextContainer>
            <GuestInformationTitle>Guest Name</GuestInformationTitle>
            <GuestInformationText>{accessGrant.pageInfo?.user.name}</GuestInformationText>
          </GuestInfoTextContainer>
        </GuestInfoContainer>
        <GuestInfoContainer>
          <GuestInformationIcon>
            <ActionBarConfirmationModalReservationIcon />
          </GuestInformationIcon>
          <GuestInfoTextContainer>
            <GuestInformationTitle>Reservation Number</GuestInformationTitle>
            <GuestInformationText>{accessGrant.pageInfo?.confirmationCode}</GuestInformationText>
          </GuestInfoTextContainer>
        </GuestInfoContainer>
      </GuestInformationBox>
      {message && <MainMessage>{message}</MainMessage>}
    </BaseQuickActionModal>
  );
};

export default ActionConfirmationModal;
