import 'styled-components/macro';

import { Colors } from '../../theme/colors';
import Container from '../Container';
import React from 'react';
import TextAreaContainerStyle from './styles/TextAreaContainer.style';
import TextareaAutosize from 'react-textarea-autosize';
import Theme from '../../theme';

export enum TextAreaModel {
  DEFAULT = 'textarea__body_default',
}

export const TEXTAREA_CSS_PROPERTY_MAP = {
  [TextAreaModel.DEFAULT]: {
    background: Colors.slateBlue,
    border: `solid 5px ${Colors.blackPearl}`,
    color: Theme.colors.white.hexCode,
  },
};

export interface TextAreaProps {
  value?: string;
  minRows?: number;
  maxRows?: number;
  background?: string;
  disabled?: boolean;
  className?: string;
  model: keyof typeof TEXTAREA_CSS_PROPERTY_MAP;
  onChange?: (e: React.SyntheticEvent<HTMLTextAreaElement>) => void;
}

const TextArea: React.FunctionComponent<TextAreaProps> = ({ model, ...props }: TextAreaProps) => {
  return (
    <Container background="transparent" css={TextAreaContainerStyle}>
      <TextareaAutosize {...props} />
    </Container>
  );
};

export default TextArea;
