import GET_VENDOR_SERVERS_TABLE_DATA, {
  GET_VENDOR_SERVERS_COUNT,
} from '../../graphql/getVendorServersTableData';
import { Query, QueryVendorServersArgs, VendorServerWhereInput } from '../../__generated__/graphql';
import { useCallback, useEffect, useRef, useState } from 'react';

import { EntityEnum } from '../../@types/client/DashboardUserRolePermissions';
import { LocationPageInfo } from '../../pages/Location/@types/GetLocationPageInfo';
import OrderByParams from '../../@types/client/OrderByParams';
import { RootState } from '../../redux/reducers';
import SearchField from '../../@types/SearchField';
import TableHookData from '../@types/TableHookData';
import TableHookProps from '../@types/TableHookProps';
import VENDOR_SERVERS_TABLE_HEADERS from './constants/vendorServersTableHeaders';
import VENDOR_SERVERS_TABLE_SEARCH_FIELDS from './constants/vendorServersTableSearchFields';
import generateVendorServersTableData from './functions/generateVendorServersTableData';
import parseOrderByDataTableParams from '../../components/DataTable/util/parseOrderByDataTableParams';
import parseVendorServerQueryParameters from './functions/parseVendorServerQueryParameters';
import useGetMany from '../useGetMany';
import useRemoveVendorServer from './hooks/useRemoveVendorServer';
import { useSelector } from 'react-redux';
import useVendorServersFilters from './hooks/useVendorServersFilters';

type VendorServerQueryParams = {
  orderBy?: OrderByParams;
  where: Partial<VendorServerWhereInput>;
};

type GetVendorServerTableData = Pick<Query, 'vendorServers'>;
type AggregateVendorServerQueryData = Pick<Query, 'aggregateVendorServer'>;

interface VendorServerTableData
  extends TableHookProps<QueryVendorServersArgs, VendorServerWhereInput> {
  relatedLocation?: LocationPageInfo;
}

export default function useVendorServersTableData({
  dataTableKey = 'vendorServers',
  formName = 'vendorServer',
  headers = VENDOR_SERVERS_TABLE_HEADERS,
  isSearchable = true,
  queryOptions,
  searchFields = VENDOR_SERVERS_TABLE_SEARCH_FIELDS,
  relatedLocation,
}: VendorServerTableData): TableHookData<VendorServerWhereInput> {
  const options = useRef<Partial<QueryVendorServersArgs>>(queryOptions ?? {});
  const sortOptions = useSelector((state: RootState) => state.dataTable.sortOptions);
  const fieldsFiltered = useSelector((state: RootState) => state.dataTable.filters);
  const filters = useVendorServersFilters();

  const [searchParameters, setSearchParameters] = useState<VendorServerWhereInput>({});

  const { data, error, loading, offset, query, setOffset } = useGetMany<
    GetVendorServerTableData,
    QueryVendorServersArgs
  >({
    graphqlQuery: GET_VENDOR_SERVERS_TABLE_DATA,
  });

  const {
    data: countData,
    error: countError,
    loading: countLoading,
    query: countQuery,
  } = useGetMany<AggregateVendorServerQueryData, QueryVendorServersArgs>({
    graphqlQuery: GET_VENDOR_SERVERS_COUNT,
  });

  const createParams = useCallback((): VendorServerQueryParams => {
    const whereArgs = parseVendorServerQueryParameters(fieldsFiltered[dataTableKey]);
    const sortParams = parseOrderByDataTableParams(sortOptions[dataTableKey]);

    return {
      ...(sortParams && { orderBy: sortParams }),
      where: {
        ...options.current?.where,
        ...searchParameters,
        ...whereArgs,
      },
    };
  }, [options, sortOptions, dataTableKey, searchParameters, fieldsFiltered]);

  const get = useCallback(() => {
    const params = createParams();

    query({
      ...options.current,
      ...(params.orderBy && { orderBy: params.orderBy }),
      skip: options.current?.skip ?? offset,
      take: options.current?.take ?? 10,
      where: params.where,
    });
  }, [createParams, offset, query]);

  const getCount = useCallback(() => {
    const params = createParams();

    countQuery({
      where: params.where,
    });
  }, [countQuery, createParams]);

  useEffect(() => {
    get();
  }, [get]);

  useEffect(() => {
    getCount();
  }, [getCount]);

  return {
    aditionalDataOnOpeningForm: relatedLocation
      ? {
          location: {
            id: relatedLocation.id,
            name: relatedLocation.name,
          },
        }
      : undefined,
    count: countData?.aggregateVendorServer?._count?._all ?? 0,
    data: generateVendorServersTableData(data),
    dataTableKey,
    dataType: 'Vendor Server',
    error: error || countError,
    filters,
    formName,
    headers,
    isLoading: loading || countLoading,
    isSearchable,
    offset,
    permission: EntityEnum.LOCATION,
    remove: useRemoveVendorServer,
    search: (searchField: SearchField, searchValue: string): void => {
      setSearchParameters({
        [searchField.queryField]: searchField.transform(searchValue),
      });

      setOffset(0);
    },
    searchFields,
    setOffset,
  };
}
