import { Mutation } from '../__generated__/graphql';
import MutationHookData from './@types/MutationHookData';
import MutationHookParams from './@types/MutationHookParams';
import { MutationUpsertOrDeleteUnitTypeLocalizationsArgs } from '../__generated__/graphql';
import UPSERT_OR_DELETE_UNIT_TYPE_LOCALIZATIONS from '../graphql/upsertOrDeleteUnitTypeLocalizations';
import { flashApolloError } from '../util/errorUtils';
import { useMutation } from '@apollo/client';

type UpsertOrDeleteUnitTypeLocalizationsData = Pick<
  Mutation,
  'upsertOrDeleteUnitTypeLocalizations'
>;

export default function useUpsertOrDeleteUnitTypeLocalizations({
  onCompleted = (): void => {},
  onError = flashApolloError,
}: MutationHookParams<UpsertOrDeleteUnitTypeLocalizationsData> = {}): MutationHookData<
  MutationUpsertOrDeleteUnitTypeLocalizationsArgs,
  UpsertOrDeleteUnitTypeLocalizationsData
> {
  const [upsertOrDelete, { data, error, loading }] = useMutation<
    UpsertOrDeleteUnitTypeLocalizationsData,
    MutationUpsertOrDeleteUnitTypeLocalizationsArgs
  >(UPSERT_OR_DELETE_UNIT_TYPE_LOCALIZATIONS, {
    onCompleted: ({ upsertOrDeleteUnitTypeLocalizations }) => {
      onCompleted({ upsertOrDeleteUnitTypeLocalizations });
    },
    onError,
  });

  return {
    data,
    error,
    loading,
    mutation: (inputs: MutationUpsertOrDeleteUnitTypeLocalizationsArgs): void => {
      upsertOrDelete({
        variables: inputs,
      });
    },
  };
}
