import { Colors, RGB } from '../../../theme/colors';
import fontSizes from '../../../theme/fontSizes';

interface SelectButtonStyleProps {
  active: boolean;
}

const SelectButtonStyle = ({ active }: SelectButtonStyleProps): string => `
  align-content: center;
  background: ${active ? Colors.mountainMeadow : `rgba(${RGB.brightGrey}, .27)`};
  border-radius: 16px;
  color: ${active ? Colors.white : Colors.blackRussian};
  cursor: pointer;
  font-size: ${fontSizes.bodyLarge};
  font-weight: bold;
  height: 40px;
  justify-items: center;
  position: relative;
  width: 166px;

  input {
    cursor: pointer;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

export default SelectButtonStyle;
