import FormResourceMap from '../../@types/react/FormResourceMap';
import { ResourceWhereUniqueInput } from '../../__generated__/graphql';
import forIn from 'lodash/forIn';

export default function createResourcesInput(
  resources: FormResourceMap
): {
  connect: ResourceWhereUniqueInput[];
  disconnect: ResourceWhereUniqueInput[];
} {
  const resourcesToConnect: ResourceWhereUniqueInput[] = [];
  const resourcesToDisconnect: ResourceWhereUniqueInput[] = [];

  forIn(resources, (value, key) => {
    if (value.shouldDisconnect) {
      resourcesToDisconnect.push({ id: key });
    } else if (value.shouldConnect) {
      resourcesToConnect.push({ id: key });
    }
  });

  return {
    connect: resourcesToConnect,
    disconnect: resourcesToDisconnect,
  };
}
