import { gql } from '../__generated__/gql';

const POLL_RESERVATION_FROM_PMS = gql(`
  mutation PollReservationFromPms(
    $tenantId: String!
    $locationId: String!
    $confirmationCode: String!
  ) {
    pollReservationFromPms(
      tenantId: $tenantId
      locationId: $locationId
      confirmationCode: $confirmationCode
    )
  }
`);

export default POLL_RESERVATION_FROM_PMS;
