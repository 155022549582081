import Container from '../../Container';
import styled from 'styled-components';

export const QRSection = styled.div`
  display: flex;
  justify-content: center;
`;

export const AuthCredentialsGrid = styled(Container)`
  gap: 15px;
`;
