import * as yup from 'yup';

const IMPORT_UNIT_TYPES_FROM_CSV_VALIDATION_SCHEMA = yup.object().shape({
  csvFile: yup.mixed().required('File is required'),
  language: yup.object().shape({
    value: yup.string().required('Language is required'),
  }),
});

export default IMPORT_UNIT_TYPES_FROM_CSV_VALIDATION_SCHEMA;
