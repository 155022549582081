import { ApolloError, DocumentNode } from '@apollo/client';
import { Mutation, MutationEndVideoArgs, QueryCallRecordsArgs } from '../__generated__/graphql';
import { useDispatch, useSelector } from 'react-redux';

import END_VIDEO from '../graphql/endVideo';
import { RootState } from '../redux/reducers';
import Video from 'twilio-video';
import { clearActiveCall } from '../redux/actions/remoteAssistance.actions';
import { closeModals } from '../redux/actions/modals.actions';
import disconnectCall from '../components/VideoCallModal/functions/disconnectCall';
import { useMutation } from '@apollo/client';

type EndVideoMutationData = Pick<Mutation, 'endVideo'>;

interface UseEndCallParameters {
  onCompleted?: (data: EndVideoMutationData) => void;
  onError?: (error: ApolloError) => void;
  refetchQueries?: {
    query: DocumentNode;
    variables: QueryCallRecordsArgs;
  }[];
}

interface EndCallProps {
  callRecordId: string;
  userId: string;
}

interface UseEndCallData {
  data?: EndVideoMutationData;
  endCall: (props: EndCallProps) => void;
  error?: ApolloError;
  loading: boolean;
}

export default function useEndCall(params?: UseEndCallParameters): UseEndCallData {
  const { onCompleted, onError } = params ?? {
    onCompleted: (): void => {},
    onError: (): void => {},
  };

  const dispatch = useDispatch();

  const [endCall, { data, error, loading, called }] = useMutation<
    EndVideoMutationData,
    MutationEndVideoArgs
  >(END_VIDEO, {
    onCompleted,
    onError,
  });

  const activeRoom = useSelector((state: RootState): Video.Room | null => {
    if (called ?? false) {
      return state.remoteAssistance.activeRoom;
    }
    return null;
  });

  const handleEndCall = (variables: EndCallProps): void => {
    disconnectCall(activeRoom);
    dispatch(clearActiveCall());
    dispatch(closeModals(['kioskActions', 'videoCall']));

    endCall({
      variables,
    });
  };

  return {
    data: data || undefined,
    endCall: (variables: EndCallProps): void => handleEndCall(variables),
    error,
    loading,
  };
}
