import { getUserType, getUserTypeName } from '../../useCredentialsTableData/functions/userType';

import { AccessGrantGuests } from '../../../pages/AccessGrant/components/GuestsTab';
import { Datum } from '../../../components/DataTable';
import { UseDateTimeFormatterInterface } from '../../useDateTimeFormatter';
import { UserOnAG } from '../../../pages/AccessGrant/@types/GetAccessGrantPageInfo';
import links from '../../../util/links';

export default function generateGuestsOnAGTableData(
  accessGrant: AccessGrantGuests,
  formatter: UseDateTimeFormatterInterface,
  data?: UserOnAG[],
  locationTimezone?: string
): Datum[] {
  const { formatDate } = formatter;

  if (!data) {
    return [];
  }

  return (
    data.map((guest) => {
      const credentialIndex = accessGrant.credentials.findIndex(
        (credential) => credential.userId === guest.id && credential.isActive
      );

      return {
        dataType: 'Guest',
        fields: [
          {
            key: 'Guest',
            link: `${links.paths.USERS}/${guest.id}`,
            value: guest.name || 'Unknown',
          },
          {
            key: 'Type',
            value: getUserTypeName(
              getUserType({
                accessGrantUserId: accessGrant.user.id,
                credentialUserId: guest.id,
              })
            ),
          },
          {
            key: 'Email',
            value: guest.email || 'Unknown',
          },
          {
            key: 'Phone Number',
            value: guest.phoneNumber || 'Unknown',
          },
          {
            key: 'Has credential',
            value: credentialIndex > -1 ? 'Yes' : 'No',
          },
          {
            key: 'Last seen',
            value: guest.lastSeenAt
              ? formatDate(guest.lastSeenAt, { timezone: locationTimezone })
              : 'N/A',
          },
        ],
        key: guest.id,
      };
    }) || []
  );
  return [];
}
