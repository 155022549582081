import 'styled-components/macro';

import {
  BaseSettingAnswer,
  LanguageInfo,
  Mutation,
  MutationDeleteKioskLocalizationArgs,
  MutationDeleteLocationLocalizationArgs,
  MutationDeleteTenantLocalizationArgs,
  MutationUpsertKioskLocalizationArgs,
  MutationUpsertLocationLocalizationArgs,
  MutationUpsertTenantLocalizationArgs,
  Query,
  QueryGetKioskLocalizationArgs,
  QueryGetLocationLocalizationArgs,
  QueryGetTenantLocalizationArgs,
} from '../../__generated__/graphql';
import React, { useCallback, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import CustomizeJson from '../CustomizeJson';
import { CustomizeThemeLocalizationEntityLevel } from '../../enums/CustomizeThemeLocalizationEntityLevel';
import CustomizeThemeLocalizationInterface from '../../@types/CustomizeThemeLocalizationInterface';
import { NotSupportedError } from '../CustomizeTheme/constants/defaultError';
import { flashApolloError } from '../../util/errorUtils';
import getDeleteQuery from './functions/getDeleteQuery';
import getLoadQuery from './functions/getLoadQuery';
import getUpsertQuery from './functions/getUpsertQuery';
import { jsonDataFromBaseSettingAnswer } from '../CustomizeJson/functions/jsonDataFromBaseSettingAnswer';
import retrieveLocalizationInputProperties from './functions/retrieveLocalizationInputProperties';
import retrieveUpsertLocalizationProperties from './functions/retrieveUpsertLocalizationProperties';

type CustomizeLanguageLocalizationInterface = CustomizeThemeLocalizationInterface;

type DeleteKioskLocalizationData = Pick<Mutation, 'deleteKioskLocalization'>;
type DeleteLocationLocalizationData = Pick<Mutation, 'deleteLocationLocalization'>;
type DeleteTenantLocalizationData = Pick<Mutation, 'deleteTenantLocalization'>;
type GetKioskLocalizationData = Pick<Query, 'getKioskLocalization'>;
type GetLocationLocalizationData = Pick<Query, 'getLocationLocalization'>;
type GetTenantLocalizationData = Pick<Query, 'getTenantLocalization'>;
type UpsertKioskLocalizationData = Pick<Mutation, 'upsertKioskLocalization'>;
type UpsertLocationLocalizationData = Pick<Mutation, 'upsertLocationLocalization'>;
type UpsertTenantLocalizationData = Pick<Mutation, 'upsertTenantLocalization'>;

interface CustomizeLocalizationInterface extends CustomizeLanguageLocalizationInterface {
  language: LanguageInfo;
}

const CustomizeLocalization: React.FC<CustomizeLocalizationInterface> = ({
  language,
  schema,
  uiSchema,
  componentName,
  fileNameToDownloadDefaultJson,
  fileNameToDownloadCurrentJson,
  title,
  entityLevel,
  entityId,
  mockMainJsonNode,
}: CustomizeLocalizationInterface) => {
  const [localization, _setLocalization] = useState<BaseSettingAnswer | undefined>(undefined);
  const setLocalization = (newLocalization: BaseSettingAnswer | undefined): void => {
    let formattedNewLocalization: BaseSettingAnswer | undefined;
    if (newLocalization) {
      try {
        // This is to display the "\n" in the field by replacing \\n with \\\\n
        formattedNewLocalization = JSON.parse(
          JSON.stringify(newLocalization).replaceAll('\\n', '\\\\n')
        );
      } catch (e) {
        formattedNewLocalization = newLocalization;
      }
    } else {
      formattedNewLocalization = newLocalization;
    }
    if (mockMainJsonNode && formattedNewLocalization) {
      _setLocalization({
        mergedSettingsData: {
          general: formattedNewLocalization.mergedSettingsData,
        },
        settingsData: formattedNewLocalization.settingsData
          ? {
              general: formattedNewLocalization.settingsData,
            }
          : undefined,
      });
    } else {
      _setLocalization(formattedNewLocalization);
    }
  };

  // Get - Implementation
  const [getLocalization, { data, loading: loadingQuery }] = useLazyQuery<
    GetTenantLocalizationData | GetLocationLocalizationData | GetKioskLocalizationData,
    | QueryGetTenantLocalizationArgs
    | QueryGetLocationLocalizationArgs
    | QueryGetKioskLocalizationArgs
  >(getLoadQuery(entityLevel), {
    fetchPolicy: 'network-only',
    onError: flashApolloError,
  });

  const load = useCallback(() => {
    getLocalization({
      variables: retrieveLocalizationInputProperties(
        componentName,
        language.id,
        entityLevel,
        entityId
      ),
    });
  }, [getLocalization, componentName, language, entityLevel, entityId]);

  useEffect(() => {
    load();
  }, [load]);

  // Load - Implementation
  if (data && !localization) {
    switch (entityLevel) {
      case CustomizeThemeLocalizationEntityLevel.TENANT: {
        const tenantLocalizationData = data as GetTenantLocalizationData;

        setLocalization({
          mergedSettingsData: tenantLocalizationData.getTenantLocalization.mergedSettingsData,
          settingsData: tenantLocalizationData.getTenantLocalization.settingsData ?? {},
        });
        break;
      }

      case CustomizeThemeLocalizationEntityLevel.LOCATION: {
        const locationLocalizationData = data as GetLocationLocalizationData;

        setLocalization({
          mergedSettingsData: locationLocalizationData.getLocationLocalization.mergedSettingsData,
          settingsData: locationLocalizationData.getLocationLocalization.settingsData ?? {},
        });
        break;
      }

      case CustomizeThemeLocalizationEntityLevel.KIOSK: {
        const kioskLocalizationData = data as GetKioskLocalizationData;

        setLocalization({
          mergedSettingsData: kioskLocalizationData.getKioskLocalization.mergedSettingsData,
          settingsData: kioskLocalizationData.getKioskLocalization.settingsData ?? {},
        });
        break;
      }

      default:
        throw NotSupportedError(entityLevel);
    }
  }

  // Upsert - Implementation
  const onCompleteUpdateLocalization = (): void =>
    window.flash({
      message: `Localization updated successfully`,
    });

  const [upsertLocalization, { loading: loadingUpsertLocalization }] = useMutation<
    UpsertTenantLocalizationData | UpsertLocationLocalizationData | UpsertKioskLocalizationData,
    | MutationUpsertTenantLocalizationArgs
    | MutationUpsertLocationLocalizationArgs
    | MutationUpsertKioskLocalizationArgs
  >(getUpsertQuery(entityLevel), {
    onCompleted: () => onCompleteUpdateLocalization(),
    onError: flashApolloError,
  });

  // Delete - Implementation
  const [deleteLocalization, { loading: loadingDeleteLocalization }] = useMutation<
    DeleteTenantLocalizationData | DeleteLocationLocalizationData | DeleteKioskLocalizationData,
    | MutationDeleteTenantLocalizationArgs
    | MutationDeleteLocationLocalizationArgs
    | MutationDeleteKioskLocalizationArgs
  >(getDeleteQuery(entityLevel), {
    onCompleted: () => onCompleteUpdateLocalization(),
    onError: flashApolloError,
  });

  const onSubmit = async (_localizationDataToUpdate: Record<string, unknown>): Promise<void> => {
    let localizationDataToUpdate = _localizationDataToUpdate;
    if (mockMainJsonNode) {
      localizationDataToUpdate = localizationDataToUpdate.general as Record<string, unknown>;
    }
    const setting = JSON.stringify(localizationDataToUpdate ?? {});

    if (setting === JSON.stringify(localization?.settingsData)) {
      window.flash({
        message: `No changes on updating localization`,
      });
      return;
    }

    const emptyJson = '{}';
    if (setting !== emptyJson) {
      // This is to fix the "\\n" issue by replacing \\\\n with \\n
      const formattedSetting = setting.replaceAll('\\\\n', '\\n');
      const updateLocalizationResult = await upsertLocalization({
        variables: retrieveUpsertLocalizationProperties(
          componentName,
          formattedSetting,
          language.id,
          entityLevel,
          entityId
        ),
      });

      if (updateLocalizationResult && updateLocalizationResult.data) {
        switch (entityLevel) {
          case CustomizeThemeLocalizationEntityLevel.TENANT: {
            // eslint-disable-next-line max-len
            const updateTenantLocalizationResult = updateLocalizationResult.data as UpsertTenantLocalizationData;

            setLocalization({
              mergedSettingsData:
                updateTenantLocalizationResult.upsertTenantLocalization.mergedSettingsData,
              settingsData:
                updateTenantLocalizationResult.upsertTenantLocalization.settingsData ?? {},
            });
            break;
          }

          case CustomizeThemeLocalizationEntityLevel.LOCATION: {
            // eslint-disable-next-line max-len
            const updateLocationLocalizationResult = updateLocalizationResult.data as UpsertLocationLocalizationData;

            setLocalization({
              mergedSettingsData:
                updateLocationLocalizationResult.upsertLocationLocalization.mergedSettingsData,
              settingsData:
                updateLocationLocalizationResult.upsertLocationLocalization.settingsData ?? {},
            });
            break;
          }

          case CustomizeThemeLocalizationEntityLevel.KIOSK: {
            // eslint-disable-next-line max-len
            const updateKioskLocalizationResult = updateLocalizationResult.data as UpsertKioskLocalizationData;

            setLocalization({
              mergedSettingsData:
                updateKioskLocalizationResult.upsertKioskLocalization.mergedSettingsData,
              settingsData:
                updateKioskLocalizationResult.upsertKioskLocalization.settingsData ?? {},
            });
            break;
          }

          default:
            throw NotSupportedError(entityLevel);
        }
      }
    } else if (JSON.stringify(localization?.settingsData) !== emptyJson) {
      const deleteLocalizationResult = await deleteLocalization({
        variables: retrieveLocalizationInputProperties(
          componentName,
          language.id,
          entityLevel,
          entityId
        ),
      });

      if (deleteLocalizationResult && deleteLocalizationResult.data) {
        switch (entityLevel) {
          case CustomizeThemeLocalizationEntityLevel.TENANT: {
            // eslint-disable-next-line max-len
            const deleteTenantLocalizationResult = deleteLocalizationResult.data as DeleteTenantLocalizationData;

            setLocalization({
              mergedSettingsData:
                deleteTenantLocalizationResult.deleteTenantLocalization.mergedSettingsData,
              settingsData:
                deleteTenantLocalizationResult.deleteTenantLocalization.settingsData ?? {},
            });
            break;
          }

          case CustomizeThemeLocalizationEntityLevel.LOCATION: {
            // eslint-disable-next-line max-len
            const deleteLocationLocalizationResult = deleteLocalizationResult.data as DeleteLocationLocalizationData;

            setLocalization({
              mergedSettingsData:
                deleteLocationLocalizationResult.deleteLocationLocalization.mergedSettingsData,
              settingsData:
                deleteLocationLocalizationResult.deleteLocationLocalization.settingsData ?? {},
            });
            break;
          }

          case CustomizeThemeLocalizationEntityLevel.KIOSK: {
            // eslint-disable-next-line max-len
            const deleteTenantLocalizationResult = deleteLocalizationResult.data as DeleteKioskLocalizationData;

            setLocalization({
              mergedSettingsData:
                deleteTenantLocalizationResult.deleteKioskLocalization.mergedSettingsData,
              settingsData:
                deleteTenantLocalizationResult.deleteKioskLocalization.settingsData ?? {},
            });
            break;
          }

          default:
            throw NotSupportedError(entityLevel);
        }
      }
    }
  };

  const isLoading = (): boolean =>
    loadingQuery || loadingUpsertLocalization || loadingDeleteLocalization;

  return (
    <CustomizeJson
      data={jsonDataFromBaseSettingAnswer(localization)}
      schema={schema}
      uiSchema={uiSchema}
      fileNameToDownloadDefaultJson={fileNameToDownloadDefaultJson}
      fileNameToDownloadCurrentJson={fileNameToDownloadCurrentJson}
      isLoading={isLoading()}
      onSubmit={onSubmit}
      title={title}
      textDirection={language.isRTL ? 'RTL' : 'LTR'}
    />
  );
};

export default CustomizeLocalization;
