import FormTemplateStep from '../../../templates/Form/@types/FormTemplateStep';
import InfoItemFormInputs from '../@types/InfoItemFormInputs';
import InfoItemSection from '../components/InfoItemSection';
import { Mutation } from '../../../__generated__/graphql';

type CreateInfoItemData = Pick<Mutation, 'createInfoItemAndUploadImageFile'>;

const INFO_ITEM_FORM_STEPS: FormTemplateStep<InfoItemFormInputs, CreateInfoItemData>[] = [
  {
    canBeAccessed: (): boolean => true,
    component: InfoItemSection,
    name: 'Info Item',
  },
];

export default INFO_ITEM_FORM_STEPS;
