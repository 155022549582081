const SearchResultsSpinnerContainerStyle = `
  align-items: center;
  justify-content: center;
  padding: 5px 10px;

  svg {
    height: 22px;
    width: 22px;
  }
`;

export default SearchResultsSpinnerContainerStyle;
