import 'styled-components/macro';

import { useDispatch, useSelector } from 'react-redux';

import IncomingCallModal from '../IncomingCallModal';
import IncomingCallsModalStyle from './style/IncomingCallsModal.style';
import Modal from 'react-modal';
import React from 'react';
import { RootState } from '../../redux/reducers';
import { closeModal } from '../../redux/actions/modals.actions';
import { removeIncomingCall } from '../../redux/actions/remoteAssistance.actions';
import { stopRinging } from '../../modules/Ringing';

const IncomingCallModals: React.FC = () => {
  const dispatch = useDispatch();

  const incomingCalls = useSelector((state: RootState) => state.remoteAssistance.incomingCalls);

  const closeIncomingCallModals = (): void => {
    stopRinging();
    dispatch(closeModal('incomingCall'));
  };

  const closeIncomingCallModal = (callRecordId: string): void => {
    dispatch(removeIncomingCall(callRecordId));

    // Verify if is the last incoming call
    if (incomingCalls.length === 1) {
      closeIncomingCallModals();
    }
  };

  const modalsToDisplay = incomingCalls.length >= 3 ? incomingCalls.length - 3 : 0;

  return (
    <Modal
      isOpen
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={false}
      onRequestClose={closeIncomingCallModals}
      style={IncomingCallsModalStyle}
    >
      {incomingCalls.slice(modalsToDisplay).map((incomingCall) => {
        return (
          <IncomingCallModal
            key={incomingCall.callRecordId}
            close={closeIncomingCallModal}
            incomingCall={incomingCall}
          />
        );
      })}
    </Modal>
  );
};

export default IncomingCallModals;
