import GetAccessGrantAuthenticationCredentialsData, {
  AccessGrantEmailCredentialsData,
} from './@types/GetAccessGrantAuthenticationCredentialsData';

import GET_ACCESS_GRANT_AUTHENTICATED_CREDENTIALS from '../../graphql/getAccessGrantAuthenticationCredentials';
import { GetAccessGrantAuthenticationCredentialsQueryVariables } from '../../__generated__/graphql';
import QueryOneHookData from '../@types/QueryOneHookData';
import { flashApolloError } from '../../util/errorUtils';
import { useLazyQuery } from '@apollo/client';

export default function useGetAccessGrantAuthenticationCredentials(
  onCompleted: (data: GetAccessGrantAuthenticationCredentialsData) => void = (): void => {}
): QueryOneHookData<AccessGrantEmailCredentialsData> {
  const [getEmailCredentialsData, { called, data, error, loading }] = useLazyQuery<
    GetAccessGrantAuthenticationCredentialsData,
    GetAccessGrantAuthenticationCredentialsQueryVariables
  >(GET_ACCESS_GRANT_AUTHENTICATED_CREDENTIALS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getAccessGrantAuthenticationCredentials }) => {
      onCompleted({ getAccessGrantAuthenticationCredentials });
    },
    onError: flashApolloError,
  });

  return {
    called,
    data: data?.getAccessGrantAuthenticationCredentials ?? undefined,
    error,
    loading,
    query: (id: string): void => {
      getEmailCredentialsData({
        variables: {
          accessGrantId: id,
        },
      });
    },
  };
}
