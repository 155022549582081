import Container from '../Container';
import React from 'react';
import { ReactComponent as Spinner } from '../../assets/loading.svg';
import { SpinnerContainerStyle } from './styles/SpinnerContainer.style';

export const LoadingSpinner: React.FC = () => {
  return (
    <Container style={SpinnerContainerStyle}>
      <Spinner height="50px" width="50px" />
    </Container>
  );
};
