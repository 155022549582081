/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css } from 'styled-components';
import Theme from '../../../theme';

interface SectionTitleWrapperProps {
  margin?: string;
}

interface SectionTileProps {
  smallerTitle?: boolean;
}

export const SectionTitleWrapper = styled.div<SectionTitleWrapperProps>`
  margin: ${({ margin }): string => margin || '0 0 50px 0'};
`;

export const SectionDescription = styled.div`
  color: ${Theme.colors.white.hexCode};
  font-size: 15px;
  line-height: 20px;

  p:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const SectionDescriptionWrapper = styled.div`
  display: grid;
  margin-top: 25px;
  grid-template-columns: 24px auto;
  gap: 10px;
  align-items: center;

  & > svg {
    align-self: start;
  }
`;

export const SectionTitle = styled.h2<SectionTileProps>`
  color: ${Theme.colors.white.hexCode};
  font-weight: 700;
  ${({ smallerTitle }): any =>
    smallerTitle &&
    css`
      font-size: 26px;
      font-weight: 600;
    `}
`;
