/* eslint-disable sort-keys */

import { JSONSchema7 } from 'json-schema';

export const confirmationEmailLocalizationSchema: JSONSchema7 = {
  type: 'object',
  properties: {
    general: {
      type: 'object',
      title: 'General',
      properties: {
        'step1Title:enable': {
          title: '#1 Step 1 title',
          type: 'boolean',
          description: '[step1Title]',
        },
        step1Title: {
          type: 'string',
        },
        'step1Description:enable': {
          title: '#2 Step 1 description',
          type: 'boolean',
          description: '[step1Description]',
        },
        step1Description: {
          type: 'string',
        },
        'step2Title:enable': {
          title: '#3 Step 2 title',
          type: 'boolean',
          description: '[step2Title]',
        },
        step2Title: {
          type: 'string',
        },
        'step2Description:enable': {
          title: '#4 Step 2 description',
          type: 'boolean',
          description: '[step2Description]',
        },
        step2Description: {
          type: 'string',
        },
        'step3Title:enable': {
          title: '#5 Step 3 title',
          type: 'boolean',
          description: '[step3Title]',
        },
        step3Title: {
          type: 'string',
        },
        'step3Description:enable': {
          title: '#6 Step 3 description',
          type: 'boolean',
          description: '[step3Description]',
        },
        step3Description: {
          type: 'string',
        },
        'policy1Title:enable': {
          title: '#7 Policy 1 title',
          type: 'boolean',
          description: '[policy1Title]',
        },
        policy1Title: {
          type: 'string',
        },
        'policy1Description:enable': {
          title: '#8 Policy 1 description',
          type: 'boolean',
          description: '[policy1Description]',
        },
        policy1Description: {
          type: 'string',
        },
        'policy2Title:enable': {
          title: '#9 Policy 2 title',
          type: 'boolean',
          description: '[policy2Title]',
        },
        policy2Title: {
          type: 'string',
        },
        'policy2Description:enable': {
          title: '#10 Policy 2 description',
          type: 'boolean',
          description: '[policy2Description]',
        },
        policy2Description: {
          type: 'string',
        },
        'propertyLabel:enable': {
          title: '#11 Property label',
          type: 'boolean',
          description: '[propertyLabel]',
        },
        propertyLabel: {
          type: 'string',
        },
        'roomTypeLabel:enable': {
          title: '#12 Room type label',
          type: 'boolean',
          description: '[roomTypeLabel]',
        },
        roomTypeLabel: {
          type: 'string',
        },
        'SecondaryTitle:enable': {
          title: '#13 Secondary title',
          type: 'boolean',
          description: '[SecondaryTitle]',
        },
        SecondaryTitle: {
          type: 'string',
        },
        'mainStepsTitle:enable': {
          title: '#14 Main steps title',
          type: 'boolean',
          description: '[mainStepsTitle]',
        },
        mainStepsTitle: {
          type: 'string',
        },
        'welcomeMessage:enable': {
          title: '#15 Welcome message',
          type: 'boolean',
          description: '[welcomeMessage]',
        },
        welcomeMessage: {
          type: 'string',
        },
        'confirmationTitle:enable': {
          title: '#16 Confirmation title',
          type: 'boolean',
          description: '[confirmationTitle]',
        },
        confirmationTitle: {
          type: 'string',
        },
        'termsConditionsTitle:enable': {
          title: '#17 Terms conditions title',
          type: 'boolean',
          description: '[termsConditionsTitle]',
        },
        termsConditionsTitle: {
          type: 'string',
        },
      },
    },
  },
};

export const confirmationEmailLocalizationUiSchema = {
  general: {
    'ui:title': '‎',
    error: {
      'ui:label': false,
      'ui:readonly': true,
    },

    step1Title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    step1Description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    step2Title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    step2Description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    step3Title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    step3Description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    policy1Title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    policy1Description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    policy2Title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    policy2Description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    propertyLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    roomTypeLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    SecondaryTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    mainStepsTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    welcomeMessage: {
      'ui:label': false,
      'ui:readonly': true,
    },
    confirmationTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    termsConditionsTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
};
