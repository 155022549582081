import { Datum } from '../../../components/DataTable';
import { Query } from '../../../__generated__/graphql';
import formatPhoneForDisplay from '../../../util/formatPhoneForDisplay';
import links from '../../../util/links';

type GetLocationsData = Pick<Query, 'locations'>;
type AggregateLocationQueryData = Pick<Query, 'aggregateLocation'>;

export default function generateLocationsTableData(
  data?: GetLocationsData,
  aggregateData?: AggregateLocationQueryData
): { tableData: Datum[]; count: number } {
  const aggregateLocations = aggregateData?.aggregateLocation;
  const locations = data?.locations || [];

  /* default to empty array if data has not been retrieved */
  const tableData =
    locations.map((location) => {
      return {
        dataType: 'Location',
        fields: [
          {
            key: 'Name',
            link: `${links.paths.LOCATIONS}/${location.id}`,
            value: location.name,
          },
          {
            key: 'Phone',
            value: formatPhoneForDisplay(location.phoneNumber) || 'N/A',
          },
          {
            key: 'Address',
            value: location.address || 'N/A',
          },
          {
            key: 'Logo Url',
            ...(location.logoUrl && { href: location.logoUrl }),
            value: location.logoUrl || '',
          },
          {
            key: 'Image Url',
            ...(location.imageUrl && { href: location.imageUrl }),
            value: location.imageUrl || '',
          },
        ],
        key: location.id,
      };
    }) || [];

  /* default to 0 if data has not been retrieved */
  const count = aggregateLocations?._count?._all || 0;

  return {
    count,
    tableData,
  };
}
