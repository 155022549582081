import * as yup from 'yup';

import EMAIL_REGEXP from '../../../constants/regex/email';
import HOURS_REGEX from '../../../constants/hoursRegex';
import URL_VALIDATION from '../../../constants/yupValidations/constants/url';

const LOCATION_FORM_VALIDATION_SCHEMA = yup.object().shape({
  address: yup.string().required('Address is required'),
  defaultEndTime: yup
    .string()
    .matches(HOURS_REGEX, { excludeEmptyString: true, message: 'Invalid time format (ex: 16:00)' })
    .required('End Time is required'),
  defaultRequirements: yup.object().shape({
    create: yup.array(
      yup.object().shape({
        id: yup.string(),
        type: yup.string(),
      })
    ),
    delete: yup.array(
      yup.object().shape({
        type_locationId: yup.object().shape({
          locationId: yup.string(),
          type: yup.string(),
        }),
      })
    ),
  }),
  defaultStartTime: yup
    .string()
    .matches(HOURS_REGEX, { excludeEmptyString: true, message: 'Invalid time format (ex: 16:00)' })
    .required('Start Time is required'),
  email: yup
    .string()
    .matches(EMAIL_REGEXP, { excludeEmptyString: true, message: 'Invalid email' })
    .required('Email is required'),
  emailFromEmail: yup
    .string()
    .matches(EMAIL_REGEXP, { excludeEmptyString: true, message: 'Invalid email' })
    .required('Outgoing email is required'),
  emailFromName: yup.string(),
  imageUrl: URL_VALIDATION,
  logoUrl: URL_VALIDATION,
  name: yup.string().required('Name is required'),
  phoneNumber: yup.string().required('Phone Number is required'),
});

export default LOCATION_FORM_VALIDATION_SCHEMA;
