import FormCrudAction from '../../../../../enums/FormCrudAction.enum';
import InputGroupProps from '../../../../InputGroup/@types/InputGroupProps';
import { RetryCheckinStatus } from '../../../../../__generated__/graphql';
import generateDayTimes from '../../../../../util/generateDayTimes';
import useLoadLocationOptions from '../../../../../hooks/useLoadLocationOptions';
import useLoadUserOptions from '../../../../../hooks/useLoadUserOptions';
import useSearchLocationsByName from '../../../../../hooks/useSearchLocations';
import { values } from 'lodash';

interface GrantFormInputProps {
  onSelectLocation: () => void;
  timezone: string;
  formCrudAction: FormCrudAction;
}

export default function generateGrantFormInputs({
  onSelectLocation,
  timezone,
  formCrudAction,
}: GrantFormInputProps): InputGroupProps[] {
  let accessGrantInputs: InputGroupProps[] = [
    {
      label: 'Search Users',
      loadOptions: useLoadUserOptions,
      name: 'user',
      required: true,
      type: 'async-select',
    },
    {
      label: 'Select Dates',
      name: 'startsAt/endsAt',
      required: true,
      timezone,
      type: 'date',
    },
    {
      isEditable: false,
      label: 'Search Locations',
      loadOptions: useLoadLocationOptions,
      name: 'location',
      onSelect: onSelectLocation,
      query: useSearchLocationsByName,
      required: true,
      type: 'async-select',
    },
    {
      label: 'Check-in',
      name: 'startTime',
      options: generateDayTimes(),
      required: false,
      type: 'select',
    },
    {
      label: 'Code',
      name: 'code',
      required: false,
      type: 'text',
    },
    {
      label: 'Check-out',
      name: 'endTime',
      options: generateDayTimes(),
      required: false,
      type: 'select',
    },
  ];

  if (formCrudAction === FormCrudAction.EDIT) {
    accessGrantInputs = [
      ...accessGrantInputs,
      {
        label: 'Retry check-in',
        name: 'retryCheckin',
        options: values(RetryCheckinStatus),
        type: 'select',
      },
    ];
  }

  return accessGrantInputs;
}
