import { AccessGrantWhereInput, Query, QueryAccessGrantsArgs } from '../../__generated__/graphql';
import { useCallback, useEffect, useRef, useState } from 'react';
import useDateTimeFormatter, { UseDateTimeFormatterInterface } from '../useDateTimeFormatter';

import ACCESS_GRANTS_SEARCH_FIELDS from './constants/AccessGrantsTableSearchFields';
import ACCESS_GRANTS_TABLE_HEADERS from './constants/AccessGrantsTableHeaders';
import Datum from '../../components/DataTable/@types/Datum';
import { EntityEnum } from '../../@types/client/DashboardUserRolePermissions';
import GET_ACCESS_GRANT_COUNT from '../../graphql/aggregateAccessGrant';
import GET_ACCESS_GRANT_TABLE_DATA from '../../graphql/getAccessGrantTableData';
import { LocationPageInfo } from '../../pages/Location/@types/GetLocationPageInfo';
import OrderByParams from '../../@types/client/OrderByParams';
import { RootState } from '../../redux/reducers';
import SearchField from '../../@types/SearchField';
import TableHookData from '../@types/TableHookData';
import TableHookProps from '../@types/TableHookProps';
import generateAccessGrantsTableData from './functions/generateAccessGrantsTableData';
import generateMembershipQuery from '../../util/api/generateMembershipQuery';
import parseAccessGrantQueryParameters from './util/parseAccessGrantQueryParameters';
import parseOrderByDataTableParams from '../../components/DataTable/util/parseOrderByDataTableParams';
import useAccessGrantFilters from './hooks/useAccessGrantFilters';
import useCheckLocationsAbleToPollReservation from '../../pages/AccessGrants/hooks/useCheckLocationsAbleToPollReservation';
import useGetMany from '../useGetMany';
import useRemoveAccessGrant from '../useRemoveAccessGrant';
import { useSelector } from 'react-redux';

type AccessGrantsQueryParams = {
  orderBy?: OrderByParams;
  where: Partial<AccessGrantWhereInput>;
};

type GetAccessGrantTableData = Pick<Query, 'findDashboardAccessGrants'>;
type AggregateAccessGrantQueryData = Pick<Query, 'aggregateDashboardAccessGrants'>;

interface AccessGrantsTableData
  extends Omit<
    TableHookProps<QueryAccessGrantsArgs, AccessGrantWhereInput, GetAccessGrantTableData>,
    'generateTableData'
  > {
  generateTableData?: (
    formatter: UseDateTimeFormatterInterface,
    data?: GetAccessGrantTableData
  ) => Datum[];
  relatedLocation?: LocationPageInfo;
  verifyIfLocationsSupportsPollReservation?: boolean;
}

export default function useAccessGrantsTableData({
  dataTableKey = 'accessGrants',
  fetchPolicy = 'cache-first',
  formName = 'accessGrant',
  generateTableData,
  headers = ACCESS_GRANTS_TABLE_HEADERS,
  isSearchable = true,
  isImportEnabled = false,
  permission = EntityEnum.ACCESS_GRANT,
  queryOptions,
  searchFields = ACCESS_GRANTS_SEARCH_FIELDS,
  relatedLocation,
  verifyIfLocationsSupportsPollReservation = false,
}: AccessGrantsTableData): TableHookData<AccessGrantWhereInput> {
  const membership = useSelector((state: RootState) => state.me.activeMembership);
  const options = useRef<Partial<QueryAccessGrantsArgs>>(queryOptions ?? {});
  const fieldsFiltered = useSelector((state: RootState) => state.dataTable.filters);
  const sortOptions = useSelector((state: RootState) => state.dataTable.sortOptions);
  const filters = useAccessGrantFilters();
  const dateTimeFormatter = useDateTimeFormatter();

  const [searchParameters, setSearchParameters] = useState<AccessGrantWhereInput>({});

  const { data, error, loading, offset, query, setOffset } = useGetMany<
    GetAccessGrantTableData,
    QueryAccessGrantsArgs
  >({
    fetchPolicy,
    graphqlQuery: GET_ACCESS_GRANT_TABLE_DATA,
  });

  const {
    data: countData,
    error: countError,
    loading: countLoading,
    query: countQuery,
  } = useGetMany<AggregateAccessGrantQueryData, QueryAccessGrantsArgs>({
    fetchPolicy,
    graphqlQuery: GET_ACCESS_GRANT_COUNT,
  });

  const createParams = useCallback((): AccessGrantsQueryParams => {
    const whereArgs = parseAccessGrantQueryParameters(fieldsFiltered[dataTableKey]);
    const sortParams = parseOrderByDataTableParams(sortOptions[dataTableKey]);

    return {
      ...(sortParams && { orderBy: sortParams }),
      where: {
        ...generateMembershipQuery(membership),
        ...options.current?.where,
        ...searchParameters,
        ...whereArgs,
      },
    };
  }, [membership, sortOptions, fieldsFiltered, dataTableKey, options, searchParameters]);

  const get = useCallback(() => {
    const params = createParams();

    query({
      ...options.current,
      ...(params.orderBy && { orderBy: params.orderBy }),
      skip: options.current?.skip ?? offset,
      take: options.current?.take ?? 10,
      where: params.where,
    });
  }, [createParams, offset, query]);

  const {
    query: queryLocationsAbleToPoll,
    data: dataLocationsAbleToPoll,
  } = useCheckLocationsAbleToPollReservation();

  if (verifyIfLocationsSupportsPollReservation) {
    queryLocationsAbleToPoll();
  }

  const getCount = useCallback(() => {
    const params = createParams();

    countQuery({
      where: params.where,
    });
  }, [countQuery, createParams]);

  useEffect(() => {
    get();
  }, [get]);

  useEffect(() => {
    getCount();
  }, [getCount]);

  return {
    aditionalDataOnOpeningForm: relatedLocation
      ? {
          location: {
            defaultEndTime: relatedLocation.defaultEndTime,
            defaultStartTime: relatedLocation.defaultStartTime,
            id: relatedLocation.id,
            name: relatedLocation.name,
          },
        }
      : undefined,
    count: countData?.aggregateDashboardAccessGrants?._count?._all ?? 0,
    data: generateTableData
      ? generateTableData(dateTimeFormatter, data)
      : generateAccessGrantsTableData(dateTimeFormatter, data),
    dataTableKey,
    dataTableTexts: {
      importText: 'Poll reservation',
    },
    dataType: 'AccessGrant',
    error: error || countError,
    filters,
    formName,
    headers,
    isImportEnabled: isImportEnabled || dataLocationsAbleToPoll?.thereIsLocationEnabledToPoll,
    isLoading: loading || countLoading,
    isSearchable,
    offset,
    permission,
    remove: useRemoveAccessGrant,
    search: (searchField: SearchField<AccessGrantWhereInput>, searchValue: string): void => {
      setSearchParameters({
        [searchField.queryField]: searchField.transform(searchValue),
      });

      setOffset(0);
    },
    searchFields,
    setOffset,
  };
}
