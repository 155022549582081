import React from 'react';
import Theme from '../../theme';
import isValidColorString from '../../util/css/isValidColorString';
import isValidPixelString from '../../util/css';
import styled from 'styled-components';

export enum TextContentModel {
  BODY_LARGE = 'text__body_large',
  BODY_SMALL = 'text__body_small',
}

type TextContentModelTypes = Record<TextContentModel, string>;

export interface TextContentProps extends React.HTMLAttributes<HTMLParagraphElement> {
  color?: string;
  fontFamily?: string;
  fontSize?: string;
  margin?: string;
  model: keyof TextContentModelTypes;
  textTransform?: string;
}

const TEXT_CONTENT_CSS_PROPERTY_MAP = {
  [TextContentModel.BODY_LARGE]: {
    ...Theme.fontStyles.bodyLarge,
  },
  [TextContentModel.BODY_SMALL]: {
    ...Theme.fontStyles.bodySmall,
  },
};

const StyledTextContent = styled.p<TextContentProps>`
  color: ${({ color }): string => {
    if (color && isValidColorString(color)) {
      return color;
    }

    return Theme.colors.dark.hexCode;
  }};
  font-family: ${({ fontFamily }): string => {
    if (fontFamily) {
      return fontFamily;
    }

    return Theme.fontFamilies.default;
  }};
  font-size: ${({ fontSize, model }): string => {
    if (fontSize && isValidPixelString(fontSize)) {
      return fontSize;
    }

    return TEXT_CONTENT_CSS_PROPERTY_MAP[model].size || '12px';
  }};
  margin: ${({ margin }): string => {
    return margin || '0';
  }};
  padding: 0;
  text-transform: ${({ textTransform }): string => {
    return textTransform || 'initial';
  }};
`;

StyledTextContent.displayName = 'TextContent';

function TextContent({
  model = TextContentModel.BODY_LARGE,
  ...props
}: Partial<TextContentProps>): React.ReactElement {
  return <StyledTextContent model={model} {...props} />;
}

export default TextContent;
