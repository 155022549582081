import { UPSERT_KIOSK_THEME, UPSERT_LOCATION_THEME } from '../../../graphql/mutations';

import { CustomizeThemeLocalizationEntityLevel } from '../../../enums/CustomizeThemeLocalizationEntityLevel';
import { DocumentNode } from 'graphql';
import { NotSupportedError } from '../constants/defaultError';
import { UPSERT_TENANT_THEME } from '../../../graphql/mutations/tenant';

const getUpsertQuery = (entityLevel: CustomizeThemeLocalizationEntityLevel): DocumentNode => {
  switch (entityLevel) {
    case CustomizeThemeLocalizationEntityLevel.TENANT:
      return UPSERT_TENANT_THEME;

    case CustomizeThemeLocalizationEntityLevel.LOCATION:
      return UPSERT_LOCATION_THEME;

    case CustomizeThemeLocalizationEntityLevel.KIOSK:
      return UPSERT_KIOSK_THEME;

    default:
      throw NotSupportedError(entityLevel);
  }
};

export default getUpsertQuery;
