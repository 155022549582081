import 'styled-components/macro';

import Container from '../../../../components/Container';
import KioskLiveUsageCard from './components/KioskLiveUsageCard';
import KiosksLiveUsageContainer from './styles/KiosksLiveUsageContainer.style';
import KiosksLiveUsageFilter from './components/KiosksLiveUsageFilter';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import React from 'react';
import useGetKiosksLiveUsage from './hooks/useGetKiosksLiveUsage';

const KiosksLiveUsage: React.FC = () => {
  const { allKiosks, isLoading } = useGetKiosksLiveUsage();

  if (isLoading) {
    return (
      <Container>
        <KiosksLiveUsageFilter />
        <LoadingSpinner />
      </Container>
    );
  }

  return (
    <Container>
      <KiosksLiveUsageFilter />
      <Container style={KiosksLiveUsageContainer}>
        {allKiosks.map((kiosk) => (
          <KioskLiveUsageCard key={kiosk.kiosk.id} kiosk={kiosk} />
        ))}
      </Container>
    </Container>
  );
};

export default KiosksLiveUsage;
