import Theme from '../../../theme';
import styled from 'styled-components';

const TableData = styled.td`
  background: transparent;
  border-bottom: 1px solid rgba(${Theme.colors.borderTable.rgb}, 0.1);
  color: ${Theme.colors.white.hexCode};
  font-family: 'San Francisco Pro';
  font-size: ${Theme.fontSizes.bodyLarge};
  padding: 15px 25px 45px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  a {
    color: ${Theme.colors.linkDarkBackground.hexCode};
    text-decoration: underline;
  }
`;

TableData.displayName = 'StyledTableData';

export default TableData;
