/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3ThemePaymentStatusSchema: NodeSchemaProperties = {
  paymentStatusScreen: {
    title: '#18 Payment Status Screen',
    type: 'object',
    properties: {
      'visaIconUrl:enable': {
        description: '[paymentStatusScreen.visaIconUrl]',
        title: '#1 Visa Icon Url',
        type: 'boolean',
      },
      visaIconUrl: {
        type: 'string',
      },
      'mastercardIconUrl:enable': {
        description: '[paymentStatusScreen.mastercardIconUrl]',
        title: '#2 Mastercard Icon Url',
        type: 'boolean',
      },
      mastercardIconUrl: {
        type: 'string',
      },
      'amexIconUrl:enable': {
        description: '[paymentStatusScreen.amexIconUrl]',
        title: '#3 Amex Icon Url',
        type: 'boolean',
      },
      amexIconUrl: {
        type: 'string',
      },
      'applepayIconUrl:enable': {
        description: '[paymentStatusScreen.applepayIconUrl]',
        title: '#4 Applepay Icon Url',
        type: 'boolean',
      },
      applepayIconUrl: {
        type: 'string',
      },
      'hideVisaIcon:enable': {
        description: '[paymentStatusScreen.hideVisaIcon]',
        title: '#5 Hide Visa Icon',
        type: 'boolean',
      },
      hideVisaIcon: {
        type: 'string',
      },
      'hideMastercardIcon:enable': {
        description: '[paymentStatusScreen.hideMastercardIcon]',
        title: '#6 Hide Mastercard Icon',
        type: 'boolean',
      },
      hideMastercardIcon: {
        type: 'string',
      },
      'hideAmexIcon:enable': {
        description: '[paymentStatusScreen.hideAmexIcon]',
        title: '#7 Hide Amex Icon',
        type: 'boolean',
      },
      hideAmexIcon: {
        type: 'string',
      },
      'hideApplepayIcon:enable': {
        description: '[paymentStatusScreen.hideApplepayIcon]',
        title: '#8 Hide Applepay Icon',
        type: 'boolean',
      },
      hideApplepayIcon: {
        type: 'string',
      },
      'cardIconUrlList:enable': {
        description: '[paymentStatusScreen.cardIconUrlList]',
        title: '#9 Card Icon Url List',
        type: 'boolean',
      },
      cardIconUrlList: {
        type: 'array',
        items: {
          type: 'string',
        },
      },
    },
  },
};

export const mobileV3ThemePaymentStatusUiSchema: NodeUiSchemaProperties = {
  paymentStatusScreen: {
    visaIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    mastercardIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    amexIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    applepayIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    hideVisaIcon: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    hideMastercardIcon: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    hideAmexIcon: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    hideApplepayIcon: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    cardIconUrlList: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:title': '‎',
      items: {
        'ui:label': false,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
    },
  },
};
