import { DisplayHeaderMap } from '../@types/DisplayHeader';
import OrderByDataTableParams from '../../../redux/actions/@types/DataTableOrderByParams';
import values from 'lodash/values';

export default function generateSortSearchQueryString(
  headers: DisplayHeaderMap
): OrderByDataTableParams[] {
  const orderBy: OrderByDataTableParams[] = [];

  values(headers).forEach(({ sortOrder, sortType, value: key }) => {
    if (sortOrder && sortType && key) {
      const params: OrderByDataTableParams = {
        key,
        sortOrder,
        sortType,
      };

      orderBy.push(params);
    }
  });

  return orderBy;
}
