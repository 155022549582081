import { Location, Mutation } from '../../__generated__/graphql';
import React, { useEffect } from 'react';

import FormTemplate from '../../templates/Form';
import INFO_ITEM_FORM_STEPS from './constants/infoItemFormSteps';
import INFO_ITEM_VALIDATION_SCHEMA from './constants/infoItemValidationSchema';
import { ImageTypeEnum } from '../UnitTypeForm/enums/ImageTypeEnum.enum';
import InfoItemFormInputs from './@types/InfoItemFormInputs';
import pick from 'lodash/pick';
import useGetInfoItemById from '../../hooks/useGetInfoItemById';

interface InfoItemAditionalDataOnOpeningFormInterface {
  location: Pick<Location, 'id' | 'name'>;
}

interface InfoItemProps {
  id?: string;
  aditionalDataOnOpeningForm?: unknown;
}

type CreateInfoItemData = Pick<Mutation, 'createInfoItemAndUploadImageFile'>;

const InfoItemForm: React.FC<InfoItemProps> = ({
  id,
  aditionalDataOnOpeningForm,
}: InfoItemProps) => {
  const { called, data: infoItem, query } = useGetInfoItemById();

  useEffect(() => {
    if (id && !called) {
      query(id);
    }
  });

  if (id && !infoItem) {
    return <React.Fragment />;
  }

  const getInitialValues = (): Partial<InfoItemFormInputs> => {
    if (infoItem) {
      const initialValues: Partial<InfoItemFormInputs> = ({
        ...pick(infoItem, ['title', 'description', 'imageUrl', 'type', 'moreInfoLink']),
        imageType: ImageTypeEnum.URL,
        ...(infoItem.location
          ? {
              location: {
                label: infoItem.location.name,
                value: infoItem.location.id,
              },
            }
          : {}),
        ...(infoItem?.infoType
          ? {
              infoType: {
                label: infoItem.infoType.title,
                value: infoItem.infoType.id,
              },
            }
          : {}),
      } as unknown) as Partial<InfoItemFormInputs>;

      return initialValues;
    }

    const defaultInitialValues: Partial<InfoItemFormInputs> = {
      imageType: ImageTypeEnum.URL,
    };

    try {
      if (aditionalDataOnOpeningForm) {
        const {
          location,
        } = aditionalDataOnOpeningForm as InfoItemAditionalDataOnOpeningFormInterface;
        return {
          ...defaultInitialValues,
          location: {
            label: location.name,
            value: location.id,
          },
        };
      }
      // eslint-disable-next-line no-empty
    } catch {}

    return defaultInitialValues;
  };

  return (
    <FormTemplate<InfoItemFormInputs, CreateInfoItemData>
      formHeader={id ? 'Edit Info Item' : 'Create Info Item'}
      identifier={id}
      steps={INFO_ITEM_FORM_STEPS}
      validationSchema={INFO_ITEM_VALIDATION_SCHEMA}
      initialValues={getInitialValues()}
    />
  );
};

export default InfoItemForm;
