import { DataTableFilterValues } from '../../../@types/client/DataTableFilterValues';
import { ResourceGroupWhereInput } from '../../../__generated__/graphql';

export default function parseResourceGroupsQueryParameters(
  fieldsFiltered: DataTableFilterValues[]
): Partial<ResourceGroupWhereInput> {
  const whereInput: Partial<ResourceGroupWhereInput> = {};

  if (fieldsFiltered?.length > 0) {
    // Is default filter
    const isDefaultFieldsIndex = fieldsFiltered.findIndex(
      (field) => field.fieldKey === 'isDefault'
    );
    if (isDefaultFieldsIndex > -1) {
      const isDefaultFilters = fieldsFiltered[isDefaultFieldsIndex].values;

      if (isDefaultFilters.length === 1) {
        const [value] = isDefaultFilters;
        const isDefaultValue = value === 'true';

        whereInput.isDefault = {};
        whereInput.isDefault.equals = isDefaultValue;
      }
    }
  }

  return whereInput;
}
