import { gql } from '../__generated__/gql';

const CREATE_USER = gql(`
  mutation CreateUser($data: UserCreateInput!) {
    createUser(data: $data) {
      id
      name
    }
  }
`);

export default CREATE_USER;
