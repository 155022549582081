import React, { ReactNode } from 'react';
import {
  labelContainer,
  labelTextStyle,
  mainContainerStyle,
  textContainer,
} from './styles/RowItem.style';

type RowInfoProps = {
  label: string;
  children: ReactNode;
  suffixIcon?: ReactNode;
  iconPosition?: 'header' | 'body';
};

const RowInfo: React.FC<RowInfoProps> = ({
  label,
  children,
  suffixIcon,
  iconPosition = 'body',
}: RowInfoProps) => {
  return (
    <div style={mainContainerStyle}>
      <div style={textContainer}>
        <div style={labelContainer}>
          <span style={labelTextStyle}>{label}</span>
          {iconPosition === 'header' && suffixIcon}
        </div>
        {children}
      </div>
      {suffixIcon && iconPosition === 'body' && suffixIcon}
    </div>
  );
};

export default RowInfo;
