import React, { useEffect } from 'react';

import QRCode from 'easyqrcodejs';
import { QRSection } from '../../styles/AccessGrantAuthenticationCredentialsStyle';
import { imageStyle } from '../RowInfo/styles/RowItem.style';

type QRCodeViewerProps = {
  value: string;
  qrCodeRef: React.RefObject<HTMLImageElement>;
};

const QRCodeViewer: React.FC<QRCodeViewerProps> = ({ qrCodeRef, value }: QRCodeViewerProps) => {
  useEffect(() => {
    const qrCode = new QRCode(qrCodeRef.current, { height: 200, text: value, width: 200 });

    return (): void => {
      qrCode.clear();
    };
  }, [qrCodeRef]);

  return <QRSection ref={qrCodeRef} style={imageStyle} />;
};

export default QRCodeViewer;
