/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationPaymentStatusSchema: NodeSchemaProperties = {
  paymentStatusScreen: {
    title: '#21 Payment Status Screen',
    type: 'object',
    properties: {
      'header:enable': {
        description: '[paymentStatusScreen.header]',
        title: '#1 Header',
        type: 'boolean',
      },
      header: {
        type: 'string',
      },
      'genericError:enable': {
        description: '[paymentStatusScreen.genericError]',
        title: '#2 Generic Error',
        type: 'boolean',
      },
      genericError: {
        type: 'string',
      },
      'cancel:enable': {
        description: '[paymentStatusScreen.cancel]',
        title: '#3 Cancel',
        type: 'boolean',
      },
      cancel: {
        type: 'string',
      },
      'remove:enable': {
        description: '[paymentStatusScreen.remove]',
        title: '#4 Remove',
        type: 'boolean',
      },
      remove: {
        type: 'string',
      },
      'removeCreditCard:enable': {
        description: '[paymentStatusScreen.removeCreditCard]',
        title: '#5 Remove Credit Card',
        type: 'boolean',
      },
      removeCreditCard: {
        type: 'string',
      },
      'confirmRemoveCard:enable': {
        description: '[paymentStatusScreen.confirmRemoveCard]',
        title: '#6 Confirm Remove Card',
        type: 'boolean',
      },
      confirmRemoveCard: {
        type: 'string',
      },
      'creditCards:enable': {
        description: '[paymentStatusScreen.creditCards]',
        title: '#7 Credit Cards',
        type: 'boolean',
      },
      creditCards: {
        type: 'string',
      },
      'addCard:enable': {
        description: '[paymentStatusScreen.addCard]',
        title: '#8 Add Card',
        type: 'boolean',
      },
      addCard: {
        type: 'string',
      },
      'addCardDisabled:enable': {
        description: '[paymentStatusScreen.addCardDisabled]',
        title: '#9 Add Card Disabled',
        type: 'boolean',
      },
      addCardDisabled: {
        type: 'string',
      },
      disabledPaymentRequirementTextHtml: {
        title: '#10 Disabled Payment Requirement Text Html',
        type: 'object',
        properties: {
          'text:enable': {
            description: '[paymentStatusScreen.disabledPaymentRequirementTextHtml.text]',
            title: '#10.1 Text',
            type: 'boolean',
          },
          text: {
            type: 'string',
          },
          'type:enable': {
            description: '[paymentStatusScreen.disabledPaymentRequirementTextHtml.type]',
            title: '#10.2 Type',
            type: 'boolean',
          },
          type: {
            enum: ['PLAIN_TEXT', 'HTML'],
            type: 'string',
          },
          'useDefaultStyles:enable': {
            description:
              '[paymentStatusScreen.disabledPaymentRequirementTextHtml.useDefaultStyles]',
            title: '#10.3 Use Default Styles',
            type: 'boolean',
          },
          useDefaultStyles: {
            type: undefined,
            enum: [true, false],
          },
        },
      },
      'paymentProviderNotSet:enable': {
        description: '[paymentStatusScreen.paymentProviderNotSet]',
        title: '#11 Payment Provider Not Set',
        type: 'boolean',
      },
      paymentProviderNotSet: {
        type: 'string',
      },
      'paymentProviderNotSupported:enable': {
        description: '[paymentStatusScreen.paymentProviderNotSupported]',
        title: '#12 Payment Provider Not Supported',
        type: 'boolean',
      },
      paymentProviderNotSupported: {
        type: 'string',
      },
      error: {
        title: '#13 Error',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[paymentStatusScreen.error.title]',
            title: '#13.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'description:enable': {
            description: '[paymentStatusScreen.error.description]',
            title: '#13.2 Description',
            type: 'boolean',
          },
          description: {
            type: 'string',
          },
        },
      },
      errorModal: {
        title: '#14 Error Modal',
        type: 'object',
        properties: {
          'buttonTitle:enable': {
            description: '[paymentStatusScreen.errorModal.buttonTitle]',
            title: '#14.1 Button Title',
            type: 'boolean',
          },
          buttonTitle: {
            type: 'string',
          },
          'title:enable': {
            description: '[paymentStatusScreen.errorModal.title]',
            title: '#14.2 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
        },
      },
      'failOnOpeningPaymentForm:enable': {
        description: '[paymentStatusScreen.failOnOpeningPaymentForm]',
        title: '#15 Fail On Opening Payment Form',
        type: 'boolean',
      },
      failOnOpeningPaymentForm: {
        type: 'string',
      },
      'instructionTextHtml:enable': {
        description: '[paymentStatusScreen.instructionTextHtml]',
        title: '#16 Instruction Text (HTML)',
        type: 'boolean',
      },
      instructionTextHtml: {
        type: 'string',
      },
      'disabledInstructionTextHtml:enable': {
        description: '[paymentStatusScreen.disabledInstructionTextHtml]',
        title: '#17 Disabled Instruction Text (HTML)',
        type: 'boolean',
      },
      disabledInstructionTextHtml: {
        type: 'string',
      },
      'cardListText:enable': {
        description: '[paymentStatusScreen.cardListText]',
        title: '#18 Card list Text (HTML)',
        type: 'boolean',
      },
      cardListText: {
        type: 'string',
      },
      'delete:enable': {
        description: '[paymentStatusScreen.delete]',
        title: '#19 Delete card text',
        type: 'boolean',
      },
      delete: {
        type: 'string',
      },
    },
  },
};

export const mobileV3LocalizationPaymentStatusUiSchema: NodeUiSchemaProperties = {
  paymentStatusScreen: {
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    genericError: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cancel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    remove: {
      'ui:label': false,
      'ui:readonly': true,
    },
    removeCreditCard: {
      'ui:label': false,
      'ui:readonly': true,
    },
    confirmRemoveCard: {
      'ui:label': false,
      'ui:readonly': true,
    },
    creditCards: {
      'ui:label': false,
      'ui:readonly': true,
    },
    addCard: {
      'ui:label': false,
      'ui:readonly': true,
    },
    addCardDisabled: {
      'ui:label': false,
      'ui:readonly': true,
    },
    disabledPaymentRequirementTextHtml: {
      text: {
        'ui:label': false,
        'ui:readonly': true,
      },
      type: {
        'ui:label': false,
        'ui:readonly': true,
      },
      useDefaultStyles: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
    },
    paymentProviderNotSet: {
      'ui:label': false,
      'ui:readonly': true,
    },
    paymentProviderNotSupported: {
      'ui:label': false,
      'ui:readonly': true,
    },
    error: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    errorModal: {
      buttonTitle: {
        'ui:label': false,
        'ui:readonly': true,
      },
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    failOnOpeningPaymentForm: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instructionTextHtml: {
      'ui:label': false,
      'ui:readonly': true,
    },
    disabledInstructionTextHtml: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cardListText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    delete: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
};
