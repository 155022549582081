import { gql } from '../../__generated__/gql';

export const ISSUE_RESET_PASSWORD_BY_EMAIL_CHALLENGE = gql(`
  mutation IssueResetPasswordByEmailChallenge($email: String!) {
    issueResetPasswordByEmailChallenge(email: $email)
  }
`);

export const RESET_PASSWORD_BY_EMAIL = gql(`
  mutation ResetPasswordByEmail($newPassword: String!, $challenge: String!, $email: String!) {
    resetPasswordByEmail(newPassword: $newPassword, challenge: $challenge, email: $email)
  }
`);
