import * as yup from 'yup';

const INFO_ITEM_VALIDATION_SCHEMA = yup.object().shape({
  title: yup.string().required('Title is required'),
  type: yup.object().shape({
    label: yup.string().required('type is required'),
    value: yup.string().required('type is required'),
  }),
});

export default INFO_ITEM_VALIDATION_SCHEMA;
