import {
  Mutation,
  MutationCreateOneVendorServerArgs,
  VendorServerCreateInput,
} from '../../../__generated__/graphql';

import { CREATE_VENDOR_SERVER } from '../../../graphql/createVendorServer';
import MutationHookData from '../../../hooks/@types/MutationHookData';
import MutationHookParams from '../../../hooks/@types/MutationHookParams';
import { closeForm } from '../../../redux/actions/modals.actions';
import { flashApolloError } from '../../../util/errorUtils';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';

type CreateVendorServerData = Pick<Mutation, 'createOneVendorServer'>;

export default function useCreateVendorServer({
  onCompleted = (): void => {},
}: MutationHookParams<CreateVendorServerData> = {}): MutationHookData<
  VendorServerCreateInput,
  CreateVendorServerData
> {
  const dispatch = useDispatch();

  const [create, { data, error, loading }] = useMutation<
    CreateVendorServerData,
    MutationCreateOneVendorServerArgs
  >(CREATE_VENDOR_SERVER, {
    onCompleted: ({ createOneVendorServer }) => {
      window.flash({
        message: `Vendor server: ${createOneVendorServer.vendor} created successfully`,
      });

      onCompleted({ createOneVendorServer });

      dispatch(closeForm());
    },
    onError: flashApolloError,
  });

  return {
    data,
    error,
    loading,
    mutation: (inputs: VendorServerCreateInput): void => {
      create({
        variables: {
          data: {
            ...inputs,
          },
        },
      });
    },
  };
}
