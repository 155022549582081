import { gql } from '../__generated__/gql';

export const GET_USER_RECORDS_TABLE_DATA = gql(`
  query GetUserRecordsPageInfo(
    $cursor: UserRecordWhereUniqueInput
    $distinct: [UserRecordScalarFieldEnum!]
    $orderBy: [UserRecordOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: UserRecordWhereInput
  ) {
    userRecords(
      cursor: $cursor
      distinct: $distinct
      orderBy: $orderBy
      skip: $skip
      take: $take
      where: $where
    ) {
      id
      recordType
      recordId
      timeStamp
      tenantId
      userId
      verificationStatus
    }
  }
`);
