import styled from 'styled-components';

const TableHead = styled.thead`
  background: transparent;
  display: contents;
`;

TableHead.displayName = 'StyledTableHead';

export default TableHead;
