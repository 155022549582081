import 'styled-components/macro';

import TextContent, { TextContentModel } from '../../TextContent';

import Container from '../../Container';
import DetailsTextStyle from '../styles/DetailsText.style';
import { FiMonitor } from 'react-icons/fi';
import React from 'react';

interface KioskInfoProps {
  kioskName: string;
}
const KioskInfo: React.FC<KioskInfoProps> = ({ kioskName }: KioskInfoProps) => {
  return (
    <Container css={DetailsTextStyle}>
      <span>
        <FiMonitor size="22px" />
      </span>{' '}
      <TextContent model={TextContentModel.BODY_LARGE}>{kioskName}</TextContent>
    </Container>
  );
};

export default KioskInfo;
