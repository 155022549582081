import buildFieldUpdateOperationsInput from './buildFieldUpdateOperationsInput';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GenericObject = { [key: string]: any };
const transformCreateInputIntoUpdateInput = <UpdateType extends GenericObject>(
  createInput: GenericObject
): UpdateType => {
  let updateInput: GenericObject = {};

  Object.entries(createInput).forEach(([key, value]) => {
    updateInput = {
      ...updateInput,
      [key]: buildFieldUpdateOperationsInput(value),
    };
  });

  return updateInput as UpdateType;
};

export default transformCreateInputIntoUpdateInput;
