import 'styled-components/macro';

import { FileToEntity, FileType } from '../../../../__generated__/graphql';
import { MdAssignment, MdAssignmentTurnedIn } from 'react-icons/md';

import Button from '../../../Button';
import ButtonModel from '../../../Button/enums/ButtonModel.enum';
import { Colors } from '../../../../theme/colors';
import Container from '../OverviewSection';
import Label from '../../../Label';
import React from 'react';

interface Props {
  fileToEntity: FileToEntity;
}

export const AgreementFile = ({ fileToEntity }: Props): React.ReactElement => {
  let label;
  let icon;
  let color;
  switch (fileToEntity.file.fileType) {
    case FileType.OriginalAgreementFile:
      label = 'Original Agreement File';
      icon = <MdAssignment color="#fff" size="16px" />;
      color = Colors.brightGrey;
      break;
    case FileType.SignedAgreementFile:
      label = 'Signed Agreement File';
      icon = <MdAssignmentTurnedIn color="#fff" size="16px" />;
      color = Colors.dodgerBlue;
      break;
    default:
      label = 'File';
      icon = <MdAssignment color="#fff" size="16px" />;
      color = Colors.brightGrey;
      break;
  }

  return (
    <Container
      alignContent="center"
      css={`
        justify-items: center;
      `}
    >
      <Button
        background={color}
        model={ButtonModel.PASSIVE_ICON_BUTTON}
        onClick={(e: React.SyntheticEvent): void => {
          window.open(fileToEntity?.file.url);
          e.preventDefault();
        }}
      >
        {icon}
      </Button>
      <Label>{label}</Label>
    </Container>
  );
};
