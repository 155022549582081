import Filters from '../../../../../../components/DataTable/components/Filters';
import React from 'react';
import useKiosksLiveUsageFilters from './hooks/useKiosksLiveUsageFilters';

export const kiosksLiveUsageDataTableKey = 'kiosksLiveUsageDataTableKey';

const KiosksLiveUsageFilter: React.FC = () => {
  const kiosksLiveUsageFilters = useKiosksLiveUsageFilters();

  return (
    <Filters
      dataTableKey={kiosksLiveUsageDataTableKey}
      filters={kiosksLiveUsageFilters}
      colorMode="LIGHT"
    />
  );
};

export default KiosksLiveUsageFilter;
