import {
  AccessGrant,
  Location,
  Query,
  QueryAccessGrantArgs,
  Requirement,
  Resource,
  User,
} from '../__generated__/graphql';

import GET_ACCESS_GRANT from '../graphql/getAccessGrant';
import QueryOneHookData from './@types/QueryOneHookData';
import { flashApolloError } from '../util/errorUtils';
import { useLazyQuery } from '@apollo/client';

type GetAccessGrantData = Pick<Query, 'accessGrant'>;

export default function useGetAccessGrantById(): QueryOneHookData<
  Pick<
    AccessGrant,
    | 'code'
    | 'confirmationCode'
    | 'endsAt'
    | 'endTime'
    | 'id'
    | 'startsAt'
    | 'startTime'
    | 'status'
    | 'type'
  > & {
    location: Pick<Location, 'id' | 'name' | 'timezone'>;
    requirements: Pick<Requirement, 'id' | 'status' | 'type'>[];
    resources: Pick<Resource, 'id' | 'name'>[];
    user: Pick<User, 'id' | 'name'>;
  }
> {
  const [getAccessGrant, { called, data, error, loading }] = useLazyQuery<
    GetAccessGrantData,
    QueryAccessGrantArgs
  >(GET_ACCESS_GRANT, {
    fetchPolicy: 'network-only',
    onCompleted: () => {},
    onError: flashApolloError,
  });

  return {
    called,
    data: data?.accessGrant ?? undefined,
    error,
    loading,
    query: (id: string): void => {
      getAccessGrant({
        variables: {
          where: { id },
        },
      });
    },
  };
}
