import { GracePeriodFormGrid, ResetButton } from '../styles/GracePeriodsController.styles';
import { GracePeriodType, TimeControlCategory, TimeControlType } from '../enums';
import React, { useMemo } from 'react';

import { ControlTitle } from '../styles';
import GracePeriodInput from './GracePeriodInput';
import { GracePeriodValues } from '../../../__generated__/graphql';
import GracePeriodsControllerSkeleton from './GracePeriodsControllerSkeleton';
import ToggleSelector from '../../ToggleSelector';
import { cloneDeep } from 'lodash';
import { useFormContext } from 'react-hook-form';

interface GracePeriodsControllerProps {
  controlType: TimeControlType;
  controlCategory: TimeControlCategory;
  resetValues: GracePeriodValues | undefined;
  currentGracePeriodValues: GracePeriodValues | undefined;
  loading: boolean;
}

const GracePeriodsController: React.FC<GracePeriodsControllerProps> = ({
  controlType,
  resetValues,
  loading,
  currentGracePeriodValues,
}: GracePeriodsControllerProps) => {
  const { register, reset, trigger } = useFormContext();

  const handleResetButtonClick = (): void => {
    if (currentGracePeriodValues && resetValues) {
      const finalResetValues = cloneDeep(currentGracePeriodValues);

      if (controlType === TimeControlType.CHECK_IN) {
        finalResetValues.checkIn = resetValues.checkIn;
      }

      if (controlType === TimeControlType.CHECK_OUT) {
        finalResetValues.checkOut = resetValues.checkOut;
      }

      reset(finalResetValues);
      trigger();
    }
  };

  const sectionTitle = useMemo(() => {
    return `Override ${controlType === TimeControlType.CHECK_IN ? 'Check-in' : 'Check-Out'} Time`;
  }, [controlType]);

  return (
    <GracePeriodFormGrid>
      {loading ? (
        <GracePeriodsControllerSkeleton title={sectionTitle} />
      ) : (
        <>
          <input type="checkbox" name={`${controlType}.isActive`} ref={register} />
          <ControlTitle>{sectionTitle}</ControlTitle>
          <ToggleSelector.Form
            name={`${controlType}.gracePeriodType`}
            themeMode="dark"
            options={[GracePeriodType.RELATIVE, GracePeriodType.ABSOLUTE]}
          />
          <GracePeriodInput controlType={controlType} />
          <ResetButton type="button" onClick={handleResetButtonClick}>
            Reset
          </ResetButton>
        </>
      )}
    </GracePeriodFormGrid>
  );
};

export default GracePeriodsController;
