import { FieldError } from 'react-hook-form';
import Theme from '../../../theme';
import styled from 'styled-components';

export interface InputBoxProps {
  error?: FieldError;
}

interface ContainerProps {
  maxWidth?: string;
}

export const Container = styled.div<ContainerProps>`
  height: 35px;
  ${({ maxWidth }): string => {
    return maxWidth !== undefined ? `max-width: ${maxWidth}` : '';
  }};

  input {
    background: none;
    border: none;
    color: ${Theme.colors.white.hexCode};
    text-align: center;
    font-size: 26px;
    text-transform: uppercase;

    :focus {
      outline: none;
    }
  }
`;

export const InputBox = styled.div<InputBoxProps>`
  display: grid;
  grid-template-columns: 60px 60px;
  align-items: center;
  height: 100%;
  width: 120px;
  background-color: ${Theme.colors.borderDark.hexCode};
  border-radius: 10px;
  border: ${({ error }): string => {
    return error ? `1px solid ${Theme.colors.errorText.hexCode};` : `none;`;
  }};

  & > input {
    max-width: 100%;
    max-height: 35px;
  }

  span {
    color: ${Theme.colors.white.hexCode};
    text-transform: uppercase;
  }
`;

export const PeriodInfo = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Theme.colors.borderDark.hexCode};
  color: ${Theme.colors.white.hexCode};
  text-transform: uppercase;
  width: 100px;
  border-radius: 10px;
`;

export const RelativeInputWrapper = styled.div`
  display: grid;
  grid-template-columns: 120px 100px;
  gap: 20px;
  height: 100%;
`;

export const InputWrapper = styled(RelativeInputWrapper)<InputBoxProps>`
  input {
    width: 120px;
    background-color: ${Theme.colors.borderDark.hexCode};
    border-radius: 10px;
    box-sizing: border-box;
    border: ${({ error }): string => {
      return error ? `1px solid ${Theme.colors.errorText.hexCode};` : `none;`;
    }};
  }
`;

export const InputContainer = styled.div`
  height: 35px;
`;

export const Input = styled.input`
  background: none;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 26px;
  text-transform: uppercase;
  &:focus {
    outline: none;
  }
`;
