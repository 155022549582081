import FormTemplateStep from '../../../templates/Form/@types/FormTemplateStep';
import { Mutation } from '../../../__generated__/graphql';
import ResourceGroupFormInputs from '../@types/ResourceGroupFormInputs';
import ResourceGroupInfoSection from '../components/InfoSection';

type CreateResourceData = Pick<Mutation, 'createResourceGroup'>;

const RESOURCE_GROUP_FORM_STEPS: FormTemplateStep<ResourceGroupFormInputs, CreateResourceData>[] = [
  {
    canBeAccessed: (): boolean => true,
    component: ResourceGroupInfoSection,
    formHeader: 'Create Resource Group',
    name: 'Resource Group',
  },
];

export default RESOURCE_GROUP_FORM_STEPS;
