import React from 'react';
import { convertBytesToMegaBytes } from '../../util/fileUtil';

interface FileInputProps {
  className?: string;
  name: string;
  onChange: (e?: File | undefined | null) => void;
  required: boolean;
  accept?: string;
  maxFileSize?: number; // Bytes
}

export default function FileInput({
  maxFileSize,
  onChange,
  ...props
}: FileInputProps): React.ReactElement {
  const reader = new FileReader();

  const clearField = (): void => {
    const input = document.getElementById('fileInput') as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  };

  const fileValidateChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e?.target?.files && e?.target?.files[0]) {
      const file = e?.target?.files[0];

      if (maxFileSize && file?.size >= maxFileSize) {
        clearField();
        onChange(null);

        window.flash({
          message: 'Error',
          subText: `File is larger than ${convertBytesToMegaBytes(maxFileSize).toFixed(
            2
          )} Mb. Please select a smaller file.`,
          type: 'error',
        });
        return;
      }

      onChange(file);
    }
    reader.addEventListener('load', (event: ProgressEvent<FileReader>) => {
      if (
        event.target?.result &&
        typeof event.target.result === 'string' &&
        event.target.result.includes('Encrypt')
      ) {
        clearField();
        onChange(null);
        window.flash({
          message: 'Error',
          subText:
            'The PDF is password protected. Please remove password protection from the file and upload again.',

          type: 'error',
        });
        return false;
      }

      return true;
    });

    if (e.target.files && onChange) {
      reader.readAsText(e.target.files[0]);
    }
  };

  return <input id={'fileInput'} type={'file'} onChange={fileValidateChange} {...props} />;
}
