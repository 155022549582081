/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationGuestsSchema: NodeSchemaProperties = {
  guests: {
    title: '#11 Guests',
    type: 'object',
    properties: {
      addGuest: {
        title: '#1 Add Guest',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[guests.addGuest.title]',
            title: '#1.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'guests:enable': {
            description: '[guests.addGuest.guests]',
            title: '#1.2 Guests',
            type: 'boolean',
          },
          guests: {
            type: 'string',
          },
          'guestDetails:enable': {
            description: '[guests.addGuest.guestDetails]',
            title: '#1.3 Guest Details',
            type: 'boolean',
          },
          guestDetails: {
            type: 'string',
          },
          'cancel:enable': {
            description: '[guests.addGuest.cancel]',
            title: '#1.4 Cancel',
            type: 'boolean',
          },
          cancel: {
            type: 'string',
          },
          'add:enable': {
            description: '[guests.addGuest.add]',
            title: '#1.5 Add',
            type: 'boolean',
          },
          add: {
            type: 'string',
          },
        },
      },
      currentGuest: {
        title: '#2 Current Guest',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[guests.currentGuest.title]',
            title: '#2.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'cancel:enable': {
            description: '[guests.currentGuest.cancel]',
            title: '#2.2 Cancel',
            type: 'boolean',
          },
          cancel: {
            type: 'string',
          },
          'save:enable': {
            description: '[guests.currentGuest.save]',
            title: '#2.3 Save',
            type: 'boolean',
          },
          save: {
            type: 'string',
          },
        },
      },
      guestFormPlaceholders: {
        title: '#3 Guest Form Placeholders',
        type: 'object',
        properties: {
          'firstName:enable': {
            description: '[guests.guestFormPlaceholders.firstName]',
            title: '#3.1 First Name',
            type: 'boolean',
          },
          firstName: {
            type: 'string',
          },
          'lastName:enable': {
            description: '[guests.guestFormPlaceholders.lastName]',
            title: '#3.2 Last Name',
            type: 'boolean',
          },
          lastName: {
            type: 'string',
          },
          'fullName:enable': {
            description: '[guests.guestFormPlaceholders.fullName]',
            title: '#3.3 Full Name',
            type: 'boolean',
          },
          fullName: {
            type: 'string',
          },
          'email:enable': {
            description: '[guests.guestFormPlaceholders.email]',
            title: '#3.4 Email',
            type: 'boolean',
          },
          email: {
            type: 'string',
          },
          'phoneNumber:enable': {
            description: '[guests.guestFormPlaceholders.phoneNumber]',
            title: '#3.5 Phone Number',
            type: 'boolean',
          },
          phoneNumber: {
            type: 'string',
          },
          'errorMessage:enable': {
            description: '[guests.guestFormPlaceholders.errorMessage]',
            title: '#3.6 Error Message',
            type: 'boolean',
          },
          errorMessage: {
            type: 'string',
          },
        },
      },
      guestCard: {
        title: '#4 Guest Card',
        type: 'object',
        properties: {
          'primaryGuest:enable': {
            description: '[guests.guestCard.primaryGuest]',
            title: '#4.1 Primary Guest',
            type: 'boolean',
          },
          primaryGuest: {
            type: 'string',
          },
          'secondaryGuest:enable': {
            description: '[guests.guestCard.secondaryGuest]',
            title: '#4.2 Secondary Guest',
            type: 'boolean',
          },
          secondaryGuest: {
            type: 'string',
          },
          'editButton:enable': {
            description: '[guests.guestCard.editButton]',
            title: '#4.3 Edit Button',
            type: 'boolean',
          },
          editButton: {
            type: 'string',
          },
        },
      },
    },
  },
};

export const mobileV3LocalizationGuestsUiSchema: NodeUiSchemaProperties = {
  guests: {
    addGuest: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      guests: {
        'ui:label': false,
        'ui:readonly': true,
      },
      guestDetails: {
        'ui:label': false,
        'ui:readonly': true,
      },
      cancel: {
        'ui:label': false,
        'ui:readonly': true,
      },
      add: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    currentGuest: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      cancel: {
        'ui:label': false,
        'ui:readonly': true,
      },
      save: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    guestFormPlaceholders: {
      firstName: {
        'ui:label': false,
        'ui:readonly': true,
      },
      lastName: {
        'ui:label': false,
        'ui:readonly': true,
      },
      fullName: {
        'ui:label': false,
        'ui:readonly': true,
      },
      email: {
        'ui:label': false,
        'ui:readonly': true,
      },
      phoneNumber: {
        'ui:label': false,
        'ui:readonly': true,
      },
      errorMessage: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    guestCard: {
      primaryGuest: {
        'ui:label': false,
        'ui:readonly': true,
      },
      secondaryGuest: {
        'ui:label': false,
        'ui:readonly': true,
      },
      editButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
  },
};
