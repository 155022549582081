import { Query, QueryLanguagesArgs, QueryMode, SortOrder } from '../__generated__/graphql';

import GET_LANGUAGES from '../graphql/getLanguages';
import SelectOption from '../@types/react/SelectOption';
import UnitTypeLanguageLocalization from '../components/UnitTypeForm/@types/UnitTypeLanguageLocalization';
import { useApolloClient } from '@apollo/client';
import { useFormContext } from 'react-hook-form';

export interface SelectLanguageOption extends SelectOption {
  isRTL: boolean;
}

type SearchLanguagesData = Pick<Query, 'getLanguages'>;

export default function useLoadLanguageOptions(): (
  input: string
) => Promise<SelectLanguageOption[]> {
  const client = useApolloClient();
  const { getValues } = useFormContext();

  return async (input: string): Promise<SelectLanguageOption[]> => {
    const addedLocalizations = getValues('languages')?.values as Array<
      UnitTypeLanguageLocalization
    >;
    let selectedLanguages: string[] = [];
    if (addedLocalizations?.length > 0) {
      selectedLanguages = addedLocalizations.map((_localization) => _localization.language.id);
    }

    const { data } = await client.query<SearchLanguagesData, QueryLanguagesArgs>({
      query: GET_LANGUAGES,
      variables: {
        orderBy: [
          {
            name: SortOrder.Asc,
          },
        ],
        take: 10,
        where: {
          id: {
            notIn: selectedLanguages,
          },
          OR: [
            {
              name: {
                contains: input,
                mode: QueryMode.Insensitive,
              },
            },
            {
              code: {
                contains: input,
                mode: QueryMode.Insensitive,
              },
            },
          ],
        },
      },
    });

    return (
      data.getLanguages?.map((language) => ({
        isRTL: language.isRTL ?? false,
        label: `${language.code} - ${language.name}`,
        value: language.id,
      })) ?? []
    );
  };
}
