import {
  Mutation,
  MutationUpdateOneVendorServerArgs,
  VendorServerCreateInput,
  VendorServerUpdateInput,
} from '../../../__generated__/graphql';

import MutationHookData from '../../../hooks/@types/MutationHookData';
import MutationHookParams from '../../../hooks/@types/MutationHookParams';
import UPDATE_VENDOR_SERVER from '../../../graphql/updateVendorServer';
import { closeForm } from '../../../redux/actions/modals.actions';
import { flashApolloError } from '../../../util/errorUtils';
import transformCreateInputIntoUpdateInput from '../../../util/api/transformCreateInputIntoUpdateInput';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';

type UpdateVendorServerData = Pick<Mutation, 'updateOneVendorServer'>;

export default function useUpdateVendorServer({
  onCompleted = (): void => {},
  onError = flashApolloError,
}: MutationHookParams<UpdateVendorServerData> = {}): MutationHookData<
  VendorServerCreateInput,
  UpdateVendorServerData
> {
  const dispatch = useDispatch();

  const [update, { data, error, loading }] = useMutation<
    UpdateVendorServerData,
    MutationUpdateOneVendorServerArgs
  >(UPDATE_VENDOR_SERVER, {
    onCompleted: ({ updateOneVendorServer }) => {
      if (updateOneVendorServer) {
        window.flash({
          message: `Vendor Server: ${updateOneVendorServer.vendor} updated successfully`,
        });

        onCompleted({ updateOneVendorServer });

        dispatch(closeForm());
      }
    },
    onError,
  });

  return {
    data,
    error,
    loading,
    mutation: (inputs: VendorServerCreateInput, id = ''): void => {
      const updateInputs: VendorServerUpdateInput = {
        ...transformCreateInputIntoUpdateInput<VendorServerUpdateInput>({
          ...inputs,
          additionalConfig: undefined,
          Location: undefined,
        }),
        additionalConfig: inputs.additionalConfig,
        Location: inputs.Location,
      };

      update({
        variables: {
          data: updateInputs,
          where: { id },
        },
      });
    },
  };
}
