import CREDENTIAL_STATUS from '../constants/CredentialStatus';

const determineCredentialStatus = (
  credentialId: string,
  isActive: boolean,
  credentialsBeingProcessed: string[]
): string => {
  const credentialInProcess = credentialsBeingProcessed.includes(credentialId);
  if (credentialInProcess) {
    return CREDENTIAL_STATUS.REVOKING;
  }

  return isActive ? CREDENTIAL_STATUS.ACTIVE : CREDENTIAL_STATUS.REVOKED;
};

export default determineCredentialStatus;
