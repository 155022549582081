import {
  confirmationEmailLocalizationSchema,
  confirmationEmailLocalizationUiSchema,
} from '../../../../../components/JsonEditor/constants/LocalizationSchema/confirmationEmailLocalizationSchema';

import CustomizeLanguageLocalization from '../../../../../components/CustomizeLocalization/components/CustomizeLanguageLocalization';
import { CustomizeThemeLocalizationEntityLevel } from '../../../../../enums/CustomizeThemeLocalizationEntityLevel';
import { LocalizationComponentName } from '../../../../../enums/ComponentNameEnum';
import React from 'react';
import { useParams } from 'react-router-dom';

const CustomizeConfirmationEmailLocalization: React.FC<{}> = () => {
  const { locationId } = useParams<{ locationId: string }>();

  return (
    <CustomizeLanguageLocalization
      title={'Customize confirmation e-mail localization'}
      componentName={LocalizationComponentName.CONFIRMATION_EMAIL}
      schema={confirmationEmailLocalizationSchema}
      uiSchema={confirmationEmailLocalizationUiSchema}
      fileNameToDownloadDefaultJson={`Default confirmation e-mail localization - Location ${locationId} - Language \${LANGUAGE_NAME}.json`}
      fileNameToDownloadCurrentJson={`Current confirmation e-mail localization - Location ${locationId} - Language \${LANGUAGE_NAME}.json`}
      entityLevel={CustomizeThemeLocalizationEntityLevel.LOCATION}
      entityId={locationId}
      mockMainJsonNode={true}
    />
  );
};

export default CustomizeConfirmationEmailLocalization;
