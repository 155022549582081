import { gql } from '../__generated__';

const GET_INFO_TYPES = gql(`
  query GetInfoTypes {
    infoTypes {
      id
      title
      shouldAppearInDashboard
    }
  }
`);

export default GET_INFO_TYPES;
