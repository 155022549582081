import { AccessGrantWhereInput } from '../../../__generated__/graphql';
import SearchField from '../../../@types/SearchField';
import createSearchField from '../../../util/tableData/createSearchField';
import transformStringFilter from '../../../util/tableData/transformStringFilter';
import transformWhereRelationFilterInput from '../../../util/tableData/transformWhereRelationFilterInput';

const ACCESS_GRANTS_SEARCH_FIELDS: SearchField<AccessGrantWhereInput>[] = [
  createSearchField<AccessGrantWhereInput, 'confirmationCode'>({
    label: 'Code',
    queryField: 'confirmationCode',
    transform: transformStringFilter,
  }),
  createSearchField<AccessGrantWhereInput, 'user'>({
    label: 'Guest',
    queryField: 'user',
    transform: transformWhereRelationFilterInput,
  }),
  createSearchField<AccessGrantWhereInput, 'location'>({
    label: 'Location',
    queryField: 'location',
    transform: transformWhereRelationFilterInput,
  }),
];

export default ACCESS_GRANTS_SEARCH_FIELDS;
