import {
  Amenity,
  AmenityGroup,
  Location,
  Maybe,
  Query,
  QueryAmenityArgs,
} from '../__generated__/graphql';

import GET_AMENITY from '../graphql/getAmenity';
import QueryOneHookData from './@types/QueryOneHookData';
import { flashApolloError } from '../util/errorUtils';
import { useLazyQuery } from '@apollo/client';

type GetAmenityData = Pick<Query, 'amenity'>;

export default function useGetAmenityById(): QueryOneHookData<
  Pick<Amenity, 'id' | 'title' | 'description' | 'imageUrl'> & {
    location?: Maybe<Pick<Location, 'id' | 'name'>>;
    group?: Maybe<Pick<AmenityGroup, 'id' | 'title'>>;
  }
> {
  const [getAmenity, { called, data, error, loading }] = useLazyQuery<
    GetAmenityData,
    QueryAmenityArgs
  >(GET_AMENITY, {
    fetchPolicy: 'network-only',
    onCompleted: () => {},
    onError: flashApolloError,
  });

  return {
    called,
    data: data?.amenity ?? undefined,
    error,
    loading,
    query: (id: string): void => {
      getAmenity({
        variables: {
          where: { id },
        },
      });
    },
  };
}
