/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationUserDetailsSchema: NodeSchemaProperties = {
  userDetailsScreen: {
    title: '#10 User Details Screen',
    type: 'object',
    properties: {
      'guests:enable': {
        description: '[userDetailsScreen.guests]',
        title: '#1 Guests',
        type: 'boolean',
      },
      guests: {
        type: 'string',
      },
      'nameOnReservation:enable': {
        description: '[userDetailsScreen.nameOnReservation]',
        title: '#2 Name On Reservation',
        type: 'boolean',
      },
      nameOnReservation: {
        type: 'string',
      },
      'otherGuests:enable': {
        description: '[userDetailsScreen.otherGuests]',
        title: '#3 Other Guests',
        type: 'boolean',
      },
      otherGuests: {
        type: 'string',
      },
      header: {
        title: '#4 Header',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[userDetailsScreen.header.title]',
            title: '#4.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'description:enable': {
            description: '[userDetailsScreen.header.description]',
            title: '#4.2 Description',
            type: 'boolean',
          },
          description: {
            type: 'string',
          },
        },
      },
      'noResourceFound:enable': {
        description: '[userDetailsScreen.noResourceFound]',
        title: '#5 No Resource Found',
        type: 'boolean',
      },
      noResourceFound: {
        type: 'string',
      },
      'footerButtonText:enable': {
        description: '[userDetailsScreen.footerButtonText]',
        title: '#6 Footer Button Text',
        type: 'boolean',
      },
      footerButtonText: {
        type: 'string',
      },
    },
  },
};

export const mobileV3LocalizationUserDetailsUiSchema: NodeUiSchemaProperties = {
  userDetailsScreen: {
    guests: {
      'ui:label': false,
      'ui:readonly': true,
    },
    nameOnReservation: {
      'ui:label': false,
      'ui:readonly': true,
    },
    otherGuests: {
      'ui:label': false,
      'ui:readonly': true,
    },
    header: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    noResourceFound: {
      'ui:label': false,
      'ui:readonly': true,
    },
    footerButtonText: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
};
