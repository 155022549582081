import { gql } from '../__generated__/gql';

const CONNECT_WITH_CLOUDBEDS_API = gql(`
  mutation cloudbedsConnectApp($token: String!, $locationId: String!) {
    cloudbedsConnectApp(token: $token, locationId: $locationId) {
      success
    }
  }
`);

export default CONNECT_WITH_CLOUDBEDS_API;
