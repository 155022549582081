import FormTemplate from '../../templates/Form';
import { Location } from '../../__generated__/graphql';
import POLL_RESERVATION_FORM_STEPS from './constants/pollReservationFormSteps';
import POLL_RESERVATION_FROM_CSV_VALIDATION_SCHEMA from './constants/pollReservationValidationSchema';
import PollReservationFormInputs from './@types/PollReservationFormInputs';
import PollReservationTypesData from './@types/PollReservationTypesData';
import React from 'react';

interface AccessGrantAditionalDataOnOpeningFormInterface {
  location: Pick<Location, 'id' | 'name'>;
}

interface AccessGrantPollReservationFormProps {
  aditionalDataOnOpeningForm?: unknown;
}

const AccessGrantPollReservationForm: React.FC<AccessGrantPollReservationFormProps> = ({
  aditionalDataOnOpeningForm,
}: AccessGrantPollReservationFormProps) => {
  const initialValues = (): Partial<PollReservationFormInputs> => {
    const defaultInitialValues: Partial<PollReservationFormInputs> = {};

    try {
      if (aditionalDataOnOpeningForm) {
        const {
          location,
        } = aditionalDataOnOpeningForm as AccessGrantAditionalDataOnOpeningFormInterface;
        return {
          ...defaultInitialValues,
          location: {
            label: location.name,
            value: location.id,
          },
        };
      }
      // eslint-disable-next-line no-empty
    } catch {}

    return defaultInitialValues;
  };

  return (
    <FormTemplate<PollReservationFormInputs, PollReservationTypesData>
      initialValues={initialValues()}
      formHeader={'Poll reservation'}
      steps={POLL_RESERVATION_FORM_STEPS}
      validationSchema={POLL_RESERVATION_FROM_CSV_VALIDATION_SCHEMA}
    />
  );
};

export default AccessGrantPollReservationForm;
