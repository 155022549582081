import React from 'react';

export const ActionBarConfirmationModalUserIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="#000"
        fillRule="evenodd"
        d="M9 16a3 3 0 00-3 3 1 1 0 001 1h10a1 1 0 001-1 3 3 0 00-3-3H9zm-5 3a5 5 0 015-5h6a5 5 0 015 5 3 3 0 01-3 3H7a3 3 0 01-3-3zM12 4a3 3 0 100 6 3 3 0 000-6zM7 7a5 5 0 1110 0A5 5 0 017 7z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
