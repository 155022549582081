import { gql } from '../__generated__/gql';

const GET_KIOSK_TABLE_DATA = gql(`
  query GetKioskTableData(
    $skip: Int
    $orderBy: [KioskOrderByWithRelationInput!]
    $take: Int
    $where: KioskWhereInput
  ) {
    kiosks(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      ...KioskTableFields
    }
  }
`);

export default GET_KIOSK_TABLE_DATA;
