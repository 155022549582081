import { gql } from '../__generated__/gql';

const UPLOAD_FILE = gql(`
  mutation uploadFile($locationId: String!, $file: Upload!, $fileType: FileType!) {
    uploadFile(locationId: $locationId, file: $file, fileType: $fileType) {
      id
      url
    }
  }
`);

export default UPLOAD_FILE;
