/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3ThemeReservationSchema: NodeSchemaProperties = {
  reservationScreen: {
    title: '#5 Reservation Screen',
    type: 'object',
    properties: {
      detailsScreen: {
        title: '#1 Details Screen',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[reservationScreen.detailsScreen.backgroundColor]',
            title: '#1.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'titleTextColor:enable': {
            description: '[reservationScreen.detailsScreen.titleTextColor]',
            title: '#1.2 Title Text Color',
            type: 'boolean',
          },
          titleTextColor: {
            type: 'string',
          },
          'descriptionTextColor:enable': {
            description: '[reservationScreen.detailsScreen.descriptionTextColor]',
            title: '#1.3 Description Text Color',
            type: 'boolean',
          },
          descriptionTextColor: {
            type: 'string',
          },
          'listTitleTextColor:enable': {
            description: '[reservationScreen.detailsScreen.listTitleTextColor]',
            title: '#1.4 List Title Text Color',
            type: 'boolean',
          },
          listTitleTextColor: {
            type: 'string',
          },
          'editButtonTextColor:enable': {
            description: '[reservationScreen.detailsScreen.editButtonTextColor]',
            title: '#1.5 Edit Button Text Color',
            type: 'boolean',
          },
          editButtonTextColor: {
            type: 'string',
          },
          'editButtonBackgroundColor:enable': {
            description: '[reservationScreen.detailsScreen.editButtonBackgroundColor]',
            title: '#1.6 Edit Button Background Color',
            type: 'boolean',
          },
          editButtonBackgroundColor: {
            type: 'string',
          },
          'editButtonBorderRadius:enable': {
            description: '[reservationScreen.detailsScreen.editButtonBorderRadius]',
            title: '#1.7 Edit Button Border Radius',
            type: 'boolean',
          },
          editButtonBorderRadius: {
            type: 'number',
          },
          'continueButtonTextColor:enable': {
            description: '[reservationScreen.detailsScreen.continueButtonTextColor]',
            title: '#1.8 Continue Button Text Color',
            type: 'boolean',
          },
          continueButtonTextColor: {
            type: 'string',
          },
          'continueButtonBackgroundColor:enable': {
            description: '[reservationScreen.detailsScreen.continueButtonBackgroundColor]',
            title: '#1.9 Continue Button Background Color',
            type: 'boolean',
          },
          continueButtonBackgroundColor: {
            type: 'string',
          },
          'editButtonBorderWidth:enable': {
            description: '[reservationScreen.detailsScreen.editButtonBorderWidth]',
            title: '#1.10 Edit Button Border Width',
            type: 'boolean',
          },
          editButtonBorderWidth: {
            type: 'number',
          },
          'avatarBackgroundColor:enable': {
            description: '[reservationScreen.detailsScreen.avatarBackgroundColor]',
            title: '#1.11 Avatar Background Color',
            type: 'boolean',
          },
          avatarBackgroundColor: {
            type: 'string',
          },
          'avatarTextColor:enable': {
            description: '[reservationScreen.detailsScreen.avatarTextColor]',
            title: '#1.12 Avatar Text Color',
            type: 'boolean',
          },
          avatarTextColor: {
            type: 'string',
          },
          'roomInfoVisible:enable': {
            description: '[reservationScreen.detailsScreen.roomInfoVisible]',
            title: '#1.13 Room Info Visible',
            type: 'boolean',
          },
          roomInfoVisible: {
            type: undefined,
            enum: [true, false],
          },
          'enableUsersEditButton:enable': {
            description: '[reservationScreen.detailsScreen.enableUsersEditButton]',
            title: '#1.14 Enable Users Edit Button',
            type: 'boolean',
          },
          enableUsersEditButton: {
            type: undefined,
            enum: [true, false],
          },
          'nameTextColor:enable': {
            description: '[reservationScreen.detailsScreen.nameTextColor]',
            title: '#1.15 Name Text Color',
            type: 'boolean',
          },
          nameTextColor: {
            type: 'string',
          },
          'emailTextColor:enable': {
            description: '[reservationScreen.detailsScreen.emailTextColor]',
            title: '#1.16 Email Text Color',
            type: 'boolean',
          },
          emailTextColor: {
            type: 'string',
          },
          'phoneTextColor:enable': {
            description: '[reservationScreen.detailsScreen.phoneTextColor]',
            title: '#1.17 Phone Text Color',
            type: 'boolean',
          },
          phoneTextColor: {
            type: 'string',
          },
          'subtitleTextColor:enable': {
            description: '[reservationScreen.detailsScreen.subtitleTextColor]',
            title: '#1.18 Subtitle Text Color',
            type: 'boolean',
          },
          subtitleTextColor: {
            type: 'string',
          },
        },
      },
      editScreen: {
        title: '#2 Edit Screen',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[reservationScreen.editScreen.backgroundColor]',
            title: '#2.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'inputTextColor:enable': {
            description: '[reservationScreen.editScreen.inputTextColor]',
            title: '#2.2 Input Text Color',
            type: 'boolean',
          },
          inputTextColor: {
            type: 'string',
          },
          'inputBackgroundColor:enable': {
            description: '[reservationScreen.editScreen.inputBackgroundColor]',
            title: '#2.3 Input Background Color',
            type: 'boolean',
          },
          inputBackgroundColor: {
            type: 'string',
          },
          'inputBorderRadius:enable': {
            description: '[reservationScreen.editScreen.inputBorderRadius]',
            title: '#2.4 Input Border Radius',
            type: 'boolean',
          },
          inputBorderRadius: {
            type: 'number',
          },
          'inputBorderColor:enable': {
            description: '[reservationScreen.editScreen.inputBorderColor]',
            title: '#2.5 Input Border Color',
            type: 'boolean',
          },
          inputBorderColor: {
            type: 'string',
          },
          'inputBorderWidth:enable': {
            description: '[reservationScreen.editScreen.inputBorderWidth]',
            title: '#2.6 Input Border Width',
            type: 'boolean',
          },
          inputBorderWidth: {
            type: 'number',
          },
          'saveButtonTextColor:enable': {
            description: '[reservationScreen.editScreen.saveButtonTextColor]',
            title: '#2.7 Save Button Text Color',
            type: 'boolean',
          },
          saveButtonTextColor: {
            type: 'string',
          },
          'saveButtonBackgroundColor:enable': {
            description: '[reservationScreen.editScreen.saveButtonBackgroundColor]',
            title: '#2.8 Save Button Background Color',
            type: 'boolean',
          },
          saveButtonBackgroundColor: {
            type: 'string',
          },
          'saveButtonBorderColor:enable': {
            description: '[reservationScreen.editScreen.saveButtonBorderColor]',
            title: '#2.9 Save Button Border Color',
            type: 'boolean',
          },
          saveButtonBorderColor: {
            type: 'string',
          },
          'saveButtonBorderWidth:enable': {
            description: '[reservationScreen.editScreen.saveButtonBorderWidth]',
            title: '#2.10 Save Button Border Width',
            type: 'boolean',
          },
          saveButtonBorderWidth: {
            type: 'number',
          },
          'avatarBackgroundColor:enable': {
            description: '[reservationScreen.editScreen.avatarBackgroundColor]',
            title: '#2.11 Avatar Background Color',
            type: 'boolean',
          },
          avatarBackgroundColor: {
            type: 'string',
          },
          'avatarTextColor:enable': {
            description: '[reservationScreen.editScreen.avatarTextColor]',
            title: '#2.12 Avatar Text Color',
            type: 'boolean',
          },
          avatarTextColor: {
            type: 'string',
          },
        },
      },
    },
  },
};

export const mobileV3ThemeReservationUiSchema: NodeUiSchemaProperties = {
  reservationScreen: {
    detailsScreen: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      titleTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      descriptionTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      listTitleTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      editButtonTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      editButtonBackgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      editButtonBorderRadius: {
        'ui:label': false,
        'ui:readonly': true,
      },
      continueButtonTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      continueButtonBackgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      editButtonBorderWidth: {
        'ui:label': false,
        'ui:readonly': true,
      },
      avatarBackgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      avatarTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      roomInfoVisible: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      enableUsersEditButton: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
      nameTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      emailTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      phoneTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      subtitleTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    editScreen: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      inputTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      inputBackgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      inputBorderRadius: {
        'ui:label': false,
        'ui:readonly': true,
      },
      inputBorderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      inputBorderWidth: {
        'ui:label': false,
        'ui:readonly': true,
      },
      saveButtonTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      saveButtonBackgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      saveButtonBorderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      saveButtonBorderWidth: {
        'ui:label': false,
        'ui:readonly': true,
      },
      avatarBackgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      avatarTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
  },
};
