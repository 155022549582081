import Filter from '../../../../components/DataTable/@types/Filter';
import addKioskDispenserFilters from '../../util/addKioskDispenserFilters';
import addKioskStatusFilters from '../../util/addKioskStatusFilters';
import addKioskTerminalFilters from '../../util/addKioskReaderFilters';

export default function useKioskFilters(): Filter[] {
  const filters: Filter[] = [];

  addKioskStatusFilters(filters);
  addKioskDispenserFilters(filters);
  addKioskTerminalFilters(filters);

  return filters;
}
