/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3ThemeTripsSchema: NodeSchemaProperties = {
  trips: {
    title: '#8 Trips',
    type: 'object',
    properties: {
      selectedTab: {
        title: '#1 Selected Tab',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[trips.selectedTab.backgroundColor]',
            title: '#1.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'textColor:enable': {
            description: '[trips.selectedTab.textColor]',
            title: '#1.2 Text Color',
            type: 'boolean',
          },
          textColor: {
            type: 'string',
          },
          'borderColor:enable': {
            description: '[trips.selectedTab.borderColor]',
            title: '#1.3 Border Color',
            type: 'boolean',
          },
          borderColor: {
            type: 'string',
          },
          'borderWidth:enable': {
            description: '[trips.selectedTab.borderWidth]',
            title: '#1.4 Border Width',
            type: 'boolean',
          },
          borderWidth: {
            type: 'integer',
          },
          'borderRadius:enable': {
            description: '[trips.selectedTab.borderRadius]',
            title: '#1.5 Border Radius',
            type: 'boolean',
          },
          borderRadius: {
            type: 'integer',
          },
        },
      },
      deselectedTab: {
        title: '#2 Deselected Tab',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[trips.deselectedTab.backgroundColor]',
            title: '#2.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'textColor:enable': {
            description: '[trips.deselectedTab.textColor]',
            title: '#2.2 Text Color',
            type: 'boolean',
          },
          textColor: {
            type: 'string',
          },
          'borderColor:enable': {
            description: '[trips.deselectedTab.borderColor]',
            title: '#2.3 Border Color',
            type: 'boolean',
          },
          borderColor: {
            type: 'string',
          },
          'borderWidth:enable': {
            description: '[trips.deselectedTab.borderWidth]',
            title: '#2.4 Border Width',
            type: 'boolean',
          },
          borderWidth: {
            type: 'integer',
          },
          'borderRadius:enable': {
            description: '[trips.deselectedTab.borderRadius]',
            title: '#2.5 Border Radius',
            type: 'boolean',
          },
          borderRadius: {
            type: 'integer',
          },
        },
      },
      bookingCard: {
        title: '#3 Booking Card',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[trips.bookingCard.backgroundColor]',
            title: '#3.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'textColor:enable': {
            description: '[trips.bookingCard.textColor]',
            title: '#3.2 Text Color',
            type: 'boolean',
          },
          textColor: {
            type: 'string',
          },
          'borderColor:enable': {
            description: '[trips.bookingCard.borderColor]',
            title: '#3.3 Border Color',
            type: 'boolean',
          },
          borderColor: {
            type: 'string',
          },
          'borderWidth:enable': {
            description: '[trips.bookingCard.borderWidth]',
            title: '#3.4 Border Width',
            type: 'boolean',
          },
          borderWidth: {
            type: 'integer',
          },
          'borderRadius:enable': {
            description: '[trips.bookingCard.borderRadius]',
            title: '#3.5 Border Radius',
            type: 'boolean',
          },
          borderRadius: {
            type: 'integer',
          },
        },
      },
      titleText: {
        title: '#4 Title text',
        type: 'object',
        properties: {
          'color:enable': {
            description: '[trips.titleText.color]',
            title: '#4.1 Text color',
            type: 'boolean',
          },
          color: {
            type: 'string',
          },
          'fontFamily:enable': {
            description: '[trips.titleText.fontFamily]',
            title: '#4.2 Font family',
            type: 'boolean',
          },
          fontFamily: {
            type: 'string',
          },
          'fontSize:enable': {
            description: '[trips.titleText.fontSize]',
            title: '#4.3 Font size',
            type: 'boolean',
          },
          fontSize: {
            type: 'integer',
          },
          'lineHeight:enable': {
            description: '[trips.titleText.lineHeight]',
            title: '#4.4 Line height',
            type: 'boolean',
          },
          lineHeight: {
            type: 'integer',
          },
          'fontWeight:enable': {
            description: '[trips.titleText.fontWeight]',
            title: '#4.5 Font weight',
            type: 'boolean',
          },
          fontWeight: {
            type: undefined,
            enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
          },
        },
      },
      subtitleText: {
        title: '#5 Subtitle text',
        type: 'object',
        properties: {
          'color:enable': {
            description: '[trips.subtitleText.color]',
            title: '#5.1 Text color',
            type: 'boolean',
          },
          color: {
            type: 'string',
          },
          'fontFamily:enable': {
            description: '[trips.subtitleText.fontFamily]',
            title: '#5.2 Font family',
            type: 'boolean',
          },
          fontFamily: {
            type: 'string',
          },
          'fontSize:enable': {
            description: '[trips.subtitleText.fontSize]',
            title: '#5.3 Font size',
            type: 'boolean',
          },
          fontSize: {
            type: 'integer',
          },
          'lineHeight:enable': {
            description: '[trips.subtitleText.lineHeight]',
            title: '#5.4 Line height',
            type: 'boolean',
          },
          lineHeight: {
            type: 'integer',
          },
          'fontWeight:enable': {
            description: '[trips.subtitleText.fontWeight]',
            title: '#5.5 Font weight',
            type: 'boolean',
          },
          fontWeight: {
            type: undefined,
            enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
          },
        },
      },
      dateText: {
        title: '#5 Date text',
        type: 'object',
        properties: {
          'color:enable': {
            description: '[trips.dateText.color]',
            title: '#6.1 Text color',
            type: 'boolean',
          },
          color: {
            type: 'string',
          },
          'fontFamily:enable': {
            description: '[trips.dateText.fontFamily]',
            title: '#6.2 Font family',
            type: 'boolean',
          },
          fontFamily: {
            type: 'string',
          },
          'fontSize:enable': {
            description: '[trips.dateText.fontSize]',
            title: '#6.3 Font size',
            type: 'boolean',
          },
          fontSize: {
            type: 'integer',
          },
          'lineHeight:enable': {
            description: '[trips.dateText.lineHeight]',
            title: '#6.4 Line height',
            type: 'boolean',
          },
          lineHeight: {
            type: 'integer',
          },
          'fontWeight:enable': {
            description: '[trips.dateText.fontWeight]',
            title: '#6.5 Font weight',
            type: 'boolean',
          },
          fontWeight: {
            type: undefined,
            enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
          },
        },
      },
    },
  },
};

export const mobileV3ThemeTripsUiSchema: NodeUiSchemaProperties = {
  trips: {
    selectedTab: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderWidth: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
      borderRadius: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
    },
    deselectedTab: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderWidth: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
      borderRadius: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
    },
    bookingCard: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderWidth: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
      borderRadius: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
    },
    titleText: {
      color: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      fontFamily: {
        'ui:label': false,
        'ui:readonly': true,
      },
      fontSize: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
      lineHeight: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
      fontWeight: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
    },
    subtitleText: {
      color: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      fontFamily: {
        'ui:label': false,
        'ui:readonly': true,
      },
      fontSize: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
      lineHeight: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
      fontWeight: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
    },
    dateText: {
      color: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      fontFamily: {
        'ui:label': false,
        'ui:readonly': true,
      },
      fontSize: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
      lineHeight: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
      fontWeight: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
    },
  },
};
