import ACCESS_GRANT_REQUIREMENT_HEADERS from '../constants/AccessGrantRequirementHeaders';
import { AccessGrantRequirementPageInfo } from '../@types/GetAccessGrantPageInfo';
import DataTable from '../../../components/DataTable';
import { EntityEnum } from '../../../@types/client/DashboardUserRolePermissions';
import React from 'react';
import generateAccessGrantRequirementTableData from '../functions/generateAccessGrantRequirementTableData';
import useDateTimeFormatter from '../../../hooks/useDateTimeFormatter';

interface RequirementsTabProps {
  requirements: AccessGrantRequirementPageInfo[];
  locationTimezone?: string;
}

const RequirementsTab: React.FC<RequirementsTabProps> = ({
  requirements,
  locationTimezone,
}: RequirementsTabProps) => {
  const dateTimeFormatter = useDateTimeFormatter();
  const { count, tableData } = generateAccessGrantRequirementTableData(
    dateTimeFormatter,
    {
      requirements,
    },
    locationTimezone
  );

  return (
    <DataTable
      count={count}
      data={tableData}
      headers={ACCESS_GRANT_REQUIREMENT_HEADERS}
      isLoading={false}
      permission={EntityEnum.NONE}
    />
  );
};

export default RequirementsTab;
