import * as yup from 'yup';

import {
  AccessGrantStatus,
  AccessGrantType,
  RequirementType,
} from '../../../__generated__/graphql';

import HOURS_REGEX from '../../../constants/hoursRegex';

const ACCESS_GRANT_VALIDATION_SCHEMA = yup.object().shape({
  code: yup.string(),
  confirmationCode: yup.string(),
  endsAt: yup.date().required('Dates are required'),
  endTime: yup
    .string()
    .matches(HOURS_REGEX, { excludeEmptyString: true, message: 'Invalid time format (ex: 16:00)' })
    .required('End Time is required'),
  location: yup.object().shape({
    label: yup.string().required('Location is required'),
    value: yup.string().required('Location is required'),
  }),
  requirements: yup.object().shape({
    delete: yup.array(),
    values: yup.array(
      yup.object().shape({
        metadata: yup.object().when('type', {
          is: RequirementType.CaptureSecurityDeposit,
          otherwise: yup.object().nullable(),
          then: yup.object().shape({
            amount: yup
              .number()
              .typeError('Please enter value.')
              .required('Please enter value.')
              .min(1, 'amount must be greater than 0.'),
          }),
        }),
        requirementId: yup.string(),
        status: yup.string().required('Status is required'),
        type: yup.string().required('Type is required'),
      })
    ),
  }),
  resources: yup.object(),
  secondaryUsers: yup.object(),
  startsAt: yup.date().required('Dates are required'),
  startTime: yup
    .string()
    .matches(HOURS_REGEX, { excludeEmptyString: true, message: 'Invalid time format (ex: 16:00)' })
    .required('Start Time is required'),
  status: yup.mixed<AccessGrantStatus>().oneOf(Object.values(AccessGrantStatus)),
  type: yup.mixed<AccessGrantType>().oneOf(Object.values(AccessGrantType)).required(),
  user: yup
    .object()
    .shape({
      label: yup.string().required('User is required'),
      value: yup.string().required('User is required'),
    })
    .required('User is required'),
});

export default ACCESS_GRANT_VALIDATION_SCHEMA;
