import { Colors } from '../../../theme/colors';

const SelectInputContainerStyle = `
  border-radius: 8px;

  &.error {
    border: 2px solid ${Colors.salmon};
  }

  &:focus {
    border: 2px solid ${Colors.mountainMeadow};
  }
`;

export default SelectInputContainerStyle;
