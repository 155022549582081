import React from 'react';

export const ActionBarModalErrorIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="#DC143C"
        fillRule="evenodd"
        d="M14.383 4.14l6.658 13.338c.884 1.773-.405 3.857-2.386 3.857H5.34c-1.981 0-3.27-2.084-2.386-3.857L9.612 4.14c.982-1.967 3.79-1.967 4.771 0zM7.226 2.95c1.964-3.936 7.58-3.936 9.543 0l6.658 13.337c1.77 3.546-.81 7.715-4.772 7.715H5.34c-3.963 0-6.542-4.17-4.772-7.715L7.226 2.949zm4.771 2.385c.737 0 1.334.597 1.334 1.334v5.333a1.333 1.333 0 01-2.667 0V6.67c0-.737.597-1.334 1.333-1.334zm-1.333 12a1.333 1.333 0 112.667 0 1.333 1.333 0 01-2.667 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
