import React, { useMemo } from 'react';

export const ActionBarArrow: React.FC<{
  color?: string;
}> = ({ color }) => {
  const fillColor = useMemo(() => color || '#fff', [color]);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M13.707 15.707a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414l3-3a1 1 0 111.414 1.414L11.414 12l2.293 2.293a1 1 0 010 1.414z"
        clipRule="evenodd"
      ></path>
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M13.643 21h-3.286c-1.084 0-1.958 0-2.666-.058-.728-.06-1.369-.185-1.96-.487a5 5 0 01-2.186-2.185c-.302-.592-.428-1.232-.487-1.961C3 15.6 3 14.727 3 13.643v-3.286c0-1.084 0-1.958.058-2.666.06-.728.185-1.369.487-1.961A5 5 0 015.73 3.545c.592-.302 1.233-.428 1.961-.487C8.4 3 9.273 3 10.357 3h3.286c1.084 0 1.958 0 2.666.058.729.06 1.369.185 1.961.487a5 5 0 012.185 2.185c.302.592.428 1.233.487 1.961C21 8.4 21 9.273 21 10.357v3.286c0 1.084 0 1.958-.058 2.666-.06.729-.185 1.369-.487 1.961a5 5 0 01-2.185 2.185c-.592.302-1.232.428-1.961.487C15.6 21 14.727 21 13.643 21zm2.503-2.051c.605-.05.953-.142 1.216-.276a3 3 0 001.311-1.311c.134-.263.226-.611.276-1.216.05-.617.051-1.41.051-2.546v-3.2c0-1.137 0-1.929-.051-2.546-.05-.605-.142-.953-.276-1.216a3 3 0 00-1.311-1.311c-.263-.134-.611-.226-1.216-.276C15.529 5.001 14.736 5 13.6 5h-3.2c-1.137 0-1.929 0-2.546.051-.605.05-.953.142-1.216.276a3 3 0 00-1.311 1.311c-.134.263-.226.611-.276 1.216C5.001 8.471 5 9.264 5 10.4v3.2c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 001.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051h3.2c1.137 0 1.929 0 2.546-.051z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
