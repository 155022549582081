import { gql } from '../../__generated__/gql';

export const ENROLL_KIOSK = gql(`
  mutation EnrollKiosk($code: String!, $locationId: String!, $name: String) {
    enrollKiosk(code: $code, locationId: $locationId, name: $name) {
      id
      lastSeenAt
      locationId
      location {
        id
        name
      }
      name
    }
  }
`);

export const UPSERT_KIOSK_LOCALIZATION = gql(`
  mutation upsertKioskLocalization(
    $componentName: String!
    $languageId: String!
    $kioskId: String!
    $localization: String!
  ) {
    upsertKioskLocalization(
      componentName: $componentName
      languageId: $languageId
      kioskId: $kioskId
      localization: $localization
    ) {
      ...BaseSettingAnswerFields
    }
  }
`);

export const DELETE_KIOSK_THEME = gql(`
  mutation deleteKioskTheme($componentName: String!, $kioskId: String!) {
    deleteKioskTheme(componentName: $componentName, kioskId: $kioskId) {
      ...BaseSettingAnswerFields
    }
  }
`);

export const DELETE_KIOSK_LOCALIZATION = gql(`
  mutation deleteKioskLocalization(
    $componentName: String!
    $languageId: String!
    $kioskId: String!
  ) {
    deleteKioskLocalization(
      componentName: $componentName
      languageId: $languageId
      kioskId: $kioskId
    ) {
      ...BaseSettingAnswerFields
    }
  }
`);

export const UPSERT_KIOSK_THEME = gql(`
  mutation upsertKioskTheme($componentName: String!, $kioskId: String!, $setting: String!) {
    upsertKioskTheme(componentName: $componentName, kioskId: $kioskId, setting: $setting) {
      ...BaseSettingAnswerFields
    }
  }
`);
