import { Location, Mutation } from '../../__generated__/graphql';
import React, { useEffect } from 'react';

import FormCrudAction from '../../enums/FormCrudAction.enum';
import FormTemplate from '../../templates/Form';
import KioskFormInputs from './@types/KioskFormInputs';
import getKioskSteps from './functions/kioskFormSteps';
import { kioskMaintenanceToMaintenanceTimeWindow } from './functions/maintenanceTimeWindowUtils';
import useGetKioskById from '../../hooks/useGetKioskById';

type EnrollKioskData = Pick<Mutation, 'enrollKiosk'>;

interface KioskAditionalDataOnOpeningFormInterface {
  location: Pick<Location, 'id' | 'name'>;
}

interface KioskProps {
  id?: string;
  aditionalDataOnOpeningForm?: unknown;
}

const KioskForm: React.FC<KioskProps> = ({ id, aditionalDataOnOpeningForm }: KioskProps) => {
  const { data: kiosk, called, query } = useGetKioskById();

  useEffect(() => {
    if (!called && id) {
      query(id);
    }
  }, [called, id, query]);

  if (id && !kiosk) {
    return <></>;
  }

  const initialValues = (): Partial<KioskFormInputs> => {
    if (kiosk) {
      const maintenanceTimeWindow =
        kiosk.maintenanceStartsAt || kiosk.maintenanceEndsAt
          ? kioskMaintenanceToMaintenanceTimeWindow(
              {
                maintenanceEndsAt: kiosk.maintenanceEndsAt,
                maintenanceStartsAt: kiosk.maintenanceStartsAt,
              },
              kiosk.location.timezone
            )
          : undefined;

      // This gets rid of the __typename property to avoid a GraphQL error
      const cleanedDispenserConfigList = kiosk.dispenserConfig?.map(
        ({ id: dispenserConfigId, serial, usage, isActive, kioskId, dispenserType }) => ({
          dispenserType,
          id: dispenserConfigId,
          isActive,
          kioskId,
          serial,
          usage,
        })
      );

      return {
        dispenserType: kiosk.dispenserType,
        hasDispenser: kiosk.hasDispenser,
        hasTerminal: kiosk.hasTerminal,
        location: {
          label: kiosk.location.name,
          value: kiosk.location.id,
        },
        maintenanceMode: Boolean(kiosk.maintenanceStartsAt || kiosk.maintenanceEndsAt),
        ...maintenanceTimeWindow,
        dispenserConfigList: cleanedDispenserConfigList,
        name: kiosk.name,
        powerSwitchId: kiosk.powerSwitchId,
        rfidTagType: kiosk.rfidTagType,
      };
    }

    const defaultValues: Partial<KioskFormInputs> = {
      hasDispenser: false,
      hasTerminal: false,
      maintenanceMode: false,
    };

    try {
      if (aditionalDataOnOpeningForm) {
        const { location } = aditionalDataOnOpeningForm as KioskAditionalDataOnOpeningFormInterface;
        return {
          ...defaultValues,
          location: {
            label: location.name,
            value: location.id,
          },
        };
      }
      // eslint-disable-next-line no-empty
    } catch {}

    return defaultValues;
  };

  const crudAction = id ? FormCrudAction.EDIT : FormCrudAction.CREATE;

  return (
    <FormTemplate<KioskFormInputs, EnrollKioskData>
      initialValues={initialValues()}
      formHeader={id ? 'Edit Kiosk' : 'Enroll Kiosk'}
      steps={getKioskSteps(crudAction)}
    />
  );
};

export default KioskForm;
