import SingleDayAggregate from '../../../@types/client/SingleDayAggregate';

export default function arrivalsToCSV(arrivals: SingleDayAggregate[]): string[][] {
  const csvData = [['date', 'numOfCheckins']];

  arrivals.forEach((arrival) => {
    csvData.push([arrival.date, arrival.count.toString()]);
  });

  return csvData;
}
