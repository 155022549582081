import React, { useContext } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';

import Form from '../../Form';
import FormContext from '../../../contexts/form.context';
import ResourceFormInputs from '../@types/ResourceFormInputs';
import SimpleFormSection from '../../../templates/SimpleFormSection';
import SubmitRow from '../../SubmitRow';
import canAccessResourceVendorsSection from '../functions/canAccessResourceVendorSection';
import generateResourceSectionInput from '../constants/generateResourceSectionInput';
import useSetLocationIdFilterForUnitType from '../hooks/useSetLocationIdFilterForUnitType';

const ResourceSection: React.FC<{}> = () => {
  const { errors, handleSubmit, watch } = useFormContext();
  const { setStep, step } = useContext(FormContext);
  const onSelectLocation = useSetLocationIdFilterForUnitType();

  const imageType: string = watch('imageType');

  const submitHandler: SubmitHandler<ResourceFormInputs> = (inputs): void => {
    if (canAccessResourceVendorsSection(inputs, errors)) {
      setStep((step ?? 0) + 1);
    }
  };

  const RESOURCE_SECTION_INPUTS = generateResourceSectionInput({
    imageType,
    onSelectLocation,
  });

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <SimpleFormSection inputs={RESOURCE_SECTION_INPUTS} />
      <SubmitRow submitText="Set Vendor" />
    </Form>
  );
};

export default ResourceSection;
