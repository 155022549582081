import { AccessGrantGuests } from '../../../pages/AccessGrant/components/GuestsTab';
import GuestOnAGWhereInput from '../@types/GuestOnAGWhereInput';
import { UserOnAG } from '../../../pages/AccessGrant/@types/GetAccessGrantPageInfo';

export const filterData = (
  guests: UserOnAG[],
  accessGrant: AccessGrantGuests,
  where: GuestOnAGWhereInput
): UserOnAG[] => {
  let newGuests: UserOnAG[] = [...guests];

  if (where) {
    newGuests = newGuests.filter((guest) => {
      let filterResult = true;

      if (where.user) {
        // Search by name
        if (where.user.name && where.user.name.contains) {
          filterResult =
            filterResult &&
            (guest.name ?? '').toUpperCase().includes(where.user.name.contains.toUpperCase());
        }

        // Filter by id (type)
        if (where.user.id) {
          if (where.user.id.equals) {
            filterResult = filterResult && guest.id === where.user.id.equals;
          } else if (where.user.id.not && where.user.id.not.equals) {
            filterResult = filterResult && guest.id !== where.user.id.not.equals;
          }
        }

        // Filter by existence of credentials
        if (where.user.Credential) {
          if (
            where.user.Credential.some &&
            where.user.Credential.some.userId &&
            where.user.Credential.some.userId.in
          ) {
            filterResult =
              filterResult &&
              accessGrant.credentials
                .filter((credential) => credential.isActive)
                .map((credential) => credential.userId)
                .includes(guest.id);
          } else if (
            where.user.Credential.every &&
            where.user.Credential.every.userId &&
            where.user.Credential.every.userId.notIn
          ) {
            filterResult =
              filterResult &&
              !accessGrant.credentials
                .filter((credential) => credential.isActive)
                .map((credential) => credential.userId)
                .includes(guest.id);
          }
        }
      }
      return filterResult;
    });
  }

  return newGuests;
};
