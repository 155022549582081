const FormSectionButtonStyle = `
  align-items: center;
  height: 37px;
  min-width: 50px;
  opacity: 1.0 !important;

  span { 
    border: 0;
    border-style: solid;
    font-weight: bold;
  }
  
  span:first-child {
    border-color: inherit;
    border-right-width: 1px;
    height: 100%;
    line-height: 37px;
    padding: 0 7px;
  }

  span:last-child {
    padding: 0 12px;
  }
`;

export default FormSectionButtonStyle;
