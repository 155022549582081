import * as yup from 'yup';

import EMAIL_REGEXP from '../../../constants/regex/email';

const BASE_USER_VALIDATION_SCHEMA = yup.object().shape(
  {
    email: yup
      .string()
      .matches(EMAIL_REGEXP, { excludeEmptyString: true, message: 'Invalid email' })
      .when('phoneNumber', {
        is: (phoneNumber: string | undefined) => !phoneNumber || phoneNumber.length === 0,
        then: yup.string().email().required('Email or phone number are required'),
        // eslint-disable-next-line sort-keys
        otherwise: yup.string(),
      }),
    name: yup.string().required('Name is required'),
    phoneNumber: yup.string().when('email', {
      is: (email: string | undefined) => !email || email.length === 0,
      then: yup.string().required('Email or phone number are required'),
      // eslint-disable-next-line sort-keys
      otherwise: yup.string(),
    }),
    role: yup.string().required('Role is required'),
  },
  [['email', 'phoneNumber']]
);

export default BASE_USER_VALIDATION_SCHEMA;
