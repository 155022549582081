import { gql } from '../__generated__/gql';

const UPDATE_RESOURCE = gql(`
  mutation UpdateResourceAndUploadImageFile(
    $data: CustomResourceUpdateInput!
    $where: ResourceWhereUniqueInput!
  ) {
    updateResourceAndUploadImageFile(data: $data, where: $where) {
      ...ResourceTableFields
    }
  }
`);

export default UPDATE_RESOURCE;
