enum AccessGrantAction {
  CLEAR_ACCESS_GRANT = 'CLEAR_ACCESS_GRANT',
  CLEAR_ACCESS_GRANT_ERROR = 'CLEAR_ACCESS_GRANT_ERROR',
  LOAD_ACCESS_GRANT = 'LOAD_ACCESS_GRANT',
  RECEIVE_ACCESS_GRANT = 'RECEIVE_ACCESS_GRANT',
  RECEIVE_ACCESS_GRANT_ERROR = 'RECEIVE_ACCESS_GRANT_ERROR',
  RECEIVE_ACCESS_GRANTS = 'RECEIVE_ACCESS_GRANTS',
  RECEIVE_MORE_ACCESS_GRANTS = 'RECEIVE_MORE_ACCESS_GRANTS',
  RESET_ACCESS_GRANT_STORE = 'RESET_ACCESS_GRANT_STORE',
  SET_ACCESS_GRANT_PAGE_INFO = 'SET_ACCESS_GRANT_PAGE_INFO',
  LOAD_ACCESS_GRANT_PAGE_INFO = 'LOAD_ACCESS_GRANT_PAGE_INFO',
}

export default AccessGrantAction;
