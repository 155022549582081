import { parsePhoneNumberFromString } from 'libphonenumber-js';

export default function isValidPhoneNumber(phoneNumber: string): boolean {
  try {
    const parsedNumber = parsePhoneNumberFromString(phoneNumber);

    return (parsedNumber && parsedNumber?.isPossible()) ?? false;
  } catch {
    return false;
  }
}
