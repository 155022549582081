import { Header } from '../../../components/DataTable/@types/DataTableProps';
import SortType from '../../../components/DataTable/@types/SortType.enum';

export enum GuestsDetailsOnAgSortableFields {
  GUEST_NAME = 'user.name',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  LAST_SEEN = 'lastSeen',
}

const GUESTS_DETAILS_ON_AG_TABLE_HEADERS: Header[] = [
  {
    displayValue: 'Guest',
    isSortable: Object.values(GuestsDetailsOnAgSortableFields).includes(
      GuestsDetailsOnAgSortableFields.GUEST_NAME
    ),
    sortType: SortType.SortOrderInput,
    value: 'user.name',
  },
  { displayValue: 'Type', isSortable: false },
  {
    displayValue: 'E-mail',
    isSortable: Object.values(GuestsDetailsOnAgSortableFields).includes(
      GuestsDetailsOnAgSortableFields.EMAIL
    ),
    sortType: SortType.SortOrderInput,
    value: 'email',
  },
  {
    displayValue: 'Phone number',
    isSortable: Object.values(GuestsDetailsOnAgSortableFields).includes(
      GuestsDetailsOnAgSortableFields.PHONE_NUMBER
    ),
    sortType: SortType.SortOrderInput,
    value: 'phoneNumber',
  },
  { displayValue: 'Has credential', isSortable: false },
  {
    displayValue: 'Last seen',
    isSortable: Object.values(GuestsDetailsOnAgSortableFields).includes(
      GuestsDetailsOnAgSortableFields.LAST_SEEN
    ),
    sortType: SortType.SortOrderInput,
    value: 'lastSeen',
  },
];

export default GUESTS_DETAILS_ON_AG_TABLE_HEADERS;
