import { CSSProperties } from 'styled-components';

const getOnlineStyle = (isOnline: boolean): CSSProperties | undefined => {
  if (isOnline) {
    return {
      color: 'green',
    };
  }

  return undefined;
};

export default getOnlineStyle;
