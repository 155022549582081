import { CallStatus, Query } from '../../../__generated__/graphql';

import Datum, { DataTableField } from '../../../components/DataTable/@types/Datum';

import formatCallDuration from './formatCallDuration';
import links from '../../../util/links';

type GetCallRecordsData = Pick<Query, 'callRecords'>;

export default function generateCallRecordTableData(data?: GetCallRecordsData): Datum[] {
  const callRecords = data?.callRecords || [];

  const tableData = callRecords.map((callRecord) => {
    const callIsCompleted = callRecord.status === CallStatus.Completed && callRecord.agent;

    const agentFieldValues = (): DataTableField => {
      if (callRecord.agent?.name) {
        return {
          key: 'Agent',
          link: `${links.paths.USERS}/${callRecord.agent.id}`,
          value: callRecord.agent.name,
        };
      }

      return {
        key: 'Agent',
        value: callRecord.status === CallStatus.Missed ? 'Missed' : 'Unknown',
      };
    };

    return {
      dataType: 'CallRecord',
      fields: [
        {
          key: 'AccessGrant',
          value: callRecord.accessGrant?.confirmationCode || 'Unknown',
        },
        {
          key: 'Guest',
          ...(callRecord.caller && { link: `${links.paths.USERS}/${callRecord.caller.id}` }),
          value: callRecord.caller?.name || 'Unknown',
        },
        agentFieldValues(),
        {
          key: 'Location',
          ...(callRecord.location && {
            link: `${links.paths.LOCATIONS}/${callRecord.location.id}`,
          }),
          value: callRecord.location?.name || 'N/A',
        },
        {
          key: 'Status',
          value: callRecord.status,
        },
        {
          key: 'Duration',
          value:
            callIsCompleted && callRecord.duration
              ? formatCallDuration(callRecord.duration)
              : 'N/A',
        },
        {
          key: 'Type',
          value: callRecord.type,
        },
      ],
      key: callRecord.id,
    };
  });

  return tableData;
}
