import { gql } from '../__generated__/gql';

const INVALIDATE_CREDENTIAL = gql(`
  mutation invalidateCredential($credentialId: String!) {
    invalidateCredential(credentialId: $credentialId) {
      ...CredentialFields
    }
  }
`);

export default INVALIDATE_CREDENTIAL;
