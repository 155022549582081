/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3ThemePaymentCaptureSchema: NodeSchemaProperties = {
  paymentCaptureScreen: {
    title: '#20 Payment Capture Screen',
    type: 'object',
    properties: {},
  },
};

export const mobileV3ThemePaymentCaptureUiSchema: NodeUiSchemaProperties = {
  paymentCaptureScreen: {},
};
