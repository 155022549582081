import { Colors } from '../../../theme/colors';

const ConfirmationModalContentContainerStyle = `
  grid-column-gap: 10px;
  grid-template-columns: 1fr 50px 50px;

  button {
    .no-icon, .yes-icon {
      color: ${Colors.neroDark};
    }

    &:hover {
      .no-icon {
        color: ${Colors.freeSpeechRed};
      }

      .yes-icon {
        color: ${Colors.mountainMeadow};
      }
    }
  }

  p {
    color: ${Colors.freeSpeechRed};
    font-size: 18px;
    font-weight: bold;
  }
`;

export default ConfirmationModalContentContainerStyle;
