import { MdEmail, MdLocationDisabled, MdPhone, MdPlace, MdToys } from 'react-icons/md';
import { QueryGetDashboardLocationArgs, Role } from '../../__generated__/graphql';
import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { openConfirmationModal, openForm } from '../../redux/actions/modals.actions';
import { useDispatch, useSelector } from 'react-redux';

import AccessControlTab from './components/AccessControlTab';
import AccessGrantsTab from './components/AccessGrantsTab';
import AmenitiesTab from './components/AmenitiesTab';
import DetailPageTemplate from '../../templates/DetailPage';
import { EntityEnum } from '../../@types/client/DashboardUserRolePermissions';
import GET_LOCATION_PAGE_INFO from './graphql/getLocationPageInfo';
import GetLocationPageInfo from './@types/GetLocationPageInfo';
import GracePeriodsTab from '../../components/GracePeriodsTab';
import InfoItemsTab from './components/InfoItemsTab';
import KiosksTab from './components/KiosksTab';
import LocationSettingsTab from './components/LocationSettingsTab';
import ResourceGroupsTab from './components/ResourceGroupsTab';
import ResourcesTab from './components/ResourcesTab';
import { RootState } from '../../redux/reducers';
import ServicesTab from './components/ServicesTab';
import UnitTypesTab from './components/UnitTypesTab';
import { flashApolloError } from '../../util/errorUtils';
import links from '../../util/links';
import useDisconnectCloudbedsApp from '../../hooks/useDisconnectCloudbedsApp';
import { useLazyQuery } from '@apollo/client';
import { validateAccessByMembership } from '../../util/authorization';

const LocationPage: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const membership = useSelector((state: RootState) => state.me.activeMembership);
  const { locationId } = useParams<{ locationId: string }>();

  const [getLocation, { called, data }] = useLazyQuery<
    GetLocationPageInfo,
    QueryGetDashboardLocationArgs
  >(GET_LOCATION_PAGE_INFO, {
    onError: flashApolloError,
    variables: {
      where: { id: { equals: locationId } },
    },
  });

  const {
    mutation: disconnectCloudbedsApp,
    loading: cloudbedsAppIsBeingDisconnected,
  } = useDisconnectCloudbedsApp();

  useEffect(() => {
    if (!called) {
      getLocation();
    }
  }, [called, getLocation]);

  const location = data?.getDashboardLocation;

  if (!location) {
    return (
      <DetailPageTemplate
        dataType="Location"
        detailItems={[]}
        identifier={locationId}
        key={'LocationNotLoaded'}
        permission={EntityEnum.LOCATION}
        primaryHeader={''}
        secondaryHeader="Location Details"
        tabs={[]}
      />
    );
  }

  try {
    validateAccessByMembership({
      membership,
      objectMembership: { locationId, tenantId: location.tenantId },
      validateLocation: true,
    });
  } catch {
    return <Redirect to={links.paths.LOCATIONS} />;
  }

  return (
    <DetailPageTemplate
      dataType="Location"
      detailItems={[
        {
          icon: MdPlace,
          label: 'Address',
          text: location.address,
        },
        {
          icon: MdPhone,
          label: 'Phone',
          text: location.phoneNumber,
        },
        {
          icon: MdEmail,
          label: 'Email',
          text: location.email ?? '',
        },
        {
          icon: MdToys,
          label: 'PMS Type',
          text: location.pmsType || 'DEFAULT',
        },
        ...([Role.Admin, Role.Agent].includes(membership.role) &&
        location.pmsType?.toUpperCase() === 'CLOUDBEDS'
          ? [
              {
                disabled: cloudbedsAppIsBeingDisconnected,
                icon: MdLocationDisabled,
                loading: cloudbedsAppIsBeingDisconnected,
                onClick: (): void => {
                  dispatch(
                    openConfirmationModal({
                      action: (): void =>
                        disconnectCloudbedsApp({
                          locationId,
                        }),
                    })
                  );
                },
                text: 'Disconnect Cloudbeds App',
              },
            ]
          : []),
      ]}
      key={'LocationLoaded'}
      identifier={locationId}
      onEditButtonClick={(): void => {
        dispatch(openForm('location', locationId));
      }}
      permission={EntityEnum.LOCATION}
      primaryHeader={location.name}
      secondaryHeader="Location Details"
      tabs={[
        {
          Component: function accessGrantTab(): React.ReactElement {
            return <AccessGrantsTab location={location} />;
          },
          id: 'Access Grants',
        },
        {
          Component: function kiosksTab(): React.ReactElement {
            return <KiosksTab location={location} />;
          },
          id: 'Kiosks',
        },
        {
          Component: function resourcesTab(): React.ReactElement {
            return <ResourcesTab location={location} />;
          },
          id: 'Resources',
        },
        {
          Component: function amenitiesTab(): React.ReactElement {
            return <AmenitiesTab location={location} />;
          },
          id: 'Amenities',
        },
        {
          Component: function servicesTab(): React.ReactElement {
            return <ServicesTab location={location} />;
          },
          id: 'Services',
        },
        {
          Component: ResourceGroupsTab,
          id: 'Resource Groups',
        },
        {
          Component: function unitTypesTab(): React.ReactElement {
            return <UnitTypesTab location={location} />;
          },
          id: 'Unit Types',
        },
        {
          Component: function infoItemsTab(): React.ReactElement {
            return <InfoItemsTab location={location} />;
          },
          id: 'Concierge',
        },
        {
          Component: LocationSettingsTab,
          id: 'Settings',
        },
        {
          Component: function gracePeriodsTab(): React.ReactElement {
            return (
              <GracePeriodsTab
                gracePeriodsConfigLevel="LOCATION"
                locationId={locationId}
                tenantId={membership.tenantId}
              />
            );
          },
          id: 'Access Setup',
        },
        {
          Component: function accessControlTab(): React.ReactElement {
            return <AccessControlTab location={location} />;
          },
          id: 'Access Control',
        },
      ]}
    />
  );
};

export default React.memo(LocationPage);
