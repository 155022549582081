import {
  confirmationEmailLocalizationSchema,
  confirmationEmailLocalizationUiSchema,
} from '../../../../../components/JsonEditor/constants/LocalizationSchema/confirmationEmailLocalizationSchema';

import CustomizeLanguageLocalization from '../../../../../components/CustomizeLocalization/components/CustomizeLanguageLocalization';
import { CustomizeThemeLocalizationEntityLevel } from '../../../../../enums/CustomizeThemeLocalizationEntityLevel';
import { LocalizationComponentName } from '../../../../../enums/ComponentNameEnum';
import React from 'react';
import { useParams } from 'react-router-dom';

const CustomizeConfirmationEmailLocalization: React.FC<{}> = () => {
  const { tenantId } = useParams<{ tenantId: string }>();

  return (
    <CustomizeLanguageLocalization
      title={'Customize confirmation e-mail localization'}
      componentName={LocalizationComponentName.CONFIRMATION_EMAIL}
      schema={confirmationEmailLocalizationSchema}
      uiSchema={confirmationEmailLocalizationUiSchema}
      fileNameToDownloadDefaultJson={`Default confirmation e-mail localization - Tenant ${tenantId} - Language \${LANGUAGE_NAME}.json`}
      fileNameToDownloadCurrentJson={`Current confirmation e-mail localization - Tenant ${tenantId} - Language \${LANGUAGE_NAME}.json`}
      entityLevel={CustomizeThemeLocalizationEntityLevel.TENANT}
      entityId={tenantId}
      mockMainJsonNode={true}
    />
  );
};

export default CustomizeConfirmationEmailLocalization;
