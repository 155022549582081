/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationAuthenticationSchema: NodeSchemaProperties = {
  authentication: {
    title: '#2 Authentication',
    type: 'object',
    properties: {
      'title:enable': {
        description: '[authentication.title]',
        title: '#1 Auth screens title',
        type: 'boolean',
      },
      title: {
        type: 'string',
      },
      'submitButton:enable': {
        description: '[authentication.submitButton]',
        title: '#2 Submit Button',
        type: 'boolean',
      },
      submitButton: {
        type: 'string',
      },
      'description:enable': {
        description: '[authentication.description]',
        title: '#3 Description',
        type: 'boolean',
      },
      description: {
        type: 'string',
      },
      remoteAssistance: {
        title: '#4 Remote Assistance',
        type: 'object',
        properties: {
          'prefix:enable': {
            description: '[authentication.remoteAssistance.prefix]',
            title: '#4.1 Prefix',
            type: 'boolean',
          },
          prefix: {
            type: 'string',
          },
          'button:enable': {
            description: '[authentication.remoteAssistance.button]',
            title: '#4.2 Button',
            type: 'boolean',
          },
          button: {
            type: 'string',
          },
        },
      },
      authScreen: {
        title: '#5 Auth Screen',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[authentication.authScreen.title]',
            title: '#5.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'description:enable': {
            description: '[authentication.authScreen.description]',
            title: '#5.2 Description',
            type: 'boolean',
          },
          description: {
            type: 'string',
          },
        },
      },
      authMethods: {
        title: '#6 Auth Methods',
        type: 'object',
        properties: {
          phoneNumber: {
            title: '#6.1 Phone Number',
            type: 'object',
            properties: {
              'title:enable': {
                description: '[authentication.authMethods.phoneNumber.title]',
                title: '#6.1.1 Title',
                type: 'boolean',
              },
              title: {
                type: 'string',
              },
              'description:enable': {
                description: '[authentication.authMethods.phoneNumber.description]',
                title: '#6.1.2 Description',
                type: 'boolean',
              },
              description: {
                type: 'string',
              },
              'submitButton:enable': {
                description: '[authentication.authMethods.phoneNumber.submitButton]',
                title: '#6.1.3 Submit Button',
                type: 'boolean',
              },
              submitButton: {
                type: 'string',
              },
              placeholder: {
                title: '#6.1.4 Placeholder',
                type: 'object',
                properties: {
                  'phoneNumber:enable': {
                    description: '[authentication.authMethods.phoneNumber.placeholder.phoneNumber]',
                    title: '#6.1.4.1 Phone Number',
                    type: 'boolean',
                  },
                  phoneNumber: {
                    type: 'string',
                  },
                },
              },
              'errorMessage:enable': {
                description: '[authentication.authMethods.phoneNumber.errorMessage]',
                title: '#6.1.5 Error Message',
                type: 'boolean',
              },
              errorMessage: {
                type: 'string',
              },
            },
          },
          email: {
            title: '#6.2 Email',
            type: 'object',
            properties: {
              'title:enable': {
                description: '[authentication.authMethods.email.title]',
                title: '#6.2.1 Title',
                type: 'boolean',
              },
              title: {
                type: 'string',
              },
              'description:enable': {
                description: '[authentication.authMethods.email.description]',
                title: '#6.2.2 Description',
                type: 'boolean',
              },
              description: {
                type: 'string',
              },
              'submitButton:enable': {
                description: '[authentication.authMethods.email.submitButton]',
                title: '#6.2.3 Submit Button',
                type: 'boolean',
              },
              submitButton: {
                type: 'string',
              },
              placeholder: {
                title: '#6.2.4 Placeholder',
                type: 'object',
                properties: {
                  'email:enable': {
                    description: '[authentication.authMethods.email.placeholder.email]',
                    title: '#6.2.4.1 Email',
                    type: 'boolean',
                  },
                  email: {
                    type: 'string',
                  },
                },
              },
              'errorMessage:enable': {
                description: '[authentication.authMethods.email.errorMessage]',
                title: '#6.2.5 Error Message',
                type: 'boolean',
              },
              errorMessage: {
                type: 'string',
              },
            },
          },
          oneTimePassword: {
            title: '#6.3 One Time Password',
            type: 'object',
            properties: {
              email: {
                title: '#6.3.1 Email',
                type: 'object',
                properties: {
                  'title:enable': {
                    description: '[authentication.authMethods.oneTimePassword.email.title]',
                    title: '#6.3.1.1 Title',
                    type: 'boolean',
                  },
                  title: {
                    type: 'string',
                  },
                  'description:enable': {
                    description: '[authentication.authMethods.oneTimePassword.email.description]',
                    title: '#6.3.1.2 Description',
                    type: 'boolean',
                  },
                  description: {
                    type: 'string',
                  },
                  'submitButton:enable': {
                    description: '[authentication.authMethods.oneTimePassword.email.submitButton]',
                    title: '#6.3.1.3 Submit Button',
                    type: 'boolean',
                  },
                  submitButton: {
                    type: 'string',
                  },
                  'resendCodeDescription:enable': {
                    description:
                      '[authentication.authMethods.oneTimePassword.email.resendCodeDescription]',
                    title: '#6.3.1.4 Resend code description',
                    type: 'boolean',
                  },
                  resendCodeDescription: {
                    type: 'string',
                  },
                  'resendCode:enable': {
                    description: '[authentication.authMethods.oneTimePassword.email.resendCode]',
                    title: '#6.3.1.5 Resend code button',
                    type: 'boolean',
                  },
                  resendCode: {
                    type: 'string',
                  },
                  'waitingForResendCode:enable': {
                    description:
                      '[authentication.authMethods.oneTimePassword.email.waitingForResendCode]',
                    title: '#6.3.1.6 Waiting for resend code text',
                    type: 'boolean',
                  },
                  waitingForResendCode: {
                    type: 'string',
                  },
                },
              },
              phoneNumber: {
                title: '#6.3.2 Phone Number',
                type: 'object',
                properties: {
                  'title:enable': {
                    description: '[authentication.authMethods.oneTimePassword.phoneNumber.title]',
                    title: '#6.3.2.1 Title',
                    type: 'boolean',
                  },
                  title: {
                    type: 'string',
                  },
                  'description:enable': {
                    description:
                      '[authentication.authMethods.oneTimePassword.phoneNumber.description]',
                    title: '#6.3.2.2 Description',
                    type: 'boolean',
                  },
                  description: {
                    type: 'string',
                  },
                  'submitButton:enable': {
                    description:
                      '[authentication.authMethods.oneTimePassword.phoneNumber.submitButton]',
                    title: '#6.3.2.3 Submit Button',
                    type: 'boolean',
                  },
                  submitButton: {
                    type: 'string',
                  },
                  'resendCodeDescription:enable': {
                    description:
                      '[authentication.authMethods.oneTimePassword.phoneNumber.resendCodeDescription]',
                    title: '#6.3.2.4 Resend code description',
                    type: 'boolean',
                  },
                  resendCodeDescription: {
                    type: 'string',
                  },
                  'resendCode:enable': {
                    description:
                      '[authentication.authMethods.oneTimePassword.phoneNumber.resendCode]',
                    title: '#6.3.2.5 Resend code button',
                    type: 'boolean',
                  },
                  resendCode: {
                    type: 'string',
                  },
                  'waitingForResendCode:enable': {
                    description:
                      '[authentication.authMethods.oneTimePassword.phoneNumber.waitingForResendCode]',
                    title: '#6.3.2.6 Waiting for resend code text',
                    type: 'boolean',
                  },
                  waitingForResendCode: {
                    type: 'string',
                  },
                },
              },
            },
          },
          nameAndDepartureDate: {
            title: '#6.4 Name And Departure Date',
            type: 'object',
            properties: {
              'title:enable': {
                description: '[authentication.authMethods.nameAndDepartureDate.title]',
                title: '#6.4.1 Title',
                type: 'boolean',
              },
              title: {
                type: 'string',
              },
              'description:enable': {
                description: '[authentication.authMethods.nameAndDepartureDate.description]',
                title: '#6.4.2 Description',
                type: 'boolean',
              },
              description: {
                type: 'string',
              },
              'submitButton:enable': {
                description: '[authentication.authMethods.nameAndDepartureDate.submitButton]',
                title: '#6.4.3 Submit Button',
                type: 'boolean',
              },
              submitButton: {
                type: 'string',
              },
              placeholder: {
                title: '#6.4.4 Placeholder',
                type: 'object',
                properties: {
                  'location:enable': {
                    description:
                      '[authentication.authMethods.nameAndDepartureDate.placeholder.location]',
                    title: '#6.4.4.1 Location',
                    type: 'boolean',
                  },
                  location: {
                    type: 'string',
                  },
                  'firstName:enable': {
                    description:
                      '[authentication.authMethods.nameAndDepartureDate.placeholder.firstName]',
                    title: '#6.4.4.2 First Name',
                    type: 'boolean',
                  },
                  firstName: {
                    type: 'string',
                  },
                  'lastName:enable': {
                    description:
                      '[authentication.authMethods.nameAndDepartureDate.placeholder.lastName]',
                    title: '#6.4.4.3 Last Name',
                    type: 'boolean',
                  },
                  lastName: {
                    type: 'string',
                  },
                },
              },
              'errorMessage:enable': {
                description: '[authentication.authMethods.nameAndDepartureDate.errorMessage]',
                title: '#6.4.5 Error Message',
                type: 'boolean',
              },
              errorMessage: {
                type: 'string',
              },
              'orDivider:enable': {
                description: '[authentication.authMethods.nameAndDepartureDate.orDivider]',
                title: '#6.4.6 OR divider text',
                type: 'boolean',
              },
              orDivider: {
                type: 'string',
              },
              'signAnotherWayButton:enable': {
                description:
                  '[authentication.authMethods.nameAndDepartureDate.signAnotherWayButton]',
                title: '#6.4.7 Sign in another way button',
                type: 'boolean',
              },
              signAnotherWayButton: {
                type: 'string',
              },
            },
          },
          confirmationCode: {
            title: '#6.5 Confirmation Code',
            type: 'object',
            properties: {
              'title:enable': {
                description: '[authentication.authMethods.confirmationCode.title]',
                title: '#6.5.1 Title',
                type: 'boolean',
              },
              title: {
                type: 'string',
              },
              'description:enable': {
                description: '[authentication.authMethods.confirmationCode.description]',
                title: '#6.5.2 Description',
                type: 'boolean',
              },
              description: {
                type: 'string',
              },
              'submitButton:enable': {
                description: '[authentication.authMethods.confirmationCode.submitButton]',
                title: '#6.5.3 Submit Button',
                type: 'boolean',
              },
              submitButton: {
                type: 'string',
              },
              placeholder: {
                title: '#6.5.4 Placeholder',
                type: 'object',
                properties: {
                  'location:enable': {
                    description:
                      '[authentication.authMethods.confirmationCode.placeholder.location]',
                    title: '#6.5.4.1 Location',
                    type: 'boolean',
                  },
                  location: {
                    type: 'string',
                  },
                  'confirmationCode:enable': {
                    description:
                      '[authentication.authMethods.confirmationCode.placeholder.confirmationCode]',
                    title: '#6.5.4.2 Confirmation Code',
                    type: 'boolean',
                  },
                  confirmationCode: {
                    type: 'string',
                  },
                },
              },
              'errorMessage:enable': {
                description: '[authentication.authMethods.confirmationCode.errorMessage]',
                title: '#6.5.5 Error Message',
                type: 'boolean',
              },
              errorMessage: {
                type: 'string',
              },
              'orDivider:enable': {
                description: '[authentication.authMethods.confirmationCode.orDivider]',
                title: '#6.5.6 OR divider text',
                type: 'boolean',
              },
              orDivider: {
                type: 'string',
              },
              'signAnotherWayButton:enable': {
                description: '[authentication.authMethods.confirmationCode.signAnotherWayButton]',
                title: '#6.5.7 Sign in another way button',
                type: 'boolean',
              },
              signAnotherWayButton: {
                type: 'string',
              },
            },
          },
          confirmationCodeAndLastName: {
            title: '#6.6 Confirmation Code And Last Name',
            type: 'object',
            properties: {
              'title:enable': {
                description: '[authentication.authMethods.confirmationCodeAndLastName.title]',
                title: '#6.6.1 Title',
                type: 'boolean',
              },
              title: {
                type: 'string',
              },
              'description:enable': {
                description: '[authentication.authMethods.confirmationCodeAndLastName.description]',
                title: '#6.6.2 Description',
                type: 'boolean',
              },
              description: {
                type: 'string',
              },
              'submitButton:enable': {
                description:
                  '[authentication.authMethods.confirmationCodeAndLastName.submitButton]',
                title: '#6.6.3 Submit Button',
                type: 'boolean',
              },
              submitButton: {
                type: 'string',
              },
              placeholder: {
                title: '#6.6.4 Placeholder',
                type: 'object',
                properties: {
                  'confirmationCode:enable': {
                    description:
                      '[authentication.authMethods.confirmationCodeAndLastName.placeholder.confirmationCode]',
                    title: '#6.6.4.1 Confirmation Code',
                    type: 'boolean',
                  },
                  confirmationCode: {
                    type: 'string',
                  },
                  'lastName:enable': {
                    description:
                      '[authentication.authMethods.confirmationCodeAndLastName.placeholder.lastName]',
                    title: '#6.6.4.2 Last Name',
                    type: 'boolean',
                  },
                  lastName: {
                    type: 'string',
                  },
                },
              },
            },
          },
          obfuscatedAuth: {
            title: '#6.7 Obfuscated Auth',
            type: 'object',
            properties: {
              'title:enable': {
                description: '[authentication.authMethods.obfuscatedAuth.title]',
                title: '#6.7.1 Title',
                type: 'boolean',
              },
              title: {
                type: 'string',
              },
              'description:enable': {
                description: '[authentication.authMethods.obfuscatedAuth.description]',
                title: '#6.7.2 Description',
                type: 'boolean',
              },
              description: {
                type: 'string',
              },
            },
          },
        },
      },
    },
  },
};

export const mobileV3LocalizationAuthenticationUiSchema: NodeUiSchemaProperties = {
  authentication: {
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    submitButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    remoteAssistance: {
      prefix: {
        'ui:label': false,
        'ui:readonly': true,
      },
      button: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    authScreen: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    authMethods: {
      phoneNumber: {
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
        submitButton: {
          'ui:label': false,
          'ui:readonly': true,
        },
        placeholder: {
          phoneNumber: {
            'ui:label': false,
            'ui:readonly': true,
          },
        },
        errorMessage: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      email: {
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
        submitButton: {
          'ui:label': false,
          'ui:readonly': true,
        },
        placeholder: {
          email: {
            'ui:label': false,
            'ui:readonly': true,
          },
        },
        errorMessage: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      oneTimePassword: {
        email: {
          title: {
            'ui:label': false,
            'ui:readonly': true,
          },
          description: {
            'ui:label': false,
            'ui:readonly': true,
          },
          submitButton: {
            'ui:label': false,
            'ui:readonly': true,
          },
          resendCodeDescription: {
            'ui:label': false,
            'ui:readonly': true,
          },
          resendCode: {
            'ui:label': false,
            'ui:readonly': true,
          },
          waitingForResendCode: {
            'ui:label': false,
            'ui:readonly': true,
          },
        },
        phoneNumber: {
          title: {
            'ui:label': false,
            'ui:readonly': true,
          },
          description: {
            'ui:label': false,
            'ui:readonly': true,
          },
          submitButton: {
            'ui:label': false,
            'ui:readonly': true,
          },
          resendCodeDescription: {
            'ui:label': false,
            'ui:readonly': true,
          },
          resendCode: {
            'ui:label': false,
            'ui:readonly': true,
          },
          waitingForResendCode: {
            'ui:label': false,
            'ui:readonly': true,
          },
        },
      },
      nameAndDepartureDate: {
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
        submitButton: {
          'ui:label': false,
          'ui:readonly': true,
        },
        placeholder: {
          location: {
            'ui:label': false,
            'ui:readonly': true,
          },
          firstName: {
            'ui:label': false,
            'ui:readonly': true,
          },
          lastName: {
            'ui:label': false,
            'ui:readonly': true,
          },
        },
        errorMessage: {
          'ui:label': false,
          'ui:readonly': true,
        },
        orDivider: {
          'ui:label': false,
          'ui:readonly': true,
        },
        signAnotherWayButton: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      confirmationCode: {
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
        submitButton: {
          'ui:label': false,
          'ui:readonly': true,
        },
        placeholder: {
          location: {
            'ui:label': false,
            'ui:readonly': true,
          },
          confirmationCode: {
            'ui:label': false,
            'ui:readonly': true,
          },
        },
        errorMessage: {
          'ui:label': false,
          'ui:readonly': true,
        },
        orDivider: {
          'ui:label': false,
          'ui:readonly': true,
        },
        signAnotherWayButton: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      confirmationCodeAndLastName: {
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
        submitButton: {
          'ui:label': false,
          'ui:readonly': true,
        },
        placeholder: {
          confirmationCode: {
            'ui:label': false,
            'ui:readonly': true,
          },
          lastName: {
            'ui:label': false,
            'ui:readonly': true,
          },
        },
      },
      obfuscatedAuth: {
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
    },
  },
};
