import { gql } from '../__generated__/gql';

export const CREATE_AMENITY = gql(`
  mutation CreateAmenityAndUploadImageFile($data: CustomAmenityCreateInput!) {
    createAmenityAndUploadImageFile(data: $data) {
      id
      imageUrl
      title
      description
    }
  }
`);
