import ClearFilterAction from './@types/ClearFilterAction';
import ClearSortOptionsAction from './@types/ClearSortAction';
import DataTableActionEnum from '../../enums/DataTableAction.enum';
import { DataTableFilterValues } from '../../@types/client/DataTableFilterValues';
import OrderByDataTableParams from './@types/DataTableOrderByParams';
import SetFilterAction from './@types/SetFilterAction';
import SetSortOptionsAction from './@types/SetSortAction';

export const setFilter = (
  dataTableKey: string,
  valuesFiltered: DataTableFilterValues
): SetFilterAction => ({
  payload: {
    dataTableKey,
    valuesFiltered,
  },
  type: DataTableActionEnum.SET_FILTER,
});

export const clearFilter = (dataTableKey: string): ClearFilterAction => ({
  payload: {
    dataTableKey,
  },
  type: DataTableActionEnum.CLEAR_FILTER,
});

export const setSortOptions = (
  dataTableKey: string,
  sortOptions: OrderByDataTableParams[]
): SetSortOptionsAction => ({
  payload: {
    dataTableKey,
    sortOptions,
  },
  type: DataTableActionEnum.SET_SORT_OPTIONS_ORDER,
});

export const clearSortOptions = (filterKey: string): ClearSortOptionsAction => ({
  payload: {
    dataTableKey: filterKey,
  },
  type: DataTableActionEnum.CLEAR_SORT_OPTIONS_ORDER,
});

export type DataTableAction =
  | SetFilterAction
  | ClearFilterAction
  | SetSortOptionsAction
  | ClearSortOptionsAction;
