import { Colors } from '../../../theme/colors';

const AuthenticationFormSubmitButtonStyle = `
  background: ${Colors.brightGrey};
  border: 0;
  border-radius: 6px;
  color: #fff;
  font-size: 11px;
  font-weight: bold; 
  height: 45px;
  line-height: 16px;
  width: 100%;

  &:active {
    outline: 0;
  }

  &:disabled {
    background: ${Colors.stormGrey};
    color: ${Colors.manatee};
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  margin-bottom: 10px !important;
`;

export default AuthenticationFormSubmitButtonStyle;
