import SearchField from '../../../@types/SearchField';
import { ServiceWhereInput } from '../../../__generated__/graphql';
import createSearchField from '../../../util/tableData/createSearchField';
import transformStringFilter from '../../../util/tableData/transformStringFilter';

const SERVICES_TABLE_SEARCH_FIELDS: SearchField<ServiceWhereInput>[] = [
  createSearchField<ServiceWhereInput, 'title'>({
    label: 'Title',
    queryField: 'title',
    transform: transformStringFilter,
  }),
  createSearchField<ServiceWhereInput, 'description'>({
    label: 'Description',
    queryField: 'description',
    transform: transformStringFilter,
  }),
];

export default SERVICES_TABLE_SEARCH_FIELDS;
