import FormTemplate from '../../templates/Form';
import { Mutation } from '../../__generated__/graphql';
import React from 'react';
import USERS_IMPORT_FORM_STEPS from './constants/usersImportFormSteps';
import USERS_IMPORT_VALIDATION_SCHEMA from './constants/usersImportValidationSchema';

type UsersImportData = Pick<Mutation, 'signUpUsersFromThirdPartyAuthenticationService'>;

const UsersImportForm: React.FC = () => {
  return (
    <FormTemplate<{}, UsersImportData>
      formHeader={'Import Users'}
      steps={USERS_IMPORT_FORM_STEPS}
      validationSchema={USERS_IMPORT_VALIDATION_SCHEMA}
    />
  );
};

export default UsersImportForm;
