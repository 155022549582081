import KioskFormInputs, { DispenserConfigFormInputs } from '../@types/KioskFormInputs';
import React, { useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import Button from '../../Button';
import ButtonModel from '../../Button/enums/ButtonModel.enum';
import { Colors } from '../../../theme/colors';
import Container from '../../Container';
import { DispenserType } from '../../../__generated__/graphql';
import InputGroup from '../../InputGroup';
import { MdClose } from 'react-icons/md';
import { generateKioskConfigInputs } from '../functions/generateKioskConfigInputs';

export const AddDispenserFormSection: React.FC = () => {
  const { control, getValues } = useFormContext<KioskFormInputs>();
  const { dispenserType } = getValues();
  const DISPENSER_CONFIG_ITEMS_LIMIT = 2;

  const { fields, append, remove: removeDispenserConfigItem } = useFieldArray<KioskFormInputs>({
    control,
    name: 'dispenserConfigList',
  });

  const addDispenserConfig = (e: React.SyntheticEvent): void => {
    e.preventDefault();

    const dispenserConfig: DispenserConfigFormInputs = {
      serial: '',
    };

    append(dispenserConfig);
  };

  const removeDispenserConfig = (e: React.SyntheticEvent, index: number): void => {
    e.preventDefault();

    removeDispenserConfigItem(index);
  };

  const isLemurXDispenser = useMemo(() => {
    return dispenserType === DispenserType.LemurX;
  }, [dispenserType]);

  const shouldDisableAddDispenserConfigButton = useMemo(() => {
    return fields.length === DISPENSER_CONFIG_ITEMS_LIMIT;
  }, [fields]);

  return (
    <Container margin="25px 0">
      {isLemurXDispenser && (
        <>
          <Button
            model={ButtonModel.ACTIVE_CTA_DARK}
            onClick={addDispenserConfig}
            active={shouldDisableAddDispenserConfigButton}
            disabled={shouldDisableAddDispenserConfigButton}
            style={{
              marginBottom: 15,
            }}
          >
            Add dispenser
          </Button>
          <Container>
            {fields.map((field, index) => (
              <Container
                columnTemplate="1fr 3fr 3fr 1fr"
                rowGap="25px"
                columnGap="15px"
                key={`kiosk-config-list-${field.id}`}
              >
                {generateKioskConfigInputs(index).map((input) => (
                  <InputGroup key={`simple-form-section-input-${input.name}-${index}`} {...input} />
                ))}
                <Button
                  background={Colors.freeSpeechRed}
                  style={{ marginTop: 28 }}
                  model={ButtonModel.PASSIVE_ICON_BUTTON}
                  onClick={(e): void => removeDispenserConfig(e, index)}
                >
                  <MdClose color={Colors.white} size="16px" />
                </Button>
              </Container>
            ))}
          </Container>
        </>
      )}
    </Container>
  );
};
