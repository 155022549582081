import { ApolloError, DocumentNode } from '@apollo/client';
import { Mutation, MutationJoinVideoArgs } from '../__generated__/graphql';
import {
  removeIncomingCall,
  setActiveCall,
  setJoinerChallenge,
  setVideoToken,
} from '../redux/actions/remoteAssistance.actions';

import IncomingCallRedux from '../redux/reducers/@types/IncomingCallRedux';
import JOIN_VIDEO from '../graphql/joinVideo';
import { openModals } from '../redux/actions/modals.actions';
import { stopRinging } from '../modules/Ringing';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';

type JoinVideoMutationData = Pick<Mutation, 'joinVideo'>;

interface UseJoinVideoParameters {
  incomingCall: IncomingCallRedux;
  onCompleted?: (data: JoinVideoMutationData) => void;
  onError?: (error: ApolloError) => void;
  refetchQueries?: {
    query: DocumentNode;
    variables: MutationJoinVideoArgs;
  }[];
  room: string;
}

interface UseJoinVideoData {
  data?: JoinVideoMutationData;
  joinVideo: () => void;
  error?: ApolloError;
  loading: boolean;
}

export default function useJoinVideo({
  incomingCall,
  onCompleted = (): void => {},
  onError = (): void => {},
  room,
}: UseJoinVideoParameters): UseJoinVideoData {
  const dispatch = useDispatch();

  const joinerChallenge = Math.random().toString(36).slice(2);

  const [joinVideo, { data, error, loading }] = useMutation<
    JoinVideoMutationData,
    MutationJoinVideoArgs
  >(JOIN_VIDEO, {
    onCompleted: (joinVideoData: JoinVideoMutationData): void => {
      stopRinging();

      dispatch(setActiveCall(incomingCall));
      dispatch(removeIncomingCall(incomingCall.callRecordId));
      dispatch(setVideoToken(joinVideoData.joinVideo.accessToken));
      dispatch(openModals(['videoCall', 'kioskActions']));

      onCompleted(joinVideoData);
    },
    onError,
  });

  return {
    data: data || undefined,
    error,
    joinVideo: (): void => {
      dispatch(setJoinerChallenge(joinerChallenge));
      joinVideo({
        variables: {
          callRecordId: incomingCall.callRecordId,
          joinerChallenge,
          room,
        },
      });
    },
    loading,
  };
}
