import 'styled-components/macro';

import {
  mobileV3ThemeCustomizationSchema,
  mobileV3ThemeCustomizationUiSchema,
} from '../../../../../components/JsonEditor/constants/ThemeSchema/mobileV3ThemeCustomizationSchema';

import CustomizeTheme from '../../../../../components/CustomizeTheme';
import { CustomizeThemeLocalizationEntityLevel } from '../../../../../enums/CustomizeThemeLocalizationEntityLevel';
import React from 'react';
import { ThemeComponentName } from '../../../../../enums/ComponentNameEnum';
import { useParams } from 'react-router-dom';

const CustomizeMobileV3Theme: React.FC<{}> = () => {
  const { locationId } = useParams<{ locationId: string }>();

  return (
    <CustomizeTheme
      schema={mobileV3ThemeCustomizationSchema}
      uiSchema={mobileV3ThemeCustomizationUiSchema}
      componentName={ThemeComponentName.MOBILE_V3}
      fileNameToDownloadDefaultJson={`Default mobile v3 theme - Location ${locationId}.json`}
      fileNameToDownloadCurrentJson={`Current mobile v3 theme - Location ${locationId}.json`}
      title={'Customize mobile v3 theme'}
      entityLevel={CustomizeThemeLocalizationEntityLevel.LOCATION}
      entityId={locationId}
    />
  );
};

export default CustomizeMobileV3Theme;
