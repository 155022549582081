import { Colors, RGB } from '../../../theme/colors';

const MultiSelectItemStyle = `
  align-content: center;
  background: rgba(${RGB.linkWater}, .2);
  border-radius: 8px;
  color: ${Colors.blackRussian};
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  height: 56px;
  justify-content: center;

  &.active {
    background: ${Colors.mountainMeadow};

    p {
      color: #fff;
    }
  }
`;

export default MultiSelectItemStyle;
