import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../redux/reducers';
import { setRefreshTokenHandlerTimeout } from '../redux/actions/auth.actions';
import useRefreshToken from './useRefreshToken';

const secondsBeforeExpiresToRefreshTheToken = 60 * 1000;

const useRefreshTokenOnTimeLeftToExpire = (): void => {
  const dispatch = useDispatch();
  const refreshTokenHandlerTimeout = useSelector(
    (store: RootState) => store.auth.refreshTokenHandlerTimeout
  );

  const { attemptTokenRefresh } = useRefreshToken();

  const refreshToken = localStorage.getItem('REFRESH_TOKEN');
  const accessToken = localStorage.getItem('ACCESS_TOKEN');
  const tokenExpiry = localStorage.getItem('TOKEN_EXPIRY');

  if (!refreshTokenHandlerTimeout && accessToken && refreshToken && tokenExpiry) {
    const millisecondsToExpire = Math.abs(Date.parse(tokenExpiry) - Date.now());

    const newTimeoutHandler = setTimeout(() => {
      attemptTokenRefresh();
    }, millisecondsToExpire - secondsBeforeExpiresToRefreshTheToken);
    dispatch(setRefreshTokenHandlerTimeout(newTimeoutHandler));
  }
};

export default useRefreshTokenOnTimeLeftToExpire;
