import 'styled-components/macro';
import React, { FocusEvent, useEffect, useState } from 'react';
import Container from '../Container';
import QueryHook from '../../hooks/@types/QueryHook';
import SearchInputWithResultsContainerStyle from './styles/SearchInputWithResultsContainer.style';
import SearchResultsDropdown from './components/SearchResultsDropdown';
import TextInput from '../TextInput';
import { useFormContext } from 'react-hook-form';

interface SearchInputWithResultsProps {
  className?: string;
  name: string;
  placeholder?: string;
  query: QueryHook;
  required?: boolean;
}

const SearchInputWithResults: React.FC<SearchInputWithResultsProps> = ({
  className = '',
  name,
  placeholder = '',
  query,
  required = true,
}: SearchInputWithResultsProps) => {
  const { register, setValue } = useFormContext();
  const [isFocused, setFocused] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchTimeout, setSearchTimeout] = useState<number | null>(null);
  const { data = [], loading, query: searchQuery } = query();

  useEffect(() => {
    register(name, { required });
  });

  function handleBlur(e: React.FocusEvent<HTMLInputElement>): void {
    if (!(e.relatedTarget instanceof HTMLElement)) {
      setFocused(false);
      return;
    }

    if (!e.relatedTarget?.dataset.identifier) {
      setFocused(false);
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = e.currentTarget;
    setSearchValue(value);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (value) {
      const timeout = setTimeout(() => {
        searchQuery(value);
        setSearchTimeout(null);
      }, 1000);

      setSearchTimeout(timeout);
    } else {
      setSearchTimeout(null);
    }
  }

  function handleSelect(e: React.SyntheticEvent): void {
    const identifier = e.currentTarget.getAttribute('data-identifier');
    setValue(name, identifier);

    const selectedItem = data?.find((item) => item.id === identifier);
    setSearchValue(selectedItem?.name ?? '');
    setFocused(false);
  }

  return (
    <Container css={SearchInputWithResultsContainerStyle}>
      <TextInput
        className={className}
        model="DEFAULT"
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={(): void => setFocused(true)}
        placeholder={placeholder}
        type="text"
        value={searchValue}
      />
      {isFocused && (
        <SearchResultsDropdown
          isLoading={Boolean(loading || searchTimeout)}
          items={searchValue ? data ?? [] : []}
          onSelect={handleSelect}
        />
      )}
    </Container>
  );
};

export default SearchInputWithResults;
