import { Styles } from 'react-modal';

const FORM_MODAL_STYLE: Styles = {
  content: {
    alignSelf: 'center',
    background: '#fff',
    borderRadius: '0',
    bottom: 'unset',
    height: '100vh',
    justifySelf: 'center',
    left: 'unset',
    maxWidth: '100vw',
    padding: '0',
    right: 'unset',
    top: '0',
    width: '100vw',
    zIndex: 10,
  },
  overlay: {
    alignItems: 'center',
    display: 'flex',
    gridColumnGap: '25px',
    justifyContent: 'center',
    zIndex: 10,
  },
};

export default FORM_MODAL_STYLE;
