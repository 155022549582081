import FormTemplateStep from '../../../templates/Form/@types/FormTemplateStep';
import { Mutation } from '../../../__generated__/graphql';
import UsersImportFormInputs from '../@types/UsersImportFormInputs';
import UsersImportSection from '../components/UsersImportSection';

type UsersImportData = Pick<Mutation, 'signUpUsersFromThirdPartyAuthenticationService'>;

const USERS_IMPORT_FORM_STEPS: FormTemplateStep<UsersImportFormInputs, UsersImportData>[] = [
  {
    canBeAccessed: (): boolean => true,
    component: UsersImportSection,
    name: 'Import Users',
  },
];

export default USERS_IMPORT_FORM_STEPS;
