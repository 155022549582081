import { gql } from '../__generated__/gql';

const ME = gql(`
  query MeWithUserRolePermissions {
    meWithUserRolePermissions {
      activeMembership {
        ...ActiveMembershipFields
      }
      email
      id
      name
      phoneNumber
      memberships {
        ...MembershipBasicFields
      }
    }
  } 
`);

export default ME;
