import { Mutation } from '../__generated__/graphql';
import MutationHookData from './@types/MutationHookData';
import RESEND_CONFIRMATION_INFO from '../graphql/resendConfirmationInfo';
import { useMutation } from '@apollo/client';

type ResendConfirmationInfoData = Pick<Mutation, 'resendConfirmationInfo'>;

export default function useResendConfirmationInfo(): MutationHookData<
  string,
  ResendConfirmationInfoData
> {
  const [resend, { data, error, loading }] = useMutation<
    ResendConfirmationInfoData,
    { accessGrantId: string }
  >(RESEND_CONFIRMATION_INFO);

  return {
    data,
    error,
    loading,
    mutation: (accessGrantId: string): void => {
      resend({
        variables: {
          accessGrantId,
        },
      });
    },
  };
}
