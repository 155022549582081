import 'styled-components/macro';

import CallInfoContainerStyle, {
  CallInfoInnerContainerStyle,
  CallInfoTextStyle,
} from './styles/CallInfoContainer.style';

import Container from '../../../Container';
import IncomingCallRedux from '../../../../redux/reducers/@types/IncomingCallRedux';
import { Link } from 'react-router-dom';
import React from 'react';
import TextContent from '../../../TextContent';
import links from '../../../../util/links';

interface CallInfoProps {
  incomingCall: IncomingCallRedux;
}

const CallInfo: React.FC<CallInfoProps> = ({ incomingCall }: CallInfoProps) => {
  const { caller, kiosk } = incomingCall;

  const getLinkToBeCalled = (): string | undefined => {
    if (kiosk) {
      return `${links.paths.KIOSKS}/${kiosk.id}`;
    }
    if (caller) {
      return `${links.paths.USERS}/${caller.id}`;
    }

    return undefined;
  };

  const getTextToBeDisplayed = (): string | undefined => {
    if (kiosk) {
      return kiosk.name;
    }

    if (caller?.name) {
      return caller.name;
    }

    return undefined;
  };

  const linkToBeCalled = getLinkToBeCalled();
  const textToBeDisplayed = getTextToBeDisplayed();

  if (linkToBeCalled && textToBeDisplayed) {
    return (
      <Container css={CallInfoContainerStyle}>
        <Container css={CallInfoInnerContainerStyle}>
          <TextContent css={CallInfoTextStyle}>
            <Link to={linkToBeCalled}>{textToBeDisplayed}</Link>
          </TextContent>
        </Container>
      </Container>
    );
  }

  return <></>;
};

export default CallInfo;
