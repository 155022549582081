/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationSuccessSchema: NodeSchemaProperties = {
  successScreen: {
    title: '#20 Success Screen',
    type: 'object',
    properties: {
      'title:enable': {
        description: '[successScreen.title]',
        title: '#1 Title',
        type: 'boolean',
      },
      title: {
        type: 'string',
      },
      'descriptionHtml:enable': {
        description: '[successScreen.descriptionHtml]',
        title: '#2 Description Html',
        type: 'boolean',
      },
      descriptionHtml: {
        type: 'string',
      },
    },
  },
};

export const mobileV3LocalizationSuccessUiSchema: NodeUiSchemaProperties = {
  successScreen: {
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    descriptionHtml: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
};
