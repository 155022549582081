import { Header } from '../../../components/DataTable/@types/DataTableProps';

const VENDOR_SERVERS_TABLE_HEADERS: Header[] = [
  { displayValue: 'Vendor', isSortable: false, value: 'vendor' },
  { displayValue: 'Host', isSortable: false, value: 'host' },
  { displayValue: 'Port', isSortable: false, value: 'port' },
  { displayValue: 'Is active', isSortable: false, value: 'isActive' },
  { displayValue: 'Should all join', isSortable: false, value: 'shouldAllJoin' },
];

export default VENDOR_SERVERS_TABLE_HEADERS;
