import * as yup from 'yup';

import BASE_USER_VALIDATION_SCHEMA from './baseUserValidationSchema';
import { REQUIRED_PASSWORD_VALIDATION_SCHEMA } from './passwordValidationSchema';
import { Role } from '../../../__generated__/graphql';
import SELECT_OPTION from '../../../constants/yupValidations/selectOption';

const USER_VALIDATION_SCHEMA = BASE_USER_VALIDATION_SCHEMA.concat(
  REQUIRED_PASSWORD_VALIDATION_SCHEMA
).concat(
  yup.object().shape({
    locations: yup.object().shape({
      map: yup.lazy(() =>
        yup.object().shape({
          option: yup.object().shape({
            label: yup.string(),
            value: yup.string(),
          }),
          shouldCreate: yup.boolean(),
          shouldDelete: yup.boolean(),
          withRole: yup.mixed<Role>().oneOf(Object.values(Role)),
        })
      ),
      values: yup.array(SELECT_OPTION),
    }),
  })
);

export default USER_VALIDATION_SCHEMA;
