import { gql } from '../__generated__/gql';

const DOWNLOAD_RESOURCE_TEMPLATE_CSV_FILE = gql(`
  query DownloadResourceTemplateCSVFile {
    downloadResourceTemplateCSVFile {
      data
    }
  }
`);

export default DOWNLOAD_RESOURCE_TEMPLATE_CSV_FILE;
