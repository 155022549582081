const SearchInputOuterContainerStyle = `
  grid-auto-flow: column;
  grid-column-gap: 60px;
  justify-content: start;
  margin: 0 0 40px;
  overflow: hidden;

  @media only screen and (max-width: 1450px) {
    margin: 0 0 20px;
  }

  @media only screen and (max-width: 1200px) {
    margin: 0 0 20px;
  }
`;

export default SearchInputOuterContainerStyle;
