import { Colors } from '../../../theme/colors';
import Theme from '../../../theme';

const HangUpButtonStyle = `
  background: ${Colors.freeSpeechRed};
  bottom: 5%;
  border-radius: 50%;
  color: ${Theme.colors.white.hexCode};
  height: 60px;
  left: calc(calc(100% / 2) - 30px);
  opacity: 0;
  position: absolute;
  right: calc(calc(100% / 2) - 30px);
  width: 60px;
  z-index: 2;

  svg, path {
    fill: ${Theme.colors.white.hexCode};
  }

  &:hover {
    opacity: 1;
  }
`;

export default HangUpButtonStyle;
