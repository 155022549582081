import { gql } from '../__generated__/gql';

const UPDATE_USER = gql(`
  mutation UpdateUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    updateUser(data: $data, where: $where) {
      email
      id
      lastSeenAt
      memberships {
        role
        tenantId
      }
      name
      phoneNumber
    }
  }
`);

export default UPDATE_USER;
