import {
  QueryGetKioskLocalizationArgs,
  QueryGetLocationLocalizationArgs,
  QueryGetTenantLocalizationArgs,
} from '../../../__generated__/graphql';

import { CustomizeThemeLocalizationEntityLevel } from '../../../enums/CustomizeThemeLocalizationEntityLevel';
import { NotSupportedError } from '../../CustomizeTheme/constants/defaultError';

const retrieveLocalizationInputProperties = (
  componentName: string,
  languageId: string,
  entityLevel: CustomizeThemeLocalizationEntityLevel,
  entityId: string
):
  | QueryGetTenantLocalizationArgs
  | QueryGetLocationLocalizationArgs
  | QueryGetKioskLocalizationArgs => {
  switch (entityLevel) {
    case CustomizeThemeLocalizationEntityLevel.TENANT: {
      return {
        componentName,
        languageId,
        tenantId: entityId,
      };
    }

    case CustomizeThemeLocalizationEntityLevel.LOCATION: {
      return {
        componentName,
        languageId,
        locationId: entityId,
      };
    }

    case CustomizeThemeLocalizationEntityLevel.KIOSK: {
      return {
        componentName,
        kioskId: entityId,
        languageId,
      };
    }

    default:
      throw NotSupportedError(entityLevel);
  }
};

export default retrieveLocalizationInputProperties;
