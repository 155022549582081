import 'styled-components/macro';

import * as yup from 'yup';

import { Link, useLocation } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';

import AuthenticationBaseContainer from '../../components/AuthenticationBaseComponent';
import AuthenticationFormActionTextStyle from '../../components/AuthenticationBaseComponent/styles/AuthenticationFormActionText.style';
import AuthenticationFormErrorTextStyle from '../../components/AuthenticationBaseComponent/styles/AuthenticationFormErrorText.style';
import AuthenticationFormInputStyle from '../../components/AuthenticationBaseComponent/styles/AuthenticationFormInput.style';
import AuthenticationFormSubmit1stButtonStyle from '../../components/AuthenticationBaseComponent/styles/AuthenticationFormSubmit1stButtonStyle.style';
import AuthenticationFormSubmitButtonStyle from '../../components/AuthenticationBaseComponent/styles/AuthenticationFormSubmitButton.style';
import LoginFormInputs from './@types/LoginFormInputs';
import React from 'react';
import { RedirectProps } from '../../components/ProtectedRoute';
import TextContent from '../../components/TextContent';
import TextInput from '../../components/TextInput';
import capitalize from 'lodash/capitalize';
import links from '../../util/links';
import useSignIn from '../../hooks/useSignIn';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

function Login(): React.ReactElement {
  const location = useLocation();
  const { previousPath } = (location.state as RedirectProps) ?? {};

  const { errors, handleSubmit, register } = useForm<LoginFormInputs>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
  });

  const { loading, oktaSignIn, signIn } = useSignIn(previousPath);

  const onSubmit: SubmitHandler<LoginFormInputs> = async ({ email, password }): Promise<void> => {
    await signIn({
      variables: { email, password },
    });
  };

  const onClickLoginViaOkta = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    oktaSignIn();
  };

  return (
    <AuthenticationBaseContainer title="Welcome to Virdee">
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          className={`${errors.email && 'error'}`}
          css={AuthenticationFormInputStyle}
          name="email"
          placeholder="Email"
          ref={register({ required: true })}
          type="email"
        />
        {errors.email && (
          <TextContent css={AuthenticationFormErrorTextStyle}>
            {capitalize(errors.email.message)}
          </TextContent>
        )}
        <TextInput
          className={`${errors.password && 'error'}`}
          css={AuthenticationFormInputStyle}
          name="password"
          placeholder="Password"
          ref={register({ required: true })}
          type="password"
        />
        {errors.password && (
          <TextContent css={AuthenticationFormErrorTextStyle}>
            {capitalize(errors.password.message)}
          </TextContent>
        )}
        <Link to={links.paths.FORGOT_PASSWORD}>
          <TextContent style={AuthenticationFormActionTextStyle}>Forgot password?</TextContent>
        </Link>
        <input
          css={AuthenticationFormSubmit1stButtonStyle}
          disabled={loading}
          type="submit"
          value="Login"
        />
        <input
          css={AuthenticationFormSubmitButtonStyle}
          disabled={loading}
          onClick={onClickLoginViaOkta}
          type="submit"
          value="Login via Okta"
        />
      </form>
    </AuthenticationBaseContainer>
  );
}
export default Login;
