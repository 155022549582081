const DetailPageTabsContainerStyle = `
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-column-gap: 25px;
  margin: 0 0 25px;
  padding: 0 35px;
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: 1600px) {
    grid-column-gap: 10px;
    padding: 0;
  }
`;

export default DetailPageTabsContainerStyle;
