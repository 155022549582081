import Filter from '../../../../components/DataTable/@types/Filter';
import { UserTypeEnum } from '../../../useCredentialsTableData/enums/UserType.enum';
import { getUserTypeName } from '../../../useCredentialsTableData/functions/userType';

function getUserTypeFilter(): Filter[] {
  const newFilters: Filter[] = [];

  Object.keys(UserTypeEnum).forEach((userType) => {
    newFilters.push({
      displayKey: 'Guest type',
      displayValue: getUserTypeName(UserTypeEnum[userType as keyof typeof UserTypeEnum]),
      key: 'guestType',
      value: UserTypeEnum[userType as keyof typeof UserTypeEnum],
    });
  });

  return newFilters;
}

function getHasCredential(): Filter[] {
  const newFilters: Filter[] = [];

  ['Yes', 'No'].forEach((value) => {
    newFilters.push({
      displayKey: 'Has credential',
      displayValue: value,
      key: 'hasCredential',
      value: String(value === 'Yes'),
    });
  });

  return newFilters;
}

export default function useGuestsOnAGFilters(): Filter[] {
  let filters: Filter[] = [];

  filters = [...filters, ...getUserTypeFilter()];
  filters = [...filters, ...getHasCredential()];

  return filters;
}
