import { Maybe } from '../../__generated__/graphql';
import { ReduxMembership } from '../../redux/reducers/@types/MeReduxState';

export interface ObjectMembership {
  tenantId: string;
  locationId?: Maybe<string>;
}
export interface ValidateAccessByMembershipProps {
  membership: ReduxMembership;
  objectMembership: ObjectMembership;
  validateLocation: boolean;
}

export const validateAccessByMembership = ({
  membership,
  objectMembership,
  validateLocation,
}: ValidateAccessByMembershipProps): void => {
  if (validateLocation) {
    if (membership.location?.id && membership.location?.id !== objectMembership.locationId) {
      throw new Error('Invalid membership');
    }
  }

  if (membership.tenant.id !== objectMembership.tenantId) {
    throw new Error('Invalid membership');
  }
};
