import Theme from '../../../theme';
import styled from 'styled-components';

type ToggleOptionSelectedProps = {
  indexSelected?: number;
  numberOfOptions: number;
  themeMode: 'light' | 'dark';
};

const ToggleOptionSelected = styled.div<ToggleOptionSelectedProps>`
  position: absolute;
  top: 0;
  left: ${({ indexSelected, numberOfOptions }): string => {
    if (!indexSelected || indexSelected === 0) {
      return '0';
    }
    const left = indexSelected / numberOfOptions;
    return `${left * 100}%`;
  }};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: ${({ numberOfOptions }): string => {
    const width = 100 / numberOfOptions;
    return `${width}%`;
  }};
  border-radius: 10px;
  background-color: ${({ themeMode }): string => {
    if (themeMode === 'light') {
      return Theme.colors.white.hexCode;
    }
    if (themeMode === 'dark') {
      return Theme.colors.tableHeading.hexCode;
    }
    return '';
  }};
  color: ${({ themeMode }): string => {
    if (themeMode === 'light') {
      return Theme.colors.tableHeading.hexCode;
    }
    if (themeMode === 'dark') {
      return Theme.colors.white.hexCode;
    }
    return '';
  }};
  transition: all 0.2s;
  font-weight: 600;
`;

export default ToggleOptionSelected;
