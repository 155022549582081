import { channelsClient } from '../../../util/pusher';
import { closeForm } from '../../../redux/actions/modals.actions';
import links from '../../../util/links';
import pollReservationTimeout from '../constants/pollReservationTimeout';
import { useDispatch } from 'react-redux';

const { REACT_APP_VIRDEE_ENV = 'local' } = process.env;

const useCompleteCallPollReservation = (): {
  onCompleteCallPollReservation: (
    pollReservationFromPms: boolean,
    tenantId: string,
    locationId: string,
    confirmationCode?: string
  ) => void;
} => {
  const dispatch = useDispatch();

  const onCompleteCallPollReservation = (
    pollReservationFromPms: boolean,
    tenantId: string,
    locationId: string,
    confirmationCode?: string
  ): void => {
    if (pollReservationFromPms) {
      window.flash({
        message: `Backend has successfully sent the request to PMS, the reservation will be updated as soon as the request is processed.`,
      });

      dispatch(closeForm());
    } else {
      window.flash({
        message: `Fail to poll reservation. Please try again.`,
        timeout: 0,
      });
    }

    if (confirmationCode) {
      const POLL_RESERVATION_EVENT_NAME = 'POLL_RESERVATION';

      const channelName = `ACCESS_GRANT-${REACT_APP_VIRDEE_ENV}-${tenantId}-${locationId}-${confirmationCode}-${POLL_RESERVATION_EVENT_NAME}`;
      const accessGrantChannel = channelsClient.subscribe(channelName);

      // Send message notifying about timeout on poll reservation
      const completeTimeoutId = setTimeout(() => {
        accessGrantChannel.unbind(POLL_RESERVATION_EVENT_NAME);

        window.flash({
          message: `Fail on poll reservation ${confirmationCode}`,
          subText: 'Timeout',
          timeout: 0,
        });
      }, pollReservationTimeout);

      // Listen pusher message about reservation polled
      accessGrantChannel.bind(
        POLL_RESERVATION_EVENT_NAME,
        (data: { accessGrant: { id?: string; confirmationCode: string }; success: boolean }) => {
          clearTimeout(completeTimeoutId);
          accessGrantChannel.unbind(POLL_RESERVATION_EVENT_NAME);

          const {
            accessGrant: { id: accessGrantId },
            success,
          } = data;
          if (success) {
            window.flash({
              ...(accessGrantId && { link: `${links.paths.ACCESS_GRANTS}/${accessGrantId}` }),
              message: `Success on poll reservation - ${confirmationCode}`,
              timeout: 0,
            });
          } else {
            window.flash({
              ...(accessGrantId && { link: `${links.paths.ACCESS_GRANTS}/${accessGrantId}` }),
              message: `Fail on poll reservation ${confirmationCode}`,
              timeout: 0,
            });
          }
        }
      );
    }
  };

  return {
    onCompleteCallPollReservation,
  };
};

export default useCompleteCallPollReservation;
