import AuthenticationBaseContainer from '../../../../components/AuthenticationBaseComponent';
import React from 'react';
import TextContent from '../../../../components/TextContent';
import cloudbedsAuthMessages from '../constants/messages';

const CloudbedsAuthError: React.FC<{}> = () => {
  const TextContentStyle: React.CSSProperties = {
    color: 'white',
    textAlign: 'center',
  };

  return (
    <AuthenticationBaseContainer
      title={cloudbedsAuthMessages.title}
      subtitle={cloudbedsAuthMessages.subtitle}
    >
      <TextContent style={TextContentStyle}>
        Failure on authenticate Cloudbeds API. Please, try again.
      </TextContent>
    </AuthenticationBaseContainer>
  );
};

export default CloudbedsAuthError;
