import {
  CustomKioskUpdateInput,
  Mutation,
  MutationUpdateKioskArgs,
} from '../__generated__/graphql';

import MutationHookData from './@types/MutationHookData';
import MutationHookParams from './@types/MutationHookParams';
import UPDATE_KIOSK from '../graphql/updateKiosk';
import { closeForm } from '../redux/actions/modals.actions';
import { flashApolloError } from '../util/errorUtils';
import links from '../util/links';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';

type UpdateKioskData = Pick<Mutation, 'updateKiosk'>;

export default function useUpdateKiosk({
  onCompleted = (): void => {},
  onError,
}: MutationHookParams<UpdateKioskData> = {}): MutationHookData<
  CustomKioskUpdateInput,
  UpdateKioskData
> {
  const dispatch = useDispatch();

  const [update, { data, error, loading }] = useMutation<UpdateKioskData, MutationUpdateKioskArgs>(
    UPDATE_KIOSK,
    {
      onCompleted: ({ updateKiosk }) => {
        dispatch(closeForm());

        if (updateKiosk) {
          window.flash({
            link: `${links.paths.KIOSKS}/${updateKiosk.id}`,
            message: `Kiosk: ${updateKiosk.name} updated successfully`,
          });
        } else {
          window.flash({ message: 'Fail to update kiosk' });
        }

        onCompleted({ updateKiosk });
      },
      onError: onError ?? flashApolloError,
    }
  );

  return {
    data,
    error,
    loading,
    mutation: (inputs: CustomKioskUpdateInput, id): void => {
      if (id) {
        update({
          variables: {
            data: {
              ...inputs,
              dispenserType: inputs.dispenserType?.set ? inputs.dispenserType : null,
              powerSwitchId: inputs.powerSwitchId?.set ? inputs.powerSwitchId : null,
            },
            where: { id },
          },
        });
      } else {
        window.flash({
          message: 'Kiosk ID not present',
          type: 'error',
        });
      }
    },
  };
}
