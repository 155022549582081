import Form from '../../Form';
import React from 'react';
import { RootState } from '../../../redux/reducers';
import SimpleFormSection from '../../../templates/SimpleFormSection';
import SubmitRow from '../../SubmitRow';
import generateServiceSectionInputs from '../constants/serviceSectionInputs';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useSubmitService from '../hooks/useSubmitService';

const ServiceSection: React.FC<{}> = () => {
  const id = useSelector((state: RootState) => state.modals.form.id);
  const { handleSubmit, watch } = useFormContext();
  const imageType: string = watch('imageType');

  const { loadingMutation, submitHandler } = useSubmitService();

  const SERVICE_SECTION_INPUTS = generateServiceSectionInputs({ imageType });

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <SimpleFormSection inputs={SERVICE_SECTION_INPUTS} />
      <SubmitRow loading={loadingMutation} submitText={id ? 'Update Service' : 'Set Service'} />
    </Form>
  );
};

export default ServiceSection;
