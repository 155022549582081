/* eslint-disable sort-keys */

const KiosksLiveUsageContainer: React.CSSProperties = {
  marginTop: '10px',
  display: 'flex',
  flex: 1,
  flexWrap: 'wrap',
  gap: '20px',
};

export default KiosksLiveUsageContainer;
