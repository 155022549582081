import FormTemplateStep from '../../../templates/Form/@types/FormTemplateStep';
import { Mutation } from '../../../__generated__/graphql';
import ServiceFormInputs from '../@types/ServiceFormInputs';
import ServiceSection from '../components/ServiceSection';

type CreateServiceData = Pick<Mutation, 'createServiceAndUploadImageFile'>;

const SERVICE_FORM_STEPS: FormTemplateStep<ServiceFormInputs, CreateServiceData>[] = [
  {
    canBeAccessed: (): boolean => true,
    component: ServiceSection,
    name: 'Service',
  },
];

export default SERVICE_FORM_STEPS;
