import React, { useCallback } from 'react';
import DataTable from '../../../components/DataTable';
import { useParams } from 'react-router';
import usePaymentsTableData from '../../../hooks/usePaymentsTableData';

const PaymentsTab: React.FC<{}> = () => {
  const { userId } = useParams<{ userId: string }>();

  const getQueryOptions = useCallback(() => {
    return {
      where: {
        userId: { equals: userId },
      },
    };
  }, [userId]);

  const tableData = usePaymentsTableData({
    queryOptions: getQueryOptions(),
  });

  return <DataTable {...tableData} />;
};

export default React.memo(PaymentsTab);
