import 'styled-components/macro';

import Heading, { HeadingModel } from '../../../../../../components/Heading';
import { useDispatch, useSelector } from 'react-redux';

import AnswerCallButtonStyle from '../../styles/AnswerCallButton.style';
import Avatar from 'react-avatar';
import Button from '../../../../../../components/Button';
import ButtonModel from '../../../../../../components/Button/enums/ButtonModel.enum';
import { CallRecord } from '../../../../../../__generated__/graphql';
import Colors from '../../../../../../util/colors';
import Container from '../../../../../../components/Container';
import IncomingCallRedux from '../../../../../../redux/reducers/@types/IncomingCallRedux';
import React from 'react';
import { RootState } from '../../../../../../redux/reducers';
import TextContent from '../../../../../../components/TextContent';
import Theme from '../../../../../../theme';
import WaitingCallStyle from '../styles/WaitingCall.style';
import callActiveMessage from '../../../../../../components/IncomingCallModal/constants/callActiveMessage';
import { flashApolloError } from '../../../../../../util/errorUtils';
import { setActiveCall } from '../../../../../../redux/actions/remoteAssistance.actions';
import unauthenticatedUserName from '../../../../../../components/IncomingCallModal/constants/unauthenticatedUserName';
import useJoinVideo from '../../../../../../hooks/useJoinVideo';

interface WaitingCallProps {
  call: CallRecord;
}

const WaitingCall: React.FC<WaitingCallProps> = ({ call }: WaitingCallProps) => {
  const dispatch = useDispatch();
  const activeCall = useSelector((state: RootState) => state.remoteAssistance.activeCall);

  const incomingCall: IncomingCallRedux = {
    caller: call.caller,
    callRecordId: call.id,
    kiosk: call.kiosk,
    location: call.location,
    room: call.room!,
    type: call.type,
  };

  const { joinVideo } = useJoinVideo({
    incomingCall,
    onError: flashApolloError,
    room: call.room!,
  });

  function handleJoinVideo(): void {
    if (activeCall) {
      window.flash({
        message: callActiveMessage,
      });
      return;
    }

    dispatch(setActiveCall(incomingCall));

    joinVideo();
  }

  return (
    <Container as="li" css={WaitingCallStyle}>
      <Avatar
        color={Colors.cgblue}
        name={call.caller?.name || unauthenticatedUserName}
        size="54"
        round
      />
      <Heading
        color={Theme.colors.white.hexCode}
        margin="15px 0 15px"
        model={HeadingModel.QUARTERNARY}
      >
        {call.caller?.name || unauthenticatedUserName}
      </Heading>
      {call.location && (
        <TextContent color={Theme.colors.white.hexCode} margin="0 0 5px">
          {call.location.name}
        </TextContent>
      )}
      {call.kiosk && (
        <TextContent color={Theme.colors.white.hexCode}>{call.kiosk?.name}</TextContent>
      )}
      <TextContent color={Theme.colors.white.hexCode} margin="10px 0 15px">
        Incoming
      </TextContent>
      <Button
        bold
        borderColor="#E1E1E1"
        css={AnswerCallButtonStyle}
        data-identifier={call.id}
        model={ButtonModel.LIST_DARK}
        onClick={handleJoinVideo}
      >
        Answer Call
      </Button>
    </Container>
  );
};

export default React.memo(WaitingCall);
