import { Location, Mutation } from '../../__generated__/graphql';
import React, { useEffect } from 'react';

import FormTemplate from '../../templates/Form';
import { ImageTypeEnum } from '../UnitTypeForm/enums/ImageTypeEnum.enum';
import SERVICE_FORM_STEPS from './constants/serviceFormSteps';
import SERVICE_VALIDATION_SCHEMA from './constants/serviceValidationSchema';
import ServiceFormInputs from './@types/ServiceFormInputs';
import pick from 'lodash/pick';
import { serviceTypeLabels } from '../../enums/ServiceType.enum';
import useGetServiceById from '../../hooks/useGetServiceById';

interface LocationAditionalDataOnOpeningFormInterface {
  location: Pick<Location, 'id' | 'name'>;
}
interface ServiceProps {
  id?: string;
  aditionalDataOnOpeningForm?: unknown;
}

type CreateServiceData = Pick<Mutation, 'createServiceAndUploadImageFile'>;

const ServiceForm: React.FC<ServiceProps> = ({ aditionalDataOnOpeningForm, id }: ServiceProps) => {
  const { called, data: service, query } = useGetServiceById();

  useEffect(() => {
    if (id && !called) {
      query(id);
    }
  });

  if (id && !service) {
    return <React.Fragment />;
  }

  const getInitialValues = (): Partial<ServiceFormInputs> => {
    if (service) {
      const initialValues: Partial<ServiceFormInputs> = {
        ...pick(service, [
          'title',
          'description',
          'imageUrl',
          'price',
          'metadata',
          'askForTime',
          'displayCommentBox',
        ]),
        imageType: ImageTypeEnum.URL,
        type: {
          label: serviceTypeLabels[service.type],
          value: service.type,
        },
        ...(service.location
          ? {
              location: {
                label: service.location.name,
                value: service.location.id,
              },
            }
          : {}),
      };

      if (initialValues.metadata) {
        try {
          initialValues.metadata = JSON.stringify(initialValues.metadata);
          // eslint-disable-next-line no-empty
        } catch {}
      }

      return initialValues;
    }

    const defaultInitialValues = {
      displayCommentBox: true,
      imageType: ImageTypeEnum.URL,
    };

    try {
      if (aditionalDataOnOpeningForm) {
        const {
          location,
        } = aditionalDataOnOpeningForm as LocationAditionalDataOnOpeningFormInterface;
        return {
          ...defaultInitialValues,
          location: {
            label: location.name,
            value: location.id,
          },
        };
      }
      // eslint-disable-next-line no-empty
    } catch {}

    return defaultInitialValues;
  };

  return (
    <FormTemplate<ServiceFormInputs, CreateServiceData>
      formHeader={id ? 'Edit Service' : 'Create Service'}
      identifier={id}
      steps={SERVICE_FORM_STEPS}
      validationSchema={SERVICE_VALIDATION_SCHEMA}
      initialValues={getInitialValues()}
    />
  );
};

export default ServiceForm;
