import { Query, QueryUnitTypesArgs, UnitTypeWhereInput } from '../../__generated__/graphql';
import { useCallback, useEffect, useRef, useState } from 'react';

import { EntityEnum } from '../../@types/client/DashboardUserRolePermissions';
import GET_UNIT_TYPE_COUNT from '../../graphql/aggregateUnitType';
import GET_UNIT_TYPE_TABLE_DATA from '../../graphql/getUnitTypeTableData';
import { LocationPageInfo } from '../../pages/Location/@types/GetLocationPageInfo';
import OrderByParams from '../../@types/client/OrderByParams';
import { RootState } from '../../redux/reducers';
import SearchField from '../../@types/SearchField';
import TableHookData from '../@types/TableHookData';
import TableHookProps from '../@types/TableHookProps';
import UNIT_TYPES_TABLE_HEADERS from './constants/UnitTypesTableHeaders';
import UNIT_TYPES_TABLE_SEARCH_FIELDS from './constants/UnitTypesTableSearchFields';
import generateLocationMembershipQuery from '../../util/api/generateLocationMembershipQuery';
import generateUnitTypesTableData from './functions/generateUnitTypesTableData';
import parseOrderByDataTableParams from '../../components/DataTable/util/parseOrderByDataTableParams';
import useGetMany from '../useGetMany';
import useRemoveUnitType from '../useRemoveUnitType';
import { useSelector } from 'react-redux';

type UnitTypesQueryParams = {
  orderBy?: OrderByParams;
  where: Partial<UnitTypeWhereInput>;
};

interface UnitTypesTableData extends TableHookProps<QueryUnitTypesArgs, UnitTypeWhereInput> {
  relatedLocation?: LocationPageInfo;
}

type GetUnitTypeTableData = Pick<Query, 'unitTypes'>;
type AggregateUnitTypeQueryData = Pick<Query, 'aggregateUnitType'>;

export default function useUnitTypesTableData({
  dataTableKey = 'unitTypes',
  formName = 'unitType',
  headers = UNIT_TYPES_TABLE_HEADERS,
  isSearchable = true,
  isImportEnabled = false,
  permission = EntityEnum.UNIT_TYPE,
  queryOptions,
  searchFields = UNIT_TYPES_TABLE_SEARCH_FIELDS,
  relatedLocation,
}: UnitTypesTableData): TableHookData<UnitTypeWhereInput> {
  const membership = useSelector((state: RootState) => state.me.activeMembership);
  const options = useRef<Partial<QueryUnitTypesArgs>>(queryOptions ?? {});
  const sortOptions = useSelector((state: RootState) => state.dataTable.sortOptions);

  const [searchParameters, setSearchParameters] = useState<UnitTypeWhereInput>({});

  const { data, error, loading, offset, query, setOffset } = useGetMany<
    GetUnitTypeTableData,
    QueryUnitTypesArgs
  >({
    graphqlQuery: GET_UNIT_TYPE_TABLE_DATA,
  });

  const {
    data: countData,
    error: countError,
    loading: countLoading,
    query: countQuery,
  } = useGetMany<AggregateUnitTypeQueryData, QueryUnitTypesArgs>({
    graphqlQuery: GET_UNIT_TYPE_COUNT,
  });

  const createParams = useCallback((): UnitTypesQueryParams => {
    const sortParams = parseOrderByDataTableParams(sortOptions[dataTableKey]);

    return {
      ...(sortParams && { orderBy: sortParams }),
      where: {
        ...generateLocationMembershipQuery(membership),
        ...options.current?.where,
        ...searchParameters,
      },
    };
  }, [membership, options, searchParameters, sortOptions, dataTableKey]);

  const get = useCallback(() => {
    const params = createParams();

    query({
      ...options.current,
      ...(params.orderBy && { orderBy: params.orderBy }),
      skip: options.current?.skip ?? offset,
      take: options.current?.take ?? 10,
      where: params.where,
    });
  }, [createParams, offset, query]);

  const getCount = useCallback(() => {
    const params = createParams();

    countQuery({
      where: params.where,
    });
  }, [countQuery, createParams]);

  useEffect(() => {
    get();
  }, [get]);

  useEffect(() => {
    getCount();
  }, [getCount]);

  return {
    aditionalDataOnOpeningForm: relatedLocation
      ? {
          location: {
            id: relatedLocation.id,
            name: relatedLocation.name,
          },
        }
      : undefined,
    count: countData?.aggregateUnitType._count?._all ?? 0,
    data: generateUnitTypesTableData(data),
    dataTableKey,
    dataType: 'Unit Type',
    error: error || countError,
    formName,
    headers,
    isImportEnabled,
    isLoading: loading || countLoading,
    isSearchable,
    offset,
    permission,
    remove: useRemoveUnitType,
    search: (searchField: SearchField<UnitTypeWhereInput>, searchValue: string): void => {
      setSearchParameters({
        [searchField.queryField]: searchField.transform(searchValue),
      });

      setOffset(0);
    },
    searchFields,
    setOffset,
  };
}
