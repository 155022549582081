import { gql } from '../__generated__/gql';

const SEARCH_ACCESS_GRANTS = gql(`
  query SearchAccessGrants(
    $orderBy: [AccessGrantOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: AccessGrantWhereInput
  ) {
    accessGrants(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
      id
      confirmationCode
    }
  }
`);

export default SEARCH_ACCESS_GRANTS;
