import { gql } from '../__generated__/gql';

const UPDATE_ACCESS_GRANT = gql(`
  mutation UpdateAccessGrant(
    $data: AccessGrantUpdateInput!
    $originalAgreementFileId: String
    $where: AccessGrantWhereUniqueInput!
  ) {
    updateAccessGrant(
      data: $data
      originalAgreementFileId: $originalAgreementFileId
      where: $where
    ) {
      ...AccessGrantTableFields
    }
  }
`);

export default UPDATE_ACCESS_GRANT;
