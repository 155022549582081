import { ReduxMembership } from '../../redux/reducers/@types/MeReduxState';

type NestedMembershipQueryType = {
  memberships: {
    some: {
      locationId?: { equals: string };
      tenantId: { equals: string };
    };
  };
};

export default function generateNestedMembershipQuery(
  membership: ReduxMembership
): NestedMembershipQueryType {
  return {
    memberships: {
      some: {
        ...(membership.location && { locationId: { equals: membership.location.id } }),
        tenantId: { equals: membership.tenant.id },
      },
    },
  };
}
