import { LimitKeys, Query, QueryGetLimitKeysConfigArgs } from '../__generated__/graphql';

import GET_LIMIT_KEYS_CONFIG from '../graphql/getLimitKeysConfig';
import { flashApolloError } from '../util/errorUtils';
import { useQuery } from '@apollo/client';

type LimitKeysData = Pick<Query, 'getLimitKeysConfig'>;

interface UseLimitKeysConfig {
  limitKeysData: LimitKeys | undefined;
  isLoadingLimitKeys: boolean;
}

export default function useLimitKeysConfig({
  accessGrantId,
  limitKeysConfigLevel,
  locationId,
  tenantId,
}: QueryGetLimitKeysConfigArgs): UseLimitKeysConfig {
  const { data, loading: isLoadingLimitKeys } = useQuery<
    LimitKeysData,
    QueryGetLimitKeysConfigArgs
  >(GET_LIMIT_KEYS_CONFIG, {
    fetchPolicy: 'network-only',
    onError: flashApolloError,
    variables: {
      accessGrantId,
      limitKeysConfigLevel,
      locationId,
      tenantId,
    },
  });

  return {
    isLoadingLimitKeys,
    limitKeysData: data?.getLimitKeysConfig,
  };
}
