import isValidColorString from '../../../../util/css/isValidColorString';
import styled from 'styled-components';

interface DisplayBlockProps {
  background?: string;
}

const Block = styled.span<DisplayBlockProps>`
  background: ${({ background }): string => {
    if (background && isValidColorString(background)) {
      return background;
    }

    return 'transparent';
  }};
  border-radius: 12px;
  display: block;
  height: 48px;
  margin: 0 15px 0 0;
  width: 48px;

  @media only screen and (max-width: 1200px), screen and (max-width: 1450px) {
    border-radius: 8px;
    height: 30px;
    width: 30px;
  }
`;

Block.displayName = 'DisplayBlock';

export default Block;
