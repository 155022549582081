import { Colors } from '../../theme/colors';
import TextInputProps from './@types/TextInputProps';
import TextInputStyles from './styles';
import Theme from '../../theme';
import styled from 'styled-components';

const TextInput = styled.input.attrs(({ type = 'text' }: TextInputProps) => ({
  type,
}))<TextInputProps>`
  background: ${({ model }: TextInputProps): string => {
    if (model) {
      const activeModel = TextInputStyles[model];
      return activeModel.default.background;
    }

    return 'initial';
  }};
  border-color: ${({ model }: TextInputProps): string => {
    if (model) {
      const activeModel = TextInputStyles[model];
      return activeModel.default.borderColor ?? 'initial';
    }

    return 'initial';
  }};
  border-radius: ${({ model }: TextInputProps): string => {
    if (model) {
      const activeModel = TextInputStyles[model];
      return activeModel.default.borderRadius ?? 'initial';
    }

    return 'initial';
  }};
  border-style: solid;
  box-sizing: border-box;
  color: ${({ model }: TextInputProps): string => {
    if (model) {
      const activeModel = TextInputStyles[model];
      return activeModel.default.color;
    }

    return 'initial';
  }};
  font-size: ${Theme.fontStyles.bodyLarge.size};
  font-weight: ${({ model }: TextInputProps): string => {
    if (model) {
      const activeModel = TextInputStyles[model];
      return activeModel.default.fontWeight ?? 'initial';
    }

    return 'initial';
  }};
  outline: 0;
  padding: 10px 15px;
  width: 100%;

  &.error {
    border-color: ${Colors.salmon};
  }

  &:focus:not(.error) {
    border-color: ${({ model }: TextInputProps): string => {
      if (model) {
        const activeModel = TextInputStyles[model];
        return activeModel.default.active?.borderColor ?? 'initial';
      }

      return 'initial';
    }};
    outline: 0;
  }
`;

TextInput.displayName = 'TextInput';

export default TextInput;
