import React, { useContext } from 'react';
import { Line } from '@visx/shape';
import LineGraphPositionContext from '../contexts/LineGraphPositionContext';
import SingleDayAggregate from '../../../@types/client/SingleDayAggregate';
import { accentColorDark } from '../constants/colors';

interface LGToolTipLineProps {
  data?: SingleDayAggregate;
}

export default function LGToolTipLine({ data }: LGToolTipLineProps): React.ReactElement {
  const { innerHeight, tooltipLeft, tooltipTop } = useContext(LineGraphPositionContext);

  if (data) {
    return (
      <g>
        <Line
          from={{ x: tooltipLeft, y: 0 }}
          to={{ x: tooltipLeft, y: innerHeight }}
          stroke={accentColorDark}
          strokeWidth={2}
          pointerEvents="none"
          strokeDasharray="5,2"
        />
        <circle
          cx={tooltipLeft}
          cy={tooltipTop + 1}
          r={4}
          fill="black"
          fillOpacity={0.1}
          stroke="black"
          strokeOpacity={0.1}
          strokeWidth={2}
          pointerEvents="none"
        />
        <circle
          cx={tooltipLeft}
          cy={tooltipTop}
          r={4}
          fill={accentColorDark}
          stroke="white"
          strokeWidth={2}
          pointerEvents="none"
        />
      </g>
    );
  }

  return <></>;
}
