import 'styled-components/macro';

import Heading, { HeadingModel } from '../../components/Heading';
import React, { CSSProperties } from 'react';

import AuthenticationInnerContainerStyle from './styles/AuthenticationInnerContainer.style';
import AuthenticationOuterContainerStyle from './styles/AuthenticationOuterContainer.style';
import { Colors } from '../../theme/colors';
import Container from '../../components/Container';
import { ReactComponent as Logo } from '../../assets/logo/virdee_square_logo.svg';

interface AuthenticationBaseContainerInterface {
  children?: React.ReactElement;
  title: string;
  subtitle?: string;
  secondLevelSubtitle?: string;
}

const AuthenticationBaseContainer: React.FC<AuthenticationBaseContainerInterface> = ({
  children,
  title,
  subtitle,
  secondLevelSubtitle,
}: AuthenticationBaseContainerInterface) => {
  const headerStyle: CSSProperties = {
    textAlign: 'center',
  };

  return (
    <Container css={AuthenticationOuterContainerStyle}>
      <Container css={AuthenticationInnerContainerStyle}>
        <Logo />
        <Heading
          color={Colors.white}
          margin="0 0 10px"
          model={HeadingModel.PRIMARY}
          style={headerStyle}
        >
          {title}
        </Heading>
        {subtitle && (
          <Heading
            color={Colors.white}
            margin="0 0 5px"
            model={HeadingModel.SECONDARY}
            fontWeight="normal"
            style={headerStyle}
          >
            {subtitle}
          </Heading>
        )}
        {secondLevelSubtitle && (
          <Heading
            color={Colors.white}
            model={HeadingModel.TERTIARY}
            fontWeight="normal"
            style={headerStyle}
          >
            {secondLevelSubtitle}
          </Heading>
        )}
        <Container margin="25px 0 0">{children}</Container>
      </Container>
    </Container>
  );
};

export default AuthenticationBaseContainer;
