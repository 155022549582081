import { BarIcon, BarTitle, Header, Wrapper } from './styles/QuickActionBar.styles';
import React, { useRef, useState } from 'react';
import { ActionBarArrow } from '../../assets/icons/actionBarArrow';
import { ActionsList } from './components/ActionsList';
import { QuickActionModals } from './components/QuickActionModals';

export const QuickActionBar: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const actionBarRef = useRef<HTMLDivElement>(null);

  const onBarClick = (): void => {
    if (!open) {
      actionBarRef.current?.focus();
    }

    setOpen(!open);
  };

  return (
    <>
      <Wrapper open={open} ref={actionBarRef} tabIndex={1}>
        <Header onClick={onBarClick}>
          <BarIcon open={open}>
            <ActionBarArrow />
          </BarIcon>
          <BarTitle open={open}>Quick Actions</BarTitle>
        </Header>
        {open && <ActionsList />}
      </Wrapper>
      <QuickActionModals />
    </>
  );
};
