import { ServiceType, ServiceWhereInput } from '../../../__generated__/graphql';

import { DataTableFilterValues } from '../../../@types/client/DataTableFilterValues';

export default function parseServiceQueryParameters(
  fieldsFiltered: DataTableFilterValues[]
): Partial<ServiceWhereInput> {
  const whereInput: Partial<ServiceWhereInput> = {};

  if (fieldsFiltered?.length > 0) {
    // Service Type filter
    const vendorFieldsIndex = fieldsFiltered.findIndex((field) => field.fieldKey === 'type');
    if (vendorFieldsIndex > -1) {
      const inInput = fieldsFiltered[vendorFieldsIndex].values as ServiceType[];
      whereInput.type = {};
      whereInput.type.in = inInput;
    }
  }

  return whereInput;
}
