export enum LineHeight {
  bodyLarge = '22px',
  bodySmall = '20px',
  display = '20px',
  firstHeading = '50px',
  fourthHeading = '20px',
  label = '20px',
  secondHeading = '30px',
  thirdHeading = '22px',
}

export default {
  bodyLarge: LineHeight.bodyLarge,
  bodySmall: LineHeight.bodySmall,
  display: LineHeight.display,
  firstHeading: LineHeight.firstHeading,
  fourthHeading: LineHeight.fourthHeading,
  label: LineHeight.label,
  secondHeading: LineHeight.secondHeading,
  thirdHeading: LineHeight.thirdHeading,
};
