import { gql } from '../__generated__/gql';

export const UPDATE_RESOURCE_GROUP = gql(`
  mutation UpdateResourceGroup(
    $data: ResourceGroupUpdateInput!
    $where: ResourceGroupWhereUniqueInput!
  ) {
    updateResourceGroup(data: $data, where: $where) {
      id
      isDefault
      name
      resources {
        id
        name
      }
    }
  }
`);
