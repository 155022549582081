type ColorString = string & { __isValidColorString: true };

export default function isValidColorString(colorString: string): colorString is ColorString {
  const hex = new RegExp(/^#(?:[0-9a-fA-F]{3}){1,2}$/);
  // const rgb = new RegExp(/^rgb\(\s*[0-255](\d{1,3}),\s*[0-255](\d{1,3}),\s*[0-255](\d{1,3})\)$/);
  // const rgba = new RegExp(
  //   /^rgba\(\s*[0-255](\d{1,3}),\s*[0-255](\d{1,3}),\s*[0-255](\d{1,3}),\s*(\d*(?:\.\d+)?)\)$/
  // );

  const rgb = new RegExp(
    /^rgb\((0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),\s?(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),\s?(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d)\)$/
  );
  const rgba = new RegExp(
    /^rgba\((0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),\s?(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),\s?(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),\s?(0|0?\.\d|1(\.0)?)\)$/
  );

  return hex.test(colorString) || rgb.test(colorString) || rgba.test(colorString);
}
