import { ImageTypeEnum } from '../enums/ImageTypeEnum.enum';
import InputGroupProps from '../../InputGroup/@types/InputGroupProps';
import { MAX_IMAGE_SIZE } from '../../../util/fileUtil';
import useLoadLocationOptions from '../../../hooks/useLoadLocationOptions';
import useSearchLocationsByName from '../../../hooks/useSearchLocations';

interface UnitTypeImageFormInputProps {
  imageType: string;
}

export default function generateUnitTypeSectionInputs({
  imageType,
}: UnitTypeImageFormInputProps): InputGroupProps[] {
  const unitTypeSectionInputs: InputGroupProps[] = [
    {
      label: 'Type',
      name: 'type',
      type: 'text',
    },
    {
      label: 'Search Location',
      loadOptions: useLoadLocationOptions,
      name: 'location',
      query: useSearchLocationsByName,
      type: 'async-select',
    },
    {
      label: 'Number of Guests',
      min: 1,
      name: 'numberOfGuests',
      type: 'number',
    },
    {
      label: 'Image type',
      name: 'imageType',
      options: Object.values(ImageTypeEnum),
      type: 'select',
    },
  ];

  if (imageType === ImageTypeEnum.URL) {
    unitTypeSectionInputs.push({
      label: 'Image URL',
      name: 'imageUrl',
      required: true,
      type: 'url',
    });
  } else if (imageType === ImageTypeEnum.UPLOAD) {
    unitTypeSectionInputs.push({
      accept: 'image/*',
      label: 'Image file',
      maxFileSize: MAX_IMAGE_SIZE,
      name: `imageFile`,
      required: true,
      type: 'file',
    });
  }

  return unitTypeSectionInputs;
}
