import Heading, { HeadingModel } from '../../Heading';
import React, { useContext } from 'react';

import Container from '../../Container';
import DASHBOARD_PREFERENCES_INPUTS from '../constants/dashboardPreferencesInputs';
import Form from '../../Form';
import FormContext from '../../../contexts/form.context';
import { Role } from '../../../__generated__/graphql';
import { RootState } from '../../../redux/reducers';
import SimpleFormSection from '../../../templates/SimpleFormSection';
import SubmitRow from '../../SubmitRow';
import generateUserSectionInputs from '../functions/generateUserSectionInputs';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import userIsAbleToSetPassword from '../functions/userIsAbleToSetPassword';

const UserSection: React.FC<{}> = () => {
  const { identifier } = useContext(FormContext);
  const loggedUser = useSelector((state: RootState) => state.me);
  const { errors, handleSubmit, trigger, watch } = useFormContext();
  const { setStep, step } = useContext(FormContext);

  const userRole = watch('role');

  const USER_SECTION_INPUTS = generateUserSectionInputs({
    isAbleToSetPassword: userIsAbleToSetPassword({
      currentUser: loggedUser,
      editingUserId: identifier,
      userRole,
    }),
    isEditing: Boolean(identifier),
    trigger,
  });

  const submitHandler = (): void => {
    setStep((step ?? 0) + 1);
  };

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Container
        css={`
          grid-auto-rows: max-content;
          grid-row-gap: 25px;
        `}
      >
        <SimpleFormSection inputs={USER_SECTION_INPUTS} />
        {[Role.Admin, Role.Agent].includes(userRole) && (
          <>
            <Heading
              style={{
                marginBottom: '5px',
                marginTop: '20px',
              }}
              model={HeadingModel.TERTIARY}
            >
              Dashboard preferences
            </Heading>
            <SimpleFormSection inputs={DASHBOARD_PREFERENCES_INPUTS} />
          </>
        )}
      </Container>
      <SubmitRow disabled={errors.phoneNumber} loading={false} submitText="Next" />
    </Form>
  );
};

export default UserSection;
