import ReceiveAggregateArrivalsAction from '../@types/ReceiveAggregateArrivalsAction';
import ReportingActions from '../enums/actions.enum';
import SingleDayAggregate from '../../../../@types/client/SingleDayAggregate';

export default function receiveAggregateArrivals(
  arrivals: SingleDayAggregate[]
): ReceiveAggregateArrivalsAction {
  return {
    payload: {
      aggregateArrivals: arrivals,
    },
    type: ReportingActions.RECEIVE_AGGREGATE_ARRIVALS,
  };
}
