import 'styled-components/macro';

import FormContext, { FormContextProps } from '../../contexts/form.context';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';

import { ApolloErrorType } from '../../util/errorUtils';
import AuthenticationBaseContainer from '../../components/AuthenticationBaseComponent';
import AuthenticationFormActionTextStyle from '../../components/AuthenticationBaseComponent/styles/AuthenticationFormActionText.style';
import AuthenticationFormInputStyle from '../../components/AuthenticationBaseComponent/styles/AuthenticationFormInput.style';
import AuthenticationFormSubmit1stButtonStyle from '../../components/AuthenticationBaseComponent/styles/AuthenticationFormSubmit1stButtonStyle.style';
import Container from '../../components/Container';
import Form from '../../components/Form';
import InputGroup from '../../components/InputGroup';
import { REQUIRED_PASSWORD_VALIDATION_SCHEMA } from '../../components/UserForm/constants/passwordValidationSchema';
import { RESET_PASSWORD_BY_EMAIL } from '../../graphql/mutations/resetPassword';
import ResetPasswordFormInputs from './@types/ResetPasswordFormInputs';
import TextContent from '../../components/TextContent';
import getEmailAndChallengeFromUrl from './functions/getEmailAndChallengeFromUrl';
import links from '../../util/links';
import { useMutation } from '@apollo/client';
import useSignIn from '../../hooks/useSignIn';
import { yupResolver } from '@hookform/resolvers/yup';

type ResetPasswordByEmailResult = boolean;

function ResetPassword(): React.ReactElement {
  const methods = useForm<ResetPasswordFormInputs>({
    mode: 'onTouched',
    resolver: yupResolver(REQUIRED_PASSWORD_VALIDATION_SCHEMA),
  });
  const { errors, handleSubmit, getValues } = methods;

  const location = useLocation();
  const { email, challenge } = getEmailAndChallengeFromUrl(location.search);
  const password = getValues('password');

  const history = useHistory();

  const { loading: signInLoading, signIn } = useSignIn();

  function displayMessage(success: boolean, subtext?: string): void {
    window.flash({
      message: success
        ? 'Password reset successfully. Logging into dashboard.'
        : 'Failure on reset password',
      onClose: async (): Promise<void> => {
        if (success) {
          await signIn({
            variables: { email, password },
          });
        } else if (history.location.pathname.startsWith(links.paths.RESET_PASSWORD)) {
          history.push(links.paths.LOGIN);
        }
      },
      subText: subtext,
      timeout: 5000,
    });
  }

  useEffect(() => {
    if (!email || !challenge) {
      displayMessage(false, 'Email or challenge are invalid.');
    }
  }, [email, challenge, history]);

  const [
    resetPasswordByEmail,
    { loading: resetPasswordLoading, data: resetPasswordData },
  ] = useMutation(RESET_PASSWORD_BY_EMAIL, {
    onCompleted: (result: ResetPasswordByEmailResult) => {
      displayMessage(result);
    },
    onError: (error: ApolloErrorType): void => {
      displayMessage(false, error.message);
    },
  });

  const onSubmit: SubmitHandler<ResetPasswordFormInputs> = (
    inputs: ResetPasswordFormInputs
  ): void => {
    resetPasswordByEmail({
      variables: {
        challenge,
        email,
        newPassword: inputs.password,
      },
    });
  };

  if (!email || !challenge || (resetPasswordData !== undefined && !resetPasswordData)) {
    return (
      <AuthenticationBaseContainer title="Welcome to Virdee" subtitle="Failure on reset password">
        <Link to={links.paths.LOGIN}>
          <TextContent style={{ ...AuthenticationFormActionTextStyle, textAlign: 'center' }}>
            ← Back to login
          </TextContent>
        </Link>
      </AuthenticationBaseContainer>
    );
  }

  const FORM_CONTEXT_PROPS: FormContextProps = {
    setIdentifier: (): void => {},
    setStep: (): void => {},
    setStepsToDisplay: (): void => {},
    step: 0,
    stepsToDisplay: [],
  };

  return (
    <AuthenticationBaseContainer title="Welcome to Virdee" subtitle="Enter your new password">
      <Container>
        <FormContext.Provider value={FORM_CONTEXT_PROPS}>
          <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <InputGroup
                key="password"
                css={AuthenticationFormInputStyle}
                type="password"
                name="password"
                placeholder="Password"
                matchField="confirmPassword"
                className={`${errors.password && 'error'}`}
              />
              <InputGroup
                key="confirmPassword"
                css={AuthenticationFormInputStyle}
                name="confirmPassword"
                placeholder="Confirm password"
                type="password"
                matchField="password"
                shouldRegister={false}
                className={`${errors.confirmPassword && 'error'}`}
              />
              <input
                css={AuthenticationFormSubmit1stButtonStyle}
                disabled={resetPasswordLoading || signInLoading || resetPasswordData !== undefined}
                type="submit"
                value="Send"
              />
            </Form>
          </FormProvider>
        </FormContext.Provider>
      </Container>
    </AuthenticationBaseContainer>
  );
}
export default ResetPassword;
