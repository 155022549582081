import { gql } from '../__generated__/gql';

const GET_GRACE_PERIODS_CONFIG = gql(`
  query getGracePeriodsConfig(
    $gracePeriodsConfigLevel: String!
    $accessGrantId: String
    $locationId: String
    $tenantId: String
  ) {
    getGracePeriodsConfig(
      gracePeriodsConfigLevel: $gracePeriodsConfigLevel
      accessGrantId: $accessGrantId
      locationId: $locationId
      tenantId: $tenantId
    ) {
      rooms {
        ...GracePeriodValuesFields
      }
      commonAreas {
        ...GracePeriodValuesFields
      }
      earlyCheckInConfig {
        allowEarlyCheckIn
        checkInGracePeriod
      }
    }
  }
`);

export default GET_GRACE_PERIODS_CONFIG;
