import { gql } from '../__generated__/gql';

const GET_UNIT_TYPE_LOCALIZATION = gql(`
  query GetUnitType(
    $where: UnitTypeWhereUniqueInput!
    $unitTypeId: String!
    $componentName: String!
  ) {
    unitType(where: $where) {
      ...UnitTypeFields
    }

    getUnitTypeLocalizations(unitTypeId: $unitTypeId, componentName: $componentName) {
      id
      language {
        id
        code
        name
        isRTL
      }
      settingsData
    }
  }
`);

export default GET_UNIT_TYPE_LOCALIZATION;
