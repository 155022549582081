import { RequirementStatus } from '../../../__generated__/graphql';

const ID_VERIFICATION_STATUS_TOOLTIPS: { [K in RequirementStatus]: string } = {
  [RequirementStatus.Completed]: 'ID Verification has completed successfully',
  [RequirementStatus.ContactUser]: 'Contact the user?',
  [RequirementStatus.Failed]: 'ID fails to match user',
  [RequirementStatus.NotRequired]: 'ID Verification is not required for this user',
  [RequirementStatus.Redo]: 'ID Verification was unable to complete successfully, please try again',
  [RequirementStatus.Required]: 'ID Verification is required and has not been completed',
  [RequirementStatus.VerifyInformation]: 'Please review document',
  [RequirementStatus.Processing]: RequirementStatus.Processing,
};

export default ID_VERIFICATION_STATUS_TOOLTIPS;
