import { Colors } from '../../../theme/colors';

export const DetailPageSummaryExternalContainerStyle = `
  border-bottom: 1px solid ${Colors.shuttleGrey};
`;

export const horizontalPadding = '80px';
export const verticalPadding = '30px';

const DetailPageSummaryContainerStyle = `
  grid-auto-flow: column;
  grid-column-gap: 49px;
  grid-template-columns: 125px 1fr;
  padding: ${verticalPadding} ${horizontalPadding} 0px ${horizontalPadding};

  @media only screen and (max-width: 1300px) {
    padding: 20px 60px;
  }
`;

export default DetailPageSummaryContainerStyle;
