import { gql } from '../__generated__/gql';

const UPSERT_OR_DELETE_UNIT_TYPE_LOCALIZATIONS = gql(`
  mutation upsertOrDeleteUnitTypeLocalizations(
    $unitTypeId: String!
    $componentName: String!
    $unitTypeLocalizations: CustomLocalizationInputArgs!
  ) {
    upsertOrDeleteUnitTypeLocalizations(
      unitTypeId: $unitTypeId
      componentName: $componentName
      unitTypeLocalizations: $unitTypeLocalizations
    ) {
      id
      language {
        code
        name
      }
    }
  }
`);

export default UPSERT_OR_DELETE_UNIT_TYPE_LOCALIZATIONS;
