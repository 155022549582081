import { AccessGrant, Location, Requirement, Resource, User } from '../../../__generated__/graphql';

import AccessGrantFormInputs from '../@types/AccessGrantFormInputs';
import AccessGrantFormResourceMap from '../@types/AccessGrantFormResourceMap';
import reduce from 'lodash/reduce';
import { tz } from 'moment-timezone';

export default function generateAccessGrantFormValues(
  accessGrant: Pick<
    AccessGrant,
    | 'code'
    | 'confirmationCode'
    | 'endDate'
    | 'endTime'
    | 'endsAt'
    | 'id'
    | 'retryCheckin'
    | 'startDate'
    | 'startTime'
    | 'startsAt'
    | 'status'
    | 'type'
  > & {
    location: Pick<Location, 'id' | 'name' | 'timezone'>;
    requirements: Pick<Requirement, 'id' | 'status' | 'type' | 'metadata'>[];
    resources: Pick<Resource, 'id' | 'name'>[];
    user: Pick<User, 'id' | 'name'>;
    secondaryUsers: { user: Pick<User, 'id' | 'name'> }[];
  }
): AccessGrantFormInputs {
  const startsAt = new Date(
    tz(accessGrant.startsAt, accessGrant.location.timezone).format('MM/DD/YYYY')
  );
  const endsAt = new Date(
    tz(accessGrant.endsAt, accessGrant.location.timezone).format('MM/DD/YYYY')
  );

  const generateAppropriateMap = (
    items: { id: string; name: string }[]
  ): {
    map: AccessGrantFormResourceMap;
    values: {
      label: string;
      value: string;
    }[];
  } => {
    return {
      map: reduce(
        items,
        (acc, item) => ({
          ...acc,
          [item.id]: {
            isConnected: true,
            option: {
              label: item.name,
              value: item.id,
            },
            shouldConnect: false,
            shouldDisconnect: false,
          },
        }),
        {} as AccessGrantFormResourceMap
      ),
      values: items.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    };
  };

  return {
    code: accessGrant.code ?? '',
    endsAt,
    endTime: accessGrant.endTime,
    location: {
      label: accessGrant.location.name,
      value: accessGrant.location.id,
    },
    requirements: {
      delete: [],
      existing: accessGrant.requirements.map((requirement) => ({
        metadata: requirement.metadata,
        requirementId: requirement.id,
        status: requirement.status,
        type: requirement.type,
      })),
      values: accessGrant.requirements.map((requirement) => ({
        metadata: requirement.metadata,
        requirementId: requirement.id,
        status: requirement.status,
        type: requirement.type,
      })),
    },
    resources: generateAppropriateMap(accessGrant.resources),
    retryCheckin: accessGrant.retryCheckin,
    secondaryUsers: generateAppropriateMap(
      accessGrant.secondaryUsers.map(({ user }) => {
        return {
          id: user.id,
          name: user.name ?? user.id,
        };
      })
    ),
    startsAt,
    startTime: accessGrant.startTime,
    status: accessGrant.status,
    type: accessGrant.type,
    user: {
      label: accessGrant.user.name ?? accessGrant.user.id,
      value: accessGrant.user.id,
    },
  };
}
