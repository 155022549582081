import { gql } from '../__generated__/gql';

const DISCONNECT_CLOUDBEDS_APP = gql(`
  mutation CloudbedsDisconnectApp($locationId: String!) {
    cloudbedsDisconnectApp(locationId: $locationId) {
      success
    }
  }
`);

export default DISCONNECT_CLOUDBEDS_APP;
