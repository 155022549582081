import DataTablePage from '../../templates/DataTablePage/DataTablePage';
import React from 'react';
import useKiosksTableData from '../../hooks/useKiosksTableData';

function KiosksPage(): React.ReactElement {
  const tableData = useKiosksTableData({});
  return <DataTablePage bannerText="Kiosks" {...tableData} />;
}

export default KiosksPage;
