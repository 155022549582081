import { Header } from '../../../components/DataTable/@types/DataTableProps';
import SortType from '../../../components/DataTable/@types/SortType.enum';

const PAYMENT_TRANSACTIONS_TABLE_HEADERS: Header[] = [
  { displayValue: 'Invoice Number', isSortable: false },
  { displayValue: 'Amount', isSortable: true, sortType: SortType.SortOrder, value: 'amount' },
  { displayValue: 'Status', isSortable: true, sortType: SortType.SortOrder, value: 'status' },
  {
    displayValue: 'Created At',
    isSortable: true,
    sortType: SortType.SortOrder,
    value: 'createdAt',
  },
];

export default PAYMENT_TRANSACTIONS_TABLE_HEADERS;
