import {
  CustomInfoItemCreateInput,
  Mutation,
  MutationCreateInfoItemAndUploadImageFileArgs,
} from '../../../__generated__/graphql';

import { CREATE_INFO_ITEM } from '../../../graphql/createInfoItem';
import MutationHookData from '../../../hooks/@types/MutationHookData';
import MutationHookParams from '../../../hooks/@types/MutationHookParams';
import { apolloRequirePreflightHeader } from '../../../client';
import { closeForm } from '../../../redux/actions/modals.actions';
import { flashApolloError } from '../../../util/errorUtils';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';

type CreateInfoItemData = Pick<Mutation, 'createInfoItemAndUploadImageFile'>;

export default function useCreateInfoItemAndUploadImage({
  onCompleted = (): void => {},
}: MutationHookParams<CreateInfoItemData> = {}): MutationHookData<
  CustomInfoItemCreateInput,
  CreateInfoItemData
> {
  const dispatch = useDispatch();

  const [create, { data, error, loading }] = useMutation<
    CreateInfoItemData,
    MutationCreateInfoItemAndUploadImageFileArgs
  >(CREATE_INFO_ITEM, {
    context: {
      headers: apolloRequirePreflightHeader,
    },
    onCompleted: ({ createInfoItemAndUploadImageFile }) => {
      window.flash({
        message: `InfoItem: ${createInfoItemAndUploadImageFile.title} created successfully`,
      });

      onCompleted({ createInfoItemAndUploadImageFile });

      dispatch(closeForm());
    },
    onError: flashApolloError,
  });

  return {
    data,
    error,
    loading,
    mutation: (inputs: CustomInfoItemCreateInput): void => {
      create({
        variables: {
          data: inputs,
        },
      });
    },
  };
}
