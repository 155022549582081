import { CHECKBOX_CSS_PROPERTY_MAP } from '..';
import { CheckmarkSVG } from './Checkmark';
import HiddenCheckbox from './HiddenCheckbox';
import styled from 'styled-components';

interface StyledCheckboxProps {
  checked: boolean;
  disabled: boolean;
  model: keyof typeof CHECKBOX_CSS_PROPERTY_MAP;
}

const StyledCheckbox = styled.div<StyledCheckboxProps>`
  align-items: center;
  background: ${({ checked, model }): string => {
    if (checked) {
      return CHECKBOX_CSS_PROPERTY_MAP[model].checked.background;
    }

    return CHECKBOX_CSS_PROPERTY_MAP[model].unchecked.background;
  }};
  border: ${({ checked, model }): string => {
    if (checked) {
      return CHECKBOX_CSS_PROPERTY_MAP[model].checked.border;
    }
    return CHECKBOX_CSS_PROPERTY_MAP[model].unchecked.border;
  }};
  border-radius: 3px;
  box-sizing: border-box;
  display: inline-block;
  height: 20px;
  justify-content: center;
  transition: all 150ms;
  width: 20px;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${CheckmarkSVG} {
    visibility: ${({ checked }): string => {
      return checked ? 'visible' : 'hidden';
    }};
  }
`;

StyledCheckbox.displayName = 'StyledCheckbox';

export default StyledCheckbox;
