/* eslint-disable sort-keys */

import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationShareKeySchema: NodeSchemaProperties = {
  shareKeyScreen: {
    title: '#15 Share Key Screen',
    type: 'object',
    properties: {
      'shareKeyButton:enable': {
        description: '[shareKeyScreen.shareKeyButton]',
        title: '#1 Share Key Button',
        type: 'boolean',
      },
      shareKeyButton: {
        type: 'string',
      },
      tutorialScreen: {
        title: '#2 Tutorial Screen',
        type: 'object',
        properties: {
          'header:enable': {
            description: '[shareKeyScreen.tutorialScreen.header]',
            title: '#2.1 Header',
            type: 'boolean',
          },
          header: {
            type: 'string',
          },
          'title:enable': {
            description: '[shareKeyScreen.tutorialScreen.title]',
            title: '#2.2 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          description: {
            title: '#2.3 Description',
            type: 'object',
            properties: {
              'text:enable': {
                description: '[shareKeyScreen.tutorialScreen.description.text]',
                title: '#2.3.1 Text',
                type: 'boolean',
              },
              text: {
                type: 'string',
              },
              'type:enable': {
                description: '[shareKeyScreen.tutorialScreen.description.type]',
                title: '#2.3.2 Type',
                type: 'boolean',
              },
              type: {
                enum: ['PLAIN_TEXT', 'HTML'],
                type: 'string',
              },
              'useDefaultStyles:enable': {
                description: '[shareKeyScreen.tutorialScreen.description.useDefaultStyles]',
                title: '#2.3.3 Use Default Styles',
                type: 'boolean',
              },
              useDefaultStyles: {
                type: undefined,
                enum: [true, false],
              },
            },
          },

          'nextButton:enable': {
            description: '[shareKeyScreen.tutorialScreen.nextButton]',
            title: '#2.4 Next Button',
            type: 'boolean',
          },
          nextButton: {
            type: 'string',
          },
        },
      },
      guestListScreen: {
        title: '#3 Guest List Screen',
        type: 'object',
        properties: {
          'header:enable': {
            description: '[shareKeyScreen.guestListScreen.header]',
            title: '#3.1 Header',
            type: 'boolean',
          },
          header: {
            type: 'string',
          },
          'title:enable': {
            description: '[shareKeyScreen.guestListScreen.title]',
            title: '#3.2 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'shareKeyButton:enable': {
            description: '[shareKeyScreen.guestListScreen.shareKeyButton]',
            title: '#3.3 Share Key Button',
            type: 'boolean',
          },
          shareKeyButton: {
            type: 'string',
          },
        },
      },
      shareKeyScreen: {
        title: '#4 Share Key Screen',
        type: 'object',
        properties: {
          'header:enable': {
            description: '[shareKeyScreen.shareKeyScreen.header]',
            title: '#4.1 Header',
            type: 'boolean',
          },
          header: {
            type: 'string',
          },
          'title:enable': {
            description: '[shareKeyScreen.shareKeyScreen.title]',
            title: '#4.2 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'shareKeyButton:enable': {
            description: '[shareKeyScreen.shareKeyScreen.shareKeyButton]',
            title: '#4.3 Share Key Button',
            type: 'boolean',
          },
          shareKeyButton: {
            type: 'string',
          },
          formPlaceholders: {
            title: '#4.4 Form Placeholders',
            type: 'object',
            properties: {
              'firstName:enable': {
                description: '[shareKeyScreen.shareKeyScreen.formPlaceholders.firstName]',
                title: '#4.4.1 First Name',
                type: 'boolean',
              },
              firstName: {
                type: 'string',
              },
              'lastName:enable': {
                description: '[shareKeyScreen.shareKeyScreen.formPlaceholders.lastName]',
                title: '#4.4.2 Last Name',
                type: 'boolean',
              },
              lastName: {
                type: 'string',
              },
              'email:enable': {
                description: '[shareKeyScreen.shareKeyScreen.formPlaceholders.email]',
                title: '#4.4.3 Email',
                type: 'boolean',
              },
              email: {
                type: 'string',
              },
              'phoneNumber:enable': {
                description: '[shareKeyScreen.shareKeyScreen.formPlaceholders.phoneNumber]',
                title: '#4.4.4 Phone Number',
                type: 'boolean',
              },
              phoneNumber: {
                type: 'string',
              },
            },
          },
          successPopup: {
            title: '#4.5 Success Popup',
            type: 'object',
            properties: {
              'title:enable': {
                description: '[shareKeyScreen.shareKeyScreen.successPopup.title]',
                title: '#4.5.1 Title',
                type: 'boolean',
              },
              title: {
                type: 'string',
              },
              'description:enable': {
                description: '[shareKeyScreen.shareKeyScreen.successPopup.description]',
                title: '#4.5.2 Description',
                type: 'boolean',
              },
              description: {
                type: 'string',
              },
              'okButton:enable': {
                description: '[shareKeyScreen.shareKeyScreen.successPopup.okButton]',
                title: '#4.5.3 Ok Button',
                type: 'boolean',
              },
              okButton: {
                type: 'string',
              },
            },
          },
          errorPopup: {
            title: '#4.6 Error Popup',
            type: 'object',
            properties: {
              'title:enable': {
                description: '[shareKeyScreen.shareKeyScreen.errorPopup.title]',
                title: '#4.6.1 Title',
                type: 'boolean',
              },
              title: {
                type: 'string',
              },
              'description:enable': {
                description: '[shareKeyScreen.shareKeyScreen.errorPopup.description]',
                title: '#4.6.2 Description',
                type: 'boolean',
              },
              description: {
                type: 'string',
              },
              'okButton:enable': {
                description: '[shareKeyScreen.shareKeyScreen.errorPopup.okButton]',
                title: '#4.6.3 Ok Button',
                type: 'boolean',
              },
              okButton: {
                type: 'string',
              },
            },
          },
        },
      },
    },
  },
};

export const mobileV3LocalizationShareKeyUiSchema: NodeUiSchemaProperties = {
  shareKeyScreen: {
    shareKeyButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    tutorialScreen: {
      header: {
        'ui:label': false,
        'ui:readonly': true,
      },
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        text: {
          'ui:label': false,
          'ui:readonly': true,
        },
        type: {
          'ui:label': false,
          'ui:readonly': true,
        },
        useDefaultStyles: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'select',
        },
      },
      nextButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    guestListScreen: {
      header: {
        'ui:label': false,
        'ui:readonly': true,
      },
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      shareKeyButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    shareKeyScreen: {
      header: {
        'ui:label': false,
        'ui:readonly': true,
      },
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      shareKeyButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
      formPlaceholders: {
        firstName: {
          'ui:label': false,
          'ui:readonly': true,
        },
        lastName: {
          'ui:label': false,
          'ui:readonly': true,
        },
        email: {
          'ui:label': false,
          'ui:readonly': true,
        },
        phoneNumber: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      successPopup: {
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
        okButton: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      errorPopup: {
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
        okButton: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
    },
  },
};
