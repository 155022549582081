import React from 'react';

export const ActionBarConfirmationModalReservationIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="#000"
        fillRule="evenodd"
        d="M18.207 9.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414l1.293 1.293 3.293-3.293a1 1 0 011.414 0z"
        clipRule="evenodd"
      ></path>
      <rect width="22" height="16" x="1" y="4" stroke="#000" strokeWidth="2" rx="3"></rect>
      <rect width="1.5" height="4" x="5" y="12.5" fill="#000" rx="0.75"></rect>
      <rect width="1.5" height="4" x="5" y="7.5" fill="#000" rx="0.75"></rect>
    </svg>
  );
};
