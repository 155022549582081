import SelectOption from '../@types/react/SelectOption';
import { ServiceType } from '../__generated__/graphql';
import { serviceTypeLabels } from '../enums/ServiceType.enum';

export default function useLoadServiceTypeOptions(): (input: string) => Promise<SelectOption[]> {
  return async (input: string): Promise<SelectOption[]> => {
    let serviceTypes = Object.values(ServiceType).map((serviceType: string) => {
      return {
        label: serviceTypeLabels[serviceType] ?? serviceType,
        value: serviceType,
      };
    });

    if (input) {
      serviceTypes = serviceTypes.filter(({ label: serviceLabel }) =>
        serviceLabel.toUpperCase().includes(input.toUpperCase())
      );
    }

    return serviceTypes;
  };
}
