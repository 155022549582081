/* eslint-disable no-param-reassign */
import { IChangeEvent } from '@rjsf/core';
import _ from 'lodash';
import enableField from './enableField';
import iterateInJson from './iterateInJson';
import jsonGetValueFromKey from './jsonGetValueFromKey';
import jsonSchemaConstantKeys from '../constants/jsonSchemaConstantKeys';
import makeFieldAsRequired from './makeFieldAsRequired';

export default function onChangeJsonField(
  data: {
    jsonData: Record<string, unknown>;
    schema: Record<string, unknown>;
    uiSchema: Record<string, unknown>;
    previousJsonData: Record<string, unknown>;
  },
  functions: {
    setSchema: (value: React.SetStateAction<Record<string, unknown>>) => void;
    updateJsonData: (updatedJsonData: Record<string, unknown>) => void;
    updateUiSchema: (updatedUiSchema: Record<string, unknown>) => void;
  },
  nodeKey: string,
  event?: IChangeEvent
): void {
  const initialization = !event;

  const { uiSchema, previousJsonData } = data;
  const jsonData = _.cloneDeep(data.jsonData);
  const schema = _.cloneDeep(data.schema);

  let { formData: newData } = event ?? { formData: jsonData };

  const schemaToIterate = (schema[jsonSchemaConstantKeys.schemaProperties] as Record<
    string,
    unknown
  >)[nodeKey] as Record<string, unknown>;
  const uiSchemaToIterate = _.cloneDeep(uiSchema[nodeKey] as Record<string, unknown>);

  iterateInJson(newData, (_data, parentKey, key): boolean => {
    let mainKey = `${parentKey}.${key}`;
    const indexEnable = mainKey.indexOf(jsonSchemaConstantKeys.schemaEnable);
    if (indexEnable > -1) {
      const fieldItIsEnabled = _data[key] as boolean;
      mainKey = mainKey.substring(0, indexEnable);

      // Enable the field to be filled and make it as required
      const newKey = key.substring(0, key.indexOf(jsonSchemaConstantKeys.schemaEnable));
      enableField(uiSchemaToIterate, mainKey, fieldItIsEnabled);

      makeFieldAsRequired(schemaToIterate, parentKey, newKey, fieldItIsEnabled);

      if (!initialization) {
        const previousValue = jsonGetValueFromKey(
          jsonData[nodeKey] as Record<string, unknown>,
          `${mainKey}${jsonSchemaConstantKeys.schemaEnable}`
        );

        if (previousValue && !fieldItIsEnabled) {
          // Set the default value in the field
          const defaultValue = jsonGetValueFromKey(
            previousJsonData[nodeKey] as Record<string, unknown>,
            mainKey
          );
          if (defaultValue === null) {
            delete _data[newKey];
          } else {
            _data[newKey] = defaultValue;
          }
        } else if (fieldItIsEnabled && _data[newKey] === undefined) {
          // Set the string field as "" instead of undefined
          const fieldSchema = (schemaToIterate[jsonSchemaConstantKeys.schemaProperties] as Record<
            string,
            unknown
          >)[newKey] as Record<string, unknown>;

          if (fieldSchema) {
            const fieldType = fieldSchema[jsonSchemaConstantKeys.schemaType];

            if (fieldType === 'string') {
              _data[newKey] = '';
            }
          }
        }
      }
    }

    return false;
  });

  const updatedJsonData = _.cloneDeep(jsonData);
  const updatedUiSchema = _.cloneDeep(uiSchema);
  const updatedSchema = _.cloneDeep(schema);

  if (nodeKey) {
    updatedUiSchema[nodeKey] = uiSchemaToIterate;
    updatedSchema[nodeKey] = schemaToIterate;

    const newDataCopy = _.cloneDeep(newData);
    newData = updatedJsonData;
    updatedJsonData[nodeKey] = newDataCopy;
  }

  const { updateUiSchema, setSchema, updateJsonData } = functions;

  updateUiSchema(updatedUiSchema);
  setSchema(updatedSchema);
  updateJsonData(updatedJsonData);
}
