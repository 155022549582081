import { DashboardPreferencesAction } from '../../actions/dashboardPreferences.actions';
import DashboardPreferencesActionEnum from '../../actions/@types/dashboardPreferences/DashboardPreferencesAction.enum';
import DashboardPreferencesReduxState from '../@types/DashboardPreferencesReduxState';
import clearPreferencesAction from './functions/clearPreferencesAction';
import setPreferencesAction from './functions/setPreferencesAction';

const initialState: DashboardPreferencesReduxState = {};

export default function dashboardPreferencesReducer(
  state: DashboardPreferencesReduxState = initialState,
  action: DashboardPreferencesAction
): DashboardPreferencesReduxState {
  switch (action.type) {
    case DashboardPreferencesActionEnum.SET_PREFERENCES: {
      return setPreferencesAction(state, action.payload);
    }

    case DashboardPreferencesActionEnum.CLEAR_PREFERENCES: {
      return clearPreferencesAction();
    }

    default: {
      return state;
    }
  }
}
