import { MdAccessTime, MdCheck, MdRefresh } from 'react-icons/md';
import { IconType } from 'react-icons/lib';

export default function determineResendInfoButtonIcon(
  success: boolean,
  loading: boolean
): IconType {
  if (loading) {
    return MdAccessTime;
  }

  if (success) {
    return MdCheck;
  }

  return MdRefresh;
}
