import DataTablePage from '../../templates/DataTablePage/DataTablePage';
import React from 'react';
import useUsersTableData from '../../hooks/useUsersTableData';

const args = {};

function UsersPage(): React.ReactElement {
  const tableData = useUsersTableData(args);
  return <DataTablePage bannerText={'Users'} {...tableData} />;
}

export default React.memo(UsersPage);
