import { Header } from '../../../components/DataTable/@types/DataTableProps';
import SortType from '../../../components/DataTable/@types/SortType.enum';

const UNIT_TYPES_TABLE_HEADERS: Header[] = [
  { displayValue: 'Type', isSortable: true, sortType: SortType.SortOrder, value: 'type' },
  {
    displayValue: 'Number of guests',
    isSortable: true,
    sortType: SortType.SortOrder,
    value: 'numberOfGuests',
  },
  { displayValue: 'Image URL', isSortable: false },
];

export default UNIT_TYPES_TABLE_HEADERS;
