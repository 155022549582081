import { colorsName } from '../../../../util/css/colorNameToColor';
import isValidColorString from '../../../../util/css/isValidColorString';

export default function isValidColor(color: string): boolean {
  if (isValidColorString(color)) {
    return true;
  }

  return (
    Object.keys(colorsName).find((_color) => _color.toLowerCase() === color.toLowerCase()) !==
    undefined
  );
}
