import { gql } from '../__generated__/gql';

const RESEND_CONFIRMATION_INFO = gql(`
  mutation resendConfirmationInfo($accessGrantId: String!) {
    resendConfirmationInfo(accessGrantId: $accessGrantId) {
      success
    }
  }
`);

export default RESEND_CONFIRMATION_INFO;
