import * as Sentry from '@sentry/react';

import { ApolloError, DocumentNode } from '@apollo/client';
import { OperationDefinitionNode, OperationTypeNode } from 'graphql';

type GraphQLError = { reqId: string; message: string; code: string };

type ApolloGraphQLErrorType = Omit<ApolloError, 'graphQLErrors'> & {
  graphQLErrors: Array<GraphQLError>;
};

export type ApolloErrorType =
  | ApolloError
  | (Omit<ApolloError, 'graphQLErrors'> & {
      graphQLErrors: Array<GraphQLError>;
    });

function isModifiedGraphQLError(
  error:
    | ApolloError
    | (Omit<ApolloError, 'graphQLErrors'> & {
        graphQLErrors: Array<GraphQLError>;
      })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | any
): error is Omit<ApolloError, 'graphQLErrors'> & {
  graphQLErrors: Array<GraphQLError>;
} {
  if (error?.graphQLErrors?.length) {
    return Boolean((error.graphQLErrors[0] as GraphQLError).reqId);
  }

  return false;
}

interface FlashApolloErrorProps {
  messageOnInternalServerError?: string;
  messageTimeout?: number;
}
export function flashApolloError(
  error: ApolloErrorType,
  flashApolloErrorProps?: FlashApolloErrorProps,
  operation?: DocumentNode
): void {
  const { messageOnInternalServerError, messageTimeout } = flashApolloErrorProps ?? {};

  const ERROR_MESSAGE = 'Internal Server Error';

  let titleMessage: string | undefined;
  let subtextMessage: string | undefined;

  if (isModifiedGraphQLError(error)) {
    const isServerError = Boolean(
      (error.graphQLErrors as Array<GraphQLError>).find(
        (e: GraphQLError) => e.code === 'INTERNAL_SERVER_ERROR'
      )
    );

    titleMessage = isServerError ? messageOnInternalServerError ?? ERROR_MESSAGE : error.message;
    subtextMessage = isServerError ? error.message : '';

    window.flash({
      message: titleMessage,
      subText: subtextMessage,
      timeout: messageTimeout,
      type: 'error',
    });
  } else {
    const isNetworkError = Boolean(error.networkError);

    titleMessage = isNetworkError ? messageOnInternalServerError ?? 'Network Error' : error.message;

    window.flash({
      message: titleMessage,
      timeout: messageTimeout,
      type: 'error',
      error,
    });
  }

  let request: Array<{ operation: OperationTypeNode; request?: string }> = [];
  try {
    if (operation && operation.definitions.length > 0) {
      request = (operation.definitions as [OperationDefinitionNode]).map(
        (_operation: OperationDefinitionNode) => {
          return {
            operation: _operation.operation,
            request: _operation.name?.value,
          };
        }
      );
    }
    // eslint-disable-next-line no-empty
  } catch {}

  const sentryError = {
    extraInfo: error.extraInfo,
    graphQLErrors: ((error.graphQLErrors as Array<GraphQLError>) ?? []).map((_graphQLError) => {
      return `{code: '${_graphQLError.code}', message: '${_graphQLError.message}', reqId: '${_graphQLError.reqId}'}`;
    }),
    request: request.map((_request) => {
      return `{Operation: '${_request.operation}', Request: '${_request.request}'}`;
    }),
    stack: error.stack,
    subtextMessage: subtextMessage ?? '',
    titleMessage,
    type: 'Apollo Error',
  };

  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(sentryError), null, 2);
  }

  Sentry.captureException(sentryError);
}

export function getErrorMessage(error: unknown): string {
  if (isModifiedGraphQLError(error)) {
    const gqlError = error as ApolloGraphQLErrorType;

    if (gqlError?.message) {
      return gqlError?.message;
    }

    if (gqlError?.graphQLErrors) {
      const graphQLErrorsMessages = gqlError?.graphQLErrors?.map(
        (_gqlError) => _gqlError?.message || _gqlError?.code
      );

      if (graphQLErrorsMessages) {
        return `Failed with error: ${graphQLErrorsMessages.join('\n')}`;
      }
    }
  }

  return 'Internal Server Error';
}
