import { Header } from '../../../components/DataTable/@types/DataTableProps';
import SortType from '../../../components/DataTable/@types/SortType.enum';

const CREDENTIALS_TABLE_HEADERS: Header[] = [
  { displayValue: 'Guest', isSortable: false, value: 'user.name' },
  { displayValue: 'Guest type', isSortable: false },
  { displayValue: 'Type', isSortable: false },
  { displayValue: 'Status', isSortable: false, value: 'status' },
  {
    displayValue: 'Created at',
    isSortable: true,
    sortType: SortType.SortOrder,
    value: 'createdAt',
  },
  {
    displayValue: 'Expires at',
    isSortable: true,
    sortType: SortType.SortOrderInput,
    value: 'expiresAt',
  },
  { displayValue: 'Revoked at', isSortable: false, value: 'cancelledAt' },
];

export default CREDENTIALS_TABLE_HEADERS;
