import React, { useMemo } from 'react';

export const ActionBarRePoll: React.FC<{
  color?: string;
}> = ({ color }) => {
  const fillColor = useMemo(() => color || '#fff', [color]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.42509 4.22244C9.15344 3.2223 11.1621 2.8191 13.1418 3.07496C15.1214 3.33082 16.9621 4.23154 18.3804 5.63838C19.7987 7.04522 20.7159 8.88013 20.9906 10.8605C21.0666 11.4084 20.6848 11.9143 20.1378 11.9905C19.5907 12.0666 19.0857 11.6842 19.0096 11.1362C18.7953 9.59112 18.0797 8.15949 16.9731 7.06185C15.8665 5.9642 14.4304 5.26144 12.8858 5.06181C11.3413 4.86218 9.77405 5.17677 8.42556 5.9571C7.53828 6.47055 6.77809 7.16689 6.19127 7.99335H8.00012C8.55241 7.99335 9.00012 8.44181 9.00012 8.99501C9.00012 9.54821 8.55241 9.99667 8.00012 9.99667H4.52249C4.50734 9.99702 4.49216 9.99702 4.47695 9.99667H4.00012C3.44784 9.99667 3.00012 9.54821 3.00012 8.99501V4.98836C3.00012 4.43516 3.44784 3.9867 4.00012 3.9867C4.55241 3.9867 5.00012 4.43516 5.00012 4.98836V6.26334C5.67868 5.45161 6.49773 4.75908 7.42509 4.22244ZM3.86247 12.0095C4.4095 11.9334 4.91458 12.3158 4.9906 12.8638C5.20497 14.4089 5.92056 15.8405 7.02713 16.9382C8.13371 18.0358 9.56988 18.7386 11.1144 18.9382C12.659 19.1378 14.2262 18.8232 15.5747 18.0429C16.462 17.5295 17.2222 16.8331 17.809 16.0066H16.0001C15.4478 16.0066 15.0001 15.5582 15.0001 15.005C15.0001 14.4518 15.4478 14.0033 16.0001 14.0033H19.4778C19.4929 14.003 19.5081 14.003 19.5233 14.0033H20.0001C20.5524 14.0033 21.0001 14.4518 21.0001 15.005V19.0116C21.0001 19.5648 20.5524 20.0133 20.0001 20.0133C19.4478 20.0133 19.0001 19.5648 19.0001 19.0116V17.7367C18.3216 18.5484 17.5025 19.2409 16.5752 19.7776C14.8468 20.7777 12.8381 21.1809 10.8585 20.925C8.87885 20.6692 7.03812 19.7685 5.61984 18.3616C4.20155 16.9548 3.28439 15.1199 3.00964 13.1395C2.93362 12.5916 3.31545 12.0857 3.86247 12.0095Z"
        fill={fillColor}
      />
    </svg>
  );
};
