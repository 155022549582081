import ClearActiveCallAction from './@types/ClearActiveCallAction';
import IncomingCallRedux from '../reducers/@types/IncomingCallRedux';
import RemoteAssistanceActionEnum from '../../enums/RemoteAssistanceAction.enum';
import RemoveIncomingCallAction from './@types/RemoveIncomingCallAction';
import SetActiveCallAction from './@types/SetActiveCallAction';
import SetActiveRoomAction from './@types/SetActiveRoomAction';
import SetIncomingCallAction from './@types/SetIncomingCallAction';
import SetJoinerChallengeAction from './@types/SetJoinerChallengeAction';
import SetVideoTokenAction from './@types/SetVideoTokenAction';
import Video from 'twilio-video';

export const removeIncomingCall = (callRecordIdToBeRemoved: string): RemoveIncomingCallAction => ({
  payload: {
    callRecordIdToBeRemoved,
  },
  type: RemoteAssistanceActionEnum.REMOVE_INCOMING_CALL,
});

export const clearActiveCall = (): ClearActiveCallAction => ({
  type: RemoteAssistanceActionEnum.CLEAR_ACTIVE_CALL,
});

export const setIncomingCall = (incomingCall: IncomingCallRedux): SetIncomingCallAction => ({
  payload: {
    incomingCall,
  },
  type: RemoteAssistanceActionEnum.SET_INCOMING_CALL,
});

export const setActiveCall = (activeCall: IncomingCallRedux): SetActiveCallAction => ({
  payload: {
    activeCall,
  },
  type: RemoteAssistanceActionEnum.SET_ACTIVE_CALL,
});

export const setVideoToken = (videoToken: string): SetVideoTokenAction => ({
  payload: {
    videoToken,
  },
  type: RemoteAssistanceActionEnum.SET_VIDEO_TOKEN,
});

export const setJoinerChallenge = (joinerChallenge?: string): SetJoinerChallengeAction => ({
  payload: {
    joinerChallenge,
  },
  type: RemoteAssistanceActionEnum.SET_JOINER_CHALLENGE,
});

export const setActiveRoom = (activeRoom: Video.Room | null): SetActiveRoomAction => ({
  payload: {
    activeRoom,
  },
  type: RemoteAssistanceActionEnum.SET_ACTIVE_ROOM,
});
