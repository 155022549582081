import InputGroupProps from '../../InputGroup/@types/InputGroupProps';
import { UsersImportFromEnum } from '../enums/UsersImportFrom.enum';

const USERS_IMPORT_SECTION_INPUTS: InputGroupProps[] = [
  {
    label: 'Import from',
    name: 'importFrom',
    options: Object.values(UsersImportFromEnum),
    required: true,
    type: 'select',
  },
];

export default USERS_IMPORT_SECTION_INPUTS;
