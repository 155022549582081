import {
  Location,
  Maybe,
  Query,
  QueryResourceArgs,
  Resource,
  UnitType,
} from '../__generated__/graphql';

import GET_RESOURCE from '../graphql/getResource';
import QueryOneHookData from './@types/QueryOneHookData';
import { flashApolloError } from '../util/errorUtils';
import { useLazyQuery } from '@apollo/client';

type GetResourceData = Pick<Query, 'resource'>;

export default function useGetResourceById(): QueryOneHookData<
  Pick<Resource, 'id' | 'imageUrl' | 'name'> & {
    location: Pick<Location, 'id' | 'name'>;
    unitType?: Maybe<Pick<UnitType, 'id' | 'type'>>;
  }
> {
  const [getResource, { called, data, error, loading }] = useLazyQuery<
    GetResourceData,
    QueryResourceArgs
  >(GET_RESOURCE, {
    fetchPolicy: 'network-only',
    onCompleted: () => {},
    onError: flashApolloError,
  });

  return {
    called,
    data: data?.resource ?? undefined,
    error,
    loading,
    query: (id: string): void => {
      getResource({
        variables: {
          where: { id },
        },
      });
    },
  };
}
