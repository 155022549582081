import { gql } from '../__generated__/gql';

const GET_INFO_ITEM = gql(`
  query getInfo($where: InfoItemWhereUniqueInput!) {
    info(where: $where) {
      ...InfoItemFields
    }
  }
`);

export default GET_INFO_ITEM;
