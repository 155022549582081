import _ from 'lodash';
import iterateInJson from './iterateInJson';

export default function removeNullValues(
  jsonData: Record<string, unknown>
): Record<string, unknown> {
  const newJsonData = _.cloneDeep(jsonData);

  iterateInJson(newJsonData, (_data, _parentKey, key): boolean => {
    const value = _data[key];
    if (value === null) {
      // eslint-disable-next-line no-param-reassign
      delete _data[key];
    }
    return false;
  });

  return newJsonData;
}
