import { Kiosk } from '../../../__generated__/graphql';
import MaintenanceTimeWindow from '../@types/MaintenanceTimeWindow';
import { tz } from 'moment';

type KioskData = Kiosk;
type MaintenanceKioskInterface = Pick<KioskData, 'maintenanceEndsAt' | 'maintenanceStartsAt'>;

const getMidnightDate = (dateTime?: Date): Date | undefined => {
  if (dateTime) {
    dateTime.setHours(0);
    dateTime.setMinutes(0);
    dateTime.setSeconds(0);
    dateTime.setMilliseconds(0);

    return dateTime;
  }

  return undefined;
};

interface GetDateAndTimeFromDateTimeInterface {
  date?: Date;
  time?: string;
}

const getDateAndTimeFromDateTime = (
  dateTime: Date,
  timezone: string
): GetDateAndTimeFromDateTimeInterface => {
  const date = new Date(tz(dateTime, timezone).format('MM/DD/YYYY HH:mm'));

  const hours = `00${date.getHours()}`;
  const minutes = `00${date.getMinutes()}`;

  return {
    date,
    time: `${hours.substring(hours.length - 2)}:${minutes.substring(minutes.length - 2)}`,
  };
};

export const kioskMaintenanceToMaintenanceTimeWindow = (
  kioskMaintenance: MaintenanceKioskInterface,
  timezone: string
): MaintenanceTimeWindow => {
  if (!kioskMaintenance.maintenanceEndsAt || !kioskMaintenance.maintenanceStartsAt) {
    return {};
  }

  let maintenanceEndDate: Date | undefined;
  let maintenanceEndTime: string | undefined;
  if (kioskMaintenance.maintenanceEndsAt) {
    const maintenanceEndsDate = new Date(kioskMaintenance.maintenanceEndsAt);

    ({ date: maintenanceEndDate, time: maintenanceEndTime } = getDateAndTimeFromDateTime(
      maintenanceEndsDate,
      timezone
    ));
  }

  let maintenanceStartDate: Date | undefined;
  let maintenanceStartTime: string | undefined;
  if (kioskMaintenance.maintenanceStartsAt) {
    const maintenanceStartsDate = new Date(kioskMaintenance.maintenanceStartsAt);

    ({ date: maintenanceStartDate, time: maintenanceStartTime } = getDateAndTimeFromDateTime(
      maintenanceStartsDate,
      timezone
    ));
  }

  const maintenanceTimeWindow: MaintenanceTimeWindow = {
    maintenanceEndDate,
    maintenanceEndTime,
    maintenanceStartDate,
    maintenanceStartTime,
  };

  return maintenanceTimeWindow;
};

export const maintenanceTimeWindowToKioskMaintenance = (
  maintenanceTimeWindow: Pick<MaintenanceTimeWindow, 'maintenanceEndDate' | 'maintenanceStartDate'>
): MaintenanceKioskInterface => {
  if (maintenanceTimeWindow) {
    return {
      maintenanceEndsAt: getMidnightDate(maintenanceTimeWindow.maintenanceEndDate),
      maintenanceStartsAt: getMidnightDate(maintenanceTimeWindow.maintenanceStartDate),
    };
  }
  return {};
};
