import { AnyAction } from 'redux';
import AuthActionEnum from '../../enums/AuthAction.enum';
import MeActionEnum from '../../enums/MeAction.enum';

interface AuthReduxState {
  accessToken: string | null;
  isLoading: boolean;
  refreshToken: string | null;
  refreshTokenHandlerTimeout: number | undefined;
}

function createInitialState(): AuthReduxState {
  const ACCESS_TOKEN = localStorage.getItem('ACCESS_TOKEN');
  const REFRESH_TOKEN = localStorage.getItem('REFRESH_TOKEN');

  const initialState: AuthReduxState = {
    accessToken: ACCESS_TOKEN ?? null,
    isLoading: false,
    refreshToken: REFRESH_TOKEN ?? null,
    refreshTokenHandlerTimeout: undefined,
  };

  return initialState;
}

export default function authReducer(
  state: AuthReduxState = createInitialState(),
  action: AnyAction
): AuthReduxState {
  switch (action.type) {
    case AuthActionEnum.CLEAR_TOKENS: {
      if (state.refreshTokenHandlerTimeout) {
        clearTimeout(state.refreshTokenHandlerTimeout);
      }

      return {
        accessToken: null,
        isLoading: false,
        refreshToken: null,
        refreshTokenHandlerTimeout: undefined,
      };
    }

    case AuthActionEnum.LOAD: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case MeActionEnum.LOG_OUT: {
      if (state.refreshTokenHandlerTimeout) {
        clearTimeout(state.refreshTokenHandlerTimeout);
      }

      return {
        accessToken: null,
        isLoading: false,
        refreshToken: null,
        refreshTokenHandlerTimeout: undefined,
      };
    }

    case AuthActionEnum.RECEIVE_TOKENS: {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        refreshTokenHandlerTimeout: undefined,
      };
    }

    case AuthActionEnum.CLEAR_REFRESH_TOKEN_HANDLER_TIMEOUT: {
      if (state.refreshTokenHandlerTimeout) {
        clearTimeout(state.refreshTokenHandlerTimeout);
      }

      return {
        ...state,
        refreshTokenHandlerTimeout: undefined,
      };
    }

    case AuthActionEnum.SET_REFRESH_TOKEN_HANDLER_TIMEOUT: {
      return {
        ...state,
        refreshTokenHandlerTimeout: action.payload.refreshTokenHandlerTimeout,
      };
    }

    default: {
      return state;
    }
  }
}
