import InputGroupProps from '../../../../InputGroup/@types/InputGroupProps';

const RESOURCE_GROUP_INFO_SECTION_INPUTS: InputGroupProps[] = [
  {
    label: 'Name',
    name: 'name',
    required: true,
    type: 'text',
  },
  {
    columnSpan: 2,
    label: 'Default',
    name: 'isDefault',
    required: true,
    type: 'checkbox',
  },
];

export default RESOURCE_GROUP_INFO_SECTION_INPUTS;
