import { gql } from '../__generated__/gql';

const ISSUE_KEY_FOR_KIOSK = gql(`
  mutation issueKeyForKiosk($kioskId: String!) {
    issueKeyForKiosk(kioskId: $kioskId) {
      success
    }
  }
`);

export default ISSUE_KEY_FOR_KIOSK;
