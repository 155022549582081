import { Query, QueryResourcesArgs, ResourceWhereInput } from '../../__generated__/graphql';
import { useCallback, useEffect, useRef, useState } from 'react';

import { EntityEnum } from '../../@types/client/DashboardUserRolePermissions';
import GET_RESOURCE_COUNT from '../../graphql/aggregateResource';
import GET_RESOURCE_TABLE_DATA from '../../graphql/getResourceTableData';
import { LocationPageInfo } from '../../pages/Location/@types/GetLocationPageInfo';
import OrderByParams from '../../@types/client/OrderByParams';
import RESOURCES_TABLE_HEADERS from './constants/ResourcesTableHeaders';
import RESOURCES_TABLE_SEARCH_FIELDS from './constants/ResourcesTableSearchFields';
import { RootState } from '../../redux/reducers';
import SearchField from '../../@types/SearchField';
import TableHookData from '../@types/TableHookData';
import TableHookProps from '../@types/TableHookProps';
import generateMembershipQuery from '../../util/api/generateMembershipQuery';
import generateResourcesTableData from './functions/generateResourcesTableData';
import parseOrderByDataTableParams from '../../components/DataTable/util/parseOrderByDataTableParams';
import parseResourceQueryParameters from './util/parseResourceQueryParameters';
import useGetMany from '../useGetMany';
import useRemoveResource from '../useRemoveResource';
import useResourceFilters from './hooks/useResourceFilters';
import { useSelector } from 'react-redux';

type ResourcesQueryParams = {
  orderBy?: OrderByParams;
  where: Partial<ResourceWhereInput>;
};

interface ResourcesTableData extends TableHookProps<QueryResourcesArgs, ResourceWhereInput> {
  relatedLocation?: LocationPageInfo;
}

type GetResourceTableData = Pick<Query, 'resources'>;
type AggregateResourceQueryData = Pick<Query, 'aggregateResource'>;

export default function useResourcesTableData({
  dataTableKey = 'resources',
  formName = 'resource',
  headers = RESOURCES_TABLE_HEADERS,
  isSearchable = true,
  isImportEnabled = true,
  permission = EntityEnum.RESOURCE,
  queryOptions,
  searchFields = RESOURCES_TABLE_SEARCH_FIELDS,
  relatedLocation,
}: ResourcesTableData): TableHookData<ResourceWhereInput> {
  const membership = useSelector((state: RootState) => state.me.activeMembership);
  const options = useRef<Partial<QueryResourcesArgs>>(queryOptions ?? {});
  const fieldsFiltered = useSelector((state: RootState) => state.dataTable.filters);
  const sortOptions = useSelector((state: RootState) => state.dataTable.sortOptions);
  const filters = useResourceFilters();

  const [searchParameters, setSearchParameters] = useState<ResourceWhereInput>({});

  const { data, error, loading, offset, query, setOffset } = useGetMany<
    GetResourceTableData,
    QueryResourcesArgs
  >({
    graphqlQuery: GET_RESOURCE_TABLE_DATA,
  });

  const {
    data: countData,
    error: countError,
    loading: countLoading,
    query: countQuery,
  } = useGetMany<AggregateResourceQueryData, QueryResourcesArgs>({
    graphqlQuery: GET_RESOURCE_COUNT,
  });

  const createParams = useCallback((): ResourcesQueryParams => {
    const whereArgs = parseResourceQueryParameters(fieldsFiltered[dataTableKey]);
    const sortParams = parseOrderByDataTableParams(sortOptions[dataTableKey]);

    return {
      ...(sortParams && { orderBy: sortParams }),
      where: {
        ...generateMembershipQuery(membership),
        ...options.current?.where,
        ...searchParameters,
        ...whereArgs,
      },
    };
  }, [membership, sortOptions, fieldsFiltered, dataTableKey, options, searchParameters]);

  const get = useCallback(() => {
    const params = createParams();

    query({
      ...options.current,
      ...(params.orderBy && { orderBy: params.orderBy }),
      skip: options.current?.skip ?? offset,
      take: options.current?.take ?? 10,
      where: params.where,
    });
  }, [createParams, offset, query]);

  const getCount = useCallback(() => {
    const params = createParams();

    countQuery({
      where: params.where,
    });
  }, [countQuery, createParams]);

  useEffect(() => {
    get();
  }, [get]);

  useEffect(() => {
    getCount();
  }, [getCount]);

  return {
    aditionalDataOnOpeningForm: relatedLocation
      ? {
          location: {
            id: relatedLocation.id,
            name: relatedLocation.name,
          },
        }
      : undefined,
    count: countData?.aggregateResource?._count?._all ?? 0,
    data: generateResourcesTableData(data),
    dataTableKey,
    dataType: 'Resource',
    error: error || countError,
    filters,
    formName,
    headers,
    isImportEnabled,
    isLoading: loading || countLoading,
    isSearchable,
    offset,
    permission,
    remove: useRemoveResource,
    search: (searchField: SearchField<ResourceWhereInput>, searchValue: string): void => {
      setSearchParameters({
        [searchField.queryField]: searchField.transform(searchValue),
      });

      setOffset(0);
    },
    searchFields,
    setOffset,
  };
}
