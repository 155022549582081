import { gql } from '../../__generated__/gql';

export const CREATE_LOCATION = gql(`
  mutation CreateLocation($data: LocationCreateInput!) {
    createLocation(data: $data) {
      address
      createdAt
      id
      name
      phoneNumber
      updatedAt
      logoUrl
      imageUrl
    }
  }
`);

export const DELETE_LOCATION_THEME = gql(`
  mutation deleteLocationTheme($componentName: String!, $locationId: String!) {
    deleteLocationTheme(componentName: $componentName, locationId: $locationId) {
      ...BaseSettingAnswerFields
    }
  }
`);

export const DELETE_LOCATION_LOCALIZATION = gql(`
  mutation deleteLocationLocalization(
    $componentName: String!
    $languageId: String!
    $locationId: String!
  ) {
    deleteLocationLocalization(
      componentName: $componentName
      languageId: $languageId
      locationId: $locationId
    ) {
      ...BaseSettingAnswerFields
    }
  }
`);

export const UPSERT_LOCATION_LOCALIZATION = gql(`
  mutation upsertLocationLocalization(
    $componentName: String!
    $languageId: String!
    $locationId: String!
    $localization: String!
  ) {
    upsertLocationLocalization(
      componentName: $componentName
      languageId: $languageId
      locationId: $locationId
      localization: $localization
    ) {
      ...BaseSettingAnswerFields
    }
  }
`);

export const UPSERT_LOCATION_THEME = gql(`
  mutation upsertLocationTheme($componentName: String!, $locationId: String!, $setting: String!) {
    upsertLocationTheme(componentName: $componentName, locationId: $locationId, setting: $setting) {
      ...BaseSettingAnswerFields
    }
  }
`);
