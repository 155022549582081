import { Mutation } from '../__generated__/graphql';
import MutationHookData from './@types/MutationHookData';
import MutationHookParams from './@types/MutationHookParams';
import SIGN_UP_USERS_FROM_THIRD_PARTY_AUTHENTICATION_SERVICE from '../graphql/signUpUsersFromThirdPartyAuthenticationService';
import UsersImportFormInputs from '../components/UsersImportForm/@types/UsersImportFormInputs';
import { closeForm } from '../redux/actions/modals.actions';
import { flashApolloError } from '../util/errorUtils';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';

type UsersImportData = Pick<Mutation, 'signUpUsersFromThirdPartyAuthenticationService'>;

export default function useUpdateResource({
  onCompleted = (): void => {},
  onError = flashApolloError,
}: MutationHookParams<UsersImportData> = {}): MutationHookData<
  UsersImportFormInputs,
  UsersImportData
> {
  const dispatch = useDispatch();

  const [update, { data, error, loading }] = useMutation<UsersImportData, UsersImportFormInputs>(
    SIGN_UP_USERS_FROM_THIRD_PARTY_AUTHENTICATION_SERVICE,
    {
      onCompleted: ({ signUpUsersFromThirdPartyAuthenticationService: user }) => {
        let message = 'created successfully.';
        if (user.length > 1) {
          message = `${user.length} users ${message}`;
        } else if (user.length === 1) {
          message = `${user.length} user ${message}`;
        } else {
          message = 'No users have been created.';
        }

        window.flash({
          message,
        });

        onCompleted({ signUpUsersFromThirdPartyAuthenticationService: user });

        dispatch(closeForm());
      },
      onError,
    }
  );

  return {
    data,
    error,
    loading,
    mutation: (inputs: UsersImportFormInputs): void => {
      update({
        variables: inputs,
      });
    },
  };
}
