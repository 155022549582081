import { Header } from '../../../components/DataTable/@types/DataTableProps';
import SortType from '../../../components/DataTable/@types/SortType.enum';

const SERVICES_TABLE_HEADERS: Header[] = [
  { displayValue: 'Title', isSortable: true, sortType: SortType.SortOrder, value: 'title' },
  { displayValue: 'Description', isSortable: false },
  { displayValue: 'Image URL', isSortable: false },
  { displayValue: 'Type', isSortable: true, sortType: SortType.SortOrder, value: 'type' },
];

export default SERVICES_TABLE_HEADERS;
