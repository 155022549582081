import { Location, Maybe, Query, QueryServiceArgs, Service } from '../__generated__/graphql';

import GET_SERVICE from '../graphql/getService';
import QueryOneHookData from './@types/QueryOneHookData';
import { flashApolloError } from '../util/errorUtils';
import { useLazyQuery } from '@apollo/client';

type GetServiceData = Pick<Query, 'service'>;

export default function useGetServiceById(): QueryOneHookData<
  Pick<
    Service,
    | 'id'
    | 'title'
    | 'description'
    | 'imageUrl'
    | 'type'
    | 'price'
    | 'metadata'
    | 'askForTime'
    | 'displayCommentBox'
  > & {
    location?: Maybe<Pick<Location, 'id' | 'name'>>;
  }
> {
  const [getService, { called, data, error, loading }] = useLazyQuery<
    GetServiceData,
    QueryServiceArgs
  >(GET_SERVICE, {
    fetchPolicy: 'network-only',
    onCompleted: () => {},
    onError: flashApolloError,
  });

  return {
    called,
    data: data?.service ?? undefined,
    error,
    loading,
    query: (id: string): void => {
      getService({
        variables: {
          where: { id },
        },
      });
    },
  };
}
