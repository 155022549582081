import { gql } from '../../__generated__/gql';

export interface TenantT {
  id: string;
  name: string;
}

export const GET_TENANT_THEME = gql(`
  query getTenantTheme($componentName: String!, $tenantId: String!) {
    getTenantTheme(componentName: $componentName, tenantId: $tenantId) {
      ...BaseSettingAnswerFields
    }
  }
`);

export const GET_TENANT_LOCALIZATION = gql(`
  query getTenantLocalization($componentName: String!, $languageId: String!, $tenantId: String!) {
    getTenantLocalization(
      componentName: $componentName
      languageId: $languageId
      tenantId: $tenantId
    ) {
      ...BaseSettingAnswerFields
    }
  }
`);
