/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3ThemeAmenitiesSchema: NodeSchemaProperties = {
  amenities: {
    title: '#6 Amenities',
    type: 'object',
    properties: {
      'backgroundColor:enable': {
        description: '[amenities.backgroundColor]',
        title: '#1 Background Color',
        type: 'boolean',
      },
      backgroundColor: {
        type: 'string',
      },
      'titleTextColor:enable': {
        description: '[amenities.titleTextColor]',
        title: '#2 Title Text Color',
        type: 'boolean',
      },
      titleTextColor: {
        type: 'string',
      },
      selectedTab: {
        title: '#3 Selected Tab',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[amenities.selectedTab.backgroundColor]',
            title: '#3.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'textColor:enable': {
            description: '[amenities.selectedTab.textColor]',
            title: '#3.2 Text Color',
            type: 'boolean',
          },
          textColor: {
            type: 'string',
          },
          'borderColor:enable': {
            description: '[amenities.selectedTab.borderColor]',
            title: '#3.3 Border Color',
            type: 'boolean',
          },
          borderColor: {
            type: 'string',
          },
          'borderWidth:enable': {
            description: '[amenities.selectedTab.borderWidth]',
            title: '#3.4 Border Width',
            type: 'boolean',
          },
          borderWidth: {
            type: 'integer',
          },
          'borderRadius:enable': {
            description: '[amenities.selectedTab.borderRadius]',
            title: '#3.5 Border radius',
            type: 'boolean',
          },
          borderRadius: {
            type: 'integer',
          },
        },
      },
      deselectedTab: {
        title: '#4 Deselected Tab',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[amenities.deselectedTab.backgroundColor]',
            title: '#4.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'textColor:enable': {
            description: '[amenities.deselectedTab.textColor]',
            title: '#4.2 Text Color',
            type: 'boolean',
          },
          textColor: {
            type: 'string',
          },
          'borderColor:enable': {
            description: '[amenities.deselectedTab.borderColor]',
            title: '#4.3 Border Color',
            type: 'boolean',
          },
          borderColor: {
            type: 'string',
          },
          'borderWidth:enable': {
            description: '[amenities.deselectedTab.borderWidth]',
            title: '#4.4 Border Width',
            type: 'boolean',
          },
          borderWidth: {
            type: 'integer',
          },
          'borderRadius:enable': {
            description: '[amenities.deselectedTab.borderRadius]',
            title: '#4.5 Border Radius',
            type: 'boolean',
          },
          borderRadius: {
            type: 'integer',
          },
        },
      },
      card: {
        title: '#5 Card',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[amenities.card.backgroundColor]',
            title: '#5.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'titleTextColor:enable': {
            description: '[amenities.card.titleTextColor]',
            title: '#5.2 Title Text Color',
            type: 'boolean',
          },
          titleTextColor: {
            type: 'string',
          },
          'descriptionTextColor:enable': {
            description: '[amenities.card.descriptionTextColor]',
            title: '#5.3 Description Text Color',
            type: 'boolean',
          },
          descriptionTextColor: {
            type: 'string',
          },
        },
      },
    },
  },
};

export const mobileV3ThemeAmenitiesUiSchema: NodeUiSchemaProperties = {
  amenities: {
    backgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    titleTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    selectedTab: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderWidth: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
      borderRadius: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
    },
    deselectedTab: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderWidth: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
      borderRadius: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
    },
    card: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      titleTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      descriptionTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
  },
};
