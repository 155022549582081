import { Maybe } from '../../__generated__/graphql';
import { ValidateAccessByMembershipProps } from './validateAccessByMembership';

interface ValidateAccessByMembershipsProps
  extends Omit<ValidateAccessByMembershipProps, 'objectMembership'> {
  objectMemberships: { tenantId: string; locationId?: Maybe<string> }[];
}

export const validateAccessByMemberships = ({
  membership,
  objectMemberships,
  validateLocation,
}: ValidateAccessByMembershipsProps): void => {
  if (validateLocation && membership.location?.id) {
    const foundMembership = objectMemberships.findIndex(
      (objectMembership) =>
        objectMembership.tenantId === membership.tenantId &&
        objectMembership.locationId === membership.location?.id
    );
    if (foundMembership === -1) {
      throw new Error('Invalid membership');
    }
  }

  const foundMembership = objectMemberships.findIndex(
    (objectMembership) => objectMembership.tenantId === membership.tenantId
  );
  if (foundMembership === -1) {
    throw new Error('Invalid membership');
  }
};
