const getEmailAndChallengeFromUrl = (search: string): { email?: string; challenge?: string } => {
  const undefinedResult = {
    challenge: undefined,
    email: undefined,
  };

  try {
    // Expected value: ?email=${email}&challenge=${challenge}
    const emailText = 'email';
    const challengeText = 'challenge';

    const findEmailIndex = search.indexOf(emailText);
    const findChallengeIndex = search.indexOf(challengeText);

    if (findEmailIndex === -1 || findChallengeIndex === -1) {
      return undefinedResult;
    }

    const emailIndex = findEmailIndex + emailText.length + '='.length;
    const ampersandIndex = search.indexOf('&');
    const challengeIndex = findChallengeIndex + challengeText.length + '='.length;

    const email = search.substring(emailIndex, ampersandIndex);
    const challenge = search.substring(challengeIndex);

    return { challenge, email };
  } catch {
    return undefinedResult;
  }
};

export default getEmailAndChallengeFromUrl;
