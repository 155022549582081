import React, { useMemo } from 'react';

export const ActionBarDispenseKey: React.FC<{
  color?: string;
}> = ({ color }) => {
  const fillColor = useMemo(() => color || '#fff', [color]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.0703 3.90037H19.7318V2H21.0703C21.5838 2 22 2.42541 22 2.95018C22 3.47496 21.5838 3.90037 21.0703 3.90037Z"
        fill={fillColor}
      />
      <path d="M4.26695 2H19.7318V3.90037H4.26695V2Z" fill={fillColor} />
      <path
        d="M17.8724 3.90037H19.7318V17.5735C19.7318 20.0182 17.7927 22 15.4007 22H8.59802C6.20604 22 4.26695 20.0182 4.26695 17.5735V3.90037H6.12636V17.5735C6.12636 18.9687 7.23296 20.0996 8.59802 20.0996H15.4007C16.7658 20.0996 17.8724 18.9687 17.8724 17.5735V3.90037Z"
        fill={fillColor}
      />
      <path
        d="M2.92971 2H4.26695V3.90037H2.92971C2.41624 3.90037 2 3.47496 2 2.95018C2 2.42541 2.41624 2 2.92971 2Z"
        fill={fillColor}
      />
      <path
        d="M10.3895 13.3442L11.0699 14.0397V7.06353C11.0699 6.53876 11.4862 6.11335 11.9996 6.11335C12.5131 6.11335 12.9293 6.53876 12.9293 7.06353V14.0397L13.6098 13.3442C13.9729 12.9731 14.5615 12.9731 14.9246 13.3442C15.2877 13.7153 15.2877 14.3169 14.9246 14.688L12.657 17.0055C12.294 17.3766 11.7053 17.3766 11.3422 17.0055L9.07466 14.688C8.71159 14.3169 8.71159 13.7153 9.07466 13.3442C9.43773 12.9731 10.0264 12.9731 10.3895 13.3442Z"
        fill={fillColor}
      />
    </svg>
  );
};
