import Theme from '../../theme';
import isValidColorString from '../../util/css/isValidColorString';
import styled from 'styled-components';

export interface LabelProps extends React.HTMLAttributes<HTMLLabelElement> {
  color?: string;
  css?: string;
  fontSize?: string;
  margin?: string;
}

const Label = styled.label<LabelProps>`
  color: ${({ color }): string => {
    if (color && isValidColorString(color)) {
      return color;
    }

    return 'inherit';
  }};
  font-family: 'San Francisco Pro';
  font-size: ${({ fontSize }): string => fontSize ?? Theme.fontStyles.label.size};
  letterspacing: ${Theme.fontStyles.label.letterSpacing || 'initial'};
  line-height: ${Theme.fontStyles.label.lineHeight};
  margin: ${({ margin }): string => margin ?? '0 0 0 0'};
`;

Label.displayName = 'StyledLabel';

export default Label;
