const CallInfoContainerStyle = `
  position: absolute;
  left: 10px;
  opacity: 0.6;
  bottom: 10px;
  z-index: 2;
`;

export const CallInfoInnerContainerStyle = `
  background-color: #333333;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
`;

export const CallInfoTextStyle = `
  color: white;
  fontSize: 20px;

  a, a:visited, a:hover, a:active {
    color: inherit;
  }
`;

export default CallInfoContainerStyle;
