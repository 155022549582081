import CreateUserFormInputs from '../@types/CreateUserFormInputs';
import EditUserFormInputs from '../@types/EditUserFormInputs';
import FormTemplateStep from '../../../templates/Form/@types/FormTemplateStep';
import MembershipSection from '../components/MembershipSection';
import { Mutation } from '../../../__generated__/graphql';
import UserSection from '../components/UserSection';

const USER_FORM_STEPS: FormTemplateStep<
  CreateUserFormInputs | EditUserFormInputs,
  Pick<Mutation, 'createUser' | 'updateUser'>
>[] = [
  {
    canBeAccessed: (): boolean => true,
    component: UserSection,
    name: 'User Information',
  },
  {
    canBeAccessed: (): boolean => false,
    component: MembershipSection,
    name: 'Memberships',
  },
];

export default USER_FORM_STEPS;
