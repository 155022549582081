const LogoContainerStyle = `
  justify-content: center;
  margin: 25px;

  @media only screen and (max-width: 1200px), 
              screen and (max-height: 900px) {
    margin: 20px auto 10px;
    max-width: 40px;
    
    svg {
      width: 40px;
    }
  }
`;

export default LogoContainerStyle;
