/* eslint-disable */
import React, { forwardRef } from 'react';
import dragIcon from '../../../assets/icons/dragIcon.png';
import folderIcon from '../../../assets/icons/folderIcon.png';
import infoIcon from '../../../assets/icons/infoIcon.png';
import styles from './styles.module.css';

const NodeIcon = forwardRef(({ type, indent }, ref) => {
  if (type === 'category') {
    return (
      <div>
        <img
          src={dragIcon}
          className={styles.conciergeInfoIcon}
          draggable={true}
          ref={ref}
        />
        <img src={folderIcon} className={styles.conciergeInfoIcon} style={{ marginInlineStart: indent }} />
      </div>
    );
  }
  if (type === 'info') {
    return (
      <div>
        <img
          src={dragIcon}
          className={styles.conciergeInfoIcon}
          draggable={true}
          ref={ref}
        />
        <img src={infoIcon} className={styles.conciergeInfoIcon} style={{ marginInlineStart: indent }} />
      </div>
    );
  }
  return null;
});

export default NodeIcon;
