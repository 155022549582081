import { Mutation, Query, QueryLocationArgs } from '../../__generated__/graphql';
import React, { useEffect } from 'react';

import FormTemplate from '../../templates/Form';
import GET_LOCATION from '../../graphql/getLocation';
import LOCATION_FORM_STEPS from './constants/locationFormSteps';
import LOCATION_FORM_VALIDATION_SCHEMA from './constants/validationSchema';
import LocationFormInputs from './@types/LocationFormInputs';
import { flashApolloError } from '../../util/errorUtils';
import parseEmailWithDisplayName from '../../util/parsers/parseEmailWithDisplayName';
import pick from 'lodash/pick';
import { useLazyQuery } from '@apollo/client';

type CreateLocationData = Pick<Mutation, 'createLocation'>;
type GetLocationData = Pick<Query, 'location'>;
type UpdateLocationData = Pick<Mutation, 'updateLocation'>;

const LocationForm: React.FC<{ id?: string }> = ({ id }: { id?: string }) => {
  const [getLocation, { data }] = useLazyQuery<GetLocationData, QueryLocationArgs>(GET_LOCATION, {
    fetchPolicy: 'network-only',
    onError: flashApolloError,
  });

  useEffect(() => {
    if (id) {
      getLocation({
        variables: {
          where: { id },
        },
      });
    }
  }, [getLocation, id]);

  const location = data?.location ?? undefined;

  if (id && !location) {
    return <div>Loading...</div>;
  }

  if (id && location) {
    const { email: emailFromEmail, name: emailFromName } = parseEmailWithDisplayName(
      location?.emailFrom ?? ''
    );

    const getInitialValues = (): Partial<LocationFormInputs> => {
      if (location) {
        return {
          ...pick(location, [
            'address',
            'defaultEndTime',
            'defaultStartTime',
            'email',
            'name',
            'phoneNumber',
            'pmsType',
          ]),
          defaultRequirements: {
            existing:
              location.defaultRequirements.map((requirement) => ({
                metadata: requirement.metadata,
                requirementId: requirement.id,
                type: requirement.type,
              })) ?? [],
            values: location.defaultRequirements.map((requirement) => ({
              metadata: requirement.metadata,
              requirementId: requirement.id,
              type: requirement.type,
            })),
          },
          emailFromEmail,
          emailFromName,
          imageUrl: location.imageUrl ?? '',
          logoUrl: location.logoUrl ?? '',
        };
      }

      return {
        defaultRequirements: {
          existing: [],
          values: [],
        },
      };
    };

    return (
      <FormTemplate<LocationFormInputs, UpdateLocationData>
        initialValues={getInitialValues()}
        formHeader={'Edit Location'}
        identifier={id}
        steps={LOCATION_FORM_STEPS}
        validationSchema={LOCATION_FORM_VALIDATION_SCHEMA}
      />
    );
  }

  return (
    <FormTemplate<LocationFormInputs, CreateLocationData>
      formHeader="Create Location"
      steps={LOCATION_FORM_STEPS}
      validationSchema={LOCATION_FORM_VALIDATION_SCHEMA}
    />
  );
};

export default LocationForm;
