import { Maybe, Query, QueryLocationArgs } from '../../../__generated__/graphql';
import React, { useCallback, useEffect, useMemo } from 'react';

import Container from '../../Container';
import CustomSectionTitle from './CustomSectionTitle';
import { GET_LOCATION } from '../../../graphql/queries';
import ReadOnlyPeriodInput from './ReadOnlyPeriodInput';
import { TimeControlType } from '../enums';
import convert24To12HoursFormat from '../util/convert24To12HoursFormat';
import { flashApolloError } from '../../../util/errorUtils';
import { useLazyQuery } from '@apollo/client';

interface DefaultReservationTimesProps {
  locationId?: Maybe<string> | undefined;
}

type GetLocationData = Pick<Query, 'location'>;

const DefaultReservationTimes: React.FC<DefaultReservationTimesProps> = ({
  locationId,
}: DefaultReservationTimesProps) => {
  const [getLocation, { data, loading }] = useLazyQuery<GetLocationData, QueryLocationArgs>(
    GET_LOCATION,
    {
      fetchPolicy: 'network-only',
      onError: flashApolloError,
    }
  );

  const getLocationDataIfNeeded = useCallback(() => {
    if (locationId) {
      getLocation({
        variables: {
          where: {
            id: locationId,
          },
        },
      });
    }
  }, [locationId, getLocation]);

  useEffect(() => {
    getLocationDataIfNeeded();
  }, [getLocationDataIfNeeded]);

  const inputValues = useMemo(() => {
    if (data?.location) {
      const { defaultEndTime, defaultStartTime } = data.location;

      return {
        checkIn: { ...convert24To12HoursFormat(defaultStartTime) },
        checkOut: { ...convert24To12HoursFormat(defaultEndTime) },
      };
    }

    return undefined;
  }, [data]);

  return (
    <Container>
      <CustomSectionTitle title="Access Setup" />
      <CustomSectionTitle
        title="Default location check-in/check-out times"
        description={[
          'These are the default check-in/check-out times configured for this location.',
        ]}
        smallerTitle
      />
      <>
        <ReadOnlyPeriodInput
          controlType={TimeControlType.CHECK_IN}
          timeTextValue={inputValues?.checkIn.time}
          timePeriod={inputValues?.checkIn.period}
          loading={loading}
        />
        <ReadOnlyPeriodInput
          controlType={TimeControlType.CHECK_OUT}
          timeTextValue={inputValues?.checkOut.time}
          timePeriod={inputValues?.checkOut.period}
          loading={loading}
        />
      </>
    </Container>
  );
};

export default DefaultReservationTimes;
