import DataTable from '../../../components/DataTable';
import { LocationPageInfo } from '../@types/GetLocationPageInfo';
import React from 'react';
import { useParams } from 'react-router-dom';
import useUnitTypesTableData from '../../../hooks/useUnitTypesTableData';

interface UnitTypesTabProps {
  location: LocationPageInfo;
}

const UnitTypesTab: React.FC<UnitTypesTabProps> = ({ location }: UnitTypesTabProps) => {
  const { locationId } = useParams<{ locationId: string }>();

  const tableData = useUnitTypesTableData({
    isImportEnabled: true,
    queryOptions: {
      where: {
        locationId: {
          equals: locationId,
        },
      },
    },
    relatedLocation: location,
  });

  return <DataTable {...tableData} />;
};

export default UnitTypesTab;
