import 'styled-components/macro';

import Heading, { HeadingModel } from '../../Heading';
import {
  LanguageInfo,
  Query,
  QueryGetLanguagesArgs,
  SortOrder,
} from '../../../__generated__/graphql';
import React, { useCallback, useEffect, useState } from 'react';

import Button from '../../Button';
import ButtonModel from '../../Button/enums/ButtonModel.enum';
import Container from '../../Container';
import CustomizeLocalization from '..';
import CustomizeThemeLocalizationInterface from '../../../@types/CustomizeThemeLocalizationInterface';
import GET_LANGUAGES from '../../../graphql/getLanguages';
import { LoadingSpinner } from '../../LoadingSpinner';
import TabButtonsStyle from '../../JsonEditor/styles/TabButtons.style';
import { flashApolloError } from '../../../util/errorUtils';
import { useLazyQuery } from '@apollo/client';

type CustomizeLanguageLocalizationInterface = CustomizeThemeLocalizationInterface;

type GetLanguagesData = Pick<Query, 'getLanguages'>;

const CustomizeLanguageLocalization: React.FC<CustomizeLanguageLocalizationInterface> = ({
  schema,
  uiSchema,
  componentName,
  fileNameToDownloadDefaultJson,
  fileNameToDownloadCurrentJson,
  title,
  entityLevel,
  entityId,
  mockMainJsonNode,
}: CustomizeLanguageLocalizationInterface) => {
  const [languages, setLanguages] = useState<LanguageInfo[] | undefined>(undefined);
  const [activeTab, setActiveTab] = useState<string>();

  const [getLanguages, { data, loading }] = useLazyQuery<GetLanguagesData, QueryGetLanguagesArgs>(
    GET_LANGUAGES,
    {
      fetchPolicy: 'cache-and-network',
      onError: flashApolloError,
    }
  );

  const load = useCallback(() => {
    getLanguages({
      variables: {
        orderBy: [
          {
            code: SortOrder.Asc,
          },
        ],
      },
    });
  }, [getLanguages]);

  useEffect(() => {
    load();
  }, [load]);

  if (data && !languages) {
    setLanguages(data.getLanguages);
  }

  const languageTabsComponent = (languages ?? []).map(
    (language): JSX.Element => {
      const { id, code, name } = language;
      return (
        <Button
          active={id === activeTab}
          data-tab={id}
          key={id}
          model={ButtonModel.TAB}
          onClick={(): void => setActiveTab(id !== activeTab ? id : undefined)}
        >
          {code} - {name}
        </Button>
      );
    }
  );

  const getFullLanguageTitle = (language: LanguageInfo): string => {
    return `${language.code} - ${language.name}`;
  };

  const selectedLanguage = (): LanguageInfo | undefined => {
    if (!activeTab || !languages) {
      return undefined;
    }

    return languages.find((language) => language.id === activeTab);
  };

  const mainComponent = (): JSX.Element => {
    const customFileNameToDownloadDefaultJson = fileNameToDownloadDefaultJson!.replace(
      `\${LANGUAGE_NAME}`,
      getFullLanguageTitle(selectedLanguage()!)
    );
    const customFileNameToDownloadCurrentJSON = fileNameToDownloadCurrentJson!.replace(
      `\${LANGUAGE_NAME}`,
      getFullLanguageTitle(selectedLanguage()!)
    );
    const language = selectedLanguage()!;
    const customizeLocalizationTitle = getFullLanguageTitle(selectedLanguage()!);

    return (
      <CustomizeLocalization
        language={language}
        fileNameToDownloadDefaultJson={customFileNameToDownloadDefaultJson}
        fileNameToDownloadCurrentJson={customFileNameToDownloadCurrentJSON}
        title={customizeLocalizationTitle}
        schema={schema}
        uiSchema={uiSchema}
        componentName={componentName}
        entityLevel={entityLevel}
        entityId={entityId}
        mockMainJsonNode={mockMainJsonNode}
        key={language.code}
      />
    );
  };

  return (
    <Container margin="0 0 0 30px">
      <Heading margin="0 0 15px 0" model={HeadingModel.PRIMARY} color="#ffffff">
        {title}
      </Heading>
      <Heading margin="0 0 15px 0" model={HeadingModel.SECONDARY} color="#ffffff">
        Languages
      </Heading>
      {!loading && languages ? (
        <div>
          <div css={TabButtonsStyle}>{languageTabsComponent}</div>
          {activeTab ? mainComponent() : <div></div>}
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </Container>
  );
};

export default CustomizeLanguageLocalization;
