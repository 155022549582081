import { Mutation } from '../__generated__/graphql';
import MutationHookData from './@types/MutationHookData';
import MutationHookParams from './@types/MutationHookParams';
import { MutationUploadUnitTypesArgs } from '../__generated__/graphql';
import UPLOAD_UNIT_TYPES from '../graphql/uploadUnitTypes';
import { apolloRequirePreflightHeader } from '../client';
import { closeForm } from '../redux/actions/modals.actions';
import { flashApolloError } from '../util/errorUtils';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useMutation } from '@apollo/client';

type UploadUnitTypesData = Pick<Mutation, 'uploadUnitTypes'>;

export default function useUploadUnitTypes({
  onCompleted = (): void => {},
  onError = flashApolloError,
}: MutationHookParams<UploadUnitTypesData> = {}): MutationHookData<
  MutationUploadUnitTypesArgs,
  UploadUnitTypesData
> {
  const { watch } = useFormContext();
  const dispatch = useDispatch();

  const csvFile: File = watch('csvFile');

  const [mutationUploadResources, { data, error, loading }] = useMutation<
    UploadUnitTypesData,
    MutationUploadUnitTypesArgs
  >(UPLOAD_UNIT_TYPES, {
    context: {
      headers: apolloRequirePreflightHeader,
    },
    onCompleted: ({ uploadUnitTypes: uploadedUnitTypes }) => {
      let message = 'created successfully.';
      if (!uploadedUnitTypes || uploadedUnitTypes.length === 0) {
        message = 'No unit types have been created.';
      } else if (uploadedUnitTypes.length > 1) {
        message = `${uploadedUnitTypes.length} unit types ${message}`;
      } else if (uploadedUnitTypes.length === 1) {
        message = `${uploadedUnitTypes.length} unit type ${message}`;
      }

      window.flash({
        message,
      });

      onCompleted({ uploadUnitTypes: uploadedUnitTypes });

      dispatch(closeForm());
    },
    onError,
  });

  return {
    data,
    error,
    loading,
    mutation: (input: MutationUploadUnitTypesArgs): void => {
      mutationUploadResources({
        variables: {
          ...input,
          file: csvFile,
        },
      });
    },
  };
}
