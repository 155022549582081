import 'react-loading-skeleton/dist/skeleton.css';
import { ControlTitle } from '../styles';
import CustomSkeletonGrid from './CustomSkeletonGrid';
import React from 'react';

interface GracePeriodsControllerSkeletonProps {
  title: string;
}

const GracePeriodsControllerSkeleton: React.FC<GracePeriodsControllerSkeletonProps> = ({
  title,
}: GracePeriodsControllerSkeletonProps) => {
  return (
    <>
      <CustomSkeletonGrid skeletonDimensionsList={[{ height: 13, width: 13 }]} />
      <ControlTitle>{title}</ControlTitle>
      <CustomSkeletonGrid
        skeletonDimensionsList={[
          { height: 35, width: 270 },
          { height: 35, width: 240 },
          { height: 35, width: 90 },
        ]}
        rounded
      />
    </>
  );
};

export default GracePeriodsControllerSkeleton;
