/* eslint-disable sort-keys */

import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3ThemeShareKeySchema: NodeSchemaProperties = {
  shareKeyScreen: {
    title: '#14 Share Key Screen',
    type: 'object',
    properties: {
      'displayShareKeys:enable': {
        description: '[shareKeyScreen.displayShareKeys]',
        title: '#1 Display Share Keys',
        type: 'boolean',
      },
      displayShareKeys: {
        type: undefined,
        enum: [true, false],
      },
      'headerBackgroundColor:enable': {
        description: '[shareKeyScreen.headerBackgroundColor]',
        title: '#2 Header Background Color',
        type: 'boolean',
      },
      headerBackgroundColor: {
        type: 'string',
      },
      'backArrowIconUrl:enable': {
        description: '[shareKeyScreen.backArrowIconUrl]',
        title: '#3 Back Arrow Icon Url',
        type: 'boolean',
      },
      backArrowIconUrl: {
        type: 'string',
      },
      'backButtonBackgroundColor:enable': {
        description: '[shareKeyScreen.backButtonBackgroundColor]',
        title: '#4 Back Button Background Color',
        type: 'boolean',
      },
      backButtonBackgroundColor: {
        type: 'string',
      },
      'tutorialImageUrl:enable': {
        description: '[shareKeyScreen.tutorialImageUrl]',
        title: '#5 Tutorial Image Url',
        type: 'boolean',
      },
      tutorialImageUrl: {
        type: 'string',
      },
      guestCard: {
        title: '#6 Guest Card',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[shareKeyScreen.guestCard.backgroundColor]',
            title: '#6.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'primaryTextColor:enable': {
            description: '[shareKeyScreen.guestCard.primaryTextColor]',
            title: '#6.2 Primary Text Color',
            type: 'boolean',
          },
          primaryTextColor: {
            type: 'string',
          },
          'secondaryTextColor:enable': {
            description: '[shareKeyScreen.guestCard.secondaryTextColor]',
            title: '#6.3 Secondary Text Color',
            type: 'boolean',
          },
          secondaryTextColor: {
            type: 'string',
          },
          'borderColor:enable': {
            description: '[shareKeyScreen.guestCard.borderColor]',
            title: '#6.4 Border Color',
            type: 'boolean',
          },
          borderColor: {
            type: 'string',
          },
          'borderWidth:enable': {
            description: '[shareKeyScreen.guestCard.borderWidth]',
            title: '#6.5 Border Width',
            type: 'boolean',
          },
          borderWidth: {
            type: 'number',
          },
          'borderRadius:enable': {
            description: '[shareKeyScreen.guestCard.borderRadius]',
            title: '#6.6 Border Radius',
            type: 'boolean',
          },
          borderRadius: {
            type: 'number',
          },
          avatar: {
            title: '#6.7 Avatar',
            type: 'object',
            properties: {
              'backgroundColor:enable': {
                description: '[shareKeyScreen.guestCard.avatar.backgroundColor]',
                title: '#6.7.1 Background Color',
                type: 'boolean',
              },
              backgroundColor: {
                type: 'string',
              },
              'textColor:enable': {
                description: '[shareKeyScreen.guestCard.avatar.textColor]',
                title: '#6.7.2 Text Color',
                type: 'boolean',
              },
              textColor: {
                type: 'string',
              },
            },
          },
        },
      },
      textField: {
        title: '#7 Text Field',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[shareKeyScreen.textField.backgroundColor]',
            title: '#7.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'textColor:enable': {
            description: '[shareKeyScreen.textField.textColor]',
            title: '#7.2 Text Color',
            type: 'boolean',
          },
          textColor: {
            type: 'string',
          },
          'borderColor:enable': {
            description: '[shareKeyScreen.textField.borderColor]',
            title: '#7.3 Border Color',
            type: 'boolean',
          },
          borderColor: {
            type: 'string',
          },
          'borderWidth:enable': {
            description: '[shareKeyScreen.textField.borderWidth]',
            title: '#7.4 Border Width',
            type: 'boolean',
          },
          borderWidth: {
            type: 'number',
          },
          'borderRadius:enable': {
            description: '[shareKeyScreen.textField.borderRadius]',
            title: '#7.5 Border Radius',
            type: 'boolean',
          },
          borderRadius: {
            type: 'number',
          },
          'defaultPrefix:enable': {
            description: '[shareKeyScreen.textField.defaultPrefix]',
            title: '#7.6 Default Prefix',
            type: 'boolean',
          },
          defaultPrefix: {
            type: 'string',
          },
        },
      },
      successPopup: {
        title: '#8 Success Popup',
        type: 'object',
        properties: {
          'imageUrl:enable': {
            description: '[shareKeyScreen.successPopup.imageUrl]',
            title: '#8.1 Image Url',
            type: 'boolean',
          },
          imageUrl: {
            type: 'string',
          },
        },
      },
      errorPopup: {
        title: '#9 Error Popup',
        type: 'object',
        properties: {
          'imageUrl:enable': {
            description: '[shareKeyScreen.errorPopup.imageUrl]',
            title: '#9.1 Image Url',
            type: 'boolean',
          },
          imageUrl: {
            type: 'string',
          },
        },
      },
    },
  },
};

export const mobileV3ThemeShareKeyUiSchema: NodeUiSchemaProperties = {
  shareKeyScreen: {
    displayShareKeys: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    headerBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    backArrowIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    backButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    tutorialImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    guestCard: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      primaryTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      secondaryTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderWidth: {
        'ui:label': false,
        'ui:readonly': true,
      },
      borderRadius: {
        'ui:label': false,
        'ui:readonly': true,
      },
      avatar: {
        backgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
    },
    textField: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderWidth: {
        'ui:label': false,
        'ui:readonly': true,
      },
      borderRadius: {
        'ui:label': false,
        'ui:readonly': true,
      },
      defaultPrefix: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    successPopup: {
      imageUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
    },
    errorPopup: {
      imageUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'link',
        'ui:widget': 'uri',
      },
    },
  },
};
