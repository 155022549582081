import { MessageText } from './BaseModal.styles';
import Theme from '../../../theme';
import styled from 'styled-components';

export const GuestInformationBox = styled.div`
  display: grid;
  gap: 10px;
  background-color: ${Theme.colors.white.hexCode};
  border-radius: 12px;
  grid-template-columns: 1fr 1fr;
  height: 75px;
  padding: 15px 20px;
`;

export const GuestInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
`;

export const GuestInfoTextContainer = styled.div``;

export const GuestInformationTitle = styled.div`
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: ${Theme.colors.overlay.hexCode};
`;

export const GuestInformationText = styled.div`
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
`;

export const GuestInformationIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #f5f5f5;
  margin-right: 15px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const MainMessage = styled(MessageText)`
  margin-top: 20px;
`;
