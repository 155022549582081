import { createContext } from 'react';

export interface FormContextProps {
  identifier?: string;
  initialValues?: {};
  setIdentifier: (id: string) => void;
  setStep: (num: number) => void;
  setStepsToDisplay: (steps: { name: string }[]) => void;
  step: number | null;
  stepsToDisplay: { name: string }[] | null;
}

const FormContext = createContext<FormContextProps>({
  identifier: '',
  setIdentifier: () => {},
  setStep: () => {},
  setStepsToDisplay: () => {},
  step: null,
  stepsToDisplay: null,
});

export default FormContext;
