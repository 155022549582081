// eslint-disable-next-line @typescript-eslint/no-explicit-any
const clipboard = navigator?.clipboard as any; // Type assertion

type CopyTextFn = (text: string) => Promise<void>;
type CopyImageFn = (image: HTMLImageElement | HTMLCanvasElement) => Promise<void>;

type UseCopyToClipboardType = {
  copyText: CopyTextFn;
  copyImage: CopyImageFn;
};

declare class ClipboardItem {
  constructor(items: { [type: string]: Blob });
}

function useCopyToClipboard(): UseCopyToClipboardType {
  const transformImageIntoBlob = (image: HTMLImageElement | HTMLCanvasElement): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      if (image instanceof HTMLCanvasElement) {
        image.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          }
        });
      } else if (image instanceof HTMLImageElement) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = image.naturalWidth;
        canvas.height = image.naturalHeight;
        if (ctx) {
          ctx.drawImage(image, 0, 0);
          canvas.toBlob((blob) => {
            if (blob) {
              resolve(blob);
            }
          });
        }
      } else {
        reject(new Error('Unsupported image type'));
      }
    });
  };

  const copyImage: CopyImageFn = async (image: HTMLImageElement | HTMLCanvasElement) => {
    if (!image || !clipboard) {
      return;
    }

    const blob = await transformImageIntoBlob(image);
    await clipboard.write([new ClipboardItem({ [blob.type]: blob })]);
  };

  const copyText: CopyTextFn = async (text) => {
    if (!clipboard) {
      return;
    }

    await clipboard.writeText(text);
  };

  return { copyImage, copyText };
}

export default useCopyToClipboard;
