/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationPaymentAuthorizationSchema: NodeSchemaProperties = {
  paymentAuthorizationScreen: {
    title: '#22 Payment Authorization Screen',
    type: 'object',
    properties: {
      'header:enable': {
        description: '[paymentAuthorizationScreen.header]',
        title: '#1 Header',
        type: 'boolean',
      },
      header: {
        type: 'string',
      },
      'paymentProviderNotSupportMessage:enable': {
        description: '[paymentAuthorizationScreen.paymentProviderNotSupportMessage]',
        title: '#2 Payment Provider Not Support Message',
        type: 'boolean',
      },
      paymentProviderNotSupportMessage: {
        type: 'string',
      },
      'currency:enable': {
        description: '[paymentAuthorizationScreen.currency]',
        title: '#3 Currency',
        type: 'boolean',
      },
      currency: {
        type: 'string',
      },
      'pleaseProvideCreditCardMessage1:enable': {
        description: '[paymentAuthorizationScreen.pleaseProvideCreditCardMessage1]',
        title: '#4 Please Provide Credit Card Message 1',
        type: 'boolean',
      },
      pleaseProvideCreditCardMessage1: {
        type: 'string',
      },
      'pleaseProvideCreditCardMessage2:enable': {
        description: '[paymentAuthorizationScreen.pleaseProvideCreditCardMessage2]',
        title: '#5 Please Provide Credit Card Message 2',
        type: 'boolean',
      },
      pleaseProvideCreditCardMessage2: {
        type: 'string',
      },
      'cardWillNotBeChargedMessage:enable': {
        description: '[paymentAuthorizationScreen.cardWillNotBeChargedMessage]',
        title: '#6 Card Will Not Be Charged Message',
        type: 'boolean',
      },
      cardWillNotBeChargedMessage: {
        type: 'string',
      },
      'authorizationDisabled:enable': {
        description: '[paymentAuthorizationScreen.authorizationDisabled]',
        title: '#7 Authorization Disabled',
        type: 'boolean',
      },
      authorizationDisabled: {
        type: 'string',
      },
      disabledPaymentRequirementTextHtml: {
        title: '#8 Disabled Payment Requirement Text Html',
        type: 'object',
        properties: {
          'text:enable': {
            description: '[paymentAuthorizationScreen.disabledPaymentRequirementTextHtml.text]',
            title: '#8.1 Text',
            type: 'boolean',
          },
          text: {
            type: 'string',
          },
          'type:enable': {
            description: '[paymentAuthorizationScreen.disabledPaymentRequirementTextHtml.type]',
            title: '#8.2 Type',
            type: 'boolean',
          },
          type: {
            enum: ['PLAIN_TEXT', 'HTML'],
            type: 'string',
          },
          'useDefaultStyles:enable': {
            description:
              '[paymentAuthorizationScreen.disabledPaymentRequirementTextHtml.useDefaultStyles]',
            title: '#8.3 Use Default Styles',
            type: 'boolean',
          },
          useDefaultStyles: {
            type: undefined,
            enum: [true, false],
          },
        },
      },
      'paymentProviderNotSet:enable': {
        description: '[paymentAuthorizationScreen.paymentProviderNotSet]',
        title: '#9 Payment Provider Not Set',
        type: 'boolean',
      },
      paymentProviderNotSet: {
        type: 'string',
      },
      error: {
        title: '#10 Error',
        type: 'object',
        properties: {
          'title:enable': {
            description: '[paymentAuthorizationScreen.error.title]',
            title: '#10.1 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'description:enable': {
            description: '[paymentAuthorizationScreen.error.description]',
            title: '#10.2 Description',
            type: 'boolean',
          },
          description: {
            type: 'string',
          },
        },
      },
      errorModal: {
        title: '#11 Error Modal',
        type: 'object',
        properties: {
          'buttonTitle:enable': {
            description: '[paymentAuthorizationScreen.errorModal.buttonTitle]',
            title: '#11.1 Button Title',
            type: 'boolean',
          },
          buttonTitle: {
            type: 'string',
          },
          'title:enable': {
            description: '[paymentAuthorizationScreen.errorModal.title]',
            title: '#11.2 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
        },
      },
      successModal: {
        title: '#12 Success Modal',
        type: 'object',
        properties: {
          'buttonTitle:enable': {
            description: '[paymentAuthorizationScreen.successModal.buttonTitle]',
            title: '#12.1 Button Title',
            type: 'boolean',
          },
          buttonTitle: {
            type: 'string',
          },
          'title:enable': {
            description: '[paymentAuthorizationScreen.successModal.title]',
            title: '#12.2 Title',
            type: 'boolean',
          },
          title: {
            type: 'string',
          },
          'description:enable': {
            description: '[paymentAuthorizationScreen.successModal.description]',
            title: '#12.3 Description',
            type: 'boolean',
          },
          description: {
            type: 'string',
          },
        },
      },
    },
  },
};

export const mobileV3LocalizationPaymentAuthorizationUiSchema: NodeUiSchemaProperties = {
  paymentAuthorizationScreen: {
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    paymentProviderNotSupportMessage: {
      'ui:label': false,
      'ui:readonly': true,
    },
    currency: {
      'ui:label': false,
      'ui:readonly': true,
    },
    pleaseProvideCreditCardMessage1: {
      'ui:label': false,
      'ui:readonly': true,
    },
    pleaseProvideCreditCardMessage2: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cardWillNotBeChargedMessage: {
      'ui:label': false,
      'ui:readonly': true,
    },
    authorizationDisabled: {
      'ui:label': false,
      'ui:readonly': true,
    },
    disabledPaymentRequirementTextHtml: {
      text: {
        'ui:label': false,
        'ui:readonly': true,
      },
      type: {
        'ui:label': false,
        'ui:readonly': true,
      },
      useDefaultStyles: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
    },
    paymentProviderNotSet: {
      'ui:label': false,
      'ui:readonly': true,
    },
    error: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    errorModal: {
      buttonTitle: {
        'ui:label': false,
        'ui:readonly': true,
      },
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    successModal: {
      buttonTitle: {
        'ui:label': false,
        'ui:readonly': true,
      },
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
  },
};
