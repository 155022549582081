import {
  GetComponentsEntityTypeEnum,
  useGetCustomizeableComponents,
} from '../../../hooks/useGetDashboardCustomizeableComponents';
import { MdLanguage, MdStyle } from 'react-icons/md';
import React, { useEffect } from 'react';
import SettingsTab, { SettingsTabOption } from '../../../components/SettingsTab';

import { CustomizeComponentEnum } from '../../../util/jsonEditorUtils/types/CustomizeComponent.enum';
import CustomizeConfirmationEmailLocalization from './Settings/CustomizeLocalization/CustomizeConfirmationEmailLocalization';
import CustomizeKioskLocalization from './Settings/CustomizeLocalization/CustomizeKioskLocalization';
import CustomizeKioskTheme from './Settings/CustomizeTheme/CustomizeKioskTheme';
import CustomizeMobileLocalization from './Settings/CustomizeLocalization/CustomizeMobileLocalization';
import CustomizeMobileTheme from './Settings/CustomizeTheme/CustomizeMobileTheme';
import CustomizeMobileV3Localization from './Settings/CustomizeLocalization/CustomizeMobileV3Localization';
import CustomizeMobileV3Theme from './Settings/CustomizeTheme/CustomizeMobileV3Theme';
import CustomizePassInfoTheme from './Settings/CustomizeTheme/CustomizePassInfoTheme';
import { CustomizeSettingsEnum } from '../../../util/jsonEditorUtils/types/CustomizeSettings.enum';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { getCustomizationTabsPerSettingsAndComponent } from '../../../util/jsonEditorUtils/getCustomizationSettings';
import { useParams } from 'react-router-dom';

const LocationSettingsTab: React.FC<{}> = () => {
  const { locationId } = useParams<{ locationId: string }>();

  const { isLoading, query, data: componentsData } = useGetCustomizeableComponents(
    GetComponentsEntityTypeEnum.LOCATION
  );

  useEffect(() => {
    query(locationId);
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const getComponentBasedOnSettingAndComponent = (
    settings: CustomizeSettingsEnum,
    component: CustomizeComponentEnum
  ): SettingsTabOption | undefined => {
    const themeSettingsTabs = new Map([
      [
        CustomizeComponentEnum.KIOSK,
        {
          Component: CustomizeKioskTheme,
          icon: MdStyle,
          key: `${component}-${settings}-CUSTOMIZATION`,
          value: 'Customize kiosk theme',
        },
      ],
      [
        CustomizeComponentEnum.MOBILE,
        {
          Component: CustomizeMobileTheme,
          icon: MdStyle,
          key: `${component}-${settings}-CUSTOMIZATION`,
          value: 'Customize mobile app & web theme',
        },
      ],
      [
        CustomizeComponentEnum.MOBILE_V3,
        {
          Component: CustomizeMobileV3Theme,
          icon: MdStyle,
          key: `${component}-${settings}-CUSTOMIZATION`,
          value: 'Customize mobile v3 theme',
        },
      ],
      [
        CustomizeComponentEnum.PASS_INFO,
        {
          Component: CustomizePassInfoTheme,
          icon: MdStyle,
          key: `${component}-${settings}-CUSTOMIZATION`,
          value: 'Customize pass info theme',
        },
      ],
    ]);

    const localizationSettingsTabs = new Map([
      [
        CustomizeComponentEnum.KIOSK,
        {
          Component: CustomizeKioskLocalization,
          icon: MdLanguage,
          key: `${component}-${settings}-CUSTOMIZATION`,
          value: 'Customize kiosk localization',
        },
      ],
      [
        CustomizeComponentEnum.MOBILE,
        {
          Component: CustomizeMobileLocalization,
          icon: MdLanguage,
          key: `${component}-${settings}-CUSTOMIZATION`,
          value: 'Customize mobile app & web localization',
        },
      ],
      [
        CustomizeComponentEnum.MOBILE_V3,
        {
          Component: CustomizeMobileV3Localization,
          icon: MdLanguage,
          key: `${component}-${settings}-CUSTOMIZATION`,
          value: 'Customize mobile v3 localization',
        },
      ],
      [
        CustomizeComponentEnum.CONFIRMATION_EMAIL,
        {
          Component: CustomizeConfirmationEmailLocalization,
          icon: MdLanguage,
          key: `${component}-${settings}-CUSTOMIZATION`,
          value: 'Customize confirmation e-mail localization',
        },
      ],
    ]);

    switch (settings) {
      case CustomizeSettingsEnum.THEME:
        return themeSettingsTabs.get(component);
      case CustomizeSettingsEnum.LOCALIZATION:
        return localizationSettingsTabs.get(component);
      default:
        return undefined;
    }
  };

  return (
    <SettingsTab
      tabs={getCustomizationTabsPerSettingsAndComponent({
        componentsData,
        getComponentBasedOnSettingAndComponent,
      })}
    />
  );
};

export default LocationSettingsTab;
