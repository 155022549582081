import { Datum } from '../../../components/DataTable';
import { Query } from '../../../__generated__/graphql';
import moment from 'moment';

type GetPaymentTableData = Pick<Query, 'payments'>;

export default function generatePaymentsTableData(data?: GetPaymentTableData): Datum[] {
  const payments = data?.payments || [];

  const tableData = payments.map((payment) => {
    return {
      dataType: 'Payment',
      fields: [
        {
          key: 'Token',
          value: payment.token,
        },
        {
          key: 'CardType',
          value: payment.cardType || '',
        },
        {
          key: 'LastFour',
          value: payment.lastFour || '',
        },
        {
          key: 'ExpYear',
          value: payment.expYear || '',
        },
        {
          key: 'ExpMonth',
          value: payment.expMonth || '',
        },
        {
          key: 'Created At',
          value: moment(payment.createdAt).format('LLL'),
        },
      ],
      key: payment.id,
    };
  });

  return tableData;
}
