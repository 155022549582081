import { KioskWhereInput } from '../../../__generated__/graphql';
import SearchField from '../../../@types/SearchField';
import createSearchField from '../../../util/tableData/createSearchField';
import transformStringFilter from '../../../util/tableData/transformStringFilter';
import transformWhereRelationFilterInput from '../../../util/tableData/transformWhereRelationFilterInput';

const KIOSKS_SEARCH_FIELDS: SearchField<KioskWhereInput>[] = [
  createSearchField<KioskWhereInput, 'name'>({
    label: 'Name',
    queryField: 'name',
    transform: transformStringFilter,
  }),
  createSearchField<KioskWhereInput, 'location'>({
    label: 'Location',
    queryField: 'location',
    transform: transformWhereRelationFilterInput,
  }),
  createSearchField<KioskWhereInput, 'buildNumber'>({
    label: 'Build Number',
    queryField: 'buildNumber',
    transform: transformStringFilter,
  }),
];

export default KIOSKS_SEARCH_FIELDS;
