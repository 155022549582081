import { getCredentialBasicType, getCredentialTypeName } from './credentialType';
import { getUserType, getUserTypeName } from './userType';

import { Datum } from '../../../components/DataTable';
import { Query } from '../../../__generated__/graphql';
import { UseDateTimeFormatterInterface } from '../../useDateTimeFormatter';
import determineCredentialStatus from './determineCredentialStatus';
import links from '../../../util/links';

type GetCredentialTableData = Pick<Query, 'getCredentials'>;

export default function generateCredentialsTableData(
  credentialsBeingProcessed: string[],
  formatter: UseDateTimeFormatterInterface,
  data?: GetCredentialTableData,
  locationTimezone?: string
): Datum[] {
  const { formatDate } = formatter;

  return (
    data?.getCredentials.map((credential) => {
      return {
        dataType: 'Credential',
        fields: [
          {
            key: 'Guest',
            link: `${links.paths.USERS}/${credential.user.id}`,
            value: credential.user.name ?? '',
          },
          {
            key: 'Guest type',
            value: getUserTypeName(
              getUserType({
                accessGrantUserId: credential.accessGrant.userId,
                credentialUserId: credential.userId,
              })
            ),
          },
          {
            key: 'Type',
            value: getCredentialTypeName(getCredentialBasicType(credential.type)),
          },
          {
            key: 'Status',
            value: determineCredentialStatus(
              credential.id,
              credential.isActive,
              credentialsBeingProcessed
            ),
          },
          {
            key: 'Created at',
            value: formatDate(credential.createdAt, { timezone: locationTimezone }),
          },
          {
            key: 'Expires at',
            value: credential.expiresAt
              ? formatDate(credential.expiresAt, { timezone: locationTimezone })
              : '',
          },
          {
            key: 'Revoked at',
            value: credential.cancelledAt
              ? formatDate(credential.cancelledAt, { timezone: locationTimezone })
              : '',
          },
        ],
        key: credential.id,
      };
    }) || []
  );
}
