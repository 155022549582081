import * as yup from 'yup';

import URL_VALIDATION from '../../../constants/yupValidations/constants/url';

const UNIT_TYPE_VALIDATION_SCHEMA = yup.object().shape({
  imageType: yup.string().required('Image type is required'),
  imageUrl: URL_VALIDATION,
  location: yup.object().shape({
    label: yup.string().required('Location is required'),
    value: yup.string().required('Location is required'),
  }),
  numberOfGuests: yup
    .number()
    .integer('Number of guests must be an integer')
    .typeError('Please enter value.')
    .required('Number of guests is required.')
    .min(1, 'Number of guests must be greater than 0.'),
  type: yup.string().required('Type is required'),
});

export default UNIT_TYPE_VALIDATION_SCHEMA;
