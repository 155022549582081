import Modal from 'react-modal';
import Theme from '../../../theme';

export const minimizedVideoCallContainerHeight = '250px';

const MinimizedVideoCallModalStyle: Modal.Styles = {
  content: {
    border: '0',
    boxShadow: `0px 5px 32px 0px rgba(${Theme.colors.borderDark.rgb}, 0.91)`,
    display: 'grid',
    gridAutoRows: '1fr',
    height: minimizedVideoCallContainerHeight,
    inset: 'calc(100% - 250px) 0px 0px calc(100% - 400px)',
    outline: '0',
    padding: '0',
    position: 'fixed',
    width: '400px',
    zIndex: 11,
  },
  overlay: {
    background: 'transparent',
    border: '0',
    display: 'contents',
    outline: '0',
    zIndex: 12,
  },
};

export default MinimizedVideoCallModalStyle;
