import { gql } from '../__generated__/gql';

const CREATE_RESOURCE = gql(`
  mutation CreateResourceAndUploadImageFile($data: CustomResourceCreateInput!) {
    createResourceAndUploadImageFile(data: $data) {
      id
      locationId
      name
    }
  }
`);

export default CREATE_RESOURCE;
