import { Location, Mutation } from '../../__generated__/graphql';
import React, { useEffect } from 'react';

import FormCrudAction from '../../enums/FormCrudAction.enum';
import FormTemplate from '../../templates/Form';
import { ImageTypeEnum } from './enums/ImageTypeEnum.enum';
import UNIT_TYPE_FORM_STEPS from './constants/unitTypeFormSteps';
import UNIT_TYPE_VALIDATION_SCHEMA from './constants/unitTypeValidationSchema';
import UnitTypeFormInputs from './@types/UnitTypeFormInputs';
import pick from 'lodash/pick';
import useGetUnitTypeById from '../../hooks/useGetUnitTypeById';

interface UnitTypeAditionalDataOnOpeningFormInterface {
  location: Pick<Location, 'id' | 'name'>;
}

interface UnitTypeProps {
  id?: string;
  aditionalDataOnOpeningForm?: unknown;
}

type CreateUnitTypeData = Pick<Mutation, 'createUnitTypeAndUploadImageFile'>;

const UnitTypeForm: React.FC<UnitTypeProps> = ({
  id,
  aditionalDataOnOpeningForm,
}: UnitTypeProps) => {
  const { called, data: unitTypeLocalization, query } = useGetUnitTypeById();

  useEffect(() => {
    if (id && !called) {
      query(id);
    }
  });

  if (id && !unitTypeLocalization) {
    return <React.Fragment />;
  }

  const initialValues = (): Partial<UnitTypeFormInputs> => {
    if (unitTypeLocalization) {
      const { getUnitTypeLocalizations: unitTypeLocalizations, unitType } = unitTypeLocalization;

      if (unitType) {
        return {
          ...pick(unitType, ['id', 'imageUrl', 'type', 'numberOfGuests']),
          imageType: ImageTypeEnum.URL,
          localizations: unitTypeLocalizations,
          location: {
            label: unitType.location.name,
            value: unitType.location.id,
          },
        };
      }
    }

    const defaultInitialValues: Partial<UnitTypeFormInputs> = { imageType: ImageTypeEnum.URL };

    try {
      if (aditionalDataOnOpeningForm) {
        const {
          location,
        } = aditionalDataOnOpeningForm as UnitTypeAditionalDataOnOpeningFormInterface;
        return {
          ...defaultInitialValues,
          location: {
            label: location.name,
            value: location.id,
          },
        };
      }
      // eslint-disable-next-line no-empty
    } catch {}

    return defaultInitialValues;
  };

  const crudAction = id ? FormCrudAction.EDIT : FormCrudAction.CREATE;

  return (
    <FormTemplate<UnitTypeFormInputs, CreateUnitTypeData>
      formHeader={`${crudAction} Unit Type`}
      identifier={id}
      steps={UNIT_TYPE_FORM_STEPS}
      validationSchema={UNIT_TYPE_VALIDATION_SCHEMA}
      initialValues={initialValues()}
    />
  );
};

export default UnitTypeForm;
