import { Datum } from '../../../components/DataTable';
import { Query } from '../../../__generated__/graphql';

type GetUnitTypeTableData = Pick<Query, 'unitTypes'>;

export default function generateUnitTypesTableData(data?: GetUnitTypeTableData): Datum[] {
  const tableData =
    data?.unitTypes?.map((unitType) => ({
      dataType: 'Unit Type',
      fields: [
        {
          key: 'Type',
          value: unitType.type,
        },
        {
          key: 'Number of guests',
          value: unitType.numberOfGuests,
        },
        {
          key: 'Image URL',
          ...(unitType.imageUrl && { href: unitType.imageUrl }),
          value: unitType.imageUrl,
        },
      ],
      key: unitType.id,
    })) || [];

  return tableData;
}
