import React, { Dispatch, SetStateAction } from 'react';
import { UseFormMethods, useFormContext } from 'react-hook-form';

import TextGrid from './components/TextGrid';
import ToggleOptionSelected from './components/ToggleOptionSelected';
import ToggleSelectorContainer from './components/ToggleSelectorContainer';
import ToggleSelectorOption from './@types/ToggleSelectorOption';
import useOptionsHelper from './hooks/useOptionsHelper';

type ToggleSelectorProps = {
  widthType?: 'compact' | 'normal' | 'parentWidth';
  options: ToggleSelectorOption[] | string[];
  themeMode: 'light' | 'dark';
};

type ToggleSelectorStateProps = ToggleSelectorProps & {
  stateObject: [string, Dispatch<SetStateAction<string>>];
};

type ToggleSelectorFormProps = ToggleSelectorProps & {
  name: string;
};

type StateObjectStringType = {
  stateValue: string;
  setStateValue: Dispatch<SetStateAction<string>>;
};

type FormObjectType = Pick<UseFormMethods, 'register' | 'getValues' | 'setValue' | 'watch'> & {
  name: string;
};

type InnerToggleSelector = ToggleSelectorProps & {
  stateObject?: StateObjectStringType;
  formObject?: FormObjectType;
  toggleSelectorType: 'state' | 'form';
};

const InnerToggleSelector: React.FC<InnerToggleSelector> = ({
  widthType,
  options,
  themeMode,
  toggleSelectorType,
  stateObject,
  formObject,
}: InnerToggleSelector) => {
  if (options.length === 0) {
    throw new Error('Options are required');
  }
  if (toggleSelectorType === 'form' && !formObject) {
    throw new Error('Form object is required on form type');
  }
  if (toggleSelectorType === 'state' && !stateObject) {
    throw new Error('State object is required on state type');
  }

  const { optionValuesToString, optionsToSelectOption } = useOptionsHelper({ options });

  const currentValue =
    toggleSelectorType === 'form'
      ? formObject?.getValues(formObject.name) || optionValuesToString()[0]
      : stateObject?.stateValue;

  if (toggleSelectorType === 'form') {
    formObject?.watch(formObject?.name);
  }

  const getIndexSelected = (): number | undefined => {
    if (currentValue) {
      return optionValuesToString().indexOf(currentValue);
    }
    return undefined;
  };

  const onOptionChosen = (newValue: string): void => {
    if (toggleSelectorType === 'form') {
      formObject?.setValue(formObject.name, newValue);
    } else {
      stateObject?.setStateValue(newValue);
    }
  };

  const switchValue = (): void => {
    // When there are 2 values, on clicking at the active one, it will switch
    if (options.length === 2) {
      const otherOption = optionValuesToString().find((option) => option !== currentValue);
      if (otherOption) {
        onOptionChosen(otherOption);
      }
    }
  };

  return (
    <ToggleSelectorContainer
      themeMode={themeMode}
      numberOfOptions={options.length}
      widthType={widthType}
    >
      <input
        hidden
        defaultValue={currentValue}
        readOnly
        ref={toggleSelectorType === 'form' ? formObject?.register : undefined}
        name={toggleSelectorType === 'form' ? formObject?.name : undefined}
      />
      <TextGrid numberOfOptions={options.length}>
        {optionsToSelectOption().map((option) => {
          return (
            <span key={option.value} onClick={(): void => onOptionChosen(option.value)}>
              {option.displayText ?? option.value}
            </span>
          );
        })}
      </TextGrid>
      <ToggleOptionSelected
        indexSelected={getIndexSelected()}
        numberOfOptions={options.length}
        themeMode={themeMode}
        onClick={switchValue}
      >
        {currentValue}
      </ToggleOptionSelected>
    </ToggleSelectorContainer>
  );
};

const ToggleSelectorState: React.FC<ToggleSelectorStateProps> = ({
  options,
  stateObject,
  themeMode,
  widthType,
}: ToggleSelectorStateProps) => {
  const [currentValue, setValue] = stateObject;

  return (
    <InnerToggleSelector
      widthType={widthType}
      options={options}
      themeMode={themeMode}
      toggleSelectorType="state"
      stateObject={{ setStateValue: setValue, stateValue: currentValue }}
    />
  );
};

const ToggleSelectorForm: React.FC<ToggleSelectorFormProps> = ({
  options,
  themeMode,
  name,
  widthType,
}: ToggleSelectorFormProps) => {
  const { register, getValues, setValue, watch } = useFormContext();

  return (
    <InnerToggleSelector
      widthType={widthType}
      options={options}
      themeMode={themeMode}
      toggleSelectorType="form"
      formObject={{ getValues, name, register, setValue, watch }}
    />
  );
};

const ToggleSelector = {
  Form: ToggleSelectorForm,
  State: ToggleSelectorState,
};

export default ToggleSelector;
