import { ClearSortOptionsPayload } from '../../../actions/@types/ClearSortAction';
import DataTableReduxState from '../../@types/DataTableReduxState';

export default function clearSortAction(
  currentState: DataTableReduxState,
  actionPayload: ClearSortOptionsPayload
): DataTableReduxState {
  const newSort = { ...currentState.sortOptions };
  delete newSort[actionPayload.dataTableKey];

  return {
    ...currentState,
    sortOptions: newSort,
  };
}
