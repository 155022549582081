enum ButtonModel {
  ACTIVE_CTA = 'button__cta_active',
  ACTIVE_CTA_DARK = 'button__cta_active--dark',
  ACTIVE_ICON_BUTTON = 'button__icon_active',
  DEFAULT = 'button__default',
  CIRCLE_ICON_BUTTON_DARK = 'button__icon_passive--dark',
  FILTER_DARK = 'button__filter--dark',
  FILTER_DARK_SELECTED = 'button__filter_selected--dark',
  INLINE_BUTTON = 'button_inline',
  INPUT_EMBEDDED_BUTTON = 'button__input',
  LIGHT_ROUNDED = 'button__light_rounded',
  LIST_DARK = 'button_list--dark',
  PASSIVE_CTA = 'button__cta_passive',
  PASSIVE_ICON_BUTTON = 'button__icon_passive',
  STEP = 'button__step',
  TAB = 'button__tab',
  TAB_SELECTED = 'button__tab_selected',
  QUICK_ACTION_MODAL_CONFIRM = 'button__quick_action_confirm_button',
  QUICK_ACTION_MODAL_CANCEL = 'button__quick_action_cancel_button',
}

export default ButtonModel;
