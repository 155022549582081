const SpinnerContainerStyle = `
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
`;

export default SpinnerContainerStyle;
