const DetailsTextStyle = `
  align-items: center;
  color: #ffffff;
  font-size: 20px;
  grid-auto-flow: column;
  grid-template-columns: min-content 1fr;
  padding: 10px 0;

  p {
    color: inherit;
    width: 100%;
  }

  span {
    display: inline-block;
    font-weight: bold;
    width: 45px;
  }
`;

export default DetailsTextStyle;
