import { ApolloError, useLazyQuery } from '@apollo/client';
import {
  DashboardPreferences,
  Query,
  QueryGetDashboardPreferencesArgs,
} from '../__generated__/graphql';
import { clearPreferences, setPreferences } from '../redux/actions/dashboardPreferences.actions';

import GET_DATE_TIME_PREFERENCES from '../graphql/getDashboardPreferences';
import { flashApolloError } from '../util/errorUtils';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

type GetDashboardPreferencesData = Pick<Query, 'getDashboardPreferences'>;

type UseGetDashboardPreferencesInterfaceType = QueryGetDashboardPreferencesArgs & {
  onCompleted?: () => void;
};

interface UseGetDashboardPreferencesInterface {
  loadDashboardPreferences: (props: UseGetDashboardPreferencesInterfaceType) => void;
  dashboardPreferences: DashboardPreferences | undefined | null;
  loading: boolean;
}

export default function useLoadDashboardPreferences(): UseGetDashboardPreferencesInterface {
  const dispatch = useDispatch();

  const [onCompleted, setOnCompleted] = useState<() => void | undefined>();

  const [loadPreferences, { loading, data }] = useLazyQuery<
    GetDashboardPreferencesData,
    QueryGetDashboardPreferencesArgs
  >(GET_DATE_TIME_PREFERENCES, {
    fetchPolicy: 'network-only',
    onCompleted: (result: GetDashboardPreferencesData) => {
      const { getDashboardPreferences } = result;

      if (getDashboardPreferences) {
        dispatch(setPreferences({ ...getDashboardPreferences }));
      } else {
        dispatch(clearPreferences());
      }

      if (onCompleted) {
        onCompleted();
      }
    },
    onError: (e: ApolloError) => {
      flashApolloError(e);
    },
  });

  return {
    dashboardPreferences: data?.getDashboardPreferences,
    loadDashboardPreferences: ({
      locationId,
      tenantId,
      userId,
      onCompleted: newOnCompleted,
    }: UseGetDashboardPreferencesInterfaceType): void => {
      if (newOnCompleted) {
        setOnCompleted(newOnCompleted);
      }

      loadPreferences({ variables: { locationId, tenantId, userId } });
    },
    loading,
  };
}
