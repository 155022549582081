import {
  CustomResourceUpdateInput,
  Mutation,
  MutationUpdateResourceAndUploadImageFileArgs,
} from '../__generated__/graphql';

import MutationHookData from './@types/MutationHookData';
import MutationHookParams from './@types/MutationHookParams';
import UPDATE_RESOURCE from '../graphql/updateResource';
import { apolloRequirePreflightHeader } from '../client';
import { closeForm } from '../redux/actions/modals.actions';
import { flashApolloError } from '../util/errorUtils';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';

type UpdateResourceData = Pick<Mutation, 'updateResourceAndUploadImageFile'>;

export default function useUpdateResourceAndUploadImage({
  onCompleted = (): void => {},
  onError = flashApolloError,
}: MutationHookParams<UpdateResourceData> = {}): MutationHookData<
  CustomResourceUpdateInput,
  UpdateResourceData
> {
  const dispatch = useDispatch();

  const [update, { data, error, loading }] = useMutation<
    UpdateResourceData,
    MutationUpdateResourceAndUploadImageFileArgs
  >(UPDATE_RESOURCE, {
    context: {
      headers: apolloRequirePreflightHeader,
    },
    onCompleted: ({ updateResourceAndUploadImageFile }) => {
      dispatch(closeForm());

      if (updateResourceAndUploadImageFile) {
        window.flash({
          message: `Resource: ${updateResourceAndUploadImageFile.name} updated successfully`,
        });
      } else {
        window.flash({ message: 'Fail to update resource' });
      }

      onCompleted({ updateResourceAndUploadImageFile });
    },
    onError,
  });

  return {
    data,
    error,
    loading,
    mutation: (inputs: CustomResourceUpdateInput, id = ''): void => {
      update({
        variables: {
          data: {
            ...inputs,
            ...(inputs.passLevel && { passLevel: parseInt(`${inputs.passLevel}`, 10) }),
          },
          where: { id },
        },
      });
    },
  };
}
