import { gql } from '../../../__generated__/gql';

const GET_ACCESS_GRANT_PAGE_REQUIREMENTS = gql(`
  query GetAccessGrantPageRequirements(
    $distinct: [RequirementScalarFieldEnum!]
    $orderBy: [RequirementOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: RequirementWhereInput
  ) {
    requirements(distinct: $distinct, orderBy: $orderBy, skip: $skip, take: $take, where: $where)
      @client {
      createdAt
      id
      status
      type
      updatedAt
    }
  }
`);

export default GET_ACCESS_GRANT_PAGE_REQUIREMENTS;
