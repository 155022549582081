import Modal from 'react-modal';

export const expandedVideoCallContainerHeight = '100%';

const ExpandedVideoCallModalStyle: Modal.Styles = {
  content: {
    alignSelf: 'center',
    border: '0',
    bottom: '0',
    display: 'grid',
    gridAutoRows: '1fr',
    height: expandedVideoCallContainerHeight,
    justifySelf: 'center',
    left: '0',
    outline: '0',
    padding: '0',
    right: '0',
    top: '0',
    width: '100%',
    zIndex: 10,
  },
  overlay: {
    background: 'rgba(255, 255, 255, 0.1)',
    border: '0',
    outline: '0',
    zIndex: 11,
  },
};

export default ExpandedVideoCallModalStyle;
