export default function generateDayTimes(): string[] {
  const dayTimes: string[] = [];
  const dayStart = '00:00';
  dayTimes.push(dayStart);

  while (dayTimes[dayTimes.length - 1] !== '23:30') {
    const lastTime = dayTimes[dayTimes.length - 1];

    if (lastTime[3] === '3') {
      const lastHour = parseInt(lastTime[0] + lastTime[1], 10);
      const nextHour = lastHour > 8 ? lastHour + 1 : `0${lastHour + 1}`;
      dayTimes.push(`${nextHour}:00`);
    } else {
      dayTimes.push(`${lastTime.slice(0, 2)}:30`);
    }
  }

  return dayTimes;
}
