import { Mutation, MutationUpdateResourceGroupArgs } from '../__generated__/graphql';

import MutationHookData from './@types/MutationHookData';
import MutationHookParams from './@types/MutationHookParams';
import ResourceGroupFormInputs from '../components/ResourceGroupForm/@types/ResourceGroupFormInputs';
import { UPDATE_RESOURCE_GROUP } from '../graphql/updateResourceGroup';
import buildFieldUpdateOperationsInput from '../util/api/buildFieldUpdateOperationsInput';
import { closeForm } from '../redux/actions/modals.actions';
import createResourcesInput from '../util/mutations/createResourcesInput';
import { flashApolloError } from '../util/errorUtils';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

type UpdateResourceGroupData = Pick<Mutation, 'updateResourceGroup'>;

export default function useUpdateResourceGroup({
  onCompleted = (): void => {},
  onError = flashApolloError,
}: MutationHookParams<UpdateResourceGroupData> = {}): MutationHookData<
  ResourceGroupFormInputs,
  UpdateResourceGroupData
> {
  const dispatch = useDispatch();
  const { locationId } = useParams<{ locationId: string }>();

  const [update, { data, error, loading }] = useMutation<
    UpdateResourceGroupData,
    MutationUpdateResourceGroupArgs
  >(UPDATE_RESOURCE_GROUP, {
    onCompleted: ({ updateResourceGroup }) => {
      dispatch(closeForm());

      window.flash({
        message: `Resource Group: ${updateResourceGroup.name} updated successfully`,
      });

      onCompleted({ updateResourceGroup });
    },
    onError,
  });

  return {
    data,
    error,
    loading,
    mutation: (inputs: ResourceGroupFormInputs, id = ''): void => {
      const { connect, disconnect } = createResourcesInput(inputs.resources.map);
      update({
        variables: {
          data: {
            isDefault: buildFieldUpdateOperationsInput(inputs.isDefault),
            location: { connect: { id: locationId } },
            name: buildFieldUpdateOperationsInput(inputs.name),
            resources: {
              connect: connect.length ? connect : undefined,
              disconnect: disconnect.length ? disconnect : undefined,
            },
          },
          where: {
            id,
          },
        },
      });
    },
  };
}
