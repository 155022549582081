/* 
  isTokenExpired determines whether a token exists and if so
  whether that token has expired based on localStorage variables
*/
export default function isTokenExpired(): boolean {
  const tokenExpiry = localStorage.getItem('TOKEN_EXPIRY');
  const accessToken = localStorage.getItem('ACCESS_TOKEN');

  if (!tokenExpiry || !accessToken) {
    return true;
  }

  if (Date.now() > Date.parse(tokenExpiry)) {
    return true;
  }

  return false;
}
