import './index.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import * as Sentry from '@sentry/react';
import * as serviceWorker from './serviceWorker';

import { ApolloProvider } from '@apollo/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import Bus from './services/events/bus';
import { Integrations } from '@sentry/tracing';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import client from './client';
import createStore from './redux/store';

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

/* setting reference for modals */
Modal.setAppElement('#root');

window.flash = ({ link = '', message, subText = '', type = 'sucess', onClose, timeout }): void => {
  Bus.emit('flash', { link, message, onClose, subText, timeout, type });
};

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const store = createStore();

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
