import Theme from '../../../../../../theme';

const WaitingCallStyle = `
  border-color: ${Theme.colors.borderTable.hexCode};
  border-radius: 10px;
  border-width: 1px;
  justify-items: center;
  min-width: 160px;
  padding: 35px 21.5px 15px;
  width: 160px;
`;

export default WaitingCallStyle;
