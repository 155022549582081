import { channelsClient } from './pusher';

const { REACT_APP_VIRDEE_ENV = 'local' } = process.env;

export const generateRealtimeNotificationsChannelName = (tenantId: string): string => {
  return `channel-${REACT_APP_VIRDEE_ENV}-${tenantId}`;
};

export const unbindRealtimeNotifications = (tenantId: string): void => {
  const CHANNEL_NAME = generateRealtimeNotificationsChannelName(tenantId);
  channelsClient.unsubscribe(CHANNEL_NAME);
};
