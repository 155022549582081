import Filter from '../../../components/DataTable/@types/Filter';

export default function addKioskDispenserFilters(filters: Filter[]): Filter[] {
  filters.push(
    {
      displayKey: 'Dispenser',
      displayValue: 'Online',
      key: 'disp',
      value: 'on',
    },
    {
      displayKey: 'Dispenser',
      displayValue: 'Offline',
      key: 'disp',
      value: 'off',
    }
  );

  return filters;
}
