import {
  loadKioskLiveUsageReservationUpdatedData,
  setKioskLiveUsage,
} from '../redux/actions/kiosksLiveUsage.actions';
import { useDispatch, useSelector } from 'react-redux';

import { KioskLiveUsagePayloadEvent } from '../redux/reducers/kiosksLiveUsage.reducer/@types/KiosksLiveUsageReduxState';
import { ReservationUpdatedType } from '../redux/actions/@types/kiosksLiveUsage/LoadKioskLiveUsageReservationUpdatedDataAction';
import { RootState } from '../redux/reducers';
import { channelsClient } from '../util/pusher';
import { generateRealtimeNotificationsChannelName } from '../util/realTimeNotificationUtils';
import { isEqual } from 'lodash';

export type KioskLiveUsageType = {
  kioskId: string;
  pageName: string;
  accessGrantId?: string;
  origin: 'KIOSK' | string;
  flowType?: 'CHECK_IN' | 'CHECK_OUT' | 'FOLIO' | 'PRINT_ANOTHER_KEYCARD';
};

export const kioskLiveUsageEventName = 'KIOSK-live-usage';

export default function useBindKiosksLiveUsage(): void {
  const dispatch = useDispatch();
  const kiosksLiveUsageState = useSelector((store: RootState) => store.kioskLiveUsage);

  const activeMembership = useSelector((store: RootState) => store.me.activeMembership);

  const tenantId = activeMembership?.tenantId;

  if (tenantId) {
    const CHANNEL_NAME = generateRealtimeNotificationsChannelName(tenantId);
    const existingChannel = channelsClient.channel(CHANNEL_NAME);
    const channel = existingChannel || channelsClient.subscribe(CHANNEL_NAME);
    channel.unbind(kioskLiveUsageEventName);

    channel.bind(kioskLiveUsageEventName, (data: KioskLiveUsageType): void => {
      const { accessGrantId, kioskId, flowType } = data;

      const reservationUpdatedEventName = `reservation-updated-${accessGrantId}`;
      channel.unbind(reservationUpdatedEventName);

      if (accessGrantId && flowType && flowType !== 'CHECK_IN') {
        return;
      }
      const newPayload: KioskLiveUsagePayloadEvent = {
        accessGrantId,
        kioskId,
      };

      const kioskInfoAlreadySet = kiosksLiveUsageState[kioskId];
      if (kioskInfoAlreadySet) {
        const kioskInfoAlreadySetPayload: KioskLiveUsagePayloadEvent = {
          accessGrantId: kioskInfoAlreadySet.payload.accessGrant?.id,
          kioskId: kioskInfoAlreadySet.payload.kioskId,
        };
        if (isEqual(kioskInfoAlreadySetPayload, newPayload)) {
          // Kiosk info already set
          return;
        }
      }

      channel.bind(
        reservationUpdatedEventName,
        (reservationUpdatedEventData: ReservationUpdatedType): void => {
          dispatch(loadKioskLiveUsageReservationUpdatedData(reservationUpdatedEventData));
        }
      );

      dispatch(setKioskLiveUsage(newPayload));
    });
  }
}
