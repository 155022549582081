/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationQrCodeSchema: NodeSchemaProperties = {
  qrCode: {
    title: '#28 QR code',
    type: 'object',
    properties: {
      'title:enable': {
        title: '#1 Title',
        type: 'boolean',
        description: 'Title of the QR code popup [qrCode.title]',
      },
      title: {
        type: 'string',
      },
      'description:enable': {
        title: '#2 Description',
        type: 'boolean',
        description: 'Description of the QR code popup [qrCode.description]',
      },
      description: {
        type: 'string',
      },
      'closeButton:enable': {
        title: '#3 Close button text',
        type: 'boolean',
        description: 'Close button text of the QR code popup [qrCode.closeButton]',
      },
      closeButton: {
        type: 'string',
      },
    },
  },
};

export const mobileV3LocalizationQrCodeUiSchema: NodeUiSchemaProperties = {
  qrCode: {
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    closeButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    description: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
};
