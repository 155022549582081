import { Kiosk, KioskWhereInput, Query, QueryKiosksArgs } from '../../__generated__/graphql';

import GET_KIOSK_TABLE_DATA from '../../graphql/getKioskTableData';
import { RootState } from '../../redux/reducers';
import generateMembershipQuery from '../../util/api/generateMembershipQuery';
import { useCallback } from 'react';
import useGetMany from '../useGetMany';
import { useSelector } from 'react-redux';

type KiosksQueryParams = {
  where: Partial<KioskWhereInput>;
};

type UseGetKiosksProps = {
  queryOptions?: Partial<QueryKiosksArgs>;
};

type UseGetKioskResult = {
  data?: Kiosk[];
  get: () => void;
  loading: boolean;
};

type GetKiosksData = Pick<Query, 'kiosks'>;

const useGetKiosks = ({ queryOptions = {} }: UseGetKiosksProps): UseGetKioskResult => {
  const membership = useSelector((state: RootState) => state.me.activeMembership);

  const { data, loading, query } = useGetMany<GetKiosksData, QueryKiosksArgs>({
    graphqlQuery: GET_KIOSK_TABLE_DATA,
  });

  const createParams = useCallback((): KiosksQueryParams => {
    return {
      where: {
        ...generateMembershipQuery(membership),
        ...queryOptions?.where,
      },
    };
  }, [membership, queryOptions]);

  const get = useCallback(() => {
    const params = createParams();

    query({
      ...queryOptions,
      take: queryOptions?.take ?? 10,
      where: params.where,
    });
  }, [createParams, query]);

  return {
    data: data?.kiosks ?? [],
    get,
    loading,
  };
};

export default useGetKiosks;
