import { gql } from '../__generated__/gql';

const SEARCH_KIOSKS = gql(`
  query SearchKiosks(
    $orderBy: [KioskOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: KioskWhereInput
  ) {
    kiosks(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
      id
      name
    }
  }
`);

export default SEARCH_KIOSKS;
