export enum ThemeComponentName {
  MOBILE = 'MOBILE',
  MOBILE_V3 = 'MOBILE_V3',
  KIOSK = 'KIOSK',
  PASS_INFO = 'PASS_INFO',
}

export enum LocalizationComponentName {
  MOBILE = 'MOBILE',
  MOBILE_V3 = 'MOBILE_V3',
  KIOSK = 'KIOSK',
  CONFIRMATION_EMAIL = 'CONFIRMATION_EMAIL',
}
