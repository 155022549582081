import { Input, InputBox, InputContainer } from '../styles/GracePeriodInput.styles';
import React, { memo } from 'react';

import { Bars } from 'svg-loaders-react';
import { Colors } from '../../../theme/colors';
import { Form } from '../styles';
import InputMask from 'react-input-mask';
import { LimitKeysForm } from '../@types';
import { QueryGetLimitKeysConfigArgs } from '../../../__generated__/graphql';
import { SubmitButton } from '../styles/GracePeriodSection.styles';
import { useForm } from 'react-hook-form';
import { useSetLimitKeys } from '../../../hooks/useSetLimitKeys';

interface KeyLimitFormProps extends QueryGetLimitKeysConfigArgs {
  initialLimitKey?: string;
}

const KeyLimitFormComponent: React.FC<KeyLimitFormProps> = ({
  initialLimitKey,
  ...rest
}: KeyLimitFormProps) => {
  const { register, errors, handleSubmit } = useForm<LimitKeysForm>({
    defaultValues: {
      limitKey: initialLimitKey,
    },
  });
  const { isSubmitting, setLimitKeysConfigCallback } = useSetLimitKeys();

  const onSubmit = (data: LimitKeysForm): void => {
    setLimitKeysConfigCallback({
      ...rest,
      maxKeys: data.limitKey ? Number(data.limitKey) : null,
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <InputContainer>
        <InputBox error={errors.limitKey}>
          <InputMask mask="99" maskPlaceholder="">
            <Input name="limitKey" ref={register()} />
          </InputMask>
          <span>keys</span>
        </InputBox>
      </InputContainer>
      <SubmitButton type="submit" disabled={isSubmitting} isSubmitting={isSubmitting}>
        {isSubmitting ? (
          <Bars fill={Colors.shuttleGrey} height="25px" stroke={Colors.shuttleGrey} width="25px" />
        ) : (
          'Save changes'
        )}
      </SubmitButton>
    </Form>
  );
};

export const KeyLimitForm = memo(KeyLimitFormComponent);
