import { Mutation, MutationCreateLocationArgs } from '../__generated__/graphql';
import { useDispatch, useSelector } from 'react-redux';

import { CREATE_LOCATION } from '../graphql/mutations';
import LocationFormInputs from '../components/LocationForm/@types/LocationFormInputs';
import MutationHookData from './@types/MutationHookData';
import MutationHookParams from './@types/MutationHookParams';
import { RootState } from '../redux/reducers';
import { closeForm } from '../redux/actions/modals.actions';
import { flashApolloError } from '../util/errorUtils';
import generateEmailWithDisplayName from '../util/generators/generateEmailWithDisplayName';
import links from '../util/links';
import pick from 'lodash/pick';
import { useMutation } from '@apollo/client';

type CreateLocationData = Pick<Mutation, 'createLocation'>;

export default function useCreateLocation({
  onCompleted = (): void => {},
  onError = flashApolloError,
}: MutationHookParams<CreateLocationData> = {}): MutationHookData<
  LocationFormInputs,
  CreateLocationData
> {
  const dispatch = useDispatch();
  const me = useSelector((state: RootState) => state.me);
  const activeTenantId = me.activeMembership?.tenantId ?? '';

  const [create, { data, error, loading }] = useMutation<
    CreateLocationData,
    MutationCreateLocationArgs
  >(CREATE_LOCATION, {
    onCompleted: ({ createLocation }) => {
      window.flash({
        link: `${links.paths.LOCATIONS}/${createLocation.id}`,
        message: `Location: ${createLocation.name} created successfully`,
      });

      onCompleted({ createLocation });

      dispatch(closeForm());
    },
    onError,
  });

  return {
    data,
    error,
    loading,
    mutation: (inputs: LocationFormInputs): void => {
      create({
        variables: {
          data: {
            ...pick(inputs, [
              'address',
              'defaultEndTime',
              'defaultStartTime',
              'email',
              'logoUrl',
              'imageUrl',
              'name',
              'phoneNumber',
            ]),
            defaultRequirements: {
              create: inputs.defaultRequirements.values ?? [],
            },
            emailFrom: generateEmailWithDisplayName(inputs.emailFromEmail, inputs.emailFromName),
            tenant: { connect: { id: activeTenantId } },
            timezone: '',
          },
        },
      });
    },
  };
}
