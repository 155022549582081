import { gql } from '../__generated__/gql';

const GET_ACCESS_GRANT_TABLE_DATA = gql(`
  query GetAccessGrantTableData(
    $cursor: AccessGrantWhereUniqueInput
    $distinct: [AccessGrantScalarFieldEnum!]
    $orderBy: [AccessGrantOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: AccessGrantWhereInput
  ) {
    findDashboardAccessGrants(
      cursor: $cursor
      distinct: $distinct
      orderBy: $orderBy
      skip: $skip
      take: $take
      where: $where
    ) {
      ...AccessGrantTableFields
    }
  }
`);

export default GET_ACCESS_GRANT_TABLE_DATA;
