import { gql } from '../__generated__/gql';

const GET_RESOURCE_GROUP = gql(`
  query GetResourceGroup($id: String!) {
    resourceGroup(where: { id: $id }) {
      isDefault
      location {
        id
        name
      }
      name
      resources {
        id
        name
      }
    }
  }
`);

export default GET_RESOURCE_GROUP;
