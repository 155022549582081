import 'react-loading-skeleton/dist/skeleton.css';
import CustomSkeletonGrid from './CustomSkeletonGrid';
import React from 'react';

const LimitKeysSkeleton: React.FC = () => {
  return (
    <>
      <CustomSkeletonGrid
        skeletonDimensionsList={[
          { height: 35, width: 130 },
          { height: 35, width: 190 },
        ]}
        rounded
      />
    </>
  );
};

export default LimitKeysSkeleton;
