import { GracePeriod, Query, QueryGetGracePeriodsConfigArgs } from '../__generated__/graphql';

import GET_GRACE_PERIODS_CONFIG from '../graphql/getGracePeriodsConfig';
import { flashApolloError } from '../util/errorUtils';
import { useQuery } from '@apollo/client';

interface GracePeriodsData {
  gracePeriodsData: GracePeriod | undefined;
  isLoadingGracePeriod: boolean;
}

type GetGracePeriodsConfigData = Pick<Query, 'getGracePeriodsConfig'>;

export default function useGetGracePeriodsData({
  accessGrantId,
  gracePeriodsConfigLevel,
  locationId,
  tenantId,
}: QueryGetGracePeriodsConfigArgs): GracePeriodsData {
  const { data, loading: isLoadingGracePeriod } = useQuery<
    GetGracePeriodsConfigData,
    QueryGetGracePeriodsConfigArgs
  >(GET_GRACE_PERIODS_CONFIG, {
    fetchPolicy: 'network-only',
    onError: flashApolloError,
    variables: {
      accessGrantId,
      gracePeriodsConfigLevel,
      locationId,
      tenantId,
    },
  });

  return {
    gracePeriodsData: data?.getGracePeriodsConfig,
    isLoadingGracePeriod,
  };
}
