const SubmitRowContainerStyle = `
  background-color: transparent;
  border: 0;
  box-shadow: 0;
  justify-content: end;
  margin: 75px 0 0;
  position: fixed;
  bottom: 0;
  padding: 10px 35px;
  right: calc(100vw / 2 - 300px)
`;

export const SubmitRowMultiContainerStyle = `
  background-color: transparent;
  border: 0;
  box-shadow: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export default SubmitRowContainerStyle;
