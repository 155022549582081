import 'styled-components/macro';

import TextContent, { TextContentModel } from '../../TextContent';

import Container from '../../Container';
import DetailsTextStyle from '../styles/DetailsText.style';
import { MdLocationOn } from 'react-icons/md';
import React from 'react';

interface LocationInfoProps {
  locationName: string;
}
const LocationInfo: React.FC<LocationInfoProps> = ({ locationName }: LocationInfoProps) => {
  return (
    <Container css={DetailsTextStyle}>
      <span>
        <MdLocationOn size="22px" />
      </span>{' '}
      <TextContent model={TextContentModel.BODY_LARGE}>{locationName}</TextContent>
    </Container>
  );
};

export default LocationInfo;
