import KiosksLiveUsageReduxState from '../@types/KiosksLiveUsageReduxState';
import { ReservationUpdatedType } from '../../../actions/@types/kiosksLiveUsage/LoadKioskLiveUsageReservationUpdatedDataAction';

export default function loadKioskLiveUsageReservationUpdatedDataAction(
  currentState: KiosksLiveUsageReduxState,
  actionPayload: ReservationUpdatedType
): KiosksLiveUsageReduxState {
  const newState = { ...currentState };
  const { kioskId } = actionPayload;

  if (kioskId) {
    const kioskLiveUsageData = currentState[kioskId]?.data;

    if (kioskLiveUsageData?.accessGrant) {
      // Access Grant already loaded, packages will be stored on Access Grant
      newState[kioskId] = {
        data: {
          ...kioskLiveUsageData,
          accessGrant: {
            ...kioskLiveUsageData.accessGrant,
            bookedPackages: actionPayload.bookedPackages ?? [],
          },
        },
        payload: currentState[kioskId].payload,
      };
    } else {
      // Access Grant not loaded yet, packages will be stored on payload
      newState[kioskId] = {
        data: kioskLiveUsageData,
        payload: {
          ...currentState[kioskId].payload,
          accessGrant: {
            ...currentState[kioskId].payload.accessGrant,
            bookedPackages: actionPayload.bookedPackages ?? [],
            id: actionPayload.accessGrantId,
          },
        },
      };
    }
  }

  return newState;
}
