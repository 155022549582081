import { Colors, RGB } from '../../../theme/colors';

const DetailPageItemStyle = `
  align-content: center;
  background: ${Colors.neroDarkA};
  border-radius: 8px;
  color: ${Colors.white};
  grid-auto-flow: column;
  grid-column-gap: 12px;
  grid-template-columns: 15px 1fr;
  height: 45px;
  margin: 0 5px 5px;
  padding: 10px 15px;
  min-width: 350px;
  align-items: center;

  &.detail-page-item__button {
    justify-content: start;
  }

  &.detail-page-item__button:not(:disabled) {
    background: rgba(${RGB.slateBlue}, 0.4);
    font-weight: bold;
  }

  &.detail-page-item__button:not(:disabled):hover {
    background: rgba(${RGB.slateBlue}, 0.8);
    color: #fff;
  }

  &.detail-page-item__button:disabled {
    background: rgba(${RGB.comet}, 0.25);
    font-weight: normal;
    
    cursor: not-allowed;
    pointer-events: all !important;
  }

  &.detail-page-item__button:disabled:hover {
    background: rgba(${RGB.comet}, 0.25);
    color: #fff;
  }

  &.detail-page-item__button__selected {
    background: rgba(${RGB.slateBlue}, 0.6);
  }

  a {
    color: ${Colors.slateBlue};
  }

  a:hover {
    text-decoration: underline;
  }

  p {
    color: inherit;
  }
`;

export default DetailPageItemStyle;
