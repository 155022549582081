/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3ThemePaymentAuthorizationSchema: NodeSchemaProperties = {
  paymentAuthorizationScreen: {
    title: '#19 Payment Authorization Screen',
    type: 'object',
    properties: {},
  },
};

export const mobileV3ThemePaymentAuthorizationUiSchema: NodeUiSchemaProperties = {
  paymentAuthorizationScreen: {},
};
