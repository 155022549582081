import * as yup from 'yup';

import URL_VALIDATION from '../../../constants/yupValidations/constants/url';

export const RESOURCE_VALIDATION_SCHEMA = yup.object().shape({
  building: yup.string(),
  description: yup.string(),
  floor: yup.string(),
  imageUrl: URL_VALIDATION,
  isComponentRoom: yup.bool(),
  location: yup.object().shape({
    label: yup.string().required('Location is required'),
    value: yup.string().required('Location is required'),
  }),
  name: yup.string().required('Name is required'),
  type: yup.string().required('Type is required'),
  unitType: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }),
});

export const RESOURCE_VENDOR_VALIDATION_SCHEMA = yup.object().shape({
  vendor: yup.string().required('Vendor is required'),
});
