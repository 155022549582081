import {
  AccessGrantState,
  AccessGrantStatus,
  AccessGrantWhereInput,
  RequirementStatus,
  RequirementType,
} from '../../../__generated__/graphql';

import { DataTableFilterValues } from '../../../@types/client/DataTableFilterValues';

export default function parseAccessGrantQueryParameters(
  fieldsFiltered: DataTableFilterValues[]
): Partial<AccessGrantWhereInput> {
  const whereInput: Partial<AccessGrantWhereInput> = {};

  if (fieldsFiltered?.length > 0) {
    // Location filter
    const locFieldsIndex = fieldsFiltered.findIndex((field) => field.fieldKey === 'loc');
    if (locFieldsIndex > -1) {
      const inInput = fieldsFiltered[locFieldsIndex].values;

      whereInput.location = {};
      whereInput.location.is = {};
      whereInput.location.is.id = {};
      whereInput.location.is.id.in = inInput;
    }

    const ivsFieldsIndex = fieldsFiltered.findIndex((field) => field.fieldKey === 'ivs');
    if (ivsFieldsIndex > -1) {
      const inInput = fieldsFiltered[ivsFieldsIndex].values as RequirementStatus[];

      whereInput.requirements = {
        some: {
          status: {
            in: inInput,
          },
          type: {
            equals: RequirementType.IdVerification,
          },
        },
      };
    }

    // Check-in filter
    const ciFieldsIndex = fieldsFiltered.findIndex((field) => field.fieldKey === 'ci');
    if (ciFieldsIndex > -1) {
      const checkInParams = fieldsFiltered[ciFieldsIndex].values.map(
        (num) => parseInt(num, 10) ?? 0
      );
      const maxCheckIn = Math.max(...checkInParams);
      const minCheckIn = Math.min(...checkInParams);

      if (minCheckIn < 0) {
        const gtInput = minCheckIn * 1000 + Date.now();
        const ltInput = Math.max(maxCheckIn, 0) * 1000 + Date.now();
        whereInput.startsAt = {};
        whereInput.startsAt.gt = new Date(gtInput);
        whereInput.startsAt.lt = new Date(ltInput);
      } else {
        const ltInput = maxCheckIn * 1000 + Date.now();
        whereInput.startsAt = {};
        whereInput.startsAt.gt = new Date();
        whereInput.startsAt.lt = new Date(ltInput);
      }
    }

    // Check-out filter
    const coFieldsIndex = fieldsFiltered.findIndex((field) => field.fieldKey === 'co');
    if (coFieldsIndex > -1) {
      const checkOutParams = fieldsFiltered[coFieldsIndex].values.map(
        (num) => parseInt(num, 10) ?? 0
      );
      const maxCheckOut = Math.max(...checkOutParams);
      const ltInput = maxCheckOut * 1000 + Date.now();

      whereInput.endsAt = {};
      whereInput.endsAt.gt = new Date();
      whereInput.endsAt.lt = new Date(ltInput);
    }

    // State filter
    const stateFieldsIndex = fieldsFiltered.findIndex((field) => field.fieldKey === 'state');
    if (stateFieldsIndex > -1) {
      const inInput = fieldsFiltered[stateFieldsIndex].values as AccessGrantState[];

      whereInput.state = {};
      whereInput.state.in = inInput;
    }

    // Status filter
    const statusFieldsIndex = fieldsFiltered.findIndex((field) => field.fieldKey === 'status');
    if (statusFieldsIndex > -1) {
      const inInput = fieldsFiltered[statusFieldsIndex].values as AccessGrantStatus[];

      whereInput.status = {};
      whereInput.status.in = inInput;
    }
  }

  return whereInput;
}
