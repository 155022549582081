import 'styled-components/macro';
import './App.css';
import './reset.css';

import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import AccessGrantPage from './pages/AccessGrant';
import AccessGrantsPage from './pages/AccessGrants';
import AuthRoute from './components/AuthRoute';
import CloudbedsAuthenticationPage from './pages/Marketplace/CloudbedsAuthentication';
import FlashMessage from './components/FlashMessage';
import ForgotPassword from './pages/ForgotPassword';
import KioskPage from './pages/Kiosk';
import KiosksPage from './pages/Kiosks';
import LandingPage from './pages/Landing';
import LocationPage from './pages/Location';
import LocationsPage from './pages/Locations';
import Login from './pages/Login';
import ProtectedRoute from './components/ProtectedRoute';
import RemoteAssistancePage from './pages/RemoteAssistance';
import ReportingPage from './pages/Reporting';
import ResetPassword from './pages/ResetPassword';
import TenantPage from './pages/Tenant';
import UserPage from './pages/User';
import UsersPage from './pages/Users';
import { closeModals } from './redux/actions/modals.actions';
import links from './util/links';
import marketplaceLinks from './util/marketplaceLinks';
import useBindKiosksLiveUsage from './hooks/useBindKiosksLiveUsage';
import useBindRAChannels from './hooks/useBindRAChannels';
import useBindReportingChannels from './hooks/useBindReportingChannels';
import { useDispatch } from 'react-redux';
import useRefreshTokenOnTimeLeftToExpire from './hooks/useRefreshTokenOnTimeLeftToExpire';

const App: React.FC<{}> = (): React.ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();

  useBindReportingChannels();
  useBindRAChannels();
  useRefreshTokenOnTimeLeftToExpire();
  useBindKiosksLiveUsage();

  useEffect(() => {
    return history.listen(() => {
      if (history.action === 'POP') {
        dispatch(closeModals());
      }
    });
  });

  return (
    <>
      <FlashMessage />
      <Switch>
        <Route path={`${links.paths.LOCATIONS}/:locationId`} exact>
          <ProtectedRoute>
            <LocationPage />
          </ProtectedRoute>
        </Route>
        <Route path={`${links.paths.LOCATIONS}`} exact>
          <ProtectedRoute>
            <LocationsPage />
          </ProtectedRoute>
        </Route>
        <Route path={`${links.paths.ACCESS_GRANTS}/:accessGrantId`} exact>
          <ProtectedRoute>
            <AccessGrantPage />
          </ProtectedRoute>
        </Route>
        <Route path={`${links.paths.ACCESS_GRANTS}`} exact>
          <ProtectedRoute>
            <AccessGrantsPage />
          </ProtectedRoute>
        </Route>
        <Route path={`${links.paths.USERS}/:userId`} exact>
          <ProtectedRoute>
            <UserPage />
          </ProtectedRoute>
        </Route>
        <Route path={`${links.paths.USERS}`} exact>
          <ProtectedRoute>
            <UsersPage />
          </ProtectedRoute>
        </Route>
        <Route path={`${links.paths.KIOSKS}/:kioskId`} exact>
          <ProtectedRoute>
            <KioskPage />
          </ProtectedRoute>
        </Route>
        <Route path={`${links.paths.KIOSKS}`} exact>
          <ProtectedRoute>
            <KiosksPage />
          </ProtectedRoute>
        </Route>
        <Route path={`${links.paths.REMOTE_ASSISTANCE}`} exact>
          <ProtectedRoute>
            <RemoteAssistancePage />
          </ProtectedRoute>
        </Route>
        <Route path={`${links.paths.REPORTING}`} exact>
          <ProtectedRoute>
            <ReportingPage />
          </ProtectedRoute>
        </Route>
        <Route path={`${links.paths.TENANT}/:tenantId`} exact>
          <ProtectedRoute>
            <TenantPage />
          </ProtectedRoute>
        </Route>
        <Route
          path={`${links.paths.MARKETPLACE}${marketplaceLinks.marketplacePaths.CLOUDBEDS}`}
          exact
        >
          <ProtectedRoute>
            <CloudbedsAuthenticationPage />
          </ProtectedRoute>
        </Route>
        <Route path={links.paths.LOGIN} exact>
          <AuthRoute>
            <Login />
          </AuthRoute>
        </Route>
        <Route path={links.paths.FORGOT_PASSWORD} exact>
          <AuthRoute>
            <ForgotPassword />
          </AuthRoute>
        </Route>
        <Route path={links.paths.RESET_PASSWORD} exact>
          <AuthRoute>
            <ResetPassword />
          </AuthRoute>
        </Route>
        <Route path={links.paths.HOME} exact>
          <ProtectedRoute>
            <LandingPage />
          </ProtectedRoute>
        </Route>
        <Redirect to={links.paths.HOME} />
      </Switch>
    </>
  );
};

export default App;
