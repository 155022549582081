import { gql } from '../__generated__/gql';

const DELETE_AMENITY = gql(`
  mutation DeleteOneAmenity($where: AmenityWhereUniqueInput!) {
    deleteOneAmenity(where: $where) {
      id
      title
    }
  }
`);

export default DELETE_AMENITY;
