/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3ThemeGuestsSchema: NodeSchemaProperties = {
  guests: {
    title: '#21 Guests',
    type: 'object',
    properties: {
      guestContainer: {
        title: '#1 Guest Container',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[guests.guestContainer.backgroundColor]',
            title: '#1.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'borderColor:enable': {
            description: '[guests.guestContainer.borderColor]',
            title: '#1.2 Border Color',
            type: 'boolean',
          },
          borderColor: {
            type: 'string',
          },
          'borderWidth:enable': {
            description: '[guests.guestContainer.borderWidth]',
            title: '#1.3 Border Width',
            type: 'boolean',
          },
          borderWidth: {
            type: 'integer',
          },
          'borderRadius:enable': {
            description: '[guests.guestContainer.borderRadius]',
            title: '#1.4 Border Radius',
            type: 'boolean',
          },
          borderRadius: {
            type: 'integer',
          },
        },
      },
      userAvatar: {
        title: '#2 User Avatar',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[guests.userAvatar.backgroundColor]',
            title: '#2.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'borderColor:enable': {
            description: '[guests.userAvatar.borderColor]',
            title: '#2.2 Border Color',
            type: 'boolean',
          },
          borderColor: {
            type: 'string',
          },
          'borderWidth:enable': {
            description: '[guests.userAvatar.borderWidth]',
            title: '#2.3 Border Width',
            type: 'boolean',
          },
          borderWidth: {
            type: 'integer',
          },
          'borderRadius:enable': {
            description: '[guests.userAvatar.borderRadius]',
            title: '#2.4 Border Radius',
            type: 'boolean',
          },
          borderRadius: {
            type: 'integer',
          },
          'textColor:enable': {
            description: '[guests.userAvatar.textColor]',
            title: '#2.5 Text Color',
            type: 'boolean',
          },
          textColor: {
            type: 'string',
          },
        },
      },
    },
  },
};

export const mobileV3ThemeGuestsUiSchema: NodeUiSchemaProperties = {
  guests: {
    guestContainer: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderWidth: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
      borderRadius: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
    },
    userAvatar: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderWidth: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
      borderRadius: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
  },
};
