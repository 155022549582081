import { Header } from '../../../components/DataTable/@types/DataTableProps';
import SortType from '../../../components/DataTable/@types/SortType.enum';

const ACCESS_GRANTS_TABLE_HEADERS: Header[] = [
  { displayValue: 'Guest', isSortable: false, value: 'user.name' },
  { displayValue: 'State', isSortable: true, sortType: SortType.SortOrderInput, value: 'state' },
  {
    displayValue: 'Confirmation #',
    isSortable: true,
    sortType: SortType.SortOrderInput,
    value: 'confirmationCode',
  },
  { displayValue: 'ETA', isSortable: false },
  { displayValue: 'Location', isSortable: false, value: 'location.name' },
  { displayValue: 'Start', isSortable: true, sortType: SortType.SortOrder, value: 'startsAt' },
  { displayValue: 'End', isSortable: true, sortType: SortType.SortOrder, value: 'endsAt' },
  { displayValue: 'In/Out', isSortable: false },
  { displayValue: 'LastSeenAt', isSortable: false, value: 'lastSeenAt' },
  { displayValue: 'Status', isSortable: false, value: 'status' },
];

export default ACCESS_GRANTS_TABLE_HEADERS;
