import 'styled-components/macro';
import Button from '../Button';
import ButtonModel from '../Button/enums/ButtonModel.enum';
import FormSectionButtonProps from './@types/FormSectionButtonProps';
import FormSectionButtonStyle from './styles/FormSectionButton.style';
import React from 'react';

const FormSectionButton: React.FC<FormSectionButtonProps> = ({
  active = false,
  disabled = false,
  formSection,
  index,
  setFormSection,
}: FormSectionButtonProps) => {
  function handleClick(): void {
    setFormSection(index);
  }

  return (
    <Button
      active={active}
      css={FormSectionButtonStyle}
      disabled={disabled}
      model={ButtonModel.STEP}
      onClick={handleClick}
    >
      <span>{index + 1}</span>
      <span>{formSection.name}</span>
    </Button>
  );
};

export default FormSectionButton;
