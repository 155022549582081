import { DisplayHeaderMap } from '../@types/DisplayHeader';
import React from 'react';

interface TableDataContextProps {
  displayHeaders: DisplayHeaderMap;
  setDisplayHeaders: React.Dispatch<React.SetStateAction<DisplayHeaderMap>>;
}

const TableDataContext = React.createContext<TableDataContextProps | undefined>(undefined);

export default TableDataContext;
