import { DataTableAction } from '../../actions/dataTable.actions';
import DataTableActionEnum from '../../../enums/DataTableAction.enum';
import DataTableReduxState from '../@types/DataTableReduxState';
import clearFilterAction from './functions/clearFilter';
import clearSortAction from './functions/clearSortOptions';
import setFilterAction from './functions/setFilter';
import setSortAction from './functions/setSortOptions';

const initialState: DataTableReduxState = {
  filters: {},
  sortOptions: {},
};

export default function dataTableReducer(
  state: DataTableReduxState = initialState,
  action: DataTableAction
): DataTableReduxState {
  switch (action.type) {
    case DataTableActionEnum.SET_FILTER: {
      return setFilterAction(state, action.payload);
    }

    case DataTableActionEnum.CLEAR_FILTER: {
      return clearFilterAction(state, action.payload);
    }

    case DataTableActionEnum.SET_SORT_OPTIONS_ORDER: {
      return setSortAction(state, action.payload);
    }

    case DataTableActionEnum.CLEAR_SORT_OPTIONS_ORDER: {
      return clearSortAction(state, action.payload);
    }

    default: {
      return state;
    }
  }
}
