import {
  CustomServiceCreateInput,
  Mutation,
  MutationCreateServiceAndUploadImageFileArgs,
} from '../__generated__/graphql';

import { CREATE_SERVICE } from '../graphql/createService';
import MutationHookData from './@types/MutationHookData';
import MutationHookParams from './@types/MutationHookParams';
import { apolloRequirePreflightHeader } from '../client';
import { closeForm } from '../redux/actions/modals.actions';
import { flashApolloError } from '../util/errorUtils';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';

type CreateServiceData = Pick<Mutation, 'createServiceAndUploadImageFile'>;

export default function useCreateServiceAndUploadImage({
  onCompleted = (): void => {},
}: MutationHookParams<CreateServiceData> = {}): MutationHookData<
  CustomServiceCreateInput,
  CreateServiceData
> {
  const dispatch = useDispatch();

  const [create, { data, error, loading }] = useMutation<
    CreateServiceData,
    MutationCreateServiceAndUploadImageFileArgs
  >(CREATE_SERVICE, {
    context: {
      headers: apolloRequirePreflightHeader,
    },
    onCompleted: ({ createServiceAndUploadImageFile }) => {
      window.flash({
        message: `Service: ${createServiceAndUploadImageFile.title} created successfully`,
      });

      onCompleted({ createServiceAndUploadImageFile });

      dispatch(closeForm());
    },
    onError: flashApolloError,
  });

  return {
    data,
    error,
    loading,
    mutation: (inputs: CustomServiceCreateInput): void => {
      create({
        variables: {
          data: inputs,
        },
      });
    },
  };
}
