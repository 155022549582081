import {
  CustomImageInput,
  CustomInfoItemCreateInput,
  InputMaybe,
  TypeOfInfoCreateNestedOneWithoutInfoInput,
} from '../../../__generated__/graphql';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { ApolloError } from '@apollo/client';
import FormCrudAction from '../../../enums/FormCrudAction.enum';
import { ImageTypeEnum } from '../../UnitTypeForm/enums/ImageTypeEnum.enum';
import InfoItemFormInputs from '../@types/InfoItemFormInputs';
import { RootState } from '../../../redux/reducers';
import { flashApolloError } from '../../../util/errorUtils';
import { openConfirmationModal } from '../../../redux/actions/modals.actions';
import useCreateInfoItemAndUploadImage from './useCreateInfoItemAndUploadImage';
import useUpdateInfoItemAndUploadImage from './useUpdateInfoItemAndUploadImage';

interface UseSubmitInfoItemInterface {
  loadingMutation: boolean;
  submitHandler: SubmitHandler<InfoItemFormInputs>;
}

const useSubmitInfoItem = (): UseSubmitInfoItemInterface => {
  // Form properties
  const dispatch = useDispatch();
  const id = useSelector((state: RootState) => state.modals.form.id);
  const crudAction = id ? FormCrudAction.EDIT : FormCrudAction.CREATE;
  const { setError } = useFormContext();

  // Mutations - Create/Update Info Item
  const useMutation =
    crudAction === FormCrudAction.CREATE
      ? useCreateInfoItemAndUploadImage
      : useUpdateInfoItemAndUploadImage;

  const { mutation, loading: loadingMutation } = useMutation();

  // Submit
  const submitHandler: SubmitHandler<InfoItemFormInputs> = (inputs: InfoItemFormInputs): void => {
    const { imageFile, imageUrl, ...mutationInputs } = inputs;

    // Image validations
    const { imageType } = mutationInputs;
    let imageErrorField;
    if (imageType === ImageTypeEnum.URL && (!imageUrl || imageUrl.length === 0)) {
      imageErrorField = 'imageUrl';
    } else if (imageType === ImageTypeEnum.UPLOAD && !imageFile) {
      imageErrorField = 'imageFile';
    }

    if (imageErrorField) {
      setError(imageErrorField, { message: 'Image is required.', types: { required: true } });
      return;
    }

    try {
      delete mutationInputs.imageType;

      const imageInput: CustomImageInput = {
        imageFile: imageType === ImageTypeEnum.UPLOAD ? imageFile : undefined,
        imageUrl: imageType === ImageTypeEnum.URL ? imageUrl : undefined,
      };

      let infoType: InputMaybe<TypeOfInfoCreateNestedOneWithoutInfoInput> = {};
      if (mutationInputs?.infoType) {
        infoType = {
          connect: { id: mutationInputs.infoType.value },
        };
      }

      const infoItemCreateInput: CustomInfoItemCreateInput = ({
        ...mutationInputs,
        image: imageInput,
        infoType,
        location: {
          connect: {
            id: mutationInputs.location.value,
          },
        },
      } as unknown) as CustomInfoItemCreateInput;

      dispatch(
        openConfirmationModal({
          action: (): void => {
            if (id) {
              mutation(infoItemCreateInput, id);
            } else {
              mutation(infoItemCreateInput);
            }
          },
        })
      );
    } catch (e) {
      flashApolloError(e as ApolloError);
    }
  };

  return {
    loadingMutation,
    submitHandler,
  };
};

export default useSubmitInfoItem;
