import { useHistory, useLocation } from 'react-router';

import links from '../util/links';
import { logout } from '../redux/actions/me.actions';
import { useDispatch } from 'react-redux';

export default function useLogoutOfApp(): (state?: unknown) => void {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  return (state?: unknown): void => {
    if (location.pathname !== links.paths.LOGIN) {
      localStorage.clear();
      dispatch(logout());
      history.push(links.paths.LOGIN, state);
    }
  };
}
