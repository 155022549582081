import 'styled-components/macro';

import { AccessGrant, Maybe, User } from '../../../__generated__/graphql';
import Checkbox, { CheckboxModel } from '../../../components/Checkbox';
import Dropdown, { ReferenceDropdownInterface } from '../../../components/Dropdown';
import Heading, { HeadingModel } from '../../../components/Heading';
import React, { createRef, useEffect, useState } from 'react';

import Button from '../../../components/Button';
import ButtonModel from '../../../components/Button/enums/ButtonModel.enum';
import DropdownButtonStyle from '../../../components/Dropdown/styles/DropdownButton.style';
import { SEND_NOTIFICATION_TO_EXTERNAL_API } from '../../../graphql/sendNotificationToExternalAPI';
import { flashApolloError } from '../../../util/errorUtils';
import { useMutation } from '@apollo/client';

type BasicUserInfo = Pick<User, 'id' | 'email' | 'name'>;

interface NotificationsTabProps {
  accessGrant: Pick<AccessGrant, 'id'> & {
    secondaryUsers?: Maybe<Array<{ user: BasicUserInfo }>>;
    user: BasicUserInfo;
  };
}

interface SendNotificationToExternalAPInterface {
  sendNotificationToExternalAPI: boolean;
}

export const NotificationsTab: React.FC<NotificationsTabProps> = ({
  accessGrant,
}: NotificationsTabProps) => {
  const { id: accessGrantId, user, secondaryUsers } = accessGrant;
  const dropdownRef = createRef<React.ReactElement>();

  const secondaryUsersBasicInfo: BasicUserInfo[] = !secondaryUsers
    ? []
    : secondaryUsers
        ?.map((u) => u.user)
        .sort((uA, uB) => (uA.name ?? '').localeCompare(uB.name ?? ''));

  const users = [user, ...secondaryUsersBasicInfo];

  const [userChecked, setUserChecked] = useState<string | undefined>();

  useEffect(() => {
    if (users.length === 1) {
      setUserChecked(user.id);
    }
  }, [users]);

  const [sendNotificationToExternalAPI, { loading }] = useMutation<
    SendNotificationToExternalAPInterface,
    { accessGrantId: string; userId: string }
  >(SEND_NOTIFICATION_TO_EXTERNAL_API, {
    onCompleted: (result) => {
      const message = result.sendNotificationToExternalAPI
        ? `Notification successfully sent to external API`
        : `Failure on send notification to external API`;

      window.flash({
        message,
      });
    },
    onError: flashApolloError,
  });

  return (
    <Dropdown ref={dropdownRef} label="Send notification to external API">
      <div
        css={`
          display: flex;
          flex-direction: column;
          min-height: auto;
          margin: 5px;
        `}
      >
        <div>
          <Heading
            css={`
              text-align: center;
            `}
            model={HeadingModel.TERTIARY}
          >
            Guest
          </Heading>
          {users.map((_user) => (
            <Checkbox
              checked={userChecked === _user.id}
              label={_user.name ?? _user.id}
              key={_user.id}
              model={CheckboxModel.TABLE_DARK}
              onClick={(): void => setUserChecked(_user.id)}
              css={`
                margin: 3px;
              `}
            />
          ))}
        </div>
        <div
          style={{
            alignSelf: 'center',
            marginTop: '5px',
          }}
        >
          <Button
            css={DropdownButtonStyle}
            style={{
              display: 'flex',
              flex: 1,
            }}
            model={ButtonModel.DEFAULT}
            disabled={!userChecked || loading}
            onClick={(): void => {
              if (userChecked) {
                sendNotificationToExternalAPI({
                  variables: { accessGrantId, userId: userChecked },
                });

                if (dropdownRef.current) {
                  // eslint-disable-next-line max-len
                  const dropdownCurrentRef = (dropdownRef.current as unknown) as ReferenceDropdownInterface;
                  dropdownCurrentRef.close();
                }
                setUserChecked(undefined);
              }
            }}
          >
            Send notification
          </Button>
        </div>
      </div>
    </Dropdown>
  );
};
