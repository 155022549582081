import InputGroupProps from '../../InputGroup/@types/InputGroupProps';

export default function generateUnitTypeLocalizationInputs(index: number): InputGroupProps[] {
  const inputGroups: InputGroupProps[] = [
    {
      label: 'Name',
      name: `languages.values[${index}].name`,
      required: true,
      type: 'text',
    },
    {
      label: 'Description',
      name: `languages.values[${index}].description`,
      required: false,
      type: 'text',
    },
  ];

  return inputGroups;
}
