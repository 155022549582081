import { PaymentTransactionWhereInput } from '../../../__generated__/graphql';
import SearchField from '../../../@types/SearchField';
import createSearchField from '../../../util/tableData/createSearchField';
import transformStringFilter from '../../../util/tableData/transformStringFilter';

const PAYMENT_TRANSACTIONS_TABLE_SEARCH_FIELDS: SearchField<PaymentTransactionWhereInput>[] = [
  createSearchField<PaymentTransactionWhereInput, 'invoiceNumber'>({
    label: 'Invoice number',
    queryField: 'invoiceNumber',
    transform: transformStringFilter,
  }),
];

export default PAYMENT_TRANSACTIONS_TABLE_SEARCH_FIELDS;
