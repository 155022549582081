import { Datum } from '../../../components/DataTable';
import { Query } from '../../../__generated__/graphql';

type GetResourceTableData = Pick<Query, 'resources'>;

export default function generateResourcesTableData(data?: GetResourceTableData): Datum[] {
  const tableData =
    data?.resources.map((resource) => ({
      dataType: 'Resource',
      fields: [
        {
          key: 'Name',
          value: resource.name,
        },
        {
          key: 'Description',
          value: resource.description || '',
        },
        {
          key: 'Unit Type',
          value: resource.unitType?.type || '',
        },
        {
          key: 'Vendor',
          value: resource.vendor || 'N/A',
        },
        {
          key: 'Vendor ID',
          value: resource.vendorId || 'N/A',
        },
        {
          key: 'PMS ID',
          value: resource.pmsId || 'N/A',
        },
        {
          key: 'Image URL',
          ...(resource.imageUrl && { href: resource.imageUrl }),
          value: resource.imageUrl || '',
        },
      ],
      key: resource.id,
    })) || [];

  return tableData;
}
