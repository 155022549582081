/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3ThemeCheckOutSchema: NodeSchemaProperties = {
  checkoutScreen: {
    title: '#23 Checkout Screen',
    type: 'object',
    properties: {
      'reservationNumberTextColor:enable': {
        description: '[checkoutScreen.reservationNumberTextColor]',
        title: '#1 Reservation Number Text Color',
        type: 'boolean',
      },
      reservationNumberTextColor: {
        type: 'string',
      },
      'dividerColor:enable': {
        description: '[checkoutScreen.dividerColor]',
        title: '#2 Divider Color',
        type: 'boolean',
      },
      dividerColor: {
        type: 'string',
      },
      'footerBackgroundColor:enable': {
        description: '[checkoutScreen.footerBackgroundColor]',
        title: '#3 Footer Background Color',
        type: 'boolean',
      },
      footerBackgroundColor: {
        type: 'string',
      },
      'successBottomSheetIconUrl:enable': {
        description: '[checkoutScreen.successBottomSheetIconUrl]',
        title: '#4 Success Bottom Sheet Icon Url',
        type: 'boolean',
      },
      successBottomSheetIconUrl: {
        type: 'string',
      },
      'errorBottomSheetIconUrl:enable': {
        description: '[checkoutScreen.errorBottomSheetIconUrl]',
        title: '#5 Error Bottom Sheet Icon Url',
        type: 'boolean',
      },
      errorBottomSheetIconUrl: {
        type: 'string',
      },
      'infoTextColor:enable': {
        description: '[checkoutScreen.infoTextColor]',
        title: '#6 Info text color',
        type: 'boolean',
      },
      infoTextColor: {
        type: 'string',
      },
      'hyperlinkColor:enable': {
        description: '[checkoutScreen.hyperlinkColor]',
        title: '#7 Hyperlink color',
        type: 'boolean',
      },
      hyperlinkColor: {
        type: 'string',
      },
      box: {
        title: '#8 Box',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[home.box.backgroundColor]',
            title: '#8.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'borderColor:enable': {
            description: '[checkoutScreen.box.borderColor]',
            title: '#8.2 Border Color',
            type: 'boolean',
          },
          borderColor: {
            type: 'string',
          },
          'borderWidth:enable': {
            description: '[checkoutScreen.box.borderWidth]',
            title: '#8.3 Border Width',
            type: 'boolean',
          },
          borderWidth: {
            type: 'integer',
          },
          'borderRadius:enable': {
            description: '[checkoutScreen.box.borderRadius]',
            title: '#8.4 Border Radius',
            type: 'boolean',
          },
          borderRadius: {
            type: 'number',
          },
        },
      },
      folioQuantity: {
        title: '#9 Folio Quanitity',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[checkoutScreen.folioQuantity.backgroundColor]',
            title: '#9.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'textColor:enable': {
            description: '[checkoutScreen.folioQuantity.textColor]',
            title: '#9.2 Text Color',
            type: 'boolean',
          },
          textColor: {
            type: 'string',
          },
        },
      },
      folioTable: {
        title: '#10 Folio Table',
        type: 'object',
        properties: {
          'backgroundColor:enable': {
            description: '[checkoutScreen.folioTable.backgroundColor]',
            title: '#10.1 Background Color',
            type: 'boolean',
          },
          backgroundColor: {
            type: 'string',
          },
          'descriptionTextColor:enable': {
            description: '[checkoutScreen.folioTable.descriptionTextColor]',
            title: '#10.2 Description text Color',
            type: 'boolean',
          },
          descriptionTextColor: {
            type: 'string',
          },
        },
      },
      'disableCheckOutScreen:enable': {
        description: '[checkoutScreen.disableCheckOutScreen]',
        title: '#11 Disable checkout screen (Folio), just show the success or error bottom sheet.',
        type: 'boolean',
      },
      disableCheckOutScreen: {
        type: undefined,
        enum: [true, false],
      },
      'checkoutScreenType:enable': {
        description: '[checkoutScreen.checkoutScreenType]',
        title: '#12 Checkout Screen type',
        type: 'boolean',
      },
      checkoutScreenType: {
        enum: ['CHECKOUT', 'FOLIO'],
        type: 'string',
      },
    },
  },
};

export const mobileV3ThemeCheckOutUiSchema: NodeUiSchemaProperties = {
  checkoutScreen: {
    errorContainerBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    errorContainerTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    errorContainerIcon: {
      'ui:label': false,
      'ui:readonly': true,
    },
    reservationNumberBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    reservationNumberTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    dividerColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    footerBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    successBottomSheetIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    errorBottomSheetIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'link',
      'ui:widget': 'uri',
    },
    infoTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    hyperlinkColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    box: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderWidth: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'updown',
      },
      borderRadius: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    folioQuantity: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    folioTable: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      descriptionTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    disableCheckOutScreen: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    checkoutScreenType: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
  },
};
