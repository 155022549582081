import { AuthAnswer } from '../../../__generated__/graphql';

export function onTokenReceived(authData?: AuthAnswer): void {
  const oldToken = localStorage.getItem('ACCESS_TOKEN');
  const newToken = authData?.accessToken;

  if (authData && newToken && oldToken !== newToken) {
    if ([authData.expiresIn, authData.accessToken, authData.refreshToken].every((field) => field)) {
      const expiresIn = Number(authData.expiresIn!);
      const expirationDate = new Date(Date.now() + expiresIn * 1000);
      localStorage.setItem('ACCESS_TOKEN', authData.accessToken!);
      localStorage.setItem('REFRESH_TOKEN', authData.refreshToken!);
      localStorage.setItem('TOKEN_EXPIRY', expirationDate.toJSON());
    }
  }
}
