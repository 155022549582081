/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3LocalizationExpectedArrivalSchema: NodeSchemaProperties = {
  expectedArrivalScreen: {
    title: '#13 Expected Arrival Screen',
    type: 'object',
    properties: {
      'title:enable': {
        description: '[expectedArrivalScreen.title]',
        title: '#1 Title',
        type: 'boolean',
      },
      title: {
        type: 'string',
      },
      'description:enable': {
        description: '[expectedArrivalScreen.description]',
        title: '#2 Description',
        type: 'boolean',
      },
      description: {
        type: 'string',
      },
      'submitButton:enable': {
        description: '[expectedArrivalScreen.submitButton]',
        title: '#3 Submit Button',
        type: 'boolean',
      },
      submitButton: {
        type: 'string',
      },
      'alertSuccessTitle:enable': {
        description: '[expectedArrivalScreen.alertSuccessTitle]',
        title: '#4 Alert Success Title',
        type: 'boolean',
      },
      alertSuccessTitle: {
        type: 'string',
      },
      'alertSuccessDescription:enable': {
        description: '[expectedArrivalScreen.alertSuccessDescription]',
        title: '#5 Alert Success Description',
        type: 'boolean',
      },
      alertSuccessDescription: {
        type: 'string',
      },
      'alertSuccessOkButton:enable': {
        description: '[expectedArrivalScreen.alertSuccessOkButton]',
        title: '#6 Alert Success Ok Button',
        type: 'boolean',
      },
      alertSuccessOkButton: {
        type: 'string',
      },
      'alertErrorTitle:enable': {
        description: '[expectedArrivalScreen.alertErrorTitle]',
        title: '#7 Alert Error Title',
        type: 'boolean',
      },
      alertErrorTitle: {
        type: 'string',
      },
      'alertErrorDescription:enable': {
        description: '[expectedArrivalScreen.alertErrorDescription]',
        title: '#8 Alert Error Description',
        type: 'boolean',
      },
      alertErrorDescription: {
        type: 'string',
      },
      'alertErrorOkButton:enable': {
        description: '[expectedArrivalScreen.alertErrorOkButton]',
        title: '#9 Alert Error Ok Button',
        type: 'boolean',
      },
      alertErrorOkButton: {
        type: 'string',
      },
      'alertConfirmationTitle:enable': {
        description: '[expectedArrivalScreen.alertConfirmationTitle]',
        title: '#10 Alert Confirmation Title',
        type: 'boolean',
      },
      alertConfirmationTitle: {
        type: 'string',
      },
      'alertConfirmationDescription:enable': {
        description: '[expectedArrivalScreen.alertConfirmationDescription]',
        title: '#11 Alert Confirmation Description',
        type: 'boolean',
      },
      alertConfirmationDescription: {
        type: 'string',
      },
      'alertConfirmationCancelButton:enable': {
        description: '[expectedArrivalScreen.alertConfirmationCancelButton]',
        title: '#12 Alert Confirmation Cancel Button',
        type: 'boolean',
      },
      alertConfirmationCancelButton: {
        type: 'string',
      },
      'alertConfirmationApplyButton:enable': {
        description: '[expectedArrivalScreen.alertConfirmationApplyButton]',
        title: '#13 Alert Confirmation Apply Button',
        type: 'boolean',
      },
      alertConfirmationApplyButton: {
        type: 'string',
      },
      'switchLabel:enable': {
        description: '[expectedArrivalScreen.switchLabel]',
        title: '#14 Switch Label',
        type: 'boolean',
      },
      switchLabel: {
        type: 'string',
      },
    },
  },
};

export const mobileV3LocalizationExpectedArrivalUiSchema: NodeUiSchemaProperties = {
  expectedArrivalScreen: {
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    submitButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertSuccessTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertSuccessDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertSuccessOkButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertErrorTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertErrorDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertErrorOkButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertConfirmationTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertConfirmationDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertConfirmationCancelButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertConfirmationApplyButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    switchLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
};
