import { ActionBarAuthenticationCredentials } from '../../../assets/icons/actionBarAuthenticationCredentials';
import { ActionBarBlockCheckIn } from '../../../assets/icons/actionBarBlockCheckIn';
import { ActionBarCheckIn } from '../../../assets/icons/actionBarCheckIn';
import { ActionBarCheckOut } from '../../../assets/icons/actionBarCheckOut';
import { ActionBarDispenseKey } from '../../../assets/icons/actionBarDispenseKey';
import { ActionBarModalErrorIcon } from '../../../assets/icons/actionBarModalErrorIcon';
import { ActionBarRePoll } from '../../../assets/icons/actionBarRePoll';
import { QuickActionCTAType } from '../@types/QuickActionBarTypes';
import React from 'react';

export const CTAIcon: React.FC<{ type: QuickActionCTAType; color?: string }> = ({
  type,
  color,
}) => {
  const getIconFromCtaType = (): JSX.Element => {
    switch (type) {
      case 'checkIn':
        return <ActionBarCheckIn color={color} />;
      case 'checkOut':
        return <ActionBarCheckOut color={color} />;
      case 'blockCheckIn':
        return <ActionBarBlockCheckIn color={color} />;
      case 'getAutenticationCredentials':
        return <ActionBarAuthenticationCredentials color={color} />;
      case 'remotePrintKeyCard':
        return <ActionBarDispenseKey color={color} />;
      case 'repollReservation':
        return <ActionBarRePoll color={color} />;
      default:
        return <ActionBarModalErrorIcon />;
    }
  };

  return getIconFromCtaType();
};
