import { Header } from '../../../components/DataTable/@types/DataTableProps';
import SortType from '../../../components/DataTable/@types/SortType.enum';

const AMENITIES_TABLE_HEADERS: Header[] = [
  { displayValue: 'Title', isSortable: true, sortType: SortType.SortOrder, value: 'title' },
  {
    displayValue: 'Description',
    isSortable: true,
    sortType: SortType.SortOrderInput,
    value: 'description',
  },
  { displayValue: 'Image URL', isSortable: false },
  { displayValue: 'Group', isSortable: false, value: 'group.name' },
];

export default AMENITIES_TABLE_HEADERS;
