import DetailPageSummaryContainerStyle, {
  horizontalPadding,
  verticalPadding,
} from './DetailPageSummaryContainer.style';

import CSS from 'csstype';

const CrudButtonsStyle = `
  ${DetailPageSummaryContainerStyle}
  padding: 10px ${horizontalPadding} ${verticalPadding} ${horizontalPadding};

  .edit-button, .remove-button {
    padding: 0 15px 0 15px;
    font-weight: 500;
    border-radius: 10px;
    gap: 5px;
  }
`;

export const CrudButtonsInnerContainerStyle: CSS.Properties = {
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  gap: '10px',
  marginLeft: '5px',
};

export default CrudButtonsStyle;
