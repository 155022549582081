import { Datum } from '../../../components/DataTable';
import { Query } from '../../../__generated__/graphql';
import { ReduxMembership } from '../../../redux/reducers/@types/MeReduxState';
import { UseDateTimeFormatterInterface } from '../../useDateTimeFormatter';
import links from '../../../util/links';

type GetUsersTableData = Pick<Query, 'users'>;

export default function generateUsersTableData(
  activeMembership: ReduxMembership,
  formatter: UseDateTimeFormatterInterface,
  data?: GetUsersTableData
): Datum[] {
  const { formatDate } = formatter;

  const tableData =
    data?.users.map((user) => {
      const relevantMembership = user.memberships.find((membership) => {
        if (activeMembership.location?.id) {
          return membership.locationId === activeMembership.location?.id;
        }

        return membership.tenantId === activeMembership.tenant.id;
      });

      return {
        dataType: 'User',
        fields: [
          {
            key: 'Name',
            link: `${links.paths.USERS}/${user.id}`,
            value: user.name || 'Unknown',
          },
          {
            key: 'Email',
            value: user.email || 'Unknown',
          },
          {
            key: 'Phone Number',
            value: user.phoneNumber || 'Unknown',
          },
          {
            key: 'Role',
            value: relevantMembership?.role ?? 'Unknown',
          },
          {
            key: 'Last Seen',
            value: user.lastSeenAt ? formatDate(user.lastSeenAt) : 'N/A',
          },
        ],
        key: user.id,
      };
    }) || [];

  return tableData;
}
