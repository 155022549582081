import { gql } from '../__generated__/gql';

const GET_RESOURCE = gql(`
  query GetResource($where: ResourceWhereUniqueInput!) {
    resource(where: $where) {
      building
      controlType
      defaultEnd
      defaultStart
      description
      floor
      id
      imageUrl
      locationId
      location {
        id
        name
      }
      name
      passLevel
      pmsId
      type
      unitType {
        id
        type
      }
      vendor
      vendorId
      code
      isComponentRoom
    }
  }
`);

export default GET_RESOURCE;
