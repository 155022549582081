import { orderByEmail, orderByGuestName, orderByLastSeen, orderByPhoneNumber } from './sortFields';

import GuestOnAGOrderByInput from '../@types/GuestOnAGOrderByInput';
import { GuestsDetailsOnAgSortableFields } from '../constants/GuestsDetailsOnAGTableHeaders';
import { UserOnAG } from '../../../pages/AccessGrant/@types/GetAccessGrantPageInfo';

export const sortData = (guests: UserOnAG[], orderBy?: GuestOnAGOrderByInput[]): UserOnAG[] => {
  let newGuests: UserOnAG[] = [...guests];

  if (orderBy) {
    orderBy.forEach((orderByField) => {
      const [field, type] = Object.entries(orderByField)[0];

      switch (field as GuestsDetailsOnAgSortableFields) {
        case GuestsDetailsOnAgSortableFields.GUEST_NAME:
          newGuests = newGuests.sort((a, b) => orderByGuestName(a, b, type));
          break;

        case GuestsDetailsOnAgSortableFields.EMAIL:
          newGuests = newGuests.sort((a, b) => orderByEmail(a, b, type));
          break;

        case GuestsDetailsOnAgSortableFields.PHONE_NUMBER:
          newGuests = newGuests.sort((a, b) => orderByPhoneNumber(a, b, type));
          break;

        case GuestsDetailsOnAgSortableFields.LAST_SEEN:
          newGuests = newGuests.sort((a, b) => orderByLastSeen(a, b, type));
          break;

        default:
          break;
      }
    });
  }

  return newGuests;
};
