import CredentialBeingProcessedAction from './@types/pendingTasks/CredentialBeingProcessedAction';
import CredentialProcessedAction from './@types/pendingTasks/CredentialProcessedAction';
import PendingTasksActionEnum from '../../enums/PendingTasksAction.enum';

export const credentialBeingProcessed = (credentialId: string): CredentialBeingProcessedAction => ({
  payload: {
    credentialId,
  },
  type: PendingTasksActionEnum.CREDENTIAL_BEING_PROCESSED,
});

export const credentialProcessed = (credentialId: string): CredentialProcessedAction => ({
  payload: {
    credentialId,
  },
  type: PendingTasksActionEnum.CREDENTIAL_PROCESSED,
});

export type PendingTasksAction = CredentialBeingProcessedAction | CredentialProcessedAction;
