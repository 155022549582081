import { Colors } from '../../theme/colors';
import styled from 'styled-components';

const CancelButton = styled.button`
  background: ${Colors.danger};
  border: 0;
  border-radius: 16px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  height: 56px;
  font-size: 16px;
  font-weight: bold;
  justify-self: end;
  margin: 0;
  outline: 0;
  padding: 0 25px;
  width: max-content;
`;

CancelButton.displayName = 'CancelButton';

export default CancelButton;
