import DataTable from '../../../components/DataTable';
import { LocationPageInfo } from '../@types/GetLocationPageInfo';
import React from 'react';
import useKiosksTableData from '../../../hooks/useKiosksTableData';
import { useParams } from 'react-router-dom';

interface KiosksTabProps {
  location: LocationPageInfo;
}

const KiosksTab: React.FC<KiosksTabProps> = ({ location }: KiosksTabProps) => {
  const { locationId } = useParams<{ locationId: string }>();

  const tableData = useKiosksTableData({
    dataTableKey: 'kiosks-Location',
    queryOptions: {
      where: {
        locationId: {
          equals: locationId,
        },
      },
    },
    relatedLocation: location,
  });

  return <DataTable {...tableData} />;
};

export default KiosksTab;
