import { gql } from '../../__generated__/gql';

export const DELETE_TENANT_THEME = gql(`
  mutation deleteTenantTheme($componentName: String!, $tenantId: String!) {
    deleteTenantTheme(componentName: $componentName, tenantId: $tenantId) {
      ...BaseSettingAnswerFields
    }
  }
`);

export const DELETE_TENANT_LOCALIZATION = gql(`
  mutation deleteTenantLocalization(
    $componentName: String!
    $languageId: String!
    $tenantId: String!
  ) {
    deleteTenantLocalization(
      componentName: $componentName
      languageId: $languageId
      tenantId: $tenantId
    ) {
      ...BaseSettingAnswerFields
    }
  }
`);

export const UPSERT_TENANT_LOCALIZATION = gql(`
  mutation upsertTenantLocalization(
    $componentName: String!
    $languageId: String!
    $tenantId: String!
    $localization: String!
  ) {
    upsertTenantLocalization(
      componentName: $componentName
      languageId: $languageId
      tenantId: $tenantId
      localization: $localization
    ) {
      ...BaseSettingAnswerFields
    }
  }
`);

export const UPSERT_TENANT_THEME = gql(`
  mutation upsertTenantTheme($componentName: String!, $tenantId: String!, $setting: String!) {
    upsertTenantTheme(componentName: $componentName, tenantId: $tenantId, setting: $setting) {
      ...BaseSettingAnswerFields
    }
  }
`);
