import Theme from '../../../theme';

interface NavigationButtonStyleProps {
  ['data-active']: boolean;
}

const NavigationButtonStyle = `
  align-content: center;
  justify-content: center;
  position: relative;
  width: 165px;

  button {
    justify-self: center;
    opacity: 1.0 !important;
  }

  button:hover p {
    color: #fff;
  }

  p {
    bottom: -22px;
    color: rgba(${Theme.colors.white.rgb}, 0.95);
    position: absolute;
    text-align: center;
  }

  p.label {
    color: rgba(${Theme.colors.white.rgb}, 0.8);
    font-size: 12px;
  }
`;

export default NavigationButtonStyle;
