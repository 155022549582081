import Theme from '../../theme';
import styled from 'styled-components';

const List = styled.ul`
  color: inherit;
  display: grid;
  font-size: ${Theme.fontStyles.bodyLarge.size}
  list-style: none;
  text-decoration: none;

  li {
    list-style: none;
  }
`;

List.displayName = 'List';

export default List;
