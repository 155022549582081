/* eslint-disable */
import React, { useCallback } from 'react';

import { InfoType } from '../../../__generated__/graphql';
import NodeIcon from './NodeIcon';
import _omit from 'lodash/omit';
import editInfoIcon from '../../../assets/icons/editInfoIcon.png';
import { getDescendants } from '@minoru/react-dnd-treeview';
import styles from './styles.module.css';

const TREE_X_OFFSET = 32;

const Node = ({
  node,
  depth,
  isOpen,
  isDropTarget,
  onClick,
  treeData,
  getPipeHeight,
  handleRef,
  editConciergeItem,
  allCategories,
}) => {
  const indent = depth * TREE_X_OFFSET;

  const handleToggle = (e) => {
    e.stopPropagation();
    onClick(node.id);
  };

  const isCategoryType = useCallback(() => {
    return node.data?.type === InfoType.Category;
  }, [node]);

  return (
    <div
      className={`${styles.nodeWrapper} tree-node ${
        node.droppable && isDropTarget ? styles.dropTarget : ''
      }`}
      style={{ marginInlineStart: 0 }}
      onClick={handleToggle}
    >
      <div style={{paddingLeft: 8}}>
        <NodeIcon type={isCategoryType() ? 'category' : 'info'} ref={handleRef} indent={indent} />
      </div>
      <div className={styles.pipeX} style={{ width: depth > 0 ? TREE_X_OFFSET - 9 : 0 }} />
      {getDescendants(treeData, node.parent)[0].id === node.id && (
        <div
          className={styles.pipeY}
          style={{
            height: Math.max(0, getPipeHeight(node.parent, treeData) - 8),
          }}
        />
      )}
      <div
        draggable={true}
        onDragStart={(event) => event.preventDefault()}
        className={styles.labelGridItem}
      >
        {node.text}
      </div>
      <div
        className={`${styles.expandIconWrapper} ${isOpen ? styles.isOpen : ''}`}
        draggable={true}
        onDragStart={(event) => event.preventDefault()}
      >
        <img
          src={editInfoIcon}
          height={44}
          onClick={() => {
            editConciergeItem(node.id, {
              ..._omit(node.data, ['__typename']),
              ...(node.parent !== ''
                ? {
                    parentCategory: allCategories.find(({ value }) => value === node.parent),
                  }
                : {}),
              ...(node.data && node.data.contentType
                ? {
                    contentType: {
                      label: 'Image And Description',
                      value: node.data.contentType,
                    },
                  }
                : {}),
            });
          }}
        />
      </div>
    </div>
  );
};

export default Node;
