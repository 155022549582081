import 'styled-components/macro';

import {
  BaseSettingAnswer,
  Mutation,
  MutationDeleteKioskThemeArgs,
  MutationDeleteLocationThemeArgs,
  MutationDeleteTenantThemeArgs,
  MutationUpsertKioskThemeArgs,
  MutationUpsertLocationThemeArgs,
  MutationUpsertTenantThemeArgs,
  Query,
  QueryGetKioskThemeArgs,
  QueryGetLocationThemeArgs,
  QueryGetTenantThemeArgs,
} from '../../__generated__/graphql';
import React, { useCallback, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import CustomizeJson from '../CustomizeJson';
import { CustomizeThemeLocalizationEntityLevel } from '../../enums/CustomizeThemeLocalizationEntityLevel';
import CustomizeThemeLocalizationInterface from '../../@types/CustomizeThemeLocalizationInterface';
import { NotSupportedError } from './constants/defaultError';
import { flashApolloError } from '../../util/errorUtils';
import getDeleteQuery from './functions/getDeleteQuery';
import getLoadQuery from './functions/getLoadQuery';
import getUpsertQuery from './functions/getUpsertQuery';
import { jsonDataFromBaseSettingAnswer } from '../CustomizeJson/functions/jsonDataFromBaseSettingAnswer';
import retrieveThemeInputProperties from './functions/retrieveThemeInputProperties';
import retrieveUpsertThemeProperties from './functions/retrieveUpsertThemeProperties';

type CustomizeThemeInterface = CustomizeThemeLocalizationInterface;

type DeleteKioskThemeData = Pick<Mutation, 'deleteKioskTheme'>;
type DeleteLocationThemeData = Pick<Mutation, 'deleteLocationTheme'>;
type DeleteTenantThemeData = Pick<Mutation, 'deleteTenantTheme'>;
type GetKioskThemeData = Pick<Query, 'getKioskTheme'>;
type GetLocationThemeData = Pick<Query, 'getLocationTheme'>;
type GetTenantThemeData = Pick<Query, 'getTenantTheme'>;
type UpsertKioskThemeData = Pick<Mutation, 'upsertKioskTheme'>;
type UpsertLocationThemeData = Pick<Mutation, 'upsertLocationTheme'>;
type UpsertTenantThemeData = Pick<Mutation, 'upsertTenantTheme'>;

const CustomizeTheme: React.FC<CustomizeThemeInterface> = ({
  schema,
  uiSchema,
  componentName,
  fileNameToDownloadDefaultJson,
  fileNameToDownloadCurrentJson,
  title,
  entityLevel,
  entityId,
  mockMainJsonNode,
}: CustomizeThemeInterface) => {
  const [theme, _setTheme] = useState<BaseSettingAnswer | undefined>(undefined);
  const setTheme = (newTheme: BaseSettingAnswer | undefined): void => {
    if (mockMainJsonNode && newTheme) {
      _setTheme({
        mergedSettingsData: {
          general: newTheme.mergedSettingsData,
        },
        settingsData: newTheme.settingsData
          ? {
              general: newTheme.settingsData,
            }
          : undefined,
      });
    } else {
      _setTheme(newTheme);
    }
  };

  // Get - Implementation
  const [getTheme, { data, loading: loadingQuery }] = useLazyQuery<
    GetTenantThemeData | GetLocationThemeData | GetKioskThemeData,
    QueryGetTenantThemeArgs | QueryGetLocationThemeArgs | QueryGetKioskThemeArgs
  >(getLoadQuery(entityLevel), {
    fetchPolicy: 'network-only',
    onError: flashApolloError,
  });

  const load = useCallback(() => {
    getTheme({
      variables: retrieveThemeInputProperties(componentName, entityLevel, entityId),
    });
  }, [getTheme, componentName, entityLevel, entityId]);

  useEffect(() => {
    load();
  }, [load]);

  // Load - Implementation
  if (data && !theme) {
    switch (entityLevel) {
      case CustomizeThemeLocalizationEntityLevel.TENANT: {
        const tenantThemeData = data as GetTenantThemeData;

        setTheme({
          mergedSettingsData: tenantThemeData.getTenantTheme.mergedSettingsData,
          settingsData: tenantThemeData.getTenantTheme.settingsData ?? {},
        });
        break;
      }

      case CustomizeThemeLocalizationEntityLevel.LOCATION: {
        const locationThemeData = data as GetLocationThemeData;

        setTheme({
          mergedSettingsData: locationThemeData.getLocationTheme.mergedSettingsData,
          settingsData: locationThemeData.getLocationTheme.settingsData ?? {},
        });
        break;
      }

      case CustomizeThemeLocalizationEntityLevel.KIOSK: {
        const kioskThemeData = data as GetKioskThemeData;

        setTheme({
          mergedSettingsData: kioskThemeData.getKioskTheme.mergedSettingsData,
          settingsData: kioskThemeData.getKioskTheme.settingsData ?? {},
        });
        break;
      }

      default:
        throw NotSupportedError(entityLevel);
    }
  }

  // Upsert - Implementation
  const onCompleteUpdateTheme = (): void =>
    window.flash({
      message: `Theme updated successfully`,
    });

  const [upsertTheme, { loading: loadingUpsertTheme }] = useMutation<
    UpsertTenantThemeData | UpsertLocationThemeData | UpsertKioskThemeData,
    MutationUpsertTenantThemeArgs | MutationUpsertLocationThemeArgs | MutationUpsertKioskThemeArgs
  >(getUpsertQuery(entityLevel), {
    onCompleted: () => onCompleteUpdateTheme(),
    onError: flashApolloError,
  });

  // Delete - Implementation
  const [deleteTheme, { loading: loadingDeleteTheme }] = useMutation<
    DeleteTenantThemeData | DeleteLocationThemeData | DeleteKioskThemeData,
    MutationDeleteTenantThemeArgs | MutationDeleteLocationThemeArgs | MutationDeleteKioskThemeArgs
  >(getDeleteQuery(entityLevel), {
    onCompleted: () => onCompleteUpdateTheme(),
    onError: flashApolloError,
  });

  const onSubmit = async (_themeDataToUpdate: Record<string, unknown>): Promise<void> => {
    let themeDataToUpdate = _themeDataToUpdate;
    if (mockMainJsonNode) {
      themeDataToUpdate = themeDataToUpdate.general as Record<string, unknown>;
    }
    const setting = JSON.stringify(themeDataToUpdate ?? {});

    if (setting === JSON.stringify(theme?.settingsData)) {
      window.flash({
        message: `No changes on updating theme`,
      });
      return;
    }

    const emptyJson = '{}';
    if (setting !== emptyJson) {
      const updateThemeResult = await upsertTheme({
        variables: retrieveUpsertThemeProperties(componentName, setting, entityLevel, entityId),
      });

      if (updateThemeResult && updateThemeResult.data) {
        switch (entityLevel) {
          case CustomizeThemeLocalizationEntityLevel.TENANT: {
            const updateTenantThemeResult = updateThemeResult.data as UpsertTenantThemeData;

            setTheme({
              mergedSettingsData: updateTenantThemeResult.upsertTenantTheme.mergedSettingsData,
              settingsData: updateTenantThemeResult.upsertTenantTheme.settingsData ?? {},
            });
            break;
          }

          case CustomizeThemeLocalizationEntityLevel.LOCATION: {
            const updateLocationThemeResult = updateThemeResult.data as UpsertLocationThemeData;

            setTheme({
              mergedSettingsData: updateLocationThemeResult.upsertLocationTheme.mergedSettingsData,
              settingsData: updateLocationThemeResult.upsertLocationTheme.settingsData ?? {},
            });
            break;
          }

          case CustomizeThemeLocalizationEntityLevel.KIOSK: {
            const updateTenantThemeResult = updateThemeResult.data as UpsertKioskThemeData;

            setTheme({
              mergedSettingsData: updateTenantThemeResult.upsertKioskTheme.mergedSettingsData,
              settingsData: updateTenantThemeResult.upsertKioskTheme.settingsData ?? {},
            });
            break;
          }

          default:
            throw NotSupportedError(entityLevel);
        }
      }
    } else if (JSON.stringify(theme?.settingsData) !== emptyJson) {
      const deleteThemeResult = await deleteTheme({
        variables: retrieveThemeInputProperties(componentName, entityLevel, entityId),
      });

      switch (entityLevel) {
        case CustomizeThemeLocalizationEntityLevel.TENANT: {
          const deleteTenantThemeResult = deleteThemeResult.data as DeleteTenantThemeData;

          setTheme({
            mergedSettingsData: deleteTenantThemeResult.deleteTenantTheme.mergedSettingsData,
            settingsData: deleteTenantThemeResult.deleteTenantTheme.settingsData ?? {},
          });
          break;
        }

        case CustomizeThemeLocalizationEntityLevel.LOCATION: {
          // eslint-disable-next-line max-len
          const deleteLocationThemeResult = deleteThemeResult.data as DeleteLocationThemeData;

          setTheme({
            mergedSettingsData: deleteLocationThemeResult.deleteLocationTheme.mergedSettingsData,
            settingsData: deleteLocationThemeResult.deleteLocationTheme.settingsData ?? {},
          });
          break;
        }

        case CustomizeThemeLocalizationEntityLevel.KIOSK: {
          const deleteTenantThemeResult = deleteThemeResult.data as DeleteKioskThemeData;

          setTheme({
            mergedSettingsData: deleteTenantThemeResult.deleteKioskTheme.mergedSettingsData,
            settingsData: deleteTenantThemeResult.deleteKioskTheme.settingsData ?? {},
          });
          break;
        }

        default:
          throw NotSupportedError(entityLevel);
      }
    }
  };

  const isLoading = (): boolean => loadingQuery || loadingUpsertTheme || loadingDeleteTheme;

  return (
    <CustomizeJson
      data={jsonDataFromBaseSettingAnswer(theme)}
      schema={schema}
      uiSchema={uiSchema}
      fileNameToDownloadDefaultJson={fileNameToDownloadDefaultJson}
      fileNameToDownloadCurrentJson={fileNameToDownloadCurrentJson}
      isLoading={isLoading()}
      onSubmit={onSubmit}
      title={title}
    />
  );
};

export default CustomizeTheme;
