import { MeAction } from '../actions/me.actions';
import MeActionEnum from '../../enums/MeAction.enum';
import MeReduxState from './@types/MeReduxState';
import { Role } from '../../__generated__/graphql';
import client from '../../client';
import { unbindRealtimeNotifications } from '../../util/realTimeNotificationUtils';

const initialState: MeReduxState = {
  activeMembership: {
    id: '',

    role: Role.User,
    tenant: {
      id: '',
      name: '',
    },
    tenantId: '',
  },
  initialized: false,
  loading: false,
  memberships: [],
};

export default function meReducer(
  state: MeReduxState = initialState,
  action: MeAction
): MeReduxState {
  switch (action.type) {
    case MeActionEnum.LOAD_ME: {
      return {
        ...state,
        loading: true,
      };
    }

    case MeActionEnum.LOG_IN: {
      return {
        ...state,
        activeMembership: action.payload.activeMembership,
        email: action.payload.email!,
        id: action.payload.id,
        initialized: true,
        loading: false,
        memberships: action.payload.memberships,
        name: action.payload.name ?? action.payload.id,
      };
    }

    case MeActionEnum.LOG_OUT: {
      unbindRealtimeNotifications(state.activeMembership.tenantId);

      client.clearStore();
      localStorage.clear();
      return initialState;
    }

    case MeActionEnum.SELECT_MEMBERSHIP: {
      const newMembership = state.memberships.find(
        (membership) => membership.id === action.payload.id
      );

      if (!newMembership || newMembership.tenantId !== state.activeMembership.tenantId) {
        unbindRealtimeNotifications(state.activeMembership.tenantId);
      }

      if (newMembership) {
        return {
          ...state,
          activeMembership: newMembership,
        };
      }

      return state;
    }

    default: {
      return state;
    }
  }
}
