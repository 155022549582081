import { ApolloError, useMutation } from '@apollo/client';
import {
  CREATE_CONCIERGE_ITEM,
  DELETE_CONCIERGE_ITEM,
  UPDATE_CONCIERGE_ITEM,
  UPDATE_CONCIERGE_ITEM_ORDER,
} from '../../../../graphql/getInfoItemsTableData';
import {
  ConciergeItem,
  CreateConciergeItemInput,
  DeleteConciergeItemMutationVariables,
  DeleteConciergeItemResponse,
  Mutation,
  MutationCreateConciergeItemArgs,
  MutationUpdateConciergeItemArgs,
  MutationUpdateConciergeItemOrderArgs,
  UpdateConciergeItemInput,
  UpdateConciergeItemOrderInput,
  UpdateConciergeItemOrderResponse,
} from '../../../../__generated__/graphql';
import { closeForm, closeModal } from '../../../../redux/actions/modals.actions';
import { useDispatch, useSelector } from 'react-redux';

import ModalsReduxState from '../../../../redux/reducers/@types/ModalsReduxState';
import { RootState } from '../../../../redux/reducers';
import { apolloRequirePreflightHeader } from '../../../../client';
import { flashApolloError } from '../../../../util/errorUtils';
import { getDescendants } from '@minoru/react-dnd-treeview';
import { useCallback } from 'react';

type CreateConciergeItemData = Pick<Mutation, 'createConciergeItem'>;
type UpdateConciergeItemData = Pick<Mutation, 'updateConciergeItem'>;
type UpdateConciergeItemOrderData = Pick<Mutation, 'updateConciergeItemOrder'>;

const useConciergeCrud = (
  onCompleted?: (
    loading: boolean,
    data?: ConciergeItem | Array<UpdateConciergeItemOrderResponse> | DeleteConciergeItemResponse,
    error?: ApolloError
  ) => void
) => {
  const { treeData } = useSelector((state: RootState) => state.concierge);
  const dispatch = useDispatch();
  const [create, { error, loading }] = useMutation<
    CreateConciergeItemData,
    MutationCreateConciergeItemArgs
  >(CREATE_CONCIERGE_ITEM, {
    context: {
      headers: apolloRequirePreflightHeader,
    },
    onCompleted: (res) => {
      window.flash({
        message: `InfoItem: ${res.createConciergeItem.title} created successfully`,
      });
      onCompleted?.(loading, res.createConciergeItem, error);
    },
    onError: flashApolloError,
  });

  const [update, { error: updateError, loading: updateLoading }] = useMutation<
    UpdateConciergeItemData,
    MutationUpdateConciergeItemArgs
  >(UPDATE_CONCIERGE_ITEM, {
    context: {
      headers: apolloRequirePreflightHeader,
    },
    onCompleted: (res) => {
      window.flash({
        message: `InfoItem: ${res.updateConciergeItem.title} updated successfully`,
      });
      onCompleted?.(
        updateLoading,
        (res as UpdateConciergeItemData).updateConciergeItem,
        updateError
      );
    },
    onError: flashApolloError,
  });

  const [deleteConcierge, { error: deleteError, loading: deleteLoading }] = useMutation<
    DeleteConciergeItemResponse,
    DeleteConciergeItemMutationVariables
  >(DELETE_CONCIERGE_ITEM, {
    context: {
      headers: apolloRequirePreflightHeader,
    },
    onCompleted: (res) => {
      window.flash({
        message: `InfoItem deleted successfully`,
      });
      onCompleted?.(deleteLoading, res as DeleteConciergeItemResponse, deleteError);
    },
    onError: flashApolloError,
  });

  const [updateOrder, { error: updateOrderError, loading: updateOrderLoading }] = useMutation<
    UpdateConciergeItemOrderData,
    MutationUpdateConciergeItemOrderArgs
  >(UPDATE_CONCIERGE_ITEM_ORDER, {
    context: {
      headers: apolloRequirePreflightHeader,
    },
    onCompleted: (res) => {
      onCompleted?.(
        updateOrderLoading,
        (res as UpdateConciergeItemOrderData).updateConciergeItemOrder,
        updateOrderError
      );
    },
    onError: flashApolloError,
  });

  const createConcierge = useCallback(
    async (input: CreateConciergeItemInput, locationId: string, cb?: () => void) => {
      const data = await create({
        variables: {
          data: {
            ...input,
            locationId,
          },
        },
      });
      dispatch(closeForm());
      dispatch(closeModal('concierge' as keyof ModalsReduxState));
      cb?.();
      return data;
    },
    [create, dispatch]
  );

  const updateConcierge = useCallback(
    async (input: UpdateConciergeItemInput, locationId: string, id: string, cb?: () => void) => {
      const data = await update({
        variables: {
          data: input,
          where: { id: { equals: id }, locationId: { equals: locationId } },
        },
      });
      dispatch(closeForm());
      dispatch(closeModal('concierge' as keyof ModalsReduxState));
      cb?.();
      return data;
    },
    [update, dispatch]
  );

  const updateConciergeOrder = useCallback(
    async (input: Array<UpdateConciergeItemOrderInput>, locationId: string) => {
      const data = await updateOrder({
        variables: {
          data: input,
          locationId,
        },
      });
      return data;
    },
    []
  );

  const deleteConciergeItem = useCallback(
    async (itemId: string, locationId: string) => {
      if (!itemId || !locationId) {
        return;
      }
      const allchilds = getDescendants(treeData, itemId);
      await deleteConcierge({
        variables: { where: { id: { equals: itemId }, locationId: { equals: locationId } } },
      });
      await Promise.all(
        allchilds.map(async ({ id }) => {
          /* eslint-disable-next-line */
          return await deleteConcierge({
            variables: {
              where: { id: { equals: id.toString() }, locationId: { equals: locationId } },
            },
          });
        })
      );
      dispatch(closeForm());
      dispatch(closeModal('concierge' as keyof ModalsReduxState));
    },
    [dispatch, treeData]
  );

  return {
    createConcierge,
    updateConcierge,
    updateConciergeOrder,
    deleteConciergeItem,
  };
};

export default useConciergeCrud;
