/* eslint-disable sort-keys */
import { NodeSchemaProperties, NodeUiSchemaProperties } from '../../../@types/NodeSchemaProperties';

export const mobileV3ThemeConciergeSchema: NodeSchemaProperties = {
  concierge: {
    title: '#24 Concierge Screen',
    type: 'object',
    properties: {
      'categoryDefaultThumbnail:enable': {
        description: '[concierge.categoryDefaultThumbnail]',
        title: '#1 Category items default Thumbnail',
        type: 'boolean',
      },
      categoryDefaultThumbnail: {
        type: 'string',
      },
      'infoItemDefaultThumbnail:enable': {
        description: '[concierge.infoItemDefaultThumbnail]',
        title: '#2 Info items default Thumbnail',
        type: 'boolean',
      },
      infoItemDefaultThumbnail: {
        type: 'string',
      },
      'cardTextColor:enable': {
        description: '[concierge.cardTextColor]',
        title: '#3 Card Text Color',
        type: 'boolean',
      },
      cardTextColor: {
        type: 'string',
      },
      'cardBackgroundColor:enable': {
        description: '[concierge.cardBackgroundColor]',
        title: '#4 Card Background Color',
        type: 'boolean',
      },
      cardBackgroundColor: {
        type: 'string',
      },
      'cardNextIconUrl:enable': {
        description: '[concierge.cardNextIconUrl]',
        title: '#5 Card Next Icon URL',
        type: 'boolean',
      },
      cardNextIconUrl: {
        type: 'string',
      },
      'categoryBackgroundColor:enable': {
        description: '[concierge.categoryBackgroundColor]',
        title: '#6 Category tab Background Color',
        type: 'boolean',
      },
      categoryBackgroundColor: {
        type: 'string',
      },
      'categoryTextColor:enable': {
        description: '[concierge.categoryTextColor]',
        title: '#7 Category tab Text Color',
        type: 'boolean',
      },
      categoryTextColor: {
        type: 'string',
      },
      'selectedCategoryBackgroundColor:enable': {
        description: '[concierge.selectedCategoryBackgroundColor]',
        title: '#8 Selected Category tab Background Color',
        type: 'boolean',
      },
      selectedCategoryBackgroundColor: {
        type: 'string',
      },
      'selectedCategoryTextColor:enable': {
        description: '[concierge.selectedCategoryTextColor]',
        title: '#9 Selected Category tab Text Color',
        type: 'boolean',
      },
      selectedCategoryTextColor: {
        type: 'string',
      },
      'cardArrowIconColor:enable': {
        description: '[concierge.cardArrowIconColor]',
        title: '#10 Card Arrow Icon Color',
        type: 'boolean',
      },
      cardArrowIconColor: {
        type: 'string',
      },
      'categoryArrowIconColor:enable': {
        description: '[concierge.categoryArrowIconColor]',
        title: '#11 Category Arrow Icon Color',
        type: 'boolean',
      },
      categoryArrowIconColor: {
        type: 'string',
      },
      'categoryDisabledArrowIconColor:enable': {
        description: '[concierge.categoryDisabledArrowIconColor]',
        title: '#12 Category Disabled Arrow Icon Color',
        type: 'boolean',
      },
      categoryDisabledArrowIconColor: {
        type: 'string',
      },
      'categoryLeftArrowIconUrl:enable': {
        description: '[concierge.categoryLeftArrowIconUrl]',
        title: '#13 Category Left Arrow Icon URL',
        type: 'boolean',
      },
      categoryLeftArrowIconUrl: {
        type: 'string',
      },
      'categoryRightArrowIconUrl:enable': {
        description: '[concierge.categoryRightArrowIconUrl]',
        title: '#14 Category Right Arrow Icon URL',
        type: 'boolean',
      },
      categoryRightArrowIconUrl: {
        type: 'string',
      },
      'categoryLeftDisabledArrowIconUrl:enable': {
        description: '[concierge.categoryLeftDisabledArrowIconUrl]',
        title: '#15 Category Left Disabled Arrow Icon URL',
        type: 'boolean',
      },
      categoryLeftDisabledArrowIconUrl: {
        type: 'string',
      },
      'categoryRightDisabledArrowIconUrl:enable': {
        description: '[concierge.categoryRightDisabledArrowIconUrl]',
        title: '#16 Category Right Disabled Arrow Icon URL',
        type: 'boolean',
      },
      categoryRightDisabledArrowIconUrl: {
        type: 'string',
      },
      'hideAllCategory:enable': {
        description: '[concierge.hideAllCategory]',
        title: '#17 Hide All Category',
        type: 'boolean',
      },
      hideAllCategory: {
        type: 'boolean',
      },
      'orderAlphabetically:enable': {
        description: '[concierge.orderAlphabetically]',
        title: '#18 Order Alphabetically',
        type: 'boolean',
      },
      orderAlphabetically: {
        type: 'boolean',
      },
      'thumbnailReziseMode:enable': {
        description: '[concierge.thumbnailReziseMode]',
        title: '#19 Thumbnail Resize Mode',
        type: 'boolean',
      },
      thumbnailReziseMode: {
        type: undefined,
        enum: ['cover', 'contain', 'stretch', 'center'],
      },
      'infoImageResizeMode:enable': {
        description: '[concierge.infoImageResizeMode]',
        title: '#20 Info Image Resize Mode',
        type: 'boolean',
      },
      infoImageResizeMode: {
        type: undefined,
        enum: ['cover', 'contain', 'stretch', 'center'],
      },
      'cardTextAlignment:enable': {
        description: '[concierge.cardTextAlignment]',
        title: '#21 Card Text Alignment',
        type: 'boolean',
      },
      cardTextAlignment: {
        type: undefined,
        enum: ['flex-start', 'flex-end', 'center', 'stretch', 'baseline'],
      },
      'cardMediaBackgroundColor:enable': {
        description: '[concierge.cardMediaBackgroundColor]',
        title: '#22 Card Media Background Color',
        type: 'boolean',
      },
      cardMediaBackgroundColor: {
        type: 'string',
      },
      'categoryThumbnailReziseMode:enable': {
        description: '[concierge.categoryThumbnailReziseMode]',
        title: '#23 Category Thumbnail Resize Mode',
        type: 'boolean',
      },
      categoryThumbnailReziseMode: {
        type: undefined,
        enum: ['cover', 'contain', 'stretch', 'center'],
      },
    },
  },
};

export const mobileV3ThemeConciergeUiSchema: NodeUiSchemaProperties = {
  concierge: {
    categoryDefaultThumbnail: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    infoItemDefaultThumbnail: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    cardTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cardBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cardNextIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    categoryBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    categoryTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    selectedCategoryBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    selectedCategoryTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cardArrowIconColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    categoryArrowIconColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    categoryDisabledArrowIconColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    categoryLeftArrowIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    categoryRightArrowIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    categoryLeftDisabledArrowIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    categoryRightDisabledArrowIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    hideAllCategory: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    orderAlphabetically: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    thumbnailReziseMode: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    infoImageResizeMode: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    cardTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    cardMediaBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    categoryThumbnailReziseMode: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
  },
};
