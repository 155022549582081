import { PendingTasksAction } from '../../actions/pendingTasks.actions';
import PendingTasksActionEnum from '../../../enums/PendingTasksAction.enum';
import PendingTasksReduxState from '../@types/PendingTasksReduxState';
import credentialBeingProcessedAction from './functions/credentialBeingProcessedAction';
import credentialProcessedAction from './functions/credentialProcessedAction';

const initialState: PendingTasksReduxState = {
  credentialsBeingProcessed: [],
};

export const cleanPendingTasks = (): PendingTasksReduxState => {
  return {
    credentialsBeingProcessed: [],
  };
};

export default function pendingTasksReducer(
  state: PendingTasksReduxState = initialState,
  action: PendingTasksAction
): PendingTasksReduxState {
  switch (action.type) {
    case PendingTasksActionEnum.CREDENTIAL_BEING_PROCESSED: {
      return credentialBeingProcessedAction(state, action.payload);
    }

    case PendingTasksActionEnum.CREDENTIAL_PROCESSED: {
      return credentialProcessedAction(state, action.payload);
    }

    default: {
      return state;
    }
  }
}
