import { rightString } from '../../../util/stringUtils';

const formatCallDuration = (seconds: number): string => {
  const date = new Date(0);
  date.setSeconds(seconds);

  const minutesAndSecondsFormated = date.toISOString().substring(14, 19);

  const hours = Number.parseInt(date.toISOString().substring(11, 13), 10);
  if (hours > 0) {
    const hoursFormated = rightString(`00${String(hours)}`, 2);
    return `${hoursFormated}:${minutesAndSecondsFormated}`;
  }
  return minutesAndSecondsFormated;
};

export default formatCallDuration;
