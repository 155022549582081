import 'styled-components/macro';
import Container from '../Container';
import LoadingScreenContainerStyle from './styles/LoadingScreenContainer.style';
import React from 'react';
import VirdeeLogo from '../../assets/logo/virdee_block_logo.png';

const LoadingScreen: React.FC<{}> = () => {
  return (
    <Container css={LoadingScreenContainerStyle}>
      <img alt="Virdee" src={VirdeeLogo} />
    </Container>
  );
};

export default LoadingScreen;
