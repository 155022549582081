import { Query, QueryMode, QueryUsersArgs, SortOrder } from '../__generated__/graphql';

import { RootState } from '../redux/reducers';
import SEARCH_USERS from '../graphql/searchUsers';
import SelectOption from '../@types/react/SelectOption';
import generateNestedMembershipQuery from '../util/api/generateNestedMembershipQuery';
import { useApolloClient } from '@apollo/client';
import { useSelector } from 'react-redux';

type SearchUsersData = Pick<Query, 'users'>;

export default function useLoadUserOptions(): (input: string) => Promise<SelectOption[]> {
  const client = useApolloClient();
  const me = useSelector((state: RootState) => state.me);
  const membership = me.activeMembership;

  return async (input: string): Promise<SelectOption[]> => {
    const { data } = await client.query<SearchUsersData, QueryUsersArgs>({
      query: SEARCH_USERS,
      variables: {
        orderBy: [
          {
            name: { sort: SortOrder.Asc },
          },
        ],
        take: 10,
        where: {
          ...generateNestedMembershipQuery(membership),
          name: {
            contains: input,
            mode: QueryMode.Insensitive,
          },
        },
      },
    });

    return (
      data.users?.map((user) => ({
        label: user.name ?? user.id,
        value: user.id,
      })) ?? []
    );
  };
}
