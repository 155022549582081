import 'styled-components/macro';

import CrudButtonsStyle, { CrudButtonsInnerContainerStyle } from './styles/CrudButtons.style';
import DetailPageSummaryContainerStyle, {
  DetailPageSummaryExternalContainerStyle,
} from './styles/DetailPageSummaryContainer.style';
import Heading, { HeadingModel } from '../../components/Heading';
import { MdArrowBack, MdDelete, MdModeEdit } from 'react-icons/md';
import { useHistory, useLocation } from 'react-router-dom';

import BaseLayout from '../BaseLayout';
import Button from '../../components/Button';
import ButtonModel from '../../components/Button/enums/ButtonModel.enum';
import { CSSProperties } from 'styled-components/macro';
import { Colors } from '../../theme/colors';
import Container from '../../components/Container';
import DetailPageBannerHeaderStyle from '../../pages/AccessGrant/styles/AccessGrantBannerHeader.style';
import DetailPageSummaryItemsStyle from './styles/DetailPageSummaryItems.style';
import DetailPageTabContainerStyle from './styles/DetailPageTabContainer.style';
import DetailPageTabsContainerStyle from './styles/DetailPageTabsContainer.style';
import { EntityEnum } from '../../@types/client/DashboardUserRolePermissions';
import React from 'react';
import RemoveHook from '../../hooks/@types/RemoveHook';
import { createButtonsFromDetailItems } from './createButtonsFromDetailItems';
import { openRemoveModal } from '../../redux/actions/modals.actions';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import useGetPermission from '../../hooks/useGetPermission';

export interface DetailPageItem {
  buttonIsSelected?: boolean;
  disabled?: boolean;
  icon?: React.ComponentType;
  label?: string;
  link?: string;
  loading?: boolean;
  onClick?: () => void;
  text: string;
  textStyle?: CSSProperties;
}

interface DetailPageTemplateProps {
  dataType: string;
  detailItems?: DetailPageItem[];
  identifier: string;
  onEditButtonClick?: () => void;
  onRemoveButtonClick?: () => void;
  onRemoveCompleted?: () => void;
  permission: EntityEnum;
  primaryHeader: string;
  remove?: RemoveHook;
  secondaryHeader: string;
  tabs?: {
    Component: React.ComponentType;
    id: string;
  }[];
}

const DetailPageTemplate: React.FC<DetailPageTemplateProps> = ({
  dataType,
  detailItems = [],
  identifier,
  onEditButtonClick,
  onRemoveCompleted,
  permission,
  primaryHeader,
  remove,
  secondaryHeader,
  tabs = [],
}: DetailPageTemplateProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const tabParam = queryParams.tab as string;
  const defaultTab = tabs[0]?.id ?? null;

  const { getPermission } = useGetPermission();
  const activePermissions = getPermission(permission);

  function handleTabClick(e: React.SyntheticEvent<HTMLButtonElement>): void {
    const tabId = e.currentTarget.getAttribute('data-tab');

    const params = qs.stringify({ ...queryParams, tab: tabId });

    history.push({
      pathname: location.pathname,
      search: params,
    });
  }

  const activeTab = tabs.find((t) => t.id === (tabParam ?? defaultTab))?.id ?? '';
  const TabComponent = tabs.find((t) => t.id === (tabParam ?? defaultTab))?.Component;

  function goBack(): void {
    const [path, _location] = history.location.pathname.split('/');
    history.push(`${path}/${_location}`);
  }

  const getCRUDButtonsElement = (): JSX.Element[] => {
    const crudButtons: JSX.Element[] = [];

    if (onEditButtonClick) {
      crudButtons.push(
        <Button
          background={Colors.dodgerBlue}
          className="edit-button"
          model={ButtonModel.INLINE_BUTTON}
          onClick={onEditButtonClick}
          disabled={!activePermissions.UPDATE}
        >
          <>
            <MdModeEdit />
            Edit {dataType}
          </>
        </Button>
      );
    }

    if (remove) {
      crudButtons.push(
        <Button
          background={Colors.freeSpeechRed}
          className="remove-button"
          model={ButtonModel.INLINE_BUTTON}
          disabled={!activePermissions.DELETE}
          onClick={(): void => {
            if (remove) {
              dispatch(
                openRemoveModal({
                  dataType,
                  hook: remove,
                  id: identifier,
                  onRemoveCompleted,
                  verbiage: 'remove',
                })
              );
            }
          }}
        >
          <>
            <MdDelete />
            Remove {dataType}
          </>
        </Button>
      );
    }

    return crudButtons;
  };

  return (
    <BaseLayout background={Colors.neroDarkC}>
      <Container>
        <Heading css={DetailPageBannerHeaderStyle} model={HeadingModel.PRIMARY}>
          <Button model={ButtonModel.PASSIVE_ICON_BUTTON} onClick={goBack}>
            <MdArrowBack size="22px" />
          </Button>
          {primaryHeader}
        </Heading>
        <Container css={DetailPageSummaryExternalContainerStyle}>
          <Container css={DetailPageSummaryContainerStyle}>
            <Heading color="#fff" margin="0 0 25px" model={HeadingModel.TERTIARY}>
              {secondaryHeader}
            </Heading>
            <Container css={DetailPageSummaryItemsStyle}>
              {createButtonsFromDetailItems(detailItems)}
            </Container>
          </Container>
          <Container css={CrudButtonsStyle}>
            <div></div>
            <Container style={CrudButtonsInnerContainerStyle}>{getCRUDButtonsElement()}</Container>
          </Container>
        </Container>
        <Container css={DetailPageTabContainerStyle}>
          <Heading color="#fff" model={HeadingModel.PRIMARY}>
            {activeTab}
          </Heading>
          <Container css={DetailPageTabsContainerStyle}>
            {tabs.map((tab) => (
              <Button
                active={tab.id === activeTab}
                data-tab={tab.id}
                key={tab.id}
                model={ButtonModel.TAB}
                onClick={handleTabClick}
              >
                {tab.id}
              </Button>
            ))}
          </Container>
        </Container>
        <Container>{TabComponent && <TabComponent />}</Container>
      </Container>
    </BaseLayout>
  );
};

export default DetailPageTemplate;
