import {
  PaymentTransactionWhereInput,
  Query,
  QueryPaymentTransactionsArgs,
} from '../../__generated__/graphql';
import { useCallback, useEffect, useRef, useState } from 'react';

import { EntityEnum } from '../../@types/client/DashboardUserRolePermissions';
import GET_PAYMENT_TRANSACTION_COUNT from '../../graphql/aggregatePaymentTransaction';
import GET_PAYMENT_TRANSACTION_TABLE_DATA from '../../graphql/getPaymentTransactionsTableData';
import PAYMENT_TRANSACTIONS_TABLE_HEADERS from './constants/PaymentTransactionsTableHeaders';
import PAYMENT_TRANSACTIONS_TABLE_SEARCH_FIELDS from './constants/PaymentTransactionsTableSearchFields';
import SearchField from '../../@types/SearchField';
import TableHookData from '../@types/TableHookData';
import TableHookProps from '../@types/TableHookProps';
import generatePaymentTransactionsTableData from './functions/generatePaymentTransactionsTableData';
import useGetMany from '../useGetMany';

type GetPaymentTransactionTableData = Pick<Query, 'paymentTransactions'>;
type AggregatePaymentTransactionQueryData = Pick<Query, 'aggregatePaymentTransaction'>;

export default function usePaymentTransactionsTableData({
  formName = 'paymentTransaction',
  headers = PAYMENT_TRANSACTIONS_TABLE_HEADERS,
  isSearchable = true,
  queryOptions,
  searchFields = PAYMENT_TRANSACTIONS_TABLE_SEARCH_FIELDS,
}: TableHookProps<QueryPaymentTransactionsArgs, PaymentTransactionWhereInput>): TableHookData<
  PaymentTransactionWhereInput
> {
  const options = useRef<Partial<QueryPaymentTransactionsArgs>>(queryOptions ?? {});

  const [searchParameters, setSearchParameters] = useState<PaymentTransactionWhereInput>({});

  const { data, error, loading, offset, query, setOffset } = useGetMany<
    GetPaymentTransactionTableData,
    QueryPaymentTransactionsArgs
  >({
    graphqlQuery: GET_PAYMENT_TRANSACTION_TABLE_DATA,
  });

  const {
    data: countData,
    error: countError,
    loading: countLoading,
    query: countQuery,
  } = useGetMany<AggregatePaymentTransactionQueryData, QueryPaymentTransactionsArgs>({
    graphqlQuery: GET_PAYMENT_TRANSACTION_COUNT,
  });

  const createParams = useCallback((): PaymentTransactionWhereInput => {
    return {
      ...options.current?.where,
      ...searchParameters,
    };
  }, [options, searchParameters]);

  const get = useCallback(() => {
    const params = createParams();

    query({
      ...options.current,
      skip: options.current?.skip ?? offset,
      take: options.current?.take ?? 10,
      where: params,
    });
  }, [createParams, offset, query]);

  const getCount = useCallback(() => {
    const params = createParams();

    countQuery({
      where: params,
    });
  }, [countQuery, createParams]);

  useEffect(() => {
    get();
  }, [get]);

  useEffect(() => {
    getCount();
  }, [getCount]);

  return {
    count: countData?.aggregatePaymentTransaction?._count?._all ?? 0,
    data: generatePaymentTransactionsTableData(data),
    dataType: 'PaymentTransaction',
    error: error || countError,
    formName,
    headers,
    isLoading: loading || countLoading,
    isSearchable,
    offset,
    permission: EntityEnum.NONE,
    search: (searchField: SearchField<PaymentTransactionWhereInput>, searchValue: string): void => {
      setSearchParameters({
        [searchField.queryField]: searchField.transform(searchValue),
      });

      setOffset(0);
    },
    searchFields,
    setOffset,
  };
}
