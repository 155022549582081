import { gql } from '../__generated__/gql';

const GET_PAYMENT_COUNT = gql(`
  query GetPaymentCount($where: PaymentWhereInput!) {
    aggregatePayment(where: $where) {
      _count {
        _all
      }
    }
  }
`);

export default GET_PAYMENT_COUNT;
