import {
  CustomInfoItemUpdateInput,
  Mutation,
  MutationUpdateInfoItemAndUploadImageFileArgs,
} from '../../../__generated__/graphql';

import MutationHookData from '../../../hooks/@types/MutationHookData';
import MutationHookParams from '../../../hooks/@types/MutationHookParams';
import UPDATE_INFO_ITEM from '../../../graphql/updateInfoItem';
import { apolloRequirePreflightHeader } from '../../../client';
import { closeForm } from '../../../redux/actions/modals.actions';
import { flashApolloError } from '../../../util/errorUtils';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';

type UpdateInfoItemData = Pick<Mutation, 'updateInfoItemAndUploadImageFile'>;

export default function useUpdateInfoItemAndUploadImage({
  onCompleted = (): void => {},
  onError = flashApolloError,
}: MutationHookParams<UpdateInfoItemData> = {}): MutationHookData<
  CustomInfoItemUpdateInput,
  UpdateInfoItemData
> {
  const dispatch = useDispatch();

  const [update, { data, error, loading }] = useMutation<
    UpdateInfoItemData,
    MutationUpdateInfoItemAndUploadImageFileArgs
  >(UPDATE_INFO_ITEM, {
    context: {
      headers: apolloRequirePreflightHeader,
    },
    onCompleted: ({ updateInfoItemAndUploadImageFile }) => {
      window.flash({
        message: `InfoItem: ${updateInfoItemAndUploadImageFile.title} updated successfully`,
      });

      onCompleted({ updateInfoItemAndUploadImageFile });

      dispatch(closeForm());
    },
    onError,
  });

  return {
    data,
    error,
    loading,
    mutation: (inputs: CustomInfoItemUpdateInput, id = ''): void => {
      update({
        variables: {
          data: inputs,
          where: { id },
        },
      });
    },
  };
}
