import 'styled-components/macro';

import {
  CallRecord,
  CallStatus,
  Query,
  QueryCallRecordsArgs,
} from '../../../../__generated__/graphql';
import Heading, { HeadingModel } from '../../../../components/Heading';
import React, { memo, useEffect } from 'react';

import { Colors } from '../../../../theme/colors';
import { ConfigProvider } from 'react-avatar';
import Container from '../../../../components/Container';
import EmptyDisclaimerTextStyle from './styles/EmptyDisclaimerText.style';
import ErrorTextStyle from './styles/ErrorText.style';
import GET_CALL_RECORDS from '../../../../graphql/callRecords';
import InnerContainerStyle from './styles/InnerContainer.style';
import List from '../../../../components/List';
import { RootState } from '../../../../redux/reducers';
import TextContent from '../../../../components/TextContent';
import Theme from '../../../../theme';
import WaitingCall from './components/WaitingCall';
import WaitingCallListStyle from './styles/WaitingCallList.style';
import WaitingCallsContainerStyle from './styles/WaitingCallsContainer.style';
import { observer } from 'mobx-react';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

type CallRecordsQueryData = Pick<Query, 'callRecords'>;

const Calls: React.FC<{}> = observer(() => {
  const membership = useSelector((state: RootState) => state.me.activeMembership);
  const incomingCalls = useSelector((state: RootState) => state.remoteAssistance.incomingCalls);
  const videoToken = useSelector((state: RootState) => state.remoteAssistance.videoToken);

  const { data, error, loading, refetch } = useQuery<CallRecordsQueryData, QueryCallRecordsArgs>(
    GET_CALL_RECORDS,
    {
      notifyOnNetworkStatusChange: true,
      pollInterval: 1500,
      variables: {
        where: {
          room: {
            not: null,
          },
          status: {
            equals: CallStatus.Waiting,
          },
          tenant: {
            is: {
              id: {
                equals: membership?.tenantId,
              },
            },
          },
        },
      },
    }
  );
  const waitingCalls: CallRecord[] = data?.callRecords || [];

  useEffect(() => {
    if (loading || waitingCalls.length) {
      return;
    }

    if (incomingCalls && !videoToken && !loading) {
      refetch();
    }
  }, [incomingCalls, videoToken, loading, refetch, waitingCalls.length]);

  return (
    <Container>
      <Container>
        <Container css={WaitingCallsContainerStyle}>
          <Heading
            color={Theme.colors.white.hexCode}
            margin="0 0 25px"
            model={HeadingModel.QUARTERNARY}
          >
            Today
          </Heading>
          {Boolean(waitingCalls.length) && (
            <ConfigProvider colors={[Colors.dodgerBlue, Colors.freeSpeechRed, Colors.neonPink]}>
              <List css={WaitingCallListStyle}>
                {waitingCalls.map((call) => (
                  <WaitingCall call={call} key={call.id} />
                ))}
              </List>
            </ConfigProvider>
          )}
          <Container alignContent="center" css={InnerContainerStyle} justify="center">
            {error && <TextContent css={ErrorTextStyle}>Network Error</TextContent>}
            {!error && !waitingCalls.length && (
              <TextContent css={EmptyDisclaimerTextStyle}>No Pending Calls</TextContent>
            )}
          </Container>
        </Container>
      </Container>
    </Container>
  );
});

export default memo(Calls);
