import { Datum } from '../../../components/DataTable';
import { Query } from '../../../__generated__/graphql';

type GetAmenityTableData = Pick<Query, 'amenities'>;

export default function generateAmenitiesTableData(data?: GetAmenityTableData): Datum[] {
  const tableData =
    data?.amenities.map((amenity) => {
      // default imageUrl until know how to get the correct one
      const imageUrl = amenity.imageUrl
        ? amenity.imageUrl
        : 'https://s3.us-east-2.amazonaws.com/app.virdee.co/images/WiFi.png';

      return {
        dataType: 'Amenity',
        fields: [
          {
            key: 'Title',
            value: amenity.title,
          },
          {
            key: 'Description',
            value: amenity.description ?? '',
          },
          {
            key: 'Image URL',
            ...(imageUrl && { href: imageUrl }),
            value: imageUrl,
          },
          {
            key: 'Group',
            value: amenity.group?.title ?? '',
          },
        ],
        key: amenity.id,
      };
    }) || [];

  return tableData;
}
