import { gql } from '../__generated__/gql';

const ISSUE_KEY_FOR_KIOSK_WITH_ACCESS_GRANT_ID = gql(`
  mutation issueKeyForKioskWithAccessGrantId($kioskId: String!, $accessGrantId: String!) {
    issueKeyForKioskWithAccessGrantId(kioskId: $kioskId, accessGrantId: $accessGrantId) {
      success
    }
  }
`);

export default ISSUE_KEY_FOR_KIOSK_WITH_ACCESS_GRANT_ID;
