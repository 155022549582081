import React from 'react';
import { Redirect } from 'react-router-dom';
import { RootState } from '../../redux/reducers';
import links from '../../util/links';
import { useSelector } from 'react-redux';

interface AuthRouteProps {
  children: React.ReactElement;
}

const AuthRoute: React.FC<AuthRouteProps> = ({ children }: AuthRouteProps): React.ReactElement => {
  const accessToken = useSelector((state: RootState) => state.auth.accessToken);

  if (accessToken) {
    return <Redirect to={links.paths.HOME} />;
  }

  return children;
};

export default AuthRoute;
