import styled from 'styled-components';

type TextGridProps = {
  numberOfOptions: number;
};

const TextGrid = styled.div<TextGridProps>`
  display: grid;
  grid-template-columns: ${({ numberOfOptions }): string => {
    const width = 100 / numberOfOptions;
    return Array.from({ length: numberOfOptions }, () => `${width}%`).join(' ');
  }};
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;

  & span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default TextGrid;
