import 'styled-components/macro';

import Calls from '../Calls';
import CallsContainerStyle from './styles/CallsContainer.style';
import Container from '../../../../components/Container';
import React from 'react';
import RemoteAssistanceAside from '../RemoteAssistanceAside';
import RemoteAssistanceMainContainerStyle from './styles/RemoteAssistanceMainContainer.style';
import moment from 'moment';

const WaitingCallsTab: React.FC<{}> = () => {
  /* calculating this value in scope of query causes infinite loop */
  const oneDayAgo = new Date(moment().subtract(1, 'day').toISOString());

  return (
    <Container as="main" css={RemoteAssistanceMainContainerStyle}>
      <Container as="section" css={CallsContainerStyle}>
        <Calls />
      </Container>
      <RemoteAssistanceAside oneDayAgo={oneDayAgo} />
    </Container>
  );
};

export default WaitingCallsTab;
