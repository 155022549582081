import { gql } from '../__generated__/gql';

const DELETE_LOCATION = gql(`
  mutation DeleteOneLocation($where: LocationWhereUniqueInput!) {
    deleteOneLocation(where: $where) {
      id
      name
    }
  }
`);

export default DELETE_LOCATION;
