import { MdPhone, MdPlace } from 'react-icons/md';
import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import DetailPageTemplate from '../../templates/DetailPage';
import { EntityEnum } from '../../@types/client/DashboardUserRolePermissions';
import GET_TENANT_PAGE_INFO from './graphql/getTenantPageInfo';
import GetTenantPageInfo from './@types/GetTenantPageInfo';
import { QueryTenantArgs } from '../../__generated__/graphql';
import { RootState } from '../../redux/reducers';
import TenantSettingsTab from './components/TenantSettingsTab';
import { flashApolloError } from '../../util/errorUtils';
import links from '../../util/links';
import { useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { validateAccessByMembership } from '../../util/authorization';

const TenantPage: React.FC<{}> = () => {
  const membership = useSelector((state: RootState) => state.me.activeMembership);
  const { tenantId } = useParams<{ tenantId: string }>();

  const [getTenant, { called, data }] = useLazyQuery<GetTenantPageInfo, QueryTenantArgs>(
    GET_TENANT_PAGE_INFO,
    {
      onError: flashApolloError,
      variables: {
        where: {
          id: tenantId,
        },
      },
    }
  );

  useEffect(() => {
    if (!called) {
      getTenant();
    }
  }, [called, getTenant]);

  try {
    validateAccessByMembership({
      membership,
      objectMembership: { tenantId },
      validateLocation: true,
    });
  } catch {
    return <Redirect to={links.paths.HOME} />;
  }

  const tenant = data?.tenant;
  if (!tenant) {
    return (
      <DetailPageTemplate
        dataType="Tenant"
        detailItems={[]}
        identifier={tenantId}
        permission={EntityEnum.TENANT}
        primaryHeader={''}
        secondaryHeader="Tenant Details"
        tabs={[]}
      />
    );
  }

  return (
    <DetailPageTemplate
      dataType="Tenant"
      detailItems={[
        {
          icon: MdPlace,
          label: 'ID',
          text: tenant.id,
        },
        {
          icon: MdPhone,
          label: 'Name',
          text: tenant.name,
        },
      ]}
      identifier={tenantId}
      permission={EntityEnum.TENANT}
      primaryHeader={tenant.name}
      secondaryHeader="Tenant Details"
      tabs={[
        {
          Component: TenantSettingsTab,
          id: 'Settings',
        },
      ]}
    />
  );
};

export default React.memo(TenantPage);
