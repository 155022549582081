import React, { useContext, useEffect } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';

import Form from '../../Form';
import FormContext from '../../../contexts/form.context';
import FormCrudAction from '../../../enums/FormCrudAction.enum';
import KioskFormInputs from '../@types/KioskFormInputs';
import { RootState } from '../../../redux/reducers';
import SimpleFormSection from '../../../templates/SimpleFormSection';
import SubmitRow from '../../SubmitRow';
import getKioskSectionInputs from '../constants/kioskSectionInputs';
import useKioskSubmit from '../hooks/useKioskSubmit';
import { useSelector } from 'react-redux';

const KioskSection: React.FC<{}> = () => {
  const modalId = useSelector((state: RootState) => state.modals.form.id);
  const { setIdentifier } = useContext(FormContext);
  const crudAction = modalId ? FormCrudAction.EDIT : FormCrudAction.CREATE;

  useEffect(() => {
    if (modalId) {
      setIdentifier(modalId);
    }
  }, [setIdentifier, modalId]);

  const { handleSubmit } = useFormContext();
  const { step, setStep } = useContext(FormContext);

  const { loadingMutation, submitHandler } = useKioskSubmit();

  const formSubmitHandler: SubmitHandler<KioskFormInputs> = async (
    inputs: KioskFormInputs
  ): Promise<void> => {
    if (crudAction === FormCrudAction.CREATE) {
      await submitHandler(inputs);
    } else {
      setStep((step ?? 0) + 1);
    }
  };

  const KIOSK_SECTION_INPUTS = getKioskSectionInputs({ formCrudAction: crudAction });

  return (
    <Form onSubmit={handleSubmit(formSubmitHandler)}>
      <SimpleFormSection inputs={KIOSK_SECTION_INPUTS} />
      <SubmitRow
        loading={loadingMutation}
        submitText={crudAction === FormCrudAction.EDIT ? 'Set configurations' : 'Enroll Kiosk'}
      />
    </Form>
  );
};

export default KioskSection;
