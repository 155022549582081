import 'styled-components/macro';
import Heading, { HeadingModel } from '../Heading';
import Container from '../Container';
import React from 'react';
import Theme from '../../theme';

export interface WelcomeBannerProps {
  subText?: string;
  text?: string;
  textLines?: string[];
}

const WelcomeBannerContainerStyle = `
  background: ${Theme.colors.white.hexCode};
  border-radius: 24px;
  box-shadow: 0px 10px 32px 0px rgba(${Theme.colors.boxShadow.rgb}, 0.91);
  height: max-content;
  padding: 30px 40px;
  width: 725px;

  @media only screen and (max-width: 1450px) {
    min-height: 150px;
    min-width: max-content;
    width: 450px;    
  }

  @media only screen and (max-width: 1200px) {
    min-height: 150px;
    min-width: max-content;
    width: 450px;
  }
`;

const SubTextStyle = `
  margin: 25px 0 0;
`;

const WelcomeBanner: React.FunctionComponent<WelcomeBannerProps> = ({
  subText,
  text,
}: WelcomeBannerProps) => {
  return (
    <Container alignContent="start" css={WelcomeBannerContainerStyle} justify="left">
      <Heading model={HeadingModel.PRIMARY}>{text}</Heading>
      <Heading css={SubTextStyle} model={HeadingModel.QUARTERNARY}>
        {subText}
      </Heading>
    </Container>
  );
};

export default WelcomeBanner;
