import COLORS from './colors';
import FONT_SIZES from './fontSizes';
import LINE_HEIGHTS from './lineHeight';

interface FontStyle {
  size: string;
  letterSpacing?: string;
  lineHeight: string;
}

type StringMap = { [key: string]: string };

type ColorsType = typeof COLORS;

interface ThemeInterface {
  colors: ColorsType;
  fontFamilies: StringMap;
  fontSizes: StringMap;
  fontStyles: {
    [key: string]: FontStyle & {
      responsive: {
        [width: string]: FontStyle;
      };
    };
  };
}

const Theme: ThemeInterface = {
  colors: COLORS,
  fontFamilies: {
    default: 'San Francisco Pro',
  },
  fontSizes: FONT_SIZES,
  fontStyles: {
    bodyLarge: {
      lineHeight: LINE_HEIGHTS.bodyLarge,
      responsive: {
        '1200px': {
          lineHeight: LINE_HEIGHTS.bodySmall,
          size: FONT_SIZES.bodySmall,
        },
      },
      size: FONT_SIZES.bodyLarge,
    },
    bodySmall: {
      lineHeight: LINE_HEIGHTS.bodySmall,
      responsive: {
        '1200px': {
          lineHeight: LINE_HEIGHTS.bodySmall,
          size: FONT_SIZES.bodySmall,
        },
      },
      size: FONT_SIZES.bodySmall,
    },
    firstHeading: {
      lineHeight: LINE_HEIGHTS.firstHeading,
      responsive: {
        '1200px': {
          lineHeight: LINE_HEIGHTS.secondHeading,
          size: FONT_SIZES.secondHeading,
        },
      },
      size: FONT_SIZES.firstHeading,
    },
    fourthHeading: {
      lineHeight: LINE_HEIGHTS.fourthHeading,
      responsive: {
        '1200px': {
          lineHeight: LINE_HEIGHTS.fourthHeading,
          size: FONT_SIZES.fourthHeading,
        },
      },
      size: FONT_SIZES.fourthHeading,
    },
    label: {
      letterSpacing: '1px',
      lineHeight: LINE_HEIGHTS.label,
      responsive: {
        '1200px': {
          lineHeight: LINE_HEIGHTS.label,
          size: FONT_SIZES.label,
        },
      },
      size: FONT_SIZES.label,
    },
    secondHeading: {
      lineHeight: LINE_HEIGHTS.secondHeading,
      responsive: {
        '1200px': {
          lineHeight: LINE_HEIGHTS.thirdHeading,
          size: FONT_SIZES.thirdHeading,
        },
      },
      size: FONT_SIZES.secondHeading,
    },
    thirdHeading: {
      lineHeight: LINE_HEIGHTS.thirdHeading,
      responsive: {
        '1200px': {
          lineHeight: LINE_HEIGHTS.fourthHeading,
          size: FONT_SIZES.fourthHeading,
        },
      },
      size: FONT_SIZES.thirdHeading,
    },
  },
};

export default Theme;
