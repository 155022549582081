import 'styled-components/macro';

import { AudioTrack, Publication, Track, VideoTrack } from './@types';
import {
  AudioTrackPublication,
  Participant as VideoParticipant,
  VideoTrackPublication,
} from 'twilio-video';
import React, { memo, useEffect, useRef, useState } from 'react';

import { IoMdPerson } from 'react-icons/io';
import ParticipantViewStyle from './styles/ParticipantView.style';

interface TrackMap {
  audioTracks: AudioTrack[];
  videoTracks: VideoTrack[];
}

interface ParticipantProps {
  participant: VideoParticipant;
  isExpanded: boolean;
  participantType: 'LOCAL' | 'REMOTE';
}

const Participant = ({
  participant,
  participantType,
  isExpanded,
}: ParticipantProps): React.ReactElement => {
  const [videoTracks, setVideoTracks] = useState<VideoTrack[]>([]);
  const [remoteVideoTrackIsEnabled, setVideoTrackIsEnabled] = useState<boolean>(false);

  const [audioTracks, setAudioTracks] = useState<AudioTrack[]>([]);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const trackPubsToTracks = (
    trackMap: Map<string, VideoTrackPublication> | Map<string, AudioTrackPublication>
  ): TrackMap => {
    const publications: IterableIterator<Publication> = trackMap.values();
    const tracks = Array.from(publications).map((publication) => publication.track);

    function isAudioTrack(track: Track | null): track is AudioTrack {
      return Boolean(track && track.kind === 'audio');
    }

    function isVideoTrack(track: Track | null): track is VideoTrack {
      return Boolean(track && track.kind === 'video');
    }

    const audioAndVideoTracks: {
      audioTracks: AudioTrack[];
      videoTracks: VideoTrack[];
    } = {
      audioTracks: [],
      videoTracks: [],
    };

    for (let i = 0; i < tracks.length; i += 1) {
      const track = tracks[i];

      if (isVideoTrack(track)) {
        audioAndVideoTracks.videoTracks.push(track);
      } else if (isAudioTrack(track)) {
        audioAndVideoTracks.audioTracks.push(track);
      }
    }

    return audioAndVideoTracks;
  };

  useEffect(() => {
    const { videoTracks: vTracks } = trackPubsToTracks(participant.videoTracks);
    const { audioTracks: aTracks } = trackPubsToTracks(participant.audioTracks);
    setVideoTracks(vTracks);
    setAudioTracks(aTracks);

    const trackSubscribed = (track: Track): void => {
      if (track.kind === 'video') {
        setVideoTrackIsEnabled(true);
        setVideoTracks((oldVideoTracks: VideoTrack[]) => [...oldVideoTracks, track]);
      } else if (track.kind === 'audio') {
        setAudioTracks((oldAudioTracks: AudioTrack[]) => [...oldAudioTracks, track]);
      }
    };

    const trackUnsubscribed = (track: Track): void => {
      if (track.kind === 'video') {
        setVideoTracks((oldVideoTracks: VideoTrack[]) =>
          oldVideoTracks.filter((videoTrack) => videoTrack !== track)
        );
      } else if (track.kind === 'audio') {
        setAudioTracks((oldAudioTracks: AudioTrack[]) =>
          oldAudioTracks.filter((audioTrack) => audioTrack !== track)
        );
      }
    };

    const trackStatusHasChanged = (track: Track, enabled: boolean): void => {
      if (track.kind === 'video') {
        setVideoTrackIsEnabled(enabled);
      }
    };
    participant.on('trackDisabled', (track) => trackStatusHasChanged(track, false));
    participant.on('trackEnabled', (track) => trackStatusHasChanged(track, true));

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    return (): void => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect((): void | (() => void | undefined) => {
    const videoTrack = videoTracks[0];

    if (videoTrack && videoRef.current) {
      videoTrack.attach(videoRef.current);

      return (): void => {
        videoTrack.detach();
      };
    }

    return (): void => {};
  }, [videoTracks]);

  useEffect((): void | (() => void | undefined) => {
    const audioTrack = audioTracks[0];

    if (audioTrack && audioRef.current) {
      audioTrack.attach(audioRef.current);

      return (): void => {
        audioTrack.detach();
      };
    }

    return (): void => {};
  }, [audioTracks]);

  const shouldRenderVideoTrack =
    videoTracks?.length > 0 && (remoteVideoTrackIsEnabled || participantType === 'LOCAL');

  return (
    <div>
      <div css={shouldRenderVideoTrack ? '' : ParticipantViewStyle(isExpanded)}>
        <IoMdPerson
          color="white"
          fontSize={isExpanded ? '144px' : '96px'}
          display={shouldRenderVideoTrack ? 'none' : 'block'}
        />
        <video
          ref={videoRef}
          autoPlay={true}
          css={`
            display: ${shouldRenderVideoTrack ? 'block' : 'none'};
          `}
        />
        <audio ref={audioRef} autoPlay={true} />
      </div>
    </div>
  );
};

export default memo(Participant);
