import FormTemplate from '../../templates/Form';
import IMPORT_UNIT_TYPES_FROM_CSV_VALIDATION_SCHEMA from './constants/importUnitTypesFromCSVValidationSchema';
import { Mutation } from '../../__generated__/graphql';
import React from 'react';
import UNIT_TYPE_IMPORT_FORM_STEPS from './constants/unitTypeFormSteps';
import UnitTypeImportFormInputs from './@types/UnitTypeImportFormInputs';

type UploadUnitTypesData = Pick<Mutation, 'uploadUnitTypes'>;

const UnitTypeImportForm: React.FC<{ id?: string }> = () => {
  return (
    <FormTemplate<UnitTypeImportFormInputs, UploadUnitTypesData>
      formHeader={'Import Unit Types'}
      steps={UNIT_TYPE_IMPORT_FORM_STEPS}
      validationSchema={IMPORT_UNIT_TYPES_FROM_CSV_VALIDATION_SCHEMA}
    />
  );
};

export default UnitTypeImportForm;
