const kiloBytesToBytes = 1024;
const megaBytesToKiloBytes = 1024;
const megaBytesToBytes = megaBytesToKiloBytes * kiloBytesToBytes;

export const convertMegaBytesToBytes = (sizeInMegabytes: number): number => {
  return sizeInMegabytes * megaBytesToBytes;
};

export const convertBytesToMegaBytes = (sizeInBytes: number): number => {
  return sizeInBytes / megaBytesToBytes;
};

export const MAX_IMAGE_SIZE = 10000000;
