import {
  CustomResourceCreateInput,
  Mutation,
  MutationCreateResourceAndUploadImageFileArgs,
} from '../__generated__/graphql';
import { useDispatch, useSelector } from 'react-redux';

import CREATE_RESOURCE from '../graphql/createResource';
import MutationHookData from './@types/MutationHookData';
import MutationHookParams from './@types/MutationHookParams';
import { RootState } from '../redux/reducers';
import { apolloRequirePreflightHeader } from '../client';
import { closeForm } from '../redux/actions/modals.actions';
import { flashApolloError } from '../util/errorUtils';
import { useMutation } from '@apollo/client';

type CreateResourceData = Pick<Mutation, 'createResourceAndUploadImageFile'>;

export default function useCreateResourceAndUploadImage({
  onCompleted = (): void => {},
  onError = flashApolloError,
}: MutationHookParams<CreateResourceData> = {}): MutationHookData<
  CustomResourceCreateInput,
  CreateResourceData
> {
  const dispatch = useDispatch();
  const me = useSelector((state: RootState) => state.me);
  const activeTenantId = me.activeMembership?.tenantId ?? '';

  const [create, { data, error, loading }] = useMutation<
    CreateResourceData,
    MutationCreateResourceAndUploadImageFileArgs
  >(CREATE_RESOURCE, {
    context: {
      headers: apolloRequirePreflightHeader,
    },
    onCompleted: ({ createResourceAndUploadImageFile }) => {
      window.flash({
        message: `Resource: ${createResourceAndUploadImageFile.name} created successfully`,
      });

      onCompleted({ createResourceAndUploadImageFile });

      dispatch(closeForm());
    },
    onError,
  });

  return {
    data,
    error,
    loading,
    mutation: (inputs: CustomResourceCreateInput): void => {
      create({
        variables: {
          data: {
            ...inputs,
            ...(inputs.passLevel && { passLevel: parseInt(`${inputs.passLevel}`, 10) }),
            tenant: { connect: { id: activeTenantId } },
          },
        },
      });
    },
  };
}
