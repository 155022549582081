import { gql } from '../../__generated__/gql';

export const GET_USER = gql(`
  query User($where: UserWhereInput!) {
    user(where: $where) {
      email
      id
      lastSeenAt
      name
      phoneNumber
      memberships {
        id
        role
        tenantId
      }
      payments {
        providerType
        token
        customerId
      }
      userRecords {
        recordType
        recordId
        tenantId
      }
    }
  }
`);

export const GET_USERS = gql(`
  query Users($skip: Int, $take: Int, $where: UserWhereInput) {
    users(skip: $skip, take: $take, where: $where) {
      email
      id
      lastSeenAt
      name
      phoneNumber
      memberships {
        id
        role
        tenantId
      }
    }
    aggregateUser(where: $where) {
      _count {
        _all
      }
    }
  }
`);
