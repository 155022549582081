import 'styled-components/macro';

import Button from '../../../Button';
import ButtonModel from '../../../Button/enums/ButtonModel.enum';
import CustomizeThemeCrudButton from '../../../CustomizeJson/styles/CustomizeThemeCrudButton.style';
import React from 'react';

interface JsonCustomizationActionButtonInterface {
  icon: JSX.Element;
  onClick: () => void;
  enabled?: boolean;
}

const JsonCustomizationActionButton: React.FC<JsonCustomizationActionButtonInterface> = ({
  icon,
  onClick,
  enabled = true,
}: JsonCustomizationActionButtonInterface) => {
  return (
    <Button
      css={CustomizeThemeCrudButton}
      active={enabled}
      disabled={!enabled}
      model={ButtonModel.PASSIVE_ICON_BUTTON}
      onClick={onClick}
    >
      {icon}
    </Button>
  );
};

export default JsonCustomizationActionButton;
