import { KioskWhereInput, Query, QueryKiosksArgs } from '../../__generated__/graphql';
import { useCallback, useEffect, useRef, useState } from 'react';

import { EntityEnum } from '../../@types/client/DashboardUserRolePermissions';
import GET_KIOSK_COUNT from '../../graphql/aggregateKiosk';
import GET_KIOSK_TABLE_DATA from '../../graphql/getKioskTableData';
import KIOSKS_SEARCH_FIELDS from './constants/KiosksTableSearchFields';
import KIOSKS_TABLE_HEADERS from './constants/KiosksTableHeaders';
import { LocationPageInfo } from '../../pages/Location/@types/GetLocationPageInfo';
import OrderByParams from '../../@types/client/OrderByParams';
import { RootState } from '../../redux/reducers';
import SearchField from '../../@types/SearchField';
import TableHookData from '../@types/TableHookData';
import TableHookProps from '../@types/TableHookProps';
import generateKiosksTableData from './functions/generateKiosksTableData';
import generateMembershipQuery from '../../util/api/generateMembershipQuery';
import parseKioskFiltersParameters from './util/parseKioskFiltersParameters';
import parseOrderByDataTableParams from '../../components/DataTable/util/parseOrderByDataTableParams';
import useGetMany from '../useGetMany';
import useKioskFilters from './hooks/useKioskFilters';
import useRemoveKiosk from '../useRemoveKiosk';
import { useSelector } from 'react-redux';

type KiosksQueryParams = {
  orderBy?: OrderByParams;
  where: Partial<KioskWhereInput>;
};

interface KiosksTableData extends TableHookProps<QueryKiosksArgs, KioskWhereInput> {
  relatedLocation?: LocationPageInfo;
}

type GetKioskTableData = Pick<Query, 'kiosks'>;
type AggregateKioskQueryData = Pick<Query, 'aggregateKiosk'>;

export default function useKiosksTableData({
  dataTableKey = 'kiosks',
  formName = 'kiosk',
  headers = KIOSKS_TABLE_HEADERS,
  isSearchable = true,
  queryOptions = {},
  searchFields = KIOSKS_SEARCH_FIELDS,
  relatedLocation,
}: KiosksTableData): TableHookData<KioskWhereInput> {
  const membership = useSelector((state: RootState) => state.me.activeMembership);
  const options = useRef<Partial<QueryKiosksArgs>>(queryOptions);
  const fieldsFiltered = useSelector((state: RootState) => state.dataTable.filters);
  const sortOptions = useSelector((state: RootState) => state.dataTable.sortOptions);
  const filters = useKioskFilters();

  const [searchParameters, setSearchParameters] = useState<KioskWhereInput>({} as KioskWhereInput);

  const { data, error, loading, offset, query, setOffset } = useGetMany<
    GetKioskTableData,
    QueryKiosksArgs
  >({
    graphqlQuery: GET_KIOSK_TABLE_DATA,
  });

  const {
    data: countData,
    error: countError,
    loading: countLoading,
    query: countQuery,
  } = useGetMany<AggregateKioskQueryData, QueryKiosksArgs>({
    graphqlQuery: GET_KIOSK_COUNT,
  });

  const createParams = useCallback((): KiosksQueryParams => {
    const whereArgs = parseKioskFiltersParameters(fieldsFiltered[dataTableKey]);
    const sortParams = parseOrderByDataTableParams(sortOptions[dataTableKey]);

    return {
      ...(sortParams && { orderBy: sortParams }),
      where: {
        ...generateMembershipQuery(membership),
        ...options.current?.where,
        ...searchParameters,
        ...whereArgs,
      },
    };
  }, [membership, sortOptions, options, fieldsFiltered, dataTableKey, searchParameters]);

  const get = useCallback(() => {
    const params = createParams();

    query({
      ...options.current,
      ...(params.orderBy && { orderBy: params.orderBy }),
      skip: options.current?.skip ?? offset,
      take: options.current?.take ?? 10,
      where: params.where,
    });
  }, [createParams, offset, query]);

  const getCount = useCallback(() => {
    const params = createParams();

    countQuery({
      where: params.where,
    });
  }, [countQuery, createParams]);

  useEffect(() => {
    get();
  }, [get]);

  useEffect(() => {
    getCount();
  }, [getCount]);

  return {
    aditionalDataOnOpeningForm: relatedLocation
      ? {
          location: {
            id: relatedLocation.id,
            name: relatedLocation.name,
          },
        }
      : undefined,
    count: countData?.aggregateKiosk?._count?._all ?? 0,
    data: generateKiosksTableData(data),
    dataTableKey,
    dataType: 'Kiosk',
    error: error || countError,
    filters,
    formName,
    headers,
    isLoading: loading || countLoading,
    isSearchable,
    offset,
    permission: EntityEnum.KIOSK,
    remove: useRemoveKiosk,
    search: (searchField: SearchField<KioskWhereInput>, searchValue: string): void => {
      setSearchParameters({
        [searchField.queryField]: searchField.transform(searchValue),
      });

      setOffset(0);
    },
    searchFields,
    setOffset,
  };
}
