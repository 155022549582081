import {
  ActionProcessingModalProps,
  ActionResult,
  BaseQuickActionModalButton,
} from '../@types/QuickActionBarTypes';
import {
  MessageText,
  OperationMessageText,
  OperationStateContainer,
  SuccessAnimationContainer,
} from '../styles/BaseModal.styles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BaseQuickActionModal from './BaseQuickActionModal';
import LoadingHourglassAnimation from '../../../assets/animations/loading-hourglass.json';
import Lottie from 'react-lottie';
import SuccessAnimation from '../../../assets/animations/success.json';
import { closeQuickActionModal } from '../../../redux/actions/modals.actions';
import { useCtaBasedText } from '../hooks/useCtaBasedText';
import { useDispatch } from 'react-redux';

export const ActionProcessingModal: React.FC<ActionProcessingModalProps> = ({
  action,
  ctaType,
}) => {
  const [actionResult, setActionResult] = useState<ActionResult | undefined>();
  const [loading, setLoading] = useState<boolean | undefined>();
  const dispatch = useDispatch();

  const executeAction = useCallback(async (): Promise<void> => {
    setLoading(true);

    try {
      const result = await action();
      setActionResult(result);
    } catch (error) {
      const message = (error as Error)?.message;

      setActionResult({
        success: false,
        errorMessage: message,
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const renderContainerElement = (): React.ReactNode => {
    if (!loading && actionResult?.success) {
      return (
        <OperationStateContainer>
          <SuccessAnimationContainer
            height={160}
            width={160}
            options={{
              animationData: SuccessAnimation,
              loop: false,
            }}
          />
          <OperationMessageText>Success!</OperationMessageText>
        </OperationStateContainer>
      );
    }

    if (!loading && actionResult?.errorMessage) {
      return <MessageText>{actionResult.errorMessage || 'There was an error'}</MessageText>;
    }

    return (
      <OperationStateContainer>
        <Lottie
          height={240}
          width={240}
          style={{ margin: -40 }}
          options={{
            animationData: LoadingHourglassAnimation,
          }}
        />
        <OperationMessageText>Loading...</OperationMessageText>
      </OperationStateContainer>
    );
  };

  const confirmationButton = useMemo((): BaseQuickActionModalButton | undefined => {
    if (actionResult?.errorMessage) {
      return {
        action: (): void => {
          setActionResult(undefined);
          executeAction();
        },
        text: 'Try again',
      };
    }

    return undefined;
  }, [actionResult?.errorMessage]);

  const { errorHeader } = useCtaBasedText(ctaType);

  const closeModal = useCallback(() => {
    dispatch(closeQuickActionModal('quickActionProcessingModal'));
  }, []);

  useEffect(() => {
    executeAction();
  }, []);

  useEffect(() => {
    if (actionResult?.success) {
      setTimeout(() => {
        closeModal();
      }, 4000);
    }
  }, [actionResult?.success]);

  return (
    <BaseQuickActionModal
      ctaType={actionResult?.errorMessage ? 'error' : ctaType}
      confirmButton={confirmationButton}
      cancelButton={
        loading
          ? undefined
          : {
              action: closeModal,
            }
      }
      headerTitle={actionResult?.errorMessage && errorHeader}
      shouldAllowSoftDismissing={!loading}
    >
      {renderContainerElement()}
    </BaseQuickActionModal>
  );
};
