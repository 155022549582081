/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';
import Theme from '../../../theme';

interface SubmitButtonProps {
  isSubmitting: boolean;
}

export const SectionSeparator = styled.hr`
  height: 1px;
  width: 100%;
  color: ${Theme.colors.tableHeading.hexCode};
`;

export const SubmitButton = styled.button<SubmitButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 170px;
  color: ${Theme.colors.white.hexCode};
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  margin: 20px 0;

  ${({ isSubmitting }): FlattenSimpleInterpolation => {
    if (isSubmitting) {
      return css`
        background-color: ${Theme.colors.linkWater.hexCode};
      `;
    }

    return css`
      background-color: ${Theme.colors.buttonInputEmbedded.hexCode};

      :hover {
        background-color: ${Theme.colors.buttonDominant.hexCode};
        transition: all 0.2s;
      }
    `;
  }}
`;
