import { Header } from '../../../components/DataTable/@types/DataTableProps';
import SortType from '../../../components/DataTable/@types/SortType.enum';

const VERIFICATIONS_TABLE_HEADERS: Header[] = [
  { displayValue: 'Guest', isSortable: false, value: 'user.name' },
  {
    displayValue: 'Confirmation #',
    isSortable: true,
    sortType: SortType.SortOrderInput,
    value: 'confirmationCode',
  },
  { displayValue: 'Req. Completed', isSortable: false },
  { displayValue: 'Check In', isSortable: true, sortType: SortType.SortOrder, value: 'startsAt' },
  { displayValue: 'Location', isSortable: false, value: 'location.name' },
  { displayValue: 'Last Seen', isSortable: false, value: 'lastSeenAt' },
];

export default VERIFICATIONS_TABLE_HEADERS;
