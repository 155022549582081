import React from 'react';
import Theme from '../../theme';

interface SVGProps {
  height?: string;
  fill?: string;
  width?: string;
}

const WarningIcon: React.FunctionComponent<SVGProps> = ({
  height = '18px',
  fill = Theme.colors.dark.hexCode,
  width = '17px',
}: SVGProps) => {
  const viewBox = `0 0 ${width} ${height}`.replace(/px/g, '');

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Assistance</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Dashboard_-1-Copy-12" transform="translate(-36.000000, -205.000000)" fill={fill}>
          <g id="Group-16" transform="translate(24.660000, 194.437096)">
            <g id="Group-11" transform="translate(11.340000, 11.340000)">
              <path
                d="M8.1,14.58 C11.678823,14.58 14.58,11.678823 14.58,8.1 C14.58,4.5211932 11.678823,1.62 8.1,1.62 C4.5211932,1.62 1.62,4.5211932 1.62,8.1 C1.62,11.678823 4.5211932,14.58 8.1,14.58 Z M8.1,16.2 C12.573468,16.2 16.2,12.573468 16.2,8.1 C16.2,3.6264915 12.573468,0 8.1,0 C3.6264915,0 0,3.6264915 0,8.1 C0,12.573468 3.6264915,16.2 8.1,16.2 Z"
                id="Shape"
              ></path>
              <path
                d="M8.1,4.05 C7.652637,4.05 7.29,4.4126532 7.29,4.86 C7.29,5.3073549 7.652637,5.67 8.1,5.67 C8.547363,5.67 8.91,5.3073549 8.91,4.86 C8.91,4.4126532 8.547363,4.05 8.1,4.05 Z M8.1,7.29 C7.652637,7.29 7.29,7.652637 7.29,8.1 L7.29,11.34 C7.29,11.787363 7.652637,12.15 8.1,12.15 C8.547363,12.15 8.91,11.787363 8.91,11.34 L8.91,8.1 C8.91,7.652637 8.547363,7.29 8.1,7.29 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default WarningIcon;
