import {
  UPSERT_KIOSK_LOCALIZATION,
  UPSERT_LOCATION_LOCALIZATION,
} from '../../../graphql/mutations';

import { CustomizeThemeLocalizationEntityLevel } from '../../../enums/CustomizeThemeLocalizationEntityLevel';
import { DocumentNode } from 'graphql';
import { NotSupportedError } from '../../CustomizeTheme/constants/defaultError';
import { UPSERT_TENANT_LOCALIZATION } from '../../../graphql/mutations/tenant';

const getUpsertQuery = (entityLevel: CustomizeThemeLocalizationEntityLevel): DocumentNode => {
  switch (entityLevel) {
    case CustomizeThemeLocalizationEntityLevel.TENANT:
      return UPSERT_TENANT_LOCALIZATION;

    case CustomizeThemeLocalizationEntityLevel.LOCATION:
      return UPSERT_LOCATION_LOCALIZATION;

    case CustomizeThemeLocalizationEntityLevel.KIOSK:
      return UPSERT_KIOSK_LOCALIZATION;

    default:
      throw NotSupportedError(entityLevel);
  }
};

export default getUpsertQuery;
