import { customAlphabet } from 'nanoid';

const alphaBet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'; // Full alpha-numeric range without '-' and '_' chars
const numChars = 16;

const reqIdGenerator = customAlphabet(alphaBet, numChars);

const REQ_ID_PREFIX = 'D_';

export const generateRequestId = (): string => {
  return REQ_ID_PREFIX + reqIdGenerator();
};
