import { ConciergeItem, InfoType, Mutation } from '../../../__generated__/graphql';
import InfoItemFormInputs, { InfoTypeLabel } from './@types/FormInputs';
import React, { useEffect } from 'react';

import FormTemplate from '../../../templates/Form';
import INFO_ITEM_FORM_STEPS from './constants/FormSteps';
import INFO_ITEM_VALIDATION_SCHEMA from './constants/ValidationSchema';
import useGetInfoItemById from '../../../hooks/useGetInfoItemById';

interface InfoItemProps {
  id?: string;
  aditionalDataOnOpeningForm?: unknown;
}

type CreateInfoItemData = Pick<Mutation, 'createConciergeItem'>;

const ConciergeForm: React.FC<InfoItemProps> = ({
  id,
  aditionalDataOnOpeningForm,
}: InfoItemProps) => {
  const { called, data: infoItem, query } = useGetInfoItemById();

  useEffect(() => {
    if (id && !called) {
      query(id);
    }
  });

  if (id && !infoItem) {
    return <React.Fragment />;
  }

  const getInitialValues = (): Partial<InfoItemFormInputs> => {
    const target = aditionalDataOnOpeningForm as ConciergeItem;

    return ({
      ...(target || {}),
      ...(target?.type
        ? {
            type: {
              label: InfoTypeLabel[target?.type as InfoType.Category | InfoType.InfoItem],
              value: target?.type,
            },
          }
        : {}),
    } as unknown) as Partial<InfoItemFormInputs>;
  };

  return (
    <FormTemplate<InfoItemFormInputs, CreateInfoItemData>
      formHeader={id ? 'Edit Info Item' : 'Create Info Item'}
      identifier={id}
      steps={INFO_ITEM_FORM_STEPS}
      validationSchema={INFO_ITEM_VALIDATION_SCHEMA}
      initialValues={getInitialValues()}
    />
  );
};

export default ConciergeForm;
