import SearchField from '../../../@types/SearchField';
import { UnitTypeWhereInput } from '../../../__generated__/graphql';
import createSearchField from '../../../util/tableData/createSearchField';
import transformStringFilter from '../../../util/tableData/transformStringFilter';

const UNIT_TYPES_TABLE_SEARCH_FIELDS: SearchField<UnitTypeWhereInput>[] = [
  createSearchField<UnitTypeWhereInput, 'type'>({
    label: 'Type',
    queryField: 'type',
    transform: transformStringFilter,
  }),
];

export default UNIT_TYPES_TABLE_SEARCH_FIELDS;
