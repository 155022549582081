import KiosksLiveUsageReduxState, {
  KioskLiveUsagePayloadData,
  KioskLiveUsagePayloadEvent,
} from '../@types/KiosksLiveUsageReduxState';

export default function setKioskLiveUsageAction(
  currentState: KiosksLiveUsageReduxState,
  actionPayload: KioskLiveUsagePayloadEvent
): KiosksLiveUsageReduxState {
  const newState = { ...currentState };

  const newKioskPayload: KioskLiveUsagePayloadData = {
    accessGrant: actionPayload.accessGrantId
      ? {
          id: actionPayload.accessGrantId,
        }
      : undefined,
    kioskId: actionPayload.kioskId,
  };

  newState[actionPayload.kioskId] = { payload: newKioskPayload };

  return newState;
}
