export enum GracePeriodType {
  RELATIVE = 'Relative',
  ABSOLUTE = 'Absolute',
}

export enum TimeControlType {
  CHECK_IN = 'checkIn',
  CHECK_OUT = 'checkOut',
}

export enum TimeControlCategory {
  GUEST_ROOM = 'guestRoom',
  COMMON_AREAS = 'commonAreas',
}

export enum TimePeriodType {
  AM = 'AM',
  PM = 'PM',
}

export enum EarlyCheckInTypeEnum {
  ALL_DAY = 'All day',
  TIME_CONSTRAINED = 'Time constrained',
}
