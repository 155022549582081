import { gql } from '../__generated__/gql';

const GET_USER = gql(`
  query GetUser($where: UserWhereInput!) {
    user(where: $where) {
      email
      id
      name
      phoneNumber
      dashboardPreferences
      memberships {
        id
        location {
          id
          name
        }
        role
        tenantId
        locationId
      }
      payments {
        providerType
        token
        customerId
      }
      userRecords {
        recordType
        recordId
        tenantId
      }
    }
  }
`);

export default GET_USER;
