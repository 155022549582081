import { Mutation, QueryKioskArgs } from '../__generated__/graphql';

import DELETE_KIOSK from '../graphql/deleteKiosk';
import RemoveHookData from './@types/RemoveHookData';
import { closeRemoveModal } from '../redux/actions/modals.actions';
import { flashApolloError } from '../util/errorUtils';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

interface Props {
  onRemoveCompleted?: () => void;
}

type DeleteKioskData = Pick<Mutation, 'deleteOneKiosk'>;

export default function useRemoveKiosk({ onRemoveCompleted }: Props = {}): RemoveHookData {
  const dispatch = useDispatch();
  const history = useHistory();

  const [remove, { error, loading }] = useMutation<DeleteKioskData, QueryKioskArgs>(DELETE_KIOSK, {
    onCompleted: ({ deleteOneKiosk }) => {
      if (deleteOneKiosk) {
        window.flash({
          message: `Kiosk: ${deleteOneKiosk.name} removed successfully`,
        });
      }

      dispatch(closeRemoveModal());
      if (onRemoveCompleted) {
        onRemoveCompleted();
      } else {
        history.go(0);
      }
    },
    onError: flashApolloError,
  });

  return {
    error,
    loading,
    mutation: (id: string): void => {
      remove({
        variables: {
          where: { id },
        },
      });
    },
  };
}
