const SearchResultsDropdownStyle = `
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.2);
  min-width: 100%;
  position: absolute;
  top: calc(100% + 10px);
  z-index: 2;
`;

export default SearchResultsDropdownStyle;
