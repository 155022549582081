import { Header } from '../../../components/DataTable/@types/DataTableProps';
import SortType from '../../../components/DataTable/@types/SortType.enum';

const LOCATIONS_TABLE_HEADERS: Header[] = [
  { displayValue: 'Name', isSortable: true, sortType: SortType.SortOrder, value: 'name' },
  { displayValue: 'Phone', isSortable: true, sortType: SortType.SortOrder, value: 'phoneNumber' },
  { displayValue: 'Address', isSortable: true, sortType: SortType.SortOrder, value: 'address' },
  { displayValue: 'Logo Url', isSortable: false },
  { displayValue: 'Image Url', isSortable: false },
];

export default LOCATIONS_TABLE_HEADERS;
