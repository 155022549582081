import { gql } from '../__generated__/gql';

const GET_VENDOR_SERVERS_TABLE_DATA = gql(`
  query GetVendorServersTableData(
    $orderBy: [VendorServerOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: VendorServerWhereInput
  ) {
    vendorServers(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
      ...VendorServerFields
    }
  }
`);

export default GET_VENDOR_SERVERS_TABLE_DATA;

export const GET_VENDOR_SERVERS_COUNT = gql(`
  query GetVendorServerCount($where: VendorServerWhereInput!) {
    aggregateVendorServer(where: $where) {
      _count {
        _all
      }
    }
  }
`);
