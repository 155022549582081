import { ReduxMembership } from '../../../redux/reducers/@types/MeReduxState';
import SelectOption from '../../../@types/react/SelectOption';

interface OptionsGroup {
  label: string;
  options: SelectOption[];
}

type GroupedOptions = Array<OptionsGroup>;

export default function generateMembershipOptions(memberships: ReduxMembership[]): GroupedOptions {
  const tenantOptions: OptionsGroup = {
    label: 'Organization',
    options: [],
  };

  const locationOptions: OptionsGroup = {
    label: 'Location',
    options: [],
  };

  memberships.forEach((membership) => {
    if (membership.location) {
      locationOptions.options.push({
        label: membership.location.name,
        value: membership.id,
      });
    } else {
      tenantOptions.options.push({
        label: membership.tenant.name,
        value: membership.id,
      });
    }
  });

  return [tenantOptions, locationOptions];
}
