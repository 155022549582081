import { Datum } from '../../../components/DataTable';
import { Query } from '../../../__generated__/graphql';

type GetResourceGroupsData = Pick<Query, 'resourceGroups'>;

export default function generateResourceGroupsTableData(data?: GetResourceGroupsData): Datum[] {
  const tableData =
    data?.resourceGroups.map((resourceGroup) => ({
      dataType: 'Resource',
      fields: [
        {
          key: 'Name',
          value: resourceGroup.name,
        },
        {
          key: 'Default',
          value: `${resourceGroup.isDefault}`,
        },
        {
          key: 'Resources',
          value: `${resourceGroup.resources.length} resource${
            resourceGroup.resources.length === 1 ? '' : 's'
          }`,
        },
      ],
      key: resourceGroup.id,
    })) || [];

  return tableData;
}
