import { Kiosk } from '../../__generated__/graphql';
import { MINUTE } from '../../constants/time';

const TIME_TO_DETERMINATE_DISPENSER_IS_OFFLINE = 5 * MINUTE;

export function getDispenserOfflineMS(): number {
  const isOfflineIf = Date.now() - TIME_TO_DETERMINATE_DISPENSER_IS_OFFLINE;
  return isOfflineIf;
}

export default function isDispenserOnline(kiosk: Pick<Kiosk, 'dispenserLastSeenAt'>): boolean {
  if (kiosk.dispenserLastSeenAt) {
    const dateInSeconds = Date.parse(kiosk.dispenserLastSeenAt);

    if (dateInSeconds) {
      return dateInSeconds >= getDispenserOfflineMS();
    }
  }

  return false;
}
