import { ImageTypeEnum } from '../../UnitTypeForm/enums/ImageTypeEnum.enum';
import InputGroupProps from '../../InputGroup/@types/InputGroupProps';
import { MAX_IMAGE_SIZE } from '../../../util/fileUtil';
import useLoadAmenityGroupOptions from '../../../hooks/useLoadAmenityGroupOptions';
import useLoadLocationOptions from '../../../hooks/useLoadLocationOptions';
import useSearchAmenityGroupsByName from '../../../hooks/useSearchAmenityGroups';
import useSearchLocationsByName from '../../../hooks/useSearchLocations';

interface AmenityImageFormInputProps {
  imageType: string;
}

export default function generateAmenitySectionInputs({
  imageType,
}: AmenityImageFormInputProps): InputGroupProps[] {
  let amenityInputs: InputGroupProps[] = [
    {
      label: 'Title',
      name: 'title',
      required: true,
      type: 'text',
    },
    {
      label: 'Description',
      name: 'description',
      type: 'text',
    },
    {
      label: 'Search Location',
      loadOptions: useLoadLocationOptions,
      name: 'location',
      query: useSearchLocationsByName,
      required: true,
      type: 'async-select',
    },
    {
      label: 'Image type',
      name: 'imageType',
      options: Object.values(ImageTypeEnum),
      type: 'select',
    },
  ];

  if (imageType === ImageTypeEnum.URL) {
    amenityInputs.push({
      label: 'Image URL',
      name: 'imageUrl',
      required: true,
      type: 'url',
    });
  } else if (imageType === ImageTypeEnum.UPLOAD) {
    amenityInputs.push({
      accept: 'image/*',
      label: 'Image file',
      maxFileSize: MAX_IMAGE_SIZE,
      name: `imageFile`,
      required: true,
      type: 'file',
    });
  }

  amenityInputs = [
    ...amenityInputs,
    {
      label: 'Groups',
      loadOptions: useLoadAmenityGroupOptions,
      name: 'group',
      query: useSearchAmenityGroupsByName,
      required: true,
      type: 'async-select',
    },
  ];

  return amenityInputs;
}
