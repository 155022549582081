import { MdContentCopy } from 'react-icons/md';
import React from 'react';

type CopyToClipboardIcon = {
  onCopyFunction: () => void;
};

const CopyToClipboardIcon: React.FC<CopyToClipboardIcon> = ({
  onCopyFunction,
}: CopyToClipboardIcon) => {
  return <MdContentCopy size="20px" color="#b2b2b2" cursor="pointer" onClick={onCopyFunction} />;
};

export default CopyToClipboardIcon;
