import { AnyAction } from 'redux';
import AuthActionEnum from '../../enums/AuthAction.enum';

export const clearTokens = (): AnyAction => ({
  type: AuthActionEnum.CLEAR_TOKENS,
});

export const loadAuthStore = (): AnyAction => ({
  type: AuthActionEnum.LOAD,
});

export const receiveTokens = (tokens: {
  accessToken: string;
  refreshToken: string;
}): AnyAction => ({
  payload: tokens,
  type: AuthActionEnum.RECEIVE_TOKENS,
});

export const setRefreshTokenHandlerTimeout = (refreshTokenHandlerTimeout: number): AnyAction => ({
  payload: { refreshTokenHandlerTimeout },
  type: AuthActionEnum.SET_REFRESH_TOKEN_HANDLER_TIMEOUT,
});

export const clearRefreshTokenHandlerTimeout = (): AnyAction => ({
  type: AuthActionEnum.CLEAR_REFRESH_TOKEN_HANDLER_TIMEOUT,
});
