import { Header } from '../../../components/DataTable/@types/DataTableProps';
import SortType from '../../../components/DataTable/@types/SortType.enum';

const USER_TABLE_HEADERS: Header[] = [
  { displayValue: 'Name', isSortable: true, sortType: SortType.SortOrderInput, value: 'name' },
  { displayValue: 'Email', isSortable: true, sortType: SortType.SortOrderInput, value: 'email' },
  {
    displayValue: 'Phone Number',
    isSortable: true,
    sortType: SortType.SortOrderInput,
    value: 'phoneNumber',
  },
  { displayValue: 'Role', isSortable: false, value: 'memberships.role' },
  {
    displayValue: 'Last Seen',
    isSortable: true,
    sortType: SortType.SortOrderInput,
    value: 'lastSeenAt',
  },
];

export default USER_TABLE_HEADERS;
