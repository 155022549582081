const FormSectionsContainerStyle = `
  border-bottom: 1px;
  border-color: #e4e4e4;
  border-style: solid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  margin: 20px 0 25px;
  padding: 0 0 25px;
`;

export default FormSectionsContainerStyle;
