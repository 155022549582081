import { gql } from '../__generated__/gql';

const SET_GRACE_PERIODS_CONFIG = gql(`
  mutation setGracePeriodsConfig(
    $gracePeriodsConfigLevel: String!
    $accessGrantId: String
    $locationId: String
    $tenantId: String
    $data: GracePeriodInput!
  ) {
    setGracePeriodsConfig(
      gracePeriodsConfigLevel: $gracePeriodsConfigLevel
      accessGrantId: $accessGrantId
      locationId: $locationId
      tenantId: $tenantId
      data: $data
    ) {
      success
    }
  }
`);

export default SET_GRACE_PERIODS_CONFIG;
