import 'styled-components/macro';

import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import Container from '../../Container';
import Form from '../../Form';
import React from 'react';
import { RootState } from '../../../redux/reducers';
import SimpleFormSection from '../../../templates/SimpleFormSection';
import SubmitRow from '../../SubmitRow';
import USERS_IMPORT_SECTION_INPUTS from '../constants/usersImportSectionInputs';
import UsersImportFormInputs from '../@types/UsersImportFormInputs';
import { openConfirmationModal } from '../../../redux/actions/modals.actions';
import useSignUpUsersFromThirdPartyAuthenticationService from '../../../hooks/useSignUpUsersFromThirdPartyAuthenticationService';

const UsersImportSection: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { handleSubmit, setError, watch } = useFormContext();

  const activeMembership = useSelector((state: RootState) => state.me.activeMembership);
  const {
    mutation: signUpUsersFromOkta,
    loading,
  } = useSignUpUsersFromThirdPartyAuthenticationService();

  const importFrom: string = watch('importFrom');

  const submitHandler: SubmitHandler<UsersImportFormInputs> = async () => {
    switch (importFrom) {
      case 'OKTA':
        dispatch(
          openConfirmationModal({
            action: (): void => {
              signUpUsersFromOkta({ serviceType: 'OKTA', tenantId: activeMembership.tenantId });
            },
          })
        );
        break;

      default:
        setError('importFrom', { message: 'Invalid import from' });
    }
  };

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Container>
        <SimpleFormSection inputs={USERS_IMPORT_SECTION_INPUTS} />
        <SubmitRow loading={loading} submitText="Import users" />
      </Container>
    </Form>
  );
};

export default UsersImportSection;
