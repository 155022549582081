import FormTemplate from '../../templates/Form';
import IMPORT_RESOURCES_FROM_CSV_VALIDATION_SCHEMA from './constants/importResourcesFromCSVValidationSchema';
import { Mutation } from '../../__generated__/graphql';
import RESOURCE_FORM_STEPS from './constants/resourceFormSteps';
import React from 'react';
import ResourceImportFormInputs from './@types/ResourceImportFormInputs';

type UploadResourcesData = Pick<Mutation, 'uploadResources'>;

const ResourceImportForm: React.FC<{ id?: string }> = () => {
  return (
    <FormTemplate<ResourceImportFormInputs, UploadResourcesData>
      formHeader={'Import Resources'}
      steps={RESOURCE_FORM_STEPS}
      validationSchema={IMPORT_RESOURCES_FROM_CSV_VALIDATION_SCHEMA}
    />
  );
};

export default ResourceImportForm;
