import { Query, QueryMode, QueryUnitTypesArgs, SortOrder } from '../__generated__/graphql';

import SEARCH_UNIT_TYPES from '../graphql/searchUnitTypes';
import SelectOption from '../@types/react/SelectOption';
import { useApolloClient } from '@apollo/client';
import { useFormContext } from 'react-hook-form';

type SearchUnitTypesData = Pick<Query, 'unitTypes'>;

export default function useLoadUnitTypeOptions(): (input: string) => Promise<SelectOption[]> {
  const client = useApolloClient();

  const { watch } = useFormContext();
  const locationId: string = watch('location.value');

  return async (input: string): Promise<SelectOption[]> => {
    const { data } = locationId
      ? await client.query<SearchUnitTypesData, QueryUnitTypesArgs>({
          query: SEARCH_UNIT_TYPES,
          variables: {
            orderBy: [
              {
                type: SortOrder.Asc,
              },
            ],
            take: 10,
            where: {
              locationId: {
                equals: locationId,
              },
              type: {
                contains: input,
                mode: QueryMode.Insensitive,
              },
            },
          },
        })
      : { data: {} as SearchUnitTypesData };

    return (
      data.unitTypes?.map((unitType) => ({
        label: unitType.type,
        value: unitType.id,
      })) ?? []
    );
  };
}
