import React, { useCallback } from 'react';

import DataTable from '../../../components/DataTable';
import { EntityEnum } from '../../../@types/client/DashboardUserRolePermissions';
import useAccessGrantsTableData from '../../../hooks/useAccessGrantsTableData';
import { useParams } from 'react-router';

const AccessGrantTab: React.FC<{}> = () => {
  const { userId } = useParams<{ userId: string }>();

  const getQueryOptions = useCallback(() => {
    return {
      where: {
        userId: { equals: userId },
      },
    };
  }, [userId]);

  const tableData = useAccessGrantsTableData({
    dataTableKey: 'accessGrants-User',
    permission: EntityEnum.NONE,
    queryOptions: getQueryOptions(),
  });

  return <DataTable {...tableData} />;
};

export default React.memo(AccessGrantTab);
