import { FontSize } from '../../../../../theme/fontSizes';

const errorColor = '#eb5757';

const JSONEditorStyle = `      
    .field-boolean {
        margin-bottom: 0px;
    }

    .error-detail {
        margin-left: 40px;
    }

    .field-boolean > .checkbox {
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 2px;
    }

    legend {
        color: white;
        font-size: ${FontSize.thirdHeading};
        margin-bottom: 0px;
    }

    .array-item fieldset > legend {
        font-size: ${FontSize.fourthHeading};
    }

    .form-group label > span, .field-object > fieldset label {
        font-size: ${FontSize.fourthHeading};
        font-weight: normal;
    }

    .form-group p.field-description {
        font-size: ${FontSize.label};
    }

    .field-object > fieldset > legend {
      padding-top: 8px;
    }

    .field-object > fieldset > p.field-description {
      margin-bottom: 8px;
    }

    .text-danger {
        color: ${errorColor};
    }

    div.has-error > input.form-control {
        border-color: ${errorColor};
        border-width: 2px;
    }

    .form-control[readonly], .form-control[disabled] {
        background-color: #ccc;
    }

    .array-item-list fieldset > legend {
        margin-bottom: 5px;
        border-bottom: none;
    }

    .field-array > fieldset.field-array-of-object > legend {
        display: none;
    }

    color: white;
`;

export default JSONEditorStyle;
